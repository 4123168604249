@import '../variables.scss';

.sidebar-new{
    position: fixed;
    left: 0;
    top: 0;
    width: 60px;
    height: 100%;
    background: #fcfaff;
    box-shadow: 0 0 7px 0 hsla(0,0%,76%,.5);
    z-index: 99;
    .sn-logo{
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 9;
        background: #fcfaff;
        cursor: pointer;
        img{
            width: 38px;
            height: 38px;
        }
        .snl-img{
            width: 100%;
            height: 60px;
            margin-top: 10px;
        }
    }
    ul{
        position: relative;
        padding: 0;
        height: calc(100% - 75px);
        margin: 0;
        transition: top .5s ease-out;
        .h2-main-item{
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            height: 70px;
            cursor: pointer;
            .h2mi-home{
                color: $primary-color;
                transition: all .6s;
                text-decoration: none;
                border-radius: 13px;
                .h2mi-icon-area{
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 12px;
                }
            }
            .hmi-img{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                border-radius: 12px;
            }
            .sub-menu{
                position: absolute;
                left: 60px;
                top: 0;
                visibility: hidden;
                opacity: 0;
                margin: 0;
                background-color: #fff;
                padding: 0;
                width: auto;
                height: auto;
                text-align: left;
                transform: translateY(20px);
                transition: all .2s ease-out;
                background: #fff;
                box-shadow: 3px 0 5px -2px #888;
                border-radius: 0 6px 6px 0;
                .sm-head{
                    display: flex;
                    align-items: center;
                    height: 35px;
                    padding: 5px;
                    border-bottom: 1px solid #eaeaea;
                    .sm-head-menu{
                        font-size: 13px;
                        font-weight: 600;
                        color: $primary-color;
                        padding-left: 12px;
                    }
                }
                .sm-inner-item {
                    position: relative;
                    width: auto;
                    min-width: 187px;
                    height: 30px;
                    min-height: 30px;
                    padding: 0 15px;
                    vertical-align: middle;
                    align-items: center;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    word-break: keep-all;
                    border-radius: 0 6px 6px 0;
                    background: #fff;
                    color: #2a2a64;
                    font-size: 12px;
                    font-weight: 500;
                    cursor: pointer;
                    border-left: 2px solid transparent;
                    .sub-menu {
                        position: absolute;
                        left: 185px;
                        top: -20px;
                        visibility: hidden;
                        opacity: 0;
                        margin: 0;
                        background-color: #ffffff;
                        padding: 0;
                        width: auto;
                        height: auto;
                        max-height: 250px;
                        overflow: auto;
                        text-align: left;
                        transform: translate(0 20px);
                        transition: all 0.2s ease-out;
                        display: flex;
                        flex-direction: column;
                        border-radius: 0px 6px 6px 0px;
                        .sm-inner-item {
                            .sm3-inner-item {
                                padding: 8px 0px;
                                display: block;
                                padding-left: 0;
                                width: 100%;
                                white-space: nowrap;
                            }
                            .sub-menu {
                                position: absolute;
                                left: 180px;
                                top: -1px;
                                visibility: hidden;
                                opacity: 0;
                                margin: 0;
                                background-color: #ffffff;
                                padding: 0;
                                width: 184px;
                                height: auto;
                                text-align: left;
                                transform: translate(0 20px);
                                transition: all 0.2s ease-out;
                                border: 1px solid #cbd5e0;
                                border-radius: 0px 6px 6px 0px;
                            }
                            .mdd-content{
                                span{
                                    visibility: hidden;
                                    opacity: 0;
                                }
                            }
                        }
                        .smv2 {
                            display: flex;
                            flex-direction: row;
                            .sm-cat-vice {
                                display: block;
                                padding: 0;
                                margin: 0;
                                max-height: 362px;
                                overflow: auto;
                                border-radius: 0 6px 6px 0;
                                .sm-inner-heading {
                                    position: relative;
                                    width: 182px;
                                    height: 32px;
                                    padding-left: 15px;
                                    vertical-align: middle;
                                    align-items: center;
                                    display: flex;
                                    background: #fff;
                                    color: $primary-color;
                                    font-size: 12px;
                                    font-weight: 700;
                                    border-bottom: 1px solid #dce6f0;
                                }
                                .sm-inner-item{
                                    white-space: nowrap;
                                }
                            }
                        }
                        .sm-inner-item:hover > .sub-menu {
                            visibility: visible;
                            opacity: 1;
                            transform: translate(0 0);
                        }
                        .sm-inner-item:hover{
                            .mdd-content{
                                span{
                                    visibility: visible;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                    .mdd-content{
                        p{
                            margin: 0;
                            font-weight: 500;
                            font-size: 12px;
                            color: #2A2A64;
                            height: 16px;
                            white-space: nowrap;
                        }
                        span{
                            font-weight: 500;
                            font-size: 10px;
                            color: #707494;
                            visibility: hidden;
                            opacity: 1;
                        }
                    }
                }
                .sm-inner-item:hover > .sub-menu {
                    visibility: visible;
                    opacity: 1;
                }
                .sm-inner-item:hover{
                    font-weight: 500;
                    background-color: $dropdown-item-hover;
                    // background-color: blue;
                    border-left: 2px solid $primary-color;
                    .mdd-content{
                        span{
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                    .drop-menu-right-arrow{
                        path{
                            stroke: $primary-color;
                            fill: none;
                        }
                    }
                }
            }
        }
        .h2-main-item:hover{
            .h2mi-home{
                .h2mi-icon-area{
                    // background-color: #f2f1fe;
                    background-color: $primary-w-less-opacity;
                    svg.homeIconSvg{
                        path{
                            fill: $primary-color;
                        }
                    }
                }
            }
            .hmi-img{
                // background-color: #f2f1fe;
                background-color: $primary-w-less-opacity;            
                .hmim-svg{
                    path{
                        fill: $primary-color;
                    }
                    .h2miia-stroke{
                        stroke: $primary-color;
                    }
                    .svg-stroke-primary {
                        stroke: $primary-color;
                    }
                    .svg-fill-none {
                        fill: none;
                    }
                }
            }
            .sub-menu{
                visibility: visible;
                opacity: 1;
            }
        }
        .h2-main-item:hover>.hmi-img .sub-menu{
            transform: translate(0);
        }
        .h2-main-item.active{
            .h2mi-home{
                .h2mi-icon-area{
                    // background-color: #f2f1fe;
                    background-color: $primary-w-less-opacity;
                    svg{
                        path{
                            fill: $primary-color;
                        }
                        .svg-stroke-none{
                            stroke: none;
                        }
                        .svg-fill-white{
                            fill: white;
                        }
                    }
                }
            }
            .hmi-img{
                // background-color: #f2f1fe;
                background-color: $primary-w-less-opacity;            
                .hmim-svg{
                    path{
                        fill: $primary-color;
                    }
                    .h2miia-stroke{
                        stroke: $primary-color;
                    }
                    .stroke-white{
                        fill: white;
                    }
                    .svg-stroke-none{
                        stroke: none;
                    }
                    .svg-stroke-primary {
                        stroke: $primary-color;
                    }
                    .svg-fill-none {
                        fill: none;
                    }
                    .svg-fill-white{
                        fill: white;
                    }
                }
            }
        }
        // .h2-main-item.active:hover{
        //     .h2mi-home{
        //         .h2mi-icon-area{
        //             // background-color: #f2f1fe;
        //             background-color: $primary-w-less-opacity;
        //             // svg{
                        
        //             //     path{
        //             //         fill: white;
        //             //     }
                        
        //             // }
        //         }
        //     }
        // }
    }
}
.sidebar-new.top-35{
    height: calc(100% - 35px);
}
.sidebar-new.top-70{
    height: calc(100% - 70px);
}
.sidebar-new.top-105{
    height: calc(100% - 105px);
}
.sidebar-new.top-140{
    height: calc(100% - 140px);
}
.last-sub-menu-bottom-up {
    bottom: 0;
    top: auto!important;
}
.sub-menu.last-sub-menu-bottom-up.top-new-pos {
    bottom: calc(100% - 200px);
}