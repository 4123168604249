@import '../variables.scss';

.sale-page-layout {
    width: 100%;
    display: flex;

    .sales-filter-section {
        width: 20%;
        background-color: #fff;
        height: calc(100vh - 60px);
        position: sticky;
        box-sizing: border-box;
        top: 60px;
        overflow: auto;

        .sfss-filter-top {
            position: sticky;
            top: 0;
            background: #fff;
            z-index: 1;
        }

        .sfss-search-area {
            padding: 5px 10px;
            display: flex;
            align-items: center;

            .generic-input-box {
                border: none;
                margin: 0;
                padding: 0;

                .gib-inner {
                    height: auto;
                    width: auto;
                    padding: 3px 2px;
                    border: none;

                    .sfsssa-icon {
                        margin-right: 5px;

                        svg {
                            width: 14px;
                            height: auto;

                            path {
                                fill: #0F172A;
                            }
                        }
                    }

                    input {
                        color: #0F172A;
                        font-size: 12px;
                        font-weight: 500;
                    }
                }
            }

            .sfss-clear {
                color: $primary-color;
                white-space: nowrap;
                font-size: 11px;
                font-weight: 500;
                margin-left: 5px;
            }
        }

        .sfs-checkbox-list {
            display: flex;
            flex-direction: column;
        }

        .generic-checkbox {
            margin: 3px 0;

            .checkmark1 {
                border-radius: 0;
            }
        }

        .generic-input-box {
            padding: 5px 10px;
            border-top: solid 1px #E7E8E8;
            margin-bottom: 8px;

            .gib-inner {
                border-radius: 0;

                .gsi-left-icon {
                    flex: 0 0 15px;
                }
            }

            .list-component-section {
                left: 10px;
                min-width: calc(100% - 20px);
                max-width: calc(100% - 20px);
            }
        }
    }

    .sales-filter-section>.generic-form-body {
        padding: 0;
        overflow: initial;
    }

    .sales-filter-section::-webkit-scrollbar {
        width: 0.1px;
    }

    .sales-filter-section::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
}

.splrs-top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;

    .splrstb-left>.generic-form-body {
        padding: 0;
        overflow: initial;
        height: auto;
        width: 100%;

        .gfb-inner {
            .generic-input-box {
                .gib-label-heading {
                    color: #888A8E;
                    font-size: 10px;
                    font-weight: 400;
                }
            }
        }
    }

    .splrstb-left {
        background-color: #fff;

        // width: 70%;
        .gfb-inner {
            flex-wrap: nowrap;

            .generic-input-box {
                width: auto;
                margin-bottom: 0;
                margin-right: 0;
                padding: 3px 10px;
                border-right: solid 1px #E7E8E8;

                .gib-label-heading {
                    .mandatory {
                        position: relative;
                        top: auto;
                    }
                }
            }

            .gib-inputButton {
                padding-left: 35px;

                .gib-inner {
                    .gibi-icon {
                        position: absolute;
                        left: -25px;
                        top: -14px;
                    }
                }

                .gen-input-disabled {
                    background-color: #fff;
                }

                .gib-dropdown:hover {
                    background-color: #fff;

                    input {
                        background-color: #fff;
                    }
                }
            }
        }

        .splrstb-down-arrow {
            padding: 10px 15px;
        }
    }

    .splrstb-right {
        display: flex;
        align-items: center;

        button {
            white-space: nowrap;
            display: flex;
            height: 47px;
            border-radius: 0;
            align-items: center;

            svg {
                fill: none !important;
            }

            .bag-count {
                display: inline-block;
                padding: 0px 10px;
                border-radius: 15px;
                background-color: #fff;
                margin-left: 10px;
                color: #0F172A;
                font-size: 11px;
            }
        }

        .splrstbr-setting {
            background: #fff;
            padding: 3px 12px;
            border-left: solid 1px #E7E8E8;
            position: relative;
        }

        .splrstbr-setting:hover {
            .generic-tooltip {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}

.splrstbl-scan {
    display: flex;
    align-items: center;
    height: 47px;
    background-color: #fff;
    padding: 3px 10px;
    position: relative;

    input {
        color: #888A8E;
        font-size: 12px;
        font-weight: 400;
        padding: 3px 5px;
    }

    select {
        background: rgba(217, 217, 217, 0.50);
        padding: 5px;
        color: #000;
        font-size: 12px;
        font-weight: 600;
        border: 1px solid transparent;
    }

    .splrstbl-dropdown {
        width: 355px;
        height: auto;
        background-color: #fff;
        border: 1px solid #e5e7eb;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05);
        position: absolute;
        top: 47px;
        left: 0;
        z-index: 1;

        .splrstbld-head {
            display: flex;
            background: #f8fafc;

            .splrstbldh-col {
                flex: 0 0 45%;
                padding: 10px 5px;

                label {
                    font-size: 11px;
                    font-weight: 400;
                    color: #0F172A;
                    margin: 0;
                }
            }

            .splrstbldh-first {
                flex: 0 0 10%;
            }
        }

        .splrstbld-body {
            max-height: 250px;
            overflow: auto;
            display: block;
            padding: 4px 5px;

            .splrstbldb-row {
                display: flex;

                .splrstbldb-col {
                    flex: 0 0 45%;
                    width: 100%;
                    padding: 10px 5px;

                    label {
                        margin: 0;
                        font-size: 12px;
                        font-weight: 500;
                        color: #0F172A;
                    }

                    button {
                        height: auto;
                        border: 1px solid #DAE4EE;
                        background: #FFF;
                        padding: 5px;
                    }

                    button:hover {
                        border: solid 1px $danger-color;

                        svg {
                            path {
                                stroke: $danger-color;
                            }
                        }
                    }

                    input {
                        border: solid 1px #dee;
                        color: #0F172A;
                        width: 100px;
                    }
                }

                .splrstbldh-first {
                    flex: 0 0 10%;
                }
            }
        }

        .splrstbld-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            .splrstbldf-left {
                p {
                    color: #7E8084;
                    font-size: 12px;
                    font-weight: 400;
                    margin: 0;
                    span {
                        color: #0F172A;
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }
            .splrstbld-right {
                display: flex;
                align-items: center;
            }
            button {
                height: 32px;
                margin-left: 10px;
            }
        }
    }
}

.spl-right-secttion {
    width: 80%;

    .splrs-top-area {
        width: 100%;
        position: sticky;
        top: 45px;
        z-index: 4;
        background: #f3f4f9;
    }
}

.splrs-card-section {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px 0;

    .no-data-found-screen {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 248px);
        margin-right: 10px;
        background-color: #fff;
    }

    .splrscs-card {
        padding-right: 10px;
        // flex: 0 0 20%;
        margin-bottom: 15px;
        width: 20%;
        transition: 0.6s all;

        .splrsmv-top {
            display: inline-block;
        }

        .splrsmv-bottom {
            display: none;
        }

        .splrscsc-inner {
            // box-shadow: 0 0 9px 0 #7a7a7a29;
            background-color: #fff;
            position: relative;

            .splrscsc-top {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                position: relative;
                overflow: hidden;
                width: 100%;
                height: 236px;
                position: relative;
                // transition: .2s;

                .splrscsct-img {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    position: relative;
                    overflow: hidden;
                    width: 100%;
                    height: 100%;

                    img {
                        width: 100%;
                        aspect-ratio: 3/2;
                        object-fit: cover;
                        height: 100%;
                    }
                }

                .splrscsc-no-img-found {
                    height: 100%;
                    width: 100%;
                    text-align: center;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background: #DFE1E6;

                    p {
                        margin: 0;
                        margin-top: 20px;
                        font-size: 12px;
                        font-weight: 700;
                        color: #afb6c2;
                        text-transform: uppercase;
                    }
                }

                .generic-checkbox {
                    position: absolute;
                    top: 12px;
                    left: 12px;
                    z-index: 3;

                    .checkmark1 {
                        border-radius: 0;
                    }
                }
            }

            .splrscsc-details {
                padding-inline: 12px;
                padding-top: 8px;
                padding-bottom: 5px;

                .splrscscd-info {
                    margin-bottom: 5px;
                    width: 100%;
                    position: relative;

                    .splrscsci-head {
                        display: block;
                        color: #74767A;
                        font-size: 10px;
                        font-weight: 400;
                    }

                    .splrscsci-para {
                        display: block;
                        color: #0F172A;
                        font-size: 12px;
                        font-weight: 500;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 100%;
                    }
                }
                .splrscscd-info:hover {
                    .generic-tooltip {
                        visibility: visible;
                        opacity: 1;
                    }
                }

                .splrscscd-tags {
                    display: inline-block;
                    color: #0F172A;
                    font-size: 11px;
                    font-weight: 600;
                    padding: 2px 6px;
                    background-color: #E3E4E7;
                    border-radius: 3px;
                    margin-right: 4px;
                }
            }

            .sb-bottom-extras {
                padding: 5px 10px;
                display: flex;
                width: 100%;

                button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .sbbe-delete {
                    border: 1px solid #DAE4EE;
                    background: #FFF;
                    margin-right: 8px;
                    width: 32px;
                    height: 32px;
                }

                .sbbe-delete:hover {
                    border: solid 1px $danger-color;

                    svg {
                        path {
                            stroke: $danger-color;
                        }
                    }
                }

                .sbbe-btn {
                    background: rgba(217, 217, 217, 0.50);
                    height: 32px;
                    padding: 3px 0 3px 5px;
                    width: calc(100% - 40px);
                    justify-content: space-between;

                    .sbber-text {
                        color: #0F172A;
                        font-weight: 600;
                        font-size: 11px;
                        white-space: nowrap;
                        max-width: 43px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    .sbber-mid {
                        padding: 3px 5px;
                        background-color: #fff;
                        border-radius: 12px;
                        color: #0F172A;
                        font-weight: 500;
                        font-size: 11px;
                        white-space: nowrap;
                    }

                    .sbbe-rightIcon {
                        height: 32px;
                        width: 32px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-left: solid 1px #DDDEDE;
                    }

                    .sbbe-rightIcon:hover {
                        background-color: #fff;
                        border: solid 1px #DDDEDE;
                    }
                }
            }

            .generic-approve-btn {
                display: none;
                width: 100%;
                border-radius: 0;
                height: 35px;
                margin-top: 1px;
                background-color: #fff !important;
                color: $primary-color !important;
                border: solid 2px $primary-color !important;
                outline: none;
                // max-height: 0;
                // overflow: hidden;
                // padding: 0 !important;
                // transition: .2s;
            }

            .generic-approve-btn:hover,
            .generic-approve-btn:focus {
                color: #fff !important;
                background-color: $primary-color !important;
            }
        }
    }

    .splrscsc-inner:hover {
        // transition: 0.6s all;

        .splrscsc-top {
            height: 190px;
        }

        .splrscsc-details {
            position: relative;
        }

        .generic-approve-btn {
            display: block;
            // max-height: 200px;
            // padding: 2px 15px !important;
        }
    }

    // .splrscs-card-grid,
    // .splrscs-card-list {
    //     .splrscsc-inner:hover {
    //         box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    //         transition: 0.4s all;
    //     }
    // }

    .splrscs-card-list {
        width: 100%;
        transition: none;

        .splrsmv-top {
            display: none;
        }

        .splrsmv-bottom {
            display: inline-block;
        }

        .splrscsc-inner {
            display: flex;
            padding: 10px;
            align-items: center;
            justify-content: space-between;

            .splrscsc-top {
                width: auto;
                display: flex;
                align-items: center;
                height: auto;

                .generic-checkbox {
                    position: relative;
                    top: auto;
                    left: auto;
                    margin-right: 10px;
                    padding-left: 16px;
                }

                .splrscsc-no-img-found {
                    height: 55px;
                    width: 55px;

                    p {
                        display: none;
                    }
                }
                .splrscsct-img {
                    img {
                        width: 55px;
                        height: 55px;
                        object-fit: contain;
                    }
                }
            }

            .splrscsc-details {
                display: flex;
                align-items: self-start;
                justify-content: flex-start;
                margin-left: 10px;
                width: calc(100% - 200px);

                .splrscscd-info {
                    margin-bottom: 0;
                    margin-right: 30px;
                    width: calc(100% - 600px);

                    .splrscsci-head {
                        white-space: nowrap;
                    }
                }

                .splrscscd-tags {
                    display: flex;
                    align-items: center;
                    padding: 1px 6px;

                    .splrscsci-para {
                        font-size: 11px;
                    }

                    span {
                        font-size: 11px;
                    }
                }
            }

            .sb-bottom-extras {
                width: 200px;
            }

            .generic-approve-btn {
                display: block;
                margin: 0;
                width: auto;
            }
        }
    }
}

.sale-search-section {
    margin: 10px 10px 0 0;
    padding: 10px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sss-left {
        display: flex;
        align-items: center;

        .generic-input-box {
            padding: 3px 5px;
            margin: 0;

            .gib-inner {
                height: 32px;
                width: 220px;
                border-radius: 0;
            }

            .sssl-search-icon {
                margin-right: 5px;

                svg {
                    width: 15px;

                    .cls-1 {
                        fill: #0F172A;
                    }
                }
            }

            input {
                padding: 0 5px;
                color: #67768E;
                font-size: 12px;
                font-weight: 400;
            }
        }

        .sssl-view-type {
            display: flex;
            margin-left: 10px;

            button {
                height: 32px;
                width: 32px;
                background-color: #F4F4F4;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    path {
                        stroke: #0F172A;
                    }
                }
            }

            .active {
                background-color: #0F172A;

                svg {
                    path {
                        stroke: #fff;
                    }
                }
            }
        }
    }

    .sss-right {
        display: flex;
        align-items: center;

        .total-records {
            padding-left: 15px;
            p {
                color: #7E8084;
                font-size: 12px;
                font-weight: 400;
                margin: 0;
            }

            h3 {
                color: #0F172A;
                font-size: 14px;
                font-weight: 600;
                margin: 0;
            }
        }

        .sssr-add-to-bag {
            border-radius: 0;
            height: 32px;
            color: #0F172A !important;
            text-transform: uppercase;
            padding: 3px 30px !important;
        }

        .sbbe-add-sizes {
            width: 32px;
            height: 32px;
            display: flex;
            border: solid 1px #DAE4EE;
            align-items: center;
            justify-content: center;
            margin-left: 15px;

            svg {
                path {
                    fill: #0F172A;
                }
            }
        }

        .sbbe-add-sizes:hover {
            background-color: #f5f5f5;
        }

        .sssshow-by {
            width: auto;
            font-size: 12px;
            color: #0F172A;
            font-weight: 600;
            padding: 3px 10px;

            .ssas-text {
                color: #7E8084;
                margin-right: 10px;
                font-weight: 400;
            }
        }
    }
}


.sales-add-to-bag {
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    overflow: auto;
    padding: 15px;

    .satb-left {
        width: 45%;

        .satbl-top {
            display: flex;
            height: 330px;

            .satbl-thumbnail-sec {
                margin-right: 20px;
                height: 100%;
                overflow-x: hidden;
                overflow-y: auto;
                flex: 0 0 70px;

                .satblts-img {
                    width: 70px;
                    height: 70px;
                    margin-bottom: 20px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        aspect-ratio: 3/2;
                        max-width: 100%;
                        max-height: 100%;
                    }

                    svg {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .satbl-thumbnail-sec::-webkit-scrollbar {
                width: 0.1px;
            }

            .satbl-thumbnail-sec::-webkit-scrollbar-thumb {
                background-color: transparent;
            }

            .satblt-mainImg {
                width: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    aspect-ratio: 3/2;
                    object-fit: cover;
                }

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .satb-right {
        width: 55%;
        padding-left: 15px;
        height: 100%;
        overflow: auto;

        .satbr-top {
            h3 {
                color: #0F172A;
                font-size: 15px;
                font-weight: 600;
            }

            p {
                color: #0F172A;
                font-size: 12px;
                font-weight: 300;
            }

            .satbrt-rate-list {
                display: flex;
                align-items: center;

                span {
                    margin-right: 5px;
                    font-size: 13px;
                    font-weight: 600;
                }

                .satbrtrl-mrp {
                    color: #A6A8AC;
                    font-weight: 400;
                    text-decoration-line: line-through;
                }

                .satbrtrl-off {
                    font-size: 9px;
                    color: #fff;
                    padding: 3px 4px;
                    border-radius: 4px;
                    background-color: #0F172A;
                }
            }
        }
    }

    .satb-right::-webkit-scrollbar {
        width: 0.1px;
    }

    .satb-right::-webkit-scrollbar-thumb {
        background-color: transparent;
    }
}

.sales-bag-outer {
    width: 100%;
    height: 100%;

    .sbo-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
        border-top: solid 1px #E5E7EB;

        button {
            border-radius: 0;
            height: 40px;
        }

        .download-pdf {
            display: flex;
            align-items: center;
            color: #0F172A !important;
            background-color: #fff !important;
            border: solid 1px #E5E7EB;

            svg {
                margin-left: 10px;

                path {
                    fill: #0F172A;
                }
            }
        }

        .generic-approve-btn {
            width: 400px;
            margin-left: 15px;
        }
    }
}

.satbrt-sizes-list {
    .satbrtsl-row {
        display: flex;
        align-items: center;
        border: 1px solid #DAE4EE;
        margin-bottom: 10px;
        justify-content: space-between;

        .satbrtslr-left {
            display: flex;
            align-items: center;
            padding: 7px 5px;
            border-right: solid 1px #DAE4EE;
            padding-right: 20px;

            .satbrtslrl-size {
                color: #7E8084;
                font-size: 11px;
                font-weight: 500;
                margin-right: 5px;
                min-width: 100px;
                max-width: 130px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .satbrtslrl-input {
                display: flex;
                align-items: center;

                .generic-input-box {
                    margin: 0;

                    .gib-inner {
                        border: none;
                        height: auto;

                        input {
                            padding: 0;
                            width: 60px;
                            text-align: center;
                        }
                    }
                }
            }
        }

        .satbrtslr-right {
            display: flex;
            padding: 0 10px;
            justify-content: flex-end;

            .gen-tag {
                margin-left: 5px;
            }

            .satbrtslrr-instock {
                background-color: #03B675;
                color: #fff;
            }

            .satbrtrl-mrp {
                color: #A6A8AC;
                font-weight: 400;
                padding: 0;
                margin-left: 5px;
                text-decoration-line: line-through;
            }

            .satbrtrl-off {
                color: #fff;
                background-color: #0F172A;
            }
        }
    }

    .gen-tag {
        padding: 1px 5px;
        font-size: 12px;
        font-weight: 500;
        background-color: #E5E7EB;
        color: #0F172A;
    }
}

.satbrt-color-drop {
    display: flex;
    align-items: flex-end;

    .generic-input-box {
        width: 220px;
        margin-right: 15px;

        .gib-inner {
            border-radius: 0;
        }

        .list-component-section {
            top: 37px;
            border-radius: 0;
        }
    }
    .satbrtcd-input {
        .list-component-section {
            top: 65px;
        }
    }
}

.satbl-details-section {
    .generic-tabs {
        width: auto;
        border: none;

        .gt-list {
            text-transform: uppercase;
            margin: 0;
            padding: 10px 15px;
            border-bottom: solid 2px #E5E7EB;
            font-weight: 600;
            color: #0F172A;
        }

        .activeTab {
            color: $primary-color;
            border-bottom: solid 2px $primary-color;
        }
    }

    .satblds-details {
        padding: 15px 0;

        .satbldsd-row {
            display: flex;

            span {
                min-width: 80px;
            }

            span,
            p {
                color: #0F172A;
                font-size: 12px;
                font-weight: 300;
            }

            p {
                font-weight: 500;
                margin-left: 5px;
                white-space: nowrap;
                max-width: 300px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.sales-multiple-product-add {
    width: 100%;
    height: 100%;

    .modal-header-section {
        border-bottom: solid 1px #E5E7EB;

        .mhs-left {
            h4 {
                color: #0F172A;
                font-size: 15px;
                font-weight: 600;
            }
        }
    }

    .smpa-body {
        padding: 15px;
        width: 100%;
        overflow: auto;
        height: calc(100% - 103px);

        .smpab-inner {
            border: 1px solid #E5E7EB;
            display: flex;
            width: 100%;
            height: 100%;

            .smpab-left {
                display: flex;
                flex-direction: column;
                width: 35%;
                flex: 0 0 35%;
                border-right: solid 1px #E5E7EB;
                height: 100%;
                overflow: auto;

                .smpabl-row {
                    padding: 5px;
                    border-bottom: solid 1px #E5E7EB;
                    border-right: solid 3px transparent;

                    .smpablrbc-strip {
                        padding: 0px 5px;
                        background-color: #2969FF;
                        color: #fff;
                        display: inline-flex;
                        font-size: 10px;
                        font-weight: 500;
                        align-items: center;
                        margin-top: 5px;

                        svg {
                            margin-right: 5px;
                        }
                    }

                    p {
                        color: #74767A;
                        font-size: 9px;
                        font-weight: 400;
                        margin: 0;
                    }

                    h3 {
                        color: #0F172A;
                        font-size: 12px;
                        font-weight: 600;
                        margin: 0;
                    }

                    .smpablr-bottom {
                        display: flex;
                        align-items: center;
                        margin-top: 5px;

                        .smpablrb-col {
                            width: 50%;
                        }

                        h3 {
                            font-weight: 400;
                        }
                    }
                }

                .smpabl-row:hover,
                .smp-active {
                    background: rgba(93, 95, 239, 0.05);
                    border-right: solid 3px $primary-color;
                    cursor: pointer;
                }
            }

            .smpab-right {
                width: 65%;
                flex: 0 0 65%;
                height: 100%;

                .satbrtcd-input {
                    padding: 0 10px;
                    width: 50%;
                    .gib-inner {
                        border-radius: 0;
                    }
                    .list-component-section {
                        border-radius: 0;
                        width: calc(100% - 20px);
                        min-width: auto;
                        left: 10px;
                    }
                }

                .satbrt-sizes-list {
                    height: calc(100% - 61px);
                    overflow: auto;
                    padding: 10px;
                }
            }

            .satbrt-sizes-list::-webkit-scrollbar,
            .smpab-left::-webkit-scrollbar {
                width: 0.1px;
            }

            .satbrt-sizes-list::-webkit-scrollbar-thumb,
            .smpab-left::-webkit-scrollbar-thumb {
                background-color: transparent;
            }
        }
    }

    .smpabr-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 15px 10px;

        // border-top: solid 1px #E5E7EB;
        button {
            border-radius: 0;
            height: 40px;
            margin-left: 15px;
        }

        .download-pdf {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #0F172A !important;
            background-color: #fff !important;
            border: solid 1px #E5E7EB;
            width: 150px;
        }

        .generic-approve-btn {
            width: 250px;
        }
    }
}

// Sales Skeleton 
.sales-skeleton {
    width: 20%;
    height: calc(100vh - 111px);
    background-color: #fff;
    margin-top: 15px;
    padding: 10px;
    overflow: hidden;

    .ss-filter {
        padding: 10px 0;
    }
}

.sales-product-grid,
.sales-product-list,
.sales-product-matrix {
    width: 100%;
    height: calc(100vh - 183px);
    background-color: #fff;
    padding: 10px 0 10px 10px;
    overflow: hidden;

    .sps-cards {
        width: 20%;
        padding: 0 10px 0px 0;

        .generic-skeleton {
            height: 240px;
            border-radius: 0;
        }
    }
}

.sales-product-list,
.sales-product-matrix {
    .sps-cards {
        width: 100%;

        .generic-skeleton {
            height: 70px;
        }
    }
}

.sales-product-matrix {
    .sps-cards {
        .generic-skeleton {
            height: 55px;
            margin-bottom: 3px;
        }
    }

    .sps-cards:nth-child(1) {
        .generic-skeleton {
            height: 37px;
        }
    }
}

// Add to Bag Modal Skeleton
.bag-skeleton {
    width: 100%;
    height: 100%;

    .bs-img {
        height: 50%;
        padding: 15px;

        .bsi-svg {
            width: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .generic-skeleton {
                width: 70px;
                height: 70px;
                border-radius: 0;
            }

            .generic-skeleton:nth-last-child(1) {
                margin: 0;
            }
        }

        .bsi-img {
            width: 30%;
            padding-left: 15px;

            .generic-skeleton {
                width: 100%;
                height: 100%;
                margin: 0;
                border-radius: 0;
            }
        }

        .bsi-det {
            width: 60%;
            height: 100%;
            padding-left: 15px;
        }
    }

    .bs-mid {
        height: 30%;
        padding: 15px 15px 0;

        .generic-skeleton {
            width: 30%;
        }
    }

    .bs-bot {
        height: 20%;
        border-top: solid 1px #E5E7EB;
        padding: 15px;
        display: flex;
        justify-content: space-between;

        .generic-skeleton {
            width: 30%;
            margin: 0;
        }
    }

    .bsk-head {
        width: 100%;
        height: 10%;
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .generic-skeleton {
            margin: 0;
        }
    }

    .bsk-mid {
        width: 100%;
        height: 80%;
        display: flex;
        padding: 15px 15px 15px;
        overflow: hidden;

        .bsk-mid1,
        .bsk-mid2 {
            width: 50%;

            .generic-skeleton {
                height: 70px;
            }
        }

        .bsk-mid2 {
            padding-left: 15px;
        }
    }

    .bsk-bot {
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: end;
        border-top: solid 1px #E5E7EB;
        padding: 15px 15px 0 0;
    }
}

// Style Manage Catalogue Table
.od-total {
    display: flex;
    align-items: center;
    padding: 10px 15px;

    .odt {
        padding-right: 15px;

        h1 {
            color: #bdbdbd;
            font-size: 13px;
            font-weight: 700;
            margin: 0;
        }

        p {
            font-size: 13px;
            font-weight: 700;
            color: #21314b;
            margin: 0;
        }
    }
}

.product-list-table {
    position: relative;
    display: block;
    padding: 10px 15px;
    background-color: #fff;

    .plt-manage-table {
        max-height: calc(100vh - 266px);
        overflow: auto;
        box-shadow: 2px 0 6px 0 hsla(0, 0%, 55%, .16);

        .plt-main-table {
            display: table;
            white-space: nowrap;
            border-collapse: collapse;
            margin: 0;
            border-spacing: 0;

            thead {
                position: sticky;
                top: 0;
                background: #fff;
                z-index: 1;
                border: none;
                box-shadow: 2px 0 6px 0 hsla(0, 0%, 55%, .16);

                tr {
                    height: 35px;
                    background-color: #fff;

                    th {
                        height: 42px;
                        vertical-align: middle;
                        white-space: nowrap;
                        border: none;
                        padding: 8px;

                        label {
                            font-size: 12px;
                            margin: 0;
                            font-weight: 600;
                            color: #000000;
                            width: auto;
                        }

                        img {
                            margin-left: 15px;
                            cursor: pointer;
                        }
                    }
                }
            }

            tbody {
                tr {
                    height: 35px;

                    td {
                        height: 35px;
                        white-space: nowrap;
                        vertical-align: middle;
                        border: 0.5px solid #e3e7f3;
                        position: relative;
                        padding: 8px;

                        label {
                            color: #000;
                            margin: 0;
                            font-size: 12px;
                            font-weight: 500;
                            white-space: nowrap;
                            max-width: 200px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            vertical-align: middle;

                            .genmt-rupee-sign {
                                margin-right: 5px;
                                font-family: system-ui;
                                font-weight: 400;
                            }

                        }

                        .genmt-rupee-sign {
                            margin-right: 5px;
                            color: #000;
                            font-weight: 400;
                            font-family: system-ui;
                            font-size: 11px;
                        }

                        .vso-process {
                            font-weight: 600;
                            color: #2681ff;
                        }

                        .vso-complete {
                            font-weight: 600;
                            color: #00af4a;
                        }

                        .plt-color {
                            display: flex !important;
                            align-items: center;
                        }

                        .plt-stock {
                            color: #6a58d0;
                            font-weight: 600;
                        }
                    }

                    td:first-child {
                        border-left: none;
                    }

                    td:nth-child(2) {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        padding: 0;

                        .td-img {
                            padding: 8px;
                            cursor: pointer;

                            img {
                                max-width: 30px;
                            }
                        }
                    }
                }

                tr:first-child {
                    td {
                        border-top: none;
                    }
                }
            }
        }
    }
}

.viewproductDetails{
    .ant-wave-target{
        display: none;
    }
    .ant-radio-group {
        margin-top: 1rem;
        // color: #21314b;
        width: 100%;
        // font-size: 12px !important;
        // font-weight: 600 !important;
        // width: fit-content;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // background-color: #ebebf3;
        background-color: #ebebf3;
        border-radius: 5px;
        padding: 0 3px;

        span{
            font-size: 12px;
        }
        .ant-radio-button-wrapper {
            width: 100%;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border-radius: 3px;
            font-size: 12px;
            font-weight: 600;
            color: #21314b;
            border: none;
            padding: 0 10px;
            white-space: nowrap;
        }

        .ant-radio-button-wrapper-checked {
            background-color: #fff;
            border: none;
        }

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
            display: none;
        }

        .ant-radio-button-wrapper:not(:first-child)::before {
            display: none;
        }
    }

    .ant-radio-wrapper{
        width: 100% !important;
        text-align: center !important;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0 !important;
        // padding-inline: 1rem !important;
        span{
            font-size: 12px !important;
        }
    }

    .ant-radio-wrapper-checked,
    .ant-radio-button-wrapper-checked {
        background-color: #fff;
        border: none;
    }
}

.draftDownloadDoc{
    .download-document-dropdown{
        position: fixed;
        top: 83px;
        right: 15px;
    }
    button{
        border-radius: 0 !important;
    }
}

.btn-without-rounded {
    button{
        border-radius: 0 !important;
    }
}

// .wishlistBtn{
//     button{
//         svg{
//             margin-right: 4px !important;
//         }
//     }
// }

.dprtr-wishlist {
    svg{
        margin-right: 5px;
    }
}