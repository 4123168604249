@import '../variables.scss';


.vendor-onboarding{
    width: 100vw;
    height: 100vh;
    background-color: #f3f4f9;
    .vo-top{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        padding: 15px;
        margin-bottom: 12px;
        .vot-left{
            width: 25%;
            margin-right: 12px;
        }
        .vod-mid{
            width: 75%;
            display: flex;
            .vodm-sec{
                padding-right: 30px;
                .vodms-top{
                    display: flex;
                    svg{
                        margin-right: 10px;
                    }
                    label{
                        width: 100%;
                        color: #0F172A;
                        font-size: 16px;
                        font-weight: 500;
                        margin-bottom: 0;
                    }
                    span{
                        color: #0F172A;
                        font-size: 12px;
                        font-weight: 600;
                    }
                }
                .vodms-bot{
                    .ant-progress {
                        .ant-progress-outer{
                            .ant-progress-inner{
                                bottom: -18px;
                            }
                        }
                        .ant-progress-text{
                            display: none;
                        }
                    }
                }
            }
        }
        .vot-right{
            display: flex;
            align-items: center;
            button{
                display: flex;
                align-items: center;
                margin-left: 10px;
            }
            .skip-btn{
                height: 35px;
                color: #0F172A;
                font-size: 13px;
                font-style: normal;
                font-weight: 600;
                border-radius: 4px;
                border: 2px solid #C7D3DF;
                padding: 0 15px;
                margin-left: 10px;
            }
            .skip-btn:hover{
                background-color: #C7D3DF;
            }
            .generic-form-footer{
                padding: 0;
                .gff-right{
                    width: 100%;
                    button:nth-child(1){
                        background: #fff !important;
                        color: $primary-color !important;
                        svg{
                            transform: rotate(180deg);
                            margin-right: 5px;
                            path{
                                fill: $primary-color;
                            }
                        }
                    }
                    button:nth-child(1):hover{
                        background: $primary-color !important;
                        color: #fff !important;
                        svg{
                            path{
                                fill: #fff;
                            }
                        }
                    }
                    button:nth-child(2){
                        svg{
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }
    .vo-body, .vo-body-summary{
        height: calc(100% - 89px);
        display: flex;
        padding: 0 12px;
        .vob-left{
            width: 25%;
            height: 100%;
            background-color: #fff;
            padding: 10px 15px;
            margin-right: 12px;
            h1{
                color: #0F172A;
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 10px;
            }
            .vobl-body{
                height: calc(100% - 43px);
                overflow: auto;
                padding-right: 10px;
                .voblb-sec{
                    padding: 15px 0;
                    .voblbs-top{
                        display: flex;
                        align-items: center;
                        padding-bottom: 10px;
                        svg{
                            margin-right: 10px;
                        }
                        h3{
                            color: #0F172A;
                            font-size: 16px;
                            font-weight: 600;
                            margin: 0;
                        }

                    }
                    .voblbs-det{
                        position: relative;
                        display: flex;
                        padding: 5px 0 0;
                        .voblbsd-icon{
                            width: 21px;
                            height: 21px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: relative;
                            margin: 5px 10px 0 0;
                            z-index: 1;
                        }
                        .voblbsd-sec{
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 5px 10px 5px 0;
                            h4{
                                color: #0F172A;
                                font-size: 13px;
                                font-weight: 500;
                                white-space: nowrap;
                            }
                            .voblbsds-not-started{
                                color: #0F172A;
                                font-size: 11px;
                                font-weight: 500;
                                margin: 0;
                            }
                            .ant-progress{
                                .ant-progress-outer{
                                    display: flex;
                                    padding: 0;
                                }
                                .ant-progress-text{
                                    color: #0F172A;
                                    font-size: 11px;
                                    font-weight: 600;
                                    margin: 0;
                                }
                            }
                            .voblbsds-edit-btn{
                                display: flex;
                                align-items: center;
                                border: 1px solid #0F172A;
                                color: #0F172A;
                                font-size: 11px;
                                font-weight: 600;
                                padding: 2px 5px;
                                svg{
                                    margin-right: 5px;
                                }
                            }
                            .voblbsds-edit-btn:hover{
                                background-color: #0F172A;
                                color: #ffff;
                                svg{
                                    path{
                                        stroke: #fff;
                                    }
                                }
                            }
                        }
                        .voblbsd-active{
                            border-radius: 6px;
                            background: rgba(93, 95, 239, 0.10);
                            padding: 5px 10px;
                            h4{
                                color: $primary-color;
                            }
                            .voblbsds-edit-btn{
                                color: $primary-color;
                                border-color: $primary-color;
                                svg{
                                    path{
                                        stroke: $primary-color;
                                    }
                                }
                            }
                            .voblbsds-edit-btn:hover{
                                background-color: $primary-color;
                                svg{
                                    path{
                                        stroke: #fff;
                                    }
                                }
                            }
                        }
                    }
                    .voblbs-det::before{
                        content: "";
                        width: 1px;
                        height: 100%;
                        position: absolute;
                        top: 16px;
                        left: 10px;
                        background: #E3E3E3;
                    }
                    .voblbs-det:nth-last-child(1)::before{
                        display: none;
                    }
                }
            }
        }
        .vob-right{
            width: 75%;
            height: 100%;
            overflow: auto;
            background-color: #fff;
            padding: 10px 15px;
            .vobr-sec, .vobrs-input-sec, .vobr-sec-sel{
                padding-bottom: 20px;
                h3{
                    width: 100%;
                    color: #0F172A;
                    font-size: 16px;
                    font-weight: 600;
                    margin: 0;
                }
                .error-message{
                    font-size: 11px;
                    font-weight: 500;
                    color: #F53B57;
                    margin: 5px 0 0;
                }
                .vobrs-checkbox{
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 10px;
                    padding-top: 10px;
                    h2{
                        width: 100%;
                        color: #0F172A;
                        font-size: 12px;
                        font-weight: 500;
                    }
                    .vobrsc{
                        border-radius: 3px;
                        border: 1px solid #C7D3DF;
                        padding: 3px 7px;
                        .generic-checkbox{
                            padding-left: 20px;
                        }
                    }
                }
                .vobrs-sel{
                    width: 100%;
                    padding: 10px 0;
                    .ant-select {
                        width: 50%;
                        border-radius: 3px;
                        .ant-select-selector{
                            .ant-select-selection-overflow{
                                .ant-select-selection-overflow-item{
                                    .ant-select-selection-item{
                                        border-radius: 70px;
                                        background: #ECF3FF;
                                        .ant-select-selection-item-content{
                                            color: #116FFF;
                                            font-size: 12px;
                                            font-weight: 500;
                                        }
                                        .ant-select-selection-item-remove{
                                            .anticon {
                                                svg{
                                                    fill: #116FFF;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .ant-select-focused{
                        box-shadow: 0px 0px 0px 2px #DADAFF;
                        .ant-select-selector{
                            border-color: $primary-color !important;
                        }
                    }
                    .ant-select:hover{
                        .ant-select-selector{
                            border-color: #C7D3DF;
                        }
                    }
                }
                .vobrs-btns{
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 15px;
                    padding-top: 10px;
                    button{
                        min-width: 100px;
                        height: 90px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        color: #0F172A;
                        font-size: 12px;
                        font-weight: 500;
                        border-radius: 3px;
                        border: 1px solid #C7D3DF;
                        padding: 0 10px;
                        svg{
                            margin-bottom: 5px;
                        }
                    }
                    button:hover, .btn-active{
                        border-color: $primary-color;
                        color: $primary-color;
                        svg{
                            path{
                                fill: $primary-color;
                            }
                        }
                        .shirt-icon{
                            path{
                                fill: snow;
                                stroke: $primary-color;
                            }
                        }
                    }
                }
                .vobrs-input{
                    width: 100%;
                    display: flex;
                    padding-top: 10px;
                    .generic-input-box{
                        width: 100%;
                        margin: 0;
                        .gib-label-heading{
                            color: #0F172A;
                            font-size: 12px;
                            font-weight: 500;
                        }
                        ul{
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                            gap: 5px;
                            padding-top: 10px;
                            li{
                                width: fit-content;
                                display: flex;
                                align-items: center;
                                border-radius: 10px;
                                background: #ECF3FF;
                                padding: 3px 5px 3px 8px;
                                p{
                                    font-size: 12px;
                                    font-weight: 500;
                                    margin: 0;
                                    color: #116FFF;
                                }
                                button{
                                    padding: 0 3px;
                                    svg{
                                        path{
                                            stroke: #116FFF;
                                        }
                                    }
                                }
                            }
                        }
                        .list-component-section {
                            max-height: 170px;
                            top: 63px;
                            .lcs-inner{
                                max-height: 120px;
                            }
                        }
                    }
                    .vobrsi-brand {
                        width: 50%;
                    }
                }
                .vobrs-upload{
                    width: 50%;
                    padding-top: 15px;
                    .generic-input-box{
                        margin: 0;
                        .gib-label-heading{
                            color: #0F172A;
                            font-size: 12px;
                            font-weight: 500;
                        }
                        .gen-upload-file{
                            min-width: 100%;
                            right: auto;
                            left: 0;
                            top: 82px;
                        }
                    }
                }
            }
            .vobrs-input-sec{
                display: flex;
                flex-wrap: wrap;
                .vobrs-input{
                    width: 50%;
                    padding: 15px 15px 0 0;
                }
                .vobrs-checkbox{
                    width: 100%;
                }
            }
            .vobr-sec-sel{
                .vobrs-sel{
                    // display: flex;
                    flex-wrap: wrap;
                    .ant-select{
                        margin-bottom: 15px;
                        display: block;
                    }
                    .vobrss-box{
                        min-width: 200px;
                        border-radius: 3px;
                        border: 1px solid #C7D3DF;
                        margin-right: 15px;
                        display: inline-block;
                        .vobrssb-top{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 5px 5px 5px 10px;
                            h2{
                                color: #116FFF;
                                font-size: 14px;
                                font-weight: 700;
                                margin: 0;
                            }
                            button{
                                padding: 5px;
                                svg{
                                    width: 19px;
                                    height: 19px;
                                }
                            }
                            button:hover{
                                background-color: #f5f5f5;
                            }
                        }
                        .vobrssb-mid{
                            border-top: 1px solid #C7D3DF;
                            border-bottom: 1px solid #C7D3DF;
                            padding: 0px 10px 10px;
                            .generic-input-box {
                                margin: 0;
                                .gib-label-heading{
                                    font-size: 9px;
                                    font-weight: 500;
                                    margin-bottom: 3px;
                                }
                                .gib-date {
                                    height: 22px;
                                    border-radius: 6px;
                                    border: 1px solid #0F172A;
                                    .ant-picker {
                                        height: 20px;
                                        border-radius: 6px;
                                        .ant-picker-input{
                                            input{
                                                color: #0F172A;
                                                font-size: 11px;
                                                font-weight: 500;
                                            }
                                            .ant-picker-suffix{
                                                .anticon {
                                                    svg{
                                                        fill: #0F172A;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .vobrssb-bot{
                            padding: 5px 10px;
                        }
                    }
                }
            }
        }
    }
    .vo-body-summary{
        height: calc(100% - 112px);
        .vob-left{
            overflow: auto;
            .vobl-top{
                border-radius: 10px;
                border: 1px solid #E1E1E1;
                padding: 10px 15px;
                margin-bottom: 15px;
                h1{
                    color: #0F172A;
                    font-size: 17px;
                    font-weight: 700;
                    margin: 15px 0 5px;
                }
                p{
                    color: #0F172A;
                    font-size: 12px;
                    font-weight: 400;
                    margin: 0;
                }
            }
            .vobl-bot{
                .voblb-head,.voblb-body{
                    padding: 15px;
                    text-align: center;
                    margin-bottom: 2px;
                    background: linear-gradient(357deg, rgba(252, 178, 0, 0.15) 59.28%, rgba(27, 89, 248, 0.00) 282.66%);
                    border-radius: 10px 10px 0 0;
                    h2{
                        color: #0F172A;
                        font-size: 18px;
                        font-weight: 700;
                        margin-bottom: 3px;
                    }
                    p{
                        color: #0F172A;
                        font-size: 14px;
                        font-weight: 400;
                        margin: 0;
                    }
                }
                .voblb-body{
                    border-radius: 0 0 10px 10px;
                    .ant-progress{
                        .ant-progress-inner{
                            .ant-progress-circle{
                                .ant-progress-circle-trail {
                                    stroke: #b5c2e3;
                                    stroke-width: 30px;
                                }
                                .ant-progress-circle-path{
                                    stroke-width: 30px;
                                    stroke: #FAD271 !important;
                                }
                            }
                        }
                    }
                    h2{
                        font-size: 26px;
                        margin: 15px 0 0;
                    }
                    p{
                        font-size: 13px;
                    }
                    button{
                        color: #FAD271;
                        font-size: 14px;
                        font-weight: 600;
                        border-radius: 4px;
                        border: 2px solid #0F172A;
                        background: #0F172A;
                        padding: 5px 50px;
                        margin-top: 20px;
                    }
                    button:hover{
                        opacity: 0.9;
                    }
                }
            }
        }
        .vob-right{
            .vobr-sec{
                border-radius: 10px;
                border: 1px solid #E1E1E1;
                margin-bottom: 15px;
                padding: 0;
                .vobrs-top{
                    padding: 15px;
                    border-bottom: 1px solid #E1E1E1;
                    h3{
                        color: #0F172A;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                    }
                    .vobrst-timeline{
                        display: flex;
                        align-items: center;
                        padding: 15px 0 5px;
                        .vobrstt{
                            position: relative;
                            padding-right: 50px;
                            span{
                                color: #0F172A;
                                font-size: 13px;
                                font-weight: 400;
                                border-radius: 30px;
                                padding: 1px 10px;
                                position: relative;
                                z-index: 1;
                                background: #F0F0F0;
                            }
                        }
                        .vobrstt::before{
                            content: "";
                            width: 100%;
                            height: 1px;
                            position: absolute;
                            top: 10px;
                            left: 0;
                            background: #DCDCDC;
                        }
                        .vobrstt:nth-last-child(1)::before{
                            display: none;
                        }
                    }
                }
                .vobrs-bot{
                    padding: 10px 15px;
                    button{
                        display: flex;
                        align-items: center;
                        color: #0F172A;
                        font-size: 14px;
                        font-weight: 600;
                        border-radius: 4px;
                        border: 1px solid #0F172A;
                        padding: 5px 20px;
                        svg{
                            width: 25px;
                            height: 25px;
                            margin-left: 5px;
                            g{
                                path{
                                    fill: #0F172A;
                                }
                            }
                        }
                    }
                    button:hover{
                        background-color: #0F172A;
                        color: #fff;
                        svg{
                            g{
                                path{
                                    fill: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.vendor-onboarding-dashboard {

    .voblb-body{
                    border-radius: 0 0 10px 10px;
                    .ant-progress{
                        .ant-progress-inner{
                            .ant-progress-circle{
                                .ant-progress-circle-trail {
                                    stroke: #b5c2e3;
                                    stroke-width: 30px;
                                }
                                .ant-progress-circle-path{
                                    stroke-width: 30px;
                                    stroke: #FAD271 !important;
                                }
                            }
                        }
                    }
                    h2{
                        font-size: 26px;
                        margin: 15px 0 0;
                    }
                    p{
                        font-size: 13px;
                    }
                    button{
                        color: #FAD271;
                        font-size: 14px;
                        font-weight: 600;
                        border-radius: 4px;
                        border: 2px solid #0F172A;
                        background: #0F172A;
                        padding: 5px 50px;
                        margin-top: 20px;
                    }
                    button:hover{
                        opacity: 0.9;
                    }
            }
}


.vendor-signup{
    .generic-input-box{
        .gib-inner{
            height: auto;
            padding-top: .56rem;
            padding-bottom: .56rem;
        }
    } 

    .generic-approve-btn{
        height: auto;
        padding-top: .56rem !important;
        padding-bottom: .56rem !important;
    }
}

.input-svg{
    svg{
        width: 20px;
        height: 20px;
        path{
            stroke-width: 1.25px;
        }
    }    
}

.vendorOnboardingRequestModal{
    .generic-input-box{

        .gib-label-heading{
            color: black;
        }

        .gib-inner{
            border-radius: 0 !important;
            height: auto;
            padding-top: .5rem;
            padding-bottom: .5rem;

            input::placeholder{
            font-weight: normal;
            }
        }
    }

    .modal-header-section {
        align-items: start;
        .mhs-left {
            p{
                margin-top: 0.5rem;
                font-weight: 400;
            }
        }
    }

    .green-tick{
        svg{
            height: 40px;
            width: 40px;
        }
    }
} 

.expand-collapse-icon{
    svg{
        height: 20px;
        width: 20px;
    }
}

.supplierDetailsModal {

    .supplierDetailsModalicons{
        svg{
            height: 20px;
            width: 20px;
        }
        button:hover{
            svg{
                stroke: $primary-color;
                path{
                    stroke: $primary-color;
                    fill: $primary-color;
                }
            }
        }
    }
    

    // .generic-tabs{
    //     .gt-list{
    //         color: #0F172A;
    //         font-weight: 600 !important;
    //     }
    // }

    // .activeTab{
    //     color: $primary-color !important;
    //     border-bottom: 2px solid $primary-color !important;
    // }

}