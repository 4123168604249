@import './variables.scss';

// Modal Animations

// From Top
@keyframes slideInFromTop {
    0% {
        transform: translateY(-5%);
        // opacity: 1;
    }

    100% {
        transform: translateY(0);
        // opacity: 0.5;
    }
}

@keyframes slideOutFromTop {
    0% {
        transform: translateY(0);
        // opacity: 0.5;
    }

    100% {
        transform: translateY(-5%);
        // opacity: 1;
    }
}

.from-top-in {
    animation: slideInFromTop 0.2s ease-out;
}

.from-top-out {
    animation: slideOutFromTop 0.2s ease-out;
}

// From Bottom
@keyframes slideInFromBottom {
    0% {
        // transform: translate(-50%, -45%);
        transform: translateY(5%);
        opacity: 0.5;
    }

    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes slideOutFromBottom {
    0% {
        transform: translateY(0%);
        opacity: 1;
    }

    100% {
        transform: translateY(5%);
        opacity: 0.5;
    }
}

.from-bot-in {
    animation: slideInFromBottom 0.2s ease-out;
}

.from-bot-out {
    animation: slideOutFromBottom 0.2s ease-out;
}

// From Left
@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideOutFromLeft {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

.from-left-in {
    animation: slideInFromLeft 0.2s ease-out;
}

.from-left-out {
    animation: slideOutFromLeft 0.2s ease-out;
}

// From Right
@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideOutFromRight {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}

.from-right-in {
    animation: slideInFromRight 0.2s ease-out;
}

.from-right-out {
    animation: slideOutFromRight 0.2s ease-out;
}

// For Center
@keyframes scaleIn {
    from {
        transform: scale(0.9);
        opacity: 0.5;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes scaleOut {
    from {
        transform: scale(1);
        opacity: 1;
    }

    to {
        transform: scale(0.9);
        opacity: 0.5;
    }
}

.from-center-in {
    animation: scaleIn 0.2s ease-out;
}

.from-center-out {
    animation: scaleOut 0.2s ease-out;
}

// move up
@keyframes moveup {
    from {
        // transform: scale(0);
        top: 100%;
        opacity: 1;
    }

    to {
        // transform: scale(1);
        top: 50%;
        opacity: 0.5;
    }
}

@keyframes movedown {
    from {
        // transform: scale(1);
        top: 50%;
        opacity: 0.5;
    }

    to {
        // transform: scale(0);
        top: 100%;
        opacity: 1;
    }
}

.start-moveup {
    animation: moveup 0.2s ease-out;
}

.close-moveup {
    animation: movedown 0.2s ease-out;
}

// align Bottom
@keyframes from-bot {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes to-bot {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(100%);
    }
}

.from-bot {
    animation: from-bot 0.3s ease-out;
}

.to-bot {
    animation: to-bot  0.3s ease-out;
}

@keyframes dot-flash {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
}

.animate-dot-flash {
    animation: dot-flash 1.4s infinite ease-in-out;
  }
  .anim-delay-200 {
    animation-delay: 0.2s;
  }
  .anim-delay-400 {
    animation-delay: 0.4s;
  }

// ------------------------------------SKELETON LOADER------------------------------------------------------
// Generic Skeleton
.generic-skeleton {
    height: 18px;
    background: linear-gradient(100deg,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 60%) #ebebf3;
    background-size: 200% 100%;
    background-position-x: 180%;
    border-radius: 5px;
    animation: 1s loadskeleton ease-in-out infinite;
    margin-bottom: 10px;
}

.listComponentHover {
    background-color: "red";
}

.generic-tooltip {
    display: block;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: -33px;
    left: 50%;
    min-width: 66px;
    transition: all 0.2s;
    border-radius: 5px;
    padding: 4px 6px;
    font-size: 11px;
    background: #000;
    color: #fff !important;
    font-weight: 600;
    transform: translate(-16%);
    text-align: center;
    white-space: nowrap;
    z-index: 999;

    p {
        color: #646f81;
        margin: 0;
    }
}

.button-tooltip {
    .ant-tooltip-content {
        .ant-tooltip-inner {
            min-height: 28px;

            p {
                font-size: 11px;
                margin: 0;
                color: #fff !important;
            }
        }
    }
}


.tooltip-color-black {
    padding-bottom: 0;

    // .ant-tooltip-arrow-content {
    //     transform: translateY(1rem);
    // }

    .ant-tooltip-content {

        .ant-tooltip-inner {
            p {
                color: black !important;
            }
        }
    }
}


.generic-tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 15%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #000 transparent transparent;
}

.table-tooltip {
    display: block;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 50%;
    width: 210px;
    transition: all 0.2s;
    border-radius: 5px;
    padding: 3px 4px;
    font-size: 11px;
    border: 1px solid #eee;
    background: #fff;
    color: #000;
    font-weight: 500;
    transform: translate(-50%, -60%);
    z-index: 0;

    label {
        white-space: normal !important;
        text-align: center;
        word-break: break-all;
    }
}

.table-tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #eee transparent transparent;
}

.generic-tabs {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #dae4ee;

    .gt-list {
        padding: 11px 0px 10px;
        font-size: 13px;
        font-weight: 500;
        // color: #67768e;
        color: #74767A;
        margin-right: 15px;
        border-bottom: 2px solid transparent;
    }

    .gt-list:hover{
        color: $fontBlack;
        border-bottom: 2px solid #CECFD3;
    }

    .activeTab {
        // color: #0f172a;
        color: $primary-color;
        // border-bottom: 2px solid #0f172a;
    }
    .activeTab:hover {
        color: $primary-color;
    }
}

.default-theme-btn {
    display: block;
    width: auto;
    height: 32px;
    padding: 5px 20px;
    color: #fff;
    background-color: $primary-color;
    border: solid 1px $primary-color;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 500;
}

.gcm-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
}

.gen-same-data-fill,
.gen-same-data-fill2 {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0 15px 15px;

    svg {
        cursor: pointer;
    }

    h3 {
        font-size: 12px;
        font-weight: 600;
        color: #0f172a;
        padding-left: 5px;
        margin: 0;
        cursor: pointer;
    }
}

.gen-same-data-fill2 {
    padding: 0;
    justify-content: start;

    h3 {
        color: #67768e;
        font-size: 11px;
        margin: 0;
        padding: 0 5px 0 0;
    }
}

// GENERIC TOGGLE BUTTON
.generic-toggle {
    display: flex;
    align-items: center;
    justify-content: center;

    .gt-switch {
        position: relative;
        width: 32px;
        height: 18px;
        margin: 0;

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .gt-slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            border-radius: 34px;
            transition: 0.4s;
        }

        .gt-slider:before {
            position: absolute;
            content: "";
            width: 12px;
            height: 12px;
            left: 3px;
            bottom: 3px;
            background-color: #fff;
            transition: 0.4s;
            border-radius: 50%;
            transform: translateX(1px);
            box-shadow: -1.840000033378601px 1.0800000429153442px 5.405211448669434px -1.0810422897338867px #23235f;
        }
    }

    .gt-switch input:checked+.gt-slider {
        background-color: $primary-color;
    }

    .gt-switch input:checked+.gt-slider:before {
        transform: translateX(14px);
    }
}

.generic-confirmation-modal {
    width: 100%;
    height: 100%;
    position: relative;

    .gcm-body {
        display: block;
        height: calc(100% - 105px);
        padding: 10px 15px;
        // min-height: 100px;
        // max-height: 180px;
        overflow: auto;
        background-color: #fff;

        h3 {
            font-size: 14px;
            font-weight: 700;
        }

        p {
            font-size: 12px;
            font-weight: 500;
            margin: 5px 0;
            color: #97abbf;
        }

        .gcmb-custom-msg {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 10px 0 30px;

            h4 {
                color: #0f172a;
                font-size: 30px;
                font-weight: 700;
            }

            p {
                color: #0f172a;
                font-size: 18px;
                font-weight: 400;
            }

            label {
                color: #0f172a;
                font-size: 13px;
                font-weight: 400;
            }
        }

        .gcmbc-det {
            min-width: 400px;
            background-color: #f8fafc;
            border: 1px solid #efeff6;
            margin: 0 0 20px;
            padding: 15px 20px;

            label {
                color: #0f172a;
                font-size: 12px;
                font-weight: 600;
                margin: 0;
            }

            .gcmbcd-details {
                display: flex;
                align-items: center;

                p {
                    color: #71819b;
                    font-size: 10px;
                    font-weight: 400;
                    margin-bottom: 3px;
                }

                label {
                    font-weight: 500;
                }
            }
        }
    }

    .generic-form-footer {
        background-color: #ffffff;
    }
}

.generic-confirmation-modal.confirmation-modal-with-header-img .gcm-body {
    height: calc(100% - 102px);
}
// Sign in Icons
.input-icons {
    position: absolute;
    left: 10px;
    top: 5px;
}

.psswrd-eye-icon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 10px;
}

.no-wait-btn {
    border-radius: 5px;
    border: 1px solid #627da8;
    background: #fff;
    padding: 3px 25px;
    height: 32px;
    font-size: 12px;
    color: #627da8;
    font-weight: 600;
}

.generic-form-in-table-view {
    display: block;
    width: 100%;
    height: calc(100% - 169px);
    position: relative;
    overflow: auto;

    .gfitv-manage {
        display: table;
        width: 100%;

        .gfitvm-header {
            display: table-header-group;

            .gfitvmh-row {
                display: table-row;

                li {
                    display: table-cell;
                    padding: 8px;
                    vertical-align: middle;
                    position: sticky;
                    top: 0;
                    background: #fff;
                    z-index: 5;

                    label {
                        font-size: 11px;
                        font-weight: 600;
                        color: #67768e;
                        margin: 0;
                        white-space: nowrap;
                    }
                }

                li:first-child {
                    padding-left: 15px;
                }

                li:last-child {
                    padding-right: 15px;
                }
            }
        }

        .generic-form-body {
            display: table-row-group;

            .gfb-inner {
                display: table-row !important;

                .generic-input-box {
                    display: table-cell;
                    padding: 8px;
                    border-top: 1px solid #e6ebef;
                    position: relative;
                    vertical-align: middle;
                    width: auto;
                    margin: 0;

                    .mdlr-multiple-row {
                        font-size: 11px;
                        font-weight: 600;
                        color: #21314b;
                        margin: 0;
                        max-width: 260px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        vertical-align: middle;
                    }

                    .use-as-generic-dropdown {
                        top: 43px;
                        left: 8px;
                    }
                }

                .mdlr-col:first-child {
                    padding-left: 15px;
                }

                .mdlr-col:last-child {
                    padding-right: 15px;
                }
            }
        }
    }
}

.add-more-row-gen-btn {
    box-shadow: 0 1px 5px 2px hsla(0, 0%, 55%, 0.16);
    background: #fff;
    font-size: 12px;
    font-weight: 500;
    height: 32px;
    border-radius: 30px;
    padding: 5px 10px 5px 9px;
    display: flex;
    align-items: center;

    .amba-inner {
        margin-right: 7px;
    }
}

.no-data-found-screen {
    width: 100%;
    height: 100%;
    padding: 20px 15px;

    .ndfs-inner {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    h3 {
        color: #0f172a;
        font-size: 18px;
        font-weight: 600;
        margin-top: 10px;
    }

    p {
        font-size: 12px;
        font-weight: 500;
        color: #0f172a;
        margin: 0;
    }
}

.adhoc-request-modal {
    display: block;
    width: 100%;
    height: calc(100% - 62px);

    .generic-form-in-table-view {
        height: auto;
        overflow: visible;

        .gfitv-manage .gfitvm-header .gfitvmh-row li {
            position: relative;
            top: auto;
            z-index: 1;
        }
    }

    .generic-form-body {
        height: calc(100% - 54px);

        .use-as-generic-dropdown {
            z-index: 2;
        }
    }
}

.main-bread-crumps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 0 15px;
    // position: sticky;
    // top: 45px;
    z-index: 9;

    .mbc-left {
        display: flex;
        align-items: center;

        .mbcl-list {
            display: flex;
            align-items: center;
            position: relative;

            .mbcl-back-btn {
                display: flex;
                align-items: center;
                padding: 4px 8px;
                font-size: 13px;
                color: #111d31;
                font-weight: 500;
                position: relative;
            }

            .mbcl-back-btn::after {
                content: "";
                position: absolute;
                top: 8px;
                right: -1px;
                background-color: #b7c6de;
                width: 1px;
                height: 11px;
            }
            .button-without-divider::after{
                display: none;
            }

            a {
                padding: 4px 10px;
                font-size: 13px;
                color: #111d31;
                font-weight: 500;
                text-decoration: none;
            }

            .mbcll-left {
                display: flex;
                align-items: center;
                padding: 4px 8px 4px 0;
                font-size: 13px;
                color: #111d31;
                font-weight: 500;
                cursor: pointer;

                .mbcll-icon {
                    margin-right: 10px;
                }

                .mbclll-down-arrow {
                    margin-left: 5px;
                }
            }

            .mbcllms-breadcrump-menu {
                position: absolute;
                top: 28px;
                left: 20px;
                min-width: 180px;
                visibility: hidden;
                opacity: 0;
                margin: 0;
                background-color: #fff;
                transition: auto;
                border-radius: 6px;
                box-shadow: 0 2px 6px 0 rgba(75, 75, 75, 0.16);
                z-index: 9;

                .bm-inner-item {
                    font-size: 12px;
                    color: #21314b;
                    display: inline-block;
                    text-align: left;
                    width: 100%;
                    padding: 5px 15px;
                    cursor: pointer;
                    font-weight: 500;
                    border-left: 2px solid #5d5fef00;
                    white-space: nowrap;
                    border-left: 2px solid transparent;
                }

                .bm-inner-item:hover {
                    color: #21314b;
                    font-weight: 600;
                    background-color: $dropdown-item-hover;
                    border-left: 2px solid $primary-color;
                }

                .breadcrump-sub-menu {
                    position: relative;
                    padding: 0;
                    font-size: 12px;
                    color: #21314b;
                    display: inline-block;
                    text-align: left;
                    width: 100%;
                    padding: 5px 10px;
                    cursor: pointer;
                    font-weight: 500;
                    border-left: 2px solid transparent;
                    white-space: nowrap;

                    .bsm-head {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-radius: 5px;
                        font-size: 12px;
                        padding: 0 5px;
                        font-weight: 500;
                        position: relative;

                        .breadcrump-sub-menu2 {
                            visibility: hidden;
                            opacity: 0;
                            left: 169px;
                        }
                    }

                    .header-last-update {
                        font-size: 12px;
                        font-weight: 500;
                        color: #646B84;
                    }

                    .sohr-btn-with-dropd {
                        position: relative;
                    }

                    .fa-chevron-right {
                        position: absolute;
                        right: 10px;
                        top: 13px;
                    }
                }

                .breadcrump-sub-menu:hover {
                    color: #21314b;
                    font-weight: 600;
                    background-color: $dropdown-item-hover;
                    border-left: 2px solid $primary-color;

                    .bsm-head {
                        font-weight: 600;
                        background-color: $dropdown-item-hover;

                        .breadcrump-sub-menu2 {
                            visibility: visible;
                            opacity: 1;
                        }

                        svg {
                            path {
                                fill: $primary-color;
                            }
                        }
                    }
                }
            }
        }

        .mbcl-list:hover {
            .mbcllms-breadcrump-menu {
                visibility: visible;
                opacity: 1;
            }
        }

        .mbcl-active-list {
            .mbcll-left {
                color: $primary-color;
            }
        }
    }

    .mbc-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 47px;

        button {
            height: 30px;
            display: flex;
            align-items: center;
            border-radius: 5px;
            border: 1px solid #ccd8ed;
            background-color: #fff;
            font-size: 12px;
            padding: 5px 10px;
            margin-left: 10px;
            font-weight: 500;
            position: relative;
        }

        .sohr-btn-with-dropd {
            position: relative;

            button {
                padding: 5px 12px;

                svg {
                    width: 12px;
                    height: 12px;
                    margin-left: 5px;

                    path {
                        stroke: #fff !important;
                    }
                }
            }

            button:hover,
            .sohr-btn-focus {
                background-color: $primary-color;
                color: #fff;
                font-weight: 600;
                border: solid 1px $primary-color;

                svg {
                    path {
                        stroke: #fff;
                    }
                }
            }

            .btnDisabled {
                svg {
                    path {
                        stroke: #d8d3d3;
                    }
                }
            }

            .btnDisabled:hover {
                svg {
                    path {
                        stroke: #d8d3d3;
                    }
                }
            }
        }

        .sohrbwdd-down {
            position: absolute;
            top: 40px;
            right: 0;
            width: 180px;
            height: auto;
            max-height: 150px;
            overflow: auto;
            z-index: 2;
            background: #fff;
            border-radius: 5px;
            box-shadow: 0 0 16px 1px #a3a3a340;

            ul {
                padding: 0;
                margin: 0;

                li {
                    padding: 7px 10px;
                    font-size: 12px;
                    font-weight: 600;
                    color: #21314b;
                    background-color: #fff;
                    transition: 0.6s all;
                    cursor: pointer;
                }

                li:hover {
                    background-color: #edf1f5;
                    transition: 0.6s all;
                }
            }
        }

        .sohr-upload-download {
            display: flex;
            align-items: center;
            height: 32px;
            border: solid 1px #dae4ee;
            position: relative;
            border-radius: 4px;
            padding: 3px 10px;
            padding-right: 0px;
            margin-left: 15px;

            label {
                margin: 0;
                font-size: 12px;
                font-weight: 600;
                cursor: pointer !important;

                input {
                    display: none;
                }
            }

            .sohrud-arrow {
                padding: 2px 10px;
                border: none;
                height: auto;
                border-left: solid 1px #c7d3df;
                border-radius: 0;
                margin-left: 10px;
            }

            .sohrud-download {
                position: absolute;
                top: 40px;
                left: 0px;
                padding: 3px 8px;
                border-radius: 4px;
                box-shadow: 0 1px 11px -3px rgba(1, 1, 1, 0.15);
                border-radius: 4px;
                height: 38px;
                border: none;
                color: #21314b;
                font-weight: 500;
                margin: 0;
                text-align: left;
                white-space: nowrap;
                display: flex;
                align-items: center;
                z-index: 9;

                svg {
                    margin-right: 5px;
                }
            }

            .sohrud-dropdown {
                position: absolute;
                box-shadow: 0 1px 11px -3px #01010126;
                top: 40px;
                right: 0;
                width: 130px;
                z-index: 9;
                background: #fff;
                padding: 5px 0;

                .sohrud-download {
                    position: relative;
                    box-shadow: none;
                    top: auto;
                    left: auto;
                    height: auto;
                    padding: 6px 8px;
                    width: 100%;
                }
            }
        }

        .sohr-upload-download-focus {
            border: solid 1px $primary-color;

            .sohrud-arrow {
                svg {
                    transform: rotate(180deg);

                    path {
                        fill: $primary-color;
                    }
                }
            }
        }

        .download-document-dropdown {
            button {
                width: 100%;
                height: 100%;
                justify-content: space-between;
                padding: 7px 10px;
                border: none;
                border-radius: 0;
                margin: 0;

                img {
                    width: 16px;
                    height: 16px;
                    object-fit: cover;
                    // margin-right: 10px;
                }

                label {
                    white-space: nowrap;
                    font-size: 12px;
                    font-weight: 500;
                    color: #0F172A;
                    margin: 0 15px 0 0;
                    cursor: pointer;

                }

                button {
                    width: fit-content;
                    font-size: 10px;
                    font-weight: 500;
                    color: #0F172A;
                    background: transparent;
                    // border: 1px solid #0F172A;
                    // border-radius: 0;
                    // padding: 1px 7px;
                    // margin-left: 10px;
                    // border-radius: 20px;
                }

                button:hover {
                    // background-color: #0F172A;
                    // color: #fff;
                    color: #3498db;
                }

                // .printer-barcode{
                //     min-width: 200px;
                //     max-height: 150px;
                //     // overflow: auto;
                //     position: absolute;
                //     top: 0;
                //     right: 251px;
                //     background-color: #fff;
                //     z-index: 9;
                //     box-shadow: 0 4px 12px 0 rgba(75, 75, 75, 0.16);
                //     .pb-data{
                //         .pbd, .pbd-inner{
                //             display: flex;
                //             align-items: center;
                //             justify-content: space-between;
                //             padding: 5px 10px;
                //             cursor: pointer;
                //             p{
                //                 font-size: 12px;
                //                 font-weight: 500;
                //                 color: #0F172A;
                //                 margin: 0;
                //             }
                //         }
                //         .pbd:hover{
                //             background-color: #f5f5f5;
                //         }
                //         .pbd-inner{
                //             p{
                //                 font-weight: 400;
                //             }
                //         }
                //         .pbd-inner:hover{
                //             background-color: #f5f5f5;
                //             p{
                //                 font-weight: 500;
                //             }
                //         }
                //     }
                // }
            }

            button:hover {
                background-color: #f5f5f5;
            }
        }
    }

    .mbc-right > button:first-child {
        margin-left: 0;
    }
}

.breadcrump-sub-menu2 {
    position: absolute;
    width: 180px;
    visibility: hidden;
    opacity: 0;
    background: #fff;
    top: 0;
    left: 165px;
    border-radius: 6px;
    box-shadow: 0 2px 6px 0 rgba(75, 75, 75, 0.16);
    transition: 0.5s;
    z-index: 99;
    max-height: 470px;
    overflow-x: hidden;
    overflow-y: auto;
}

.genrate-mbq-suggestion-modal{
    display: block;
    width: 100%;
    height: 100%;

    .generic-form-body {
        height: calc(100% - 72px);
        .gfb-multiple-row {
            .generic-input-box {
                width: 33%;
            }
        }
    }
}
.schedule-job-modal {
    display: block;
    width: 100%;
    height: 100%;

    .generic-form-body {
        height: calc(100% - 170px);
        .cron_builder {
            input[type=checkbox] {
                display: inline;
            }
        }
    }
}

.days-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 0.5rem;

    button {
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        border: 1px solid #71819b;
        background: #fff;
        padding: 0 10px;
        margin: 0 10px 0 0;
        color: #0f172a;
        font-size: 13px;
        font-weight: 500;
    }

    .btn-active {
        border: 1px solid $primary-color;
        background: $primary-color;
        color: #fff;
    }
}

.time-alert {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    background-color: #ebe2cd;
    margin-top: 7px;
    padding: 0 10px;

    svg {
        margin-right: 10px;
    }

    p {
        color: #0f172a;
        font-size: 12px;
        font-weight: 400;
        margin: 0;
    }
}

.schedule-job-modal {
    .sjm-head {
        display: flex;
        align-items: start;
        justify-content: space-between;

        button {
            font-size: 12px;
            color: #0f172a;
            font-weight: 500;
        }

        button:hover {
            text-decoration: underline;
        }
    }

    .generic-input-box {
        .list-component-section {
            max-width: 100%;

            .lcs-inner {
                max-height: 150px;
            }
        }

        .modal-header-section {
            padding: 5px 15px;
        }
    }

    // radio buttons
    .ant-radio-wrapper {
        font-family: 'Poppins' !important;
        // color: var(--primary-color); /* Set dynamic color using CSS variables */
        font-size: 11px;
        font-weight: 600;
    }
    .ant-radio-wrapper .ant-radio-label{
        margin-top: 6px !important;
        color: var(--fontGrey)
    }
    
    .ant-radio-checked .ant-radio-inner {
        border-color: var(--primary-color) !important;
        background-color: var(--primary-color) !important;
    }
    
    .ant-radio:hover .ant-radio-inner {
        border-color: var(--primary-color) !important;
    }

    .ant-radio:hover .ant-radio-inner {
        border-color: var(--primary-color) !important;
    }
    /* Change radio button's focus shadow */
    .ant-radio-wrapper:hover .ant-radio-inner {
        box-shadow: 0 0 0 3px rgba(var(--primary-color), 0.3) !important;
    }
    .ant-radio-wrapper:hover .ant-radio, .ant-radio-wrapper:hover .ant-radio-inner {
        border-color: var(--primary-color) !important;
    }
}

// Style Generic custom modal
.custom-modal {
    min-width: 100%;
    height: auto;
    max-height: 170px;
    overflow: auto;
    position: absolute;
    left: 91px;
    top: 0;
    background-color: #fff;
    z-index: 999;
    border-radius: 4px;
    box-shadow: 0px 4px 24px 0px rgba(115, 114, 114, 0.25);

    li {
        padding: 5px 10px;

        h1 {
            font-weight: 500;
            font-size: 13px;
            color: #2a2a64;
            margin: 0;
        }

        h4 {
            font-weight: 400;
            font-size: 10px;
            color: #8496a9;
            margin: 0;
        }

        button,
        .generic-toggle {
            margin-left: 10px;

            .gt-switch {
                width: 30px;
                height: 18px;

                .gt-slider:before {
                    height: 12px;
                    width: 12px;
                    bottom: 3px;
                    box-shadow: -1.84px 1.08px 5.40521px -1.08104px #23235f;
                }

                input:checked+.gt-slider::before {
                    transform: translateX(12px);
                }
            }
        }
    }
}

// Style Generic custom select
.gen-custom-select {
    position: relative;
    width: 100%;

    .gcs-select-btn {
        border-radius: 6px;
        // box-shadow: 0 3px 6px 0 #d7e1f3;
        border: solid 1px #dce6f0;
        background-color: #fff;
        font-size: 12px;
        font-weight: 500;
        padding: 5px 20px 5px 5px;
        height: 30px;
        color: #21314b;
        width: 100%;
        text-align: left;
        background-image: url("../../assets/icons/downArrowNew.svg");
        background-repeat: no-repeat;
        background-position: 96%;
        background-size: 13px;
    }

    .gcs-select-btn:focus {
        border: 1px solid $primary-color;
        box-shadow: 0px 0px 0px 2px #dadaff;
    }

    .gcs-dropdown {
        display: block;
        position: absolute;
        top: 35px;
        left: 0;
        width: 100%;
        margin: 0;
        z-index: 99;
        border-radius: 6px;
        box-shadow: 0 2px 6px 0 rgba(75, 75, 75, 0.16);
        min-height: 150px;
        background-color: #fff;
        max-height: 370px;
        overflow: auto;

        ul {
            margin: 0;
            padding: 0;

            li {
                display: block;
                padding: 5px 15px;
                cursor: pointer;
                font-size: 12px;
                font-weight: 500;
                color: #4a5568;
            }

            .gcsd-search {
                position: relative;
                padding: 5px 10px;

                input[type="search"] {
                    border-radius: 37px;
                    border: 1px solid #dce6f0;
                    background: #fff;
                    padding: 4px 28px 5px 9px;
                    height: 30px;
                    font-size: 12px;
                    color: #000;
                    font-weight: 500;
                    width: 100%;
                }

                input:focus {
                    border: 1px solid $primary-color;
                }

                .search-image {
                    position: absolute;
                    top: 13px;
                    right: 21px;
                    width: 14px;
                }
            }

            .gcsd-search:hover {
                background-color: transparent;
            }

            li:hover {
                font-weight: 600;
                color: #21314b;
                background-color: #e5edf8;
            }

            .gcsd-head {
                font-size: 12px;
                font-weight: 500;
                color: #969fad;
            }
        }
    }

    .cem-dropdown {
        width: auto;
        max-height: 300px;
    }
}

.generic-spread-form {
    width: 100%;
    height: 100%;

    .gsf-body {
        height: calc(100% - 116px);
        overflow: auto;

        h2 {
            font-size: 14px;
            font-weight: 600;
            color: #0f172a;
            margin: 0 0 0 15px;
        }

        .generic-form-body {
            overflow: visible;
            height: auto;
        }

        .generic-input-box {
            .list-component-section {
                z-index: 7;
            }

            .generic-approve-btn {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .manage-roles-section {
            display: flex;
            align-items: center;
            padding: 0 15px;

            h3 {
                font-weight: 700;
                font-size: 13px;
                color: #23235f;
                margin: 0 20px 0 0;
            }
        }
    }
}

.createSiteMappingModal{
        .gsf-body {
            height: calc(100% - 150px);
        }
}

.gsf-height {
    .gsf-body {
        height: calc(100% - 134px);
    }
}

.gsf-hide-footer-btn {
    .gsf-body {
        height: calc(100% - 60px);
    }
}

.list-component-section.roles-modal-height-manage {
    .lcs-inner {
        height: calc(100% - 155px);
    }
}

.roles-modal .gsf-body{
    overflow: hidden;
}

.generic-checkbox {
    position: relative;
    width: auto;
    height: 16px;
    padding-left: 25px;
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    input:checked~.checkmark1 {
        background-color: $primary-color;
        border: 1px solid $primary-color;
    }

    .checkmark1 {
        position: absolute;
        left: 0;
        top: 0;
        height: 16px;
        width: 16px;
        background-color: #fff;
        cursor: pointer;
        border-radius: 4px;
        border: 2px solid #dce6f0;
    }

    input:checked~.checkmark1 {
        background-color: $primary-color !important;
        border: 1px solid $primary-color;
    }

    input:checked~.checkmark1:after {
        content: "";
    }

    .checkmark1:after {
        display: block;
        position: absolute;
        left: 5px;
        top: 2px;
        border: solid #fff;
        width: 5px;
        height: 9px;
        border-width: 0 2px 2px 0;
        content: none;
        transform: rotate(45deg);
    }


    .checkmark1:after {
        content: none;
    }

    input:checked~.checkmark1 {
        background-color: $primary-color;
        border: none;
    }

    input:checked~.checkmark1-disabled {
        background-color: #dce6f0 !important;
        cursor: not-allowed !important;
    }

    .checkmark1-disabled {
        background-color: #F4F4FD !important;
        cursor: not-allowed !important;
    }

    input:checked~.checkmark1-red {
        background-color: #E66767 !important;
        border: none !important;
    }

    .checkmark1-red {
        background-color: #f9f3f1 !important;
        border: solid 2px#eacdcc !important;
    }

    input:checked~.checkmark1:after {
        content: "";
        width: 5px;
        height: 9px;
        border-width: 0 2px 2px 0;
    }
}

.manage-role-check {
    display: flex;
    align-items: center;

    .generic-checkbox {
        margin-right: 10px;
    }

    .mrc-label {
        display: flex;
        align-items: center;
        margin-right: 10px !important;
        font-size: 12px;
        font-weight: 500;
        margin: 0;

        svg {
            margin-right: 10px;
            width: 15px;
            height: 15px;
        }
    }
}

.gib-upload {
    cursor: pointer;
}

// Style Generic Remark Modal
.bag-sizes-modal {
    .bsm-body {
        padding: 15px;
        height: calc(100% - 125px);
        overflow: auto;

        .bsm-remark {
            height: 150px;
            border: none !important;

            .gib-inner {
                height: 100%;
                border: none;
            }

            textarea {
                padding: 5px;
                height: 100%;
                width: 100%;
                resize: none;
                border: none;
                outline: none;
                color: #888a8e;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }

    .bsm-footer {
        padding: 15px;

        .generic-approve-btn {
            width: 100%;
            height: 40px;
            border-radius: 0;
        }
    }
}

// Generic Image View Modal
.generic-img-view-modal {
    width: 100%;
    height: 100%;
    position: relative;

    .givm-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background: linear-gradient(180deg, #343332 0%, rgba(52, 51, 50, 0) 82.45%);
        padding: 10px 15px;

        h3 {
            font-size: 13px;
            font-weight: 600;
            color: #fff;
            margin: 0;
        }

        .ant-slider {
            border-radius: 30px;
            background: rgba(40, 39, 37, 0.8);

            .ant-tooltip-open {
                border-color: transparent;
            }

            .ant-slider-rail {
                background-color: #d9d9d9;
            }

            .ant-slider-handle {
                top: 40%;
            }

            .ant-slider-handle::after {
                box-shadow: 0 0 0 2px #3d3c3b;
            }
        }

        :where(.css-dev-only-do-not-override-gzal6t).ant-slider .ant-slider-track {
            background-color: #d9d9d9;
            border-radius: 2px;
        }

        .givmh-right {
            display: flex;
            align-items: center;

            button {
                padding: 3px 5px;
                margin-left: 10px;
                border: 1px solid transparent;

                svg {
                    path {
                        fill: #fff;
                    }
                }
            }

            button:hover {
                border: 1px solid #fff;
            }
        }
    }

    .givm-mid {
        width: 100%;
        height: calc(100% - 122px);

        .givmm-data {
            width: 100%;
            height: 100%;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

            video {
                width: 100%;
                height: 100%;
            }
        }
    }

    .givm-footer {
        display: flex;
        width: 100%;

        .PreviewPdfIcon{
            svg{
                height: 45px;
                width: 45px;
            }
        }

        button {
            width: 4%;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #ecedf7;
            margin-right: 5px;
        }

        // button:hover {
        //     background-color: black;

        //     svg {
        //         path {
        //             fill: #ecedf7;
        //         }
        //     }
        // }

        button:nth-last-child(1) {
            margin-right: 0;
            margin-left: 5px;
        }

        .givmf-img {
            width: 100%;
            display: flex;
            overflow-x: auto;

            .givmfi {
                width: auto;
                min-width: 100px;
                max-width: 100px;
                height: 70px;
                margin-right: 5px;
                border: 1px solid #fff;
                cursor: pointer;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            // .givmfi:hover {
            //     border: 1px solid #fff;
            // }

            .givmfi:nth-last-child(1) {
                margin-right: 0;
            }
        }
    }
}

// Generic Share File
.share-file {
    padding: 10px 15px;

    h3 {
        font-size: 14px;
        font-weight: 700;
        color: #21314b;
        margin-bottom: 5px;
    }

    .sf-data {
        button {
            padding: 5px;
            border: 1px solid transparent;
        }

        button:hover {
            border: 1px solid #707494;
        }

        span {
            font-size: 11px;
            font-weight: 500;
            color: #fff;
            background-color: #000;
            padding: 4px 10px;
            border-radius: 35px;
            margin-right: 3px;
        }

        p {
            font-size: 12px;
            font-weight: 500;
            color: #21314b;
            margin: 0;
        }
    }

    .sf-icons {
        display: flex;
        flex-wrap: wrap;
        padding-top: 20px;

        .sfi {
            margin-right: 10px;

            button {
                width: 35px;
                height: 35px;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

// Generic Password Check
.check-password {
    padding: 10px;

    h2 {
        font-size: 14px;
        font-weight: 500;
        color: #12203c;
        margin: 0;
    }

    p {
        color: #71819b;
        font-size: 12px;
        font-weight: 400;
        margin: 0;
    }

    .cp-body {
        .generic-input-box {
            padding: 15px 0 0;

            .gib-inner {
                input::placeholder {
                    color: #c1c1cc;
                    font-family: Poppins;
                    font-size: 11px;
                    font-weight: 400;
                }
            }
        }

        .cpb-check {
            width: 100%;

            .cpbc {
                width: 50%;
                display: flex;
                align-items: center;
                padding-top: 10px;

                svg {
                    margin-right: 5px;
                }

                span {
                    color: #71819b;
                    font-size: 9px;
                    font-weight: 400;
                    margin: 0;
                }
            }

            .cpbc-checked {
                svg {
                    circle {
                        fill: #03b675;
                    }
                }
            }
        }

        .prog-strong-text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 10px;

            .prog-strong {
                progress {
                    width: 50px;
                    margin-right: 10px;
                }

                progress {
                    background: rgba(3, 182, 117, 0.08);
                }

                progress {
                    color: #03b675;
                    height: 4px;
                    border-radius: 30px;
                }

                progress::-webkit-progress-value {
                    color: #03b675;
                    border-radius: 30px;
                }

                progress::-moz-progress-bar {
                    color: #03b675;
                    border-radius: 30px;
                }

                progress::-webkit-progress-bar {
                    background: rgba(3, 182, 117, 0.08);
                    border-radius: 30px;
                }
            }

            .prog-medium {
                progress {
                    width: 50px;
                    margin-right: 10px;
                }

                progress {
                    background: #ffd800;
                }

                progress {
                    color: #ffd800;
                    height: 4px;
                    border-radius: 20px;
                }

                progress::-webkit-progress-value {
                    background: #ffd800;
                    border-radius: 30px;
                }

                progress::-moz-progress-bar {
                    background: #ffd800;
                    border-radius: 30px;
                }

                progress::-webkit-progress-bar {
                    background: #ffd800;
                    border-radius: 30px;
                }
            }

            .prog-weak {
                progress {
                    width: 50px;
                    margin-right: 10px;
                }

                progress {
                    background: #e40808;
                }

                progress {
                    color: #e40808;
                    height: 4px;
                    border-radius: 30px;
                }

                progress::-webkit-progress-value {
                    background: #e40808;
                    border-radius: 30px;
                }

                progress::-moz-progress-bar {
                    background: #e40808;
                    border-radius: 30px;
                }

                progress::-webkit-progress-bar {
                    background: #e40808;
                    border-radius: 30px;
                }
            }

            h3 {
                font-weight: 600;
                font-size: 12px;
                color: #03b675;
                margin: 0;
            }

            .lineStrength-0,
            .lineStrength-1 {
                color: #e40808;
            }

            .lineStrength-2,
            .lineStrength-3 {
                color: #ffd800;
            }
        }
    }
}

// Generic OTP Form
.otp-form {
    .otp-head {
        padding: 0 15px;

        h3 {
            font-weight: 700;
            font-size: 20px;
            color: #0f172a;
            margin-bottom: 0px;
        }

        p {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            color: #71819b;
            font-size: 12px;
            font-weight: 400;
            margin: 0;

            b {
                color: #0f172a;
                font-weight: 600;
            }

            .edit-btn {
                border-radius: 4px;
                padding: 3px 5px;
                margin: 0 0 5px 1px;
            }

            .edit-btn:hover {
                // background-color: #f2f1fe;
                background-color: $primary-w-less-opacity;
                
    
                svg {
                    g {
                        path {
                            fill: $primary-color;
                        }
                    }
                }
            }
        }

        .of-otp {
            padding-top: 10px;

            input {
                width: 30px;
                height: 46px;
                border: none;
                border-bottom: 2px solid #71819b !important;
                margin-right: 20px;
                color: #0f172a;
                font-size: 23px;
                font-weight: 500;
                text-align: center;
            }
        }

        .clock-icon-loader {
            margin-right: 10px;
            height: 30px;
            position: relative;
            width: 37px;
            display: flex;
            align-items: center;
            justify-content: center;

            .clock-loader {
                position: absolute;
                top: -4px;
                left: -3px;

                svg {
                    circle {
                        stroke-dasharray: 104.25px;
                        stroke-dashoffset: 0px;
                        stroke-linecap: round;
                        stroke-width: 2px;
                        stroke: $primary-color;
                        fill: none;
                        animation: countdown 30s linear infinite forwards;
                    }

                    @keyframes countdown {
                        from {
                            stroke-dashoffset: 0px;
                        }

                        to {
                            stroke-dashoffset: 104.25px;
                        }
                    }
                }
            }
        }

        .of-resend-otp {
            padding-top: 15px;

            button {
                color: #71819b;
                font-size: 11px;
                font-weight: 400;
                padding: 5px 8px 5px 0;
                background: #fff;
            }

            button:hover {
                color: #0f172a;
            }
        }
    }
}

// Generic ant Toggle
.ant-toggle {
    .ant-switch {
        width: 32px;
        height: 22px;
        background-color: #ccc;

        .ant-switch-handle {
            width: 16px;
            height: 16px;
            top: 3px;
            transition: 0.4s;
        }

        .ant-switch-handle::before {
            transition: 0.4s;
            transform: translateX(1px);
            box-shadow: -1.840000033378601px 1.0800000429153442px 5.405211448669434px -1.0810422897338867px #23235f;
        }
    }

    .ant-switch:hover {
        background-color: #ccc;
    }

    .ant-switch:focus {
        box-shadow: none;
    }

    .ant-switch-checked {
        background: $primary-color;
    }

    .ant-switch-checked:hover:not(.ant-switch-disabled) {
        background: $primary-color;
    }

    .ant-switch-checked:focus {
        box-shadow: none;
    }
}

// Generic Rowtype Component
.gen-rowtype-component {
    height: 100%;

    .grc-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px 15px;

        button {
            display: flex;
            align-items: center;
            border-radius: 4px;
            font-size: 12px;
            color: #fff;
            border: 1px solid #111d31;
            background-color: #111d31;
            padding: 5px 10px;

            svg {
                margin-right: 3px;
            }
        }

        h4 {
            color: #67768e;
            font-weight: 500;
            font-size: 12px;
            margin: 0;
        }
    }

    .grc-body {
        height: calc(100% - 179px);
        overflow: auto;
        margin: 0 15px;
        padding-right: 10px;

        .grcb-head {
            width: fit-content;
            display: flex;
            background: #f8f8f8;
            padding: 10px 0;
            padding-left: 30px;

            .gib-label-heading {
                width: 150px;
                display: flex;
                align-items: center;
                color: #8496a9;
                font-size: 11px;
                // text-transform: uppercase;
                white-space: nowrap;
                padding: 0 8px;
                margin: 0;

                .generic-toggle {
                    margin-left: 5px;
                }
            }
        }

        .generic-form-body {
            width: fit-content;
            padding: 0;
            overflow: inherit;

            .gfb-multiple-row {
                width: fit-content;
                display: flex !important;
                flex-wrap: nowrap;
                width: auto;
                position: relative;
                padding: 10px 0 10px 30px;

                .generic-input-box {
                    width: 150px;
                    padding: 0 0 0 8px;
                    margin: 0;

                    .gen-input-disabled {
                        cursor: not-allowed;

                        input {
                            cursor: not-allowed !important;
                        }
                    }

                    .gib-upload {
                        height: 32px;

                        label {
                            height: 24px;
                            font-size: 12px;
                        }
                    }

                    .list-component-section {
                        top: 37px;
                        left: 8px;
                    }
                }

                .generic-input-box:nth-child(1) {
                    width: fit-content;
                    position: absolute;
                    top: 10px;
                    left: 0;
                    padding: 0;
                }
            }
        }
    }
}

// Generic Upload File
.gen-upload-file {
    width: auto;
    min-width: 250px;
    height: 250px;
    position: absolute;
    top: 40px;
    right: 0;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 2.71233px 18.99px rgba(160, 160, 160, 0.5607843137);
    z-index: 2;

    .gpf-head {
        position: relative;
        padding: 5px 10px;

        h3 {
            color: #23235f;
            font-size: 12px;
            font-weight: 500;
            margin: 0;
            white-space: nowrap;
        }

        button {
            padding: 3px 5px;
        }

        .gpfh-tag {
            width: 100%;
            height: 34px;
            display: flex;
            align-items: center;
            border-radius: 4px;
            border: 1px dashed #dae4ee;
            margin-top: 10px;
            padding: 0 10px;

            svg {
                margin-right: 5px;
            }

            p {
                color: #23235f;
                font-size: 12px;
                font-weight: 500;
                margin: 0;
            }
        }

        .gpfh-input {
            width: 100%;
            height: 34px;
            position: absolute;
            top: 41px;
            left: 0;
            opacity: 0;
            z-index: 1;
            cursor: pointer;
        }
    }

    .gpf-body {
        height: calc(100% - 122px);
        overflow: auto;

        ul {
            padding: 0 10px 10px;

            li {
                border-radius: 4px;
                background: #edeff0;
                padding: 5px;
                margin-top: 5px;

                .uploaded-img {
                    display: flex;

                    .ui-img {
                        width: 40px;
                        min-width: 40px;
                        height: 40px;
                        border-radius: 4px;
                        border: 1.5px solid #fff;
                        margin-right: 10px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    h4 {
                        color: #23235f;
                        font-size: 12px;
                        font-weight: 500;
                        margin: 0;
                    }

                    p {
                        color: #8698ab;
                        font-size: 9px;
                        font-weight: 500;
                        margin: 0;
                    }

                    button {
                        svg {
                            width: 14px;
                            height: 14px;
                        }
                    }
                }
            }
        }

        .gpf-body {
            height: calc(100% - 122px);
            overflow: auto;

            ul {
                padding: 0 10px 10px;

                li {
                    border-radius: 4px;
                    background: #EDEFF0;
                    padding: 5px;
                    margin-top: 5px;

                    .uploaded-img {
                        display: flex;

                        .ui-img {
                            width: 40px;
                            min-width: 40px;
                            height: 40px;
                            border-radius: 4px;
                            border: 1.5px solid #FFF;
                            margin-right: 10px;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        h4 {
                            color: #23235F;
                            font-size: 12px;
                            font-weight: 500;
                            margin: 0;
                        }

                        p {
                            color: #8698AB;
                            font-size: 9px;
                            font-weight: 500;
                            margin: 0;
                        }

                        button {
                            svg {
                                width: 14px;
                                height: 14px;
                            }
                        }
                    }
                }
            }
        }
    }

}

// Generic Reason Remark Modal
.gen-reason-remark-modal {
    height: 100%;

    .grrm-icon {
        display: flex;
        justify-content: center;
        padding: 15px 25px 10px;
    }

    .grrm-no {
        width: 100%;
        position: relative;
        padding: 10px 40px;

        h1 {
            font-size: 14px;
            font-weight: 600;
            color: #12203c;
            margin: 0;
            text-align: center;
        }

        .generic-tooltip {
            width: 600px;
            height: auto;
            white-space: normal;
            text-align: left;
            top: auto;
            bottom: 50px;
            left: 100%;
            padding: 7px 10px;
        }

        .generic-tooltip::after {
            display: none;
        }
    }

    .grrm-no:hover {
        .generic-tooltip {
            visibility: visible;
            opacity: 1;
        }
    }

    .generic-spread-form {
        height: calc(100% - 105px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .gsf-body {
            height: 100%;

            .generic-form-body {
                padding: 10px 40px;
                overflow: hidden;

                .gfb-inner {
                    display: grid;

                    .generic-input-box:nth-child(1) {
                        width: 50%;

                        .list-component-section {
                            top: 62px;
                            max-height: 100px;

                            .lcs-inner {
                                max-height: 100px;
                            }
                        }
                    }

                    .generic-input-box:nth-child(2) {
                        width: 100%;

                        .gib-textarea {
                            width: 100%;
                            height: 120px;
                        }
                    }
                }
            }

            .gsfb-check {
                padding: 0 40px;

                label {
                    font-size: 12px;
                    font-weight: 500;
                    color: #778695;
                }
            }
        }
    }
}

.generic-filter-section {
    box-shadow: 1px 4px 7px 0 #dce6f096;
    background-color: #fff;
    position: relative;
    // z-index: 3;
    padding: 0 30px;
    min-height: 45px;
    border-top: 1px solid #dce6f0;

    .gfs-top {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .gfst-left {
            display: flex;
            align-items: center;

            p {
                font-size: 16px;
                font-weight: 700;
                color: #21314b;
                margin: 0;
                position: relative;

                .font-bold {
                    font-size: 11px;
                    color: #97abbf;
                }
            }

            button {
                margin-left: 10px;
            }

            .generic-default-btn {
                height: 28px;
                color: #21314b !important;
                font-size: 12px !important;
                border: 1px solid #e8ecf4 !important;
                background: #e8ecf4 !important;
                border-radius: 15px;
            }
        }

        .gfst-right {
            display: flex;
            align-items: center;

            .header-last-update {
                font-size: 12px;
                font-weight: 500;
                color: #646b84;
            }

            button {
                margin-left: 15px;
            }

            .gfst-round-btn {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                box-shadow: 0 3px 16px 0 rgba(220, 230, 240, .89);
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all .6s;
            }

            .gfstr-rotate {
                background: $primary-color;
                transform: rotate(180deg);

                svg {
                    path {
                        stroke: #fff !important;
                    }
                }
            }
        }
    }

    .gfs-body {
        transition: all .6s;
        padding-bottom: 10px;

        .generic-spread-form {
            .gsf-body {
                height: auto;
                overflow: visible;

                .generic-form-body {
                    padding: 0;

                    .gfb-inner {
                        .generic-input-box {
                            width: 16.66%;
                            margin-right: 0;
                            padding-right: 15px;

                            .gib-range {
                                .ant-picker {
                                    padding: 5px 5px;

                                    input {
                                        text-overflow: clip;
                                        padding: 0;
                                    }

                                    .ant-picker-range-separator {
                                        padding: 0 3px;
                                    }
                                }
                            }

                            .list-component-section {
                                .lcs-inner {
                                    max-height: 210px;
                                }

                                .generic-form-footer {
                                    padding: 5px 15px;
                                }
                            }
                        }

                        .goly-report-select {
                            width: 20%;
                        }
                    }
                }
            }

            .generic-form-footer {
                padding: 5px 0;
            }

            .generic-confirmation-modal {
                .generic-form-footer {
                    padding: 15px;
                }
            }
        }
    }
}

.generic-tabs.tab-as-table-component {
    box-shadow: 0 8px 14px -17px #8b93bb;
    border: none;
    padding: 0 30px;
    background: #fff;

    // .gt-list {
    //     color: #23235f;
    // }

    .activeTab {
        color: $primary-color;
        // border-bottom: 2px solid $primary-color;
    }
}

.table-head-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;

    .ths-left {
        display: flex;
        align-items: center;

        .summary-data {
            padding-right: 15px;

            p {
                color: #67768e;
                font-size: 11px;
                font-weight: 600;
                margin: 0;
            }

            span {
                color: #2a2a64;
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    .ths-right {
        .thshs-right {
            background: $primary-color;
            border-radius: 3px;
            border: 1px solid $primary-color;
            font-weight: 500;
            font-size: 13px;
            color: #ffffff;
            padding: 2px 15px;
            cursor: pointer;
            height: 32px;
            align-items: center;
            justify-content: center;
        }
    }
}

.ths-with-summary {
    padding: 5px 10px;
    background-color: #fff;
    border-radius: 5px;
    margin: 12px 30px;
    width: calc(100% - 60px);
}

.table-component-new-style.style-reports-table {
    .invoice-manage-table {
        margin: 0;
        padding: 0 30px;
        margin-bottom: 20px;

        .imt-manage-table {
            border: none;
            border-radius: 0;
            box-shadow: 2px 0 6px 0 hsla(0, 0%, 55%, .16);
            min-height: fit-content;

            .imt-main-table {
                thead {
                    tr {
                        th {
                            padding: 6px;
                            border: none;
                            background-color: #fff;

                            label {
                                margin: 0;
                                color: #0f172a;
                                font-weight: 600;
                            }

                            .rab-refresh {
                                .rab-rinner {
                                    svg {
                                        width: 18px;
                                    }
                                }
                            }
                        }

                        .fix-action-btn {
                            width: 40px;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            padding: 8px;
                            background-color: #fcfdfe;
                            border: 0.5px solid #e3e7f3;
                        }

                        td:first-child {
                            border-left: none;
                        }

                        td:last-child {
                            border-right: none;
                        }

                        .fix-action-btn {
                            width: 40px;

                            .table-item-list {
                                box-shadow: none;
                            }
                        }
                    }
                }
            }

            .table-grand-total {
                thead {
                    tr {
                        th:nth-last-child(1) {
                            background-color: #F2F5F8 !important;
                        }
                    }
                }

                tbody {
                    tr {
                        td:nth-last-child(1) {
                            background-color: #F2F5F8 !important;
                        }
                    }
                }
            }
        }
    }

    .main-table-with-pagination {
        margin-bottom: 0;
    }

    .pad-top-10.mainbgColor {
        padding: 10px 15px 0 30px;

        .columnFilterGeneric {
            right: 15px;
        }

        .imt-manage-table {
            .imt-main-table {
                thead {
                    tr {
                        th {
                            padding: 0;
                            border-left: 1px solid #dae4ee;
                            border-bottom: 1px solid #dae4ee;
                        }
                    }
                }
            }
        }
    }

    .table-loader-bg {
        padding: 0;

        .table-loader-modal {
            border-radius: 0;
        }
    }

    .manipulate-loader-css {
        padding: 0;

        .new-table-loader {
            border: none;
            border-radius: 0;
        }
    }
}

.style-reports-table {
    .atv_storewise_and_dnld_btn {
        .generic-input-box {
            margin: 0;
        }

        button {
            align-items: center;
            white-space: nowrap;
        }
    }
}

.style-reports-table.wrap-header {
    .invoice-manage-table {
        .imt-manage-table {

            .imt-main-table {
                thead {
                    tr {
                        th {
                            padding: 6px 10px;

                            label {
                                white-space: normal;
                                min-width: 120px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.otb-reports-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .otbrc-left {
        justify-content: flex-start;
        display: flex;
        align-items: center;

        .otbrc-report-view {
            position: relative;

            h3 {
                font-size: 14px;
                font-weight: 700;
                color: #21314b;
            }

            .gen-pi-formate {
                padding: 10px 0;

                .gpf-radio-list {
                    padding: 0;
                    margin-bottom: 0;

                    li {
                        display: inline-block;
                        position: relative;
                        margin-right: 40px;

                        .gen-radio-btn {
                            margin: 0;
                            font-size: 13px;
                            font-weight: 500;
                            color: #12203c;
                            padding-left: 25px;
                            cursor: pointer !important;

                            input[type=radio] {
                                display: none;
                            }

                            input:checked~.checkmark {
                                background-color: #fff;
                                border: 2px solid $primary-color;
                            }

                            .checkmark {
                                content: "";
                                position: absolute;
                                top: 2px;
                                left: 0;
                                height: 15px;
                                width: 15px;
                                border: 2px solid #d6dce6;
                                border-radius: 50%;
                            }

                            input:checked~.checkmark:before {
                                background: $primary-color;
                            }

                            .checkmark:before {
                                content: "";
                                position: absolute;
                                top: 3px;
                                left: 3px;
                                width: 5px;
                                height: 5px;
                                display: block;
                                border-radius: 50%;
                                background: #d6dce6;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Generic Image Upload
.generic-upload-img {
    width: 100%;
    height: 100%;

    .gui-error {
        border: 1px solid #FECACA;
        border-radius: 12px;
        background-color: #FEE2E2;
        padding: 10px 15px;
        margin-bottom: 10px;

        .guie-sec {
            padding-bottom: 10px;

            h1 {
                font-size: 13px;
                font-weight: 600;
                color: #991B1B;
                margin-bottom: 3px;
            }

            li {
                color: #B91C1C;
                font-size: 13px;
                font-weight: 400;
            }
        }

        .guie-sec:nth-last-child(1) {
            padding-bottom: 0;
        }
    }

    h4 {
        font-size: 14px;
        font-weight: 600;
        color: #0f172a;
    }

    .ant-radio-group {
        display: flex;
        align-items: center;

        .ant-radio-wrapper {
            display: flex;
            align-items: center;
            white-space: nowrap;

            .ant-radio {
                top: 0;
            }
            .ant-radio-checked::after{
                border: 1px solid $primary-color;
            }

            span {
                color: #0F172A;
                font-size: 13px;
                font-weight: 500;
                font-family: Poppins;
            }
        }
        .ant-radio-wrapper:hover{
            .ant-radio{
                border-color: $primary-color;
                .ant-radio-inner{
                    border-color: $primary-color;
                }
            }
        }

        .ant-radio-wrapper-checked {
            .ant-radio-checked {
                .ant-radio-inner {
                    border-color: $primary-color;
                    background-color: $primary-color;
                }
            }
        }
    }

    .gu-img-sec {
        width: 100%;
        position: relative;
        padding-top: 15px;

        .gui-img {
            .guii-btns {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 15px 25px;
                position: absolute;
                top: 25px;

                .preview-img {
                    color: #FFF;
                    font-size: 11px;
                    font-weight: 600;
                    background: #0F172A;
                    padding: 0px 3px;
                }

                .expand-img {
                    padding: 3px;
                    background-color: #fff;
                }

                .expand-img:hover {
                    background-color: #000;

                    svg {
                        path {
                            stroke: #fff;
                        }
                    }
                }

            }

            .guii-img {
                width: 100%;
                height: 360px;
                display: flex;
                align-items: center;
                justify-content: center;
                // padding: 10px;
                // border: 1px solid #ECECEC;

                video {
                    width: 100%;
                    height: 100%;
                }

                img {
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }

                .guii-no-img {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background: #F2F4F7;

                    p {
                        color: #5F677A;
                        font-size: 16px;
                        font-weight: 500;
                        margin: 0;
                    }
                }
            }
        }

        .gui-sub-img {
            width: 100%;
            max-width: 100%;
            overflow: auto;
            display: flex;
            align-items: center;
            background: #ECECEC;
            padding: 10px;

            .guis {
                display: flex;
                align-items: center;
                flex-direction: column;
                padding-right: 15px;

                p {
                    width: 100%;
                    color: #FFF;
                    font-size: 10px;
                    font-weight: 600;
                    background: #A6A8AC;
                    padding: 0 3px;

                    span {
                        color: #d0021b;
                    }
                }

                .remove-p {
                    background: $danger-color;
                    cursor: pointer;
                }

                button {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 3px solid #FFF;
                    background: #D9D9D9;
                    position: relative;

                    input {
                        width: 100%;
                        height: 100%;
                        left: 0;
                        cursor: pointer;
                    }

                    .guii-img {
                        img {
                            width: 100%;
                            height: 100%;
                        }

                        .guii-normal-no-img {
                            svg {
                                width: 24px;
                                height: 24px;

                                path {
                                    fill: #0f172a;
                                }
                            }
                        }
                    }
                }

                .guis-btn-disable {
                    cursor: not-allowed !important;

                    input {
                        cursor: not-allowed;
                    }
                }
            }
        }
    }
}

// Map Tooltip\
.map-tooltip {
    display: block;
    font-size: 12px;
    color: #fff;
    background-color: #000;
    font-weight: 500;
    text-align: left;
    z-index: 9;
    width: 150px;
    padding-left: 10px;

    .mt-row {
        display: flex;
        align-items: center;
        margin: 3px 0;

        h3 {
            font-size: 12px;
            font-weight: 600;
            color: #ffffff;
            margin: 0;
        }

        p {
            font-size: 10px;
            font-weight: 500;
            color: #ffffff;
            margin: 0;
            margin-left: 7px;
        }
    }
}

// Download Document Dropdown
.download-document-dropdown {
    min-width: 270px;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    z-index: 9;
    box-shadow: 0 4px 12px 0 rgba(75, 75, 75, 0.16);

    button {
        width: 100%;
        height: 100%;
        justify-content: space-between;
        padding: 7px 10px;
        border: none;
        border-radius: 0;
        margin: 0;

        img {
            width: 16px;
            height: 16px;
            object-fit: cover;
            // margin-right: 10px;
        }

        label {
            white-space: nowrap;
            font-size: 12px;
            font-weight: 500;
            color: #0F172A;
            margin: 0 15px 0 0;
            cursor: pointer;

        }

        button {
            width: fit-content;
            font-size: 10px;
            font-weight: 500;
            color: #0F172A;
            border: 1px solid #0F172A;
            border-radius: 0;
            padding: 1px 7px;
            margin-left: 10px;
            border-radius: 20px;
        }

        button:hover {
            // background-color: #0F172A;
            // color: #fff;
            color: #3498db;

        }

        // .printer-barcode{
        //     min-width: 200px;
        //     max-height: 150px;
        //     // overflow: auto;
        //     position: absolute;
        //     top: 0;
        //     right: 251px;
        //     background-color: #fff;
        //     z-index: 9;
        //     box-shadow: 0 4px 12px 0 rgba(75, 75, 75, 0.16);
        //     .pb-data{
        //         .pbd, .pbd-inner{
        //             display: flex;
        //             align-items: center;
        //             justify-content: space-between;
        //             padding: 5px 10px;
        //             cursor: pointer;
        //             p{
        //                 font-size: 12px;
        //                 font-weight: 500;
        //                 color: #0F172A;
        //                 margin: 0;
        //             }
        //         }
        //         .pbd:hover{
        //             background-color: #f5f5f5;
        //         }
        //         .pbd-inner{
        //             p{
        //                 font-weight: 400;
        //             }
        //         }
        //         .pbd-inner:hover{
        //             background-color: #f5f5f5;
        //             p{
        //                 font-weight: 500;
        //             }
        //         }
        //     }
        // }
    }

    button:hover {
        background-color: #f5f5f5;
    }
}

.filter-main-section {
    position: absolute;
    top: 0;
    width: 40%;
    height: 100%;
    right: 0;
    background-color: #fff;

    .fms-body {
        height: calc(100% - 116px);
        padding: 0 15px;
        overflow: auto;

        th {
            display: block;
            width: 100%;
            margin-bottom: 8px;

            .dropdown-menu-city1 {
                top: 45px;
            }

            .asnfbb-right {
                position: relative;

                .generic-smart-input {
                    padding-left: 5px;

                    .gsi-selected {
                        margin-left: 0;
                    }

                    .gsis-clear {
                        bottom: 6px;
                        right: 5px;

                        svg {
                            path {
                                fill: #627da8;
                            }
                        }
                    }
                }

                .asnfbb-from,
                .asnfbb-to,
                .fms-input {
                    height: 32px;
                    border-radius: 5px;
                    border: solid 1px #dce6f0;
                    width: 100%;
                    padding: 3px 10px;
                    font-size: 12px;
                    font-weight: 500;
                    color: #0F172A;
                }

                .gsis-clear {
                    // padding: 2px;
                    background-color: #f6f6f6;
                    border-radius: 20px;
                    position: absolute;
                    bottom: 1px;
                    right: 7px;
                    width: 18px;
                    height: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .fms-num-range {
                    display: flex;
                    align-items: center;

                    .fmsnr-input {
                        width: 50%;
                        position: relative;

                        .gsis-clear {
                            bottom: 7px;
                            right: 6px;
                        }

                        input {
                            width: 100%;
                            border: solid 1px #edeff0;
                        }

                        .asnfbb-from {
                            border-radius: 5px 0 0 5px;
                        }

                        .asnfbb-to {
                            border-radius: 0 5px 5px 0;
                            border-left: none;
                        }
                    }
                }

                .fth-label,
                .pnl-purchase-label {
                    font-size: 12px;
                    font-weight: 500;
                }

                .dropdown-menu-city1.gen-width-auto {
                    .dropdown-menu-city-item {
                        li {
                            .checkBoxLabel0 {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .gen-dropdown-pagination {
                        padding: 0 10px;
                    }
                }

                .asnfbbr-inner {
                    position: absolute;
                    background: #fff;
                    border-radius: 8px;
                    box-shadow: 0 4px 13px 4px hsla(0, 0%, 56%, 0.16);
                    min-width: 140px;
                    width: 100%;
                    padding: 10px 0px;
                    z-index: 99;
                    display: block;
                    top: 65px;

                    .asnfbbr-all-vendor {
                        display: block;
                        padding: 0;
                        max-height: 175px;
                        overflow: auto;

                        li {
                            display: block;
                            width: 100%;
                            padding: 5px 10px;
                            transition: 0.6s all;
                            cursor: pointer;

                            button {
                                padding: 0;
                                margin: 0;
                                height: auto;
                                background-color: transparent;
                            }

                            .asnfbbrav-code {
                                color: #97abbf;
                                display: block;
                                padding: 0;
                            }
                        }

                        li:hover {
                            background-color: $dropdown-item-hover;

                            button {
                                font-weight: 600;
                            }
                        }

                        span {
                            display: block;
                            padding: 5px 10px;
                            font-size: 11px;
                            font-weight: 500;
                        }

                        button {
                            display: block;
                            padding: 5px 20px;
                            font-size: 12px;
                            font-weight: 500;
                            border: none;
                        }
                    }
                }
            }
        }
    }
}

// Style Notification Com 

:where(.css-dev-only-do-not-override-1r287do).ant-notification .ant-notification-notice-wrapper .ant-notification-notice {
    padding: 15px 12px;
    width: 450px;

    .ant-notification-notice-close {
        top: 10px;
        inset-inline-end: 10px;
    }
}

.ant-notification-notice {
    margin-bottom: 0;
    box-shadow: 0 4px 21px -2px #3f3e3e26;
    border-radius: 0;

    .anticon-close-circle {
        background-color: $danger-color;
        border-radius: 20px;
        border: solid 2px $danger-color;
        margin-top: 4px;

        svg {
            path {
                fill: #fff;
            }
        }
    }

    .ant-notification-notice-icon {
        margin-top: 4px;

        svg {
            width: 18px;
            height: 18px;
        }
    }

    .ant-notification-notice-icon-success {
        background-color: #228900;
        border-radius: 20px;
        border: solid 2px #228900;
        margin-top: 4px;

        svg {
            path {
                fill: #fff;
            }
        }
    }

    .anticon-close {
        svg {
            width: 12px;
            height: 12px;

            path {
                fill: #575D6A;
            }
        }
    }

    .ant-notification-notice-content {
        .ant-notification-notice-with-icon {
            .ant-notification-notice-message {
                font-size: 16px !important;
                font-weight: 600;
                color: #0F172A !important;
            }

            .ant-notification-notice-description {
                font-weight: 400;
                font-size: 13px !important;
                color: #575D6A !important;
            }
        }
    }
}

// .modal-chat-container {
//     overflow: auto;
//     height: 75%;
//     width: 100%;
// }

.commentsModal {

    .enterIcon{
        svg{
            height: 8px;
            width: 8px;
        }
    }

    .custom-suggestion-menu{
        .bn-suggestion-menu {
            position: absolute !important;   /* Absolute positioning within the editor container */
            top: auto !important;            /* Position just below the editor */
            bottom: 50px !important;
            height: fit-content;
            width: max-content;
            max-height: 60vh !important;    /* Set a max height to prevent overflow issues */
            // left: 0 !important;              /* Align to the left of the editor */
            // z-index: 1000 !important;        /* High z-index to ensure it appears above other elements */
            overflow-y:  auto !important;     /* Allow scrolling if the content exceeds max height */
            // border: 2px solid green !important;
        }
    }

    // .mantine-Menu-dropdown{
    //     border: 2px solid red !important;
    // }
    
}

.messageBox .bn-editor {
    border-radius: 0;
    padding-inline: 10px;
    font-weight: 500;
    font-size: 14px;
    word-break: break-all;
}

.msgInput .bn-editor {
    padding-right: 5px;
}

.me .bn-editor {
    background-color: #E5F0FF;
}

.others .bn-editor {
    background-color: #F3F5F7;
}

.bn-suggestion-menu {
    background-color: #03B675;
}

.check-new-ed {
    opacity: 1;
        /* Ensure the side menu is fully visible */
        visibility: visible;
        /* Ensure the side menu is visible */
        transition: none !important;
        /* Remove any hover transition effects */
}

// .comments-col {

//     .comments-button {

//         .comment-icon {

//             // padding: 2px;
//             svg {
//                 // transform: scale(75%);
//                 background-color: #03B675;
//                 color: white;
//                 stroke: white;

//                 path {
//                     color: white;
//                 }
//             }
//         }
//     }

// }

.comments-col:hover {
    background: none !important;
    background-color: none !important;
}

.comments-without-count {
    .comments-icon-without-count {
        svg {
            height: 20px !important;
            width: 20px !important;
            color: #94a3b8;
            stroke: #94a3b8;

            path {
                color: #94a3b8;
                stroke: #94a3b8;
            }
        }
    }
}

.checkedIcon{
        rect{
            fill: $primary-color;
            stroke: $primary-color;
        }
}

.save-icon{
    path{
        fill: $primary-color;
    }
}

// .ant-message{
//     right: 2rem;
// }

// .comments-cross-btn {

//         .generic-default-btn{
//             padding: 0 !important;
//             border: none !important;
//             background: none !important;
//             height:auto !important;

//                 svg {
//                     path {
//                         stroke: white;
//                         stroke-width: 2px;
//                     }
//                 }
//         }

//         .generic-default-btn:hover{
//             background: none !important;
//         }

//     }

.no-fill-icon-button {
    .no-fill-icon{
        svg{
            fill: none !important;
        }
    }
}

// goods-in-transit
.barcode-reprint {
    height: 100%;
  
    .modal-header-section {
      align-items: center;
    }
  
    .br-body {
      padding: 0 15px;
  
      .brb-box {
        display: flex;
        padding: 15px 0 5px;
  
        .brbb-left,
        .brbb-right {
          width: 50%;
          border: 1px solid #dae4ee;
  
          .brbbl-top {
            height: 32px;
            padding: 5px 10px;
            position: relative;
            border-bottom: 1px solid #dae4ee;
  
            input {
              padding-right: 20px;
            }
  
            input::placeholder {
              font-weight: 600;
              font-size: 13px;
            }
  
            span {
              position: absolute;
              right: 5px;
              top: 7px;
            }
  
            h1 {
              font-weight: 600;
              font-size: 13px;
              color: #2a2a64;
              margin: 0;
            }
          }
  
          ul {
            height: 200px;
            overflow: auto;
  
            li {
              padding: 5px 10px;
  
              label {
                width: 100%;
              }
            }
  
            li:hover {
              cursor: pointer;
              background-color: #f5f5f5;
            }
          }
        }
  
        .brbb-right {
          border-left: none;
        }
      }
  
      .brb-det {
        display: flex;
        background: #edf3fa;
        border-radius: 5px;
        padding: 10px;
        margin-top: 10px;
  
        span {
          margin-right: 10px;
        }
  
        p {
          font-weight: 500;
          font-size: 12px;
          color: #2a2a64;
          margin: 0;
        }
      }
    }
  }

//   view sales orders > approve with edit
.approval_w_edit{
    .generic-spread-form{
        .gsf-body{
            height: calc(100% - 134px) !important;
            
            .gfb-inner{
                .generic-input-box:nth-child(3n){
                    .generic-tooltip {
                        left: auto !important;
                        right: 0 !important;
                        
                        &::after{
                            left: auto !important;
                            right: 15px !important;
                        }
                    }
                }
            }
        }
    }
}

.sales-agent-create-edit-modal {
    .show-selected-all{
        margin-top: 10px;
    }
}

.inventory-planning {
    .table-loader-bg {
        padding: 10px 15px 10px 30px !important;
    }
}
.tooltip-bottom {
    top: calc(100% + 4px) !important;
    bottom: auto !important;

    &::after {
        right: auto !important;
        left: 15px !important;
        top: auto;
        bottom: 100%;
        transform: rotate(180deg);
    }
}
.playIconWrapper:hover{
    .playIconSvg {
        // fill: $primary-color;
        path{
            stroke: $primary-color;
        }
        .pathFilled {
            fill: $primary-color;
        }
    }
}

.mdlr-li:hover {
    .openLink {
        font-weight: 500;
        color: $fontBlack;
        svg{
            color: $primary-color;
        }
    }
}

.tag-discrepencies-modal{
    .list-component-section {
        .lcs-inner{
            height: 100%;
        }
    }
}

.create-discrepency-modal{
    .gen-rowtype-component{
        display: flex;
        flex-direction: column;
        .grc-body{
            height: 100%;
            border: 1px solid $borderSlateGrey;
            border-radius: 6px;
            padding-right: 0;

            // .grcb-head{
            //     // padding-top: 0;
            //     // padding-bottom: 0;
            //     // border-right: 1px solid $borderSlateGrey;
            // }
        }
    }
}

.create-discrepency-modal{
    .grc-top{
  
      button{
        background-color: var(--primary-color) !important;
        border: none !important;
      }
      button:hover{
        opacity: .75;
      }
    }
  
    .gib-inner{
      border-radius: 0 !important;
    }
    .gib-label-heading{
      color: var(--fontBlack) !important;
      font-weight: 500 !important;
    }
  
    // .generic-spread-form {
    //   .gsf-body{
        .gen-rowtype-component{
          /* margin-top: -38px; */
          .grc-body{
            min-height: 150px;
            /* max-height: 300px; */
            // height: calc(100% - 50px);
            .grcb-head{
              padding-left: 38px;
              padding-top: 0;
              padding-bottom: 0;
              position: sticky;
              top: 0;
              z-index: 10;
              border-bottom: 1px solid #DAE4EE;
  
                .gib-label-heading{
                  padding-top: 8px;
                  padding-bottom: 8px;
                  color: #6A6C70 !important;
                  text-transform: capitalize;
                  font-weight: 500;
                  width: 190px;
                }
                .gib-label-heading:not(:last-child){
                  border-right: 1px solid #DAE4EE;
              }
              .gib-label-heading:first-child{
                border-left: 1px solid #DAE4EE;
                width: 191px;
            }
            }
            .grcb-head::before{
                margin-right: 8px;
                content: "";
                background: #F8F8F8;
                position: sticky;
                left: 0;
                margin-left: -47px;
                z-index: 2;
                width: 39px;
                height: 35.5px;
                border-right: 1px solid $borderSlateGrey;
            }
            div{
              .generic-form-body{
                // border-top: 1px solid #DAE4EE;
                border-bottom: 1px solid #DAE4EE;
                .gfb-multiple-row{
                  padding: 0 !important;
                  .gfbi-btns{
                    height: 40px;
                    padding-inline: 12px;
                    border-right: 1px solid #DAE4EE;
                  }
                  .generic-input-box{
                    width: 190px;
                    position: relative !important;
                    padding: 4px !important;
                    top: auto;
                      .gib-inner{
                        border-radius: 0 !important;
                      }
                      .gib-inner>svg{
                        margin-right: 4px;
                        flex-shrink: 0;
                        height: 14px;
                        width: auto;
                        max-width: 16px;
                      }
                      .list-component-section{
                        border-radius: 0 !important;
                        left: 4px;
                        .spinnerClassName{
                            width: 179px;
                        }
                      }
                  }
                  .generic-input-box:first-child{
                    width: fit-content;
                    position: sticky !important;
                    left: 0;
                    z-index: 1;
                    background-color: white;
                    padding: 5px 4px !important;
                  }
                  .generic-input-box:not(:last-child){
                    border-right: 1px solid #DAE4EE;
                  }
                }
              }
            }
          }
        }
    //   }
    //   .generic-form-footer{
    //     .gff-right{
    //       .generic-default-btn{
    //         background-color: white !important;
    //         border: 1px solid black !important;
    //         color: var(--fontBlack) !important;
    //       }
    //       .generic-default-btn:hover{
    //         opacity: .75;
    //       }
    //       .generic-default-btn, .generic-approve-btn {
    //         min-width: 100px;
    //       }
    //       .generic-approve-btn{
    //         margin-left: 0 !important;
    //       }
    //     }
    //   }
    // }
  
    /* generic */
    // button{
    //   border-radius: 0 !important;
    // }
  }
  
  .create-asn-modal {
    .ant-toggle {
        .ant-switch {
            width: 70px;
            background-color: #efefef;

            .ant-switch-handle {
                width: 14px;
                height: 14px;
                top: 4px;
            }

            .ant-switch-handle::before {
                background-color: #969fad;
                transform: translateX(2px);
            }

            .ant-switch-inner {
                span {
                    font-size: 11px;
                    font-weight: 600;
                    color: #12203c;
                }

                .ant-switch-inner-checked {
                    display: none;
                }

                .ant-switch-inner-unchecked {
                    margin-inline-start: -31px;
                    margin-top: 0;
                    margin-inline-end: 0;
                }
            }
        }

        .ant-switch-checked {
            background-color: #e3fbed;

            .ant-switch-handle::before {
                background: #3ee585;
            }

            .ant-switch-inner {
                .ant-switch-inner-checked {
                    display: block;
                    margin-inline-start: -7px;
                }

                .ant-switch-inner-unchecked {
                    display: none;
                }
            }
        }

        .ant-switch-checked:hover:not(.ant-switch-disabled) {
            background-color: #e3fbed;
        }
    }
  }

//   scrollbar
.thin-scrollbar::-webkit-scrollbar {
    height: 4px !important;
    background: transparent !important;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
    background: transparent !important; /* Initially hidden */
    border-radius: 4px !important;
}

.thin-scrollbar:hover::-webkit-scrollbar-thumb {
    background: #eaeaee !important;
}
