@import '../variables.scss';

.tip-assortment{
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 6px;
    padding: 15px;
    margin: 10px 0 0;
    .item-assortment{
        padding-right: 25px;
        p{
            color: #111D31;
            margin: 0;
            font-size: 18px;
            font-weight: 700;
            display: flex;
            align-items: center;
            svg{
                margin-right: 10px;
            }
        }
        span{
            color: #67768E;
            margin: 0;
            font-size: 13px;
            font-weight: 400;
        }
    }
    .total-assortment{
        padding-right: 25px;
        p{
            color: #111D31;
            margin: 0;
            font-size: 18px;
            font-weight: 700;
            display: flex;
            align-items: center;
            svg{
                margin-right: 10px;
            }
        }
        span{
            color: #67768E;
            margin: 0;
            font-size: 13px;
            font-weight: 400;
        }
    }
    .pending-assortment{
        padding-right: 25px;
        p{
            color: #FA983A;
            margin: 0;
            font-size: 18px;
            font-weight: 700;
            display: flex;
            align-items: center;
            u{
                color:  #111D31;
                margin: 0;
                font-size: 13px;
                font-weight: 500;
                padding-left: 10px;
                cursor: pointer;
            }
            svg{
                margin-right: 10px;
            }
        }
        span{
            color: #67768E;
            margin: 0;
            font-size: 13px;
            font-weight: 400;
        }
        .dwnlod-mbq{
            color: #FA983A;
            font-size: 13px;
            font-weight: 500;
            margin: 0;
            padding-left: 10px;
            cursor: pointer;
        }
    }
    .delete-assortment{
        padding-right: 25px;
        p{
            color:$danger-color;
            margin: 0;
            font-size: 18px;
            font-weight: 700;
            display: flex;
            align-items: center;
            span{
                font-size: 12px;
                color: $danger-color;
                font-weight: 400;
                padding-left: 5px;
            }
            svg{
                margin-right: 10px;
            }
        }
        span{
            color: #67768E;
            margin: 0;
            font-size: 13px;
            font-weight: 400;
        }
        u{
            color: $danger-color;
            font-size: 13px;
            font-weight: 500;
            margin: 0;
            padding-left: 10px;
            cursor: pointer;
        }
    }
}

// Style New Summary Page
.summary-page-status {
    width: 100%;
    display: flex;
    padding: 10px 15px 0 30px;
    .sps-head {
        width: 65%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
        background-color: #fff;
        z-index: 3;
        .spsh-left {
            display: flex;
            .spsh-job {
                display: flex;
                align-items: center;
                .spshj-job-id{
                    display: flex;
                    align-items: center;
                    p {
                        font-size: 12px;
                        font-weight: 400;
                        color: #0F172A;
                        margin-bottom: 0;
                        white-space: nowrap;
                    }
                    .spsht-heading {
                        font-size: 12px;
                        font-weight: 600;
                        color: #0F172A;
                        max-width: 125px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        position: relative;
                        min-width: 110px;
                    }
                }
                button{
                    color: #0F172A;
                    font-size: 10px;
                    font-weight: 500;
                    border: 1px solid #DAE4EE;
                    padding: 3px 10px;
                    margin-left: 10px;
                    white-space: nowrap;
                }
                button:hover{
                    background-color: #f5f5f5;
                }
                .spshj-det-icon{
                    position: relative;
                    margin-left: 10px;
                    svg{
                        width: 16px;
                        height: 16px;
                    }
                    .spshjd-dropdown{
                        min-width: 230px;
                        position: absolute;
                        right: 0;
                        top: 25px;
                        border-radius: 6px;
                        background: #FFF;
                        box-shadow: 0px 6.71px 18.99px 0px rgba(160, 160, 160, 0.26);
                        padding: 10px 0 10px 15px;
                        visibility: hidden;
                        opacity: 0;
                        .spshjdd-det{
                            padding-right: 40px;
                            h3{
                                color: #9CA3AF;
                                font-size: 10px;
                                font-weight: 400;
                                margin: 0;
                                white-space: nowrap;
                            }
                            p{
                                font-size: 12px;
                                font-weight: 600;
                                color: #0F172A;
                                margin-bottom: 0;
                                white-space: nowrap;
                            }
                        }
                    }
                }
                .spshj-det-icon:hover{
                    .spshjd-dropdown{
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }    
        }
        .spsh-right {
            display: flex;
            align-items: center;
            padding-left: 20px;
            .generic-input-box{
                display: flex;
                align-items: center;
                border: 1px solid #DAE4EE;
                padding: 5px;
                margin: 0;
                .gib-label-heading{
                    width: fit-content;
                    color: #0F172A;
                    font-weight: 400;
                    white-space: nowrap;
                    margin: 0 5px 0 0;
                }
                .gib-inner {
                    height: auto;
                    border: none;
                    input{
                        font-size: 11px;
                        color: #0F172A;
                    }
                    .generic-tooltip{
                        top: -30px;
                    }
                    .generic-tooltip::after{
                        display: none;
                    }
                }
                .gib-inner:hover{
                    background-color: #fff;
                    input{
                        background-color: #fff;
                    }
                }
                .focusOn{
                    border: none !important;
                    box-shadow: none !important;
                }

                .list-component-section {
                    top: 31px;
                }
            }
            .spshr-btns{
                display: flex;
                align-items: center;
                position: relative;
                button{
                    display: flex;
                    align-items: center;
                    color: #0F172A;
                    font-size: 11px;
                    font-weight: 600;
                    border: 1px solid #DAE4EE;
                    white-space: nowrap;
                    padding: 5px 7px;
                    margin-left: 10px;
                    svg{
                        margin-left: 5px;
                    }
                }
                button:hover{
                    background-color: #f5f5f5;
                }
                .summary-drop{
                    position: absolute;
                    border-radius: 6px;
                    box-shadow: 0 2px 7px 0 rgba(169, 156, 156, 0.25);
                    background-color: #fff;
                    padding: 0;
                    min-width: 250px;
                    top: 35px;
                    // right: 0;
                    left: 10px;
                    z-index: 9;
                    li{
                        display: flex;
                        align-items: center;
                        padding: 7px 15px;
                        cursor: pointer;
                        color: #21314b;
                        font-size: 12px;
                        font-weight: 500;
                        text-align: left;
                        svg{
                            margin-right: 10px;
                        }
                    }
                    li:hover{
                        background: #f8fafe;
                    }
                }
            }
        }
    }
    .filter-summary{
        width: 35%;
        margin-left: 10px;
    }
}

// Style Summary error
.summary-page-error {
    padding: 15px 15px 0 30px;
    .sps-error-msg {
        padding: 15px;
        background-color: #fff;
        box-shadow: 0 1px 10px 0 rgba(180,197,214,.65);
        .spsem-msg {
            display: flex;
            align-items: center;
            font-size: 13px;
            font-weight: 700;
            color: #12203c;
            svg {
                margin-right: 10px;
            }
        }
        p {
            font-size: 12px;
            font-weight: 500;
            margin: 5px 0;
            margin-left: 25px;
        }
    }
}

// Style Vendor Custom Data Upload
.custop-data-upload {
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 150px;
    background-color: transparent;
    position: relative;
    z-index: 3;
    padding: 0 15px 0 30px;
    margin-top: 10px;
    .cdu-inner{
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 5px 10px;
        background-color: #fff;
        border-radius: 6px;
        .cdu-left{
            .cdu-head {
                h3 {
                    font-size: 17px;
                    font-weight: 700;
                }
                p {
                    font-size: 12px;
                    margin: 0;
                    font-weight: 500;
                    color: #97abbf;
                }
            }
            .cdu-body{
                .cdub-select-upload{
                    margin: 20px 0;
                    label {
                        font-size: 12px;
                        font-weight: 500;
                        color: #3a5074;
                    }
                    .cdubsu-select{
                        position: relative;
                        display: flex;
                        align-items: flex-end;
                        .generic-input-box{
                            margin-right: 15px;
                            .gib-label-heading{
                                margin-left: 0 !important;
                            }
                            .gib-upload {
                                height: 32px;
                                background-color: #fff;
                                border: 1px dashed #dae4ee;
                                .gib-upload{
                                    height: 30px;
                                    border: none;
                                    cursor: pointer;
                                    padding: 10px 0;
                                    input {
                                        display: block !important;
                                    }
                                    .gibu-label{
                                        display: flex;
                                        align-items: center;
                                        padding: 0 5px;
                                        svg{
                                            margin-right: 5px;
                                        }
                                        h4{
                                            font-size: 12px;
                                            font-weight: 500;
                                            margin: 0;
                                        }
                                    }
                                }
                                .gib-upload:hover{
                                    background-color: #FFFFFF;
                                }
                                .gib-upload:hover{
                                    background-color: #f5f5f5;
                                    .gib-upload{
                                        background-color: #f5f5f5;
                                    }
                                }
                            }
                            button:hover{
                                background-color: #f5f5f5;
                            }

                            .list-component-section{
                                top: 57px;
                            }
                        }
                        .generic-input-box:nth-child(2){
                            .gib-label-heading{
                                margin-left: 10px;
                            }
                        }
                    }
                    .cdub-expected-header {
                        display: block;
                        padding: 10px 0;
                        h5{
                            font-weight: 600;
                            color: #3a5074;
                            font-size: 13px;
                            margin-bottom: 10px;
                            display: inline-block;

                        }
                        p {
                            font-size: 11px;
                            margin: 0;
                            font-weight: 500;
                            color: #3a5074;
                            max-width: 808px;
                            word-break: break-word;
                        }
                        button {
                            font-size: 12px;
                            font-weight: 500;
                            color: #9b7e00;
                            border-radius: 19px;
                            box-shadow: 0 3px 14px 0 #f8f7fe;
                            background-color: #fff4c9e3;
                            padding: 3px 7px;
                            margin-left: 5px;
                        }
                        .cdub-download {
                            color: #fff;
                            background-color: $primary-color;
                            font-weight: 600;
                            padding: 5px 13px;
                            margin-left: 20px;
                        }
                    }
                }
            }
        }
        .cdu-right {
            display: block;
            position: relative;
            padding: 40px 45px 10px 70px;
            .cdur-uploaded-file {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 12px;
                font-weight: 500;
                padding: 5px 12px;
                height: 42px;
                box-shadow: 2px 2px 8px 0 rgba(220,230,240,.89);
                margin-bottom: 10px;
                img{
                    width: 18px;
                    margin-right: 10px;
                }
            }
            .cdur-selected-file {
                display: block;
                padding: 20px 0;
                margin-bottom: 10px;
                p {
                    font-size: 12px;
                    font-weight: 600;
                    margin-bottom: 5px;
                }
                .cdursf-last-mod {
                    font-size: 11px;
                    margin: 0;
                    font-weight: 500;
                    color: #97abbf;
                    .cdursf-time {
                        color: #252323;
                    }
                }
            }
            .cdur-download-now {
                display: block;
                width: 100%;
                padding: 8px 15px;
                border-radius: 6px;
                color: #ffffff;
                font-size: 12px;
                font-weight: 600;
            }
            a {
                text-decoration: none;
            }
            a:hover {
                text-decoration: none;
            }
        }
        .cdu-right:after{
            content: "";
            position: absolute;
            top: -5px;
            left: 25px;
            width: 1px;
            height: 251px;
            background-color: #dce6f0;
        }
    }
}

.inputTextKeyFucMain {
    .mrl-input{
        display: inline-block;
        width: 50%;
        height: 34px;
        border-radius: 5px 0 0 5px;
        border: solid 1px #dce6f0;
        background-color: #ffffff;
        font-size: 12px;
        font-weight: 500;
        padding: 5px;
        color: #12203c;
    }
    .mrl-input1{
        display: inline-block;
        width: 50%;
        height: 34px;
        border: solid 1px #dce6f0;
        background-color: #ffffff;
        font-size: 12px;
        font-weight: 500;
        padding: 5px;
        border-left: none;
        color: #12203c;
        border-radius: 0 5px 5px 0;
    }
}

// Style Custom Header Modal
.custom-search-modal {
    width: 80vw;
    height: 80vh;
    max-width: 1300px;
    max-height: 800px;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    background: #fff;
    overflow: hidden;
    border: none;
    box-shadow: none;
    border-radius: 0;
    padding-bottom: 20px;
    .csm-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 25px;
        height: 65px;
        border-bottom: 1px solid #dae4ee;
        .csmh-left {
            h3 {
                font-size: 14px;
                font-weight: 700;
                color: #12203c;
                margin: 0;
            }
        }
        .csmh-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            button {
                position: relative;
                height: 32px;
                font-size: 12px;
                margin-left: 15px;
            }
            .csmhr-apply {
                padding: 4px 20px;
                background: $primary-color;
                box-shadow: none;
                color: #fff;
            }
            .csmhr-close {
                box-shadow: none;
                color: #67768e;
                padding: 5px;
                width: auto;
            }
        }
    }
    .subscription-tab {
        .subscription-tab-list {
            border-top: none !important;
            padding: 0 12px;
            position: relative;
            li {
                a {
                    color: #21314B;
                }
            }
            .active {
                .st-btn {
                    color: #21314B;
                }
                .st-btn::before {
                    background-color: #111D31;
                }
            }
            .stl-clear-all {
                position: absolute;
                right: 0;
                top: 1px;
            }
        }
    }
    .item-filters {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 10px 25px;
        overflow: auto;
        .asnfb-head {
            display: table;
            width: 100%;
            .asnfb-left {
                font-size: 14px;
                font-weight: 600;
                color: #12203c;
                display: block;
                width: 40%;
                float: left;
            }
            .asnfb-right {
                font-size: 14px;
                font-weight: 600;
                color: #12203c;
                display: block;
                width: 60%;
                float: left;
            }
        }
        .asnfb-body {
            display: block;
            width: 100%;
            padding: 10px 0;
            .asnfb-item {
                display: table;
                width: 100%;
                margin-bottom: 10px;
            }
            .recentAdded {
                position: absolute;
                top: 56px;
                left: 167px;
                background: #fff;
                box-shadow: 0 0 6px 0 hsla(0,0%,56%,.16);
                width: 200px;
                height: 320px;
                z-index: 99;
                .recentAdded-inner{
                    height: 285px;
                    overflow: auto;
                }
                ul{
                    position: relative;
                    padding: 8px 0;
                    margin: 0;
                }
                li{
                    padding: 0 15px;
                    font-weight: 12px;
                }
                label{
                    font-size: 12px;
                    font-weight: 400;
                }
                .checkmark1 {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 17px;
                    width: 17px;
                    background-color: #c72323;
                }
                .checkmark1:after {
                    left: 5px;
                    top: 3px;
                    width: 6px;
                    height: 9px;
                }
                .asnfra-bottom {
                    display: block;
                    width: 100%;
                    position: absolute;
                    height: 45px;
                    left: 0;
                    bottom: 0;
                    background-color: #ffffff;
                    padding: 5px 10px;
                    box-shadow: 0 0 6px 0 hsla(0,0%,56%,.16);
                    z-index: 999;
                    .asnfrb-rmall {
                        font-size: 12px;
                        display: inline-block;
                        padding: 3px 5px;
                        font-weight: 400;
                        .asnfrb-img{
                            display: inline-block;
                            padding: 3px;
                            border: 1px solid #ccd8ed;
                            border-radius: 5px;
                        }
                    }
                    .asnfrb-done {
                        font-size: 12px;
                        padding: 7px 5px;
                        border: 1px solid #ccd8ed;
                        border-radius: 5px;
                        font-weight: 400;
                        float: right;
                        color: #12203c;
                    }
                }
            }
            .asnfbb-left {
                position: relative;
                display: block;
                width: 40%;
                float: left;
                input[type="radio"] {
                    display: none;
                }
                .checkmark1 {
                    position: absolute;
                    left: -25px;
                    top: 7px;
                    height: 16px;
                    width: 16px;
                    background-color: #ffffff;
                    cursor: pointer;
                    border-radius: 4px;
                    border: solid 2px #dce6f0;
                }
                .checkmark1:after {
                    width: 5px;
                    height: 9px;
                    border-width: 0 2px 2px 0;
                    content: none;
                }
                input[type="radio"]:checked + label {
                    color: #12203c;
                }
                .checkBoxLabel0 input:checked~.checkmark1{
                    background-color: $primary-color;
                    border: none;
                }
                .checkBoxLabel0 input:checked~.checkmark1:after{
                    width: 5px;
                    height: 9px;
                    border-width: 0 2px 2px 0;
                    content: "";
                }
                label{
                    font-size: 12px;
                    font-weight: 500;
                    color: #12203c;
                    padding-left: 0;
                    cursor: pointer !important;
                    margin-left: 24px;
                    margin-bottom: 0;
                    padding: 6px 0;
                }
                label img{
                    position: relative;
                    display: inline-block;
                    background-color: #dce6f0;
                    border-radius: 4px;
                    width: 17px;
                    height: 17px;
                    padding: 0 5px;
                    margin-right: 10px;
                }
                label:before, label:after{
                    content: none;
                }
                input[type="radio"]:checked + label img{
                    background-color: $primary-color;
                }
            }
            .asnfbb-right {
                display: block;
                width: 60%;
                float: left;
                position: relative;
                input[type=date]{
                    width: 100%;
                    height: 30px;
                    border-radius: 5px;
                    border: solid 1px #a4b9dd;
                    background-color: #ffffff;
                    padding: 5px 10px;
                    font-size: 12px; 
                    font-weight: 500;
                }
                input[type=text]{
                    width: 100%;
                    height: 30px;
                    border-radius: 5px;
                    border: solid 1px #a4b9dd;
                    background-color: #ffffff;
                    padding: 5px 30px 5px 10px;
                    font-size: 12px;
                    background: transparent;
                    font-weight: 500;
                }
                .asnfbbr-input-arrow {
                    position: absolute;
                    right: 6px;
                    top: 4px;
                    border-radius: 10px;
                    padding: 7px 5px;
                }
                .asnfbbr-input-arrow:hover {
                    background: #ebedf0;
                }
                input[type=text][disabled] {
                    text-indent: 0;
                }
                input[type=number]{
                    width: 100%;
                    height: 30px;
                    border-radius: 5px;
                    border: solid 1px #a4b9dd;
                    background-color: #ffffff;
                    padding: 5px 10px;
                    font-size: 12px;
                    background: transparent;
                    -webkit-appearance: none;
                    font-weight: 500;
                }
                select{
                    width: 100%;
                    height: 30px;
                    margin-right: 0;
                    border-radius: 5px;
                    border: solid 1px #a4b9dd;
                    background-color: #ffffff;
                    padding: 5px 10px;
                    font-size: 12px;
                    background: transparent;
                    font-weight: 500;
                }
                .asnfbbr-poquantity{
                    margin-top: 10px;
                }
                .asnfbbr-inner {
                    position: absolute;
                    background: #fff;
                    border-radius: 8px;
                    box-shadow: 0 4px 13px 4px hsla(0, 0%, 56%, 0.16);
                    width: 100%;
                    padding: 10px 0px;
                    z-index: 99;
                    display: block;
                    .asnfbbr-all-vendor {
                        display: block;
                        padding: 0;
                        max-height: 175px;
                        overflow: auto;
                        li{
                            display: block;
                            width: 100%;
                            padding: 5px 10px;
                            transition: 0.6s all;
                            cursor: pointer;
                            button{
                                padding: 0;
                                margin: 0;
                                height: auto;
                                background-color: transparent;
                            }
                            .asnfbbrav-code{
                                color: #97abbf;
                                display: block;
                                padding: 0;
                            }
                        }
                        li:hover{
                            background-color: #f8f3ff;
                            button{
                                font-weight: 600;
                            }
                        }
                        span {
                            display: block;
                            padding: 5px 10px;
                            font-size: 11px;
                            font-weight: 500;
                        }
                        button{
                            display: block;
                            padding: 5px 20px;
                            font-size: 12px;
                            font-weight: 500;
                            border: none;
                        }
                    }
                }
                #genericSearchModalDropdown {
                    top: 20px;
                }
                .dropdown-menu-city1 {
                    .dropdown-menu-city-item {
                        .gwa-advance-filter {
                            .gwaaf-btn {
                                margin-left: 0;
                            }
                        }
                        .deselect-all {
                            border: none;
                            margin: 0;
                            border-top: 1px solid #dee6f4;
                            border-radius: 0;
                            margin-top: 3px;
                        }
                    }
                    .gen-dropdown-pagination {
                        display: flex;
                        align-items: center;
                        padding: 0 15px;
                        border-top: 1px solid #dce6f0;
                        height: 13%;
                        justify-content: center;
                        .page-next-prew-btn {
                            display: flex;
                            justify-content: center;
                            button {
                                display: inline-block;
                                border-radius: 6px;
                                border: none;
                                background-color: #fff;
                                font-size: 12px;
                                font-weight: 500;
                                color: #12203c;
                                transition: all .6s;
                                margin: 0;
                                height: 24px;
                                background-image: none;
                                width: auto;
                                white-space: normal;
                                text-align: center;
                            }
                        }
                    }
                }
            }
            .asnfbb-right.select {
                display: flex;
                width: 60%;
                float: left;
                position: relative;
                align-items: center;
                cursor: pointer;
                input {
                    padding-right: 50px;
                    text-overflow: ellipsis;
                    cursor: pointer;
                    img.asnfbbr-input-arrow:hover {
                        background: #ffffff;
                    }
                }
                input+img.asnfbbr-input-arrow:hover {
                    background: #ffffff;
                }
                .asnfbbr-select-count {
                    background: #ecedf7;
                    position: absolute;
                    right: 25px;
                    display: inline-flex;
                    align-items: center;
                    padding: 1px 5px;
                    font-weight: 600;
                    color: #67768e;
                    font-size: 11px;
                    border-radius: 2px;
                    cursor: pointer;
                }
                .dropdown-menu-city1 {
                    height: auto;
                    top: 30px;
                    left: unset;
                    transform: none;
                    right: 0;
                    max-width: 360px;
                    max-height: 270px;
                    overflow: auto;
                    .dropdown-menu-city-item {
                        li {
                            .checkBoxLabel0 {
                                white-space: normal;
                            }
                        }
                    }
                }
            }
            .asnfbb-right.not-checked{
                height: 30px;
                background-color: #f2f4f8;
                border-radius: 5px;
            }
        }
    }
    .csm-body {
        width: 100%;
        display: block;
        padding: 10px 25px;
        height: calc(100% - 65px);
        overflow: auto;
        padding-top: 0;
        .csmb-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 0;
            .csmbt-left {
                position: relative;
                display: flex;
                .gen-custom-select {
                    width: auto;
                }
                .csmbtl-col {
                    position: relative;
                    margin-left: 15px;
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                    p {
                        font-size: 12px;
                        font-weight: 500;
                        color: #12203c;
                        margin: 0;
                        padding-left: 5px;
                    }
                    .checkBoxLabel0{
                        width: 16px;
                        height: 16px;
                        padding-left: 8px;
                        margin: 0;
                        .checkmark1 {
                            left: 0px;
                            top: 0px;
                            height: 16px;
                            width: 16px;
                            background-color: #ffffff;
                            cursor: pointer;
                            border-radius: 4px;
                            border: solid 2px #dce6f0;
                        }
                        .checkmark1:after {
                            width: 5px;
                            height: 9px;
                            border-width: 0 2px 2px 0;
                            content: none;
                        }
                        input:checked~.checkmark1 {
                            background-color: $primary-color;
                            border: none;
                        }
                        input:checked~.checkmark1:after{
                            width: 5px;
                            height: 9px;
                            border-width: 0 2px 2px 0;
                            content: "";
                        }
                    }
                }
                button {
                    position: relative;
                    height: 32px;
                    padding: 4px 10px;
                    border-radius: 3px;
                    font-size: 12px;
                    font-weight: 500;
                    background: #fff;
                    color: #67768e;
                    border: 1px solid #dae4ee;
                    text-align: left;
                    min-width: 140px;
                    padding-right: 25px;
                    svg {
                        position: absolute;
                        right: 10px;
                        top: 13px;
                    }
                }
                .gcs-dropdown {
                    min-height: 40px;
                    max-height: 300px;
                    height: auto;
                }
            }
            .csmbt-right {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                button {
                    background-color: #fff;
                    border: none;
                    border-radius: 0;
                    padding: 3px;
                    color: #21314b;
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        }
    }
}
.csmb-bottom {
    border: solid 1px #dae4ee;
    border-radius: 4px;
    min-height: 220px;
    width: 100%;
    .csmbb-type-area {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: solid 1px #dae4ee;
        .csmbbta-left {
            display: flex;
            align-items: center;
            min-width: calc(100% - 200px);
            input {
                width: 100%;
                height: 60px;
                color: #8d99ac;
                font-size: 12px;
                font-weight: 500;
                padding: 5px 10px;
                border: none;
                background-color: #fff;
            }
            input::placeholder {
                color: #8d99ac;
                font-size: 12px;
                font-weight: 500;
            }
        }
        .csmbbta-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 10px;
            height: 62px;
            border-left: solid 1px #dae4ee;
            p {
                font-size: 11px;
                font-weight: 500;
                margin: 0;
                color: #67768e;
                text-align: center;
            }
        }
    }
    .csmbb-tags {
        display: block;
        padding: 10px;
        h3 {
            font-size: 12px;
            font-weight: 600;
            color: #21314b;
            margin-bottom: 15px;
        }
        .csmbbt-inner {
            display: flex;
            flex-wrap: wrap;
            .csmbbti-tag {
                font-size: 11px;
                font-weight: 600;
                color: #67768e;
                display: flex;
                align-items: center;
                padding: 4px 8px;
                border-radius: 20px;
                border: solid 1px #dae4ee;
                margin-bottom: 15px;
                margin-right: 15px;
                background-color: #fff;
                .csmbbtit-text {
                    display: block;
                    max-width: 250px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
            .csmbbtit-close {
                display: block;
                margin-left: 10px;
                cursor: pointer;
            }
            .csmbbti-tag:hover {
                background-color: $primary-color;
                border: solid 1px $primary-color;
                color: #fff;
            }
        }
    }
}

// Manage Adhoc Page Genric Design
.new-gen-head{
    display: flex;
    position: relative;
    width: 100%;
    min-height: 45px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dce6f0;
    .new-gen-left{
        display: flex;
        align-items: center;
        justify-content: start;
        position: relative;
        h3 {
            font-size: 16px;
            font-weight: 700;
            color: #12203c;
            margin: 0;
        }
        .nph-title{
            h2{
                font-size: 20px;
                font-weight: 700;
                color: #12203c;
            }
        }
        .ngl-search{
            position: relative;
            display: inline-block;
            margin-right: 20px;
            form{
                margin: 0;
            }
            input[type="search"] {
                border-radius: 5px;
                border: solid 1px #dce6f0;
                background: #fff;
                padding: 8px 30px;
                height: 32px;
                font-size: 11px;
                color: #000;
                font-weight: 600;
                width: 250px;
            }
            input::placeholder{
                font-size: 11px;
                font-weight: 500;
                color: #12203c;
            }
            .search-image{
                position: absolute;
                top: 8px;
                left: 7px;
                width: 15px;
            }
            .closeSearch {
                position: absolute;
                right: 10px;
                top: 10px;
                cursor: pointer;
            }
        }
        button{
            border-radius: 5px;
            border: solid 1px #ccd8ed;
            background-color: #ffffff;
            font-size: 12px;
            padding: 5px 15px;
            margin-right: 20px;
            height: 32px;
            font-weight: 500;
            position: relative;
            display: inline-block;
        }
        .ngl-back {
            border-radius: 21px;
            border: solid 2px #000;
            padding: 2px 10px;
            display: flex;
            align-items: center;
            height: 30px;
            .back-arrow {
                display: inline-block;
                vertical-align: middle;
                margin-right: 6px;
            }
        }
        .filter-btn{
            position: relative;
            padding: 5px 10px;
            img{
                width: 14px;
            }
            .fb-notifi{
                position: absolute;
                top: -9px;
                right: -6px;
                width: 16px;
                height: 16px;
                text-align: center;
                background-color: #eb0008;
                color: #ffffff;
                border-radius: 50%;
                font-size: 9px;
                font-weight: 500;
                padding: 2px 0;
            }
        }
        .global-search-tab {
            ul {
                li {
                    a {
                        .gsti-icon {
                            margin-right: 10px;
                            display: inline-block;
                        }
                        .gsti-icon:hover {
                            svg {
                                path {
                                    fill: #ffffff;
                                }
                            }
                        }
                    }
                }
                li.active {
                    a {
                        .gsti-icon {
                            svg {
                                path {
                                    fill: #ffffff;
                                }
                            }
                        } 
                    }
                }
            }
        }
    }
    .new-gen-right{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        button{
            border-radius: 5px;
            border: 1px solid #ccd8ed;
            background-color: #fff;
            font-size: 12px;
            padding: 5px 15px;
            margin-left: 20px;
            height: 32px;
            font-weight: 600;
            position: relative;
            display: inline-block;
        }
        button:hover {
            .generic-tooltip {
                visibility: visible;
                opacity: 1;
            }
        }
        .ngl-back {
            border-radius: 21px;
            border: solid 2px #000;
            padding: 3px 10px;
            display: flex;
            align-items: center;
            height: 30px;
            .back-arrow {
                display: inline-block;
                vertical-align: middle;
                margin-right: 6px;
            }
        }
        .save-draft {
            border: solid 1px #000;
        }
        .ngr-clear {
            border: none;
            padding: 0px;
        }
        .aprv-req-btn{
            color: #ffffff;
            background-color: $primary-color;
            border: 1px solid $primary-color;
            position: relative;
        }
        .indent-dropdown{
            position: absolute;
            top: 55px;
            right: 237px;
            height: auto;
            background-color: #fff;
            padding: 0;
            border: none;
            text-align: left;
            box-shadow: 0 1px 11px -4px #9ea0a1e6;
            transition: all .6s;
            z-index: 2;
            border-radius: 3px;
            li{
                border-bottom: 1px solid #dce6f0;
                padding: 7px 10px;
                .add-blank{
                    font-size: 12px;
                    font-weight: 500;
                    color: #12203c;
                }
            }
            li:hover{
                background-color: #d8daf599;
                cursor: pointer;
            }
        }
        .reject-btn{
            border: solid 1px #E66767;
            background-color: #E66767;
            color: #fff;
        }
        .new-trigger {
            color: #fff;
            background: $primary-color;
            border: solid 1px $primary-color;
            box-shadow: none;
            padding: 6px 8px;
            svg {
                margin-right: 5px;
            }
        }
        .gvpd-download-drop{
            display: inline-block;
            position: relative;
            .pi-history-download {
                display: block;
                position: absolute;
                top: 42px;
                right: 0;
                width: 205px;
                padding: 10px 15px;
                border-radius: 12px;
                box-shadow: 0 -1px 6px 0 rgba(177, 177, 177, 0.16);
                background: #ffffff;
                margin: 0;
                z-index: 9;
                li{
                    display: block;
                    button{
                        display: block;
                        padding: 0;
                        margin: 0;
                        border: none;
                        margin-bottom: 5px;
                        img{
                            width: 17px;
                        }
                        span{
                            display: inline-block;
                            padding: 5px 7px;
                            border-radius: 50%;
                        }
                        .pi-pdf-img{
                            width: 20px;
                            margin-left: 8px;
                        }
                        div{
                            display: inline-block;
                            .multipleDownloadSelect {
                                border: none;
                                padding: 0;
                                -webkit-appearance: none;
                                background: #fff !important;
                            }
                        }
                    }
                    .export-excel{
                        color: #a4b9dd;
                        span{
                            margin-right: 10px;
                        }
                    }
                    .export-excel:hover{
                        color: #12203c;
                        span{
                            background-color: #049d4b14;
                            svg{
                                path{
                                    fill: #049d4b;
                                }
                                text{
                                    fill: #049d4b;
                                }
                            }
                        }
                    }
                    .pi-pdf-download{
                        .pi-pdf-svg{
                            margin-right: 10px;
                        }
                        .btnDisabled{
                            color: #a4b9dd !important;
                        }
                    }
                    .pi-pdf-download:hover{
                        .pi-pdf-svg{
                            background-color: #eb000817;
                            svg{
                                path{
                                    fill: #eb0008;
                                }
                                text{
                                    fill: #eb0008;
                                }
                            }
                        }
                    }
                }
            }
        }
        .pi-download, .pi-download2 {
            width: 32px;
            padding: 0;
        }
        .pi-download2 {
            border: 1px solid #d8d3d3;
            svg {
                path {
                    fill: #d8d3d3;
                }
            }
        }
        .pi-download:hover{
            color: #fff;
            background-color: #4a5568;
            border: 1px solid #4a5568;
            transition: 0.3s all;
            svg path{
                fill: #fff;
            }
        }
        .pi-download-focus{
            color: #fff;
            background-color: #4a5568;
            border: 1px solid #4a5568;
            svg path{
                fill: #fff;
            }
        }
        .gvpd-filter{
            position: relative;
            .gvpd-filter-inner{
                width: 32px;
                padding: 0;
            }
            .gvpd-filter-inner:hover{
                color: #fff;
                background-color: #4a5568;
                border: 1px solid #4a5568;
                transition: 0.3s all;
                svg path{
                    fill: #fff;
                }
            }
            .gvpd-filter-inner-focus{
                color: #fff;
                background-color: #4a5568;
                border: 1px solid #4a5568;
                transition: 0.3s all;
                svg path{
                    fill: #fff;
                }
            }
            .noOfFilter {
                position: absolute;
                right: -9px;
                top: -7px;
                background-color: #eb0008;
                border-radius: 50%;
                padding: 2px 0;
                font-size: 10px;
                width: 17px;
                height: 17px;
                text-align: center;
                color: #fff;
            }
        }
        .excel-export{
            // border-radius: 25px;
            display: flex;
            align-items: center;
            background-color: #00ced6;
            color: #fff;
            border: 1px solid #00ced6;
            padding: 5px 15px 5px 7px;
            span{
                padding: 5px;
                border-radius: 50%;
                background-color: #28d6dd;
                width: 20px;
                height: 20px;
                display: inline-block;
                margin-right: 10px;
            }
        }
        .gen-cancel{
            border: solid 1px #E66767;
            background-color: #E66767;
            color: #fff;
            font-weight: 600;
        }
        .gen-approved{
            border: 1px solid $primary-color;
            background-color: $primary-color;
            color: #fff;
            padding: 5px 10px;
            font-weight: 600;
            img{
                margin-right: 7px;
                width: 17px;
            }
        }
        .add-btn{
            display: inline-block;
            position: relative;
            cursor: pointer;
            border: none;
            padding: 0px;
            background-color: transparent;
            border-radius: 50%;
            img{
                width: 27px;
            }
            .generic-tooltip {
                top: -30px;
                right: 50%;
                transform: translate(15%);
                width: 120px;
                left: auto;
            }
            .generic-tooltip:after {
                right: 10%;
                left: auto;
            }
        }
        .add-btn:hover>.generic-tooltip{
            visibility: visible;
            opacity: 1;
        }
        .get-details {
            border: solid 1px $primary-color;
            background-color: $primary-color;
            padding: 5px 25px;
            color: #ffffff;
        }
        .tree-dot-btn{
            width: 4px;
            height: 4px;
            background-color: #12203c;
            position: relative;
            border-radius: 5px;
            border: none;
            padding: 0;
        }
        .tree-dot-btn:after, .tree-dot-btn:before{
            position: absolute;
            content: '';
            left: 0;
            width: 4px;
            height: 4px;
            background-color: #12203c;
            border-radius: 5px;
        }
        .tree-dot-btn:after{
            bottom: -8px;
        }
        .tree-dot-btn:before{
            top: -8px;
        }
        .tdm-drop-down{
            width: 150px;
            height: 89px;
            border-radius: 8px;
            box-shadow: 0 1.5px 2px 0 #e6e6e6;
            background-color: #ffffff;
            position: absolute;
            top: 50px;
            right: 0;
            padding: 10px 15px;
            margin: 0;
            z-index: 9;
            .tdm-title-line {
                font-size: 11px;
                font-weight: 500;
                color: #82909f;
            }
            li {
                font-weight: 600;
                color: #12203c;
                font-size: 12px;
                padding: 2px 0;
            }
        }
    }
}
.new-gen-bot{
    padding: 15px 47px;
    label {
        font-size: 11px;
        color: #12203c;
        font-weight: 600;
        display: block!important;
    }
    .ngb-inputs{
        width: 250px;
        display: flex;
        align-items: center;
        .generic-input-box:nth-child(1){
            .gib-inner{
                border-right: none;
                border-radius: 4px 0 0 4px;
            }
        }
        .generic-input-box:nth-child(2){
            .gib-inner{
                border-radius: 0 4px 4px 0;
            }
        }
    }
}

// Bill Discount Summary
.bill-discount-day-summary {
    background: #fff;
    margin: 10px 15px 0 30px;
    border-radius: 6px;
    padding: 5px 10px;
    .bdds-top {
        display: block;
        h3 {
            font-size: 16px;
            font-weight: 700;
            margin: 0;
            color: #21314b;
            height: 27px;
        }
    }

    .bdds-bottom {
        display: flex;
        align-items: center;
        border-radius: 6px;
        padding: 5px 0;

        .bddsb-col {
            margin-right: 40px;
            position: relative;

            p {
                font-size: 12px;
                font-weight: 500;
                color: #627da8;
                margin-bottom: 7px;
            }

            h3 {
                font-size: 14px;
                font-weight: 700;
                margin: 0;
                color: #21314b;
                height: 27px;
            }

            .bddsb-rate-dis {
                padding: 3px 12px;
                border-radius: 13px;
                background-color: rgba(222, 230, 244, 0.51);
                font-size: 13px;
                font-weight: 700;
                color: #21314b;
                display: inline-block;
                height: 27px;
                .genmt-rupee-sign {
                    color: #97abbf;
                    margin-right: 5px;
                    font-family: system-ui;
                    font-weight: 400;
                }
            }

            .bddsb-rate-saved {
                padding: 3px 12px;
                border-radius: 13px;
                background-color: #d5ffe7;
                font-size: 13px;
                font-weight: 700;
                color: #35b16b;
                display: inline-block;
                height: 27px;
                .genmt-rupee-sign {
                    color: #35b16b;
                    margin-right: 5px;
                    font-weight: 400;
                    font-family: system-ui;
                }
            }
        }

        .bddsb-col-after::after {
            content: "";
            position: absolute;
            right: -20px;
            top: 50%;
            width: 1px;
            height: 22px;
            background-color: #cad9e8;
            transform: translate(-50%, -50%);
        }
    }
}