@import '../variables.scss';

// Style Home Page
.home-page {
    height: 100vh;
    min-height: 93vh;
    background-color: #f3f4f9;
    padding: 75px 0 0 60px;

    .home-page-main-layer {
        padding: 0;
        background: #ffffff;
        padding-bottom: 0;

        .hpml-inner {
            padding: 10px;
            background: #ebe8fe;

            .hpmli-div {
                padding: 30px;
                background: #ffffff;
                position: relative;

                .hpmli-top {
                    text-align: center;

                    .hpmlit-message {
                        p {
                            font-size: 13px;
                            font-weight: 500;
                            color: #21314b;
                        }
                    }

                    .hpmlit-time {
                        border-radius: 20px;
                        border: solid 1px #ebe8fe;
                        background-color: #ffffff;
                        font-size: 12px;
                        font-weight: 500;
                        padding: 6px 15px;
                        position: absolute;
                        top: 30px;
                        right: 30px;

                        .bold {
                            font-size: 12px;
                        }
                    }

                    .hpmlit-wlcm-text {
                        h3 {
                            font-size: 26px;
                            font-weight: 300;
                            color: #21314b;
                            margin: 0;
                        }

                        p {
                            font-size: 12px;
                            font-weight: 400;
                            color: #636e81;
                            padding-top: 10px;
                        }
                    }
                }

                .hpmli-body {
                    padding: 0 20px;
                    display: flex;
                    justify-content: center;

                    .hpmlib-box {
                        position: relative;
                        display: inline-block;
                        width: 33.33%;
                        text-align: center;
                        padding: 10px 15px;

                        .hpmlibb-icon {
                            display: inline-block;
                            width: 170px;
                            height: 170px;
                            padding: 30px;
                            border-bottom: 1px solid #d6dce6;

                            .hpmlibbi-bg {
                                display: inline-block;
                                width: 100%;
                                height: 100%;
                                background-image: linear-gradient(to bottom, $primary-color, #04cbd7);
                                border-radius: 50%;
                                padding: 5px;

                                .hpmlibbibg-inner {
                                    display: flex;
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 50%;
                                    background-color: #fff;
                                    align-items: center;
                                    justify-content: center;

                                    img {
                                        width: 50px;
                                    }
                                }
                            }
                        }

                        .hpmlibb-content {
                            padding: 20px 15px;

                            h3 {
                                font-size: 20px;
                                font-weight: 600;
                                color: #21314b;
                            }

                            p {
                                font-size: 12px;
                                font-weight: 500;
                                color: #636e81;
                                padding: 10px 15px;
                                letter-spacing: 0.28px;
                                margin-bottom: 25px;
                                height: 75px;
                            }

                            button {
                                position: relative;
                                box-shadow: 0 3px 6px 0 rgba(61, 168, 230, 0.5);
                                background-image: linear-gradient(to right, $primary-color, #00ced6);
                                font-size: 14px;
                                font-weight: 600;
                                color: #ffffff;
                                width: 180px;
                                height: 40px;
                                transition: all 0.5s;
                                padding: 9px 27px;

                                .hpmlibb-right-arrow {
                                    position: absolute;
                                    top: 14px;
                                    right: -20px;
                                    opacity: 0;
                                    visibility: hidden;
                                    transition: 0.5s;
                                }
                            }

                            button:hover {
                                transition: 0.5s;
                                padding: 9px 8px;
                                padding-right: 38px;

                                .hpmlibb-right-arrow {
                                    opacity: 1;
                                    visibility: visible;
                                    transition: 0.5s;
                                    right: 15px;
                                }
                            }

                            .hpmlibb-button {
                                display: inline-block;
                                box-shadow: 0 3px 6px 0 rgba(61, 168, 230, 0.5);
                                background-image: linear-gradient(to right, $primary-color, #00ced6);
                                font-size: 14px;
                                font-weight: 600;
                                color: #ffffff;
                                width: 180px;
                                height: 40px;
                                padding: 2px;
                                opacity: 0.5;

                                button {
                                    width: 100%;
                                    height: 100%;
                                    background-image: none;
                                    color: #ffffff;
                                    padding: 0;
                                    cursor: auto;
                                    background: transparent;
                                }
                            }
                        }
                    }

                    .hpmlib-blr:before,
                    .hpmlib-blr:after {
                        content: "";
                        position: absolute;
                        top: 90px;
                        width: 1px;
                        height: 200px;
                        background-color: #d6dce6;
                    }

                    .hpmlib-blr:before {
                        left: 0;
                    }

                    .hpmlib-blr:after {
                        right: 0;
                    }
                }
            }
        }
    }
}

// Dashboard Loader
.box-loader {
    width: 100%;
    height: 190px;
    border-radius: 6px;
    box-shadow: 0 0 16px 1px rgba(164, 164, 164, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0;
    background-color: #ffffff;

    .bl-circle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #ebebf3;
        position: relative;
        background: linear-gradient(100deg,
                rgba(255, 255, 255, 0) 40%,
                rgba(255, 255, 255, 0.5) 50%,
                rgba(255, 255, 255, 0) 60%) #ebebf3;
        background-size: 200% 100%;
        background-position-x: 180%;
        animation: 1s loadskeleton ease-in-out infinite;
    }

    .bl-squre-first,
    .bl-squre-second,
    .bl-squre-third {
        background-color: #ebebf3;
        border-radius: 2px;
        height: 18px;
        margin-top: 20px;
        position: relative;
        background: linear-gradient(100deg,
                rgba(255, 255, 255, 0) 40%,
                rgba(255, 255, 255, 0.5) 50%,
                rgba(255, 255, 255, 0) 60%) #ebebf3;
        background-size: 200% 100%;
        background-position-x: 180%;
        animation: 1s loadskeleton ease-in-out infinite;
    }

    .bl-squre-first {
        width: 80%;
    }

    .bl-squre-second {
        width: 50%;
    }

    .bl-squre-third {
        width: 60%;
    }

    @keyframes loadskeleton {
        to {
            background-position-x: -20%;
        }
    }
}

// Dashboard
.dashboard-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 0 12px 0 27px;
    position: sticky;
    top: 45px;
    z-index: 9;
    box-shadow: 0px 1px 44px 0px rgba(0, 0, 0, 0.07);

    .generic-tabs {
        padding: 0 !important;
        border-bottom: none;

        .gt-list {
            // font-weight: 600;
            color: #74767A;
            border-bottom: 2px solid transparent;
        }

        .gt-list:hover{
            color: $fontBlack;
            border-bottom: 2px solid #CECFD3;
        }

        .activeTab, .activeTab:hover  {
            color: $primary-color;
            // border-bottom: 2px solid $primary-color;
        }
    }

    .generic-input-box {
        width: 22%;
        margin: 0;
        cursor: pointer;

        .list-component-section {
            top: 35px;
        }

        .gib-dropdown{
            .generic-tooltip{
                top: 38px;
                left: auto;
                right: -32px;
            }
            .generic-tooltip::after{
                top: -10px;
                left: auto;
                right: 15%;
                border-color: transparent transparent #000;
            }
        }
    }

    .generic-input-box::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -17px;
        width: 1px;
        height: 14px;
        background-color: #c3d2e1;
        transform: translateY(-50%);
    }
}

.tcd-tranfer-order {
    width: 100%;
    padding: 12px 12px 12px 27px;

    .tcdt-card,
    .tcd-jobs,
    .tcd-status {
        width: 25%;
        height: auto;
        min-height: 150px;
        text-align: center;
        background: #fff;
        border-radius: 6px;
        z-index: 1;
        padding: 15px 0;
        margin-right: 12px;
        box-shadow: 0 0 16px 1px hsla(0, 0%, 64%, 0.25);
        transition: all 0.6s;
        cursor: pointer;

        span {
            width: 42px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            background-color: rgba(253, 55, 87, 0.2);
            border-radius: 50%;
        }

        .span-approved {
            background-color: rgba(0, 206, 181, 0.2);
        }

        .span-pending {
            background-color: #bddbff52;
        }

        h1 {
            font-size: 24px;
            font-weight: 800;
            color: #21314b;
            margin: 10px 0 6px;
        }

        p {
            font-size: 12px;
            font-weight: 600;
            color: #67768e;
            position: relative;
        }

        .tcdtc-status {
            padding-top: 5px;

            p {
                font-weight: 500;
                margin: 0;
            }

            h4 {
                font-size: 13px;
                color: #21314b;
                font-weight: 700;
                margin: 0;
            }
        }
    }

    .tcd-jobs {
        width: 14.28%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .p-normal {
            color: #21314b;
            margin-top: 15px;
        }

        h3 {
            font-size: 14px;
            font-weight: 700;
            color: #21314b;
            text-transform: uppercase;
            margin: 0;
        }

        .h3-succeed {
            color: #00baa1;
        }
    }

    .tcd-status {
        width: 25%;
        display: flex;
        padding: 0;

        h1 {
            margin-bottom: 0;
        }

        .tcds-left {
            width: 50%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
        }

        .tcds-right {
            width: 50%;
            height: 100%;
            border-left: 1px solid #e6e9ec;

            p::before,
            .p-failed::before {
                content: "";
                position: absolute;
                top: 6px;
                left: -11px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: #00ceb5;
            }

            .p-failed::before {
                background-color: $danger-color;
            }

            .tcdsr-top {
                height: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border-bottom: 1px solid #e6e9ec;
            }

            .tcdsr-bot {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
        }
    }

    .tcdt-card:hover,
    .tcd-jobs:hover,
    .tcd-status:hover {
        box-shadow: 0 14px 36px 1px #d8daf5;
    }

    .tcdt-card:nth-last-child(1),
    .tcd-jobs:nth-last-child(1),
    .tcd-status:nth-last-child(1) {
        margin-right: 0;
    }
}

// Style Support Ticket Home
.add-more-btn-home {
    display: block;
    position: fixed;
    background: #2676f6;
    right: 57px;
    bottom: 40px;
    padding: 12px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 999;

    .ambh-add-icon {
        transition: 0.6s all;
    }

    .amb-drop {
        position: absolute;
        bottom: 46px;
        right: 0;
        width: 46px;
        height: auto;
        visibility: hidden;
        opacity: 0;
        transform: translateY(70px);
        transition: all 0.2s ease-in-out;

        .ambd-create-ticket,
        .ambd-chat {
            position: relative;
            width: 46px;
            height: 46px;
            display: block;
            border-radius: 50%;
            margin-bottom: 15px;
            padding: 13px 15px;
        }

        .ambd-chat {
            background-color: #7a65ef;
            box-shadow: 0 5px 8px 0 #d0c7ff;
            padding: 13px 13px;

            img {
                width: 18px;
                height: 18px;
            }
        }

        .ambd-tooltip {
            position: absolute;
            top: 10px;
            right: 60px;
            border: solid 1px #dee6f4;
            font-size: 12px;
            font-weight: 500;
            background: #fff;
            padding: 3px 10px;
            white-space: nowrap;
            border-radius: 25px;
            color: #627da8;
            visibility: hidden;
            opacity: 0;
        }

        .ambd-create-ticket:hover,
        .ambd-chat:hover {
            transition: 0.6s all;

            .ambd-tooltip {
                visibility: visible;
                opacity: 1;
            }
        }

        .ambd-create-ticket {
            background: #6fc192;
        }

        .ambd-create-ticket:hover {
            background-color: #3f9b66;
        }

        .ambd-chat:hover {
            background-color: #5541c4;
        }
    }
}

// Updated create ticket icon
.add-more-btn-home {
    padding: 0;
    border-radius: 0;
    right: -84px;
    background: $primary-color;
    border-radius: 6px 0px 0px 6px;
    transition: 0.6s all;
    z-index: 999;

    .gct-icon {
        display: flex;
        align-items: center;
        transition: 0.6s all;

        .gct-plus {
            padding: 22px 0;

            svg {
                width: 22px;
                height: 20px;
            }
        }

        .amb-drop-tckt {
            background: #67768e;
            box-shadow: 0px 4px 20px -16px #8b93bb;
            padding: 6px 0;
            transform: translateX(84px);
            visibility: hidden;
            opacity: 0;

            .create-gen-ticket {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                transition: 0.6s all;

                p {
                    margin: 0;
                    font-weight: 600;
                    font-size: 11px;
                    color: #ffffff;
                    padding: 0 5px;
                }
            }
        }
    }
}

.add-more-btn-home:hover {
    right: 0;
    transition: 0.6s all;

    .ambh-add-icon {
        transition: 0.6s all;
    }

    .amb-drop-tckt {
        visibility: visible;
        opacity: 1;
        transform: translateX(0px);
    }
}

// Style Manage Support Ticket Head
.manage-support-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 75px;
    border-bottom: 1px solid #dce6f0;
    margin-bottom: 20px;

    .msh-left {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .msh-search {
            position: relative;
            display: inline-block;
            margin-right: 20px;

            form {
                margin: 0;
            }

            input[type="search"] {
                border-radius: 5px;
                border: solid 1px #dce6f0;
                background: #fff;
                padding: 8px 30px;
                height: 32px;
                font-size: 11px;
                color: #000;
                font-weight: 600;
                width: 250px;
            }

            input:focus {
                border: solid 1px #cdc4ff;
            }

            input::placeholder {
                font-size: 11px;
                font-weight: 500;
                color: #12203c;
            }

            .search-image {
                position: absolute;
                top: 8px;
                left: 7px;
                width: 15px;
            }

            .closeSearch {
                position: absolute;
                right: 10px;
                top: 10px;
                cursor: pointer;
            }
        }

        .drop-toggle-btn {
            display: block;
            position: relative;
            margin-right: 15px;

            .dtb-show {
                height: 32px;
                font-size: 12px;
                background-color: #ffffff;
                font-weight: 600;
                color: $primary-color;
                border: 2px solid $primary-color;
                padding: 5px 8px;
                border-radius: 4px;
                min-width: 150px;
                margin: 0px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                i {
                    margin-left: 15px;
                }
            }

            .dtb-hide {
                height: 32px;
                font-size: 12px;
                visibility: hidden;
                opacity: 0;
                position: absolute;
                top: 40px;
                left: 0;
                color: #12203c;
                font-weight: 600;
                height: 34px;
                width: 100%;
                padding: 5px 8px;
                margin: 0;
                border: none;
                text-align: left;
                box-shadow: 0 2px 6px 0 rgba(75, 75, 75, 0.16);
                transition: 0.6s all;
                z-index: 2;

                i {
                    display: none;
                }
            }

            .dtb-hide:hover {
                background-color: #e5edf8;
            }

            .dtb-dropdown {
                visibility: hidden;
                opacity: 0;
                position: absolute;
                top: 40px;
                left: 0;
                height: auto;
                width: 100%;
                background-color: #fff;
                padding: 5px 0;
                border: none;
                text-align: left;
                box-shadow: 0 2px 6px 0 rgba(75, 75, 75, 0.16);
                transition: 0.6s all;
                z-index: 2;

                .dtb-hide {
                    position: relative;
                    top: auto;
                    left: auto;
                    display: block;
                    height: auto;
                    box-shadow: none;
                    z-index: auto;
                    font-weight: 500;
                    border-radius: 0px;
                    background-color: #fff;
                }

                .dtb-show {
                    display: none;
                }
            }
        }

        .drop-toggle-btn:hover {
            .dtb-hide {
                transition: 0.6s all;
                visibility: visible;
                opacity: 1;
            }

            .dtb-dropdown {
                transition: 0.6s all;
                visibility: visible;
                opacity: 1;
            }

            .dtb-hide:hover {
                background-color: #e5edf8;
                font-weight: 600;
            }
        }

        .status-drop {
            position: relative;

            button {
                border-radius: 4px;
                border: solid 1px #a4b9dd;
                background-color: #fff;
                font-size: 12px;
                font-weight: 600;
                padding: 6px 26px 6px 13px;
                height: 32px;
                color: #21314b;
                text-align: left;
                background-image: url("../../../assets/icons/downArrowNew.svg");
                background-repeat: no-repeat;
                background-position: 94%;
                background-size: 11px;
                min-width: 150px;
            }

            .sd-dropdown {
                display: block;
                position: absolute;
                top: 41px;
                left: 0;
                width: 150px;
                padding: 8px 0;
                margin: 0;
                z-index: 99;
                border-radius: 6px;
                box-shadow: 0 2px 6px 0 rgba(75, 75, 75, 0.16);
                background-color: #fff;
                max-height: 270px;
                overflow: auto;

                ul {
                    margin: 0;
                    padding: 0;

                    li {
                        display: block;
                        padding: 5px 15px;
                        cursor: pointer;
                        font-size: 12px;
                        font-weight: 500;
                        color: #4a5568;
                    }

                    li:hover {
                        font-weight: 600;
                        color: #21314b;
                        background-color: #e5edf8;
                    }

                    .sahrsdd-head {
                        font-size: 12px;
                        font-weight: 500;
                        color: #969fad;
                    }
                }
            }
        }
    }

    .msh-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
            position: relative;
            border-radius: 5px;
            border: solid 1px #ccd8ed;
            background-color: #ffffff;
            height: 32px;
            padding: 3px 10px;
            font-size: 12px;
            font-weight: 500;
            margin-left: 20px;
        }

        a {
            color: #fff;
            position: relative;
            border-radius: 5px;
            border: solid 1px $primary-color;
            background-color: $primary-color;
            height: 32px;
            padding: 3px 10px;
            font-size: 12px;
            font-weight: 500;
            margin-left: 20px;
            font-weight: 600;
            align-items: center;
            display: inline-flex;
            text-decoration: none;
        }

        .otbrc-setting {
            padding: 0px;
            color: #12203c;
            width: 32px;
        }

        button:hover {
            .generic-tooltip {
                visibility: visible;
                opacity: 1;
            }
        }

        .otbrc-setting:hover {
            background-color: #12203c;
            border: solid 1px #12203c;

            svg {
                .otbrc-fill-2 {
                    fill: #fff;
                }
            }

            .generic-tooltip {
                visibility: visible;
                opacity: 1;
            }
        }

        .otbrc-email {
            span {
                margin-left: 10px;
            }
        }

        .otbrc-down-report {
            .otbrcdr-icon {
                margin-left: 10px;
            }
        }

        .gvpd-filter {
            position: relative;

            .gvpd-filter-inner {
                width: 32px;
                padding: 0;
            }

            .gvpd-filter-inner:hover {
                color: #fff;
                background-color: #4a5568;
                border: 1px solid #4a5568;
                transition: 0.3s all;

                svg path {
                    fill: #fff;
                }
            }

            .gvpd-filter-inner-focus {
                color: #fff;
                background-color: #4a5568;
                border: 1px solid #4a5568;
                transition: 0.3s all;

                svg path {
                    fill: #fff;
                }
            }

            .noOfFilter {
                position: absolute;
                right: -9px;
                top: -7px;
                background-color: #eb0008;
                border-radius: 50%;
                padding: 2px 0;
                font-size: 10px;
                width: 17px;
                height: 17px;
                text-align: center;
                color: #fff;
            }
        }

        .pi-download {
            width: 32px;
            padding: 0;
        }

        .gvpd-download-drop {
            display: inline-block;
            position: relative;

            .pi-history-download {
                display: block;
                position: absolute;
                top: 42px;
                right: 0;
                width: 220px;
                padding: 10px 0;
                border-radius: 12px;
                box-shadow: 0 -1px 6px 0 rgba(177, 177, 177, 0.16);
                background: #ffffff;
                margin: 0;
                z-index: 9;

                li {
                    display: block;

                    button {
                        display: flex;
                        padding: 5px 10px;
                        margin: 0;
                        border: none;
                        width: 100%;
                        height: auto;
                        text-align: left;
                        white-space: nowrap;
                        border-radius: 0;
                        transition: all 0.6s;
                        align-items: center;

                        img {
                            width: 17px;
                        }

                        span {
                            display: inline-block;
                            padding: 7px 0px;
                            border-radius: 50%;
                            height: 32px;
                            width: 32px;
                            text-align: center;
                            margin-right: 10px;
                        }

                        // .pipdf-text {
                        //     border-radius: 0;
                        //     max-width: 100px;
                        //     text-align: left;
                        //     width: auto;
                        //     text-overflow: ellipsis;
                        //     overflow: hidden;
                        //     margin: 0;
                        // }

                        .pi-pdf-img {
                            width: 20px;
                            margin-left: 8px;
                        }

                        div {
                            display: inline-block;

                            .multipleDownloadSelect {
                                border: none;
                                padding: 0;
                                -webkit-appearance: none;
                                background: #fff !important;
                            }
                        }

                        a {
                            font-size: 10px;
                            font-weight: 600;
                            background: transparent;
                            color: #21314b;
                            padding: 3px 8px;
                            border-radius: 50px;
                            text-align: right;
                            position: absolute;
                            top: 11px;
                            right: 10px;
                            text-decoration: none;
                        }
                    }

                    .export-excel {
                        color: #a4b9dd;

                        span {
                            margin-right: 10px;
                        }
                    }

                    .export-excel:hover {
                        color: #12203c;

                        span {
                            background-color: #049d4b14;

                            svg {
                                path {
                                    fill: #049d4b;
                                }

                                text {
                                    fill: #049d4b;
                                }
                            }
                        }
                    }

                    .pi-pdf-download {
                        // .pipdf-barcode {
                        //     padding: 10px 0px;
                        // }

                        .btnDisabled {
                            color: #a4b9dd !important;
                        }
                    }

                    .pi-pdf-download:hover {
                        background: #ebf2fa;
                        transition: 0.6s all;

                        .pi-pdf-svg {
                            background-color: #eb000817;

                            svg {
                                path {
                                    fill: #eb0008;
                                }

                                text {
                                    fill: #eb0008;
                                }
                            }
                        }

                        // .pipdf-barcode {
                        //     background-color: #3279dd1f;

                        //     svg {
                        //         path {
                        //             fill: #3279dd;
                        //         }
                        //     }
                        // }

                        // .pipdf-invoice {
                        //     background-color: #e2c80017;

                        //     svg {
                        //         path {
                        //             fill: #e2c800;
                        //         }
                        //     }
                        // }

                        // .pipdf-packing {
                        //     background-color: #00ced617;

                        //     svg {
                        //         path {
                        //             fill: #00ced6;
                        //         }
                        //     }
                        // }

                        a {
                            color: #fff;
                        }

                        // .pipd-pipdf {
                        //     background-color: #eb0008;
                        // }

                        // .pipd-barcode {
                        //     background-color: #3279dd;
                        // }

                        // .pipd-invoice {
                        //     background-color: #e2c800;
                        // }

                        // .pipd-packing {
                        //     background-color: #00ced6;
                        // }
                    }
                }
            }
        }

        .pi-download-focus {
            color: #fff;
            background-color: #4a5568;
            border: 1px solid #4a5568;

            svg path {
                fill: #fff;
            }
        }

        .sales-through {
            position: relative;

            button {
                border-radius: 46px;
                box-shadow: 0 1px 6px 0 #d7e1f3;
                background-color: #fff;
                font-size: 12px;
                font-weight: 600;
                padding: 6px 33px 6px 13px;
                height: 32px;
                color: #21314b;
                text-align: left;
                background-image: url("../../../assets/icons/downArrowNew.svg");
                background-repeat: no-repeat;
                background-position: 90%;
                background-size: 11px;
                margin-left: 20px;
                min-width: 95px;
                border: none;
            }

            .sathrst-dropdown {
                display: block;
                position: absolute;
                top: 41px;
                right: 0;
                width: 130px;
                padding: 8px 0;
                margin: 0;
                z-index: 99;
                border-radius: 6px;
                box-shadow: 0 2px 6px 0 rgba(75, 75, 75, 0.16);
                background-color: #fff;
                max-height: 270px;
                overflow: auto;

                .sathrstd-inner {
                    margin: 0;
                    padding: 0;

                    li {
                        display: block;
                        padding: 5px 15px;
                        cursor: pointer;
                        font-size: 12px;
                        font-weight: 500;
                        color: #4a5568;
                    }

                    li:hover {
                        font-weight: 600;
                        color: #21314b;
                        background-color: #e5edf8;
                    }

                    .sahrsdd-head {
                        font-size: 12px;
                        font-weight: 500;
                        color: #969fad;
                    }
                }
            }
        }
    }
}

// Style Manage Support Table Design
.manage-support-ticket-table {
    position: relative;
    display: block;
    box-shadow: 2px 0 6px 0 hsla(0, 0%, 55%, 0.16);
    margin-top: 20px;

    .mstt-manage {
        overflow-y: hidden;
        overflow-x: auto;

        .columnFilterGeneric {
            width: 19px;

            .glyphicon {
                padding: 15px 3px;
                background: $primary-color;
            }

            .columnSetting {
                right: 19px;
            }
        }

        .table {
            display: table;
            white-space: nowrap;
            border-collapse: collapse;
            margin: 0;
            border-spacing: 0;

            thead {
                border: none;
                box-shadow: 2px 0 6px 0 hsla(0, 0%, 55%, 0.16);

                tr {
                    height: 35px;
                    background-color: #fff;

                    th {
                        height: 42px;
                        vertical-align: middle;
                        white-space: nowrap;
                        border: none;
                        cursor: pointer;

                        label {
                            margin: 0;
                            font-weight: 600;
                            color: #000000;
                            width: auto;
                            cursor: pointer !important;
                            font-size: 12px !important;

                            img {
                                margin-left: 15px;
                                cursor: pointer;
                            }
                        }

                        label.rotate180 {
                            transform: rotate(0);

                            img {
                                transform: rotate(180deg);
                            }
                        }

                        img {
                            margin-left: 15px;
                            cursor: pointer;
                        }

                        .rotate180 {
                            filter: sepia(1);
                        }
                    }

                    th.rotate180 {
                        transform: rotate(0);

                        img {
                            filter: sepia(1);
                            transform: rotate(180deg);
                        }
                    }

                    th:last-child {
                        padding-right: 30px;
                    }

                    .fix-action-btn {
                        width: auto;
                        box-shadow: 10px 0 8px -4px hsla(0, 0%, 55%, 0.16);
                        background-color: #fff;
                        border: 0;
                        left: 0;
                        position: sticky;
                        border-right: none;
                        border-bottom: none;
                        z-index: 1;
                        padding: 0;
                        min-width: 40px;

                        label {
                            width: auto;
                        }

                        .rab-refresh {
                            display: flex;
                            margin: 0;
                            padding: 0;
                            display: flex;
                            width: 100%;
                            align-items: center;
                            justify-content: flex-start;

                            .rab-rinner {
                                display: inline-block;
                                padding: 7px;
                                position: relative;
                                border-radius: 50%;
                                cursor: pointer;

                                span {
                                    img {
                                        margin: 0;
                                        width: 18px;
                                    }
                                }

                                label {
                                    font-size: 12px;
                                }

                                .checkBoxLabel0 {
                                    width: 16px;
                                    height: 16px;
                                    padding-left: 16px;

                                    .checkmark1 {
                                        left: 0;
                                        top: 0;
                                        height: 16px;
                                        width: 16px;
                                        background-color: #ffffff;
                                        cursor: pointer;
                                        border-radius: 4px;
                                        border: solid 2px #dce6f0;
                                    }

                                    .checkmark1:after {
                                        width: 5px;
                                        height: 9px;
                                        border-width: 0 2px 2px 0;
                                        content: none;
                                    }

                                    input:checked~.checkmark1 {
                                        background-color: $primary-color;
                                        border: none;
                                    }

                                    input:checked~.checkmark1:after {
                                        width: 5px;
                                        height: 9px;
                                        border-width: 0 2px 2px 0;
                                        content: "";
                                    }
                                }

                                .select-all-text {
                                    position: absolute;
                                    top: 3px;
                                    left: 30px;
                                    padding: 4px 14px;
                                    font-size: 12px;
                                    background: $primary-color;
                                    color: #fff;
                                    border-radius: 30px;
                                    z-index: 9;
                                }

                                .generic-tooltip {
                                    left: 140%;
                                    top: 2px;
                                }

                                .generic-tooltip:after {
                                    top: 25%;
                                    left: -9%;
                                    margin-left: -6px;
                                    border-width: 6px;
                                    border-color: transparent #000 transparent transparent;
                                }
                            }

                            .rab-rinner:hover>.generic-tooltip {
                                visibility: visible;
                                opacity: 1;
                            }

                            .til-inner {
                                display: inline-block;
                                padding: 7px;
                                position: relative;
                                border-radius: 50%;
                                cursor: pointer;
                                margin-left: 5px;

                                .generic-tooltip {
                                    top: 1px;
                                    left: 160%;
                                    z-index: 99;
                                }

                                .generic-tooltip:after {
                                    top: 31%;
                                    left: -9%;
                                    margin-left: -5px;
                                    border-width: 5px;
                                    border-style: solid;
                                    border-color: transparent #000 transparent transparent;
                                }
                            }

                            .til-inner:hover>.generic-tooltip {
                                visibility: visible;
                                opacity: 1;
                            }

                            .til-edit-btn:hover {
                                background: #3ee58521;

                                svg {
                                    path {
                                        fill: #3ee585;
                                    }
                                }
                            }
                        }
                    }

                    .width62 {
                        width: 62px;
                    }

                    .width62 {
                        width: 62px;
                    }

                    .width40 {
                        width: 40px;
                    }

                    .width62 {
                        width: 62px;
                    }
                }
            }

            tbody {
                tr {
                    height: 35px;

                    td {
                        height: 35px;
                        white-space: nowrap;
                        vertical-align: middle;
                        border: 0.5px solid #e3e7f3;
                        position: relative;

                        .acp-running,
                        .acp-scheduled {
                            margin-left: 7px;
                            width: 14px;
                        }

                        .table-per-tooptip {
                            position: relative;
                        }

                        .table-per-tooptip:hover>.generic-tooltip {
                            opacity: 1;
                            visibility: visible;
                        }

                        label {
                            color: #000;
                            margin: 0;
                            font-weight: 500;
                            white-space: nowrap;
                            max-width: 240px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            vertical-align: middle;
                            font-size: 11px !important;
                            cursor: auto;

                            .genmt-rupee-sign {
                                font-size: 12px;
                                font-weight: 400;
                                font-family: system-ui;
                                color: #97abbfcf;
                                margin-right: 5px;
                            }

                            a {
                                color: inherit;
                                text-decoration: none;
                            }
                        }

                        .mst-status {
                            font-weight: 600;
                            min-width: 90px;
                            position: relative;
                        }

                        .mst-status:after {
                            content: "";
                            position: absolute;
                            top: 5px;
                            right: 10px;
                            width: 5px;
                            height: 5px;
                            border-radius: 50%;
                            background-color: #6fc192;
                        }

                        .mst-status {
                            color: #6fc192;
                        }

                        .mst-status-unresolved {
                            color: #eb0008;
                        }

                        .mst-status-open {
                            color: #2681ff;
                        }

                        .mst-status-closed {
                            color: #21314b;
                        }

                        .mst-status-pending {
                            color: #e2c800;
                        }

                        .mst-status-unresolved:after {
                            background-color: #eb0008;
                        }

                        .mst-status-open:after {
                            background-color: #2681ff;
                        }

                        .mst-status-closed:after {
                            background-color: #21314b;
                        }

                        .mst-status-pending:after {
                            background-color: #e2c800;
                        }

                        input[type="text"] {
                            border-radius: 6px;
                            border: solid 1px #dce6f0;
                            background-color: #ffffff;
                            font-size: 11px;
                            font-weight: 500;
                            color: #000;
                            padding: 3px 5px;
                            height: 28px;
                            width: 80px;
                        }

                        select {
                            border-radius: 4px;
                            border: 1px solid #ccd8ed;
                            background-color: #fff;
                            width: 220px;
                            display: block;
                            height: 30px;
                            padding: 3px 25px 3px 5px;
                            font-size: 12px;
                            font-weight: 500;
                            color: #12203c;
                            background-repeat: no-repeat;
                            background-position: 96%;
                            background-image: url("../../../assets/icons/downArrowNew.svg");
                        }

                        label.clr-red {
                            color: #eb0008;
                        }

                        .table-td-text {
                            white-space: nowrap;
                            max-width: 240px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            vertical-align: middle;
                        }

                        pre {
                            background: #fff;
                            border: none;
                            white-space: nowrap;
                            margin: 0;
                            max-width: 260px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            vertical-align: middle;
                            padding: 0;

                            code {
                                white-space: nowrap;
                                max-width: 200px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                vertical-align: middle;
                            }
                        }
                    }

                    .mr-vap {
                        label {
                            padding: 3px 10px;
                            background: #bfdfff;
                            border-radius: 33px;
                            color: #004183;
                        }
                    }

                    .bg-green {
                        background-color: rgba(68, 215, 182, 0.12);
                    }

                    .bg-red {
                        background-color: rgba(224, 32, 32, 0.12);
                        border: 1px solid #e5e5e5a1;
                    }

                    td:first-child {
                        border-left: none;
                    }

                    td:nth-child(2) {
                        border-left: none;
                    }

                    .fix-action-btn {
                        width: auto;
                        box-shadow: 10px 0 8px -4px hsla(0, 0%, 55%, 0.16);
                        background-color: #fff;
                        border: 0;
                        padding: 0;
                        left: 0;
                        position: sticky;
                        z-index: 1;

                        button {
                            border: solid 1px #dee6f4;
                            background: #fff;
                            padding: 4px 7px;
                            border-radius: 4px;
                            color: #627da8;
                            font-size: 12px;
                            font-weight: 600;
                            margin: 0 6px;
                        }

                        button:hover {
                            background-color: #7a65ef;
                            color: #fff;
                            border: solid 1px #7a65ef;
                        }

                        .table-item-list {
                            display: flex;
                            width: 100%;
                            align-items: center;
                            justify-content: space-around;
                            padding: 0;
                            margin: 0;
                            height: 33px;

                            .til-inner {
                                display: inline-block;
                                padding: 7px;
                                position: relative;
                                border-radius: 50%;
                                cursor: pointer;

                                .checkBoxLabel0 {
                                    width: 16px;
                                    height: 16px;
                                    padding-left: 16px;

                                    .checkmark1 {
                                        left: 0px;
                                        top: 0px;
                                        height: 16px;
                                        width: 16px;
                                        background-color: #ffffff;
                                        cursor: pointer;
                                        border-radius: 4px;
                                        border: solid 2px #dce6f0;
                                    }

                                    .checkmark1:after {
                                        width: 5px;
                                        height: 9px;
                                        border-width: 0 2px 2px 0;
                                        content: none;
                                    }

                                    input:checked~.checkmark1 {
                                        background-color: $primary-color;
                                        border: none;
                                    }

                                    input:checked~.checkmark1:after {
                                        width: 5px;
                                        height: 9px;
                                        border-width: 0 2px 2px 0;
                                        content: "";
                                    }
                                }
                            }

                            .til-inner:hover>.generic-tooltip {
                                visibility: visible;
                                opacity: 1;
                            }
                        }

                        .til-for-lr {
                            justify-content: flex-start;

                            .til-inner {
                                padding: 4px;
                            }

                            .til-chat-icon {
                                width: 25px;
                            }
                        }
                    }

                    td:last-child {
                        padding-right: 30px;
                    }

                    .width62 {
                        width: 62px;
                    }

                    .width40 {
                        width: 40px;
                    }

                    .width62 {
                        width: 62px;
                    }

                    .width105 {
                        width: 105px;
                    }
                }

                tr:first-child {
                    td {
                        border-top: none;
                    }
                }

                .vgt-tr-bg {
                    background-color: #f7f5fb;
                }
            }
        }
    }
}

// Style view raiesd ticket modal
.view-raised-ticket-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 950px;
    height: 610px;
    transform: translate(-50%, -50%);
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 10px 30px;

    .vrtm-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;

        .vrtm-left {
            h3 {
                font-size: 16px;
                font-weight: 700;
                color: #21314b;
                margin: 0;
            }

            p {
                font-size: 12px;
                font-weight: 500;
                color: #97abbf;
                margin: 0;
            }
        }

        .vrtm-right {
            position: relative;

            .vrtm-tid {
                font-size: 12px;
                font-weight: 500;
                color: #97abbf;
                margin-right: 40px;

                .no-ticket {
                    color: #21314b;
                    font-weight: 600;
                }
            }

            button {
                position: absolute;
                top: -20px;
                right: -5px;
                padding: 2px;
                background: #fff;
            }
        }
    }

    .vrtm-body {
        display: block;
        padding: 10px 0;
        overflow: scroll;

        .vrtmb-head {
            display: flex;
            align-items: center;
            padding: 12px 0;
            border-bottom: solid 1px #dee6f4;
            border-top: solid 1px #dee6f4;

            .vrtmbh-text {
                margin-left: 20px;

                h3 {
                    display: inline-block;
                    font-size: 16px;
                    font-weight: 700;
                    color: #21314b;
                    margin: 0;
                }

                .vrtmbh-details {
                    margin-top: 5px;
                }

                .vrtmbhd-mail,
                .vrtmbhd-time {
                    font-size: 12px;
                    font-weight: 500;
                    color: #21314b;
                }

                .vrtmbhd-time {
                    margin: 0 20px;
                    position: relative;
                }

                .vrtmbhd-time::before,
                .vrtmbhd-time::after {
                    content: "";
                    position: absolute;
                    top: 7px;
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    background-color: #b4c5d6;
                }

                .vrtmbhd-time::before {
                    left: -10px;
                }

                .vrtmbhd-time::after {
                    right: -10px;
                }

                .vrtmbhd-tag {
                    background-color: #c0efd4;
                    padding: 3px 6px;
                    font-size: 12px;
                    font-weight: 500;
                    color: #049d97;
                    border-radius: 2px;
                }

                .vrtmbhd-internal {
                    margin-left: 10px;
                    background-color: #fff6d6;
                    padding: 3px 6px;
                    font-size: 12px;
                    font-weight: 500;
                    color: #ff9303;
                    border-radius: 2px;
                }

                .vrtmbhd-external {
                    margin-left: 10px;
                    background-color: #ffd5c5;
                    padding: 3px 6px;
                    font-size: 12px;
                    font-weight: 500;
                    color: #ff4602;
                    border-radius: 2px;
                }
            }
        }

        .vrtmb-body {
            .vrtmbb-tabs {
                padding: 15px 0;

                button {
                    position: relative;
                    border-radius: 6px;
                    border: solid 1px #dee6f4;
                    background: #fff;
                    font-weight: 500;
                    font-size: 12px;
                    padding: 4px 20px;
                    margin-right: 20px;
                    height: 32px;
                }

                .active::after {
                    content: "";
                    position: absolute;
                    top: 13px;
                    right: 10px;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: #5541c4;
                }

                button:hover,
                .active {
                    background-color: #e1ddf6;
                    border: solid 1px #e1ddf6;
                    color: #5541c4;
                }
            }

            .vrtmbb-all-details {
                .vrtmbbad-row {
                    margin-bottom: 20px;

                    .vrtmbbad-col {
                        display: block;
                        margin-right: 25px;

                        span {
                            font-size: 12px;
                            font-weight: 600;
                        }

                        .vrtmbbad-raisedon {
                            color: #ff6347;
                        }

                        .vrtmbbad-status {
                            color: #2681ff;
                        }

                        .vrtmbbad-priority {
                            color: #ce8500;
                        }

                        .vrtmbbad-img {
                            position: relative;
                            width: 47px;
                            height: 47px;
                            overflow: hidden;
                            display: inline-flex;
                            align-content: center;
                            border-radius: 8px;
                            border: solid 1px #d1e2f4;
                            margin-right: 12px;
                            transition: 0.6s all;
                            margin-bottom: 10px;

                            .vrtmbbad-overlay {
                                position: absolute;
                                top: 0;
                                left: 0;
                                display: none;
                                align-items: center;
                                justify-content: center;
                                width: 100%;
                                height: 100%;
                                background: #627da8c2;
                            }
                        }

                        .vrtmbbad-img:hover {
                            transition: 0.6s all;

                            .vrtmbbad-overlay {
                                display: flex;
                            }
                        }

                        .vrtmbbad-attach-name {
                            display: block;
                            color: #3279dd;
                            font-size: 12px;
                            font-weight: 500;
                            margin-bottom: 5px;
                            cursor: pointer;
                        }
                    }

                    p {
                        font-size: 12px;
                        font-weight: 500;
                        color: #718096;
                        margin: 0;
                    }

                    span {
                        font-size: 12px;
                        font-weight: 500;
                        color: #21314b;
                        white-space: pre-line;
                    }

                    .vrtmbbad-raisedon {
                        color: #ff6347;
                    }

                    .vrtmbbad-status {
                        color: #2681ff;
                    }

                    .vrtmbbad-priority {
                        color: #ce8500;
                    }
                }
            }

            .raised-conversation-tab {
                display: flex;
                justify-content: space-between;

                .rct-left {
                    display: block;
                    border: dashed 1px #dee6f4;
                    border-radius: 9px;
                    width: 70%;
                    padding: 10px;

                    .rct-message-body {
                        display: block;
                        height: 340px;
                        overflow: auto;
                        padding: 10px;

                        .rct-row {
                            display: block;
                            margin: 10px 0;

                            .rct-msg-sender {
                                display: flex;
                                align-items: flex-start;
                                justify-content: flex-start;

                                .rctms-left {
                                    display: block;
                                    margin-right: 10px;

                                    .rct-user-icon {
                                        background: #2681ff;
                                        padding: 9px;
                                        height: 35px;
                                        width: 35px;
                                        display: block;
                                        border-radius: 50%;
                                    }

                                    .rctms-time {
                                        font-size: 10px;
                                        font-weight: 500;
                                        color: #b4c5d6;
                                        display: block;
                                        white-space: nowrap;
                                    }
                                }

                                .rctms-right {
                                    border-radius: 9px;
                                    border: solid 1px #dee6f4;
                                    position: relative;
                                    max-width: 290px;
                                    padding-bottom: 7px;

                                    pre {
                                        background: #fff0;
                                        font-size: 12px;
                                        font-weight: 500;
                                        color: #21314b;

                                        border: none;
                                        border-radius: 8px;
                                        white-space: pre-wrap;
                                        margin: 0;
                                        padding: 10px;
                                    }

                                    .rctms-time {
                                        font-size: 10px;
                                        font-weight: 500;
                                        color: #b4c5d6;
                                        display: block;
                                        white-space: nowrap;
                                        position: absolute;
                                        right: 10px;
                                        bottom: 1px;
                                    }
                                }
                            }

                            .rct-msg-reciver {
                                display: flex;
                                align-items: flex-start;
                                justify-content: flex-end;

                                .rctms-left {
                                    display: block;
                                    margin-left: 10px;

                                    .rct-user-icon {
                                        background: #ff9922;
                                        padding: 9px;
                                        height: 35px;
                                        width: 35px;
                                        display: block;
                                        border-radius: 50%;
                                    }

                                    .rctms-time {
                                        font-size: 10px;
                                        font-weight: 500;
                                        color: #b4c5d6;
                                        display: block;
                                        white-space: nowrap;
                                    }
                                }

                                .rctms-right {
                                    border-radius: 9px;
                                    border: solid 1px #dee6f4;
                                    position: relative;
                                    max-width: 290px;
                                    padding-bottom: 7px;

                                    pre {
                                        background: #fff0;
                                        font-size: 12px;
                                        font-weight: 500;
                                        color: #21314b;

                                        border: none;
                                        border-radius: 8px;
                                        white-space: pre-wrap;
                                        margin: 0;
                                        padding: 10px;
                                    }

                                    .rctms-time {
                                        font-size: 10px;
                                        font-weight: 500;
                                        color: #b4c5d6;
                                        display: block;
                                        white-space: nowrap;
                                        position: absolute;
                                        right: 10px;
                                        bottom: 1px;
                                    }
                                }
                            }
                        }
                    }

                    .rct-message-footer {
                        border-radius: 9px;
                        border: solid 1px #dee6f4;

                        textarea {
                            display: block;
                            width: 100%;
                            border-radius: 9px 9px 0 0;
                            padding: 10px;
                            border: none;
                            font-size: 12px;
                            font-weight: 500;
                            color: #21314b;
                            outline: none;
                            height: 70px;
                        }

                        textarea::placeholder {
                            color: #b4c5d6;
                        }

                        .rctmf-bot {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            border-top: solid 1px #dee6f4;
                            padding: 6px 10px;

                            .rctmf-attach-name {
                                margin-right: 20px;
                                display: flex;
                                flex-direction: column;
                                align-items: flex-end;
                            }

                            .rctmfb-attach {
                                border: solid 1px #dee6f4;
                                border-radius: 4px;
                                margin-right: 15px;
                                margin-bottom: 0;
                                padding: 4px;
                                height: 30px;
                                cursor: pointer !important;

                                input[type="file"] {
                                    display: none;
                                }

                                .rctmfb-icon {
                                    display: inline-block;
                                }
                            }

                            .rctmfb-attach-name {
                                display: block;
                                color: #3279dd;
                                font-size: 12px;
                                font-weight: 500;
                                margin-bottom: 5px;

                                .rctmfb-close {
                                    margin-left: 5px;
                                }
                            }

                            button {
                                font-size: 12px;
                                font-weight: 600;
                                background: #7a65ef;
                                color: #fff;
                                padding: 4px 10px 3px 6px;
                                border-radius: 5px;
                                height: 30px;

                                svg {
                                    margin-right: 7px;
                                }
                            }
                        }
                    }
                }

                .rct-right {
                    display: block;
                    border: 1px dashed #dee6f4;
                    border-radius: 9px;
                    width: 28%;
                    padding: 10px 0 0 10px;

                    .rctr-head {
                        h3 {
                            font-size: 14px;
                            font-weight: 700;
                            color: #21314b;
                        }
                    }

                    .rctr-body {
                        padding: 10px 0;

                        .rctrb-img {
                            position: relative;
                            width: 47px;
                            height: 47px;
                            overflow: hidden;
                            display: inline-flex;
                            align-content: center;
                            border-radius: 8px;
                            border: solid 1px #d1e2f4;
                            margin-right: 12px;
                            transition: 0.6s all;
                            margin-bottom: 10px;

                            .rctrb-overlay {
                                position: absolute;
                                top: 0;
                                left: 0;
                                display: none;
                                align-items: center;
                                justify-content: center;
                                width: 100%;
                                height: 100%;
                                background: #627da8c2;
                            }
                        }

                        .rctrb-img:hover {
                            transition: 0.6s all;

                            .rctrb-overlay {
                                display: flex;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Style Create New Ticket
#create_new_ticket {
    height: 100%;

    .generic-spread-form {
        .gsf-body {
            height: calc(100% - 140px);

            .generic-form-body {
                .gfb-inner {
                    .generic-input-box {
                        .gib-upload {
                            padding: 0;

                            .gib-upload {
                                .gibu-text {
                                    font-size: 12px;
                                }
                            }
                        }
                    }

                    .generic-textarea {
                        width: 100%;

                        .gib-textarea {
                            width: 100%;
                            height: 70px;

                            textarea {
                                width: 100%;
                                height: 60px !important;
                            }
                        }
                    }

                    .generic-upload {
                        width: 100%;

                        .gib-upload {
                            width: 100%;
                            height: 60px;
                            background-color: #fff;
                            padding: 10px 0;
                            cursor: pointer;

                            .gib-upload {
                                height: 56px;
                                .gibu-inner {
                                    text-align: center;
                                    h4 {
                                        font-size: 12px;
                                        font-weight: 500;
                                        color: #90a5c7;
                                        margin: 0;

                                        b {
                                            color: #627da8;
                                            font-weight: 600;
                                        }
                                    }
                                }
                            }
                        }

                        .gu-capture {
                            position: relative;
                            padding: 5px 0;

                            .guc-div {
                                width: fit-content;
                                height: 32px;
                                display: flex;
                                align-items: center;
                                border: 1px solid #dae4ee;
                                border-radius: 3px;
                                padding: 0 10px;
                                cursor: pointer;

                                .guc-btn:hover {
                                    background-color: #f5f5f5;
                                }

                                p {
                                    color: #67768e;
                                    font-weight: 600;
                                    font-size: 12px;
                                    margin: 0 0 0 10px;
                                }
                            }

                            .guc-div:hover {
                                background-color: #f5f5f5;
                            }

                            .guc-text {
                                width: fit-content;
                                display: flex;
                                align-items: center;
                                padding: 5px 10px;
                                background: #f8f8f8;
                                border: 1px solid #dae4ee;
                                border-radius: 50px;

                                h3 {
                                    color: #67768e;
                                    font-size: 13px;
                                    font-weight: 500;
                                    margin: 0;
                                }

                                button {
                                    margin-left: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.create-new-ticket-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 700px;
    height: 80%;
    transform: translate(-50%, -50%);
    border: none;
    border-radius: 0;
    box-shadow: none;
    padding: 3px 0px;

    .cntm-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 30px;
        border-bottom: solid 1px #dee6f4;

        .cntm-left {
            h3 {
                font-size: 16px;
                font-weight: 700;
                color: #21314b;
                margin: 0;
            }

            p {
                font-size: 12px;
                font-weight: 500;
                color: #97abbf;
                max-width: 490px;
                margin: 0;
                margin-top: 6px;
            }
        }

        .cntm-right {
            position: relative;

            button {
                position: absolute;
                top: -36px;
                right: -17px;
                padding: 2px;
                background: #fff;
            }
        }
    }

    .cntm-body {
        display: block;
        padding: 12px 15px 12px 30px;
        overflow: auto;
        height: calc(100% - 150px);

        .cntmb-row {
            margin-bottom: 20px;
            padding-right: 15px;

            .cntmb-add-cc {
                height: 32px;
                font-size: 12px;
                font-weight: 500;
                color: #21314b;
                border-radius: 4px;
                padding: 2px 4px 2px 12px;
                border: solid 0.5px #a4b9dd;
                background-color: #fff;
                display: flex;
                align-items: center;

                svg {
                    margin-left: 2px;
                }
            }

            .cntmb-col {
                display: block;
                width: 33.33%;
                padding-right: 15px;
                position: relative;

                span {
                    font-size: 12px;
                    font-weight: 600;
                }

                .vrtmbbad-raisedon {
                    color: #ff6347;
                }

                .vrtmbbad-status {
                    color: #2681ff;
                }

                .vrtmbbad-priority {
                    color: #ce8500;
                }
            }

            .cntmb-col.add-cc {
                input {
                    padding-right: 36px;
                    text-overflow: ellipsis;
                }

                span:nth-of-type(1) {
                    position: absolute;
                    right: 15px;
                    bottom: 0px;
                    background-color: $primary-color;
                    width: 34px;
                    height: 34px;
                    font-size: 25px;
                    font-weight: 500;
                    color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    border: 1px solid #a4b9dd;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }

                span:nth-of-type(1):hover+.generic-tooltip {
                    opacity: 1;
                    visibility: visible;
                }

                .generic-tooltip {
                    top: -10px;
                    left: 85%;
                    color: #ffffff;
                }
            }

            label {
                font-size: 12px;
                font-weight: 600;
                color: #3a5074;
            }

            input,
            select {
                display: block;
                border-radius: 4px;
                border: solid 1px #a4b9dd;
                background-color: #ffffff;
                height: 34px;
                font-size: 12px;
                font-weight: 500;
                color: #3a5074;
                width: 100%;
                padding: 3px 5px;
            }

            textarea {
                display: block;
                width: 100%;
                border-radius: 4px;
                height: 60px;
                padding: 7px;
                font-size: 12px;
                font-weight: 500;
                border: solid 1px #a4b9dd;
                color: #3a5074;
            }

            textarea::placeholder {
                color: #b4c5d6;
            }

            .ermb-input-field {
                display: flex;
                width: 100%;
                border: 1px solid #a4b9dd;
                border-radius: 4px;
                padding: 5px;
                background: #fff;
                flex-wrap: wrap;
                max-height: 70px;
                height: 60px;
                overflow: auto;

                .ermbif-tag {
                    display: inline-flex;
                    border-radius: 5px;
                    background-color: #fff;
                    padding: 3px 5px;
                    font-size: 11px;
                    font-weight: 500;
                    color: #12203c;
                    width: auto;
                    margin-bottom: 6px;
                    margin-right: 10px;
                    border: 1px solid #a4b9dd;
                    align-items: center;
                    height: 26px;

                    .ermbift-mailid {
                        margin-right: 10px;
                    }

                    .ermbift-close {
                        cursor: pointer;
                        float: right;
                        padding: 3px 4px;
                    }
                }

                .ermbif-tag:hover {
                    background-color: #7a65ef;
                    border: solid 1px #7a65fe;
                    color: #fff;

                    .ermbift-mailid {
                        color: #fff;
                    }

                    .ermbift-close {
                        svg path {
                            fill: #fff;
                        }
                    }
                }

                input {
                    border: none;
                    height: 26px;
                    width: auto;
                }
            }

            span {
                font-size: 12px;
                font-weight: 500;
                color: #21314b;
            }

            .cntmb-img-name {
                display: block;
                color: #3279dd;
                font-size: 12px;
                font-weight: 500;
                margin-bottom: 5px;

                .cntmb-close {
                    margin-left: 5px;
                }
            }

            .cntmb-img-input {
                position: relative;
                width: 91%;
                border: solid 1px #a4b9dd;
                height: 40px;
                border-radius: 4px;
                margin: 0;
                text-align: center;
                padding: 9px 10px;
                color: #90a5c7;
                cursor: pointer !important;

                input[type="file"] {
                    visibility: hidden;
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                .cntmb-text {
                    color: #90a5c7;

                    span {
                        color: #627da8;
                        font-weight: 600;
                    }
                }
            }

            .cntmb-img-input:hover,
            .capture-sshot:hover {
                border: solid 1px #7a65ef;
                transition: 0.6s all;

                .cntmb-text {
                    span {
                        color: #7a65ef;
                    }
                }
            }

            .capture-sshot {
                height: 40px;
                width: 40px;
                background-color: #fff;
                border: 1px solid #a4b9dd;
                padding: 3px 2px;
                border-radius: 4px;
                margin-left: 15px;
                position: relative;

                .generic-tooltip {
                    color: white;
                    top: -33px;
                    left: -100px;
                    z-index: 99;
                }

                .generic-tooltip:after {
                    bottom: 0;
                    left: 90%;
                    border-width: 5px;
                    border-style: solid;
                    border-color: #000000 transparent transparent transparent;
                }
            }

            .capture-sshot:hover>.generic-tooltip {
                visibility: visible;
                opacity: 1;
            }

            .cntmb-info {
                text-align: right;
                float: right;
                color: #97abbf;
                font-size: 11px;
            }
        }

        .cntmb-row.d-flex {
            justify-content: space-between;

            input {
                width: 100%;
                background-color: #f4f4f4;
            }
        }
    }

    .cntm-footer {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        padding: 3px 30px;

        button {
            font-size: 12px;
            font-weight: 500;
            color: #21314b;
            border: solid 1px #627da8;
            background-color: #ffffff;
            border-radius: 4px;
            margin-left: 20px;
            padding: 3px 25px;
            height: 32px;
        }

        .cntmf-create {
            color: #ffffff;
            font-weight: 600;
            padding: 3px 35px;
            background-color: $primary-color;
            border: solid 1px $primary-color;
        }
    }
}

.image-detail-tracking-modal {
    display: block;
    width: 540px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-color: #fff;
    z-index: 99;
    border: none;
    box-shadow: none;
    border-radius: 0;

    .idtm-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;

        .idtmh-left {
            display: flex;
            align-items: center;

            .idtm-col {
                margin-right: 10px;

                p {
                    font-size: 11px;
                    font-weight: 500;
                    color: #67768e;
                    margin-bottom: 3px;
                }

                h3 {
                    font-size: 14px;
                    font-weight: 600;
                    color: #21314b;
                    margin-bottom: 0;
                }
            }
        }

        .idtmh-right {
            button {
                border: solid 2px #21314b;
                background-color: #fff;
                border-radius: 20px;
                padding: 6px;
            }
        }
    }

    .idtm-body {
        .subscription-tab {
            position: relative;

            .subscription-tab-list {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-top: 1px solid #dce6f0;
                border-bottom: 1px solid #dce6f0;
                padding: 10px 15px;

                li {
                    a {
                        position: relative;
                        padding: 15px 15px 17px !important;
                    }

                    .pstb-inner {

                        button {
                            height: 30px;
                            border-radius: 4px;
                            font-size: 12px;
                            margin-left: 15px;
                        }
                    }
                }
            }
        }

        .idtmb-order-tracking {
            overflow: auto;
            height: calc(100vh - 170px);
        }

        .idtmb-top-section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 15px;

            .idtmbts-search {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .idtmbtss-icon {
                    margin-right: 5px;
                }

                input {
                    border: none;
                    height: 24px;
                    font-size: 12px;
                    font-weight: 500;
                    color: #21314b;
                    width: 200px;
                    padding: 2px 3px;
                    text-overflow: ellipsis;
                }

                input::placeholder {
                    color: #71819b;
                }
            }

            .idtmbts-right {
                button {
                    padding: 6px 10px;
                    border-radius: 4px;
                    color: #21314b;
                    font-size: 12px;
                    font-weight: 600;
                    border: solid 1px #dae4ee;
                    background-color: #fff;
                }

                .idtmbts-clear {
                    font-size: 12px;
                    font-weight: 600;
                    color: #21314b;
                    background-color: #e6e9ec;
                    padding: 3px 10px;
                    border-radius: 20px;
                }
            }
        }

        .idtmb-mid-section {
            display: flex;
            align-items: center;
            padding: 15px 15px 0;

            .sdfl-input-col {
                position: relative;
                width: 25%;
                padding-right: 15px;
                margin-bottom: 15px;

                .generic-smart-input {
                    .gsi-selected {
                        margin-left: 5px;
                    }

                    .gsis-clear {
                        display: flex;
                        border-radius: 50%;
                        align-items: center;
                        justify-content: center;
                        width: 18px;
                        height: 18px;
                        margin-right: 10px;
                        background-color: #f6f6f6;
                        cursor: pointer;
                    }
                }

                .dropdown-menu-city1.gen-width-auto {
                    height: 260px;
                    min-width: 90%;
                    max-width: fit-content;

                    .dropdown-menu-city-item {
                        li {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .dmci-color {
                                width: 12px;
                                height: 12px;
                                display: inline-block;
                                border-radius: 20px;
                                background-color: #21314b;
                                flex: 0 0 12px;
                            }

                            .checkBoxLabel0 {
                                // white-space: normal;
                                margin: 0;
                                word-break: break-all;
                            }
                        }
                    }
                    .gen-dropdown-pagination {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 15px;
                        .page-close {
                            display: inline-block;
                            padding: 1px 15px;
                            border-radius: 26px;
                            border: 1px solid #b4c5d6;
                            background-color: #fff;
                            font-size: 12px;
                            font-weight: 500;
                            color: #12203c;
                            transition: all .6s;
                            height: 24px;
                            outline: none;
                        }
                        .page-close:hover {
                            border: 1px solid $primary-color;
                            background-color: $primary-color;
                            color: #fff;
                            transition: all .6s;
                        }
                    }
                }
            }

            .sdfl-input-col:last-child {
                .dropdown-menu-city1.gen-width-auto {
                    left: auto;
                    right: 15px;
                }
            }
        }

        .idtmb-main-section {
            display: block;
            width: 100%;
            padding: 5px 15px;

            .idtmbms-items {
                display: flex;
                align-items: center;
                border-radius: 6px;
                box-shadow: 0 1px 6px 0 #dae4ee;
                margin-bottom: 15px;

                .idtmbmsi-left {
                    flex: 0 0 45%;
                    position: relative;
                    padding: 10px;
                    .idtmbmsil-top {
                        position: relative;
                    }
                    img, svg {
                        width: 85%;
                        height: 80%;
                        border-radius: 6px;
                    }
                    
                    svg{
                        margin-inline: auto;
                    }

                    img {
                        height: 70%;
                        width: 100%;
                        max-height: 120px;
                        object-fit: cover;
                        object-position: center;
                    }

                    .idtmbmsil-left,
                    .idtmbmsil-right {
                        position: absolute;
                        top: 38%;
                        left: -1px;
                        background: transparent;
                        padding: 0;
                        transform: translate(0px, -50%);
                    }

                    .idtmbmsil-right {
                        left: auto;
                        right: -3px;
                    }

                    .idtmbmsil-pagination {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        // position: absolute;
                        bottom: 0;
                        // left: 10px;
                        // padding: 10px;
                        width: 100%;

                        button {
                            background-color: #fff0;
                            padding: 0;
                            border-radius: 6px;
                            margin-right: 10px;
                            border: solid 1px transparent;

                            img,
                            svg {
                                width: 30px;
                                border-radius: 6px;
                                height: 30px;
                                object-fit: cover;
                            }
                            img{
                                margin-top: .75rem;
                            }
                        }

                        .idtmbmsil-active {
                            border: solid 1px #ffffff;
                        }
                    }
                }

                .idtmbmsi-right {
                    padding: 10px 8px 0;
                    flex: 0 0 55%;

                    .idtmbmsir-row {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        margin-bottom: 10px;

                        p {
                            font-size: 11px;
                            font-weight: 500;
                            color: #67768e;
                            flex: 0 0 100%;
                            margin-bottom: 3px;
                        }

                        h3 {
                            font-size: 13px;
                            font-weight: 600;
                            color: #21314b;
                            margin-bottom: 0;
                        }

                        .idtmbsirr-col {
                            width: auto;
                            margin-right: 20px;

                            .idtmbsirr-color {
                                display: block;
                                height: 12px;
                                border-radius: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Style Item Preview Image Modal
.image-item-priview-modal {
    width: 800px;
    min-height: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    border: none;
    box-shadow: none;
    border-radius: 9px;
    transform: translate(-50%, -50%);

    .iipm-top {
        position: relative;
        padding: 12px;

        .iipmt-close {
            position: absolute;
            top: 25px;
            right: 25px;
            background-color: transparent;
            padding: 0;

            img {
                width: 30px;
            }
        }

        .container-img {
            max-height: 600px;
            overflow-y: auto;
            overflow-x: hidden;

            .iipmt-img {
                width: 100%;
                height: 100%;
                border-radius: 5px;
            }
        }

        .iipmt-dd {
            .gvpd-download-drop {
                position: absolute;
                bottom: 25px;
                backdrop-filter: blur(13px);
                border: solid 1px #ffffff33;
                background-color: #ffffff4d;
                border-radius: 9px;
                margin-left: 15px;

                .iipmt-download {
                    border: 1px solid #ffffff33;
                    background-color: #ffffff4d;
                    width: 32px;
                    height: 32px;
                    padding: 7px;
                    display: inline-block;
                    border-radius: 4px;
                }

                .iipmt-download:hover {
                    .generic-tooltip {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }

            .iipmt-details {
                position: absolute;
                right: 25px;
                bottom: 25px;
                backdrop-filter: blur(13px);
                border: solid 1px #ffffff33;
                background-color: #ffffff4d;
                border-radius: 9px;
                padding: 0px 6px;
                width: 220px;

                .iipmtd-inner {
                    padding: 6px 0;

                    p {
                        font-size: 11px;
                        font-weight: 500;
                        color: #fff;
                        margin: 0;
                    }

                    h3 {
                        font-size: 13px;
                        font-weight: 700;
                        color: #ffffff;
                        letter-spacing: normal;
                        line-height: normal;
                        margin: 0;
                    }
                }
            }
        }
    }

    .iipm-bottom {
        padding: 0px 5px;
        display: flex;
        align-items: center;
        width: 100%;
        overflow: auto;

        .iipmb-btn {
            background: #fff;
            padding: 0;
            margin-right: 5px;

            img {
                width: 80px;
            }
        }
    }
}

// All Filter Dashboard
.all-filter-dashboard {
    max-width: 100%;
    display: flex;
    align-items: center;
    background-color: #fff;
    margin: 12px 12px 0 27px;
    overflow: inherit;

    .generic-form-body {
        width: 50%;
        background-color: #fff;
        padding: 10px;
        overflow: inherit;
        position: relative;

        .gfb-inner {
            flex-wrap: nowrap;

            .generic-input-box {
                width: 25%;
                margin: 0;
                padding-right: 10px;
            }
            .generic-input-box:nth-child(1) {
                width: 36%;
                .gib-range{
                    .ant-picker{
                        padding: 5px;
                        .ant-picker-input{
                            input{
                                padding-right: 0;
                            }
                        }
                    }
                    .gen-input-clear hide{
                        right: 3px;
                    }
                }
            }

            .list-component-section {
                top: 35px;
            }

            .gfbi-btns {
                display: flex;
                align-items: center;
                position: relative;

                .generic-default-btn:nth-child(1) {
                    display: flex;
                    align-items: center;
                    color: #111d31 !important;
                    font-weight: 600 !important;
                    margin-right: 10px;
                    white-space: nowrap;

                    svg {
                        path {
                            fill: #111d31;
                        }
                    }
                }

                .gfbii-dropdown {
                    width: auto;
                    min-width: 200px;
                    min-height: 200px;
                    display: flex;
                    position: absolute;
                    top: 37px;
                    left: 0;
                    background-color: #fff;
                    border-radius: 4px;
                    z-index: 8;
                    box-shadow: 0 1px 11px -4px #9ea0a1e6;
                    .gfbiid-input{
                        width: 300px;
                        .generic-input-box {
                            width: 100%;
                            margin: 10px 10px 0;
                            padding-right: 20px;
                        }
                    }

                    .generic-form-body {
                        width: 100%;

                        .gfb-inner {
                            height: 200px;
                            overflow: auto;
                            .generic-input-box {
                                width: 100%;
                                padding: 0;
                                margin: 0 0 10px;
                            }
                        }
                    }
                    .generic-form-body::before {
                        display: none;
                    }

                    .applied-filter {
                        width: 445px;
                        min-height: 100%;
                        overflow: auto;
                        padding: 5px 15px;
                        z-index: 10;
                        border-left: 1px solid #dae4ee;

                        .af-top {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 5px 0;

                            h2 {
                                color: #111d31;
                                font-size: 12px;
                                font-weight: 700;
                                margin: 0;
                            }

                            button {
                                height: 24px;
                                font-size: 12px;
                            }
                        }
                        .af-body{
                            height: 200px;
                            overflow: auto;
                            .af-det {
                                p {
                                    font-size: 11px;
                                    font-weight: 500;
                                    color: #71819b;
                                    margin-bottom: 3px;
                                }
    
                                label {
                                    color: #111d31;
                                    font-size: 12px;
                                    font-weight: 600;
                                    margin: 0 5px 5px 0;
                                    padding: 2px 4px;
                                    background: #f5f5f5;
                                    border-radius: 3px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .generic-form-body::before {
        content: "";
        position: absolute;
        top: 18px;
        right: 10px;
        height: 16px;
        width: 2px;
        display: block;
        background: #b7c6de;
    }

    .afd-filters {
        width: 50%;
        max-width: 50%;
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 10px;
        overflow: inherit;

        button {
            display: flex;
            align-items: center;
            white-space: nowrap;
        }

        .afdff {
            width: fit-content;
            height: 30px;
            display: flex;
            align-items: center;
            border: 1px solid #dde6ef;
            border-radius: 4px;
            padding: 0 5px;
            margin-right: 10px;
            cursor: pointer;
            position: relative;
            text-transform: capitalize;

            p {
                color: #23235f;
                font-size: 13px;
                font-weight: 500;
                margin: 0;
                white-space: nowrap;
            }

            button {
                height: 100%;
                padding-left: 3px;
            }

            .three-dot {
                height: 100%;
                padding: 2px 7px;
                position: relative;
                display: flex;
                align-items: center;
                visibility: hidden;
                opacity: 0;

                ul {
                    min-width: 150px;
                    position: absolute;
                    top: 27px;
                    left: 0;
                    border-radius: 3px;
                    background-color: #fff;
                    text-align: left;
                    box-shadow: 0 4px 14px -3px hsla(0, 0%, 55%, .25);
                    transition: all .6s;
                    z-index: 2;

                    li {
                        width: 100%;
                        font-size: 13px;
                        color: #21314b;
                        text-align: left;
                        padding: 3px 10px;
                        font-weight: 500;
                        white-space: nowrap;
                        position: relative;
                        cursor: pointer;
                        text-transform: capitalize;
                    }

                    li:hover {
                        background-color: #f5f5f5;
                    }

                    .delete-tab {
                        color: $danger-color;
                    }
                }
            }
        }

        .afdff:hover {
            background-color: #f5f5f5;

            .three-dot {
                visibility: visible;
                opacity: 1;
            }
        }

        .afdff-sel {
            border: 1px solid $primary-color;
            background-color: $primary-color;

            p {
                color: #fff;
            }

            button {
                svg {
                    path {
                        fill: #fff;
                    }
                }
            }

            .three-dot {
                svg {
                    circle {
                        fill: #fff;
                    }
                }
            }
        }

        .afdff-sel:hover {
            background-color: $primary-color;
        }

        .afdff-btns {
            display: flex;
            align-items: center;
            position: relative;

            button {
                margin-left: 10px;
                display: flex;
                align-items: center;

                svg {
                    margin-left: 5px;
                }
            }

            .pinned-filter-dropdown {
                min-width: 200px;
                max-height: 250px;
                overflow-y: auto;
                position: absolute;
                top: 36px;
                right: 0;
                left: auto;
                background-color: #fff;
                box-shadow: 0 2.71px 18.99px hsla(0, 0%, 63%, .26);
                transition: all .6s;
                z-index: 10;
                border-radius: 3px;

                li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 5px 10px;
                    cursor: pointer;

                    p {
                        font-weight: 500;
                        font-size: 13px;
                        color: #23235f;
                        white-space: nowrap;
                        margin: 0;
                    }

                    .three-dot {
                        height: 100%;
                        padding: 2px 7px;
                        position: relative;
                        display: flex;
                        align-items: center;

                        ul {
                            min-width: 150px;
                            position: absolute;
                            top: 27px;
                            left: 28px;
                            border-radius: 3px;
                            background-color: #fff;
                            text-align: left;
                            box-shadow: 0 4px 14px -3px hsla(0, 0%, 55%, .25);
                            transition: all .6s;
                            z-index: 2;

                            li {
                                width: 100%;
                                font-size: 13px;
                                color: #21314b;
                                text-align: left;
                                padding: 3px 10px;
                                font-weight: 500;
                                white-space: nowrap;
                                position: relative;
                                cursor: pointer;
                                text-transform: capitalize;
                            }

                            li:hover {
                                background-color: #f5f5f5;
                            }

                            .delete-tab {
                                color: $danger-color;
                            }
                            .delete-tab:hover{
                                background-color: #fdf2f7;
                            }
                        }
                    }

                    .three-dot:hover > svg{
                        circle{
                            fill: $primary-color;
                        }
                    }
                }

                li:hover {
                    background-color: #f5f5f5;
                }
            }
        }
    }
}

// Box Analysis
.box-analysis {
    width: 100%;
    display: flex;
    padding: 12px 12px 12px 27px;

    .pie-chart-section:nth-child(1) {
        width: 55%;
    }
}

// ASN Life Cycle
.asn-life-cycle {
    padding: 12px 12px 12px 27px;
}

// Warehouse Appointment
.warehouse-appointment {
    .all-filter-dashboard {
        .generic-form-body {
            .gfb-inner {
                .generic-input-box {
                    width: 20%;
                }
            }
        }
    }

    .wa-bot {
        display: flex;
        padding: 12px 12px 12px 27px;

        .wab-left {
            width: 25%;
            height: calc(100vh - 175px);
            overflow: auto;
            margin-right: 12px;
            background-color: #fff;
            padding: 10px;

            button {
                width: 100%;
                border: 1px solid #dae4ee;
                border-radius: 13px;
                padding: 10px 10px 7px;
                margin-bottom: 10px;

                .btn-top {
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 10px;

                    h3 {
                        height: fit-content;
                        width: fit-content;
                        color: #21314b;
                        font-size: 13px;
                        font-weight: 600;
                        margin: 0;
                    }

                    .range-per {
                        width: 50%;

                        .pro-bar {
                            display: flex;
                            justify-content: space-between;

                            span {
                                color: #67768E;
                                font-size: 11px;
                                font-weight: 400;
                            }

                            .per-num {
                                color: #03B675;
                                font-weight: 600;
                                font-size: 12px;
                            }

                            .s-color {
                                color: #03B675;
                            }

                            .c-color {
                                color: $danger-color;
                            }

                            .n-color {
                                color: #111D31;
                            }

                            .f-color {
                                color: #FE9D0B;
                            }
                        }
                    }

                    .slow-fil {
                        progress {
                            width: 100%;
                        }

                        progress {
                            background: rgba(3, 182, 117, 0.08);
                        }

                        progress {
                            color: #03B675;
                            height: 6px;
                            border-radius: 20px;
                        }

                        progress::-webkit-progress-value {
                            background: #03B675;
                            border-radius: 20px;
                        }

                        progress::-moz-progress-bar {
                            background: #03B675;
                            border-radius: 20px;
                        }

                        progress::-webkit-progress-bar {
                            background: rgba(3, 182, 117, 0.08);
                            border-radius: 20px;
                        }
                    }

                    .non-fil {
                        progress {
                            width: 100%;
                        }

                        progress {
                            background: rgba(3, 182, 117, 0.08);
                        }

                        progress {
                            color: #111D31;
                            height: 6px;
                            border-radius: 20px;
                        }

                        progress::-webkit-progress-value {
                            background: #111D31;
                            border-radius: 20px;
                        }

                        progress::-moz-progress-bar {
                            background: #111D31;
                            border-radius: 20px;
                        }

                        progress::-webkit-progress-bar {
                            background: rgba(103, 118, 142, 0.08);
                            border-radius: 20px;
                        }
                    }

                    .fast-fil {
                        progress {
                            width: 100%;
                        }

                        progress {
                            background: rgba(254, 157, 11, 0.08);
                        }

                        progress {
                            color: #FE9D0B;
                            height: 6px;
                            border-radius: 20px;
                        }

                        progress::-webkit-progress-value {
                            background: #FE9D0B;
                            border-radius: 20px;
                        }

                        progress::-moz-progress-bar {
                            background: #FE9D0B;
                            border-radius: 20px;
                        }

                        progress::-webkit-progress-bar {
                            background: rgba(254, 157, 11, 0.08);
                            border-radius: 20px;
                        }
                    }

                    .cap-fil {
                        progress {
                            width: 100%;
                        }

                        progress {
                            background: rgba(253, 55, 87, 0.08);
                        }

                        progress {
                            color: $danger-color;
                            height: 6px;
                            border-radius: 20px;
                        }

                        progress::-webkit-progress-value {
                            background: $danger-color;
                            border-radius: 20px;
                        }

                        progress::-moz-progress-bar {
                            background: $danger-color;
                            border-radius: 20px;
                        }

                        progress::-webkit-progress-bar {
                            background: rgba(253, 55, 87, 0.08);
                            border-radius: 20px;
                        }
                    }
                }

                .btn-bot {
                    display: flex;
                    justify-content: space-between;

                    .btnb {
                        text-align: left;

                        p {
                            margin: 0;
                            color: #67768e;
                            font-size: 11px;
                            font-weight: 400;
                        }

                        label {
                            margin: 0;
                            color: #67768e;
                            font-size: 13px;
                            font-weight: 600;
                        }

                    }
                }
            }

            .sel-btn {
                background-color: #2969ff;

                .btn-top {
                    h3 {
                        background: #fff;
                        color: #2969ff;
                        padding: 3px 7px;
                        border-radius: 60px;
                    }

                    .range-per{
                        .pro-bar{
                            span{
                                color: #fff;
                            }
                        }
                    }
                }

                .btn-bot {
                    .btnb {
                        p {
                            color: #fff;
                        }

                        label {
                            color: #fff;
                        }
                    }
                }
            }

            button:nth-last-child(1) {
                margin: 0;
            }
        }

        .wab-right {
            width: 75%;
            height: calc(100vh - 175px);
            overflow: auto;
            background-color: #fff;
            padding: 10px;

            .wabr-top {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .wabrt-left {
                    display: flex;
                    align-items: center;

                    .wabrtl {
                        label {
                            color: #67768e;
                            font-size: 11px;
                            font-weight: 400;
                            margin-bottom: 5px;
                        }

                        p {
                            color: #fff;
                            font-size: 13px;
                            font-weight: 600;
                            background: #8496a9;
                            border-radius: 60px;
                            padding: 3px 7px;
                            margin: 0;
                        }
                    }

                    .wh-show-data{
                        p{
                            margin: 0;
                            color: #67768E;
                            font-size: 11px;
                            font-weight: 400;
                            padding-bottom: 5px;
                        }
                        span{
                            color: #fff;
                            font-size: 13px;
                            font-weight: 600;
                            background: #8496A9;
                            border-radius: 60px;
                            padding: 1px 10px;
                            min-width: 50px;
                            display: inline-block;
                            height: 21.5px;
                        }
                        button {
                            color: #fff;
                            font-size: 13px;
                            font-weight: 600;
                            background: #2969FF;
                            border-radius: 60px;
                            padding: 3px 7px;
                            min-width: 60px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            // height: 21.5px;
                            svg {
                                margin-left: 10px;
                            }
                        }
                    }
                }

                .ant-radio-group {
                    height: 32px;
                    display: flex;
                    align-items: center;
                    background-color: #ebebf3;
                    border-radius: 5px;
                    padding: 0 3px;
                    margin-left: 10px;

                    .ant-radio-button-wrapper {
                        height: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: transparent;
                        border-radius: 3px;
                        font-size: 13px;
                        font-weight: 600;
                        color: #21314b;
                        border: none;
                        padding: 0 20px;
                        white-space: nowrap;
                    }

                    .ant-radio-button-wrapper-checked {
                        // background-color: #67768e;
                        // color: #fff;
                        // border: none;
                        background-color: #fff;
                        border: none;
                    }

                    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
                        display: none;
                    }

                    .ant-radio-button-wrapper:not(:first-child)::before {
                        display: none;
                    }
                }
            }

            table {
                margin-top: 10px;
                width: 100%;

                thead {
                    tr {
                        th {
                            color: #67768e;
                            font-size: 12px;
                            font-weight: 600;
                            padding: 8px;
                            background-color: #fafafa;
                            text-align: left;
                            border-bottom: 1px solid #eef0fe;

                            p {
                                color: #67768e;
                                font-size: 11px;
                                font-weight: 400;
                                margin: 0;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            color: #111d31;
                            font-size: 12px;
                            font-weight: 500;
                            padding: 8px;
                            border-bottom: 1px solid #eef0fe;
                            border-right: 1px solid #eef0fe;
                            button{
                                padding: 5px 15px;
                                border-radius: 40px;
                                display: flex;
                                align-items: center;
                                font-size: 12px;
                                font-weight: 600;
                                cursor: auto;
                                progress{
                                    width: 50px;
                                    margin-right: 10px;
                                }
                            }
                            .fast-fill{
                                background: rgba(254, 157, 11, 0.08);
                                color: #9B6007;
                                progress {
                                    background: rgba(254, 157, 11, 0.08);
                                }                                  
                                progress {
                                    color: #FE9D0B;
                                    height: 6px;
                                    border-radius: 20px;
                                }                                  
                                progress::-webkit-progress-value {
                                    background: #FE9D0B;
                                    border-radius: 20px;
                                }                                  
                                progress::-moz-progress-bar {
                                    background: #FE9D0B;
                                    border-radius: 20px;
                                }
                                progress::-webkit-progress-bar {
                                    background: rgba(254, 157, 11, 0.08);
                                    border-radius: 20px;
                                }
                            }
                            .cap-fill{
                                background: rgba(253, 55, 87, 0.08);
                                color: $danger-color;
                                progress {
                                    background: rgba(253, 55, 87, 0.08);
                                }
                                progress {
                                    color: $danger-color;
                                    height: 6px;
                                    border-radius: 20px;
                                }                                  
                                progress::-webkit-progress-value {
                                    background: $danger-color;
                                    border-radius: 20px;
                                }                                  
                                progress::-moz-progress-bar {
                                    background: $danger-color;
                                    border-radius: 20px;
                                }
                                progress::-webkit-progress-bar {
                                    background: rgba(253, 55, 87, 0.08);
                                    border-radius: 20px;
                                }
                            }
                            .slow-fill{
                                background: rgba(3, 182, 117, 0.08);
                                color: #03B675;
                                progress {
                                    background: rgba(3, 182, 117, 0.08);
                                }
                                progress {
                                    color: #03B675;
                                    height: 6px;
                                    border-radius: 20px;
                                }                                  
                                progress::-webkit-progress-value {
                                    background: #03B675;
                                    border-radius: 20px;
                                }                                  
                                progress::-moz-progress-bar {
                                    background: #03B675;
                                    border-radius: 20px;
                                }
                                progress::-webkit-progress-bar {
                                    background: rgba(3, 182, 117, 0.08);
                                    border-radius: 20px;
                                }
                            }
                            .non-fill{
                                background: rgba(103, 118, 142, 0.08);
                                color: #111D31;
                                progress {
                                    background: rgba(3, 182, 117, 0.08);
                                }
                                progress {
                                    color: #111D31;
                                    height: 6px;
                                    border-radius: 20px;
                                }                                  
                                progress::-webkit-progress-value {
                                    background: #111D31;
                                    border-radius: 20px;
                                }                                  
                                progress::-moz-progress-bar {
                                    background: #111D31;
                                    border-radius: 20px;
                                }
                                progress::-webkit-progress-bar {
                                    background: rgba(103, 118, 142, 0.08);
                                    border-radius: 20px;
                                }
                            }
                        }

                        td:nth-last-child(1) {
                            border-right: none;
                        }
                    }
                }
            }

            .no-data-found-screen {
                height: 50%;
            }
        }
    }
}

// Order Tracking Dashboard (Beta)
.order-tracking-dashboard {
    height: calc(100vh - 106px);
    position: relative;

    .otd-alert{
        padding: 0 12px 12px 27px;
        .discrepancies-body{
            background: rgba(227, 31, 31, 0.07);
            border-radius: 5px;
            display: inline-block;
            p{
                margin: 0;
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: 12px;
                color: #000000;
                padding: 8px;
                label{
                    margin: 0;
                    text-decoration-line: underline;
                    padding-left: 10px;
                    font-weight: 500;
                    font-size: 12px;
                    color: #000000;
                    cursor: pointer !important;
                }
                span{
                    padding-right: 10px;
                }
            }
        }
    }

    .od-toggle {
        display: flex;
        align-items: center;
        position: absolute;
        right: 12px;
        top: -46px;
        z-index: 9;

        .ant-toggle {
            padding-right: 10px;

            .ant-switch-checked {
                background: #50617d;
            }

            .ant-switch-checked:hover:not(.ant-switch-disabled) {
                background: #50617d;
            }
        }

        p {
            font-weight: 500;
            color: #67768e;
            font-size: 11px;
            margin: 0;

            span {
                opacity: .7;
                font-weight: 700;
                font-size: 12px;
                color: #111d31;
            }
        }
    }

    .all-filter-dashboard{
        margin-bottom: 12px;
    }
    .otd-scroll{
        height: calc(100% - 70px);
        overflow: auto;

        .otd-mid {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            padding: 0 12px 12px 27px;

            .otd-gap {
                width: 33.33%;
                height: fit-content;
                padding-right: 12px;

                .otdc-cards {
                    min-height: 110px;
                    display: flex;
                    justify-content: space-between;
                    background-color: #fff;
                    border-radius: 6px;
                    box-shadow: 0 0 16px 1px hsla(0, 0%, 64%, .25);
                    transition: all .6s;

                    .otdcc-left {
                        width: 50%;
                        display: flex;
                        flex-direction: column;
                        padding: 5px 10px;

                        h3 {
                            width: fit-content;
                            display: flex;
                            align-items: center;
                            color: #0f172a;
                            font-size: 23px;
                            font-weight: 700;
                            margin: 0;
                            position: relative;

                            b {
                                color: #8496a9;
                                font-size: 11px;
                                font-weight: 400;
                                padding-left: 5px;
                                font-family: system-ui;
                            }

                            .success-icon {
                                display: none;
                            }

                            .rupee-icon {
                                color: #0f172a;
                                font-size: 13px;
                                padding-right: 3px;
                            }

                            .last-days-tag {
                                font-size: 10px;
                                position: relative;
                                font-weight: 400;
                                margin-top: 3px;
                                padding-left: 10px;
                            }

                            .last-days-tag::before {
                                content: "";
                                position: absolute;
                                top: 7px;
                                left: 5px;
                                width: 2px;
                                height: 2px;
                                border-radius: 50%;
                                background-color: #8496a9;
                            }

                            .generic-tooltip {
                                top: 37px;
                                left: 60%;
                            }
                            .generic-tooltip::after{
                                top: -10px;
                                margin-left: 0;
                                border-color: transparent transparent #000;
                            }
                        }

                        h3:hover {
                            .generic-tooltip {
                                visibility: visible;
                                opacity: 1;
                            }
                        }

                        p {
                            width: fit-content;
                            position: relative;
                            color: #0f172a;
                            font-size: 13px;
                            font-weight: 400;
                            display: flex;
                            align-items: center;
                            white-space: nowrap;
                            cursor: pointer;
                            margin: 0;

                            span {
                                position: relative;
                                padding-left: 10px;

                                ul {
                                    max-height: 370px;
                                    overflow: auto;
                                    display: block;
                                    position: absolute;
                                    top: -5px;
                                    left: 9px;
                                    border-radius: 6px;
                                    box-shadow: 0 2px 6px 0 rgba(75, 75, 75, .16);
                                    background-color: #fff;
                                    z-index: 8;
                                    visibility: hidden;
                                    opacity: 0;

                                    li {
                                        min-width: 150px;
                                        display: flex;
                                        align-items: center;
                                        padding: 5px 8px;
                                        white-space: nowrap;
                                        color: #111d31;
                                        font-size: 12px;
                                        font-weight: 500;
                                        cursor: pointer;
                                    }

                                    li:hover {
                                        background-color: #f5f5f5;
                                    }
                                }
                            }
                        }

                        p:hover {
                            span {
                                ul {
                                    visibility: visible;
                                    opacity: 1;
                                }
                            }
                        }

                        .otdccl {
                            padding-right: 20px;

                            label {
                                color: #67768e;
                                font-size: 8px;
                                font-weight: 400;
                                margin: 0;
                            }

                            p {
                                font-size: 12px;
                                font-weight: 600;
                                margin: 0;
                            }
                        }
                    }

                    .otdcc-right {
                        width: 50%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        .otdccr-top,
                        .otdccr-bot {
                            height: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: space-evenly;
                            background: linear-gradient(270deg, #a9fee3, rgba(228, 247, 246, 0) 88.17%);
                            position: relative;
                            border-radius: 0 6px 0 0;

                            .otdccrt {
                                p {
                                    font-weight: 500;
                                    color: #8496a9;
                                    font-size: 11px;
                                    margin: 0;
                                }

                                label {
                                    color: #456664;
                                    font-size: 14px;
                                    font-weight: 500;
                                    text-align: start;
                                    margin: 0;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    position: relative;

                                    b {
                                        font-family: system-ui;
                                    }
                                }
                                label:hover{
                                    .generic-tooltip{
                                        visibility: visible;
                                        opacity: 1;
                                    }
                                }
                            }

                            .otdccrt-count {
                                font-size: 10px;
                                font-weight: 600;
                                color: #fff;
                                border-radius: 2px;
                                padding: 2px;
                                background: linear-gradient(90deg, #0fb9b1, #20bf6b);
                            }

                            ul {
                                max-height: 370px;
                                overflow: auto;
                                display: block;
                                position: absolute;
                                top: 0;
                                left: 100%;
                                border-radius: 6px;
                                box-shadow: 0 2px 6px 0 rgba(75, 75, 75, .16);
                                background-color: #fff;
                                z-index: 8;
                                visibility: hidden;
                                opacity: 0;

                                li {
                                    min-width: 150px;
                                    display: flex;
                                    align-items: center;
                                    padding: 5px 8px;
                                    white-space: nowrap;
                                    color: #111d31;
                                    font-size: 12px;
                                    font-weight: 500;
                                    cursor: pointer;
                                }

                                li:hover {
                                    background-color: #f5f5f5;
                                }
                            }
                        }

                        .otdccr-top:hover {
                            background: linear-gradient(270deg, #31f9b9, rgba(228, 247, 246, 0) 88.17%);

                            ul {
                                visibility: visible;
                                opacity: 1;
                            }
                        }

                        .otdccr-bot {
                            border-radius: 0 0 6px 0;
                            background: linear-gradient(270deg, #ffd7d7, hsla(0, 75%, 97%, 0) 90.56%);

                            .otdccrt {
                                label {
                                    color: #714e4e;
                                }
                            }

                            .otdccrt-count {
                                background: linear-gradient(90deg, #ea8685, #fc5c65);
                            }
                        }

                        .otdccr-bot:hover {
                            background: linear-gradient(270deg, #fd7171, hsla(0, 75%, 97%, 0) 90.56%);

                            ul {
                                visibility: visible;
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            .otd-gap:nth-last-child(1) {
                .otdc-cards {
                    background: linear-gradient(90deg, #1ebe74, rgba(147, 222, 187, 0) 60.39%), linear-gradient(0deg, #fff, #fff);
                    padding: 2px 0 2px 2px;

                    .otdcc-left {
                        background-color: #fff;
                        border-radius: 4px 0 0 4px;

                        h3 {
                            .success-icon {
                                display: block;
                                padding-right: 5px;
                            }
                        }
                    }

                    .otdcc-right {
                        position: relative;
                        background-color: #fff;

                        .otdccr-top,
                        .otdccr-bot {
                            width: 100%;
                            height: 55px;
                            position: absolute;
                            top: -2px;
                            right: -1px;
                        }

                        .otdccr-bot {
                            top: auto;
                            bottom: -2px;
                        }
                    }
                }
            }

            .otd-gap:nth-child(3),
            .otd-gap:nth-child(6) {
                padding-right: 0;

                .otdc-cards {
                    .otdcc-right {

                        .otdccr-top,
                        .otdccr-bot {
                            ul {
                                left: auto;
                                right: 88%;
                            }
                        }
                    }
                }
            }

            .otd-gap:nth-child(4),
            .otd-gap:nth-child(5),
            .otd-gap:nth-child(6) {
                padding-top: 12px;
            }

            .otd-gap:nth-child(1),
            .otd-gap:nth-child(2),
            .otd-gap:nth-child(3) {
                .otdc-cards{
                    .otdcc-right{
                        .otdccr-top{
                            .otdccrt{
                                label{
                                    .generic-tooltip {
                                        top: 28px;
                                        left: 60%;
                                    }
                                    .generic-tooltip::after{
                                        top: -10px;
                                        margin-left: 0;
                                        border-color: transparent transparent #000;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .otd-bot {
            width: 100%;
            display: flex;
            padding: 12px 12px 12px 27px;
            border-top: 1px solid #dce6f0a8;

            .otdb-left {
                width: 65%;
                background-color: #fff;
                margin-right: 12px;

                table {
                    width: 100%;
                    border: 1px solid #DAE4EE;
                    border-radius: 6px;
                    background-color: #fff;

                    thead {
                        tr {
                            th {
                                padding: 8px;
                                border-bottom: 1px solid #DAE4EE;
                                border-right: 1px solid #DAE4EE;
                                text-align: left;
                                vertical-align: bottom;

                                label {
                                    color: #111D31;
                                    font-size: 14px !important;
                                    font-weight: 700;
                                    margin: 0;
                                }

                                .del-head-ot {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;

                                    svg {
                                        cursor: pointer;
                                    }
                                }
                            }
                        }

                        .osl-sub-head {
                            th {
                                label {
                                    color: #67768E;
                                    font-size: 12px !important;
                                    font-weight: 500;
                                    margin: 0;
                                }
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                height: 36px;
                                position: relative;
                                padding: 0 8px;
                                border-bottom: 1px solid #DAE4EE;
                                border-right: 1px solid #DAE4EE;

                                label {
                                    color: #111D31 !important;
                                    font-size: 13px !important;
                                    margin: 0;
                                    font-weight: 500;
                                }

                                .amount {
                                    color: #111d31;
                                    font-size: 13px !important;
                                    margin: 0;
                                    font-weight: 500;

                                    label {
                                        span {
                                            font-family: system-ui;
                                            font-weight: 400;
                                        }
                                    }

                                    .generic-tooltip {
                                        left: 10%;
                                    }
                                }

                                .amount:hover {
                                    .generic-tooltip {
                                        visibility: visible;
                                        opacity: 1;
                                    }
                                }

                                .logistic-table-ot {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    position: relative;

                                    .logic-ot {
                                        display: flex;
                                        flex-direction: column;

                                        span {
                                            color: #67768E;
                                            font-weight: 400;
                                            font-size: 10px;
                                        }
                                    }

                                    .ot-icon {
                                        position: relative;

                                        svg {
                                            cursor: pointer;
                                        }

                                        .generic-tooltip {
                                            background: #FD7272;
                                            white-space: normal;
                                            text-align: left;
                                            top: -86px;
                                            left: -83px;
                                            padding: 10px;

                                            .ot-tooltip {
                                                width: 250px;

                                                u {
                                                    cursor: pointer;
                                                }
                                            }
                                        }

                                        .generic-tooltip:after {
                                            border-color: #fd7272 transparent transparent;
                                            left: 50%;
                                        }
                                    }

                                    .ot-icon:hover {
                                        .generic-tooltip {
                                            opacity: 1;
                                            visibility: visible;
                                        }
                                    }
                                }

                                .shipment-table-ot {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;

                                    .logic-ot {
                                        display: flex;
                                        flex-direction: column;

                                        span {
                                            color: #67768E;
                                            font-weight: 400;
                                            font-size: 10px;
                                        }
                                    }

                                    label {
                                        color: #485869;
                                    }

                                    .ot-icon {
                                        position: relative;

                                        svg {
                                            cursor: pointer;
                                        }

                                        .generic-tooltip {
                                            background: #FD7272;
                                            white-space: normal;
                                            text-align: left;
                                            top: -86px;
                                            left: -83px;
                                            padding: 10px;

                                            .ot-tooltip {
                                                width: 250px;

                                                p{
                                                    color: #fff;
                                                }

                                                u {
                                                    cursor: pointer;
                                                }
                                            }
                                        }

                                        .generic-tooltip:after {
                                            border-color: #fd7272 transparent transparent;
                                            left: 50%;
                                        }
                                    }

                                    .ot-icon:hover {
                                        .generic-tooltip {
                                            opacity: 1;
                                            visibility: visible;
                                        }
                                    }
                                }

                                .trc-first {
                                    position: relative;

                                    .amount {
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-start;
                                        position: relative;

                                        .review-tool {
                                            visibility: hidden;
                                            opacity: 0;
                                            cursor: pointer;

                                            .generic-tooltip {
                                                top: -32px;
                                                left: 52%;
                                                background: #111D31;
                                                font-size: 12px;
                                                font-weight: 600;
                                            }

                                            .generic-tooltip:after {
                                                left: 50%;
                                            }
                                        }
                                    }
                                }

                                .count-second {
                                    position: relative;

                                    .review-tool {
                                        visibility: hidden;
                                        opacity: 0;
                                        cursor: pointer;

                                        .generic-tooltip {
                                            top: -24px;
                                            left: 35%;
                                            background: #111D31;
                                            font-size: 12px;
                                            font-weight: 600;
                                        }

                                        .generic-tooltip:after {
                                            left: 50%;
                                        }
                                    }

                                    .amount {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        position: relative;

                                        .review-tool {
                                            visibility: hidden;
                                            opacity: 0;
                                            cursor: pointer;

                                            .generic-tooltip {
                                                top: -24px;
                                                left: 35%;
                                                background: #111D31;
                                                font-size: 12px;
                                                font-weight: 600;
                                            }

                                            .generic-tooltip:after {
                                                left: 50%;
                                            }
                                        }
                                    }
                                }

                                .ship-ot-head {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;

                                    label {
                                        color: #111D31;
                                        font-size: 14px !important;
                                        font-weight: 700;
                                        margin: 0;
                                    }

                                    svg {
                                        cursor: pointer;
                                    }
                                }

                                .log-table-ot {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;

                                    label {
                                        color: #111D31;
                                        font-size: 14px !important;
                                        font-weight: 700;
                                        margin: 0;
                                    }

                                    svg {
                                        cursor: pointer;
                                    }
                                }
                            }

                            td:hover {
                                .trc-first {
                                    .amount {
                                        .review-tool {
                                            visibility: hidden;
                                            opacity: 0;

                                            .generic-tooltip {
                                                visibility: hidden;
                                                opacity: 0;
                                            }
                                        }
                                    }
                                }

                                .count-second:hover {
                                    .review-tool {
                                        visibility: hidden;
                                        opacity: 0;

                                        .generic-tooltip {
                                            visibility: hidden;
                                            opacity: 0;
                                        }
                                    }

                                    .amount {
                                        .review-tool {
                                            visibility: hidden;
                                            opacity: 0;

                                            .generic-tooltip {
                                                visibility: hidden;
                                                opacity: 0;
                                            }
                                        }
                                    }
                                }
                            }

                            .trc-first {
                                position: relative;

                                .amount {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    position: relative;

                                    label {
                                        cursor: pointer !important;
                                    }

                                    .review-tool {
                                        visibility: hidden;
                                        opacity: 0;
                                        cursor: pointer;

                                        .generic-tooltip {
                                            top: -32px;
                                            left: 52%;
                                            background: #111D31;
                                            font-size: 12px;
                                            font-weight: 600;
                                        }

                                        .generic-tooltip:after {
                                            left: 50%;
                                        }
                                    }
                                }
                            }

                            .count-second {
                                // border: 2px solid transparent;
                                position: relative;

                                .review-tool {
                                    visibility: hidden;
                                    opacity: 0;
                                    cursor: pointer;

                                    .generic-tooltip {
                                        top: -24px;
                                        left: 35%;
                                        background: #111D31;
                                        font-size: 12px;
                                        font-weight: 600;
                                    }

                                    .generic-tooltip:after {
                                        left: 50%;
                                    }
                                }

                                .amount {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    position: relative;

                                    label {
                                        cursor: pointer !important;
                                    }

                                    .review-tool {
                                        visibility: hidden;
                                        opacity: 0;
                                        cursor: pointer;

                                        .generic-tooltip {
                                            top: -24px;
                                            left: 35%;
                                            background: #111D31;
                                            font-size: 12px;
                                            font-weight: 600;
                                        }

                                        .generic-tooltip:after {
                                            left: 50%;
                                        }
                                    }
                                }
                            }

                            .otqty {
                                background: linear-gradient(90deg, #0fb9b114 0%, #20bf6b1a 100%);
                                border-left: 1px solid #DAE4EE;
                                cursor: pointer;

                                label {
                                    cursor: pointer !important;
                                }

                                .amount {
                                    color: #111d31;
                                    font-size: 13px !important;
                                    margin: 0;
                                    font-weight: 500;

                                    span {
                                        font-family: system-ui;
                                        font-weight: 400;
                                    }
                                }
                            }

                            .otqcount {
                                background: linear-gradient(90deg, #0fb9b114 0%, #20bf6b1a 100%);
                                border-right: 1px solid #DAE4EE;
                                cursor: pointer;

                                .amount {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                }

                                label {
                                    cursor: pointer !important;
                                }
                            }

                            .dey-qty,
                            .dey-count {
                                background: linear-gradient(90deg, #ea868514 0%, #fc5c651a 100%);
                                cursor: pointer;

                                label {
                                    cursor: pointer !important;
                                }

                                .amount {
                                    color: #111d31;
                                    font-size: 13px !important;
                                    margin: 0;
                                    font-weight: 500;

                                    span {
                                        font-family: system-ui;
                                        font-weight: 400;
                                    }
                                }
                            }

                            .sot-brd {
                                background: linear-gradient(90deg, #0fb9b114 0%, #20bf6b1a 100%);
                                border-left: 1px solid #DAE4EE;
                                border-right: 1px solid #DAE4EE;
                            }

                            .lot-brd {
                                background: linear-gradient(90deg, #0fb9b114 0%, #20bf6b1a 100%);
                                border-left: 1px solid #DAE4EE;
                                border-right: 1px solid #DAE4EE;
                            }

                            .sot-exp,
                            .lot-exp {
                                background: linear-gradient(90deg, #ea868514 0%, #fc5c651a 100%);
                            }

                            .othead {
                                border-right: 1px solid #DAE4EE;

                                label {
                                    color: #485869;
                                }
                            }

                            .otqty:hover,
                            .otqcount:hover {
                                background-color: #18BC8D;
                            }

                            .dey-qty:hover,
                            .dey-count:hover {
                                background: #EC8382;
                            }
                        }
                    }
                }
            }

            .otdb-right {
                width: 35%;
                height: fit-content;
                background-color: #fff;
                padding: 12px;

                .ant-radio-group {
                    width: 100%;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    background-color: #ebebf3;
                    border-radius: 5px;
                    padding: 0 3px;

                    .ant-radio-button-wrapper {
                        height: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: transparent;
                        border-radius: 3px;
                        font-size: 12px;
                        font-weight: 600;
                        color: #21314b;
                        border: none;
                        padding: 0 45px;
                    }

                    .ant-radio-button-wrapper-checked {
                        background-color: #fff;
                        border: none;
                    }

                    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
                        display: none;
                    }

                    .ant-radio-button-wrapper:not(:first-child)::before {
                        display: none;
                    }
                }

                .pcsl-top {
                    padding: 10px 0;

                    p {
                        font-size: 12px;
                        font-weight: 500;
                        color: #000;
                        margin: 0;
                        opacity: .5;
                    }
                }

                .pcsl-circle {
                    display: flex;
                    justify-content: center;
                }

                .pcslc-bottom {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    padding: 15px 10px;

                    .pcslc-col {
                        position: relative;
                        padding-left: 16px;
                        margin-right: 30px;

                        h3 {
                            .generic-tooltip {
                                left: 0;
                            }
                        }

                        h3:hover {
                            .generic-tooltip {
                                visibility: visible;
                                opacity: 1;
                                left: 0;
                            }
                        }

                        .pcslcc-dot {
                            content: "";
                            display: block;
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background-color: #824bf6;
                            position: absolute;
                            left: 0px;
                            top: 2px;
                        }

                        .pcslccd-v2 {
                            background-color: #ef6e8b;
                        }

                        .pcslccd-v1 {
                            background-color: #6bf3e3;
                        }

                        .pcslccd-v3 {
                            background-color: #ffaf4f;
                        }

                        h3 {
                            font-size: 11px;
                            font-weight: 500;
                            color: #121212;
                            opacity: 0.7;
                            margin-bottom: 0px;
                            position: relative;
                        }
                    }
                }
            }
        }
    }
    .otd-scroll-more{
        height: calc(100% - 116px);
    }

    .sd-sec {
        position: relative;
        // overflow-x: hidden;

        .tableHeading{
            position: absolute;
            top: 1px;
            left: 10px;
            z-index: 1;
        }

        .gen-action-btn{
            position: absolute;
            left: 210px;
            top: 11px;
            z-index: 2;
        }

        .imh-update {
            padding: 0 15px 0 30px;
        
            .invoice-manage-head {
                height: auto;
                border: 0;
                padding: 0;
                padding: 10px 15px;
                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
                background-color: #fff;
                border-radius: 6px;
                box-shadow: 2px 0 6px 0 hsla(0, 0%, 55%, 0.16);

                .imh-left {
                    max-width: calc(100% - 470px);

                    .summary-data {
                        padding-right: 10px;

                        p {
                            font-weight: 600;
                            font-size: 11px;
                            color: #67768E;
                            margin: 0;
                        }

                        span {
                            font-weight: 600;
                            font-size: 16px;
                            color: #2A2A64;
                        }
                    }

                    .imhl-btn-back {
                        margin-right: 10px;

                        button {
                            padding: 5px 10px;
                            color: #21314b;
                            font-weight: 500;
                            border-radius: 3px;

                            svg {
                                margin-right: 5px;
                            }

                            margin: 0;
                        }
                    }

                    .audit-filter-field {
                        position: relative;

                        .dropdown-addfilter {
                            display: flex;
                            align-items: center;

                            .sdfl-input-col {
                                width: 300px;
                                position: relative;

                                .gsi-v2 {
                                    .gsis-clear {
                                        padding-right: 10px;
                                    }
                                }

                                .dropdown-menu-city1.gen-width-auto {
                                    top: 24px !important;
                                    min-width: 300px;
                                }
                            }
                        }
                    }

                    .imhl-btn-back:hover {
                        button {
                            border: 1px solid #111D31;
                        }
                    }

                    .imhl-search {
                        margin-right: 10px;

                        input {
                            width: 230px;
                            border-radius: 3px;
                        }

                        svg {
                            position: absolute;
                            top: 7px;
                            left: 7px;
                        }

                        .td-search {
                            position: absolute;
                            right: 20px;
                        }
                    }

                    .quick-filter {
                        button {
                            border-radius: 3px;

                            .qf-img {
                                margin: 0;
                            }
                        }

                        .qf-btn {
                            margin: 0;

                            .qf-img {
                                margin: 0;
                            }
                        }
                    }

                    .drop-toggle-btn {
                        margin-right: 10px;

                        button {
                            border: 1px solid #ccd8ed;
                            color: #000;
                            white-space: nowrap;
                            border-radius: 3px;
                        }

                        .dtb-dropdown {

                            .dtb-show,
                            .dtb-hide {
                                border: none;
                                white-space: nowrap;
                                width: 100%;
                                padding: 5px 10px;
                            }
                        }
                    }

                    .summary-count-tab {
                        display: flex;
                        align-items: center;

                        .summary-data {
                            p {
                                font-weight: 600;
                                font-size: 11px;
                                color: #67768E;
                                margin: 0;
                            }

                            label {
                                font-weight: 600;
                                font-size: 16px;
                                color: #2A2A64;
                                margin: 0;
                            }
                        }

                        .summ-rup-icon {
                            padding: 0 50px;
                            position: relative;

                            .toggle-icon {
                                cursor: pointer;
                                padding-left: 10px;
                            }
                        }

                        .summ-rup-icon:before {
                            content: "|";
                            position: absolute;
                            top: 10px;
                            left: 22px;
                            color: #b7c6de;
                            font-size: 17px;
                            font-weight: 300;
                        }

                        .summ-rup-icon:after {
                            content: "|";
                            position: absolute;
                            top: 10px;
                            right: 22px;
                            color: #b7c6de;
                            font-size: 17px;
                            font-weight: 300;
                        }
                    }
                }

                .imh-right {
                    justify-content: space-between;

                    .sort-genTooltip {
                        .generic-tooltip {
                            transform: translate(16%);
                            left: auto;
                            right: 50%;
                        }

                        .generic-tooltip:after {
                            left: auto;
                            right: 15%;
                        }
                    }

                    .gvpd-sync {
                        .sync-btn {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 5px 7px;
                            color: #21314b;
                            font-weight: 600;

                            svg {
                                margin-right: 5px;
                            }
                        }
                    }

                    .applied-filters {
                        display: flex;
                        align-items: center;
                        padding-inline: .75rem;
                        margin-left: 0;
                        border-radius: 5px;
                        height: 30px;
                    }

                    .applied-filter-opened,
                    .applied-filters:hover {
                        background: #f5f5f5;
                        border: 1px solid #f5f5f5;
                        color: #67768e;
                    }
                }

                .new-gen-pagination {
                    width: 100%;
                    height: auto;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    box-shadow: none;
                    padding: 0;
                    margin: 0;
                    position: relative;
                    background-color: #fff;

                    .ngp-left {
                        width: auto;
                        position: relative;
                        display: block;

                        .barcode-search {
                            position: relative;
                            // margin-right: 10px;

                            .generic-input-box {
                                margin: 0;

                                .gib-text {
                                    height: 30px;
                                    border: 1px solid #ccd8ed;

                                    // input {
                                    //     padding-left: 25px;
                                    // }
                                }
                            }

                            .barcode-icon {
                                position: absolute;
                                left: 6px;
                                top: 9px;
                            }
                        }

                        .imhl-search {
                            position: relative;

                            input {
                                background: #fff;
                                padding: 8px 30px;
                                height: 30px;
                                font-size: 12px;
                                color: #000;
                                font-weight: 600;
                                border-radius: 3px;
                                width: 110px;
                                border: 1px solid #ccd8ed;
                                transition: 0.5s;
                                border-radius: 5px;
                                outline: none;
                            }

                            input:focus {
                                width: 250px;
                                transition: 0.5s;
                                border: 1px solid $primary-color;
                                box-shadow: 0px 0px 0px 2px #DADAFF;
                            }

                            input::placeholder {
                                color: #8496A9;
                            }

                            .search-image {
                                position: absolute;
                                top: 8px;
                                left: 7px;
                                width: 15px;
                            }

                            svg {
                                position: absolute;
                                top: 7px;
                                left: 7px;
                            }

                            .td-search {
                                position: absolute;
                                top: 6px;
                                right: 2px;
                                padding: 10px;
                                cursor: pointer;
                                opacity: 0;
                                visibility: hidden;

                                svg {
                                    position: absolute;
                                    top: 2px;
                                    left: 8px;
                                }
                            }

                            .closeSearch {
                                position: absolute;
                                right: 46px;
                                top: 0px;
                                cursor: pointer;
                            }
                        }

                        .imhl-search:hover {
                            .td-search {
                                opacity: 1;
                                visibility: visible;
                            }
                        }

                        .drop-toggle-btn {
                            position: relative;
                            margin-left: 10px;

                            .dtb-show {
                                padding: 5px 7px;
                                white-space: nowrap;
                                color: #8496A9;
                                border-radius: 5px;
                                border: 1px solid #ccd8ed;
                                background-color: #fff;
                                margin-left: 0px;
                                height: 30px;
                                font-weight: 500;
                                position: relative;
                                font-size: 12px;
                                display: flex;
                                align-items: center;

                                .total-count {
                                    padding-right: 5px;

                                    .to-num {
                                        color: #111D31;
                                        font-size: 13px;
                                        font-weight: 600;
                                    }

                                    .to-num:hover {
                                        .generic-tooltip {
                                            opacity: 1;
                                            visibility: visible;
                                            left: 38%;
                                            color: #fff;
                                        }
                                    }

                                    .to-slas {
                                        color: #111D31;
                                        font-size: 12px;
                                        font-weight: 700;
                                        padding: 0 3px;
                                    }
                                }

                                span {
                                    color: #111d31;
                                    font-weight: 600;
                                }

                                svg {
                                    margin-left: 5px;
                                }
                            }

                            .dtb-show:hover {
                                border: 1px solid $primary-color;
                                box-shadow: 0px 0px 0px 2px #DADAFF;
                            }

                            .page-dropdown {
                                position: absolute;
                                top: 38px;
                                right: 0;
                                height: auto;
                                background-color: #fff;
                                border: none;
                                text-align: left;
                                box-shadow: 2px 0 6px 0 rgba(139, 139, 139, 0.16);
                                transition: all .6s;
                                z-index: 2;

                                .sel-page {
                                    border: none;
                                    width: 100%;
                                    margin: 0;
                                    text-align: left;
                                    font-size: 12px;
                                    padding: 5px 15px;
                                    font-weight: 500;
                                }

                                .sel-page:hover {
                                    background-color: $dropdown-item-hover;
                                }
                            }
                        }
                    }

                    .ngp-right {
                        display: block;
                        margin-left: 10px;

                        .nt-btn {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;

                            .pagination-inner {
                                display: block;

                                .pagination-item {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-end;

                                    li {
                                        margin: 0;

                                        button {
                                            margin-left: 10px;

                                            .page-item-btn-inner {
                                                letter-spacing: 3px;
                                            }
                                        }

                                        .falsenext-btn,
                                        .falselast-btn,
                                        .falseprev-btn,
                                        .falsefirst-btn {
                                            width: 30px;
                                            height: 30px;
                                            border: 1px solid #dae4ee;
                                            padding: 0;
                                            display: inline-block;
                                            border-radius: 4px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                        }

                                        .falsenext-btn:hover,
                                        .falselast-btn:hover,
                                        .falseprev-btn:hover,
                                        .falsefirst-btn:hover {
                                            color: #fff !important;
                                            background-color: $primary-color !important;
                                            border: 1px solid $primary-color !important;
                                            transition: all .3s !important;

                                            svg {
                                                path {
                                                    fill: #fff;
                                                }
                                            }
                                        }

                                        .pi-number-btn,
                                        .pinum-input {
                                            min-width: 70px;
                                            height: 30px;
                                            border: 1px solid #dae4ee;
                                            padding: 0 3px 0 5px;
                                            display: inline-block;
                                            border-radius: 4px;

                                            span {
                                                font-weight: 600;
                                                font-size: 12px;
                                                letter-spacing: 0;

                                                label {
                                                    margin: 0;
                                                    padding: 0 3px;
                                                    cursor: pointer;
                                                }
                                            }
                                        }

                                        .pi-number-btn:hover {
                                            background-color: #f5f5f5;
                                        }

                                        .pinum-input {
                                            width: 70px;
                                            margin-left: 10px;
                                            font-size: 13px;
                                        }

                                        .pinum-input:focus {
                                            border: 1px solid $primary-color;
                                            box-shadow: 0px 0px 0px 2px #DADAFF;
                                        }
                                    }
                                    li:first-child {
                                        button{
                                            margin-left: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .quick-filter {
                        button {
                            border-radius: 3px;

                            .qf-img {
                                margin: 0;
                            }
                        }

                        .qf-btn {
                            margin: 0;

                            .qf-img {
                                margin: 0;
                            }
                        }
                    }
                }

                // .imh-ret-sup-radio {
                //     // position: fixed;
                //     // right: 25px;
                //     // top: 96px;
                //     // z-index: 7;

                //     .ant-radio-group {
                //         width: fit-content;
                //         height: 30px;
                //         display: flex;
                //         align-items: center;
                //         background-color: #ebebf3;
                //         border-radius: 5px;
                //         padding: 0 3px;

                //         .ant-radio-button-wrapper {
                //             height: 24px;
                //             display: flex;
                //             align-items: center;
                //             justify-content: center;
                //             background-color: transparent;
                //             border-radius: 3px;
                //             font-size: 12px;
                //             font-weight: 600;
                //             color: #21314b;
                //             border: none;
                //             padding: 0 10px;
                //         }

                //         .ant-radio-button-wrapper-checked {
                //             background-color: #fff;
                //             border: none;
                //         }

                //         .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
                //             display: none;
                //         }

                //         .ant-radio-button-wrapper:not(:first-child)::before {
                //             display: none;
                //         }
                //     }
                // }

            }
        }

        .table-component-new-style{
            min-height: auto;
        }
    }
}

// Primary Filters
.primary-filters {
    .generic-form-body {
        padding: 15px 10px;
        overflow: inherit;

        .gfb-inner {
            .generic-input-box {
                width: 20%;
                margin: 0 15px 0 0;

                .list-component-section {
                    top: 35px;
                    z-index: 11;
                }
            }

            button {
                white-space: nowrap;
            }

            .generic-approve-btn {
                margin-right: 15px;
            }
        }
    }
}

// Performance Summary
.performance-summary {
    padding: 12px 12px 12px 27px;

    .ps-filter-tab {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;

        .generic-tabs {
            padding-left: 10px;

            .gt-list {
                color: #12203c;
                font-size: 12px;
                padding: 15px 0;
            }

            .activeTab {
                color: $primary-color;
                font-weight: 600;
                border-bottom: 2px solid $primary-color;
            }
        }

        .psf-right {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #DAE4EE;
            padding: 10px;

            span {
                i {
                    font-size: 12px;
                    color: #8496a9;
                    font-weight: 500;
                    white-space: nowrap;
                }
            }

            button {
                height: 30px;
                margin-left: 15px;
                white-space: nowrap;
                padding: 0 10px;
                border-radius: 4px;
                font-size: 12px;
                font-weight: 600;
            }

            .psfr-refresh-btn {
                width: 101px;
                color: $primary-color;
                border: 1px solid $primary-color;
            }
            .psfr-refresh-btn:hover{
                background-color: $primary-color;
                color: #fff;
            }

            .psfr-download-btn {
                display: flex;
                align-items: center;
                color: #12203c;
                border: 1px solid #12203c;

                svg {
                    margin-right: 5px;
                }
            }
            .psfr-download-btn:hover{
                background-color: #12203c;
                color: #fff;
                svg{
                    path{
                        fill: #fff;
                    }
                }
            }
        }
    }

    .ps-dsr-summary {
        background-color: #fff;

        .psd-table {
            padding: 0 10px 10px;

            h3 {
                font-size: 12px;
                font-weight: 700;
                color: #12203c;
                margin-bottom: 10px;
            }

            .vendor-gen-table {
                .manage-table {
                    .gen-main-table {
                        width: 100%;

                        thead {
                            tr {
                                th {
                                    background-color: #f8f8f8;

                                    label {
                                        color: #8496a9;
                                    }
                                }

                                .fix-action-btn {
                                    ul {
                                        li {
                                            svg {
                                                width: 18px;
                                                margin: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ps-pan-india {
        background-color: #fff;

        .primary-filters {
            .generic-form-body {
                .gfb-inner {
                    flex-wrap: nowrap;

                    .generic-input-box {
                        width: 16.66%;
                    }
                }
            }
        }
    }
}

.dsr-skeleton{
    width: 100%;
    height: calc(100vh - 315px);
    table{
        width: 100%;
        tbody{
            tr{
                td{
                    padding: 8px;
                    border-right: 1px solid #dee;
                    border-bottom: 1px solid #dee;
                    .generic-skeleton{
                        margin: 0;
                    }
                }
                td:nth-last-child(1){
                    border-right: none;
                }
            }
        }
    }
}
.ds-division-wise{
    height: calc(100vh - 275px);
    overflow: hidden;
}
.ysm-skeleton{
    height: calc(100vh - 280px);
    padding: 20px;
    .ars-graph-box{
        height: calc(100% - 27px);
        padding: 20px;
        overflow: hidden;
    }
}
.ot-table-loader{
    height: calc(100vh - 335px);
    overflow: hidden;
}

.psp-radio {
    padding: 0 10px;

    h1 {
        font-size: 11px;
        font-weight: 600;
        color: #0f172a;
    }

    .ant-radio-group {
        width: fit-content;
        height: 32px;
        display: flex;
        align-items: center;
        background-color: #ebebf3;
        border-radius: 5px;
        padding: 0 3px;

        .ant-radio-button-wrapper {
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border-radius: 3px;
            font-size: 12px;
            font-weight: 600;
            color: #21314b;
            border: none;
            padding: 0 10px;
        }

        .ant-radio-button-wrapper-checked {
            background-color: #fff;
            border: none;
        }

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
            display: none;
        }

        .ant-radio-button-wrapper:not(:first-child)::before {
            display: none;
        }
    }
}
.asm-ytd, .division-wise, .daily-sales-tracking{
    background-color: #fff;
    .primary-filters{
        .generic-form-body{
            .gfb-inner{
                flex-wrap: nowrap;
            }
        }
    }
    .vendor-gen-table {
        .manage-table {
            .gen-main-table {
                width: 100%;

                thead {
                    tr {
                        .fix-action-btn {
                            ul {
                                li {
                                    svg {
                                        width: 18px;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Pie Chart
.pie-chart-section {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border: solid 1px #eef0fe;
    width: 100%;
    min-height: 368px;
    background-color: #ffffff;

    .pcs-left {
        display: flex;
        flex-direction: column;
        padding: 20px;

        .spa-toggle-asn {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            background-color: #ebebf3;
            border-radius: 5px;
            margin-left: 15px;
            margin-right: 15px;
            margin-top: 15px;
            padding: 8px 5px;
            height: 34px;

            button {
                padding: 2px 25px;
                background-color: transparent;
                border-radius: 3px;
                font-size: 12px;
                font-weight: 600;
                color: #21314b;
                white-space: nowrap;
            }

            .spat-active-asn {
                background: #fff;
                color: #21314b;
            }
        }

        .pcsl-top {
            margin-bottom: 28px;

            h3 {
                font-size: 14px;
                font-weight: 600;
                color: #122121;
                margin-bottom: 0px;
            }

            p {
                font-size: 12px;
                font-weight: 500;
                color: #000000;
                margin: 0;
                opacity: 0.5;
            }
        }

        .pcsl-circle {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }

        .pcslc-v2 {
            flex-direction: column;
            align-items: flex-start;
        }

        .piecsi-count-section {
            display: flex;
            align-items: center;
            flex: 0 0 100%;
            border-top: solid 1px #eef0fe;
            padding: 20px 20px;

            .piecsics-col {
                margin-right: 30px;

                h3 {
                    font-size: 11px;
                    font-weight: 500;
                    color: #000000;
                    opacity: 0.5;
                    margin-bottom: 0px;
                }

                p {
                    font-size: 12px;
                    font-weight: 700;
                    color: #111d31;
                    margin-bottom: 0px;
                }
            }
        }
    }

    .pcsl-v2 {
        padding: 0;
        flex: 0 0 100%;
        border: none;

        .pcsl-top {
            padding: 4px 15px 0;
        }
    }

    .pcslc-bottom {
        display: flex;
        align-items: center;
        flex: 0 0 100%;
        flex-direction: row;
        margin-bottom: 13px;
        margin-left: 15px;

        .pcslc-col {
            position: relative;
            padding-left: 16px;
            margin-right: 30px;

            h3 {
                .generic-tooltip {
                    left: 0;
                }
            }

            h3:hover {
                .generic-tooltip {
                    visibility: visible;
                    opacity: 1;
                    left: 0;
                }
            }

            .pcslcc-dot {
                content: "";
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #824bf6;
                position: absolute;
                left: 0px;
                top: 2px;
            }

            .pcslccd-v2 {
                background-color: #ef6e8b;
            }

            .pcslccd-v1 {
                background-color: #6bf3e3;
            }

            .pcslccd-v3 {
                background-color: #ffaf4f;
            }

            h3 {
                font-size: 11px;
                font-weight: 500;
                color: #121212;
                opacity: 0.7;
                margin-bottom: 0px;
                position: relative;
            }
        }
    }

    .pcslc-bottom-asn {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 20px 20px 20px 15px;
    }

    .pcs-right {
        display: flex;
        // flex: 0 0 40%;
        flex-direction: column;
        padding: 20px 0;

        .asn-lifecycle-table {
            border-radius: 7px;
            border: solid 1px #eef0fe;
            border-collapse: separate;
            border-spacing: 0;

            thead {
                tr {
                    th {
                        border: solid 1px #eef0fe;
                        border-top: none;
                        border-left: none;
                        padding: 10px 16px;

                        label {
                            font-size: 12px;
                            font-weight: 600;
                            color: #67768e;
                            margin: 0;
                        }
                    }

                    th:last-child {
                        border-right: none;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        font-size: 12px;
                        font-weight: 500;
                        color: #121212;
                        border: solid 1px #eef0fe;
                        border-top: none;
                        border-bottom: none;
                        border-left: none;
                        padding: 14px 16px;

                        .pcsr-row {
                            display: flex;
                            margin: 0;

                            h3 {
                                white-space: nowrap;
                                font-weight: 600;
                            }
                        }
                    }

                    td:last-child {
                        border-right: none;
                    }
                }
            }
        }

        .pcsr-row {
            margin-bottom: 20px;
            position: relative;
            padding-left: 18px;

            h3 {
                font-size: 12px;
                font-weight: 500;
                color: #566464;
                margin-bottom: 0px;
                position: relative;

                .generic-tooltip {
                    left: 12%;
                }
            }

            h3:hover {
                .generic-tooltip {
                    visibility: visible;
                    opacity: 1;
                }
            }

            p {
                font-size: 13px;
                font-weight: 600;
                color: #111d31;
                opacity: 0.7;
                margin: 0;
            }

            .pcsr-dot {
                content: "";
                display: block;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #824bf6;
                position: absolute;
                left: 0px;
                top: 3px;
            }

            .asn-aprv-lrcreat {
                background-color: #ef6e8b;
            }

            .intran-gate-entry {
                background-color: #ffaf4f;
            }

            .gate-entry-grc {
                background-color: #6bf3e3;
            }
        }
    }

    .pcsr-v2 {
        padding-right: 20px;
        flex: 0 0 40%;
    }

    .piecs-inner {
        width: 100%;
        padding: 20px;

        .recharts-default-legend {
            margin-bottom: 20px !important;
        }

        .piecsi-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 30px;

            .piecsit-left {
                h3 {
                    font-size: 14px;
                    font-weight: 600;
                    color: #122121;
                    margin-bottom: 0px;
                }

                p {
                    font-size: 12px;
                    font-weight: 500;
                    color: #000000;
                    margin: 0;
                    opacity: 0.5;
                }

            }

            .piecsit-right {
                position: relative;
                display: flex;
                align-items: center;

                .ant-radio-group {
                    width: fit-content;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    background-color: #ebebf3;
                    border-radius: 5px;
                    padding: 0 3px;

                    .ant-radio-button-wrapper {
                        height: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: transparent;
                        border-radius: 3px;
                        font-size: 12px;
                        font-weight: 600;
                        color: #21314b;
                        border: none;
                        padding: 0 10px;
                        white-space: nowrap;
                    }

                    .ant-radio-button-wrapper-checked {
                        background-color: #fff;
                        border: none;
                    }

                    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
                        display: none;
                    }

                    .ant-radio-button-wrapper:not(:first-child)::before {
                        display: none;
                    }
                }

                .ant-space-compact {
                    margin-left: 15px;
                    .ant-btn:hover{
                        color: $primary-color;
                        border-color: $primary-color;
                        span{
                            color: $primary-color;
                        }
                        .ant-btn-icon{
                            .anticon {
                                svg{
                                    path{
                                        fill: $primary-color;
                                    }
                                }
                            }
                        }
                    }
                    .ant-btn:focus{
                        color: $primary-color;
                        border-color: $primary-color;
                        span{
                            color: $primary-color;
                        }
                    }
                }
            }

        }

        .piecsi-bottom {
            width: 100%;
        }

        .recharts-surface{
            path{
                fill: $primary-color
            }
        }
        .legend-item-0{

            .recharts-legend-item-text{
                color: $primary-color !important;
            }
        }

        .vpad-chart {
            .recharts-cartesian-axis-tick {
                width: 100px;
            }

            .recharts-cartesian-axis-tick-value {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                white-space: nowrap;
            }
        }
    }

    .piecs-inner-header {
        padding: 15px 15px 0 15px;
    }

    .graph-table-section {
        display: flex;
        justify-content: space-between;
        padding-right: 20px;
        width: 100%;
    }

    .pie-main-chart {
        width: 100%;
        padding: 20px;
    }
}

.ars-data-table {
    .manage-table {
        .gen-main-table {
            thead {
                tr {

                    th,
                    .fix-action-btn {
                        background-color: #f8f8f8;

                        label {
                            color: #8496a9;
                        }

                        .rab-refresh {
                            justify-content: center;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        border: solid 1px #dae4ee;

                        label {
                            color: #71819b;
                        }

                        .vgtvf-col {
                            .rprtmt-arrow {
                                position: relative;
                                top: auto;
                                left: auto;
                                margin-right: 0;
                                margin-left: 10px;
                                display: inline-block;
                            }
                        }

                        .bold {
                            color: #21314b;
                        }
                    }

                    .fix-action-btn {
                        .table-item-list {
                            .til-inner {
                                display: inline-block;
                                padding: 7px;
                                position: relative;
                                border-radius: 50%;
                            }

                            .til-add-btn:hover {
                                background-color: #5D5FEF1c;
                                transition: 0.3s all;

                                svg {
                                    g {
                                        g:first-child {
                                            fill: $primary-color;
                                            stroke: $primary-color;
                                        }

                                        g {
                                            g {
                                                fill: #ffffff !important;
                                                stroke: #ffffff !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .bl-green {
                        border-left: 3px solid #00ceb5 !important;
                    }

                    .bl-red {
                        border-left: 3px solid $danger-color !important;
                    }

                    .bl-last {
                        .bold {
                            color: #21314b;
                        }
                    }

                    .adt-text-b {
                        label {
                            color: #21314b;
                            font-weight: 600;
                        }
                    }

                    .vgtvf-first-col {
                        background-color: #ffffff;

                        label {
                            color: #21314b;
                            font-weight: 700;
                        }
                    }
                }

                .vgt-tr-bg {
                    background-color: #f7f5fb;

                    .vgtvf-first-col,
                    .fix-action-btn {
                        background-color: #f7f5fb;
                    }
                }
            }
        }
    }
}

// Graphs
.store-analytics-table-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;

    .sath-left {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        h3 {
            font-size: 12px;
            font-weight: 700;
            color: #12203c;
            margin: 0;
        }

        .nph-switch-btn {
            margin: 0;
            margin-left: 20px;

            .tg-switch {
                width: 50px;
                height: 22px;

                .tg-slider {
                    background-color: #fff;
                    box-shadow: 0 3px 6px 0 #d7e1f3;
                }

                .tg-slider:before {
                    height: 14px;
                    width: 14px;
                    left: 4px;
                    bottom: 4px;
                    background-color: #4cd3d7;
                }

                input:checked+.tg-slider {
                    background-color: #ffffff;
                }

                input:checked+.tg-slider:before {
                    background-color: #fcdb3a;
                    transform: translateX(28px);
                }
            }

            .nph-wbtext {
                position: absolute;
                top: 4px;
                left: 60px;
                margin: 0;
                font-size: 12px;
                font-weight: 600;
                color: #12203c;
            }
        }
    }

    .sath-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .sathr-sales-through {
            position: relative;

            button {
                border-radius: 46px;
                box-shadow: 0 3px 6px 0 #d7e1f3;
                background-color: #fff;
                font-size: 12px;
                font-weight: 600;
                padding: 6px 33px 6px 13px;
                height: 32px;
                color: #21314b;
                text-align: left;
                background-image: url('../../../assets/icons/downArrowNew.svg');
                background-repeat: no-repeat;
                background-position: 90%;
                background-size: 11px;
                margin-left: 20px;
                min-width: 95px;
            }

            .sathrst-dropdown {
                display: block;
                position: absolute;
                top: 41px;
                right: 0;
                width: 130px;
                padding: 8px 0;
                margin: 0;
                z-index: 99;
                border-radius: 6px;
                box-shadow: 0 2px 6px 0 rgba(75, 75, 75, .16);
                background-color: #fff;
                max-height: 270px;
                overflow: auto;

                .sathrstd-inner {
                    margin: 0;
                    padding: 0;

                    li {
                        display: block;
                        padding: 5px 15px;
                        cursor: pointer;
                        font-size: 12px;
                        font-weight: 500;
                        color: #4a5568;
                    }

                    li:hover {
                        font-weight: 600;
                        color: #21314b;
                        background-color: #e5edf8;
                    }

                    .sahrsdd-head {
                        font-size: 12px;
                        font-weight: 500;
                        color: #969fad;
                    }
                }
            }
        }
    }
}

.store-analytics-table-body {
    display: block;
    background: #fff;
    border-radius: 9px;
    padding: 10px 25px;
    margin-bottom: 30px;

    .satb-head {
        display: block;
        text-align: right;
        margin-bottom: 10px;

        button {
            position: relative;
            padding: 5px 3px;
            background-color: #ffffff;
        }
    }

    .satb-table {
        max-height: 340px;
        overflow-x: auto;
        overflow-y: auto;
        position: relative;
    }

    table {
        thead {
            tr {
                th {
                    border-top: 1px solid #dce6f0 !important;
                    border-bottom: 1px solid #dce6f0;
                    vertical-align: middle;
                    position: sticky;
                    top: 0;
                    background: #fff;
                    z-index: 5;

                    label {
                        font-size: 12px !important;
                        font-weight: 600;
                        margin: 0;
                    }
                }

                th:first-child {
                    padding-left: 0px;
                }
            }
        }

        tbody {
            tr {
                td {
                    border: none;
                    vertical-align: middle;
                    position: relative;

                    label {
                        font-size: 11px;
                        font-weight: 500;
                        margin: 0;
                        color: #12203c;
                        max-width: 100px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .table-tooltip {
                        z-index: 9;
                        transform: translate(-50%, -90%);

                        label {
                            max-width: none;
                        }
                    }
                }

                td:first-child {
                    padding-left: 0px;
                }
            }
        }
    }

    .table-page-no {
        display: flex;
        align-items: center;

        span.bold {
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 50px;
        }
    }

    .new-gen-pagination .ngp-right .nt-btn .pagination-inner .pagination-item {
        display: flex;
        align-items: center;

        li button span {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 75px;
        }
    }
}

.ars-graph-box {
    border-radius: 6px;
    box-shadow: 0 0 16px 1px rgba(164, 164, 164, 0.25);
    padding: 10px;
}

.recharts-text{
    tspan{
        font-size: 10px;
    }
}
.recharts-legend-item-text {
    font-size: 10px;
}
.recharts-legend-wrapper{
    ul{
        li{
            height: 15px;
        }
    }
}

.vgtbg-fcfdfe{
    .manage-table{
        .gen-main-table{
            .box-shadow-none {
                box-shadow: none;
            }
            .vgt-header-top {
                .vgtht-none {
                    border-top: solid 3px #fff;
                }
                .vgtht-qty {
                    border-top: solid 3px #00ced6 !important;
                    background-color: #f9fafc !important;
                    text-align: center;
                    label {
                        color: #00ced6 !important;
                    }
                }
                .vgtht-value {
                    border-top: solid 3px #2681ff !important;
                    background-color: #f9fafc !important;
                    text-align: center;
                    label {
                        color: #2681ff !important;
                    }
                }
                .vgtht-last {
                    border-top: solid 3px #ffb103 !important;
                    background-color: #f9fafc !important;
                    text-align: center;
                    label {
                        color: #ffb103 !important;
                    }
                }
                .vgtht-ytd {
                    border-top: solid 3px #03b675 !important;
                    background-color: #f9fafc !important;
                    text-align: center;
                    label {
                        color: #03b675 !important;
                    }
                }
            }
            thead {
                tr{
                    th{
                        text-align: left;
                    }
                    .vgtbt-first {
                        border-top: solid 3px #ff6347 !important;
                    }
                    .vgtbt-second {
                        border-top: solid 3px #5677b1 !important;
                    }
                    .vgtbt-third {
                        border-top: solid 3px #ff8f66 !important;
                    }
                    .vgtbt-forth {
                        border-top: solid 3px #36a5e3 !important;
                    }
                    .vgtbt-fifth {
                        border-top: solid 3px #9fc9ff !important;
                    }
                }
            }
            tbody{
                tr{
                    background-color: #fcfdfe;
                    .fix-action-btn {
                        .table-item-list {
                            justify-content: flex-end !important;
                        }
                    }
                    .vgtvf-first-col {
                        background-color: #e8ecf4;
                        width: 120px;
                        label {
                            font-weight: 700;
                            max-width: 190px;
                        }
                        .table-tooltip {
                            width: auto;
                            max-width: 200px;
                            label {
                                font-weight: 500;
                            }
                        }
                    }
                    .vgtvf-col {
                        display: block;
                        .vgtvfc-per {
                            color: #94a5c6;
                            font-size: 11px;
                            font-weight: 600;
                            display: block;
                            text-align: right;
                        }
                    }
                    .vgtvf-first-col:hover{
                        .table-tooltip{
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
                td.vgtvf-total {
                    background-color: #eeeeee;
                    border: none;
                    label {
                        font-weight: 700;
                    }
                }
                .vgtvf-footer {
                    box-shadow: 2px 0 6px 0 hsla(0,0%,55%,.16);
                    background-color: #ffffff;
                    td {
                        border: none;
                        height: 42px;
                    }
                    .vgtvf-first-col {
                        background-color: #ffffff;
                    }
                    .vgtvf-col {
                        .vgtvf-num {
                            font-weight: 700;
                        }
                        .vgtvfc-per {
                            color: #627da8;
                        }
                    }
                }
            }
            .til-grand-total{
                tr:nth-last-child(1){
                    td{
                        background-color: #f2f5f8;
                        label{
                            font-size: 12px !important;
                            font-weight: 600 !important;
                        }
                    }
                }
            }
        }
    }
}

// Sticky Six Col
.fisrt-six-sticky-col {
    .manage-table{
        .gen-main-table{
            thead{
                tr{
                    th:nth-child(1), th:nth-child(2), th:nth-child(3), th:nth-child(4), th:nth-child(5), th:nth-child(6), th:nth-child(7)  {
                        left: 0;
                        position: sticky;
                        z-index: 5;
                        background-color: #ffffff !important;
                    }
                    th:nth-child(1){
                        min-width: 40px;
                    }
                    th:nth-child(2){
                        left: 40px;
                    }
                    th:nth-child(3){
                        left: 139px;
                    }
                    th:nth-child(4){
                        left: 238px;
                    }
                    th:nth-child(5){
                        left: 327px;
                    }
                    th:nth-child(6){
                        left: 415px;
                    }
                    th:nth-child(7){
                        left: 466px;
                    }
                }
            }
            tbody {
                tr {
                    td:nth-child(1), td:nth-child(2), td:nth-child(3), td:nth-child(4), td:nth-child(5), td:nth-child(6), td:nth-child(7) {
                        position:sticky;
                        left: 0px;
                        z-index: 5;
                        background-color: #f3f6fb;
                    }
                    td:nth-child(1){
                        min-width: 40px;
                        left: 0;
                    }
                    td:nth-child(2){
                        left: 40px;
                        min-width: 100px;
                    }
                    td:nth-child(3){
                        left: 139px;
                        min-width: 100px;
                    }
                    td:nth-child(4){
                        left: 238px;
                        min-width: 90px;
                    }
                    td:nth-child(5){
                        left: 327px;
                        min-width: 55px;
                    }
                    td:nth-child(6){
                        left: 415px;
                    }
                    td:nth-child(7){
                        left: 466px;
                    }
                }
            }
        }
    }
}

// Sticky table First Seven Column
.sticky-seven-col {
    .manage-table{
        overflow: auto;
        max-height: calc(100vh - 360px);
        .gen-main-table{
            thead{
                tr{
                    th:nth-child(1), th:nth-child(2), th:nth-child(3), th:nth-child(4) {
                        left: 0;
                        position: sticky;
                        z-index: 9;
                        background-color: #ffffff;
                    }
                    th:nth-child(1){
                        min-width: 40px;
                        z-index: 10;
                    }
                    th:nth-child(2){
                        left: 39px;
                        min-width: 105px;
                    }
                    th:nth-child(3){
                        left: 144px;
                        min-width: 100px;
                    }
                    th:nth-child(4){
                        left: 244px;
                    }
                    th {
                        position: sticky;
                        top: 37px;
                        background-color: #fff;
                        z-index: 7;
                    }
                }
                .vgt-header-top {
                    th {
                        top: -3px;
                    }
                }
            }
            tbody {
                tr {
                    td:nth-child(1), td:nth-child(2), td:nth-child(3), td:nth-child(4) {
                        position:sticky;
                        left: 0px;
                        z-index: 5;
                        background-color: #fff;
                    }
                    td {
                        background-color: #fff;
                    }
                    td:nth-child(1){
                        min-width: 40px;
                        left: 0;
                    }
                    td:nth-child(2){
                        left: 39px;
                        min-width: 105px;
                    }
                    td:nth-child(3){
                        left: 144px;
                        min-width: 100px;
                    }
                    td:nth-child(4){
                        left: 244px;
                    }
                    td:nth-child(9), td:nth-child(10), td:nth-child(11), td:nth-child(12), td:nth-child(13), td:nth-child(14){
                        background-color: #00ced614;
                    }
                    td:nth-child(15), td:nth-child(16), td:nth-child(17), td:nth-child(18), td:nth-child(19), td:nth-child(20), td:nth-child(21), td:nth-child(22), td:nth-child(23), td:nth-child(24), td:nth-child(25), td:nth-child(26), td:nth-child(27) {
                        background-color: #2681ff33;
                    }
                    td:nth-child(28), td:nth-child(29), td:nth-child(30), td:nth-child(31), td:nth-child(32), td:nth-child(33), td:nth-child(34), td:nth-child(35), td:nth-child(36), td:nth-child(37), td:nth-child(38), td:nth-child(39), td:nth-child(40) {
                        background-color: #ffb10312;
                    }
                    td:nth-child(41), td:nth-child(42), td:nth-child(43), td:nth-child(44), td:nth-child(45), td:nth-child(46), td:nth-child(47), td:nth-child(48), td:nth-child(49), td:nth-child(50), td:nth-child(51), td:nth-child(52), td:nth-child(53) {
                        background-color: #03b6751a;
                    }
                }
                .vgt-tr-bg {
                    td:nth-child(1), td:nth-child(2), td:nth-child(3), td:nth-child(4), td {
                        background-color: #eaf3ff !important;
                        label {
                            font-weight: 600;
                        }
                    }
                }
                .vgttable-footer {
                    background-color: #ecedf7;
                    td:nth-child(1), td:nth-child(2), td:nth-child(3), td:nth-child(4), td {
                        background-color: #ecedf7 !important;
                        label {
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
}

.gv-analysis-redemption{
    .table-component-new-style{
        .tableBody-border{
            .imh-update{
                .invoice-manage-head{
                    padding: 5px 15px;
                    background-color: #fff;
                    .imh-right{
                        position: static;
                    }
                }
            }
            .invoice-manage-head{
                padding: 0;
                background-color: transparent;
                .imh-right{
                    position: absolute;
                    top: -168px;
                    right: 25px;
                }
            }
        }
    }
}