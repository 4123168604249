@import './variables.scss';

// -------------------------------Style Modal Header Section-------------------------- //
.modal-header-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 15px;

    .mhs-left {
        h4 {
            margin: 0;
            color: #0f172a;
            font-weight: 700;
            font-size: 16px;
        }

        p {
            font-size: 12px;
            font-weight: 500;
            color: #67768e;
            margin: 0;
        }
    }

    .mhs-right {
        display: flex;
        align-items: center;

        .mhs-close-sortcode {
            font-size: 12px;
            font-weight: 500;
            color: #969fad;
            position: relative;
        }

        .mhs-line-saprator {
            display: inline-block;
            width: 1px;
            height: 10px;
            margin: 0 5px 0 9px;
            background-color: #969fad;
        }
    }
}

.modal-search-area {
    display: flex;
    justify-content: space-between;
    padding: 0px 15px;

    .msa-left {
        display: flex;
        align-items: center;

        .msa-input-outer {
            display: flex;
            align-items: center;
            border: solid 1px #dce6f0;
            border-radius: 4px;
            height: 32px;
            padding: 0 6px;
            font-size: 12px;
            font-weight: 500;
            color: #0f172a;

            input {
                width: 100%;
            }

            .msa-search-icon {
                margin-right: 6px;

                svg {
                    width: 14px;
                }
            }

            input::placeholder {
                font-size: 12px;
            }

            .msaio-clear {
                border-radius: 50%;
                background-color: #f6f6f6;
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 0 0 20px;

                svg {
                    width: 14px;
                    height: 14px;

                    path {
                        fill: #b6baca;
                    }
                }
            }
        }
    }

    .msa-right {
        display: flex;
        flex-direction: column;

        label {
            font-size: 12px;
            font-weight: 500;
            color: #90a5c7;
            margin-bottom: 6px;
        }

        select {
            border: 1px solid #dce6f0;
            font-weight: 400;
            color: #21314b;
            border-radius: 4px;
            height: 32px;
            width: 180px;
            padding: 3px 4px;
        }
    }
}

.modal-mapped-data {
    height: calc(100% - 189px);
    display: flex;
    padding: 0 15px;

    .lcs-inner {
        border: 1px solid #e6ebef;

        .list-component-table-view {
            .lcv-table-head {
                .lcvth-row {
                    li {
                        background: #f8f8f8 !important;

                        label {
                            font-weight: 500;
                        }
                    }
                }
            }

            .modal-data-list-render {
                li:hover {
                    background-color: #f5f5f5;
                }
            }
        }
    }

    .lcs-inner:nth-child(1) {
        margin-right: 10px;
    }
}

.list-modal-total-records {
    display: flex;
    padding: 10px 15px;

    h3 {
        font-size: 12px;
        font-weight: #90a5c7;
        font-weight: 400;
        margin: 0;
    }

    span {
        font-size: 13px;
        font-weight: 500;
        color: #000;
    }
}

.list-component-section {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .lcs-inner {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: auto;

        .list-component-table-view {
            display: table;
            width: 100%;

            .lcv-table-head {
                display: table-header-group;

                .lcvth-row {
                    display: table-row;

                    li {
                        display: table-cell;
                        padding: 8px;
                        vertical-align: middle;
                        position: sticky;
                        top: 0;
                        background: #fff;
                        z-index: 5;
                        
                        .mdlr-select-all{
                            cursor: pointer;
                        }

                        label {
                            font-size: 11px;
                            font-weight: 600;
                            color: #67768e;
                            margin: 0;
                            white-space: nowrap;
                        }
                    }

                    li:first-child {
                        padding-left: 15px;
                    }

                    li:last-child {
                        padding-right: 15px;
                    }
                }
            }

            .lctv-table-loader {
                display: table-row-group;

                .lctv-loader {
                    display: table-row;

                    .lctvl-col {
                        display: table-cell;
                        padding: 8px;
                    }

                    .lctvl-col:first-child {
                        padding-left: 15px;
                    }

                    .lctvl-col:last-child {
                        padding-right: 15px;
                    }
                }
            }

            .modal-data-list-render {
                display: table-row-group;
                .no-data-found-screen {
                    display: table-row;
                }

                .mdlr-li {
                    display: table-row;

                    .mdlr-col {
                        display: table-cell;
                        padding: 8px;
                        border-top: 1px solid #e6ebef;
                        position: relative;
                        vertical-align: middle;

                        .mdlr-multiple-row {
                            font-size: 11px;
                            font-weight: 600;
                            color: #21314b;
                            margin: 0;
                            max-width: 260px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            display: block;
                        }

                        .fileName-field {
                            display: flex;

                            svg {
                                margin-right: 10px;
                            }
                        }

                        .edd-btn {
                            background-color: #fff;
                            padding: 4px;
                            position: relative;
                        }

                        .edd-btn:hover {
                            .generic-tooltip {
                                visibility: visible;
                                opacity: 1;
                            }
                        }
                    }

                    .mdlr-col:first-child {
                        padding-left: 15px;
                    }

                    .mdlr-col:last-child {
                        padding-right: 15px;
                    }
                }
            }
        }

        .lcgv-data {
            display: grid;
            .lcv-table-head {
                display: flex;
                width: 100%;
                .lcvth-row {
                    width: 100%;
                }
            }
            .modal-data-list-render {
                display: flex;
                height: auto;
            }
        }

        .list-component-normal-view {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}

.export-data-dropdown {
    .edd-status-col {
        display: flex;
        align-items: center;

        button {
            margin-left: 8px;
        }
        .vso-status {
            position: relative;
            .generic-tooltip {
                left: auto;
                right: 23px;
                transform: none;
            }
            .generic-tooltip::after {
                left: auto;
                right: 10px;
            }
        }
        .vso-status:hover {
            .generic-tooltip {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .lcs-inner {
        height: calc(100% - 54px);
    }

    .eddsc-refresh {
        display: flex;
        align-items: center;

        button {
            margin-left: 10px;

            svg {
                width: 18px;
            }
        }
    }

    .edd-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 10px;
    }
}

.vso-status,
.vsos-hold {
    padding: 3px 5px;
    border-radius: 4px;
    background: $danger-color;
    color: #fff;
    min-width: 84px;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    margin: 0;
    white-space: nowrap;
}

.vsos-hold {
    background: #fe9d0b;
}

.vsos-approved {
    background: #00ceb5;
}

.vsos-error {
    background: $danger-color;
}

.modal-data-list-render {
    display: block;
    width: 100%;
    overflow: auto;
    height: calc(100% - 140px);

    .mdlr-li {
        padding: 8px 15px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .mdlr-col {
            display: flex;
            flex-direction: column;
        }

        .list-col-1 {
            flex: 0 0 100%;
        }

        .list-col-2 {
            flex: 0 0 50%;
        }

        .list-col-3 {
            flex: 0 0 33.33%;
        }

        .list-col-4 {
            flex: 0 0 25%;
        }

        .list-col-5 {
            flex: 0 0 20%;
        }

        .global-search-custom-col {
            display: flex;
            font-size: 13px;
            color: #878d96;
            font-weight: 600;
            margin: 0;

            svg {
                path {
                    fill: $primary-color;
                }
            }
        }

        .mdlr-multiple-row {
            font-size: 11px;
            color: #71819b;
            font-weight: 500;
        }
    }

    .hover {
        // background-color: #f8fafe;
        background-color: $dropdown-item-hover;
    }

    .mdlr-li:hover,
    .hover {
        .global-search-custom-col {
            svg {
                path {
                    fill: $primary-color;
                }
            }
        }
    }
}

.generic-notification-modal {
    .generic-tabs {
        padding: 0 15px;

        .gt-list {
            padding: 0px 0px 10px;
            margin-right: 15px;
        }
    }

    .modal-search-area {
        padding: 10px 15px;
        border-bottom: 1px solid #dae4ee;

        .msa-left {
            width: 100%;

            .msa-input-outer {
                width: 100%;

                input {
                    width: 100%;
                }
            }
        }
    }

    .gnm-filter {
        width: auto;
        height: 32px;
        border-radius: 4px;
        border: 1px solid #dae4ee;
        background: #fff;
        display: flex;
        align-items: center;
        padding: 5px;
        margin-left: 12px;

        label {
            color: #8496a9;
            font-size: 13px;
            font-weight: 400;
            margin: 0;
            white-space: nowrap;
        }

        select {
            color: #0f172a;
            font-size: 13px;
            font-weight: 600;
            border: none;
            padding-left: 5px;
        }

        select:focus {
            border: none !important;
            box-shadow: none;
        }
    }

    .gib-row {
        padding: 10px 15px;
    }

    .gnm-filter:hover {
        border: 1px solid $primary-color;
        box-shadow: 0px 0px 0px 2px #dadaff;
    }

    .mdlr-li {
        border-bottom: 1px solid #dae4ee;
    }

    .gen-action-btn:focus-visible svg path,
    .gen-action-btn:hover svg path {
        stroke: #fff;
    }

    .mdlr-li:hover {
        .log-details {
            .ld-time {
                .ldt-copy-icon {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }

    .modal-data-list-render {
        height: calc(100% - 93px);
    }

    .generic-input-box {
        .gib-inner {
            position: relative;
            height: auto;
            padding: 10px 15px;
            border: none;

            .gen-input-clear {
                position: absolute;
                top: 17px;
                right: 22px;
                background-color: #f6f6f6;
                border-radius: 50%;
                padding: 3px;
                cursor: pointer;

                svg {
                    width: 14px;
                    height: 14px;

                    path {
                        fill: #b6baca;
                    }
                }
            }
        }
    }
}

.notification-expand {
    .modal-data-list-render {
        height: calc(100% - 193px);
    }
}

.notificatio-list-component {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .status-icon {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex: 0 0 32px;

        span {
            color: #fff;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
        }

        .si-sub-icon {
            position: absolute;
            top: 17px;
            right: -5px;
            padding: 4px;
            width: 18px;
            height: 18px;
            border-radius: 50%;
        }
    }

    .si-plus {
        background-color: #2969ff;

        .si-sub-icon {
            background-image: url("../../assets/images/Ellipse\ 596\ \(bg-blue\).png");
        }
    }

    .si-success {
        background-color: #03b675;

        .si-sub-icon {
            background-image: url("../../assets/images/Ellipse\ 596\ bg-green.png");
            padding: 3px 3px 4px;
        }
    }

    .si-failed {
        background-color: $danger-color;

        .si-sub-icon {
            background-image: url("../../assets/images/Ellipse\ 596\ bg-red.png");
        }
    }

    .si-reqc {
        background-color: #f28c67;

        .si-sub-icon {
            background-image: url("../../assets/images/Ellipse\ 596\ bg-red.png");
        }
    }

    .si-cmnt {
        background-color: #50617d;

        .si-sub-icon {
            background-image: url("../../assets/images/Ellipse\ 596\ bg-grey.png");
        }
    }

    .log-details {
        padding-left: 15px;

        label {
            color: #67656d;
            font-size: 13px;
            font-weight: 400;
            margin: 0;
            word-break: break-all;

            b {
                color: #0f172a;
                font-weight: 600;
                padding-right: 4px;
            }
        }

        .ld-time {
            display: flex;
            align-items: center;

            .ldt-date-time {
                color: #808a94;
                font-size: 12px;
                font-weight: 400;
            }

            .ldt-separator {
                width: 4px;
                height: 4px;
                background-color: #53627a;
                border-radius: 50%;
                margin: 0px 5px;
            }

            .ldt-poNo {
                color: #515a62;
                font-size: 11px;
                font-weight: 600;
                margin: 0;
                margin-right: 5px;
            }

            .ldt-copy-icon {
                position: relative;
                visibility: hidden;
                opacity: 0;

                .generic-tooltip {
                    top: -35px;
                    left: -19px;
                    visibility: visible;
                    opacity: 1;
                }

                .generic-tooltip::after {
                    left: 56%;
                }
            }
        }
    }

    .nndb-active-dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #2969ff;
    }
}

.generic-input-box {
    width: 100%;
    position: relative;
    margin-bottom: 10px;

    .gib-del-btn{
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px !important;
    }
    .gib-del-btn:hover{
        background-color: rgba(235, 0, 8, 0.1490196078);
        transition: 0.3s all;
        svg{
            path{
                fill: #eb0008;
            }
        }
    }
    .gib-del-btn[disabled]:hover{
        background-color: transparent;
        svg{
            path{
                fill: #707494;
            }
        }
    }

    .gib-label-heading {
        color: #67768e;
        font-weight: 600;
        font-size: 11px;
        width: 100%;

        .mandatory {
            position: absolute;
            top: -2px;
        }
    }

    .gib-inner {
        width: 100%;
        height: 32px;
        border-radius: 4px;
        border: 1px solid #dae4ee;
        display: flex;
        align-items: center;
        padding: 0 5px;
        position: relative;
        background-color: #fff;

        .gib-search {
            margin-right: 5px;
        }

        .gibp-password-padding{
            padding: 0 40px;
        }

        .gib-search:hover {
            svg {
                path {
                    fill: $primary-color;
                }
            }
        }

        input,
        textarea {
            width: 100%;
            height: 100%;
            padding: 0;
            background-color: #fff;
            border: none;
            padding-right: 12px;
            font-size: 12px;
            font-weight: 600;
            color: #23235f;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .gen-input-clear {
            background-color: #f6f6f6;
            border-radius: 50%;
            padding: 3px;
            cursor: pointer;
            margin-left: 3px;

            svg {
                width: 12px;
                height: 12px;

                path {
                    fill: #b6baca;
                }
            }
        }

        .gen-input-clear:hover {
            background-color: $primary-color;

            svg {
                path {
                    fill: #fff;
                }
            }
        }

        .custome-dropdown-input {
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
        .custome-dropdown-input:focus-visible {
            border: none;
            outline: none;
        }
    }

    .gib-inner:hover {
        .generic-tooltip {
            visibility: visible;
            opacity: 1;
        }
    }

    .gib-textarea {
        textarea {
            padding-top: 5px;
        }
    }

    .gib-mobileNo {
        .react-tel-input {
            font-family: "Poppins", sans-serif;
            input {
                padding-left: 45px;
            }
            .country-list {
                overflow-x: hidden;
                .search{
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-right: 1px;
                    .search-emoji {
                        position: absolute;
                        left: 19px;
                        top: 14px;
                        visibility: hidden;
                    }
                    .search-emoji::before {
                        content: '⌕';
                        position: absolute;
                        left: 3px;
                        top: -11px;
                        visibility: visible;
                        font-size: 1.5rem;
                        transform: rotateY(180deg);
                    }
                    .search-box{
                        font-size: 12px;
                        margin-left: 0;
                        height: 30px;
                        padding-left: 35px;
                    }
                }
                .country *{
                    margin-right: 10px;
                }
                .country, .no-entries-message{
                    font-size: 12px;
                    padding-left: 14px;
                }
                .country:hover, .highlight{
                    background-color: $primary-w-less-opacity;
                }
            }
        } 
        
    }

    .list-component-section {
        position: absolute;
        background: #fff;
        box-shadow: 0 2.71233px 18.99px #a0a0a08f;
        border-radius: 6px;
        top: 67px;
        left: 0;
        width: auto;
        min-width: 100%;
        min-height: 70px;
        height: auto;
        max-height: 300px;
        z-index: 2;

        .modal-header-section {
            .mhs-left {
                h4 {
                    font-weight: 600;
                    font-size: 12px;
                }
            }
        }

        .modal-search-area {
            flex-direction: column;

            .msa-left {
                width: 100%;

                .msa-input-outer {
                    width: 100%;
                    height: 30px;
                    border-radius: 3px;
                }

                .focusOn {
                    box-shadow: none;
                }
            }

            .msa-right {
                margin-top: 5px;

                select {
                    width: 100%;
                    font-size: 12px;
                    height: 30px;
                }

                label {
                    font-weight: 400;
                    font-size: 11px;
                    margin-bottom: 4px;
                }
            }
        }

        .lcs-inner {
            // height: calc(100% - 85px);
            max-height: 215px;

            .list-component-table-view {
                .lcv-table-head {
                    .lcvth-row {
                        li {
                            .mdlr-select-all {
                                display: flex;
                                align-items: center;
                                cursor: pointer;

                                svg {
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }

                .modal-data-list-render {
                    .mdlr-li {
                        .mdlr-col {
                            padding: 5px;
                        }

                        .mdlr-col:first-child {
                            padding-left: 15px;
                        }

                        .mdlr-col:last-child {
                            padding-right: 15px;
                        }
                    }
                }
            }
        }

        .generic-form-footer {
            padding: 7px 15px;

            .gff-right {
                button {
                    font-size: 11px;
                    height: 26px;
                    padding: 2px 15px !important;
                    border-radius: 40px;
                }
            }
        }

        .no-data-found-screen {
            .ndfs-inner {
                svg {
                    width: 120px;
                    height: 120px;
                }

                h3 {
                    font-size: 14px;
                }
            }
        }
    }

    .gib-date,
    .gib-range,
    .gib-time {
        padding: 0;
        // border-radius: 0;
        position: relative;

        .ant-picker {
            height: 30px;
            border: none !important;
            cursor: pointer;
            .ant-picker-input{
                input{
                    cursor: pointer;
                }
            }
        }

        .gen-input-clear {
            position: absolute;
            right: 7px;
        }
    }

    .gib-range{
        .gen-input-clear{
            visibility: hidden;
            opacity: 0;
            right: 5px;
        }
    }
    .gib-range:hover{
        .gen-input-clear{
            visibility: visible;
            opacity: 1;
        }
    }

    .gen-input-disabled {
        background-color: #f1f1f1;
        border: none;
        cursor: not-allowed;

        input {
            background-color: #f1f1f1;
        }

        .gib-upload {
            cursor: not-allowed !important;
        }

        textarea{
            background-color: #f1f1f1;
        }
    }

    .gib-sliderInput {
        background-color: #f2f2f2;
        border: none;
        width: fit-content;

        button {
            color: #67768e;
            font-size: 12px;
            font-weight: 500;
            background-color: #f2f2f2;
            padding: 3px 10px;
            border-radius: 5px;
        }

        .asmbmt-active {
            background-color: #fff;
            opacity: 1;
        }

        .gen-input-clear {
            display: none;
        }
    }

    .gib-radioInput {
        border: none;
        padding: 5px 0;

        .gibri-btn {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 500;
            color: #111d31;

            svg {
                margin-right: 5px;
            }
        }
    }

    .gib-upload {
        // background-color: #f8f8f8;
        border: none;
        height: auto;
        // padding: 10px;
        padding: 0;

        .gib-upload {
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            border: 1px dashed #dae4ee;
            position: relative;
            border-radius: 3px;
            padding: 5px;
            font-size: 13px;
            font-weight: 600;
            color: #111d31;
            margin: 0;

            input {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                background: transparent;
                opacity: 0;
                cursor: pointer;
            }

            .gibu-text {
                margin: 5px 0;
            }

            .uembri-uploaded-text {
                width: fit-content;
                padding: 3px 13px;
                font-size: 12px;
                font-weight: 500;
                color: #fff;
                background-color: #111d31;
                border-radius: 20px;
                margin-top: 7px;
            }

            .uembri-hide {
                display: none;
            }
            .gibu-upload-btn{
                display: flex;
                align-items: center;
                font-size: 12px;
                font-weight: 500;
                padding: 0 5px;
                svg{
                    margin-right: 5px;
                }
            }
        }
    }

    .gib-dropdown:hover {
        background-color: #f5f5f5;

        input {
            background-color: #f5f5f5;
        }
    }

    .gib-numberRange {
        height: auto;
        border: none;

        .ant-slider {
            width: 100%;
        }
    }

    .fix-large-modal-right {
        max-width: 500px;
        left: auto;
        right: 15px;
    }
}

.generic-input-box.gic-upload-file-with-icon {
    .gib-upload .gib-upload {
        align-items: center;
    }
}

.generic-input-box.transporter-date-input {
    .gib-date {
        .ant-picker {
            padding-left: 4px;
            padding-right: 7px;
            .ant-picker-input{
                input{
                    padding-right: 5px;
                }
            }
        }
    }
}

.date-input-remove-footer {
    .ant-picker-footer {
        display: none;
    }
}

.data-range-size-changes {
    .ant-picker-date-panel {
        .ant-picker-content {
            th {
                height: 30px;
            }
        }
        .ant-picker-body {
            padding: 2px 18px;
        }
    }
    .ant-picker-cell {
        .ant-picker-cell-inner {
            min-width: 22px;
            height: 22px;
            line-height: 22px;
        }
    }
    .ant-picker-footer {
        .ant-picker-ranges {
            padding: 0px 8px;
        }
        .ant-btn-primary {
            border-color: $primary-color;
            background-color: $primary-color;
        }
        .ant-btn-primary[disabled]{
            border: #d9d9d9;
        }
        .ant-btn-primary:hover {
            background: $primary-color;
        }
        .ant-picker-ok .ant-btn span {
            color: #fff;
        }
    }
    .ant-picker-time-panel-cell-selected{
        background-color: $primary-light;
    }
}

.warn-class {
    .gib-date {
        .ant-picker {
            border: solid 1px #ffb103 !important;
        }
    }
}
.error-class {
    .gib-date {
        .ant-picker {
            border: solid 1px #ed3946 !important;
        }
    }
}

.gib-inputButton {
    .gib-label-heading {
        margin-bottom: 0;
    }

    .gib-inner {
        border: none;
        background-color: transparent;
        height: 20px;
        padding: 0;

        input {
            background-color: transparent;
        }
    }

    .gen-input-disabled {
        background-color: #f1f1f1;
    }
}

.use-as-generic-dropdown {
    position: absolute;
    background: #fff;
    box-shadow: 0 2.71233px 18.99px #a0a0a08f;
    border-radius: 6px;
    top: 67px;
    left: 0;
    width: auto;
    min-width: 100%;
    min-height: 70px;
    height: auto;
    max-height: 300px;
    z-index: 1;

    .modal-header-section {
        .mhs-left {
            h4 {
                font-weight: 600;
                font-size: 12px;
            }
        }
    }

    .modal-search-area {
        .msa-left {
            width: 100%;

            .msa-input-outer {
                width: 100%;
                height: 30px;
                border-radius: 3px;
            }

            .focusOn {
                box-shadow: none;
            }
        }
    }

    .lcs-inner {
        // height: calc(100% - 85px);
        max-height: 215px;

        .list-component-table-view {
            .modal-data-list-render {
                .mdlr-li {
                    .mdlr-col {
                        padding: 5px;
                    }
                }
            }
        }
    }
}

.generic-form-body {
    display: block;
    padding: 10px 15px;
    height: calc(100% - 116px);
    overflow: auto;

    .gfb-inner {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        .generic-input-box {
            width: 30%;
            margin-bottom: 20px;
            margin-right: 3.33%;
        }
    }

    .gfb-multiple-row {
        display: block !important;

        .generic-input-box {
            width: 100%;
            margin-right: 0;
        }
    }
}

.mandatory {
    color: #d0021b !important;
    font-size: 13px !important;
}

.error {
    border: solid 1px $danger-color !important;
    box-shadow: none;
    .ant-picker{
        height: 30px;
    }
}

.error-msg {
    font-size: 10px !important;
    font-weight: 500 !important;
    font-style: normal !important;
    font-stretch: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    text-align: left !important;
    color: #c44569 !important;
    width: 100%;
    display: block;
    margin-top: 4px;
}

.generic-form-footer {
    padding: 15px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .gff-right {
        display: flex;
        align-items: center;

        button {
            margin-left: 15px;
        }
    }
}

.rnm-tag {
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    background-color: #e7f1ff;
    margin-bottom: 25px;
    padding: 0 10px;

    svg {
        margin-right: 15px;
    }

    p {
        color: #0f172a;
        font-size: 12px;
        font-weight: 400;
        margin: 0;
    }
}

.summary-logs-modal {
    .modal-search-area {
        flex-direction: column;

        .msa-left {
            .msa-input-outer {
                margin-top: 10px;
                width: 100%;
            }
        }
    }
}

.sap-param {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .pp-left {
        label {
            color: #0f172a;
            font-size: 12px;
            font-weight: 500;
            margin: 0;
            margin-bottom: 3px;
        }

        p {
            color: #71819b;
            font-size: 10px;
            font-weight: 400;
            margin: 0;
        }
    }

    .sapp-value {
        width: 248px;
        height: 30px;
        display: flex;
        align-items: center;
        border-radius: 3px;
        border: 1px solid #dbdbea;
        background: #f3f3f3;
        padding-left: 10px;
        position: relative;

        span {
            color: #0f172a;
            font-size: 12px;
            font-weight: 500;
        }
    }
    .sapp-value {
        .generic-tooltip {
            // visibility: visible;
            // opacity: 1;
            left: auto;
            right: -19%;
        }
    }
    .sapp-value:hover {
        .generic-tooltip {
            visibility: visible;
            opacity: 1;
        }
    }
}

.planning-para-modal {
    .modal-data-list-render {
        height: calc(100% - 72px);
    }
}

.ars-job-modal {
    height: 100%;

    .generic-form-body {
        height: calc(100% - 190px);
    }
}

.manage-otb-plan-modal {
    .generic-form-body {
        height: calc(100% - 132px);
    }
}

.addNewConfigurationModal{
    .gsf-body {
        height: calc(100% - 132px);
    }
}

.custome-value-input {
    .cvi-input {
        width: 100%;
        height: 30px;
        border-radius: 3px;
        border: solid 1px #dce6f0;
        padding: 4px;
        font-size: 12px;
        font-weight: 500;
        color: #0f172a;
    }
}

.printBarcodeList {
     .list-component-section {
        
        position: relative;
        margin-top: -62px;
       
    }
}
.search-icon-black{
    svg{
        path{
            fill: black;
            stroke-width: 1px;
        }
    }

    .generic-spread-form{
        .gsf-body{
            height: calc(100% - 130px);
        }
    }
}