@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.procDashboardBottom {
  .performanceAnalysisItem:nth-last-child(1)
  /* .total-amount-val .performanceAnalysisItem:nth-child(3) */
   {
    .generic-tooltip{
      left: auto !important;
      right: 10px !important;
    }
    .generic-tooltip::after{
      left: auto !important;
      right: 35px !important;
    }
  }

  .total-amount-val .performanceAnalysisItem:nth-last-child(1)  {
    .generic-tooltip{
      right: auto !important;
      left: 14px !important
    }
    .generic-tooltip::after{
      left: 25% !important;
      right: auto !important;
    }
  }

 

  /* .recharts-wrapper{
    .recharts-bar-rectangle{
      width: 30px !important;
    }
  } */

}
.statusWiseStatistics .ant-radio-button-wrapper,
.procDashboardBottom .ant-radio-button-wrapper{
  background-color: #ECEEEF;
  color: #949DA8;
  border: none;
  border-right: none;
  height: 28px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.statusWiseStatistics .ant-radio-button-wrapper:hover, 
.procDashboardBottom .ant-radio-button-wrapper:hover{
  color: initial;
}

.statusWiseStatistics .ant-radio-button-wrapper:focus-within, 
.procDashboardBottom .ant-radio-button-wrapper:focus-within{
  border: none;
  outline: none;
  box-shadow: none;
}


.statusWiseStatistics .ant-radio-button-wrapper-checked,
.procDashboardBottom .ant-radio-button-wrapper-checked{
  background: white;
  font-weight: 700;
  color: black;
  border: none;
  outline: none;
}


.statusWiseStatistics .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
.procDashboardBottom .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #ECEEEF;
}
.retryBtn svg{
  height: 25px;
  width: 25px;
}

.retryBtn svg path{
  fill: white;
}

.wechatOtpInput input{
  border: 1px solid #E3E4E6;
  width: 2.25rem !important;
  padding-top: .4rem !important;
  padding-bottom: .4rem !important;
  font-weight: 600;
  margin-right: .75rem;
}

.settingsTab .gt-list{
  padding-inline: 1rem !important;
}

.settingsTab .activeTab{
  color: var(--primary-color) !important;
  padding-inline: 1rem !important;
  border-bottom: 2px solid var(--primary-color) !important;
}
/* comment editor */
.msgInput .ant-mentions textarea{
  padding-inline: .3rem;
}
.msgInput .ant-mentions-outlined{
  border: none;
  outline: none;
  outline-offset: 0;
}
.msgInput .ant-mentions-outlined:focus, .msgInput .ant-mentions-outlined:focus-within{
  box-shadow: none;
}

/* For Global configuration > table header */
.tableHeader{

  .controlIcons {
    .fix-header:hover, .dragIcon:hover{
      svg{
          stroke: var(--primary-color);
          
      }

    }

    .dragIcon {
      svg{
          stroke: #67768E;
      }
    }
    
  }

  table{
    .controlIcons{
      td{
        max-width: 80px;
      }
    }
  }
}

/* language choose popup */
.choose-language-popup{
  .generic-input-box{
    svg{
      stroke: black;
    }
  }
}

/* jis report */
.jis-report-table{
  .generic-tooltip {
    left: 8%;
  }
}
/* general settings > customize theme */
.colorPickerInput .list-component-section{
    position: static !important;
    margin-top: .5rem !important;
    padding-top: .2rem !important;
    padding-bottom: .2rem !important;
    border-radius: 0 !important;
}

.colorPickerInput .gib-inner{
  border-radius: 0 !important;
  padding-left: 10px !important;
}

.colorPickerInput .gib-inner input{
  padding-right: 0 !important;
}

.colorPickerInput .modal-data-list-render .mdlr-li{
  padding: 10px 12px;
}

/* lucid icons color */
.primary-color {
  color: var(--primary-color);
}


/* schedule job modal */

.ant-picker-time-panel-cell-selected{
  .ant-picker-time-panel-cell-inner{
    background-color: var(--primary-light) !important;
  }
}

.ant-picker-footer button, .ant-picker-footer button[disabled]:hover{
  background-color: transparent !important;
}
.ant-picker-footer button, .ant-picker-footer button[disabled] span{
  color: #d9d9d9 !important;
}

.ant-picker-footer button{
  background-color: var(--primary-color);
  color: white !important;
  border-color: var(--primary-color);
}

.ant-picker-footer button span{
  color: var(--primary-color) !important;
}

.ant-picker-footer button:hover{
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color);
  opacity: .75;
}

.ant-picker-footer button:hover span{
  color: white !important;
}

.ant-picker-now{
  a:hover{
    color: var(--primary-color) !important;
  }
}

/* App tour */
.introjs-tooltip, .introjs-tooltiptext, .introjs-button {
  font-family: Poppins !important;
  color: var(--fontBlack) !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  border-radius: 0 !important;
}

.introjs-tooltiptext{
  padding: 0 12px 10px !important;
}
.introjs-tooltipbuttons{
  border-color: #DAE4EE !important;
  padding: 8px 12px !important;

  .introjs-nextbutton{
    text-shadow: none !important;
    background-color: var(--primary-color) !important;
    color: white !important;
    border: 1px solid var(--primary-color) !important;
  }
}
.introjs-button{
  background-color: white !important;
  border-color: #DAE4EE !important;
  border-radius: 0 !important;
  padding-top: .3rem !important;
  padding-bottom: .3rem !important;
}
.introjs-button:focus{
  box-shadow: 0 0 0 .2rem var(--primary-light) !important;
  outline: 1px solid var(--primary-color) !important;
}
.introjs-button:hover{
  opacity: .5;
}
.introjs-disabled{
  opacity: .5 !important;
  cursor: not-allowed !important;
}
.introjs-helperLayer{
  border-radius: 0 !important;
  box-shadow: var(--primary-color) 0px 0px 1px 2px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
}
/* inspection */
.verifyQCQty{
  .modal-header-section{
    padding-inline: 24px;
  }
  .gib-inner{
    border-radius: 0 !important;
  }
}

/* generic */
.blackIcon{
  path{
    fill: var(--fontBlack);
  }
}

/* Mailing list */
.mailingListDataModal{
  .grc-top{
    flex-direction: row-reverse;
    /* justify-content: end !important; */
    gap: 1rem;

    button{
      background-color: var(--primary-color) !important;
      border: none !important;
    }
    button:hover{
      opacity: .75;
    }
  }

  .gib-inner{
    border-radius: 0 !important;
  }
  .gib-label-heading{
    color: var(--fontBlack) !important;
    font-weight: 500 !important;
  }

  .generic-spread-form {
    .gsf-body{
      .gen-rowtype-component{
        /* margin-top: -38px; */
        .grc-body{
          min-height: 150px;
          /* max-height: 300px; */
          height: calc(100% - 50px);
          .grcb-head{
            padding-left: 70px;
            padding-top: 0;
            padding-bottom: 0;
            position: sticky;
            top: 0;
            z-index: 10;

              .gib-label-heading{
                padding-top: 8px;
                padding-bottom: 8px;
                color: #6A6C70 !important;
                text-transform: capitalize;
                font-weight: 500;
              }
              .gib-label-heading:not(:last-child){
                border-right: 1px solid #DAE4EE;
            }
            .gib-label-heading:first-child{
              border-left: 1px solid #DAE4EE;
          }
          }
          div{
            .generic-form-body{
              border: 1px solid #DAE4EE;
              .gfb-multiple-row{
                padding: 0 !important;
                .gfbi-btns{
                  height: 40px;
                  padding-inline: 12px;
                  border-right: 1px solid #DAE4EE;
                }
                .generic-input-box{
                  padding: 4px !important;
                    .gib-inner{
                      border-radius: 0 !important;
                    }
                    .gib-inner>svg{
                      margin-right: 4px;
                      flex-shrink: 0;
                      height: 14px;
                      width: auto;
                      max-width: 16px;
                    }
                    .list-component-section{
                      border-radius: 0 !important;
                      left: 4px;
                    }
                }
                .generic-input-box:not(:last-child){
                  border-right: 1px solid #DAE4EE;
                }
              }
            }
          }
        }
      }
    }
    .generic-form-footer{
      .gff-right{
        .generic-default-btn{
          background-color: white !important;
          border: 1px solid black !important;
          color: var(--fontBlack) !important;
        }
        .generic-default-btn:hover{
          opacity: .75;
        }
        .generic-default-btn, .generic-approve-btn {
          min-width: 100px;
        }
        .generic-approve-btn{
          margin-left: 0 !important;
        }
      }
    }
  }

  /* generic */
  button{
    border-radius: 0 !important;
    
  }
}

/* Create Product Survey */
.createProductSurvey{

  .generic-spread-form{
    .gsf-body{
      height: calc(100% - 170px) !important;
    }
  }
  .generic-spread-form > .generic-form-footer{
    .gff-right{
      flex: 1;
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      gap: 8px;
        .generic-approve-btn{
          width: 100% !important;
          margin-inline: 0;
        }
        .generic-default-btn{
          margin-inline: 0;
          padding: 0 !important;
          width: fit-content;
          background-color: white !important;
          border: none !important;
          color: var(--fontBlack) !important;
        }
        .generic-default-btn:hover{
          opacity: .75;
        }
    }
  }

  /* .gfb-inner{ */
    /* display: block !important; */
    /* .generic-input-box{
      width: 100% !important;
    } */
    /* .generic-input-box{ */
      /* .gib-inner{
        height: 35px;
       } */

      /* .gib-textarea{
        height: 100px !important;
      } */

      /* .gib-customeDropdown{
        min-height: 32px;
        height: fit-content !important;
          .custome-dropdown-input{
            min-height: 32px;
            padding-top: 6px;
            padding-bottom: 6px;
          }
      } */

      /* .list-component-section{
        border-radius: 0 !important;
        top: calc(100% + 5px) !important;
          .lcs-inner{
            max-height: 210px !important;
          }
      } */
    /* } */
  /* } */

   /* generic */
   .gib-inner, .msa-input-outer{
    border-radius: 0 !important;
  }
  .gib-label-heading{
    font-size: 11px !important;
    font-weight: 400 !important;
    color: var(--fontBlack) !important;
  }
   button{
    border-radius: 0 !important;
  }
}

.createProductSurvey .gfb-inner{
  display: block !important;
}
.createProductSurvey .gfb-inner .generic-input-box{
  width: 100% !important;
}
.createProductSurvey .gfb-inner .generic-input-box .list-component-section{
  border-radius: 0 !important;
  top: calc(100% + 5px) !important;
}
.createProductSurvey .gfb-inner .generic-input-box .list-component-section .lcs-inner{
  max-height: 210px !important;
}

.createProductSurvey .gfb-inner .generic-input-box .gib-inner{
  height: 35px;
 }
.createProductSurvey .gfb-inner .generic-input-box .gib-textarea{
  height: 100px !important;
}
.createProductSurvey .gfb-inner .generic-input-box .gib-customeDropdown{
  min-height: 32px;
  height: fit-content !important;
}
.createProductSurvey .gfb-inner .generic-input-box .gib-customeDropdown .custome-dropdown-input{
  min-height: 32px;
  padding-top: 6px;
  padding-bottom: 6px;
}
@keyframes pulseAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* product survey complete */
.completeProductSurvey{
  .ant-progress{
    .ant-progress-steps-outer{
      .ant-progress-steps-item{
        border-radius: 5px;
        width: 3px !important;
        margin-right: 3px;
      }
    }
  }

  .timerProgress{
    
    .ant-progress-steps-outer {
      .ant-progress-steps-item-active:nth-child(-n+3) {
          background-color: #FF2A28 !important;
      }
      .ant-progress-steps-item-active:nth-child(n+4):nth-child(-n+6) {
          background-color: #FF6128 !important;
      }
      .ant-progress-steps-item-active:nth-child(n+7):nth-child(-n+9) {
          background-color: #E6A729 !important;
      }
      .ant-progress-steps-item-active:nth-child(n+10):nth-child(-n+12) {
          background-color: #F0CC62 !important;
      }
      .ant-progress-steps-item-active:nth-child(n+13):nth-child(-n+15) {
          background-color: #51B850 !important;
      }
  }
  
  }

  /* .timeStarted{
    .ant-progress-steps-outer {
      .ant-progress-steps-item-active:last-child {
        animation: pulseAnimation 1.5s infinite;
      }
    }
  } */

  .stepsProgress{
    
    .ant-progress-steps-outer {
      .ant-progress-steps-item-active:nth-child(-n+5) {
          background-color: #FF2A28 !important;
      }
      .ant-progress-steps-item-active:nth-child(n+6):nth-child(-n+10) {
          background-color: #FF6128 !important;
      }
      .ant-progress-steps-item-active:nth-child(n+11):nth-child(-n+15) {
          background-color: #E6A729 !important;
      }
      .ant-progress-steps-item-active:nth-child(n+16):nth-child(-n+20) {
          background-color: #F0CC62 !important;
      }
      .ant-progress-steps-item-active:nth-child(n+21):nth-child(-n+25) {
          background-color: #51B850 !important;
      }
      /* .ant-progress-steps-item-active:last-child {
        animation: pulseAnimation 1.5s infinite;
    } */
  }
  
  }

  .ant-carousel{

    .slick-arrow{
      /* background-color: rgba(255, 255, 255, 0.5); */
      background-color: white;
      margin-top: 0;
    }

    .slick-prev{
      position: absolute;
      left: 0;
    }
    .slick-next{
      position: absolute;
      right: 0;
    }

  }
  .list-component-section{
    .modal-data-list-render{
      max-height: calc(100vh - 135px) !important;
      overflow: auto;
      padding: 12px 16px !important;
        .rowInput{
          .gib-inner{
            height: 100% !important;
          }
        }
    }
  }
}

/* viewProductSurvey modal */
.viewProductSurvey{

  .list-component-section{
    .list-component-normal-view{
      .modal-data-list-render{
        height: 100%;
      }
    }
  }
}
  
/* central comments */
.commentDocList{
    .list-component-section{
      .modal-search-area{
        padding-top: 14px !important;
        padding-bottom: 14px !important;
        border-bottom: 1px solid #DAE4EE;
        .msa-left{
          width: 100%;
          display: block;
            .msa-input-outer{
              border-radius: 0;
              height: 37px;
            }
        }
      }
      .lcs-inner{
        .modal-data-list-render{
          padding-top: 4px;
          padding-bottom: 4px;
          /* height: 100%; */
          height: calc(100% - 120px);
        }
        .lctv-table-loader{
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
      }
    }
}

.commentDocList .list-component-section .generic-input-box .gib-inner{
  border-radius: 0 !important;
}

/* prodDashboardBottomSection */

.prodDashboardBottomSection{

  .generic-input-box {
    flex-shrink: 0;
    .gib-inner {
      border-radius: 0;
    }

  } 

  .list-component-section{
    top: 35px !important;
  }
}
/* od-edit-modal */
.od-edit-modal .gib-textarea{
  height: 120px !important;
} 
.od-edit-modal .gib-textarea textarea{
  overflow: auto !important;
}
.od-edit-modal .quill .ql-container .ql-editor{
  height: 120px !important;
  overflow: auto !important;
}
.od-edit-modal .quill .ql-container .ql-syntax{
  height: 120px !important;
  overflow: auto !important;
}

/* global config > update organization > code editor */
.rdw-editor-toolbar .rdw-link-modal{
  left: auto;
  right: 2.5rem;
  height: auto;
}

.rdw-editor-toolbar .rdw-colorpicker-modal{
  left: auto;
  right: .3rem;
}

@media (max-width: 1265px) {
  .rdw-editor-toolbar .rdw-link-modal{
    left: 5px;
    right: auto;
    height: auto;
  }
}

@media (max-width: 1243px) {
  .rdw-editor-toolbar .rdw-colorpicker-modal{
    left: auto;
    right: .3rem;
  }
}

@media (max-width: 1129px) {
  .rdw-editor-toolbar .rdw-colorpicker-modal{
    left: 5px;
    right: auto;
  }
}
/* master data > customers */
/* manage customer modal */
.manageCustomerModal, .customerDetailsModal{
  .generic-view-modal{
    .gvm-scroll{
      h2{
        font-size: 14px;
        font-weight: 600;
        color: var(--fontBlack);
      }
      .gvm-body{
        .gvmb{
          label{
            color: #67768e;
            font-size: 10px;
            font-weight: 500;
            /* margin-bottom: .5rem; */
          }
        }
      }
    }
  }
}
/* createRoles */
.createRoles{
  .modal-header-section{
    padding: 0;
  }
  .generic-input-box .gib-inner{
    border-radius: 0;
  }
  .gsf-body{
    .generic-form-body{
      background-color: white;
      padding: 16px;

      .gfb-inner{
        .generic-input-box{
          margin-bottom: 0 !important;

          .gib-label-heading{
            color: var(--fontBlack) !important;
            font-weight: 500 !important;
          }
          .gib-inner{
            border-radius: 0 !important;
          }
        }
        .generic-input-box:first-child{
          flex: 1 !important;
        }
      }
    }
  }
}

.viewPermissionsModal {
  .generic-input-box{
    /* margin-bottom: 0 !important;

    .gib-label-heading{
      color: var(--fontBlack) !important;
      font-weight: 500 !important;
    } */
    .gib-inner{
      border-radius: 0 !important;
    }
  }
}

/* utility classes */

.textarea-height-100 .gib-inner{
  height: 100px !important;
}
/* master data > sites > site settings */

.mbc-right .configureSitesBtn svg{
    stroke: white !important;
    fill: transparent !important;
}

/* site settings */
.mapDefaultSiteContainer > div:last-child .siteSettingsModalDropdown .list-component-section {
  left: auto;
  right: 0;
}
/* trainingGuideSidebar */

.trainingGuideSidebar .generic-tabs .gt-list{
  width: 100% !important;
  border-right: 1px solid #DAE4EE;
  margin-right: 0;
  padding-inline: 1rem;
  color: var(--fontBlack);
}

.trainingGuideSidebar .generic-tabs .gt-list:nth-last-child(){
  border-right: none;
}

.trainingGuideSidebar .generic-tabs .activeTab{
  color: var(--primary-color) !important;
  border-bottom-color: var(--primary-color);
}

/* master data > vendors */

.addUserModal .gen-rowtype-component .grc-body{
  padding-right: 0 !important;
}

.addUserModal .gen-rowtype-component .grc-body .grcb-head .gib-label-heading{
  width: 16.75vw !important;
  max-width: 275px;
  min-width: 150px;
}

.addUserModal .gen-rowtype-component .grc-body .generic-form-body .generic-input-box{
  width: 16.75vw !important;
  max-width: 275px;
  min-width: 150px;
}


/* view vendor users modal */

.viewVendorsUsersModal{
  color: var(--fontBlack);
}

.viewVendorsUsersModal > div {
  height: 100%;
}

/* .viewVendorsUsersModal .list-component-section {
  height: fit-content;
} */
.viewVendorsUsersModal .list-component-section .lcs-inner {
  width: auto;
  height: calc(100% - 60px);
}

.viewVendorsUsersModal .lcs-inner .list-component-normal-view {
  width: fit-content !important;
}
/* .viewVendorsUsersModal .lcs-inner .lcv-table-head{
  position: sticky;
  top: 0;
} */
.viewVendorsUsersModal .lcs-inner .lcvth-row{
  padding-inline: 15px;
  display: flex;
  white-space: nowrap;
  padding-bottom: 0;
  border-bottom: 1px solid #E5E7EB;
  background-color: #F8F8F8;
}

.viewVendorsUsersModal .lcs-inner .lcvth-row li{
  /* width: 13vw;
  max-width: 250px;
  min-width: 150px; */
  font-size: 11px;
  font-weight: 500;
  color: #67768e;
  text-align: left;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  background-color: #F8F8F8 !important;
}

.viewVendorsUsersModal .lcs-inner .lcvth-row li label{
  margin: 0;
}

.viewVendorsUsersModal .lcs-inner .modal-data-list-render{
  height: auto;
}
.viewVendorsUsersModal .lcs-inner .modal-data-list-render .mdlr-li{
  cursor: default;
}

.viewVendorsUsersModal .lcs-inner .modal-data-list-render li:not(:last-child){
  border-bottom: 1px solid #DAE4EE;
}

/* .viewVendorsUsersModal .lcs-inner .modal-data-list-render li .mdlr-col{
  width: 13vw;
  max-width: 250px;
  min-width: 150px;
  font-size: 11px;
} */

.viewVendorsUsersModal .lcs-inner .modal-data-list-render li .mdlr-col .mdlr-multiple-row,
.viewVendorsUsersModal .lcs-inner .modal-data-list-render li .mdlr-col td
 {
  color: #67768e;
  font-size: 11px;
  font-weight: 600;
  color: #21314b !important;
 }
/* add/edit user modal */

.addEditUserModal .list-component-table-view .lcvth-row li{
  border-top: 1px solid #E5E7EB;
  background-color: #F8F8F8 !important;
}

.addEditUserModal .list-component-table-view .lcvth-row li:first-child{
  min-width: 120px;
}

.addEditUserModal .list-component-table-view .lcvth-row li:nth-child(2){
  width: 35%;
}
.addEditUserModal .list-component-table-view .lcvth-row li:last-child{
  width: 100%;
}

.addEditUserModal .list-component-table-view .lcvth-row li:not(:last-child){
  border-right: 1px solid #E5E7EB;
}
.addEditUserModal .list-component-table-view .modal-data-list-render .mdlr-li .mdlr-col:not(:last-child){
  border-right: 1px solid #E5E7EB;
}
.addEditUserModal .list-component-table-view .modal-data-list-render .mdlr-li .mdlr-col{
  border-bottom: 1px solid #E5E7EB;
/* generic styling */
}

.textarea-w-scroll textarea{
    overflow-y: auto !important;
}

/* digiCat dashboard */
.seeAllCollectionsPopover .list-component-section{
  display: flex;
  flex-direction: column;
}
.seeAllCollectionsPopover .list-component-section .lcs-inner {
  height: 100%;
}

.seeAllCollectionsPopover .list-component-section .modal-header-section{
  padding-top: 10px;
  padding-bottom: 8px;
  border-bottom: 1px solid #E3E4E7;
}
.seeAllCollectionsPopover .list-component-section .msa-left{
  margin-top: 1rem;
  width: 100%;
  display: block;
}
.seeAllCollectionsPopover .list-component-section .msa-left .msa-input-outer{
  border-radius: 0;
}
.seeAllCollectionsPopover .list-component-section .msa-left .msa-input-outer input{
  border: none;
  width: 100%;
}

.seeAllCollectionsPopover .list-component-section .lcs-inner {
  height: 100% !important;
}
.seeAllCollectionsPopover .list-component-section .lcs-inner .list-component-table-view{
  display: block !important;
}
.seeAllCollectionsPopover .list-component-section .lcs-inner .modal-data-list-render{
  display: block !important;
}
.seeAllCollectionsPopover .list-component-section .lcs-inner .modal-data-list-render .no-data-found-screen{
  display: block !important;
  margin-top: -3rem;
}