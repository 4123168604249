@import '../variables.scss';

.globalconfig-breadcrumb {
    position: sticky;
    top: 45px;
    z-index: 8;

    .main-bread-crumps {
        padding: 0 12px 0 20px;
    }
}

.global-configuration {
    width: 100%;
    height: calc(100vh - 92px);
    display: flex;
    justify-content: space-between;
    padding: 12px 12px 12px 27px;

    .gc-left {
        width: 20%;
        height: 100%;
        background-color: #fff;
        margin-right: 12px;

        .gcl-det {
            .generic-input-box {
                padding: 10px;
                margin: 0;

                .gib-inner {
                    svg {
                        width: 30px;
                    }
                }
            }
        }

        ul {
            height: auto;
            max-height: calc(100vh - 180px);
            overflow: auto;

            li {
                display: flex;
                align-items: start;
                padding: 10px;
                cursor: pointer;
                border-left: 3px solid transparent;

                span {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }

                p {
                    color: #0F172A;
                    font-size: 13px;
                    font-weight: 400;
                    margin: 0;
                }
            }

            li:hover,
            .active {
                // border-left: 3px solid #0F172A;
                // background: rgba(15, 23, 42, 0.10);
                border-left:3px solid $primary-color;
                background-color: $primary-w-less-opacity;
                // background-color: rgba(var(--primary-color), 0.5);

                span {
                    svg {
                        path {
                            // stroke: #0F172A;
                            stroke: $primary-color;
                        }
                        circle {
                            // stroke: #0F172A;
                            stroke: $primary-color;
                        }
                    }

                    .replenish-svg {
                        path {
                            stroke: none;
                            // fill: #0F172A;
                            fill: $primary-color;
                        }
                    }

                }

                p {
                    font-weight: 600;
                    color: $primary-color;
                }
            }
        }
    }

    .gc-right {
        width: 80%;

        .gcr-top {
            display: flex;
            align-items: center;
            background-color: #fff;
            padding: 10px;
            margin-bottom: 12px;

            .gcrt-left {
                margin-left: 15px;
                width: 100%;
                h1 {
                    margin-left: 0;
                }
                p {
                    white-space: normal;
                    max-width: fit-content;
                }
                .gcrt-trainingGuides {
                    max-width: 60%;
                }
            }

            span {
                width: 20px;
                height: 20px;

                svg {
                    path, circle {
                        stroke: #0F172A;
                    }
                }

                .replenish-svg {
                    path {
                        stroke: none;
                        fill: #0F172A;
                    }
                }
            }

            h1 {
                color: #0F172A;
                font-size: 12px;
                font-weight: 600;
                margin: 0 3px 0 7px;
                white-space: nowrap;
            }

            p {
                max-width: calc(100% - 375px);
                color: #67768E;
                font-size: 11px;
                font-weight: 400;
                margin: 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

        }

        .gcr-body {
            height: calc(100vh - 168px);
            background-color: #fff;

            .gcrb-sec {
                .generic-input-box {
                    width: 21%;
                    display: flex;
                    align-items: center;
                    position: absolute;
                    top: 13px;
                    right: 10px;
                    border: 1px solid #DAE4EE;
                    padding-left: 5px;
                    margin: 0;
                    cursor: pointer;

                    .gib-label-heading {
                        color: #7E8084;
                        font-size: 12px;
                        font-weight: 400;
                        margin: 0;
                        cursor: pointer;
                        white-space: nowrap;
                    }

                    .gib-inner {
                        height: 28px;
                        border: none;

                        input {
                            color: #0F172A;
                        }
                    }

                    .gib-inner:hover {
                        background-color: #fff;

                        input {
                            background-color: #fff;
                        }
                    }

                    .list-component-section {
                        top: 32px;
                    }
                }

                .gcrbs-btns {
                    position: absolute;
                    top: -51px;
                    right: 0;
                    z-index: 9;

                    button {
                        height: 30px;
                        font-size: 12px;
                        font-weight: 600 !important;
                        margin-left: 12px;
                        border-radius: 0;
                        // text-transform: uppercase;
                    }
                }
            }

            .gcrb-tabs {
                max-width: 100%;
                overflow-x: auto;
                .generic-tabs {
                    padding-inline: 10px;
                    .gt-list {
                        // color: #0F172A;
                        // font-size: 14px;
                        // font-weight: 600;
                        // padding: 10px 20px;
                        // margin: 0;
                        white-space: nowrap;
                    }

                    .activeTab {
                        color: $primary-color;
                        // border-bottom: 2px solid $primary-color;
                    }
                }
            }

            .gcrb-msg {
                padding: 12px 15px;
                border-bottom: 1px solid #DAE4EE;

                .gcrbm {
                    display: flex;
                    align-items: flex-start;
                    background: rgba(93, 95, 239, 0.10);
                    padding: 10px 15px;

                    svg {
                        margin-right: 7px;
                    }

                    p {
                        color: #0F172A;
                        font-size: 11px;
                        font-weight: 400;
                        margin: 0;
                    }
                }
            }

            .gcrb-det {
                display: flex;
                height: calc(100vh - 290px);

                .gcrbd-left {
                    width: 20%;
                    max-height: calc(100vh - 290px);
                    overflow: auto;
                    padding: 0 20px;
                    border-right: 1px solid #DAE4EE;

                    .gcrbdl-timeline {
                        padding-top: 20px;

                        ul {
                            position: relative;
                            list-style: none;
                            padding-left: 20px;
                            margin-bottom: 1rem;

                            li {
                                width: fit-content;
                                display: flex;
                                align-items: start;
                                position: relative;
                                margin-bottom: 25px;
                                cursor: pointer;

                                span {
                                    width: 17px;
                                    min-width: 17px;
                                    height: 17px;
                                    margin-right: 7px;

                                    svg {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }

                                p {
                                    color: #0F172A;
                                    font-size: 13px;
                                    font-weight: 600;
                                    margin: 0;
                                }
                            }

                            li:hover{
                                p {
                                    color: $primary-color;
                                }
                            }

                            li::after {
                                content: "";
                                width: 11px;
                                height: 2px;
                                position: absolute;
                                top: 9px;
                                left: -20px;
                                background-color: #DAE4EE;
                            }
                        }

                        ul::before {
                            content: "";
                            width: 2px;
                            height: 100%;
                            position: absolute;
                            top: 9px;
                            left: 0;
                            background-color: #DAE4EE;
                        }

                        .sub-ul {

                            .sub-li {
                                margin-bottom: 15px;
                                padding: 5px 10px;

                                p {
                                    color: #67768E;
                                    font-weight: 500;
                                }
                            }

                            .sub-li-active,
                            .sub-li:hover {
                                background: rgba(93, 95, 239, 0.10);

                                p {
                                    color: $primary-color;
                                }
                            }

                            .sub-li::after {
                                width: 13px;
                                top: 15px;
                                left: -20px;
                            }
                        }

                        .sub-ul::before {
                            top: -13px;
                            left: 0;
                        }
                    }
                }

                .gcrbd-right {
                    width: 80%;
                    max-height: calc(100vh - 290px);
                    overflow: auto;

                    .tableconfig-default {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                        padding: 20px;

                        p {
                            font-weight: 600;
                            font-size: 15px;
                            text-align: center;
                            color: #67768E;
                            padding-top: 15px;
                        }
                    }
                }
            }
        }

        .data-setting-tabs {
            overflow: auto;
        }
    }

    .templateMapping{
        .generic-input-box{
            .gib-label-heading{
                
            }
            .gib-inner{
                border-radius: 0 !important;
            }
        }
    }
}

// Table Header
.gc-transfer-order {
    .gct-tab {
        max-width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid #DAE4EE;

        button {
            height: 30px;
            margin-right: 30px;
        }

        .generic-tabs {
            width: auto;
            border: none;

            button {
                padding: 0 10px;
                margin: 0 30px 0 0;
                border: none;
                white-space: nowrap;
                transition: all .3s;
            }

            .gt-list:hover,
            .activeTab {
                background-color: #21314b;
                color: #fff;
                font-weight: 600;
                border: none;
            }
        }

        .gctt-excel-filter {
            display: flex;
            align-items: center;
            position: relative;
            padding: 0 10px;
            border: 1px solid #21314b;
            color: #21314b;
            font-weight: 500;
            font-size: 13px;
            margin-left: 25px;
            white-space: nowrap;
            transition: all .3s;

            svg {
                margin-right: 5px;
            }
        }

        .gctt-excel-filter:hover,
        .btn-active {
            background-color: #21314b;
            color: #fff;
            font-weight: 600;

            svg {
                path {
                    stroke: #fff;
                }
            }
        }

        .gctt-excel-filter[disabled]:hover {
            background-color: #fff;
            color: #21314b;
            font-weight: 500;

            svg {
                path {
                    stroke: #21314b;
                }
            }
        }

        .gctt-excel-filter::before {
            content: "";
            position: absolute;
            top: 5px;
            left: -23px;
            height: 18px;
            width: 1px;
            display: block;
            background: #dae4ee;
        }

    }

    .gct-export-filter {
        height: calc(100vh - 355px);
        overflow: auto;
        padding: 10px;

        .gcte-alert {
            display: flex;
            align-items: center;
            background-color: #edf3fa;
            padding: 15px 10px;
            margin-bottom: 10px;

            svg {
                margin-right: 10px;
            }

            p {
                color: #4d719c;
                font-size: 13px;
                font-weight: 500;
                margin: 0;
            }
        }

        .generic-input-box {
            width: 50%;

            .gib-label-heading {
                color: #21314b;
                font-size: 13px;
                margin-bottom: 5px;
            }

            .gib-inner {
                height: 40px;

                svg {
                    margin-right: 5px;
                }

                input {
                    color: #8496A9;
                }
            }

            .list-component-section {
                .lcs-inner {
                    .list-component-normal-view {
                        .modal-data-list-render {
                            li {
                                .mdlr-col {
                                    p {
                                        font-size: 12px;
                                        font-weight: 600;
                                        color: #12203c;
                                        margin: 0;
                                    }

                                    svg {
                                        width: 18px;
                                        height: 18px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        table {
            width: 100%;
            border: 1px solid #dae4ee;
            border-bottom: none;

            thead {
                tr {
                    th {
                        background: #f5f5f5;
                        color: #67768e;
                        font-size: 11px;
                        font-weight: 600;
                        border-right: 1px solid #dae4ee;
                        border-bottom: 1px solid #dae4ee;
                        padding: 8px;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        border-right: 1px solid #dae4ee;
                        border-bottom: 1px solid #dae4ee;
                        font-size: 12px;
                        font-weight: 500;
                        padding: 5px 8px;

                        .generic-input-box {
                            width: 100%;
                            margin: 0;

                            .gib-inner {
                                height: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Global Configuration Table
.gc-table {
    height: calc(100vh - 355px);
    overflow: auto;
    padding: 10px;

    table {
        width: 100%;
        border: 1px solid #dae4ee;

        thead {
            tr {
                th {
                    text-align: left;
                    padding: 8px;
                    border-right: 1px solid #dae4ee;
                    background-color: #f5f5f5;

                    label {
                        color: #67768e;
                        font-size: 12px;
                        font-weight: 600;
                        // text-transform: uppercase;
                        margin: 0;
                    }
                }

                th:nth-last-child(1) {
                    border: none;
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 5px 8px;
                    border-top: 1px solid #dae4ee;
                    border-right: 1px solid #dae4ee;

                    .generic-smart-input {
                        height: 40px;
                        display: flex;
                        align-items: center;
                        padding: 0 8px;

                        .generic-input-box {
                            margin: 0;

                            .gib-inner {
                                border: none;
                                padding: 0;

                                input {
                                    font-weight: 500;
                                }
                            }
                        }
                    }

                    .bgDisabled {
                        background-color: #f1f1f1;
                    }
                }

                .td-sort {
                    display: flex;
                    align-items: center;

                    .tds-name {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        position: relative;
                        border-bottom: 1px transparent solid;

                        span {
                            font-weight: 500;
                            color: #67768e;
                            font-size: 12px;
                        }

                        .up-icon {
                            margin-left: 7px;
                        }

                        .active-up-icon {
                            opacity: 1;
                            visibility: visible;

                            svg {
                                transform: rotate(180deg);

                                path {
                                    fill: $primary-color;
                                }
                            }
                        }

                        .generic-tooltip {
                            background: rgba(17, 29, 49, 0.9);
                            border-radius: 60px;
                            font-weight: 500;
                            left: 23%;
                            color: #fff;
                        }

                        .generic-tooltip:after {
                            display: none;
                        }
                    }

                    .tds-name:hover {
                        border-bottom: 1px dashed #8D99AC;

                        .generic-tooltip {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }

                td:nth-last-child(1) {
                    border-right: none;
                }
            }
        }
    }
}

// Document Format
.document-format {
    height: calc(100vh - 290px);
    overflow: auto;

    h1 {
        color: #111d31;
        font-size: 14px;
        font-weight: 600;
        margin: 0 0 0 15px;
    }

    .df-det {
        max-width: 100%;
        overflow: inherit;
        display: flex;
        border-bottom: 1px solid #dae4ee;
        padding: 10px 15px 15px;

        .generic-input-box,
        .dfd-active-format {
            margin: 0 30px 0 0;

            label {
                font-size: 13px;
                font-weight: 500;
                color: #67768e;
                white-space: nowrap;
                margin-bottom: 3px;
            }

            .gib-inner {
                height: 30px;
            }

            .list-component-section {
                top: 57px;
            }

            .dfd-val {
                height: 30px;
                display: flex;
                align-items: center;
                color: #67768e;
                font-size: 11px;
                font-weight: 600;
                border-radius: 6px;
                background-color: #f8f8f8;
                white-space: nowrap;
                padding: 0 10px;
            }
        }

        .generic-input-box::after {
            content: "/";
            position: absolute;
            bottom: 3px;
            right: -19px;
            font-size: 16px;
            color: #cbd5e0;
            font-weight: 600;
        }
    }

    .p-tb-10:nth-last-child(1) {
        .df-det {
            border-bottom: none;
        }
    }
}

// Api Integration
.api-integration {
    height: calc(100vh - 210px);
    overflow: auto;

    p {
        font-size: 12px;
        text-align: left;
        color: #67768e;
        margin: 0;
        padding: 15px 15px 0;
    }

    .ai-loading{
        height: calc(100% - 33px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ai-tabs{
        height: calc(100% - 33px);
        .ait-det{
            display: flex;
            justify-content: flex-start;
            padding: 15px;
            position: relative;
            label{
                min-width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #111d31;
                font-size: 13px;
                font-weight: 600;
                border: 1px solid #dae4ee;
                background: #f5f5f5;
                border-radius: 5px 0 0 5px;
                padding: 10px 8px 8px;
                margin: 0;
            }
            .aitd-det{
                min-width: 500px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 8px;
                border: 1px solid #dae4ee;
                border-left: none;
                border-radius: 0 5px 5px 0;
                p{
                    font-size: 13px;
                    font-weight: 500;
                    color: #111d31;
                    margin: 0;
                    padding: 0;
                }
                button{
                    position: relative;
                    margin-left: 10px;
                    padding: 5px;
                    border-radius: 4px;
                }
                button:hover{
                    // background-color: #f2f1fe;
                    background-color: $primary-w-less-opacity;

                    svg{
                        path{
                            fill: $primary-color;
                        }
                    }
                    .generic-tooltip{
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }
    }

    .ai-data {
        .aid-tab {
            display: flex;
            flex-direction: row;
            gap: 12px;
            max-width: 100%;
            // overflow-x: auto;
            // margin: 0 15px;
            padding: 15px;
            // border-bottom: 1px solid #DAE4EE;
            // border-top: 1px solid #DAE4EE;

            button {
                min-height: 40px;
                // display: flex;
                // align-items: center;
                // border: 1px solid #DAE4EE;
                // margin-right: 15px;
                // padding: 0 10px;
                box-shadow: 0 8px 14px -17px #8b93bb;

                span {
                    width: auto;
                    height: 30px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                p {
                    // border-radius: 2px;
                    // border: 1px solid #03B675;
                    // background: #FFF;
                    // margin: 0 0 0 5px;
                    // color: #03B675;
                    font-size: 9px;
                    // font-weight: 500;
                    padding: 0 5px;
                }
            }

            .active {
                box-shadow: 0px 1px 44px 0px rgba(0, 0, 0, 0.07);
            }
        }

        .aid-bot {
            .aidb-det {
                display: flex;
                gap: 1rem;
                align-items: flex-end;
                justify-content: space-between;
                padding: 10px 15px;

                .aidbd {
                    width: 25%;
                    // padding-right: 30px;

                    label {
                        color: #74767A;
                        font-size: 10px;
                        font-weight: 400;
                        margin-bottom: 3px;
                        white-space: nowrap;
                    }

                    p {
                        width: 100%;
                        max-width: 100%;
                        color: #0F172A;
                        font-size: 11px;
                        font-weight: 500;
                        padding: 5px 10px;
                        border: 1px solid #DAE4EE;
                        background: #F2F4F7;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .generic-input-box {
                        margin: 0;

                        .gib-text {
                            border-radius: 0;
                            input{
                                padding: 0 5px;
                            }
                        }
                    }

                    .generic-default-btn {
                        display: flex;
                        align-items: center;
                        background: #fff !important;
                        color: #0F172A !important;
                        border: 1px solid #DAE4EE !important;
                        border-radius: 0;

                        svg {
                            margin-right: 5px;
                        }
                    }

                    .generic-default-btn:hover {
                        background: #f5f5f5 !important;
                    }
                }

                .aidbd:nth-last-child(1) {
                    display: flex;
                    justify-content: end;
                    padding: 0;
                    width: auto;
                }
            }

        }
        .aidb-table {
            padding: 10px 10px 0 10px;

            table {
                width: 100%;

                thead {
                    background-color: #f8f8f8;

                    tr {
                        th {
                            color: #67768e;
                            font-size: 12px;
                            font-weight: 500;
                            text-align: left;
                            border-left: 1px solid #dae4ee;
                            border-bottom: 1px solid #dae4ee;
                            padding: 8px;
                        }

                        th:nth-child(1) {
                            border-left: none;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            border-left: 1px solid #dae4ee;
                            border-bottom: 1px solid #dae4ee;
                            padding: 8px;

                            label {
                                max-width: 190px;
                                display: flex;
                                align-items: center;
                                color: #111d31;
                                font-size: 13px;
                                font-weight: 400;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                margin: 0;
                                position: relative;
                            }

                            .td-icon {
                                position: relative;
                                margin-left: 5px;
                            }

                            .td-icon:hover {
                                .generic-tooltip {
                                    visibility: visible;
                                    opacity: 1;
                                }
                            }
                            .copyBtn:hover{
                                svg{
                                    path{
                                        fill: $primary-color;
                                    }
                                }
                            }
                        }

                        td:nth-child(1) {
                            border-left: none;
                        }
                    }
                }
            }
        }
        .generic-tabs{
            border-top: 1px solid #dae4ee;
            // .gt-list{
            //     padding-inline: 1.5rem;
            //     font-weight: 600;
            //     margin-right: 0;
            // }
            padding-inline: 16px;
        .activeTab{
            color: $primary-color;
            // border-bottom-color: $primary-color;
        }
        }
    }

    .generic-input-box {
        .gib-inner{
            border-radius: 0;            
        }
    }
}

.edit-integration {
    .gsf-body {
        max-height: calc(80vh - 116px);
        .generic-form-body {
            .gfb-inner {
                flex-direction: column;

                .generic-input-box {
                    width: 100%;
                    margin-right: 0;

                    .gib-label-heading {
                        font-size: 13px;
                        font-weight: 500;
                        margin-bottom: 3px;
                    }

                    .gib-textarea {
                        height: 150px;
                    }

                    p {
                        font-size: 11px;
                        margin: 0;
                    }

                    .list-component-section {
                        top: 58px;
                    }
                }
            }
        }
        .gib-inner{
            border-radius: 0;
        }

    }
    button{
        border-radius: 0;
    }
}

// Organization Data
.organization-data {
    height: calc(100vh - 168px);
    background-color: #fff;
    position: relative;
    padding-bottom: 10px;

    .od-head {

        button {
            position: absolute !important;
            top: -53px;
            right: 10px;
            background: #f5f5f5;
            border: 1px solid #f5f5f5;
            font-weight: 600;
            font-size: 13px;
            color: #0F172A;
            display: flex;
            align-items: center;
            padding: 3px 5px;

            svg {
                margin-right: 5px;
            }
        }
    }

    .od-body {
        height: calc(100vh - 181px);
        overflow: auto;

        .od-det {
            background: #fff;
            border: 1px solid #dae4ee;
            margin: 10px;

            .odd-head {
                border-bottom: 1px solid #dae4ee;
                padding: 10px;

                h3 {
                    font-weight: 700;
                    font-size: 13px;
                    color: #0F172A;
                    margin: 0;
                }
            }

            .odd-det {
                display: flex;
                flex-wrap: wrap;
                padding: 10px 10px 0;

                .oddd {
                    padding: 0 50px 20px 0;

                    label {
                        font-weight: 500;
                        font-size: 11px;
                        color: #67768e;
                        margin: 0;
                    }

                    p {
                        font-weight: 500;
                        font-size: 13px;
                        color: #23235f;
                        margin: 0;

                        a {
                            color: #007bff;
                            text-decoration: none;
                            background-color: transparent;
                        }

                        a:hover {
                            color: #0056b3;
                            text-decoration: underline;
                        }
                    }

                    .org-name {
                        background: linear-gradient(132.83deg, $primary-color 2.97%, #20bf6b 107.05%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-weight: 700;
                    }

                    .oddd-term {
                        p {
                            max-width: 625px;
                            display: -webkit-box !important;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                        }

                        .read-more {
                            font-weight: 500;
                            font-size: 13px;
                            color: $primary-color;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .od-det:nth-child(1) {
            .odd-det {
                .oddd:nth-child(1) {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    .read-more-text {
        height: 100%;

        .rmt-body {
            height: calc(100% - 54px);
            padding: 0 15px 15px;

            iframe {
                width: 100%;
                height: 100%;
                border: 1px solid #dee;
            }
        }
    }

    .od-edit-modal {
        .gsf-body {
            h2 {
                font-weight: 700;
                font-size: 13px;
                color: #23235f;
            }

            .generic-form-body {
                .gfb-inner {
                    .generic-input-box {
                        width: 100%;
                        margin-right: 0;

                        .gib-label-heading {
                            font-weight: 500;
                            font-size: 12px;
                        }
                        .gib-inner{
                            .generic-tooltip{
                                display: none;
                            }
                        }

                        .gib-email-input {
                            width: 100%;
                            height: 170px;
                            display: flex;
                            flex-direction: column;
                            border: 1px solid #dee;
                            border-radius: 4px;

                            .generic-input-box {
                                margin-bottom: 0;

                                .gib-inner {
                                    border: none;
                                    border-bottom: 1px solid #dae4ee;
                                    border-radius: 0;
                                    background-color: transparent;
                                }
                            }

                            .gibe-emails {
                                // height: calc(100% - 32px);
                                overflow: auto;
                                display: flex;
                                align-items: flex-start;
                                flex-wrap: wrap;
                                gap: 7px;
                                padding: 10px;

                                .gibee {
                                    width: fit-content;
                                    display: flex;
                                    align-items: center;
                                    background-color: #f1f1f1;
                                    padding: 3px 5px;
                                    border-radius: 25px;

                                    span {
                                        font-size: 12px;
                                        font-weight: 500;
                                        color: $fontBlack;
                                    }

                                    button {
                                        padding-left: 5px;
                                    }
                                }
                            }
                        }

                        #inputwebsite0{
                            padding-left: 0;
                            .website-span{
                                height: 30px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background: #f5f5f5;
                                border-radius: 4px 0 0 4px;
                                padding: 7px 10px;
                                font-weight: 500;
                                font-size: 13px;
                                border-right: 1px solid #dae4ee;
                                margin: 0 5px 0 0;
                            }
                        }
                    }
                }
            }

            .generic-form-body:nth-last-child(1){
                .generic-input-box {
                    .gib-textarea{
                        height: 100px;
                        textarea{
                            height: 98px;
                        }
                    }
                }
                .generic-input-box:nth-last-child(1){
                    margin-right: 0 !important;
                }
                .generic-input-box:nth-last-child(1),
                .generic-input-box:nth-last-child(2),
                .generic-input-box:nth-last-child(3){
                    width: 31%;
                    margin-right: 3.33%;
                    .list-component-section {
                        top: auto;
                        bottom: 36px;
                    }
                }
            }
        }
    }
}

// Workflow Setting
.workflow-setting {
    height: calc(100vh - 225px);
    overflow: auto;
    overflow-x: hidden;

    .ws-search {

        .generic-input-box {
            width: 25%;
            margin: 0;
            position: absolute;
            right: 15px;
            top: 74px;
            .gib-text{
                height: 30px;
                border-radius: 0;
                input{
                    padding-left: 5px;
                }
            }
        }
    }

    .ws-det {
        display: flex;
        justify-content: space-between;
        padding: 10px 10px 10px 20px;
        border-top: 1px solid #dae4ee;
        position: relative;

        .wsd-left {
            width: 65%;
            position: relative;
            h3 {
                color: #0F172A;
                font-size: 13px;
                font-weight: 600;
                margin: 0;
                white-space: nowrap;
            }

            .wsdl-more {
                display: flex;
                align-items: center;
                padding: 5px 0;
                position: relative;

                p {
                    max-width: 600px;
                    color: #67768E;
                    font-size: 11px;
                    font-weight: 400;
                    white-space: nowrap;
                    // text-overflow: ellipsis;
                    // overflow: hidden;
                    margin: 0;
                }

                span {
                    position: relative;
                    color: #111d31;
                    font-weight: 500;
                    font-size: 11px;
                    white-space: nowrap;
                    cursor: pointer;

                    .wsdlm-tooltip {
                        width: 325px;
                        position: absolute;
                        top: -5px;
                        right: -333px;
                        color: #b6c8db;
                        font-size: 12px;
                        font-weight: 500;
                        background-color: #111d31;
                        box-shadow: 0 0 20px -8px rgba(0, 0, 0, .35);
                        border-radius: 10px;
                        padding: 12px;
                        z-index: 9;
                        transition: all .2s;
                        white-space: pre-line;
                        line-height: 1;
                        visibility: hidden;
                        opacity: 0;
                    }
                }

                span:hover {
                    .wsdlm-tooltip {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }

        .wsd-right {
            width: 35%;
            display: flex;
            justify-content: end;
        }

        .wsd-input{
            display: flex;
            align-items: center;
        }
        
        .generic-input-box{
            width: 170px;
            margin: 0 10px 0 0;
            .gib-inner{
                height: 30px;
                border-radius: 0;
            }
            .list-component-section {
                top: 33px;
            }
        }
    }
    .grid{
        .wsd-left{
            width: 100%;
        }
    }
    .ws-det:first-child {
        border-top: none;
    }
    .ws-det:nth-last-child(1){
        border-bottom: 1px solid #dae4ee;
    }
    .ws-child-det{
        width: 100%;
        border: none;
        padding-left: 40px;
        .wsd-left{
            
            .wsdl-timeline{
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background: $primary-color;
                position: absolute;
                top: 6px;
                left: -20px;
            }
            .wsdl-timeline::before{
                content: "";
                width: 1px;
                height: 35px;
                background: #DAE4EE;
                position: absolute;
                top: 10px;
                left: 3px;
            }
        }
    }
}

// Form Setting
.form-setting {
    height: calc(100vh - 225px);
    overflow: auto;

    .fs-top {
        padding: 10px 0 10px 10px;

        .fst-top {
            h3 {
                font-size: 14px;
                font-weight: 600;
                color: #21314b;
                margin: 0;
            }

            p {
                font-size: 12px;
                font-weight: 500;
                color: #67768e;
                font-style: italic;
            }
        }

        .fst-mid {
            display: flex;
            align-items: center;
            padding: 5px 0 0;

            .ant-toggle {
                .ant-switch {
                    width: 50px;
                    background: #fff;
                    box-shadow: 0 3px 6px 0 #d7e1f3;

                    .ant-switch-handle {
                        width: 14px;
                        height: 14px;
                        top: 4px;
                    }

                    .ant-switch-handle::before {
                        background-color: #8496a9;
                        transform: translateX(2px);
                    }
                }

                .ant-switch-checked {
                    background: #fff;

                    .ant-switch-handle::before {
                        background-color: $primary-color;
                    }
                }
            }

            h2 {
                padding: 4px 8px;
                border-radius: 3px;
                background-color: #8496a9;
                color: #fff;
                font-size: 10px;
                font-weight: 600;
                margin: 0 0 0 10px;
            }

            .h2-enabled {
                background-color: $primary-color;
            }
        }

        .fst-bot {
            padding: 10px 0 0;

            .generic-input-box {
                width: 20%;
                margin: 0;

                .gib-label-heading {
                    margin: 0;
                }

                .gib-radioInput {
                    button {
                        margin-right: 20px;
                    }
                }

                .list-component-section {
                    top: 55px;
                }
            }

            .fstb-status {
                padding: 10px 0;

                label {
                    font-size: 11px;
                    font-weight: 600;
                    color: #67768e;
                    margin-bottom: 0;
                }

                p {
                    font-size: 12px;
                    font-weight: 600;
                    color: #21314b;
                    font-style: italic;
                    margin: 0 10px 0 0;
                }

                button {
                    height: 30px;
                    background: #fff !important;
                    color: $primary-color !important;
                }

                button:hover {
                    background: $primary-color !important;
                    color: #fff !important;
                }
            }
        }
    }

    .fs-bot {
        height: 100%;
        .fsb-top {
            padding: 0 10px;

            h3 {
                font-size: 14px;
                font-weight: 600;
                color: #21314b;
                margin-bottom: 3px;
            }

            p {
                font-size: 12px;
                font-weight: 500;
                color: #67768e;
                font-style: italic;
            }
        }
        .fsb-filter{
            display: flex;
            padding: 10px;
            .generic-input-box {
                width: 20%;
                margin: 0 10px 0 0;
                p{
                    font-size: 10px;
                    font-weight: 500;
                    color: #0f172a;
                    white-space: nowrap;
                    margin: 5px 0 0;
                }
                .list-component-section{
                    top: 62px;
                    z-index: 3;
                }
            }
        }

        .fsb-alert{
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            padding: 10px;
            .fsba-msg{
                width: fit-content;
                display: flex;
                align-items: center;
                background: rgba(93, 95, 239, 0.1);
                padding: 10px 15px;
                svg{
                    margin-right: 5px;
                }
                p{
                    color: #0F172A;
                    font-size: 11px;
                    font-weight: 400;
                    margin: 0;
                }
            }
            .fsbf-left{
                width: fit-content;
                position: relative;
                .fsbfl-dropdown {
                    width: auto;
                    min-width: 190px;
                    position: absolute;
                    top: 0px;
                    right: 35px;
                    border-radius: 6px;
                    background-color: #fff;
                    padding: 10px 10px;
                    visibility: hidden;
                    opacity: 0;
                    transition: all .6s;
                    z-index: 10;
                    box-shadow: 2px 3px 6px 0 rgba(0,0,0,.16);
                    label{
                        cursor: default;
                        margin-bottom: 10px;
                    }
                    p {
                        font-size: 12px;
                        font-weight: 500;
                        white-space: nowrap;
                        margin: 0;
                        padding: 3px 0;
                        font-style: italic;
                    }
                }
            }
            .fsbf-left:hover{
                .fsbfl-dropdown {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        .fsb-table{
            max-width: 100%;
            height: calc(100% - 162px);
            overflow: auto;
            margin: 0 10px;
            .gc-config-table{
                table{
                    thead{
                        top: 0;
                    }
                }
            }
        }
    }

    .fs-mode{
        display: flex;
        align-items: center;
        position: absolute;
        right: 15px;
        top: 78px;
        p{
            font-size: 13px;
            font-weight: 500;
            color: #0f172a;
            margin: 0 10px 0 0;
        }
    }
}

// GC Config table
.gc-config-table {

    table {
        width: 100%;

        thead {
            position: sticky;
            top: -1px;
            z-index: 2;
            box-shadow: 2px 0 6px 0 hsla(0, 0%, 55%, .16);
            background-color: #fff;

            tr {
                th {
                    padding: 8px;
                    text-align: left;
                    white-space: nowrap;

                    label {
                        font-weight: 600;
                        color: #000;
                        font-size: 12px;
                        margin: 0;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 0 8px;
                    height: 35px;
                    white-space: nowrap;
                    border: 1px solid #e3e7f3;
                    position: relative;

                    label{
                        font-size: 12px;
                        font-weight: 500;
                        color: #0f172a;
                        margin: 0;
                    }

                    .generic-input-box {
                        min-width: 160px;
                        margin: 0;

                        .gib-inner {
                            height: 28px;
                        }

                        .list-component-section{
                            top: 31px;
                        }
                    }

                    .ant-toggle {
                        .ant-switch {
                            width: 50px;
                            background-color: #efefef;

                            .ant-switch-handle {
                                width: 14px;
                                height: 14px;
                                top: 4px;
                            }

                            .ant-switch-handle::before {
                                background-color: #969fad;
                                transform: translateX(2px);
                            }

                            .ant-switch-inner {
                                span {
                                    font-size: 11px;
                                    font-weight: 600;
                                    color: #12203c;
                                }

                                .ant-switch-inner-checked {
                                    display: none;
                                }

                                .ant-switch-inner-unchecked {
                                    margin-inline-start: -31px;
                                    margin-top: 0;
                                    margin-inline-end: 0;
                                }
                            }
                        }

                        .ant-switch-checked {
                            background-color: #e3fbed;

                            .ant-switch-handle::before {
                                background: #3ee585;
                            }

                            .ant-switch-inner {
                                .ant-switch-inner-checked {
                                    display: block;
                                    margin-inline-start: -7px;
                                }

                                .ant-switch-inner-unchecked {
                                    display: none;
                                }
                            }
                        }

                        .ant-switch-checked:hover:not(.ant-switch-disabled) {
                            background-color: #e3fbed;
                        }
                    }
                }

                td:nth-child(1) {
                    .ant-toggle {
                        .ant-switch-checked {
                            background-color: $primary-light;

                            .ant-switch-handle::before {
                                background: $primary-color;
                            }
                        }
                        .ant-switch:hover{
                            background-color: $primary-light;
                        }
                    }
                }
            }
        }
    }
}

.add-lr-val-modal {
    height: 100%;

    .gen-rowtype-component {
        .grc-top {
            button {
                background-color: #fff;
                border: 1px solid $primary-color;
                color: $primary-color;
                font-weight: 500;

                svg {
                    path {
                        fill: $primary-color;
                    }
                }
            }

            button:hover {
                background-color: $primary-color;
                color: #fff;

                svg {
                    path {
                        fill: #fff;
                    }
                }
            }
        }

        .grc-body {
            .grcb-head {
                width: 100%;

                .gib-label-heading {
                    width: 100%;
                }
            }

            .generic-form-body {
                width: 100%;

                .gfb-multiple-row {
                    .generic-input-box {
                        width: 100%;
                    }
                }
            }
        }
    }
}

// Barcode Profile
.barcode-profile {
    background-color: #fff;
    padding-bottom: 10px;

    .bp-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;

        .ant-radio-group {
            width: fit-content;
            height: 32px;
            display: flex;
            align-items: center;
            background-color: #ebebf3;
            border-radius: 5px;
            padding: 0 3px;

            .ant-radio-button-wrapper {
                height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                border-radius: 3px;
                font-size: 12px;
                font-weight: 600;
                color: #21314b;
                border: none;
                padding: 0 10px;
            }

            .ant-radio-button-wrapper-checked {
                background-color: #fff;
                border: none;
            }

            .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
                display: none;
            }

            .ant-radio-button-wrapper:not(:first-child)::before {
                display: none;
            }
        }

        .bpt-right {
            display: flex;
            align-items: center;

            .bptr-btn {
                display: flex;
                align-items: center;
                color: #575fee;
                font-size: 13px;
                font-weight: 600;

                svg {
                    margin-right: 5px;
                }
            }

            .generic-default-btn {
                height: 30px;
                display: flex;
                align-items: center;
                color: #0F172A !important;
                margin-left: 10px;

                svg {
                    path {
                        fill: #0F172A;
                    }
                }
            }
        }
    }

    .bp-table {
        height: calc(100vh - 230px);
        overflow: auto;

        table {
            width: 100%;

            thead {
                position: sticky;
                top: 0;
                background: #fff;
                z-index: 1;

                tr {
                    th {
                        font-weight: 400;
                        font-size: 12px;
                        color: #67768e;
                        text-align: left;
                        padding: 8px;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        padding: 8px;

                        label {
                            font-weight: 500;
                            font-size: 13px;
                            color: #23235f;
                            margin: 0;
                            white-space: nowrap;
                            max-width: 260px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .label-green,
                        .label-orange {
                            background: #daf8e7;
                            border-radius: 17px;
                            padding: 2px 8px;
                        }

                        .label-orange {
                            background: #fdecc8;
                        }

                        .td-edit {
                            padding: 5px;
                            border-radius: 6px;
                        }

                        .td-edit:hover {
                            // background-color: #f2f1fe;
                            background-color: $primary-w-less-opacity;


                            svg {
                                path {
                                    fill: $primary-color;
                                }
                            }
                        }
                        
                        .map-count{
                            background: #dae4ee;
                            border-radius: 50%;
                            margin-left: 5px;
                            font-size: 10px;
                            font-weight: 500;
                            color: #0f172a;
                            padding: 2px 4px;
                        }
                    }
                }
            }
        }
    }
}

.add-barcode-profile {
    .gsf-body {
        .generic-form-body {
            .gfb-inner {
                .generic-input-box {
                    .gib-label-heading {
                        font-weight: 500;
                        font-size: 12px;
                    }

                    .list-component-section {
                        max-width: 300px;
                    }
                }

                .generic-input-box:nth-child(1) {
                    width: 100%;
                    margin-right: 0;
                }

                .generic-input-box:nth-child(4) {
                    margin-right: 0;
                }

                .generic-input-box:nth-last-child(1) {
                    width: 100%;
                    margin-right: 0;

                    .gib-textarea {
                        height: 90px;

                        textarea {
                            height: 80px;
                        }
                    }
                }
            }
        }

        .abp-bot {
            padding: 0 15px;

            .abpb-table {
                display: flex;
                border: 1px solid #dae4ee;
                margin-bottom: 20px;

                .abpbt-det,
                .abpbt-det2 {
                    width: 50%;
                    border-right: 1px solid #dae4ee;

                    .abpbtd-top {
                        border-bottom: 1px solid #dae4ee;
                        padding: 5px 10px;

                        h3 {
                            color: #2a2a64;
                            font-size: 13px;
                            font-weight: 600;
                            margin: 0;
                        }
                        button:hover {
                            svg {
                                path {
                                    fill: #0056b3;
                                }
                            }
                        }
                    }

                    ul {
                        max-height: 250px;
                        overflow: auto;

                        li {
                            position: relative;
                            cursor: pointer;
                            padding: 7px 10px;

                            label {
                                display: flex;
                                align-items: center;
                                width: 100%;
                                margin: 0;
                                font-size: 12px;
                                font-weight: 500;
                                color: #21314b;
                                cursor: pointer !important;

                                svg {
                                    margin-right: 10px;
                                }
                            }
                            
                            .generic-input-box {
                                margin: 0;
                            }

                            .abpbtdt-drag {
                                position: relative;
                                // top: 10px;
                                // left: 13px;
                                width: 18px;
                                height: 12px;
                                background: #fff;
                                border-top: 2px solid #9ea9ce;
                                border-bottom: 2px solid #9ea9ce;
                                margin-right: 10px;
                            }

                            .abpbtdt-drag::before {
                                content: "";
                                position: absolute;
                                top: 3px;
                                left: 0;
                                width: 100%;
                                height: 2px;
                                background-color: #9ea9ce;
                            }
                        }

                        li:hover {
                            background-color: #f5f5f5;
                        }
                    }
                }

                .abpbt-det2 {
                    border-right: none;

                    ul {
                        li {
                            // padding-left: 45px;
                            cursor: default;

                            label {
                                color: #67768e;
                            }
                        }

                        li:hover {
                            background-color: transparent;
                        }
                    }
                }
            }

            .abpb-check {
                display: flex;
                align-items: center;
                cursor: pointer;

                p {
                    font-weight: 500;
                    font-size: 12px;
                    color: #67768e;
                    margin: 0 0 0 5px;
                }
            }

            .abpb-enable {
                display: flex;
                align-items: center;
                padding-top: 10px;

                p {
                    font-weight: 600;
                    font-size: 13px;
                    color: #21314b;
                    margin: 0 5px 0 0;
                }
            }
        }
    }
}

// General Setting
.general-setting {
    height: calc(100vh - 180px);
    overflow: auto;
    overflow-x: hidden;
    background-color: #fff;
    padding: 0 10px;

    .gs-sec,
    .gs-mid {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        .gss-left {
            width: 50%;

            h3 {
                font-size: 14px;
                color: #111d31;
                font-weight: 600;
                margin-bottom: 3px;
            }

            p {
                color: #67768e;
                font-size: 12px;
                font-weight: 400;
                margin-bottom: 0;
            }

            .ant-radio-group {
                width: fit-content;
                height: 32px;
                display: flex;
                align-items: center;
                background-color: #ebebf3;
                border-radius: 5px;
                padding: 0 3px;
                margin-top: 20px;

                .ant-radio-button-wrapper {
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: transparent;
                    border-radius: 3px;
                    font-size: 12px;
                    font-weight: 600;
                    color: #21314b;
                    border: none;
                    padding: 0 10px;
                }

                .ant-radio-button-wrapper-checked {
                    background-color: #fff;
                    border: none;
                }

                .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
                    display: none;
                }

                .ant-radio-button-wrapper:not(:first-child)::before {
                    display: none;
                }
            }
        }

        .gss-right {
            width: 40%;
            height: 120px;
            display: flex;
            border: 1px solid #dae4ee;

            .gss-logo {
                width: 40%;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;

                .gssl-img {
                    height: 96px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    padding: 18px 20px;
                }

                span {
                    width: 100%;
                    text-align: center;
                    padding: 2px;
                    font-weight: 500;
                    font-size: 11px;
                    color: #67768e;
                    background: #f5f5f5;
                    cursor: pointer;
                }
            }

            .gssr-upload {
                width: 60%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border-left: 1px solid #dae4ee;
                position: relative;

                input {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }

                .gssru-icon {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    background-color: #f7f8f9;
                }

                label {
                    color: #111d31;
                    font-size: 13px;
                    font-weight: 600;
                    margin: 3px 0 0;
                }

                p {
                    color: #67768e;
                    font-size: 11px;
                    font-weight: 400;
                    margin: 0;
                }
            }
        }
    }

    .gs-mid {
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: 0;
        border-top: solid 1px #dae4ee;

        .gss-left {
            width: auto;
        }

        .generic-input-box {
            margin-bottom: 0;
        }

        .gsm-input {
            display: flex;
            align-items: flex-start;
            padding-left: 50px;

            .generic-input-box {
                padding-right: 10px;
                margin: 0;

                .gib-inner {
                    padding: 0;

                    p {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        font-weight: 500;
                        font-size: 13px;
                        background: #f5f5f5;
                        border-radius: 4px 0 0 4px;
                        padding: 0 10px;
                        margin: 0;
                    }

                    input {
                        padding-left: 5px;
                    }

                    .cursor-pointer {
                        height: 100%;

                        p {
                            border-radius: 0 4px 4px 0;
                        }
                    }
                }
            }

            .gsm-link {
                display: flex;
                align-items: center;
                padding-top: 5px;

                a {
                    color: #2681ff;
                    font-size: 12px;
                    font-weight: 500;
                }
            }

            .gsmi-check {
                display: flex;
                align-items: center;

                svg {
                    margin-right: 7px;
                }

                p {
                    font-weight: 500;
                    font-size: 12px;
                    color: #111d31;
                    margin: 0;
                    white-space: nowrap;
                }
            }
        }
        .gsm-right{
            .generic-approve-btn {
                background: #111d31 !important;
                border: none !important;
            }
        }

        .gsm-note {
            background-color: #E7F1FF;
            display: flex;
            align-items: center;
            padding: 10px 15px;
            svg {
                flex: 0 0 22px;
            }
            p, h3 {
                color: #4D719C;
                font-weight: 400;
                font-size: 11px;
                margin: 0;
            }
            h3 {
                font-size: 16px;
                margin: 0;
                font-weight: 600;
            }
        }
    }

    .gs-ip-restriction{
        padding: 20px 0 20px;
        border-top: solid 1px #dae4ee;
        .gsi-head{
            h3 {
                font-size: 14px;
                color: #111d31;
                font-weight: 600;
                margin-bottom: 3px;
            }

            p {
                color: #67768e;
                font-size: 12px;
                font-weight: 400;
                margin: 0;
            }
        }
        .gsi-date-range, .gsi-selected-date-range{
            display: flex;
            align-items: flex-end;
            padding-top: 15px;
            .gsid-det{
                position: relative;
                padding-right: 30px;
                margin-right: 20px;
                p{
                    font-size: 11px;
                    font-weight: 400;
                    color: rgba(132, 150, 169, 1);
                    margin-bottom: 3px;
                }
                .gsidd-input{    
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    border: 1px solid rgba(218, 228, 238, 1);
                    border-radius: 3px;
                    .gsiddi{
                        position: relative;
                        input{
                            width: 55px;
                            height: 35px;
                            font-size: 13px;
                            font-weight: 600;
                            color: rgba(15, 23, 42, 1);
                            padding: 0 15px;
                            background-color: transparent;
                        }
                        input::placeholder{
                            font-size: 13px;
                            font-weight: 400;
                            color: rgba(15, 23, 42, 1) !important;
                        }
                        input[disabled]{
                            background-color: #f1f1f1;
                        }
                    }
                    .gsiddi::before{
                        content: "";
                        width: 3px;
                        height: 3px;
                        border-radius: 50%;
                        position: absolute;
                        right: 0;
                        top: 15px;
                        background: rgba(217, 217, 217, 1);
                    }
                    .gsiddi:nth-last-child(1)::before{
                        display: none;
                    }
                }
                span{
                    position: absolute;
                    right: 0;
                    top: 22px;
                    color: #000;
                    font-size: 20px;
                    font-weight: 600;
                }
            }
            .generic-approve-btn {
                height: 35px;
                display: flex;
                align-items: center;
            }
            .gsid-del-btn{
                padding: 5px;
                border-radius: 3px;
            }
            .gsid-del-btn:hover{
                background-color: rgba(255, 199, 208, 1);
                svg{
                    path{
                        fill: rgba(253, 55, 87, 1);
                    }
                }
            }
        }

        .gsi-selected-date-range{
            align-items: center;
            .gsid-det{
                span{
                    top: 4px;
                }
            }
        }
    }
}
.authentication-confirmation-modal{
    height: 100%;
    .acm-head{
        padding: 10px 15px;
        h3{
            color: #0F172A;
            font-size: 18px;
            font-weight: 600;
            margin: 0 0 0 5px;
        }
        p{
            color: #71819B;
            font-size: 12px;
            font-weight: 400;
            margin: 0;
        }
    }
    .acm-body{
        height: calc(100% - 215px);
        overflow: auto;
        padding: 0 15px;
        .generic-input-box {
            margin: 0;
            padding-bottom: 20px;
            label{
                color: #0F172A;
                font-size: 13px;
                font-weight: 600;
            }
            .gib-inner{
                border-radius: 0;
            }
        }
        .acmb-note{
            display: flex;
            align-items: center;
            background: #E7F1FF;
            padding: 10px;
            p{
                color: #0F172A;
                font-size: 11px;
                font-weight: 500;
                margin: 0 0 0 5px;
                white-space: nowrap;
                max-width: 90%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .acm-footer{
        padding: 10px 15px;
        button{
            width: 100%;
        }
        .generic-approve-btn{
            margin-bottom: 10px;
        }
    }
}

// System Maintenance
.system-maintenance {
    height: calc(100vh - 180px);
    overflow: auto;
    overflow-x: hidden;

    .sm-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;

        p {
            color: #67768e;
            font-size: 11px;
            font-weight: 400;
        }

        .smt-msg {
            color: #0f172a;
            font-size: 13px;
            font-weight: 400;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            overflow: hidden;
            width: 625px;
            margin: 0;
        }
    }

    .sm-bot {
        width: fit-content;
        display: flex;
        align-items: center;
        border-radius: 60px;
        border: 1px solid #eaedf8;
        padding: 3px 5px;
        margin: 0 10px 15px;

        .ant-toggle {
            .ant-switch {
                width: 28px;
                min-width: auto;
                height: 18px;

                .ant-switch-handle {
                    width: 12px;
                    height: 12px;
                }
            }

            .ant-switch-checked {
                .ant-switch-handle::before {
                    transform: translateX(5px);
                }
            }
        }

        label {
            color: #0f172a;
            font-size: 11px;
            font-weight: 500;
            margin: 0 0 0 5px;
        }
    }

    .generic-input-box {
        padding: 0 10px;
        margin: 0;

        .gib-textarea {
            height: 100px;
            .generic-tooltip{
                display: none;
            }
        }
    }
}

// Data Setting
.data-setting {
    height: calc(100vh - 225px);
    overflow: auto;
    overflow-x: hidden;

    .ds-sec {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 0 0 10px;

        .dss-box {
            min-height: 150px;
            border-radius: 12px;
            border: 1px solid #dce6f0;
            padding: 10px 15px;

            .dssb-top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 10px;

                h3 {
                    color: #12203c;
                    font-size: 14px;
                    font-weight: 600;
                    margin: 0;
                }

                button {
                    padding: 5px;
                    border-radius: 6px;
                }

                button:hover {
                    // background-color: #f2f1fe;
                    background-color: $primary-w-less-opacity;

                    svg {
                        path {
                            fill: $primary-color;
                        }
                    }
                }
            }

            .dssb-bot {
                label {
                    color: #0F172A;
                    font-size: 11px;
                    font-weight: 600;
                    margin: 0;
                }

                p {
                    font-size: 11px;
                    font-weight: 500;
                    color: #97abbf;
                    margin: 0;
                }
            }
        }

        .w-33:nth-child(3),
        .w-33:nth-child(6) {
            padding-right: 0 !important;
        }

        .w-33:nth-last-child(1) {
            .dss-box {
                .dssb-bot {
                    label {
                        font-size: 12px;
                        margin-bottom: 10px;
                    }

                    .ant-radio-group {
                        .ant-radio-button-wrapper-checked {
                            border-color: $primary-color;
                            color: $primary-color;
                        }
                    }
                }
            }
        }
    }

    .ds-vend-det {
        padding: 10px 10px 20px;

        h3 {
            font-size: 14px;
            font-weight: 600;
            color: #21314b;
        }

        .generic-input-box {
            margin: 0;
            padding-right: 10px;

            .gib-label-heading {
                font-size: 12px;
            }

            .gib-textarea {
                height: 100px;
            }
        }

        .generic-input-box:nth-last-child(1) {
            padding-right: 0;
        }
    }
}

// Digicat Data Settings
.digicat-data-setting {
    .create-cost-sheet {
        .cc-body {
            .gen-rowtype-component {
                .grcb-head {
                    padding: 10px 0 10px 77px;
                }
                .grc-body {
                    overflow: visible;
                    height: auto;
                    .generic-form-body {
                        .gfb-inner {
                            padding: 10px 0 10px 10px;
                            .gfbi-btns{
                                display: flex;
                                align-items: center;
                                button{
                                    padding: 5px;
                                    border-radius: 4px;
                                    margin-right: 5px;
                                }
                                button:hover{
                                    // background-color: #f2f1fe;
                                    background-color: $primary-w-less-opacity;

                                    svg{
                                        path{
                                            fill: $primary-color;
                                        }
                                    }
                                }
                                button:nth-last-child(1):hover{
                                    background-color: #fdf2f7;
                                    svg{
                                        path{
                                            fill: #fc5c65;
                                        }
                                    }
                                }
                            }
                            .generic-input-box{
                                width: 150px;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

// ARS Mapping Filter
.ars-mapping-filter {
    height: 100%;

    .amf-body {
        height: calc(100% - 116px);
        overflow: auto;

        .amfsb-det {
            width: 50%;
            padding: 0 20px;

            .amfsbd-top {
                height: 98px;
                padding: 10px 0;

                h3 {
                    font-size: 14px;
                    font-weight: 600;
                    color: #12203c;
                    margin: 0;
                }

                p {
                    font-size: 11px;
                    font-weight: 500;
                    color: #a4b9dd;
                }

                .generic-input-box {
                    margin: 0;
                }
            }

            .amfsbd-table {
                width: 100%;
                height: calc(100% - 115px);
                overflow: auto;

                table {
                    width: 100%;

                    tbody {
                        tr {
                            td {
                                font-size: 12px;
                                font-weight: 500;
                                color: #21314b;
                                cursor: pointer;
                                padding: 5px;
                                position: relative;
                                vertical-align: middle;

                                .td-drag {
                                    position: absolute;
                                    top: 15px;
                                    left: 0;
                                    width: 18px;
                                    height: 12px;
                                    background: #fff;
                                    border-top: 2px solid #9ea9ce;
                                    border-bottom: 2px solid #9ea9ce;
                                }

                                .td-drag::before {
                                    content: "";
                                    position: absolute;
                                    top: 3px;
                                    left: 0;
                                    width: 100%;
                                    height: 2px;
                                    background-color: #9ea9ce;
                                }

                                .td-del {
                                    padding: 5px;
                                    border-radius: 6px;
                                }
                            }
                        }
                    }
                }

                .table-1 {
                    tbody {
                        tr {
                            td:nth-child(1) {
                                width: 50px;
                            }
                        }

                        tr:hover {
                            background-color: #f5f5f5;
                        }
                    }
                }
            }
        }
    }
}

// Master Data Configuration Table
.master-data-configuration{
    height: calc(100vh - 223px);
    padding: 10px 10px 0;
    .mdc-choose-level{
        display: flex;
        justify-content: end;
        .generic-input-box {
            width: 36%;
            display: flex;
            align-items: center;
            border: 1px solid #dae4ee;

            label{
                width: fit-content;
                height: 32px;
                display: flex;
                align-items: center;
                color: #7e8084;
                font-size: 12px;
                font-weight: 400;
                margin: 0;
                white-space: nowrap;
                padding-left: 5px;
            }
            .gib-inner{
                border: none;
                border-radius: 0;
                input{
                    color: #0f172a;
                }
            }
            .gib-inner:hover{
                background-color: #fff;
                input{
                    background-color: #fff;
                }
            }
            .list-component-section {
                top: 36px;
                z-index: 4;
            }
        }
        .generic-input-box:hover{
            background-color: #f5f5f5;
            .gib-inner{
                background-color: #f5f5f5;
                input{
                    background-color: #f5f5f5;
                }
            }
        }
    }
    .master-data-table {
        height: 100%;
        overflow: auto;
    
        table {
            width: 100%;
    
            thead {
                position: sticky;
                top: 0;
                background: #f8f8f8;
                z-index: 3;
                box-shadow: 2px 0 6px 0 hsla(0, 0%, 55%, .16);
    
                tr {
                    th {
                        color: #67768e;
                        font-size: 12px;
                        font-weight: 600;
                        padding: 8px;
                        text-align: left;
                        border-bottom: 1px solid #dae4ee;
                        border-right: 1px solid #dae4ee;
                    }
    
                    th:nth-last-child(1) {
                        border-right: none;
                    }
                }
            }
    
            tbody {
                tr {
                    td {
                        font-weight: 500;
                        color: #000;
                        font-size: 12px;
                        padding: 12px 8px;
                        border-bottom: 1px solid #dae4ee;
                        border-right: 1px solid #dae4ee;
    
                        .generic-input-box {
                            margin: 0;
    
                            .gib-inner {
                                height: 30px;
                            }
    
                            .list-component-section {
                                top: 35px;
                            }
                        }
                    }
    
                    td:nth-last-child(1) {
                        border-right: none;
                    }
                }
                tr:nth-last-child(1),
                tr:nth-last-child(2),
                tr:nth-last-child(3),
                tr:nth-last-child(4),
                tr:nth-last-child(5) {
                    td{
                        .generic-input-box {
                            .list-component-section {
                                bottom: 35px;
                                top: auto;
                            }
                        }
                    }
                }
            }
        }
    }
    .mdt-scroll{
        height: calc(100% - 44px);
    }
}

// Data Management
.data-management-table {
    height: calc(100vh - 233px);
    overflow: auto;
    margin: 10px 10px 0;

    table {
        width: 100%;

        thead {
            position: sticky;
            top: 0;
            z-index: 1;
            background: #f8f8f8;

            tr {
                th {
                    color: #67768e;
                    font-size: 12px;
                    font-weight: 600;
                    text-align: left;
                    border-bottom: 1px solid #dae4ee;
                    border-right: 1px solid #dae4ee;
                    padding: 8px;
                }

                th:nth-last-child(1) {
                    border-right: none;
                }
            }
        }

        tbody {
            tr {
                td {
                    border-bottom: 1px solid #dae4ee;
                    border-right: 1px solid #dae4ee;
                    padding: 3px 8px;

                    h2 {
                        color: #0f172a;
                        font-size: 13px;
                        font-weight: 500;
                        margin: 0;
                    }

                    p {
                        color: #67768e;
                        font-size: 12px;
                        font-weight: 400;
                        margin: 0;
                    }

                    span {
                        color: #fff;
                        font-size: 12px;
                        font-weight: 600;
                        border-radius: 3px;
                        background-color: #116fff;
                        padding: 3px 7px;
                        margin-right: 5px;
                    }

                    .generic-approve-btn {
                        background: #fff !important;
                        color: $primary-color !important;
                    }

                    .generic-approve-btn:hover {
                        background: $primary-color !important;
                        color: #fff !important;
                    }

                    .generic-approve-btn[disabled]:hover {
                        background: #fff !important;
                        color: $primary-color !important;
                    }
                }

                td:nth-last-child(1) {
                    border-right: none;
                }
            }
        }
    }
}

// training guide body
.training-guide-body {
    height: calc(100vh - 200px) !important;
}

// Training Guide
.training-guide {
    height: calc(100vh - 200px);
    overflow: auto;

    .tg-top {
        display: flex;
        position: absolute !important;
        right: 10px;
        top: 21px;
        align-items: center;
        button {
            height: 30px;
            display: flex;
            align-items: center;
            border-radius: 0;
        }
        .generic-input-box {
            width: 150px;
            margin: 0;
            margin-right: 15px;
            .gib-text{
                height: 30px;
                border-radius: 0;
                input{
                    padding-left: 5px;
                }
            }
        }
    }

    .tg-det {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;
        background: #fff;
        padding: 10px;
        margin: 10px 15px 0;
        box-shadow: 0 0 16px -2px hsla(0, 0%, 64%, .25);

        .tgd-icon {
            width: auto;
            // min-width: 40px;
        }

        label {
            width: auto;
            min-width: 260px;
            max-width: 260px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: #0f172a;
            font-size: 13px;
            font-weight: 500;
            margin: 0;
            margin-right: 20px;
        }

        .tgd-link {
            // width: 100%;
            // display: flex;
            // align-items: center;
            // justify-content: space-between;

            .tgdl-left {
                display: flex;
                align-items: center;

                label {
                    margin: 0;
                }

                button {
                    padding: 3px;
                    border-radius: 4px;
                }

                button:hover {
                    background-color: #2969FF;

                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }
            }

            .tgdl-right {
                display: flex;
                align-items: center;

                button {
                    border-radius: 4px;
                    padding: 5px;
                    margin-left: 10px;
                }

                button:nth-child(1):hover {
                    // background-color: #f2f1fe;
                    background-color: $primary-w-less-opacity;


                    svg {
                        path {
                            fill: $primary-color;
                        }
                    }
                }

                button:nth-child(2):hover {
                    background-color: #fdf2f7;

                    svg {
                        path {
                            fill: #fc5c65;
                        }
                    }
                }
            }
        }
    }

    .generic-tabs{
        padding-inline: 10px;
        // .gt-list{
        //     padding-inline: 2rem;
        //     font-weight: 600;
        // }
        .activeTab{
            color: $primary-color;
            border-block-color: $primary-color;
        }
    }

    .training-guide-modal {
        .gsf-body {
            .generic-form-body {
                .gfb-inner {
                    display: grid;

                    .generic-input-box {
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 10px;

                        .gib-label-heading {
                            font-size: 11px;
                            font-weight: 400;
                            color: #71819B;
                        }

                        .gib-text {
                            padding-left: 0;

                            span {
                                height: 100%;
                                display: flex;
                                align-items: center;
                                background-color: #f8f8f8;
                                padding: 0 10px;
                                border-radius: 4px 0 0 4px;
                            }

                            input {
                                padding-left: 5px;
                            }
                        }

                        .gib-textarea {
                            height: 100px;
                        }

                        .gib-max {
                            display: flex;
                            justify-content: end;

                            p {
                                color: #71819B;
                                font-size: 11px;
                                font-weight: 400;
                                margin: 3px 0 0;
                            }
                        }

                        .generic-tooltip{
                            background-color: #000 !important;
                            border-radius: 5px !important;
                        }
                    }
                }
            }
        }
    }
}

// Announcement
.announcement-tab {
    height: calc(100vh - 180px);
    overflow: auto;

    .at-btn {
        button {
            height: 30px;
            display: flex;
            align-items: center;
            position: absolute !important;
            top: 13px;
            right: 10px;
            border-radius: 0 !important;

            svg {
                margin-right: 5px;
            }
        }
    }

    .at-det {
        display: flex;
        justify-content: space-between;
        margin: 10px 10px 0;
        padding: 10px;
        box-shadow: 0 0 16px 0 rgba(164, 164, 164, .25);

        .atd-content {
            margin-left: 12px;

            label {
                display: flex;
                align-items: center;
                color: #67768e;
                font-size: 10px;
                font-weight: 400;
                margin-bottom: 3px;

                span {
                    border-radius: 40px;
                    background: #03b675;
                    color: #fff;
                    font-size: 10px;
                    font-weight: 600;
                    padding: 0 5px;
                    margin-left: 5px;
                }
            }

            p {
                display: flex;
                align-items: center;
                color: #4a5d7a;
                font-size: 12px;
                font-weight: 500;
                margin: 0;
            }

            .antdc-msg {
                color: #0f172a;
                font-size: 13px;
                font-weight: 600;
                -webkit-line-clamp: 2;
                overflow: hidden;
                width: 625px;
            }
        }

        .atd-right {
            display: flex;
            flex-direction: column;
            align-items: end;

            .atdr-btns {
                display: flex;
                align-items: center;
                padding-top: 20px;

                button {
                    border-radius: 4px;
                    padding: 5px;
                    margin-left: 10px;
                }

                

                button:hover {
                    // background-color: #f2f1fe;
                    background-color: $primary-w-less-opacity;

                    svg {
                        path {
                            fill: $primary-color;
                        }
                    }
                }

                button:last-child:hover {
                    background-color: #fdf2f7;

                    svg {
                        path {
                            fill: #fc5c65;
                        }
                    }
                }
            }
        }
    }

    .new-announcement{
        height: 100%;
        .modal-header-section{
            border-bottom: 1px solid rgba(218, 228, 238, 1);
            .ant-radio-group {
                height: 30px;
                display: flex;
                align-items: center;
                background-color: #ebebf3;
                border-radius: 0;
                padding: 0 3px;
                position: absolute;
                left: 210px;
                top: 12px;

                .ant-radio-button-wrapper {
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: transparent;
                    border-radius: 0;
                    font-size: 12px;
                    font-weight: 600;
                    color: #21314b;
                    border: none;
                    padding: 0 10px;
                }

                .ant-radio-button-wrapper-checked {
                    background-color: #fff;
                    border: none;
                }

                .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
                    display: none;
                }

                .ant-radio-button-wrapper:not(:first-child)::before {
                    display: none;
                }
            }
        }
        .na-body{
            height: calc(100% - 55px);
            display: flex;
            .nab-left {
                width: 50%;
                border-right: 1px solid rgba(218, 228, 238, 1);
                
                .gsf-body {
                    height: calc(100% - 55px);
                    .generic-form-body{
                        padding: 0 15px;
                        .gfb-inner{
                            .generic-input-box {
                                width: 100%;
                                margin: 10px 0 0;
                                .gib-label-heading{
                                    color: #4A5D7A;
                                    font-weight: 600;
                                }
                                .gib-textarea {
                                    height: 100px;
                                }
                                .gib-upload{
                                    background-color: #fff;
                                    border: 1px dashed #dae4ee;
                                    padding: 5px;
                                    .gib-upload{
                                        border: none;
                                        .uembri-uploaded-text {
                                            margin: 0;
                                            background-color: white;
                                            color: $fontBlack;
                                            padding: 0;
                                            font-weight: 600;
                                            font-size: 12px;
                                        }
                                        .gibu-sel{
                                            display: flex;
                                            align-items: center;
                                            justify-content: start;
                                            svg{
                                                margin-right: 7px;
                                                transform: rotate(90deg);
                                            }
                                            p{
                                                color: #0F172A;
                                                font-size: 11px;
                                                font-weight: 500;
                                                margin: 0;
                                            }
                                        }
                                    }
                                    .gib-upload>div{
                                        justify-content: start;
                                    }
                                }
                            }
                            .addUsersInput{
                                .mandatory{
                                    left: 55px !important;
                                }
                            }
                        }
                    }
                }
            }
            .nab-right{
                width: 50%;
                height: 100%;
                overflow: auto;
                padding: 10px 0;
                h2{
                    color: #0F172A;
                    font-size: 16px;
                    font-weight: 600;
                    margin: 0 15px;
                }
                .nabr-preview{
                    height: calc(100% - 24px);
                    padding: 10px 15px;
                    .nabrp-tab{
                        width: 100%;
                        height: 100%;
                        .nabrpt-top{
                            padding: 10px 15px;
                            background-color: #DCDEDF;
                            border-radius: 20px 20px 0 0;
                        }
                        .nabrpt-body{
                            height: calc(100% - 30px);
                            overflow: auto;
                            background-color: rgba(0, 0, 0, 0.70);
                            border-radius: 0 0 20px 20px; 
                            padding: 12%;
                            .announcement-preview{
                                .ap-body{
                                    max-height: auto;
                                }
                            }
                        }
                    }
                }
            }
            .generic-default-btn{
                border-radius: 0;
                background-color: white !important;
                border: 1px solid #DAE4EE !important;
                font-weight: 600 !important;
                color: $fontBlack !important;
            }
            .generic-approve-btn,.generic-input-box .gib-inner{
                border-radius: 0;
            }
            .generic-input-box .gib-sliderInput button{
                border-radius: 0;
            }
            .generic-default-btn:hover{
                opacity: .75;
            }
        }
        .announcementTabs{
            // .gt-list{
            //     padding-inline: 1rem;
            //     color: #4A5D7A;
            //     font-weight: 600;
            // } 
            padding-inline: 12px;
            .activeTab{
                color: $primary-color;
                border-bottom-color: $primary-color;
            }
        }
        .wechatIcon{
            path{
                fill: black;
            }
        }
        .wechatIconActive{
            path{
                fill: $primary-color;
            }
        }
    }

    .at-no-announcements{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .atn-content{
            width: 50%;
            display: flex;
            align-items: center;
            flex-direction: column;
            h1{
                color: #0F172A;
                font-size: 20px;
                font-weight: 600;
                margin: 30px 0 15px;
            }
            p{
                color: #575D6A;
                font-size: 13px;
                font-weight: 400;
                margin: 0 0 20px;
                text-align: center;
            }
            button{
                display: flex;
                align-items: center;
                border-radius: 0;
                svg{
                    margin-right: 5px;
                }
            }
        }
    }
}

// Announcement Preview
.announcement-preview{
    height: auto;
    background-color: #fff;
    .api-expired{
        width: 100%;
        display: flex;
        align-items: center;
        padding: 10px 15px;
        background: linear-gradient(0deg, rgba(3, 182, 117, 0.10) 0%, rgba(3, 182, 117, 0.10) 100%), linear-gradient(96deg, #CB2D3E 0.42%, #EF473A 100%);
        h1{
            color: #FFF;
            font-size: 25px;
            font-weight: 700;
            margin: 0;
        }
        p{
            color: #FFF;
            font-size: 14px;
            font-weight: 400;
            margin: 0;
            b{
                font-weight: 700;
            }
        }
    }
    .ap-img{
        height: auto;
        padding: 5px;
        img{
            width: 100%;
            height: 100%;
        }
        .api-no-img{
            height: 125px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: linear-gradient(0deg, rgba(3, 182, 117, 0.10) 0%, rgba(3, 182, 117, 0.10) 100%), linear-gradient(96deg, #BCF4AB 0.42%, #BDEAF7 100%);
            p{
                color: #3D6B4A;
                font-size: 14px;
                font-weight: 500;
                margin: 0;
            }
        }
    }
    .ap-body{
        height: auto;
        max-height: calc(100% - 220px);
        overflow: auto;
        padding: 0 15px 10px;
        .apb-head, .apb-para{
            padding-top: 10px;
            label{
                color: #0F172A;
                font-size: 11px;
                font-weight: 700;
                margin-bottom: 3px;
            }
            p{
                max-width: 100%;
                overflow-wrap: break-word;
                color: #67768E;
                font-size: 10px;
                font-weight: 500;
                margin: 0;
            }
        }
        .apb-para{
            label{
                font-size: 10px;
                font-weight: 600;
            }
            p{
                font-weight: 400;
            }
        }
        .apb-read-more{
            padding-top: 15px;
            button{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 5px;
                background: #F7F8FB;
                padding: 7px 10px;
                label{
                    color: #598988;
                    font-size: 12px;
                    font-weight: 500;
                    margin: 0 0 0 5px;
                    cursor: pointer;
                }
            }
        }
        .apb-subscription-det{
            h2{
                color: #0F172A;
                font-size: 16px;
                font-weight: 600;
                margin: 0;
            }
            .apbs-current, .apbs-grace{
                display: flex;
                align-items: start;
                border-radius: 5px;
                background: rgba(116, 118, 122, 0.10);
                padding: 10px 15px;
                margin-top: 15px;
                h1{
                    color: #74767A;
                    font-size: 11px;
                    font-weight: 500;
                    margin: 0;
                }
                p{
                    color: #0F172A;
                    font-size: 14px;
                    font-weight: 500;
                    margin: 0;
                }
                span{
                    color: #74767A;
                    font-size: 10px;
                    font-weight: 400;
                }
            }
            .apbs-grace{
                background: rgba(3, 182, 117, 0.10);
                svg{
                    g{
                        path{
                            stroke: #275756;
                        }
                    }
                }
                h1{
                    color: #598988;
                }
                p{
                    color: #275756;
                }
                span{
                    color: #598988;
                }
            }
        }
    }
    .apb-content-body{
        height: calc(100% - 274px) !important;
    }
    .ap-footer, .ap-sub-footer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #EBEBEB;
        padding: 10px 15px;
        button{
            height: 35px;
            font-weight: 500;
            font-size: 13px !important;
            border-radius: 0 !important;
            white-space: nowrap;
        }
        .generic-approve-btn{
            background-color: #0F172A !important;
            border: none !important;
        }
        .generic-approve-btn:hover{
            opacity: 0.9;
        }
        .generic-approve-btn:disabled:hover{
            background-color: #0F172A !important;
            border: none !important;
            opacity: 0.4;
        }
        .generic-default-btn{
            background:  #fff !important;
            color: #0f172a !important;
            border: 1px solid #0f172a !important;
            margin-right: 10px;
        }
        .generic-default-btn:hover{
            background: #0f172a !important;
            color: #fff !important;
        }
        .generic-default-btn:disabled:hover{
            background:  #fff !important;
            color: #0f172a !important;
            opacity: 0.4;
        }
        p{
            display: flex;
            align-items: center;
            margin: 0;
            span{
                font-size: 11px;
                font-weight: 600;
                color: #0f172a;
                margin-right: 3px;
            }
        }
    }
    .ap-sub-footer{
        justify-content: start;
        button{
            height: 35px;
            font-size: 12px !important;
        }
        .generic-default-btn {
            width: 100%;
            margin-right: 0;
        }
    }
}

.initial-announcement{
    position: sticky;
    top: 0;
    z-index: 99;
    .announcement-preview{
        .ap-body{
            height: calc(100% - 223px);
            .apb-para{
                label{
                    color: #74767A;
                    font-size: 12px;
                    font-weight: 400;
                }
                p{
                    color: #0F172A;
                    font-weight: 500;
                    font-size: 13px;
                }
            }
        }
        .ap-p{
            color: #74767A;
            font-size: 12px;
            font-weight: 400;
            margin: 5px 15px 10px;
        }
    }
}

// 
.announcement-modal-main {
    // transform: translate(-50%, -50%);
    // inset: 50% auto auto 50%;
    overflow: auto;
    max-height: 100%;
    .announcement-preview{
        .ap-body{
            height: auto;
            overflow: inherit;
            .apb-para{
                label{
                    color: #74767A;
                    font-size: 12px;
                    font-weight: 400;
                }
                p{
                    color: #0F172A;
                    font-weight: 500;
                    font-size: 13px;
                }
            }
        }
        .ap-p{
            color: #74767A;
            font-size: 12px;
            font-weight: 400;
            margin: 5px 15px 10px;
        }
    }
}

.announcement-strip{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(180deg, #CB2D3E 0%, #EF473A 234.2%);
    padding: 3px 15px;
    position: sticky;
    top: 0;
    z-index: 99;

    .as-left{
        width: 90%;
        display: flex;
        align-items: center;
        svg{
            g{
                path{
                    fill: #67768E;
                }
                path:nth-child(1){
                    fill: #fff !important;
                }
            }
        }
        p{
            width: auto;
            max-width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #FFF;
            font-size: 12px;
            font-weight: 500;
            margin: 0 10px 0;
        }
        button{
            color: #FFF;
            font-size: 12px;
            font-weight: 700;
        }
        button:hover{
            text-decoration: underline;
        }
    }
    .as-right{
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: end;
        button{
            color: #fff;
            font-size: 12px;
            font-weight: 500;
            border-radius: 20px;
            background: rgba(255, 255, 255, 0.21);
            padding: 3px 7px;
        }
        button:hover{
            opacity: 0.9;
        }
        .next-btn{
            color: #D6353D;
            background: #FFF;
        }
        p{
            margin: 0 10px;
            span{
                color: #FFF;
                font-size: 12px;
                font-weight: 600;
            }
        }
    }
}

// Multilevel Approval
.multilevel-approval {
    height: calc(100vh - 225px);
    overflow: auto;

    .gen-rowtype-component {
        .grc-top {
            position: sticky;
            top: 0;
            background: #fff;
            z-index: 1;
            padding-top: 15px;

            button {
                color: $primary-color;
                background-color: #fff;
                border-color: $primary-color;

                svg {
                    path {
                        fill: $primary-color;
                    }
                }
            }

            button:hover {
                color: #fff;
                background-color: $primary-color;

                svg {
                    path {
                        fill: #fff;
                    }
                }
            }
        }

        .grc-body {
            height: calc(100% - 60px);
        }
    }
}

// Replenishment Setting
.replenishment-setting {
    height: calc(100vh - 225px);
    overflow: auto;
    background-color: #fff;

    .table-component-new-style {
        background: #fff;

        .select-tab-head {
            top: 0;
            padding: 0 20px;
        }

        .tableBody-border {
            background: #fff;

            .imh-update {
                padding: 0;
                background: #fff;
            }

            .invoice-manage-table {
                background: #fff;
                padding: 10px 0 0 10px;

                table {
                    tbody {
                        tr {
                            td {
                                .td-select{
                                    height: 30px;
                                    padding: 0 5px;
                                    margin: 5px 0;
                                }
                            }

                            .fix-action-btn {
                                .table-item-list {
                                    box-shadow: none;
                                    padding: 5px 8px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .rs-top {
        width: 50%;
        display: flex;
        align-items: end;
        padding: 10px 15px;
        position: relative;
        z-index: 6;

        .generic-input-box {
            padding-right: 15px;
            margin: 0;

            .gib-label-heading {
                font-weight: 500;
                font-size: 13px;
                margin-bottom: 3px;
            }

            .gib-dropdown {
                p {
                    font-weight: 600;
                    font-size: 12px;
                    color: #71819b;
                    border: 1px solid #b7c6de;
                    border-radius: 3px;
                    padding: 0 5px;
                    margin: 0 5px 0 0;
                }
            }

            .list-component-section {
                top: 64px;
            }
        }

        .rst-btn{
            position: absolute;
            right: 10px;
            top: 59px;
            button{
                height: 28px;
                border-radius: 0;
                font-size: 12px;
            }
        }
    }

    .rs-bot {
        padding-top: 10px;
        height: auto;

        .lcs-inner {
            .list-component-normal-view, .list-component-table-view {
                .lcv-table-head {
                    padding-right: 10px;
                    .lcvth-row {
                        // display: flex;

                        li {
                            width: 33.33%;
                            padding: 8px 0 8px 15px;
                            border-right: 1px solid #dee;
                            border-top: 1px solid #dee;
                            border-bottom: 1px solid #dee;

                            label {
                                margin: 0;
                                font-weight: 500;
                                font-size: 13px !important;
                                color: #23235f;
                            }
                        }

                        li:nth-last-child(1) {
                            border-right: none;
                        }
                    }
                }

                .modal-data-list-render {
                    height: calc(100% - 105px);

                    li {
                        padding: 0;

                        .mdlr-col {
                            height: 45px;
                            padding: 8px 8px 8px 15px;
                            border-right: 1px solid #dee;
                            border-bottom: 1px solid #dee;
                            border-top: none;

                            .mdlr-multiple-row {
                                font-weight: 500;
                                font-size: 13px;
                                color: #67768e;
                                white-space: nowrap;
                                max-width: 300px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            .mdlrc-manage {
                                display: flex;
                                align-items: center;
                                justify-content: end;

                                .del-btn {
                                    padding: 5px;
                                    border-radius: 4px;
                                }

                                .del-btn:hover {
                                    background-color: #fdf2f7;

                                    svg {
                                        path {
                                            fill: #fc5c65;
                                        }
                                    }
                                }

                                .generic-default-btn {
                                    margin: 0 15px 0 10px;
                                }
                            }
                        }

                        .mdlr-col:nth-last-child(1) {
                            border-right: none;
                        }

                        .mdlr-col:nth-child(1), .mdlr-col:nth-child(2) {
                            span {
                                font-weight: 500;
                                font-size: 13px;
                                color: #67768e;
                                word-break: break-all;
                            }

                            .default-asrt {
                                width: fit-content;
                                font-weight: 500;
                                font-size: 13px;
                                padding: 3px 5px;
                                border-radius: 4px;
                                border: 1px solid $primary-color;
                                color: $primary-color;
                            }
                        }
                    }
                    // .mdlr-li:first-child {
                    //     .mdlr-col {
                    //         border-top: none;
                    //     }
                    // }
                }
            }
            
             .lcgv-data{
                display: table;
                width: 100%;

                .lcv-table-head{
                    display: table-header-group;
                }

                .ndfs-inner{
                    svg {
                        height: 75px;
                        width: auto;
                    }

                    h3{
                        white-space: nowrap;
                     }
                }

                .modal-data-list-render{
                    position: relative;
                    left: 112%;
                }
            }
        }
    }

    .assortment-value {
        .list-component-section {
            .modal-header-section {
                align-items: center;
                justify-content: flex-start;

                p {
                    width: auto;
                    max-width: 300px;
                    font-weight: 600;
                    font-size: 12px;
                    color: #23235f;
                    margin: 0 0 0 10px;
                    background: #fff;
                    border: 1px solid #b7c6de;
                    border-radius: 3px;
                    padding: 5px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    height: 30px;
                }
            }

            .modal-mapped-data {
                height: calc(100% - 123px);

                .lcs-inner {
                    .list-component-normal-view {
                        .lcv-table-head {
                            .lcvth-row {
                                li {
                                    padding: 10px 15px 0;

                                    label {
                                        font-weight: 500;
                                        font-size: 14px;
                                        color: #67768e;
                                    }
                                }

                                li:nth-child(1) {
                                    display: none;
                                }
                            }
                        }

                        .modal-data-list-render {
                            height: calc(100% - 50px);

                            li {
                                .mdlr-col {
                                    font-size: 14px;
                                    color: #67768e;
                                    margin-right: 5px;
                                }
                            }
                            li:hover{
                                background: #f5f5f5;
                            }
                        }
                    }
                }
            }
        }
    }

    .planning-parameter{
        height: 100%;
        .pp-tabs{
            display: flex;
            align-items: center;
            padding: 10px 15px;
            button{
                width: 300px;
                height: 75px;
                display: flex;
                align-items: center;
                border: 1px solid #e8e9ef;
                background-color: #fff;
                padding: 0 15px;
                margin-right: 15px;
                p{
                    color: #83879a;
                    font-size: 14px;
                    font-weight: 500;
                    margin-left: 15px;
                    text-align: start;
                    margin: 0 0 0 15px;
                }
            }
            button:hover{
                background-color: #f8fafc;
            }
            .btn-active{
                border: 1px solid $primary-color;
                background-color: #f8fafc;
                p{
                    color: #0f172a;
                }
            }
        }
        .pp-mid{
            display: flex;
            align-items: center;
            border: 1px solid #e5e6f1;
            background-color: #f3f4f9;
            padding: 10px 15px;
            margin: 0 15px 15px;
            label{
                color: #0f172a;
                font-size: 14px;
                font-weight: 600;
                margin: 0;
            }
            p{
                color: #0f172a;
                font-size: 11px;
                font-weight: 400;
                margin: 0;
            }
        }
        .pp-table{
            height: calc(100vh - 395px);
            overflow: auto;
            padding: 0 5px 0 15px;
            table{
                width: 100%;
                thead{
                    position: sticky;
                    top: 0;
                    z-index: 1;
                    background-color: #f8f8f8;
                    tr{
                        th{
                            color: #67768e;
                            font-size: 12px;
                            font-weight: 600;
                            text-align: left;
                            border-bottom: 1px solid #dae4ee;
                            border-right: 1px solid #dae4ee;
                            padding: 8px;
                        }
                        th:nth-last-child(1){
                            border-right: none;
                        }
                    }
                }
                tbody{
                    tr{
                        td{
                            border-bottom: 1px solid #dae4ee;
                            border-right: 1px solid #dae4ee;
                            padding: 5px 8px;
                            label{
                                max-width: 180px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                color: #0f172a;
                                font-size: 12px;
                                font-weight: 500;
                                margin: 0;
                                white-space: nowrap;
                                position: relative;
                            }
                            .generic-input-box {
                                margin: 0;
                            }
                        }
                        td:nth-last-child(1){
                            border-right: none;
                        }
                    }
                }
            }
        }
    }
}

// Notifications
.gc-notifications{
    height: calc(100% - 44px);
    padding: 10px 12px;
    .gcn-top{
        .gcnt-alert{
            width: fit-content;
            display: flex;
            align-items: center;
            background: #E7F1FF;
            padding: 5px 10px;
            svg{
                path{
                    stroke: #2B83FF;
                }
            }
            label{
                color: #0F172A;
                font-size: 14px;
                font-weight: 600;
                margin: 0;
            }
            p{
                color: #4D719C;
                font-size: 12px;
                font-weight: 400;
                margin: 0;
            }
        }
        .gcnt-inputs{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 10px;
            .generic-input-box {
                width: 36%;
                display: flex;
                align-items: center;
                border: 1px solid #DAE4EE;
                margin: 0 10px 0 0;
                label{
                    height: 32px;
                    width: fit-content;
                    color: #7E8084;
                    font-size: 12px;
                    font-weight: 400;
                    margin: 0;
                    white-space: nowrap;
                    .gibl-label{
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding-left: 5px;
                    }
                    .disabled-label{
                        background-color: #f1f1f1;
                    }
                }
                .gib-inner{
                    border: none;
                    border-radius: 0;
                    input{
                        color: #0f172a;
                    }
                }
                .gib-dropdown:hover{
                    background-color: #fff;
                    input{
                        background-color: #fff;
                    }
                }
                .focusOn{
                    border: none !important;
                    box-shadow: none !important;
                }
                .list-component-section{
                    top: 36px;
                    z-index: 3;
                }
            }
            .generic-input-box:hover{
                background-color: #f5f5f5;
                .gib-inner{
                    background-color: #f5f5f5;
                    input{
                        background-color: #f5f5f5;
                    }
                }
            }
            .gcnti-right{
                .generic-input-box{
                    width: 100%;
                    margin: 0;
                    .gib-inner{
                        svg{
                            margin-right: 5px;
                        }
                        .gen-input-clear{
                            svg{
                                margin: 0;
                            }
                        }
                    }
                    .focusOn{
                        border: 1px solid $primary-color !important;
                        box-shadow: 0px 0px 0px 2px #DADAFF !important;
                    }
                }
                .generic-input-box:hover{
                    background-color: #fff;
                    .gib-inner{
                        background-color: #fff;
                        input{
                            background-color: #fff;
                        }
                    }
                }
            }
        }
    }
    .gcn-table{
        height: calc(100% - 56px);
        overflow: auto;
        margin-top: 12px;
        .gcrbs-btns {
            position: absolute;
            top: -51px;
            right: 12px;
            z-index: 9;

            button {
                height: 30px;
                font-size: 12px;
                font-weight: 600 !important;
                margin-left: 15px;
                border-radius: 0;
                text-transform: uppercase;
            }
        }
        table{
            width: 100%;
            border: 1px solid #DAE4EE;
            thead{
                position: sticky;
                top: -1px;
                z-index: 2;
                background-color: #f8f8f8;
                tr{
                    th{
                        text-align: left;
                        color: #67768E;
                        font-size: 12px;
                        font-weight: 600;
                        text-transform: uppercase;
                        padding: 8px 10px;
                        border-right: 1px solid #DAE4EE;
                    }
                }
            }
            tbody{
                tr{
                    td{
                        padding: 5px 10px;
                        border-top: 1px solid #DAE4EE;
                        border-right: 1px solid #DAE4EE;
                        color: #0F172A;
                        font-size: 13px;
                        font-weight: 400;
                        position: relative;
                        .td-no-data{
                            text-align: center;
                            font-size: 12px;
                            font-weight: 500;
                            color: #0F172A;
                            padding: 20px 0;
                        }
                        .generic-input-box {
                            margin: 0;
                            .list-component-section {
                                top: 34px;
                                z-index: 1;
                            }
                        }
                        // .add-email-btn{
                        //     display: flex;
                        //     align-items: center;
                        //     border: 1px solid #0F172A;
                        //     color: #0F172A;
                        //     font-size: 13px;
                        //     font-weight: 500;
                        //     padding: 2px 7px;
                        //     svg{
                        //         path{
                        //             fill: #0F172A;
                        //         }
                        //     }
                        // }
                        // .add-email-btn:hover{
                        //     background-color: #0F172A;
                        //     color: #fff;
                        //     svg{
                        //         path{
                        //             fill: #fff;
                        //         }
                        //     }
                        // }
                        // .added-emails{
                        //     display: flex;
                        //     align-items: center;
                        //     justify-content: space-between;
                        //     span{
                        //         color: $primary-color;
                        //         font-size: 13px;
                        //         font-weight: 600;
                        //         cursor: pointer;
                        //         margin-right: 3px;
                        //     }
                        //     span:hover{
                        //         color: #3F41D1;
                        //         text-decoration: underline;
                        //     }
                        //     button{
                        //         border: 1px solid #0F172A;
                        //         padding: 4px 5px;
                        //         svg{
                        //             path{
                        //                 stroke: #0F172A;
                        //             }
                        //         }
                        //     }
                        //     button:hover{
                        //         background-color: #0F172A;
                        //         svg{
                        //             path{
                        //                 stroke: #fff;
                        //             }
                        //         }
                        //     }
                        // }
                        // .ae-email-dropdown{
                        //     position: absolute;
                        //     left: 10px;
                        //     top: 32px;
                        //     border-radius: 4px;
                        //     background: #FFF;
                        //     box-shadow: 0px 4px 24px 0px rgba(115, 114, 114, 0.25);
                        //     padding: 10px 15px;
                        //     z-index: 1;
                        //     transition: 0.6s all;
                            
                        //     ul{
                        //         max-height: 200px;
                        //         overflow: auto;
                        //         li{
                        //             color: #2A2A64;
                        //             font-size: 12px;
                        //             font-weight: 400;
                        //             padding: 5px 0;
                        //             border-bottom: 1px solid #F2F2F2;
                        //         }
                        //         li:nth-last-child(1){
                        //             border: none;
                        //         }
                        //     }
                        // }
                    }
                    td:nth-last-child(1){
                        border-right: none;.generic-input-box {
                            .list-component-section {
                                left: auto;
                                right: 0;
                            }
                        }
                    }
                    .td-events{
                        label{
                            color: #0F172A;
                            font-size: 13px;
                            font-weight: 600;
                            margin: 0;
                        }
                        p{
                            width: fit-content;
                            color: #67768E;
                            font-size: 8px;
                            font-weight: 600;
                            border-radius: 2px;
                            border: 1px solid #67768E;
                            padding: 0 3px;
                            margin: 0;
                        }
                    }
                }
                
                tr:nth-last-child(-n+8){
                    td{
                        // .ae-email-dropdown{
                        //     bottom: 32px;
                        //     top: auto;
                        // }
                        .generic-input-box {
                            .list-component-section {
                                bottom: 34px;
                                top: auto;
                            }
                        }
                    }
                }

                tr:nth-child(-n+7){
                    td{
                        // .ae-email-dropdown{
                        //     bottom: 32px;
                        //     top: auto;
                        // }
                        .generic-input-box {
                            .list-component-section {
                                top: 34px;
                                bottom: auto;
                            }
                        }
                    }
                }
            }
        }
        // .gcnt-edit-modal{
        //     height: 100%;
        //     .gcnte-body{
        //         height: calc(100% - 175px);
        //         overflow: auto;
        //         padding: 2px 15px;
        //         .generic-input-box{
        //             .gib-inner{
        //                 border-radius: 0;
        //                 svg{
        //                     path{
        //                         stroke: #707494;
        //                     }
        //                 }
        //                 input{
        //                     padding-left: 7px;
        //                 }
        //                 input::placeholder{
        //                     color: #67768E;
        //                     font-size: 12px;
        //                     font-weight: 400;
        //                 }
        //             }
        //         }
        //         .gcnteb-det{
        //             display: flex;
        //             flex-wrap: wrap;
        //             gap: 8px;
        //             padding-top: 5px;
        //             .gcntebd{
        //                 display: flex;
        //                 align-items: center;
        //                 border-radius: 70px;
        //                 background: #EEEFF0;
        //                 padding: 5px 10px;
        //                 label{
        //                     color: #0F172A;
        //                     font-size: 12px;
        //                     font-weight: 500;
        //                     margin: 0 5px 0 0;
        //                 }
        //                 button{
        //                     border-radius: 50%;
        //                     border: 1.5px solid rgba(253, 55, 87, 1);
        //                     svg{
        //                         path{
        //                             stroke: rgb(253, 55, 87);
        //                         }
        //                     }
        //                 }
        //                 button:hover{
        //                     background-color: rgba(253, 55, 87, 1);
        //                     svg{
        //                         path{
        //                             stroke: #fff;
        //                         }
        //                     }
        //                 }
        //             }
        //         }
        //     }
        //     .generic-form-footer{
        //         .gff-right{
        //             width: 100%;
        //             display: grid;
        //             button{
        //                 width: 100%;
        //                 margin: 0;
        //             }
        //             .generic-approve-btn{
        //                 margin-bottom: 10px;
        //             }
        //         }
        //     }
        // }
    }
}

.gc-config-table {

    table {
        thead {
            tr {
                th {
                    label {
                        font-weight: 500 !important;
                    }
                }
            }
        }
    }
}

// default article section

.default-article-section {
    .list-component-section{
        min-height: 230px !important;
    }
    .article-filter-section {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 5px 15px;
        .afs-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 5px;
            h3 {
                font-weight: 600;
                font-size: 11px;
                color: #67768e;
                margin: 0;
            }
            button {
                font-size: 11px;
                font-weight: 500;
                margin: 0;
                cursor: pointer;
                color: #2969ff;
                text-decoration: underline;
            }
        }
        .afs-filter-section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .afsfs-input {
                display: flex;
                height: 30px;
                border-radius: 4px;
                border: 1px solid #dae4ee;
                align-items: center;
                padding: 3px 5px;
                flex: 0 0 31%;
                svg {
                    flex: 0 0 15px;
                }
                input {
                    font-size: 12px;
                    font-weight: 500;
                    color: #23235f;
                    padding: 3px;
                    width: 100%;
                }
            }
        }
    }
    .gib-inner{
        height: 36px !important;
    }
    .proc-article-modal {
        // .generic-input-box {
            // color: black;
            .gib-inner{
                height: 36px;
            }
        // }

        .adf-col {
            display: flex;
            flex-direction: column;
            .adfc-head {
                display: flex;
                align-items: center;
                h3 {
                    font-weight: 600;
                    font-size: 12px;
                    color: #23235f;
                    margin: 0;
                    max-width: 300px;
                    white-space: normal;
                }
                p {
                    color: #67768e;
                    font-size: 11px;
                    font-weight: 500;
                    margin-left: 5px;
                    display: inline-block;
                    margin-bottom: 0px;
                }
            }
            .adfc-bottom {
                display: flex;
                align-items: center;
                .adfcb-col {
                    margin-right: 10px;
                    min-width: 110px;
                }
                p {
                    color: #8994a7;
                    font-size: 10px;
                    font-weight: 400;
                    margin-bottom: 0px;
                }
                span {
                    color: #67768e;
                    font-size: 11px;
                    font-weight: 500;
                    display: block;
                }
            }
        }

        .cofmhci-text {
            display: flex;
            flex-direction: column;
            padding: 0 5px;
            h3 {
                color: #23235f;
                font-weight: 600;
                font-size: 12px;
                margin: 0;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .cofmhcit-bottom {
                color: #67768e;
                font-size: 11px;
                font-weight: 500;
                white-space: nowrap;
                max-width: 225px;
                overflow: hidden;
                text-overflow: ellipsis;
                vertical-align: middle;
                display: inline-block;
            }
        }
    }
    .cofhlc-dropdown{
        background: #FFFFFF;
        border-radius: 6px;
        box-shadow: 0px 2.71233px 18.99px #a0a0a08f;
        width: auto;
        min-width: calc(100% - 15px);
        min-height: 70px;
        height: auto;
        max-height: 300px;
        z-index: 1;

        .drop-body-field {
            max-height: 260px;
            overflow-y: auto;
            overflow-x: hidden;

            .dbf-input {
                padding: 7px;
                top: 0;
                position: sticky;
                z-index: 4;
                background-color: white;

                .dbfi-input {
                    height: 28px !important;
                    border: 1px solid #dee6f4;
                    padding-left: 8px;
                    width: 100%;
                    font-size: 12px;
                    font-weight: 700;
                    color: #12203c;
                }
            }

            table {
                margin-bottom: 0;
                width: 100%;

                thead {
                    position: sticky;
                    top: 42px;
                    background: #fff;
                    z-index: 1;

                    tr {
                        height: 30px;

                        th {
                            padding: 5px 10px;
                            height: 30px;
                            border-bottom: 1px solid #DAE4EE;
                            border-top: 0;
                            border-right: 0;
                            border-left: 0;
                            text-align: start;
                            font-size: 11px;
                            font-weight: 600;
                            color: #67768e;
                        }
                    }
                }

                .cofhlcd-body {
                    tr {
                        height: 30px;

                        .checkmark1{
                            left: 8px;
                            background-color: #fff;
                            border: 2px solid #dce6f0;
                            border-radius: 4px;
                            cursor: pointer;
                            height: 16px;
                            width: 16px;
                        }

                        .cofhlcd-checked.checkmark1 {
                            background-color: $primary-color;
                            border: none;
                        }
                        
                        .cofhlcd-checked.checkmark1:after {
                            width: 5px;
                            height: 9px;
                            border-width: 0 2px 2px 0;
                            content: "";
                            top: 2px;
                            left: 5px;
                        }

                        td {
                            font-weight: 500;
                            font-size: 12px;
                            color: #23235F;
                            padding: 5px 10px;
                            height: 30px;
                            border: none;

                            .cofhlcd-checkbox {
                                margin: 0;
                                font-size: 12px;
                                font-weight: 600;
                                color: #12203c;
                                padding-left: 25px;
                                cursor: pointer !important;
                                white-space: nowrap;
                                min-width: 72px;
                                position: relative;

                                input[type=radio] {
                                    display: none;
                                }

                                .checkmark {
                                    content: "";
                                    position: absolute;
                                    top: 2px;
                                    left: 0;
                                    height: 15px;
                                    width: 15px;
                                    border: 2px solid #d6dce6;
                                    border-radius: 50%;
                                }

                                .checkmark:before {
                                    content: "";
                                    position: absolute;
                                    top: 3px;
                                    left: 3px;
                                    width: 5px;
                                    height: 5px;
                                    display: block;
                                    border-radius: 50%;
                                    background: #d6dce6;
                                }

                                input:checked~.checkmark {
                                    background-color: #fff;
                                    border: 2px solid $primary-color;
                                }

                                input:checked~.checkmark:before {
                                    background: $primary-color;
                                }
                            }

                            input {
                                background: #FFFFFF;
                                border: 1px solid #DAE4EE;
                                font-size: 12px;
                                font-weight: 600;
                                color: #23235F;
                                padding: 3px 5px;
                                border-radius: 4px;
                                width: 110px;
                            }

                            input::placeholder {
                                color: #ADB4BC;
                            }

                            input:focus {
                                border: 1px solid $primary-color;
                                box-shadow: 0px 0px 0px 2px #DADAFF;
                            }

                            .cofhlcd-color {
                                display: block;
                                width: 30px;
                                height: 18px;
                                border-radius: 4px;
                                background-color: #dee;
                                margin-right: 10px;
                            }

                            .checkBoxLabel0 {
                                width: 16px;
                                height: 16px;
                                padding-left: 8px;
                                margin: 0;
                                margin-right: 10px;
                                display: inline-block;

                                .checkmark1 {
                                    left: 0px;
                                    top: 3px;
                                    height: 16px;
                                    width: 16px;
                                    background-color: #ffffff;
                                    cursor: pointer;
                                    border-radius: 4px;
                                    border: solid 2px #dce6f0;
                                }

                                .checkmark1:after {
                                    width: 5px;
                                    height: 9px;
                                    border-width: 0 2px 2px 0;
                                    content: none;
                                }

                                .cofhlcd-checked.checkmark1 {
                                    background-color: $primary-color;
                                    border: none;
                                }

                                .cofhlcd-checked.checkmark1:after {
                                    width: 5px;
                                    height: 9px;
                                    border-width: 0 2px 2px 0;
                                    content: "";
                                }
                            }
                        }
                    }
                }
            }
        }
        .cofhlcd-footer {
            padding: 3px 10px 5px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            border-top: solid 1px #DAE4EE;
    
            button {
                background-color: $primary-color;
                color: #fff;
                border-radius: 4px;
                padding: 4px 12px;
                font-size: 12px;
                font-weight: 500;
            }
    
            .gdp-close {
                background-color: #ffffff;
                border: solid 1px #DAE4EE;
                color: #23235F;
                border-radius: 50px;
                padding: 4px 10px;
            }
    
            .gdp-close:hover {
                background-color: #f5f5f5;
            }
    
            .page-next-prew-btn {
                display: flex;
                height: auto;
    
                button {
                    display: inline-block;
                    padding: 1px 10px;
                    border-radius: 6px;
                    border: none;
                    background-color: #fff;
                    font-size: 12px;
                    font-weight: 500;
                    color: #12203c;
                    transition: all .6s;
                    margin: 0;
                    height: 24px;
                    background-image: none;
                    width: auto;
                    white-space: normal;
                    text-align: center;
                }
    
                .pnpb-hov:hover,
                .pnpb-hov:focus {
                    svg {
                        path {
                            fill: #2969FF;
                        }
                    }
                }
            }
        }
    
        .jcsb {
            justify-content: space-between;
        }
    }
}