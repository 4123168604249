@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
@import '../../styles/stylesheets/variables.scss';
    
.cost-sheet-new-modal{
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    .csn-head{
        padding: 10px 25px;
        h3{
            font-size: 16px;
            font-weight: 700;
            color: #21314b;
            margin: 0;
        }
    }
    .csn-tab{
        height: 45px;
        display: flex;
        align-items: center;
        border-top: 1px solid #dee;
        border-bottom: 1px solid #dee;
        padding: 0 25px;
        button{
            width: fit-content;
            height: 100%;
            color: #67768e;
            font-size: 13px;
            padding: 0 15px;
            font-weight: 500;
            position: relative;
        }
        .sel-btn{
            color: $primary-color;
        }
        .sel-btn::after{
            content: "";
            width: 100%;
            height: 2px;
            position: absolute;
            left: 0;
            bottom: 0;
            background: $primary-color;
        }
    }
    .csn-body{
        width: 100%;
        height: calc(100vh - 139px);
        overflow: auto;
        padding: 10px 15px;
        h3{
            color: #111d31;
            font-weight: 700;
            font-size: 14px;
            margin: 0 0 0 10px; 
        }
        .csnb{
            display: flex;
            .csnb-left{
                width: 50%;
                padding: 10px;
                table{
                    width: 100%;
                    thead{
                        background: #f8f8f8;
                        tr{
                            th{
                                text-align: left;
                                padding: 8px;
                                label{
                                    font-size: 12px;
                                    font-weight: 600;
                                    color: #8496a9;
                                    text-transform: uppercase;
                                    margin: 0;
                                }
                            }
                        }
                    }
                    tbody{
                        tr{
                            td{
                                padding: 5px 8px;
                                border: 1px solid #dee;
                                label{
                                    color: #0F172A;
                                    font-size: 12px;
                                    font-weight: 400;
                                    margin: 0;
                                }
                                input{
                                    border: 1px solid #dce6f0;
                                    background: #fff;
                                    padding: 7px 15px;
                                    height: 32px;
                                    font-size: 12px;
                                    color: #111d31;
                                    font-weight: 500;
                                    border-radius: 3px;
                                    width: 100%;
                                }
                                input[disabled] {
                                    background-color: #f1f1f1 !important;
                                    text-indent: 5px;
                                }
                            }
                            td:nth-child(1){
                                border-left: none;
                            }
                            td:nth-last-child(1){
                                border-right: none;
                            }
                        }
                    }
                }
            }
            .csnb-right{
                width: 50%;
                padding: 10px;
            }
        }
        .costh-col {
            padding: 10px;
            h4 {
                font-size: 12px;
                font-weight: 600;
                margin-bottom: 5px;
                color: #21314B;
            }
            p {
                font-size: 12px;
                font-weight: 600;
                color: #21314b;
                margin: 0;
            }
            .costhc-input {
                position: relative;
                display: block;
                width: 25%;
                border-radius: 5px;
                border: 1px solid #dce6f0;
                height: 32px;
                background-color: #f4f4f4;
                font-size: 12px;
                font-weight: 600;
                color: #21314b;
                padding: 3px 6px;
            }
            .checkBoxLabel0{
                width: auto;
                height: 16px;
                padding-left: 26px;
                font-size: 12px;
                font-weight: 500;
                color: #21314B;
                .checkmark1 {
                    left: 0px;
                    top: 0px;
                    height: 16px;
                    width: 16px;
                    background-color: #ffffff;
                    cursor: pointer;
                    border-radius: 4px;
                    border: solid 2px #dce6f0;
                }
                .checkmark1:after {
                    width: 5px;
                    height: 9px;
                    border-width: 0 2px 2px 0;
                    content: none;
                }
                input:checked~.checkmark1 {
                    background-color: $primary-color;
                    border: none;
                }
                input:checked~.checkmark1:after{
                    width: 5px;
                    height: 9px;
                    border-width: 0 2px 2px 0;
                    content: "";
                }
            }
        }
    }
    .plm-box{
        overflow: auto;
        padding: 10px 20px;
        h1{
            font-size: 15px;
            font-weight: 700;
            color: #111d31;
            margin-bottom: 3px;
        }
        p{
            font-size: 12px;
            font-weight: 500;
            color: #111d31;
            margin: 0;
        }
        .plmb-inner{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding-bottom: 15px;
            .plmbi{
                display: flex;
                flex-direction: column;
                padding: 10px 15px 0 10px;
                label{
                    color: #8496a9;
                    font-size: 11px;
                    font-weight: 500;
                }
                input{
                    width: 100%;
                    height: 30px;
                    border-radius: 4px;
                    border: 1px solid #dae4ee;
                    padding: 3px 5px;
                    background-color: #f1f1f1;
                    font-size: 12px;
                    font-weight: 600;
                    color: #23235f;
                }
                input[disabled] {
                    background-color: #f1f1f1 !important;
                    text-indent: 5px;
                }
            }
        }
    }
}

@media screen and (max-width: 600px){
    .csnb{
        display: grid !important;
        .csnb-left{
            width: 100% !important;
        }
        .csnb-right{
            width: 100% !important;
        }
    }
}