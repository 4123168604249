@import '../variables.scss';

.header-outer {
    position: sticky;
    left: 60px;
    top: 0px;
    width: calc(100% - 40px);
    z-index: 98;

    .header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 45px;
        padding: 5px 5px 5px 30px;
        background-color: #ffffff;
        border-bottom: 1px solid #F3F6F9;

        .hoh-left {
            display: flex;
            align-items: center;

            .hohl-global-search {
                height: 33px;
                padding: 0px 5px;
                border-radius: 3px;
                border: solid 1px #dae4ee;
                min-width: 250px;
                background-color: #fff;
                display: flex;
                align-items: center;
                cursor: text;

                input[type="text"] {
                    background: #fff;
                    border: none;
                    width: 220px;
                    margin-left: 6px;
                    height: 28px;
                    font-size: 12px;
                    font-weight: 600;
                    color: #21314b;
                    outline: none;
                }

                .short-key-btn {
                    background: rgba(112, 116, 148, 0.1);
                    border-radius: 4px;
                    font-weight: 500;
                    font-size: 11px;
                    color: #67768E;
                    white-space: nowrap;
                    padding: 3px 10px;
                }

                input::placeholder {
                    color: #67768e;
                    font-weight: 500;
                }
            }

            .hohl-global-search:focus {
                border: 1px solid $primary-color;
                box-shadow: 0px 0px 0px 2px #DADAFF;
            }
        }

        .hoh-right {
            display: flex;
            align-items: center;

            .signInLanguageSelect{
                max-width: 70px;
                margin-right: 1rem;
            }

            .hohr-menu {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 0;
                margin: 0;
                position: relative;

                .help-dropdown {
                    position: absolute;
                    background-color: #fff;
                    top: 40px;
                    left: 0;
                    background-color: #fff;
                    position: absolute;
                    box-shadow: 0px 2.71px 18.99px rgba(160, 160, 160, 0.26);
                    transition: all .6s;
                    z-index: 10;
                    border-radius: 3px;
                    min-width: 220px;

                    .list-component-section {
                        .modal-header-section {
                            align-items: center;
                            padding: 10px 15px;

                            .mhs-left {
                                h4 {
                                    font-weight: 500;
                                    font-size: 12px;
                                    color: #8994a7;
                                }
                            }

                            .mhs-right {
                                display: none;
                            }
                        }

                        .lcs-inner {
                            .list-component-normal-view {
                                .lcv-table-head {
                                    display: none;
                                }

                                .modal-data-list-render {
                                    .mdlr-li {
                                        .list-col-2 {
                                            flex: none;
                                            padding-right: 10px;

                                            .mdlr-multiple-row {
                                                font-weight: 500;
                                                font-size: 13px;
                                                color: #23235f;

                                                svg {
                                                    margin: 0;
                                                }
                                            }
                                        }

                                        .list-col-2:nth-child(1) {
                                            width: 28px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .hohrm-list {
                    display: block;
                    background-color: #fff;
                    position: relative;

                    .hohrm-user-info {
                        cursor: pointer;
                        display: block;
                        padding: 0px 10px;
                        border-radius: 5px;
                        background-color: #ffffff;
                        display: flex;
                        align-items: center;

                        .hu-det {
                            padding-left: 10px;

                            h4 {
                                font-size: 12px;
                                font-weight: 700;
                                color: #21314b;
                                margin: 0;
                                max-width: 200px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }

                            p {
                                font-size: 11px;
                                font-weight: 600;
                                color: #67768e;
                                margin: 0;
                            }

                            .hud-icon {
                                display: inline-block;
                                margin-left: 10px;
                                transform: rotate(180deg);
                            }
                        }

                        .hu-logo {
                            img {
                                width: auto;
                                height: 32px;
                            }
                        }
                    }

                    .hohrm-user-info:before {
                        content: "|";
                        position: absolute;
                        color: #b7c6de;
                        top: 6px;
                        font-weight: 400;
                        font-size: 17px;
                        left: -7px;
                    }

                    .profile-dropdown {
                        width: auto;
                        min-width: 300px;
                        height: auto;
                        position: absolute;
                        top: 42px;
                        right: 0;
                        border-radius: 6px;
                        box-shadow: 0 0 16px 1px #a3a3a340;
                        background: #fff;
                        z-index: 9;

                        ul {
                            padding: 0;
                            margin: 0;

                            .onboarding-alert{
                                display: flex;
                                border-radius: 12px;
                                border: 0.5px solid #FFF;
                                background: linear-gradient(353deg, #FAD271 -9.7%, #FBFCFD 169.63%);
                                padding: 10px 15px;
                                .oa-det{
                                    padding-left: 15px;
                                    h2{
                                        color: #445674;
                                        font-size: 15px;
                                        font-weight: 600;
                                        white-space: nowrap;
                                        margin: 0;
                                    }
                                    p{
                                        color: #7B8084;
                                        font-size: 12px;
                                        font-weight: 400;
                                        margin: 5px 0 10px;
                                    }
                                    button{
                                        display: flex;
                                        align-items: center;
                                        color: #445674;
                                        font-size: 14px;
                                        font-weight: 600;
                                        background: #FFF;
                                        padding: 4px 15px;
                                        svg{
                                            margin-left: 10px;
                                        }
                                    }
                                    button:hover{
                                        background: #445674;
                                        color: #fff;
                                        svg{
                                            path{
                                                stroke: #fff;
                                            }
                                        }
                                    }
                                }
                            }

                            li {
                                width: 100%;
                                display: flex;
                                align-items: center;
                                padding: 10px;
                                background-color: #fff;
                                cursor: pointer;
                                transition: 0.6s all;
                                border-left: solid 3px transparent;

                                .hund-icon {
                                    width: 30px;

                                    svg {
                                        width: 18px;
                                        height: 18px;
                                    }
                                }

                                label {
                                    color: #23235F;
                                    font-size: 12px;
                                    font-weight: 600;
                                    margin: 0;
                                    white-space: nowrap;
                                    max-width: 80%;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    cursor: pointer !important;
                                }
                            }

                            .pd-label {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                padding: 6px 15px;
                                background-color: #F5F5F5;
                                cursor: auto;

                                span {
                                    color: #0F172A;
                                    font-size: 12px;
                                    font-weight: 700;
                                }
                            }

                            .pd-logout {
                                border-top: solid 1px #e6e9ec;
                                border-radius: 0 0 6px 6px;

                                label {
                                    font-size: 12px;
                                    font-weight: 600;
                                    color: $danger-color;
                                    margin: 0;
                                }
                            }

                            .switch-retailer {
                                position: relative;
                                flex-direction: column;
                                align-items: flex-start;
                                border-radius: 0px !important;

                                .sr-default {
                                    border-radius: 40px;
                                    background: #2969FF;
                                    color: #FFF;
                                    text-align: center;
                                    font-size: 9px;
                                    font-weight: 500;
                                    padding: 0 5px;
                                    margin: 0 0 3px 30px;
                                }

                                .sr-org-inner {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    width: 100%;

                                    label {
                                        user-select: none;
                                    }
                                }

                                .sr-left {
                                    display: flex;
                                    align-items: center;

                                    .default-tag {
                                        padding: 1px 5px;
                                        font-size: 10px;
                                        font-weight: 600;
                                        width: 53px;
                                        height: 17px;
                                        background-color: #23235f;
                                        border-radius: 30px;
                                        color: #fff;
                                    }
                                }

                                .sricon-rotete180 {
                                    transform: rotate(180deg);
                                }

                                .sroi-name {
                                    font-size: 10px;
                                    font-weight: 500;
                                    display: block;
                                    color: #67768e;
                                    padding-left: 30px;
                                    word-break: break-all;
                                    user-select: none;
                                }

                                .switch-organizations {
                                    width: 365px;
                                    min-height: 100px;
                                    position: absolute;
                                    top: -38px;
                                    left: -370px;
                                    border-radius: 6px;
                                    box-shadow: 0px 0px 16px 1px rgba(164, 164, 164, 0.25);
                                    background: #fff;
                                    padding: 15px;

                                    .so-head {
                                        display: flex;
                                        align-items: center;

                                        svg {
                                            margin-right: 10px;
                                        }

                                        h2 {
                                            color: #0F172A;
                                            font-size: 16px;
                                            font-weight: 600;
                                            margin: 0;
                                        }
                                    }
                                }

                                p {
                                    color: #7A7E80;
                                    font-size: 12px;
                                    font-weight: 400;
                                    margin: 10px 0 0 0;
                                }

                                ul {
                                    height: auto;
                                    max-height: 170px;
                                    overflow: auto;

                                    li {
                                        border-radius: 10px;
                                        border: 1px solid #E8E9E9;
                                        background: #FFF;
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        padding: 7px 10px;
                                        margin-top: 10px;
                                        cursor: pointer;
                                        position: relative;

                                        .so-left {
                                            display: flex;

                                            .sol-logo {
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                width: 32px;
                                                height: 32px;
                                                background-color: #fff;
                                                border-radius: 32px;
                                                box-shadow: 0px 1px 14px -4px rgba(0, 0, 0, 0.25);
                                                object-fit: cover;
                                                margin-right: 15px;

                                                label {
                                                    color: #0F172A;
                                                    font-weight: 600;
                                                    font-size: 15px;
                                                }
                                            }

                                            .sol-org-det {
                                                display: flex;
                                                align-items: baseline;
                                                flex-direction: column;

                                                label {
                                                    color: #0F172A;
                                                    font-size: 13px;
                                                    font-weight: 500;
                                                    margin: 0;
                                                    white-space: nowrap;
                                                    max-width: 225px;
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;

                                                    .generic-tooltip {
                                                        top: -22px;
                                                        left: 50px;
                                                        transition: none;
                                                    }

                                                    .generic-tooltip::after {
                                                        left: 30%;
                                                    }
                                                }

                                                label:hover {
                                                    .generic-tooltip {
                                                        visibility: visible;
                                                        opacity: 1;
                                                    }
                                                }

                                                p {
                                                    color: #414649;
                                                    font-size: 11px;
                                                    font-weight: 400;
                                                    margin: 0;
                                                    position: relative;

                                                    .infot-detail {
                                                        display: block;
                                                        position: absolute !important;
                                                        top: -10px;
                                                        left: 62px;
                                                        background: #fff;
                                                        max-width: 200px;
                                                        border-radius: 8px;
                                                        box-shadow: 0 1px 8px 2px #89848440;
                                                        padding: 5px 0;
                                                        z-index: 9;
                                                        opacity: 0;
                                                        visibility: hidden;

                                                        .infotd-row {
                                                            p {
                                                                margin: 0;
                                                            }
                                                        }
                                                    }

                                                    .infot-detail:before {
                                                        content: "";
                                                        position: absolute;
                                                        border-style: solid;
                                                        top: 10px;
                                                        left: 0;
                                                        margin-left: -9px;
                                                        border: medium solid #fff;
                                                        border-width: 7px 13px 7px 0;
                                                        border-color: transparent #fff transparent transparent;
                                                        z-index: 10;
                                                    }
                                                }

                                                .sol-default {
                                                    color: #fff;
                                                    font-size: 10px;
                                                    font-weight: 500;
                                                    border-radius: 40px;
                                                    background: #2969ff;
                                                    padding: 1px 5px;
                                                }

                                                .sol-hold {
                                                    border-radius: 40px;
                                                    background: #F6B316;
                                                    color: #FFF;
                                                    text-align: center;
                                                    font-size: 9px;
                                                    font-weight: 500;
                                                    padding: 2px 5px 0;
                                                }

                                                .sol-offline {
                                                    border-radius: 40px;
                                                    background: #e66767;
                                                    color: #FFF;
                                                    text-align: center;
                                                    font-size: 9px;
                                                    font-weight: 500;
                                                    padding: 2px 5px 0;
                                                }

                                                .solo-btn {
                                                    color: #0F172A;
                                                    font-size: 10px;
                                                    font-weight: 500;
                                                    border-radius: 19px;
                                                    border: 1px solid #0F172A;
                                                    background: #FFF;
                                                    padding: 1px 5px;
                                                    margin-top: 2px;
                                                }

                                                .solo-btn:hover {
                                                    color: #fff;
                                                    background: #0F172A;
                                                }

                                                .solo-btn:disabled:hover{
                                                    color: #0F172A;
                                                    background: #FFF;
                                                }
                                            }
                                        }
                                    }

                                    li:hover,
                                    .li-active {
                                        background: #F2F3F3 !important;

                                        .so-right {
                                            visibility: visible;
                                            opacity: 1;

                                            .set-mark-default {
                                                visibility: visible;
                                                opacity: 1;
                                                z-index: 9;
                                            }
                                        }

                                        .so-left {
                                            .sol-org-det {
                                                p {
                                                    .infot-detail {
                                                        opacity: 1 !important;
                                                        visibility: visible !important;
                                                    }
                                                    .generic-tooltip {
                                                        visibility: visible;
                                                        opacity: 1;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .disabled-org {
                                        opacity: 1;
                                        cursor: not-allowed !important;

                                        .so-left {
                                            .sol-org-det {
                                                label {
                                                    cursor: not-allowed !important;
                                                }
                                            }
                                        }

                                        .so-right {
                                            visibility: hidden;
                                            opacity: 0;
                                        }
                                    }

                                    .disabled-org:hover {
                                        .so-right {
                                            visibility: hidden;
                                            opacity: 0;
                                        }
                                    }
                                }
                            }

                            .sr-active {
                                background-color: #F2F3FF;
                                border-left: 3px solid $primary-color;

                                .hund-icon {
                                    svg {
                                        path {
                                            fill: #21314b;
                                        }
                                    }
                                }
                            }

                            li:first-child {
                                border-radius: 6px 6px 0 0;
                            }

                            li:hover {
                                // background-color: #F2F3FF;
                                background-color: $dropdown-item-hover;
                                border-left: solid 3px $primary-color;
                            }

                            .pd-label:hover {
                                background-color: #F2F3FF;
                                border-left: solid 3px transparent;
                            }

                            .pd-logout:hover {
                                background-color: $danger-w-less-opacity;
                                border-top: solid 1px $danger-w-less-opacity;
                                border-left: solid 3px transparent;
                            }
                        }
                    }
                }

                .hohrm-btn,
                .hohrm-btn-v2 {
                    background-color: #fff;
                    position: relative;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 7px;

                    .generic-tooltip {
                        top: 46px;
                        background: #1f2f4c;
                        transform: translate(-50%);
                    }

                    .generic-tooltip:after {
                        left: calc(50% - 5px);
                        top: -10px;
                        margin: 0;
                        border-color: transparent transparent #1f2f4c;
                    }
                }

                .hohrm-btn-v2 {
                    svg {
                        width: 23px;
                        height: 23px;
                    }
                }

                .hohrm-btn-v2:hover {
                    svg {
                        path {
                            fill: $primary-color;
                        }
                    }
                }

                .hohrm-btn:hover {
                    cursor: pointer;
                    // background-color: #F2F1FE;
                    background-color: $primary-w-less-opacity;

                    border-radius: 50px;

                    .generic-tooltip {
                        visibility: visible;
                        opacity: 1;
                    }

                    svg {
                        path {
                            fill: $primary-color;
                        }
                    }
                }

                .hohrm-btn-v2:hover {
                    cursor: pointer;
                    // background-color: #F2F1FE;
                    background-color: $primary-w-less-opacity;
                    border-radius: 50px;

                    .generic-tooltip {
                        visibility: visible;
                        opacity: 1;
                    }

                    svg {
                        .hohrmb-hover {
                            fill: $primary-color;
                        }
                    }
                }
            }
        }
    }
}

//logged strip css
.show-logged-strip {
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: 999;
    background: #E55039;
    width: 100%;
    height: 35px;

    p {
        font-weight: 500;
        font-size: 12px;
        color: #fff;
        margin: 0;
        display: flex;
        align-items: center;
        padding: 5px 0;
        display: flex;
        align-items: center;

        label {
            margin: 0 5px 0 0;
        }

        .strip-info-icon {
            padding-right: 5px;
        }

        button {
            font-weight: 600;
            font-size: 12px;
            color: #fff;
            background: #E55039;
            border: 1px solid #fff;
            margin: 0 5px;
            border-radius: 3px;
            padding: 0px 5px;
            cursor: default !important;
        }

        .complete-merge-btn {
            background: #fff !important;
            padding: 4px 10px;
            cursor: pointer !important;
            color: #E55039;
        }

        .complete-merge-btn:hover {
            opacity: 0.9;
        }
    }
}

// Account Facility
.acc-facility {
    height: 100%;

    .af-body {
        height: calc(100% - 116px);
        overflow: auto;
        padding: 0 15px;

        .sapb-acc-form {
            width: auto;
            height: auto;
            background: #EDF3FA;
            border-radius: 5px;
            padding: 15px 20px;

            .af-heading {
                display: grid;
                position: relative;

                label {
                    font-weight: 600;
                    font-size: 20px;
                    color: #2A2A64;
                    margin: 0;
                }

                span {
                    font-weight: 600;
                    font-size: 20px;
                    color: #116FFF;
                    position: relative;
                }

                span::after {
                    content: "";
                    position: absolute;
                    width: 104px;
                    height: 9px;
                    left: 0px;
                    top: 17px;
                    background: rgba(17, 111, 255, .1);
                }
            }

            .af-benefits {
                display: grid;
                padding-top: 20px;

                label {
                    font-weight: 700;
                    font-size: 11px;
                    color: #116FFF;
                    letter-spacing: 0.14em;
                }

                span {
                    font-weight: 500;
                    font-size: 13px;
                    color: #2A2A64;

                    svg {
                        cursor: pointer;
                        margin-left: 5px;
                    }
                }

                p {
                    font-weight: 400;
                    font-size: 11px;
                    color: #67768E;
                    margin: 0;
                }
            }
        }

        .sapb-warning {
            display: flex;
            padding: 10px;
            margin-top: 15px;
            width: auto;
            height: auto;
            background: #EDF3FA;
            border-radius: 5px;

            span {
                svg {
                    cursor: pointer;
                }
            }

            p {
                font-weight: 500;
                font-size: 12px;
                color: #2A2A64;
                margin: 0;
                padding-left: 10px;
            }
        }

        .sapb-accounts {
            padding-top: 30px;

            h3 {
                font-weight: 600;
                font-size: 14px;
                color: #2A2A64;
            }

            .sapba-primary-account,
            .sapba-other-accounts {
                width: auto;
                height: auto;
                background: #EDF3FA;
                border-radius: 5px;
                margin-top: 12px;
                padding: 15px;

                .spa-acc-name {
                    display: flex;
                    justify-content: space-between;

                    .spa-company-logo {
                        width: 115px;
                        height: 34px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    .acc-name {
                        display: grid;
                        padding-left: 50px;

                        label {
                            font-weight: 400;
                            font-size: 10px;
                            color: #67768E;
                            margin: 0;
                        }

                        span {
                            font-weight: 600;
                            font-size: 13px;
                            color: #2A2A64;
                        }
                    }

                    .spanl-acc {
                        span {
                            background: #24A148;
                            border-radius: 3px;
                            font-weight: 600;
                            font-size: 11px;
                            color: #F8F8F8;
                            padding: 1px 4px;
                        }
                    }
                }

                .spa-mob-no {
                    display: grid;
                    padding-top: 15px;

                    label {
                        font-weight: 400;
                        font-size: 9px;
                        color: #67768E;
                        margin: 0;
                    }

                    span {
                        font-weight: 600;
                        font-size: 11px;
                        color: #2A2A64;
                    }
                }

                .spa-org-details {
                    display: flex;
                    padding-top: 15px;

                    .displayGrid {
                        label {
                            font-weight: 400;
                            font-size: 9px;
                            color: #67768E;
                            margin: 0;
                        }

                        span {
                            font-weight: 400;
                            font-size: 11px;
                            color: #2A2A64;
                        }
                    }
                }
            }
        }
    }
}


// Global Search Modal
.global-search-modal {
    .modal-search-area {
        border-bottom: 1px solid #ededed;
        padding: 5px 15px !important;

        .msa-left {
            width: 100%;

            .msa-input-outer {
                padding: 0;
                border: none;
                width: 100%;
            }

            .focusOn {
                border: none !important;
                box-shadow: none !important;
            }
        }
    }

    .lcs-inner {
        .list-component-normal-view {
            .modal-data-list-render {
                height: calc(100% - 97px);

                .mdlr-li {
                    border-bottom: 1px solid #ededed;
                }
            }
        }
    }
}

// Upload Excel
.upload-excel {
    height: calc(100vh - 45px);
    padding: 15px 30px;
    background: #fff;
    overflow: hidden;

    .ue-sec {
        width: 100%;
        display: flex;
        padding-left: 10px;

        ul {
            position: relative;
            width: 75%;
            // justify-content: space-between;

            li {
                // padding: 0 5px;
                // background-color: #fff;
                // z-index: 1;

                // span {
                //     font-size: 12px;
                //     font-weight: 500;
                //     color: #21314b;
                // }
            }
        }

        ul::before {
            content: "";
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: -.25rem;
            left: 0%;
            background: #d9d9d9;
        }
    }

    .ue-head {
        padding: 40px 15px 10px;

        .uploadExcelSummarySection{
            .generic-form-footer {
                padding-right: 0;
            }
        }

        h1,
        h3 {
            font-size: 18px;
            font-weight: 700;
            color: #21314b;
            margin: 0;
        }

        h3 {
            font-size: 12px;
        }

        h4 {
            font-size: 12px;
            font-weight: 500;
            margin: 0;
            color: #6a7385;
        }

        h5 {
            font-size: 12px;
            font-weight: 500;
            color: #90a5c7;
            margin: 0;
        }

        .ueh-sec {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .ueh-left {
                display: flex;
                align-items: center;
                padding-top: 10px;

                .uehl {
                    margin-right: 30px;
                }
            }

            .ueh-right {
                display: flex;
                align-items: center;

                .uehr-info {
                    padding: 5px;
                    position: relative;

                    .uehr-dropdown {
                        position: absolute;
                        top: 0;
                        right: 23px;
                        width: auto;
                        border-radius: 6px;
                        box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, .16);
                        background-color: #fff;
                        visibility: hidden;
                        opacity: 0;
                        transition: all .6s;
                        z-index: 9;
                        min-width: 190px;

                        ul {
                            li {
                                font-size: 12px;
                                font-weight: 500;
                                white-space: nowrap;
                                font-style: italic;
                                padding: 5px 10px;
                                cursor: default;

                                .generic-checkbox {
                                    font-style: normal;

                                    .c-maroon {
                                        background-color: maroon !important;
                                        border: none;
                                    }

                                    .c-red {
                                        background-color: red !important;
                                        border: none;
                                    }

                                    .c-orange {
                                        background-color: orange !important;
                                        border: none;
                                    }

                                    .c-yellow {
                                        background-color: #ff0 !important;
                                        border: none;
                                    }
                                }
                            }
                        }
                    }
                }

                .uehr-info:hover {
                    .uehr-dropdown {
                        visibility: visible;
                        opacity: 1;
                    }
                }

                .generic-input-box {
                    margin: 0 0 0 10px;

                    .gib-inner {
                        svg {
                            margin-right: 5px;
                        }
                    }

                    .gen-input-disabled {
                        cursor: not-allowed;

                        input {
                            cursor: not-allowed;
                        }
                    }
                }
            }
        }
    }

    .ue-upload {
        height: auto;
        min-height: calc(100% - 180px);
        max-height: calc(100% - 180px);
        overflow: auto;

        .generic-form-body {
            overflow-x: hidden;
            height: calc(100vh - 260px);

            .gfb-inner {
                .gfb-choose {
                    width: 25%;

                    .list-component-section {
                        z-index: 9;
                    }
                }

                .gib-dropdown:hover {
                    background-color: #f5f5f5 !important;
                }

                .gfb-upload {
                    .gib-inner {
                        .gen-input-clear {
                            position: absolute;
                            right: 12px;
                            top: 12px;
                        }
                    }
                }

                .generic-input-box {
                    .gib-inner {
                        input {
                            margin-right: 20px;
                            padding-right: 0;
                            padding-left: 8px;
                        }
                    }

                    button {
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        font-weight: 600;
                        color: #12203c;
                        margin-top: 15px;

                        svg {
                            margin-right: 7px;
                        }
                    }

                    .list-component-section {
                        .modal-search-area {
                            .msa-left {
                                .msa-input-outer {
                                    .msaio-clear {
                                        margin: 0;

                                        svg {
                                            margin: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .msaio-clear {}
                }

                .generic-input-box:nth-child(2) {
                    .gib-inner {
                        background-color: #fff;
                        padding: 0;

                        .gib-upload {
                            padding: 40px 0;
                            text-align: center;
                            background-color: #fff;
                            border: 2px dashed #c1d8f0;
                            cursor: pointer;
                            input {
                                display: block !important;
                            }

                            .gibu-inner {
                                display: flex;
                                align-items: center;
                                flex-direction: column;

                                h4 {
                                    font-size: 14px;
                                    font-weight: 600;
                                    color: #718096;
                                    margin: 6px 0;
                                }

                                p {
                                    font-size: 11px;
                                    font-weight: 500;
                                    color: #718096;
                                }

                                .generic-approve-btn {
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 5px;
                                }
                            }
                        }
                    }

                    .error {
                        .gib-upload {
                            border: none;
                        }
                    }
                }
            }
        }

        .eupbc-table {
            overflow: auto;
            margin-top: 10px;
            padding: 10px 15px;
            box-shadow: 0 3px 7px 0 #e7ebf2;

            table {
                margin: 0px;

                thead {
                    tr {
                        th {
                            background: #eff2f7;
                            vertical-align: middle;
                            // border-top: solid 1px #b4c5d6;
                            padding: 6px 5px;
                            border: solid 1px #eff2f7;

                            label {
                                font-size: 11px !important;
                                margin: 0;
                                font-weight: 600;
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        th {
                            white-space: nowrap;
                            background: #f9f5fe;
                            vertical-align: middle;
                            // border-top: solid 1px #b4c5d6;
                            padding: 10px 8px;
                            border: solid 1px transparent;
                            font-size: 12px;
                            margin: 0;
                            font-weight: 600;
                            height: 42px;

                            label {
                                font-size: 12px !important;
                                margin: 0;
                                font-weight: 600;
                            }
                        }

                        td {
                            white-space: nowrap;
                            border: solid 1px #b4c5d6;
                            padding: 8px;
                            font-size: 11px;
                            font-weight: 500;
                            height: 38px;
                            color: #12203c;

                            input {
                                font-size: 12px;
                                font-weight: 500;
                                border: solid 1px #d6e3f1;
                                background-color: #f9fafc;
                                padding: 3px 4px;
                                border-radius: 5px;
                                width: 130px;
                                margin-right: 5px;
                                color: #12203c;
                            }

                            input.mousetrap {
                                font-size: 12px;
                                font-weight: 500;
                                border: solid 1px #d6e3f1;
                                background-color: #f9fafc;
                                padding: 3px 4px;
                                border-radius: 5px;
                                width: 130px;
                                margin-right: 5px;
                                color: #12203c;
                            }
                        }

                        .selected {
                            background: #f7f8fc;
                            border: 1px solid #abc1e3;
                        }
                    }
                }
            }
        }

        .upload-completed {
            .upc-head {
                display: flex;
                padding: 20px 15px 12px;
                box-shadow: 0 3px 7px 0 #e7ebf2;

                .text-area {
                    padding-left: 30px;

                    p {
                        margin: 0;
                        color: #67768E;
                        font-size: 12px;
                        font-weight: 500;
                    }

                    h3 {
                        font-weight: 700;
                        color: #111D31;
                        font-size: 18px;
                    }
                }

                .upload-items {
                    padding-left: 30px;

                    p {
                        margin: 0;
                        color: #67768E;
                        font-size: 12px;
                        font-weight: 500;
                    }

                    label {
                        color: #111D31;
                        font-weight: 600;
                        font-size: 13px;
                    }
                }

                .total-item {
                    padding-left: 30px;

                    p {
                        margin: 0;
                        color: #67768E;
                        font-size: 12px;
                        font-weight: 500;
                    }

                    input {
                        background-color: #fff !important;
                        border: none;
                        color: #111d31;
                        font-weight: 600;
                        font-size: 13px;
                        min-width: 200px;
                    }

                    button {
                        position: relative;
                        border-radius: 5px;
                        border: 1px solid #ccd8ed;
                        background-color: #fff;
                        font-size: 12px;
                        padding: 4px 6px;
                        margin-left: 10px;
                        font-weight: 600;
                        color: #111D31;

                        .generic-tooltip-new {
                            bottom: 74px !important;
                            left: 57% !important;
                            min-width: 75px !important;
                        }
                    }

                    button:hover {
                        .generic-tooltip {
                            visibility: visible;
                            opacity: 1;
                        }
                    }

                    .copy-successed {
                        margin-left: 6px;
                        background: rgba(17, 29, 49, 0.9);
                        border-radius: 60px;
                        padding: 5.08px 8.12px;
                        color: #fff;
                        font-size: 12px;

                    }
                }
            }

            .view-link-btn {
                padding-left: 65px;

                .vbp-btn {
                    color: #3364ED;
                    font-size: 13px;
                    font-weight: 600;
                    text-decoration: underline;
                    padding-right: 20px;
                    cursor: pointer;
                }
            }
        }

        .ueu-sec {
            display: flex;
            justify-content: space-between;
            cursor: pointer;

            .ueus-left {
                display: flex;
                align-items: center;
                padding-bottom: 10px;

                .ueusl {
                    margin-right: 30px;

                    h3 {
                        font-size: 12px;
                        font-weight: 500;
                        color: #90a5c7;
                        margin: 0;
                    }

                    h5 {
                        font-size: 14px;
                        font-weight: 700;
                        color: #21314b;
                        margin: 0;
                    }
                }

                h4,
                .h4-failed {
                    font-size: 12px;
                    font-weight: 600;
                    margin: 0;
                    padding-left: 20px;
                    position: relative;
                }

                h4::before {
                    content: "";
                    position: absolute;
                    top: -3px;
                    left: -5px;
                    background-color: #90a5c761;
                    width: 1px;
                    height: 25px;
                }
            }

            .ueus-right {
                width: 23px;
                height: 23px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px;
                border: 1px solid #dee;
                cursor: pointer;
            }
        }

        .ueu-scroll {
            max-width: 100%;
            overflow: auto;
        }
    }

    .ue-upload-small-height{
        min-height: calc(100% - 260px);
        max-height: calc(100% - 260px);
    }

    .ue-bot {
        border-top: 1px solid #dee;
    }
}

// Profile
.user-profile {
    width: 100%;
    height: calc(100vh - 45px);
    display: flex;
    padding: 10px 10px 10px 25px;

    .up-left {
        width: 17%;
        min-width: 220px;
        height: 100%;
        background-color: #fff;
        margin-right: 10px;

        .upl-head {
            padding: 10px 20px;

            h3 {
                font-size: 20px;
                font-weight: 700;
                color: #12203c;
                margin: 0;
            }
        }

        .upl-det {
            display: flex;
            cursor: pointer;
            padding: 12px 20px 12px 10px;
            border-left: 3px solid transparent;

            span {
                width: 20px;
                margin-right: 10px;
            }

            p {
                font-size: 13px;
                // font-weight: 500;
                // color: #97abbf;
                margin: 0;
                color: $fontBlack;
            }
        }

        .upl-det:hover,
        .upl-active {
            border-left: 3px solid $primary-color;
            background-color: $primary-w-less-opacity;
            span {
                svg {
                    path {
                        // fill: #12203c;
                        fill: $primary-color;
                    }
                }
            }

            p {
                // color: #12203c;
                color: $primary-color;
                font-weight: 600;
            }
        }

        .setIconActive{
            svg {
                stroke: $primary-color;
            path {
                stroke: $primary-color;
            }
            circle{
                stroke: $primary-color;
            }
        }
        }
    }

    .up-right {
        width: 83%;
        height: 100%;
        overflow: auto;
        background-color: #fff;

        .upr-info {
            display: flex;
            align-items: center;
            padding: 10px 35px;

            .upri-name {
                width: 80px;
                height: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background: $primary-color;
                margin-right: 30px;

                span {
                    color: #ffff;
                    position: relative;
                    font-size: 26px;
                    font-weight: 700;
                }
            }

            .upri-det {
                h3 {
                    font-size: 16px;
                    font-weight: 700;
                    color: #3a5074;
                    margin: 0;
                }

                p {
                    color: #97abbf;
                    font-size: 12px;
                    font-weight: 500;
                }

                .generic-approve-btn {
                    height: 30px;
                    border-radius: 50px;
                }
            }
        }

        .upr-det {
            display: flex;
            flex-wrap: wrap;
            padding: 10px 35px;

            .uprd {
                display: grid;
                width: 50%;
                margin-bottom: 20px;

                label {
                    font-size: 12px;
                    color: #12203c;
                    font-weight: 500;

                    span {
                        cursor: pointer;
                        padding: 3px;
                        border-radius: 3px;

                        svg {
                            path {
                                fill: $primary-color;
                            }
                        }
                    }

                    span:hover {
                        background-color: $primary-color;

                        svg {
                            path {
                                fill: #fff;
                            }
                        }
                    }
                }

                .generic-input-box {
                    margin: 0;

                    .gib-inner {
                        border: none;
                        padding: 0;
                    }
                }

                input {
                    width: 100%;
                    height: 37px;
                    font-size: 12px;
                    font-weight: 500;
                    color: #3a5074;
                    border: 1px solid #dce6f0;
                    border-radius: 5px;
                    padding: 6px 10px;
                }

                input:focus {
                    border: 1px solid $primary-color;
                    box-shadow: 0px 0px 0px 2px #DADAFF;
                }

                input[disabled] {
                    border: 1px solid #ccd8ed;
                    font-weight: 600;
                    background-color: #fcfcfc;
                }

                input[disabled]:focus {
                    border: none;
                    box-shadow: none;
                }
                .gib-right-icon, .gen-input-clear {
                    position: absolute;
                    right: 10px;
                    z-index: 10;
                }
                .list-component-section{
                    top: 40px;
                }
            }
            
            .countryDropdown{
                .msa-input-outer{
                    height: 37px !important;;

                    input{
                        // width: 100%;
                        height: auto;
                        // font-size: 12px;
                        // font-weight: 500;
                        color: initial;
                        border: none;
                        border-radius: 0;
                        padding:0;
                    }
                    input:focus{
                        border: none;
                        box-shadow: none;
                    }
                    
                }
                .focusOn{
                    box-shadow: none !important;
                    border: none;
                }
            }

            .uprd:nth-child(odd){
                padding-right: 20px;
            }

            .uprd-bot {
                width: 100%;
                display: flex;
                justify-content: end;

                button {
                    margin-left: 10px;
                }
            }

            #parentModalMain {
                .success-msg {
                    display: flex;
                    align-items: center;
                    background: #05c46b;
                    padding: 10px 15px;

                    svg {
                        margin-right: 10px;
                    }

                    p {
                        font-weight: 600;
                        font-size: 14px;
                        color: #fff;
                        margin: 0;
                    }
                }

                .otp-form {
                    // .otp-head {
                    //     height: calc(100% - 160px);
                    //     overflow: auto;
                    // }
                }

                .generic-input-box {
                    padding-left: 15px;
                    height: calc(100% - 130px);

                    .gib-inner {
                        width: 100%;
                        padding: 0;

                        input {
                            border-radius: 4px;
                            padding: 0 10px;
                        }
                        .gibp-password-padding {
                            padding: 0 40px !important;
                        }

                        .react-tel-input {
                            font-family: "Poppins", sans-serif;
                            input {
                                padding-left: 45px;
                            }
                            .country-list {
                                overflow-x: hidden;
                                .search{
                                    padding-top: 10px;
                                    padding-bottom: 10px;
                                    padding-right: 1px;
                                    .search-emoji {
                                        position: absolute;
                                        left: 19px;
                                        top: 14px;
                                        visibility: hidden;
                                    }
                                    .search-emoji::before {
                                        content: '⌕';
                                        position: absolute;
                                        left: 3px;
                                        top: -11px;
                                        visibility: visible;
                                        font-size: 1.5rem;
                                        transform: rotateY(180deg);
                                    }
                                    .search-box{
                                        font-size: 12px;
                                        margin-left: 0;
                                        height: 30px;
                                        padding-left: 35px;
                                    }
                                }
                                .country *{
                                    margin-right: 10px;
                                }
                                .country, .no-entries-message{
                                    font-size: 12px;
                                    padding-left: 14px;
                                }
                                .country:hover, .highlight{
                                    background-color: $primary-w-less-opacity;
                                }
                            }
                        }
                        
                    }
                }
            }
        }

        .check-password {
            width: 45%;
            padding: 10px 20px;

            h2 {
                font-size: 18px;
                font-weight: 700;
                color: #21314b;
                margin: 0;
            }

            .cp-body {
                .justify-end {
                    justify-content: flex-start !important;
                }
            }
        }

        .upr-data-mapping {
            height: 100%;
            overflow: auto;
            padding: 10px 20px;

            .pdmt-head {
                h3 {
                    font-size: 18px;
                    font-weight: 700;
                    color: #21314b;
                    margin: 0;
                }
            }

            .pdmt-item {
                margin-top: 10px;
                border-radius: 6px;
                border: solid 1px #dee6f4;

                .pdmti-top {
                    padding: 10px 15px;

                    .pdmtit-heading {
                        display: flex;
                        align-items: center;
                        margin-bottom: 5px;

                        svg {
                            width: 20px;
                            margin-right: 10px;
                        }

                        h3 {
                            font-size: 16px;
                            font-weight: 700;
                            color: #21314b;
                            margin: 0;
                            margin-right: 10px;
                        }

                        .pdmtit-count {
                            display: flex;
                            width: 30px;
                            height: 30px;
                            background-color: #e6e1fe;
                            border-radius: 30px;
                            color: $primary-color;
                            font-size: 13px;
                            font-weight: 700;
                            justify-content: center;
                            align-items: center;
                        }
                    }

                    .pdmtit-text {
                        font-size: 11px;
                        font-weight: 500;
                        color: #778695;
                    }
                }

                .pdmti-body {
                    .table {
                        width: 100%;
                        margin: 0;

                        tbody {
                            tr {
                                td {
                                    border: none;

                                    input[type="text"] {
                                        width: auto;
                                        height: 32px;
                                        border: solid 1px #dee6f4;
                                        background-color: #e5eef724;
                                        border-radius: 5px;
                                        font-size: 12px;
                                        font-weight: 500;
                                        color: #21314b;
                                        padding: 2px 7px;
                                        margin: 8px 15px 8px 8px;
                                    }

                                    input.default-value {
                                        border: 1px solid $primary-color;
                                        background-color: #5D5FEF3f;
                                    }

                                    input:focus {
                                        border: 1px solid $primary-color;
                                        box-shadow: 0px 0px 0px 2px #DADAFF;
                                    }
                                }

                                .pdmti-depart {
                                    width: 85px;
                                    text-align: center;
                                    font-weight: 600;
                                    font-size: 12px;
                                    background-color: #f8f7fe;
                                    color: #21314b;
                                    border: solid 1px #dee6f4;
                                    border-left: none;
                                    border-bottom: none;
                                }

                                .pdmti-flex-td {
                                    border: 1px solid #dee6f4;
                                    border-radius: 0;
                                    padding: 0 8px;
                                    white-space: pre-wrap;
                                    border-right: none;
                                }
                            }

                            tr:first-child {
                                td {
                                    border-top: solid 1px #dee6f4;
                                }
                            }

                            tr:nth-child(2n+2) {
                                td {
                                    border-bottom: solid 1px #dee6f4;
                                }
                            }

                            tr:last-child {
                                td {
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Setting
.notification-handler {
    padding-left: 15px;

    .generic-tabs {
        background-color: #fff;
        padding: 0 30px;

        .activeTab {
            border-bottom: 2px solid $primary-color;
        }
    }

    .nh-modal {
        .nhm-top {
            display: flex;
            align-items: center;
            padding: 15px 30px;
            background-color: #fff;

            .nhmt-right {
                padding-left: 15px;

                h3 {
                    font-size: 14px;
                    font-weight: 700;
                    color: #0F172A;
                    margin: 0;
                }

                p {
                    color: #97abbf;
                    font-size: 12px;
                    font-weight: 500;
                    margin: 0;
                }
            }
        }

        .nhm-body {
            padding: 10px 30px;

            .generic-spread-form {
                width: 50%;
                background-color: #fff;
                border-radius: 6px;
                box-shadow: 0 0 7px 3px hsla(0, 0%, 90%, .5);

                .gsf-body {
                    height: 100%;
                    padding: 10px 0;

                    .generic-form-body {
                        .gfb-inner {
                            .generic-input-box {
                                margin-bottom: 0;

                                .gen-input-disabled {
                                    cursor: not-allowed;

                                    input {
                                        cursor: not-allowed !important;
                                    }
                                }
                            }
                        }
                    }

                    .generic-form-body:nth-child(3),
                    .generic-form-body:nth-child(4) {
                        .gfb-inner {
                            .generic-input-box {
                                width: 100%;
                            }
                        }
                    }

                    .gsfb-note {
                        padding: 15px;

                        h5 {
                            color: #0F172A;
                            font-size: 12px;
                            font-weight: 600;
                            margin: 0;
                        }

                        p {
                            color: #0F172A;
                            font-size: 12px;
                            font-weight: 500;
                            margin: 0;
                        }
                    }
                }
            }
        }

        .no-data-found-screen {
            padding-top: 50px;
        }
    }
}

// Escalation Matrix
.escalation-matrix {
    height: 100%;

    .em-body {
        height: calc(100% - 54px);
        overflow: auto;
        display: flex;
        gap: 15px;
        padding: 10px 15px 15px;

        .emb-cards {
            width: 100%;
            padding: 10px 15px;
            box-shadow: 0px 0px 16px 0px rgba(164, 164, 164, 0.25);

            h1 {
                width: fit-content;
                font-size: 12px;
                font-weight: 600;
                padding: 2px 7px;
                margin-bottom: 10px;
            }

            .h1-blue {
                color: #5256E2;
                background: #E5E9F5;
            }

            .h1-orange {
                color: #AD7B1E;
                background: #FEEAC5;
            }

            .h1-red {
                color: $danger-color;
                background: #FEC3CD;
            }

            h2 {
                color: #000;
                font-size: 17px;
                font-weight: 700;
                margin: 0;
                white-space: nowrap;
            }

            .embc-det {
                padding-top: 20px;

                .embcd-sec {
                    display: flex;
                    align-items: flex-start;
                    padding-bottom: 15px;

                    .embcds-icons{
                        padding-right: 10px;
                        svg{
                            path{
                                fill: black;
                            }
                            g{
                                path{
                                    fill: none;
                                    stroke: black;
                                }
                            }
                        }
                    }

                    label {
                        color: #67768E;
                        font-size: 10px;
                        font-weight: 400;
                        margin: 0;
                    }

                    p {
                        display: flex;
                        align-items: center;
                        color: #000;
                        font-size: 12px;
                        font-weight: 600;
                        margin: 0;
                        button{
                            position: relative;
                            margin-left: 5px;
                            .generic-tooltip{
                                left: 40%;
                            }
                        }
                        button:hover{
                            .generic-tooltip{
                                visibility: visible;
                                opacity: 1;
                            }
                        }
                    }
                    p:hover{
                        a:hover {
                            color: #000;
                            text-decoration: underline;
                        }
                        button{
                            svg{
                                g{
                                    path{
                                        stroke: $primary-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}