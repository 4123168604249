@import '../variables.scss';


// Style Email Report
.email-report-modal {
    display: block;
    width: 480px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-color: #fff;
    z-index: 99;
    border: none;
    box-shadow: none;
    border-radius: 0;
    .erm-head {
        width: 100%;
        display: block;
        padding: 15px 25px;
        h3{
            font-size: 16px;
            font-weight: 700;
            color: #12203c;
            display: inline-block;
            margin: 0;
            line-height: 25px;
        }
        .ermh-close{
            display: flex;
            align-items: center;
            position: relative;
            float: right;
            cursor: pointer;
            .ermh-esc {
                font-size: 12px;
                font-weight: 500;
                color: #969fad;
                position: relative;
                margin-right: 15px;
            }
            .ermh-esc:after{
                content: "";
                position: absolute;
                top: 4px;
                right: -8px;
                width: 2px;
                height: 10px;
                background: #969fad47;
            }
            img{
                padding: 6px 4px;
                cursor: pointer;
            }
        }
    }
    .erm-body {
        display: block;
        width: 100%;
        padding: 10px 25px;
        h3{
            font-size: 14px;
            font-weight: 700;
            color: #12203c;
            display: inline-block;
            margin: 0;
            line-height: 25px;
        }
        .ermb-input-field {
            display: block;
            width: 100%;
            border-radius: 6px;
            border: solid 1px #ccd8ed;
            background-color: #ffffff;
            height: 40px;
            padding: 3px 6px;
            font-size: 12px;
            font-weight: 500;
            color: #21314b;
            margin-bottom: 10px;
            input {
                display: block;
                width: 100%;
                border-radius: 6px;
                background-color: #ffffff;
                height: 32px;
                padding: 3px 6px;
                font-size: 12px;
                font-weight: 500;
                color: #21314b;
            }
            .ermbif-tag {
                display: inline-block;
                border-radius: 5px;
                background-color: #d1dff8;
                padding: 3px 5px;
                font-size: 11px;
                font-weight: 500;
                color: #12203c;
                min-width: 191px;
                margin-bottom: 10px;
                margin-right: 10px;
                .ermbift-mailid {
                    margin-right: 10px;
                }
                img{
                    cursor: pointer;
                    float: right;
                    padding: 3px 4px;
                }
            }
        }
        .ermb-dashdata {
            display: block;
            h3 {
                font-size: 13px;
                font-weight: bold;
                color: #12203c;
                margin: 0;
            }
            .ermbdd-inner {
                display: block;
                padding: 12px 0;
                position: relative;
                .ermbddi-checkbox {
                    display: inline-block;
                    margin-right: 20px;
                    .checkBoxLabel0 {
                        font-size: 12px;
                        font-weight: 500;
                        color: #12203c;
                        padding-left: 0;
                        cursor: pointer !important;
                        margin-left: 24px;
                        .checkmark1 {
                            position: absolute;
                            left: -25px;
                            top: 0;
                            height: 16px;
                            width: 16px;
                            background-color: #fff;
                            cursor: pointer;
                            border-radius: 4px;
                            border: 2px solid #dce6f0;
                        }
                        .checkmark1:after{
                            content: none;
                        }
                        input:checked~.checkmark1{
                            background-color: $primary-color;
                            border: none;
                        }
                        input:checked~.checkmark1:after {
                            content: "";
                            display: block;
                            position: absolute;
                            left: 6px;
                            top: 3px;
                            width: 5px;
                            height: 9px;
                            border-width: 0 2px 2px 0;
                            transform: rotate(45deg);
                        }
                    }
                }
            }
            .check-panel{
                display: block;
                border-radius: 6px;
                padding: 10px 10px 0;
                border: 1px solid #ccd8ed;
                margin-bottom: 20px;
                .cp-checkbox{
                    position: relative;
                    .cpc-head{
                        position: relative;
                        cursor: pointer;
                        .checkBoxLabel0 {
                            font-size: 12px;
                            font-weight: 500;
                            color: #12203c;
                            padding-left: 0;
                            cursor: pointer !important;
                            margin-left: 24px;
                            margin-bottom: 10px;
                            .checkmark1 {
                                position: absolute;
                                left: -25px;
                                top: 0;
                                height: 16px;
                                width: 16px;
                                background-color: #fff;
                                cursor: pointer;
                                border-radius: 4px;
                                border: 2px solid #dce6f0;
                            }
                            .checkmark1:after{
                                content: none;
                            }
                            input:checked~.checkmark1{
                                background-color: $primary-color;
                                border: none;
                            }
                            input:checked~.checkmark1:after {
                                width: 5px;
                                height: 9px;
                                border-width: 0 2px 2px 0;
                                content: "";
                            }
                        }
                        img{
                            position: absolute;
                            top: 5px;
                            right: 10px;
                        }
                    }
                    .cpc-body{
                        position: relative;
                        padding-top: 10px;
                        border-top: 1px solid #ccd8ed;
                        transition: 0.6s all;
                        .checkBoxLabel0 {
                            font-size: 12px;
                            font-weight: 500;
                            color: #12203c;
                            padding-left: 0;
                            cursor: pointer !important;
                            margin-left: 24px;
                            margin-right: 20px;
                            .checkmark1 {
                                position: absolute;
                                left: -25px;
                                top: 0;
                                height: 16px;
                                width: 16px;
                                background-color: #fff;
                                cursor: pointer;
                                border-radius: 4px;
                                border: 2px solid #dce6f0;
                            }
                            .checkmark1:after{
                                content: none;
                            }
                            input:checked~.checkmark1{
                                background-color: $primary-color;
                                border: none;
                            }
                            input:checked~.checkmark1:after {
                                width: 5px;
                                height: 9px;
                                border-width: 0 2px 2px 0;
                                content: "";
                            }
                        }
                    }
                }
            }
        }
    }
    .aerm-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 25px;
        border-bottom: solid 1px #b4c5d6;
        .aermh-left {
            h3{
                font-size: 14px;
                font-weight: 700;
                color: #12203c;
                margin: 0;
            }
        }
        .aermh-right {
            display: flex;
            align-items: center;
            .aermh-manage {
                font-size: 12px;
                font-weight: 600;
                height: 32px;
                padding: 5px 15px;
                border-radius: 4px;
                background-color: #fff;
                border: solid 1px #dee6f4;
                margin-right: 15px;
            }
            .aermh-manage:hover {
                background-color: #f5f5f5;
            }
            .ermh-esc {
                font-size: 12px;
                font-weight: 500;
                color: #969fad;
                position: relative;
                margin-right: 15px;
            }
            .ermh-esc:after{
                content: "";
                position: absolute;
                top: 4px;
                right: -8px;
                width: 2px;
                height: 10px;
                background: #d6dce6;
            }
            svg{
                cursor: pointer;
            }
        }
    }
    .erm-scheduler {
        display: flex;
        justify-content: flex-end;
        padding: 15px 25px;
        border-bottom: solid 1px #b4c5d6;
        .nph-text{
            font-size: 12px;
            font-weight: 600;
            color: #778695;
            margin-left: 5px;
            padding-top: 2px;
        }
    }
    .aerm-body {
        max-height: calc(100% - 170px);
        overflow: auto;
        .aermb-row {
            margin-bottom: 20px;
            position: relative;
            .generic-smart-input{
                .error{
                    border: none !important;
                }
            }
            .cron_builder {
                width: 100%;
                border: none;
                background: #fff;
                padding: 5px;
                ul, .nav {
                    border-bottom: 1px solid #eaeaea;
                    padding: 0;
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 0;
                    list-style: none;
                    cursor: pointer;
                    li {
                        a {
                            border: none;
                            font-size: 12px;
                            font-weight: 500;
                            color: #788090;
                            padding: 10px 15px;
                        }
                        a:hover, a:focus {
                            background-color: transparent;
                        }
                    }
                    li.active {
                        transform: translateX(0);
                        a {
                            color: #000;
                            font-weight: 600;
                        }
                        a:after {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            width: 15px;
                            height: 3px;
                            background-color: $primary-color;
                            transform: translate(-50%);
                        }
                    }
                    li:first-child {
                        a {
                            padding-left: 0;
                        }
                        a:after {
                            transform: translate(-100%);
                        }
                    }
                }
                .nav::before{
                    display: table;
                    content: " ";
                }
                .nav > li {
                    position: relative;
                    display: block;
                    padding: 10px 0;
                }
                .cron_builder_bordering {
                    border: none;
                    background: #fff;
                    padding: 0px;
                    .tab-pane {
                        text-align: left;
                        font-size: 12px;
                        font-weight: 500;
                    }
                    span {
                        text-align: left;
                        font-size: 12px;
                        font-weight: 500;
                    }
                    select{
                        border-radius: 5px;
                        border: 1px solid #ccd8ed;
                        background-color: #fff;
                        height: 30px;
                        width: 60px;
                        text-align: left;
                        font-weight: 500;
                        font-size: 12px;
                        background-position: 87%;
                        background-repeat: no-repeat;
                        padding: 4px 23px 4px 7px;
                        margin-left: 10px;
                        -webkit-appearance: none;
                    }
                    .container-fluid {
                        padding: 0;
                        text-align: left;
                        font-size: 12px;
                        font-weight: 500;
                        .well-small {
                            .span6.col-sm-6 {
                                padding: 0;
                                .text-left {
                                    margin-left: 21px;
                                    input[type=checkbox] {
                                        display: inline-block;
                                        margin-left: 0;
                                        margin-top: 2px;
                                        position: absolute;
                                        left: 0;
                                        top: auto;
                                    }
                                }
                            }
                        }
                        span {
                            text-align: left;
                            font-size: 12px;
                            font-weight: 500;
                        }
                    }
                    .well {
                        height: auto;
                        width: auto;
                        padding: 16px 0;
                        margin: 0;
                        border: none;
                        background: #fff;
                        text-align: left;
                        font-size: 12px;
                        font-weight: 500;
                        box-shadow: none;
                        input[type="Number"] {
                            border-radius: 5px;
                            border: 1px solid #ccd8ed;
                            background-color: #fff;
                            height: 30px;
                            width: 100px !important;
                            text-align: left;
                            font-weight: 500;
                            font-size: 12px;
                            padding: 4px 7px;
                            margin-left: 10px;
                            margin: 0 10px;
                            color: #21314b;
                        }
                        .text_align_right.row {
                            display: block;
                            text-align: left;
                            padding: 0 15px;
                        }
                    }
                    input[type=radio] {
                        margin: 0 5px 0 0;
                        vertical-align: middle;
                        position: relative;
                    }
                }
                .cron-builder-bg {
                    margin-top: 20px;
                    background: #c8f9e6;
                    color: #000;
                    font-size: 12px;
                    font-weight: 500;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 7px 5px;
                    min-height: 34px;
                }
            }
            span.date-range {
                display: block;
                margin: 0px 3px 8px 3px;
                .radio-label {
                    border-radius: 5px;
                    background-color: #fff;
                    font-size: 12px;
                    padding: 8px 10px;
                    margin: 8px 8px 0px 0px;
                    font-weight: 500;
                    position: relative;
                    display: inline-block;
                    text-align: center;
                    cursor: pointer !important;
                    input[type="radio"] {
                        display: none;
                    }
                    .radio-text {
                        font-size: 12px;
                        color: #12203c;
                        font-weight: 500;
                        position: relative;
                        z-index: 1;
                        cursor: pointer;
                    }
                    .radio-check:before {
                        content: "";
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-color: #fff;
                        border: 1px solid #ccd8ed;
                        border-radius: 5px;
                        z-index: 0;
                        cursor: pointer;
                    }
                    input:checked~.radio-text {
                        font-weight: 600;
                        color: $primary-color;
                    }
                    input:checked~.radio-check:before {
                        border: 2px solid $primary-color;
                    }
                }
            }
            .dropdown-menu-city1.gen-width-auto{
                height: 200px;
                top: 41px;
            }
        }
        .ist-update{
            position: relative;
            margin-top: 10px;
            .utc-time{
                background: rgba(233, 170, 21, 0.1);
                border-radius: 10px;
                padding: 10px 10px 10px 20px;
                font-weight: 600;
                font-size: 13px;
                color: #21314B;
            }
            .utc-time:before{
                content: "|";
                position: absolute;
                top: 10px;
                left: 10px;
                color: #F6B316;
                font-weight: 700;
            }          
        }
        label {
            font-size: 12px;
            font-weight: 600;
            color: #21314b;
        }
        .aermb-input {
            display: block;
            width: 100%;
            border-radius: 6px;
            border: solid 1px #ccd8ed;
            background-color: #ffffff;
            height: 32px;
            padding: 3px 6px;
            font-size: 12px;
            font-weight: 500;
            color: #21314b;
        }
        .aermb-input-field {
            border-radius: 6px;
            border: solid 1px #ccd8ed;
            background-color: #ffffff;
            max-height: 100px;
            padding: 3px 6px;
            margin-bottom: 20px;
            overflow: auto;
            .aermb-tag {
                display: inline-block;
                border-radius: 5px;
                background-color: #ffffff;
                border: solid 1px #a4b9dd;
                padding: 3px 5px;
                font-size: 11px;
                font-weight: 500;
                color: #3a5074;
                min-width: 100px;
                margin-bottom: 6px;
                margin-right: 10px;
                .aermb-mailid {
                    margin-right: 5px;
                }
                img{
                    cursor: pointer;
                    float: right;
                    padding: 4px 4px;
                    width: 17px;
                }
            }
            .aermb-tag:hover {
                background-color: $primary-color;
                border: solid 1px $primary-color;
                color: #fff;
                transition: 0.6s all;
                img {
                    filter: brightness(40);
                }
            }
            input {
                display: inline-block;
                width: 50px;
                border-radius: 0;
                border: none;
                background-color: #ffffff;
                height: 25px;
                padding: 1px 3px;
                font-size: 12px;
                font-weight: 500;
                color: #21314b;
            }
        }
        .scheduled-item {
            border-radius: 8px;
            box-shadow: 0 0 16px 0 #cddaef;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            .si-left {
                padding: 10px 15px;
                border-right: solid 1px #dee6f4;
                width: 72%;
                .sil-top {
                    h3 {
                        font-size: 14px;
                        font-weight: 700;
                        color: #12203c;
                        display: block;
                        margin: 0;
                        line-height: normal;
                    }
                    .silt-mail {
                        display: block;
                        font-size: 12px;
                        font-weight: 500;
                        color: #969fad;
                        margin: 2px 0;
                        .siltm-count {
                            font-size: 10px;
                            color: #21314b;
                        }
                    }
                    .silt-enabled {
                        position: relative;
                        font-size: 12px;
                        font-weight: 500;
                        color: #2681ff;
                        margin-left: 12px;
                    }
                    .silt-disabled {
                        position: relative;
                        font-size: 12px;
                        font-weight: 500;
                        color: #ff7703;
                    }
                    .silt-enabled::before {
                        content: "";
                        position: absolute;
                        top: 7px;
                        left: -11px;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background-color: #2681ff;
                    }
                }
                .sil-bottom {
                    margin-top: 37px;
                    span {
                        font-size: 11px;
                        font-weight: 500;
                        color: #6a7385;
                        position: relative;
                        margin-right: 7px;
                    }
                    .silb-text {
                        margin-right: 15px;
                    }
                    .silb-text::after {
                        content: "";
                        position: absolute;
                        top: 2px;
                        right: -8px;
                        width: 1px;
                        height: 10px;
                        background-color: #b4c5d6;
                    }
                }
            }
            .si-right {
                width: 28%;
                padding: 10px 15px;
                button {
                    display: block;
                    font-size: 11px;
                    font-weight: 600;
                    color: #21314b;
                    background-color: #fff;
                    height: 28px;
                    border: solid 1px #ccd8ed;
                    border-radius: 4px;
                    width: 100%;
                }
                button:hover {
                    color: #ffffff;
                    background-color: $primary-color;
                    border: solid 1px $primary-color;
                    transition: 0.6s all;
                }
                .sir-edit {
                    margin: 15px 0;
                }
            }
        }
    }
    .vder-body{
        overflow: auto;
        max-height: 80vh;
        h3 {
            font-size: 13px;
        }
    }
    .erm-footer {
        display: block;
        padding: 10px 25px;
        position: absolute;
        bottom: 10px;
        background-color: #fff;
        button.ermf-send {
            border-radius: 5px;
            border: solid 1px $primary-color;
            background-color: $primary-color;
            height: 36px;
            font-size: 12px;
            font-weight: 600;
            color: #fff;
            width: 310px;
            margin-right: 20px;
        }
        button.ermf-clear {
            height: 36px;
            font-size: 12px;
            font-weight: 600;
            width: 100px;
            border-radius: 5px;
            border: solid 1px #ccd8ed;
            background-color: #ffffff;
            color: #12203c;
        }
    }
    .drm-body {
        display: block;
        padding: 0;
        border-top: 1px solid #dae4ee;
        border-bottom: 1px solid #dae4ee;
        height: calc(100% - 249px);
        overflow: auto;
        .drm-checkbox {
            padding: 15px 25px;
            border-bottom: 1px solid #dae4ee;
            height: calc(100% - 121px);
            overflow: auto;
        }
        .checkBoxLabel0{
            width: auto;
            display: block !important;
            height: 16px;
            padding-left: 25px;
            font-size: 12px;
            font-weight: 500;
            color: #12203c;
            cursor: pointer !important;
            margin: 0px;
            margin-bottom: 15px;
            .checkmark1{
                left: 0;
                top: 0;
                height: 16px;
                width: 16px;
                background-color: #ffffff;
                cursor: pointer;
                border-radius: 4px;
                border: solid 2px #dce6f0;
            }
            .checkmark1:after{
                width: 5px;
                height: 9px;
                border-width: 0 2px 2px 0;
                content: none;
            }
            input:checked~.checkmark1{
                background-color: $primary-color;
                border: none;
            }
            input:checked~.checkmark1:after{
                width: 5px;
                height: 9px;
                border-width: 0 2px 2px 0;
                content: "";
            }
        }
        .drm-select-all {
            font-weight: 600;
        }
        .drm-filters {
            padding: 15px 25px;
            h3 {
                font-size: 16px;
                font-weight: 700;
                color: #21314b;
                margin-bottom: 15px;
            }
            div.drmf-date {
                width: 70%;
                margin-bottom: 15px;
                label {
                    font-size: 12px;
                    font-weight: 600;
                    color: #21314b;
                }
                .ant-picker {
                    border-radius: 4px;
                    border: solid 1px #dae4ee;
                }
                .errorBorder {
                    border: 1px solid #c44569 !important;
                }
            }
            .other-applied-filter{
                padding: 15px 15px 10px;
                margin-top: 15px;
                border-radius: 5px;
                border: solid 1px #dae4ee;
                .applied-body{
                    h3{
                        margin-bottom: 10px;
                        font-size: 15px;
                    }
                    .oaf-status{
                        margin-bottom: 10px;
                        h3{
                            color: #67768e;
                            font-size: 13px;
                            margin-bottom: 7px;
                        }
                        .show-applied-filter{
                            button{
                                margin-top: 0px;
                            }
                            button:hover{
                                .generic-tooltip{
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                    }
                }
            }
            div.drmf-number {
                width: 70%;
                margin-bottom: 15px;
                font-size: 12px;
                label {
                    font-size: 12px;
                    font-weight: 600;
                    color: #21314b;
                }
                div {
                    display: flex;
                    justify-content: space-between;
                    height: 34px;
                    input {
                        width: 48%;
                        border-radius: 4px;
                        border: 1px solid #dae4ee;
                        padding: 0 10px;
                    }
                }
            }
        }
    }
    .erm-search-fields {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 25px;
        border-top: solid 1px #dae4ee;
        .gen-pi-formate {
            padding: 0px;
        }
        select {
            height: 34px;
            border-radius: 4px;
            width: 125px;
            border: 1px solid #dae4ee;
            font-size: 12px;
            font-weight: 600;
            color: #21314b;
            background-position: 95%;
            background-repeat: no-repeat;
            padding: 3px 5px;
        }
        button {
            height: 34px;
            border-radius: 3px;
            border: 1px solid #dae4ee;
            width: 290px;
            background-color: #fff;
            cursor: text;
            display: flex;
            align-items: center;
            input[type=text] {
                background: #fff;
                border: none;
                width: 255px;
                margin-left: 6px;
                height: 28px;
                font-size: 12px;
                font-weight: 600;
                color: #21314b;
            }
        }
    }
    .erm-footerv2 {
        display: block;
        padding: 20px 25px;
        background-color: #fff;
        .generic-approve-btn {
            width: 100%;
            height: 38px;
        }
    }
}

// Style General Setting Page Tab PI/PO Formate
.gen-pi-formate {
    padding: 10px 0;
    .gpf-radio-list {
        padding: 0;
        margin-bottom: 0;
        li {
            display: inline-block;
            position: relative;
            margin-right: 40px;
            .gen-radio-btn {
                margin: 0;
                font-size: 13px;
                font-weight: 500;
                color: #12203c;
                padding-left: 25px;
                cursor: pointer !important;
                input[type="radio"] {
                    display: none;
                }
                .checkmark {
                    content: "";
                    position: absolute;
                    top: 2px;
                    left: 0;
                    height: 15px;
                    width: 15px;
                    border: 2px solid #D6DCE6;
                    border-radius: 50%;
                }
                .checkmark:before {
                    content: "";
                    position: absolute;
                    top: 3px;
                    left: 3px;
                    width: 5px;
                    height: 5px;
                    display: block;
                    border-radius: 50%;
                    background: #D6DCE6;
                }
                input:checked~.checkmark {
                    background-color: #fff;
                    border: 2px solid $primary-color;
                }
                input:checked~.checkmark:before {
                    background: $primary-color;
                }
                input:checked~.checkeddisabled {
                    border: 2px solid #5D5FEF8c;
                }
                input:checked~.checkeddisabled:before {
                    background: #5D5FEF8c;
                }
            }
        }
        .buff-days-inc-dec{
            display: inline-block;
            .bdid-right{
                display: flex;
                align-items: center;
                label{
                    margin: 0 10px;
                    font-size: 13px;
                    font-weight: 500;
                    color: #12203c;
                }
            }
        }
        .mlft103{
            margin-left: 103px;
        }
        .gpfd-create {
            display: inline-block;
            input[type="text"] {
                border-radius: 6px;
                border: 1px solid #d6dce6;
                background-color: #fff;
                display: inline-block;
                padding: 6px 10px;
                min-width: 120px;
                font-size: 12px;
                font-weight: 600;
                width: 100px;
                margin: 0 10px;
                color: #12203c;
            }
            label {
                margin: 0;
                font-size: 13px;
                font-weight: 500;
                color: #12203c;
            }
        }
        .mlft93{
            margin-left: 93px;
        }
    }
    .gpf-check-list {
        padding: 0;
        margin-bottom: 0;
        li {
            display: inline-block;
            position: relative;
            margin-right: 40px;
            .checkBoxLabel0{
                width: auto;
                height: 16px;
                font-size: 13px;
                font-weight: 500;
                color: #12203c;
                padding-left: 25px;
                margin: 0px;
                cursor: pointer!important;
                .checkmark1 {
                    left: 0px;
                    top: 0px;
                    height: 16px;
                    width: 16px;
                    background-color: #ffffff;
                    cursor: pointer;
                    border-radius: 4px;
                    border: solid 2px #dce6f0;
                }
                .checkmark1:after {
                    width: 5px;
                    height: 9px;
                    border-width: 0 2px 2px 0;
                    content: none;
                }
                input:checked~.checkmark1 {
                    background-color: $primary-color;
                    border: none;
                }
                input:checked~.checkmark1:after{
                    width: 5px;
                    height: 9px;
                    border-width: 0 2px 2px 0;
                    content: "";
                }
            }
        }
    }
    .gpf-dbs{
        h3 {
            font-size: 14px;
            font-weight: 600;
            color: #12203c;
            margin: 0;
        }
        .gpfdbs-piform{
            h5 {
                font-size: 14px;
                font-weight: 600;
                color: #12203c;
                margin: 0;
            }
        }
    }
}