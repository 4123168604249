:root {
    --primary-color: #5d5fef;
    --danger-color: #fd3757;
    --dropdown-item-hover: #f2f3fb;
    --primary-w-less-opacity: rgba(93, 95, 239, 0.08);
    --danger-w-less-opacity: #fd37571f;
    --primary-light: rgba(93, 95, 239, 0.17);
    --fontBlack: #0F172A;
    --success-color: #20BF6B;
    --fontGrey: #67768E;
    --borderSlateGrey: #DAE4EE;
  }
  
// ---> primary
$primary-color: var(--primary-color);
// $primary-color: #FF0000;
$primary-w-less-opacity: var(--primary-w-less-opacity);
$primary-light: var(--primary-light);
// $primary-hover: rgba($primary-color, 0.75);

// ---> danger
$danger-color: #fd3757;
// $danger-color: yellow;
$danger-w-less-opacity: var(--danger-w-less-opacity);

// ---> success
$success-color: #20BF6B;

// ---> dropdown
// $dropdown-item-hover: #f2f3fb;
$dropdown-item-hover: var(--primary-w-less-opacity);
// $dropdown-item-hover: green;

// ---> icons
// $icon-primary: grey;
// $icon-secondary: black;

// ---> font size
// $font-size-base: 16px;
$fontBlack: var(--fontBlack);
$fontGrey: var(--fontGrey);

// border colors
$borderSlateGrey: var(--borderSlateGrey);
