@import '../variables.scss';

.generic-view-modal {
    height: 100%;
    overflow: hidden;

    h2 {
        font-size: 15px;
        font-weight: 700;
        color: #0F172A;
        margin: 0 0 0 15px;
    }

    .gvm-scroll {
        height: calc(100% - 116px);
        overflow: auto;

        .gvm-body {
            display: flex;
            flex-wrap: wrap;
            padding: 10px 15px 0;
            overflow: auto;

            .gvmb {
                padding-bottom: 25px;

                label {
                    font-size: 12px;
                    font-weight: 500;
                    color: #94a5c6;
                    margin-bottom: 2px;
                }

                p {
                    font-size: 12px;
                    font-weight: 600;
                    color: #0F172A;
                    margin: 0;
                }
            }
        }
    }
}

// Set Roles Modal
.set-roles-modal {
    .lcs-inner {
        height: calc(100% - 148px);
    }
}

// Auto ASN Details
.auto-asn-details{
    height: 100%;
    .table-component-new-style{
        min-height: auto;
        height: calc(100% - 54px);
        overflow: auto;
        .main-bread-crumps{
            display: none;
        }
        .tableBody-border{
            .imh-update{
                padding-left: 15px;
            }
            .invoice-manage-table{
                padding-left: 15px;
            }
        }
    }
}


// requestOnboardingModal

.requestOnboardingModal {
    .addUserModal {
        .gen-rowtype-component .grc-body {
            .grcb-head .gib-label-heading {
                // width: auto !important;
                // min-width: 85px !important;
                width: 80px !important;
            }
             .generic-form-body .generic-input-box {
                width: 80px !important;
                // min-width: 85px !important;
            }
        }
    }
}