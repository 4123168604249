@import '../variables.scss';

// Logging in to Supplymint
.sm-alerts {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .sa-img {
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
        height: 150px;
    }

    h3 {
        color: #0F172A;
        font-size: 15px;
        font-weight: 500;
    }

    .full-page-loader {
        position: relative;
        display: flex;
        justify-content: center;
        padding-top: 100px;
        height: auto;
        z-index: 0;
    }
}

// User Offline 
.user-offline,
.user-online {
    width: 100%;
    position: absolute;
    bottom: 0;
    text-align: center;
    background: #fff;
    padding: 0;
    z-index: 999;
    animation: slideInFromBottom 0.5s ease-in-out;
    display: flex;
    align-items: center;
    .uo-left {
        padding: 10px;
    }
    .uo-right {
        padding: 8px 0;
    }
    h2, p{
        color: #000000;
        margin: 0;
        text-align: left;
    }
    h2 {
        font-size: 18px;
        font-weight: 600;
    }
    p {
        font-size: 12px;
        font-weight: 400;
    }
}
.user-online{
    // background: #03B675;
    animation: alertClose 1s ease-in-out;
    animation-delay: 1s;
}

@keyframes alertClose {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(100%);
    }
}

// Error Page
.page-error {
    height: calc(100vh - 45px);
    position: relative;
    padding-left: 20px;
    background: #fff;

    .pe-btn {
        text-align: end;
        padding: 20px 60px 30px;

        button {
            color: #0F172A;
            font-size: 12px;
            font-weight: 700;
            border: 1px solid #e4e4e4;
            border-radius: 20px;
            background-color: #fff;
            padding: 5px 15px;
        }

        button:hover {
            background-color: #f5f5f5;
        }
    }

    .pe-content {
        text-align: center;

        h1 {
            font-size: 60px;
            font-weight: 700;
            color: #414141;
        }

        h3 {
            font-size: 15px;
            margin-top: 3%;
            font-weight: 600;
            color: #0F172A;
        }

        p {
            color: #0F172A;
            font-size: 13px;
            font-weight: 400;
            margin-top: 1%;
        }
    }

    .pe-footer {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #fff;

        .pef-svg {
            display: flex;
            justify-content: center;

            svg {
                g {
                    .rotatingLight {
                        -webkit-animation: rotatingLight 2s linear infinite;
                    }

                    @-webkit-keyframes rotatingLight {
                        100% {
                            transform: rotate(10deg);
                        }

                        50% {
                            -webkit-transform: rotate(-10deg);
                            -o-transform: rotate(-10deg);
                            transform: rotate(-10deg);
                        }

                        0% {
                            -webkit-transform: rotate(10deg);
                            -o-transform: rotate(10deg);
                            transform: rotate(10deg);
                        }
                    }

                    @keyframes rotatingLight {
                        100% {
                            transform: rotate(10deg);
                        }

                        50% {
                            -ms-transform: rotate(-10deg);
                            -moz-transform: rotate(-10deg);
                            -webkit-transform: rotate(-10deg);
                            -o-transform: rotate(-10deg);
                            transform: rotate(-10deg);
                        }

                        0% {
                            -ms-transform: rotate(10deg);
                            -moz-transform: rotate(10deg);
                            -webkit-transform: rotate(10deg);
                            -o-transform: rotate(10deg);
                            transform: rotate(10deg);
                        }
                    }

                    .rotatingLight {
                        -webkit-animation: rotatingLight 2s linear infinite;
                        -moz-animation: rotatingLight 2s linear infinite;
                        -ms-animation: rotatingLight 2s linear infinite;
                        -o-animation: rotatingLight 2s linear infinite;
                        animation: rotatingLight 2s linear infinite;
                    }

                }
            }
        }

        .pef-img {
            height: 30vh;

            img {
                height: 100%;
                width: 100%;
            }
        }
    }
}

// Crash Page
.page-crashed {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;
    position: relative;
    z-index: 999;

    label {
        color: #111D31;
        text-align: center;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
    }

    h1 {
        color: #111D31;
        font-size: 53px;
        font-weight: 700;
    }

    p {
        color: #111D31;
        font-size: 17px;
        font-weight: 500;
        line-height: 35px;
        letter-spacing: 0.51px;
        max-width: calc(100% - 290px);
    }

    .pcc-btns {
        display: flex;
        padding: 50px 0;

        .home-btn,
        .issue-btn {
            display: flex;
            align-items: center;
            border-radius: 4px;
            border: 1px solid #CED5E1;
            background-color: #FFF;
            color: #111D31;
            font-size: 15px;
            font-weight: 600;
            margin-right: 15px;
            padding: 10px 40px;

            svg {
                margin-right: 7px;
            }
        }

        .home-btn:hover {
            background-color: #f5f5f5;
        }

        .issue-btn {
            border-radius: 4px;
            background-color: #666AF6;
            color: #fff;
        }

        .issue-btn:hover {
            background-color: #5355E5 !important;
        }
    }

    .pcc-help {
        width: auto;

        button {
            width: auto;
            display: block;
            background-color: #fff;
            padding: 0 0 15px 0;
            text-align: left;

            label {
                color: #111D31;
                font-size: 14px;
                font-weight: 600;
                margin: 0;
                cursor: pointer;
            }

            svg {
                margin-left: 10px;
            }

            p {
                color: #6B7687;
                font-size: 13px;
                font-weight: 400;
                margin: 0;
                white-space: nowrap;
            }
        }
    }
}

// Cache Checker
.notification-alert {
    width: auto;
    max-width: 480px;
    position: fixed;
    bottom: 40px;
    right: 40px;
    // border: 1px solid #E3E4E7;
    background: #FFF;
    z-index: 999;
    box-shadow: 0px 4px 21px -2px rgba(63, 62, 62, 0.15);
    padding: 20px 15px;

    .na-top {
        display: flex;
        align-items: start;
        .nat-det{
            padding-left: 15px;
            h1 {
                color: #0F172A;
                font-size: 15px;
                font-weight: 600;
                margin-bottom: 5px;
            }
            p{
                color: #575D6A;
                font-size: 12px;
                font-weight: 400;
            }
            .natd-btns{
                padding: 5px 0 0;
                button{
                    height: 34px;
                    border-radius: 0 !important;
                    margin-right: 10px;
                    letter-spacing: 0.4px;
                    padding: 2px 8px !important;
                }
            }
        }
        .nat-close-btn{
            padding: 3px;
            border-radius: 3px;
            svg{
                width: 16px;
                height: 17px;
                path{
                    fill: #575D6A;
                }
            }
        }
        .nat-close-btn:hover{
            background-color: #f5f5f5;
        }
    }
}

.notification-pop-up {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    box-shadow: none;
    bottom: auto;
    max-width: fit-content;
    padding: 30px;
    .na-top {
        flex-direction: column;
        text-align: center;
        h3 {
            color: #0F172A;
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 15px;
            width: 100%;
        }
        .npu-icon {
            width: 100%;
            padding: 10px 0;
            display: flex;
            justify-content: center;
            margin-bottom: 5px;
        }
        .nat-det{
            padding-left: 0;
            h1 {
                font-weight: 500;
                margin-bottom: 15px;
                font-size: 16px;
            }
            p {
                font-size: 13px;
            }
            .natd-btns{
                margin-top: 50px;
                button{
                    height: 36px;
                    width: 100%;
                    margin-right: 0;
                    letter-spacing: 0.1px;
                }
            }
        }
    }
}


.with-auto-height {
    #parentModalAnimation {
        inset: 50% auto auto 50%;
        max-height: 100%;
        overflow: auto;
        transform: translate(-50%, -50%);
    }
}