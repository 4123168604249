@import '../variables.scss';

.sales-dashboard {
    padding: 12px 12px 12px 27px;

    .sd-top {
        background-color: #fff;
        margin-bottom: 12px;

        .generic-form-body {
            padding: 10px;
            overflow: inherit;

            .gfb-inner {
                flex-wrap: nowrap;
                .generic-input-box {
                    width: 12.5%;
                    margin: 0 10px 0 0;

                    .list-component-section {
                        top: 37px;

                        .lcs-inner {
                            max-height: 155px;
                        }
                    }
                }
            }
        }
    }

    .sd-cards {
        // width: 16.66%;
        min-height: 185px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        // background-color: #fff;
        border-radius: 6px;
        // box-shadow: 0 0 16px 1px hsla(0, 0%, 64%, .25);
        border: 1px solid $borderSlateGrey;
        padding: 15px 0;
        // margin-right: 12px;
        cursor: pointer;
        transition: .2s;

        .sdc-img {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #bddbff52;
            border-radius: 50%;
        }

        .sdc-hold {
            background-color: rgba(239, 143, 0, .2);
        }

        .sdc-rejected {
            background-color: rgba(253, 55, 87, .2);
        }

        .sdc-approved {
            background-color: rgba(0, 206, 181, .2);
        }

        .sdc-unauthorised {
            background-color: rgba(254, 157, 11, .2);
        }

        .sdc-amount {
            text-align: center;
        }

        .sdc-size {
            padding: 6px 0;
            font-size: 24px;
            font-weight: 800;
            color: #21314b;
        }

        p {
            font-size: 12px;
            font-weight: 600;
            color: #67768e;
            margin: 0;
        }

        .sdc-amount {
            padding-top: 15px;
            white-space: nowrap;

            p {
                font-weight: 500;
            }

            span {
                font-size: 13px;
                color: #21314b;
                font-weight: 700;
            }
        }
    }

    .tooltip-target:hover .generic-tooltip{
        opacity: 1;
        visibility: visible;
    }

    .sd-cards-customer {
        width: 16.66%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0 0 16px 1px hsla(0, 0%, 64%, .25);
        padding: 15px 0;
        margin-right: 12px;
        cursor: pointer;

        .sdc-img {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #bddbff52;
            border-radius: 50%;
        }

        .sdc-hold {
            background-color: rgba(239, 143, 0, .2);
        }

        .sdc-rejected {
            background-color: rgba(253, 55, 87, .2);
        }

        .sdc-approved {
            background-color: rgba(0, 206, 181, .2);
        }

        .sdc-unauthorised {
            background-color: rgba(254, 157, 11, .2);
        }

        .sdc-amount {
            text-align: center;
        }

        .sdc-size {
            padding: 10px 0;
            font-size: 24px;
            font-weight: 800;
            color: #21314b;
        }

        p {
            font-size: 12px;
            font-weight: 600;
            color: #67768e;
            margin: 0;
        }

        .sdc-amount {
            padding-top: 15px;

            p {
                font-weight: 500;
            }

            span {
                font-size: 13px;
                color: #21314b;
                font-weight: 700;
            }
        }
    }

    .sd-cards:nth-last-child(1), .sd-cards-customer:nth-last-child(1) {
        margin: 0;
    }

    .sd-cards-customer:hover {
        box-shadow: 0 14px 36px 1px #d8daf5;
    }

    .sdc-img {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #bddbff52;
        border-radius: 50%;
    }

    .sdc-hold {
        background-color: rgba(239, 143, 0, .2);
    }

    .sdc-rejected {
        background-color: rgba(253, 55, 87, .2);
    }

    .sdc-approved {
        background-color: rgba(0, 206, 181, .2);
    }

    .sdc-unauthorised {
        background-color: rgba(254, 157, 11, .2);
    }

    // .sdc-amount {
    //     text-align: center;
    // }

    .sdc-size {
        padding: 6px 0;
        font-size: 24px;
        font-weight: 800;
        color: #21314b;
    }

    p {
        font-size: 12px;
        font-weight: 600;
        color: #67768e;
        margin: 0;
    }

    .sdc-amount {
        // padding-top: 15px;
        white-space: nowrap;

        p {
            font-weight: 500;
        }

        span {
            font-size: 13px;
            color: #21314b;
            font-weight: 700;
        }
    }

    .detailsBox{
        transition: .2s;
    }

    .detailsBox:hover{
        box-shadow: 0 14px 36px 1px #d8daf570;
    }

    .sd-cards:hover {
        // box-shadow: 0 14px 36px 1px #d8daf5;
        box-shadow: 0 14px 36px 1px #d8daf570;
    }

    .sd-sec {
        padding: 10px;
        background-color: #fff;

        h3 {
            font-size: 15px;
            font-weight: 600;
            color: #67768e;
            margin: 0;
        }

        .sds-top {
            display: flex;
            padding: 10px 0;

            .ant-radio-group {
                height: 32px;
                display: flex;
                align-items: center;
                background-color: #ebebf3;
                border-radius: 5px;
                padding: 0 3px;
                margin-right: 10px;

                .ant-radio-button-wrapper {
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: transparent;
                    border-radius: 3px;
                    font-size: 12px;
                    font-weight: 600;
                    color: #21314b;
                    border: none;
                    padding: 0 10px;
                }

                .ant-radio-button-wrapper-checked {
                    background-color: #fff;
                    border: none;
                }

                .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
                    display: none;
                }

                .ant-radio-button-wrapper:not(:first-child)::before {
                    display: none;
                }
            }

            .generic-input-box {
                width: 10%;
                margin: 0;

                .list-component-section {
                    top: 34px;
                    z-index: 9;
                }
            }
        }

        .table-component-new-style {
            min-height: auto;
            background-color: #fff;

            .tcns-no-data {
                width: 100%;
                text-align: center;
                padding: 150px 0;

                p {
                    color: #21314b;
                    font-weight: 600;
                    font-size: 12px;
                    margin: 0;
                }
            }

            .tableBody-border {
                background: #fff;

                .invoice-manage-table {
                    background-color: #fff;
                    padding: 5px 0;
                    margin: 0;

                    .imt-manage-table {
                        min-height: 60vh;
                        max-height: 60vh;
                        overflow: auto;

                        table {
                            thead {
                                position: sticky;
                                top: 0;
                                background: #f8f8f8;
                                z-index: 3;
                                tr {
                                    th {
                                        padding: 0 8px;

                                        .td-header {
                                            padding: 0;
                                        }
                                    }

                                    th:hover{
                                        .td-header {
                                            border: none;
                                            background-color: transparent;
                                        }
                                    }

                                    .filter-table-header {
                                        padding: 0;

                                        .asnfbb-right {
                                            .generic-smart-input {
                                                padding-left: 8px;
                                            }
                                        }
                                    }
                                }
                            }

                            tbody {
                                tr {
                                    td {
                                        border-top: none;
                                        border-bottom: 1px solid #dae4ee;

                                        .table-item-list {
                                            padding: 0 8px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .imt-spinner {
                        padding: 50px 0;

                        svg {
                            width: 3% !important;
                            height: 3% !important;
                        }
                    }
                }
            }
        }

        .sds-filter {
            display: flex;
            align-items: center;

            .sdsf-btn {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #dae4ee;
                border-radius: 4px;
                position: relative;
            }

            .sdsf-btn:hover {
                background-color: $primary-color;
                border: 1px solid $primary-color;

                svg {
                    path {
                        fill: #fff;
                    }
                }

                .generic-tooltip {
                    visibility: visible;
                    opacity: 1;
                }
            }

            .generic-form-body {
                width: 100%;
                overflow: inherit;
                padding: 10px;

                .gfb-inner {
                    .generic-input-box {
                        width: 15%;
                        margin: 0 10px 0 0;

                        .list-component-section {
                            top: 37px;
                            z-index: 9;

                            .lcs-inner {
                                max-height: 150px;
                            }
                        }
                    }
                }
            }
        }

        .table-component-new-style {
            .tableBody-border{
                .invoice-manage-table{
                    .imt-manage-table{
                        .imt-spinner{
                            .so-table{
                                width: 100%;
                                tbody{
                                    tr{
                                        td{
                                            
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}