@import '../variables.scss';

.proc-dashboard {
    position: relative;
    padding: 12px 12px 12px 27px;

    .ant-radio-group {
        width: fit-content;
        height: 32px;
        display: flex;
        align-items: center;
        background-color: #ebebf3;
        border-radius: 5px;
        padding: 0 3px;
        position: absolute;
        right: 12px;
        top: -37px;
        z-index: 10;

        .ant-radio-button-wrapper {
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border-radius: 3px;
            font-size: 12px;
            font-weight: 600;
            color: #21314b;
            border: none;
            padding: 0 10px;
        }

        .ant-radio-button-wrapper-checked {
            background-color: #fff;
            border: none;
        }

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
            display: none;
        }

        .ant-radio-button-wrapper:not(:first-child)::before {
            display: none;
        }
    }

    .generic-form-body {
        background-color: #fff;
        overflow: inherit;

        .gfb-inner {
            flex-wrap: nowrap;

            .generic-input-box {
                width: 12.50%;
                margin: 0;
                padding-right: 10px;

                .gib-inner{
                    border-radius: 0 !important;
                }

                .gib-range {
                    .ant-picker {
                        height: 32px;
                        border: 1px solid #dae4ee !important;
                        border-radius: 0 !important;

                        .ant-picker-input {
                            input {
                                transition: none;
                            }
                        }
                    }

                    .ant-picker:hover {
                        background-color: #f5f5f5;
                        cursor: pointer;

                        .ant-picker-input {
                            input {
                                background-color: #f5f5f5;
                                cursor: pointer;
                            }
                        }
                    }
                }

                .list-component-section {
                    top: 35px;
                }
            }

            .generic-input-box:nth-last-child(2) {
                .list-component-section {
                    left: auto;
                    right: 0;
                }
            }

            .gfbi-btns {
                display: flex;
                align-items: center;
                justify-content: space-between;

                button {
                    height: 30px;
                    white-space: nowrap;
                }

                .generic-approve-btn {
                    margin-right: 10px;
                }
            }
        }
    }

    .afd-filters {
        width: 50%;
        max-width: 50%;
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 10px;
        overflow: inherit;

        button {
            display: flex;
            align-items: center;
            white-space: nowrap;
        }

        .afdff {
            width: fit-content;
            height: 30px;
            display: flex;
            align-items: center;
            border: 1px solid #dde6ef;
            border-radius: 4px;
            padding: 0 5px;
            margin-right: 10px;
            cursor: pointer;
            position: relative;
            text-transform: capitalize;

            p {
                color: #23235f;
                font-size: 13px;
                font-weight: 500;
                margin: 0;
                white-space: nowrap;
            }

            button {
                height: 100%;
                padding-left: 3px;
            }

            .three-dot {
                height: 100%;
                padding: 2px 7px;
                position: relative;
                display: flex;
                align-items: center;
                visibility: hidden;
                opacity: 0;

                ul {
                    min-width: 150px;
                    position: absolute;
                    top: 27px;
                    left: 0;
                    border-radius: 3px;
                    background-color: #fff;
                    text-align: left;
                    box-shadow: 0 4px 14px -3px hsla(0, 0%, 55%, .25);
                    transition: all .6s;
                    z-index: 2;

                    li {
                        width: 100%;
                        font-size: 13px;
                        color: #21314b;
                        text-align: left;
                        padding: 3px 10px;
                        font-weight: 500;
                        white-space: nowrap;
                        position: relative;
                        cursor: pointer;
                        text-transform: capitalize;
                    }

                    li:hover {
                        background-color: #f5f5f5;
                    }

                    .delete-tab {
                        color: $danger-color;
                    }
                }
            }
        }

        .afdff:hover {
            background-color: #f5f5f5;

            .three-dot {
                visibility: visible;
                opacity: 1;
            }
        }

        .afdff-sel {
            border: 1px solid $primary-color;
            background-color: $primary-color;

            p {
                color: #fff;
            }

            button {
                svg {
                    path {
                        fill: #fff;
                    }
                }
            }

            .three-dot {
                svg {
                    circle {
                        fill: #fff;
                    }
                }
            }
        }

        .afdff-sel:hover {
            background-color: $primary-color;
        }

        .afdff-btns {
            display: flex;
            align-items: center;
            position: relative;

            button {
                margin-left: 10px;
                display: flex;
                align-items: center;

                svg {
                    margin-left: 5px;
                }
            }

            .pinned-filter-dropdown {
                min-width: 200px;
                max-height: 250px;
                overflow-y: auto;
                position: absolute;
                top: 36px;
                right: 0;
                left: auto;
                background-color: #fff;
                box-shadow: 0 2.71px 18.99px hsla(0, 0%, 63%, .26);
                transition: all .6s;
                z-index: 10;
                border-radius: 3px;

                li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 5px 10px;
                    cursor: pointer;

                    p {
                        font-weight: 500;
                        font-size: 13px;
                        color: #23235f;
                        white-space: nowrap;
                        margin: 0;
                    }

                    .three-dot {
                        height: 100%;
                        padding: 2px 7px;
                        position: relative;
                        display: flex;
                        align-items: center;

                        ul {
                            min-width: 150px;
                            position: absolute;
                            top: 27px;
                            left: 28px;
                            border-radius: 3px;
                            background-color: #fff;
                            text-align: left;
                            box-shadow: 0 4px 14px -3px hsla(0, 0%, 55%, .25);
                            transition: all .6s;
                            z-index: 2;

                            li {
                                width: 100%;
                                font-size: 13px;
                                color: #21314b;
                                text-align: left;
                                padding: 3px 10px;
                                font-weight: 500;
                                white-space: nowrap;
                                position: relative;
                                cursor: pointer;
                                text-transform: capitalize;
                            }

                            li:hover {
                                background-color: #f5f5f5;
                            }

                            .delete-tab {
                                color: $danger-color;
                            }
                            .delete-tab:hover{
                                background-color: #fdf2f7;
                            }
                        }
                    }

                    .three-dot:hover > svg{
                        circle{
                            fill: $primary-color;
                        }
                    }
                }

                li:hover {
                    background-color: #f5f5f5;
                }
            }
        }
    }

    .pd-body {
        display: flex;
        align-items: center;
        padding: 12px 0;

        .pdb-cards {
            padding-right: 12px;

            .pdbc-det {
                cursor: pointer;
                background-color: #fff;
                border-radius: 10px;
                box-shadow: 0 15px 64px -27px #d8d8d8;
                padding: 15px 0;

                .pdbcd-top {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-bottom: 1px solid #dee;

                    span {
                        width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        background-color: rgba(0, 41, 186, .1);
                    }

                    .drafted {
                        background-color: #f8f8f8;
                    }

                    .approved {
                        background-color: rgba(0, 206, 181, .2);
                    }

                    .rejected {
                        background-color: rgba(253, 55, 87, .2);
                    }

                    h3 {
                        font-size: 22px;
                        font-weight: 700;
                        color: #21314b;
                        padding: 10px 0;
                        margin: 0;
                    }

                    .clr-red {
                        color: #ee434c;
                    }

                    h5 {
                        font-size: 12px;
                        font-weight: 600;
                        color: #67768e;
                        margin-bottom: 15px;
                    }
                }

                .pdbcd-bot {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 15px 20px 0;
                    position: relative;

                    .pdbcdb-det {
                        width: 50%;
                        text-align: center;

                        h5 {
                            color: #67768e;
                            font-size: 11px;
                            font-weight: 500;
                            margin: 0;
                        }

                        .pdbcdbd {
                            position: relative;

                            h3 {
                                font-size: 12px;
                                color: #21314b;
                                font-weight: 700;
                                margin: 0;
                                margin-top: 3px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            .rupFontIcon {
                                font-family: system-ui;
                                font-weight: 400;
                            }
                        }

                        .pdbcdbd:hover {
                            .generic-tooltip {
                                visibility: visible;
                                opacity: 1;
                            }
                        }
                    }

                    .pdbcdb-days {
                        display: flex;
                        align-items: center;
                        position: relative;

                        label {
                            font-size: 12px;
                            color: #12203c;
                            font-weight: 600;
                            margin: 0;
                        }

                        svg {
                            margin-left: 7px;
                        }

                        ul {
                            min-width: 82px;
                            height: auto;
                            position: absolute;
                            bottom: 43px;
                            right: -18px;
                            border-radius: 3px;
                            box-shadow: 0 0 6px 0 hsla(0, 0%, 53%, .16);
                            background-color: #fff;
                            transform: translateY(20px);
                            transition: all .3s ease-out;
                            z-index: 99;
                            visibility: hidden;
                            opacity: 0;

                            li {
                                padding: 5px 10px;

                                span {
                                    font-size: 12px;
                                    color: #000;
                                    font-weight: 600;
                                }
                            }

                            li:hover {
                                background-color: #f5f5f5;
                            }
                        }
                    }

                    .pdbcdb-days:hover {
                        ul {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }

                .pdbcd-bot::after {
                    content: "";
                    position: absolute;
                    top: 40%;
                    left: 50%;
                    width: 1px;
                    height: 25px;
                    background-color: #e6e9ec;
                    transform: translate(-50%);
                }

                .pdbcd-bot::before {
                    content: "";
                    position: absolute;
                    bottom: -15px;
                    left: 50%;
                    width: 100px;
                    height: 4px;
                    border-radius: 8px;
                    transform: translate(-50%);
                    background-image: linear-gradient(90deg, $primary-color, #00ced6);
                    visibility: hidden;
                    opacity: 0;
                }
            }

            .pdbc-det:hover {
                box-shadow: 0 15px 74px -27px #a4b8cc;

                .pdbcd-bot::before {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        .pdb-cards:nth-last-child(1) {
            padding-right: 0;
        }
    }
}

.procV2Styling{
    margin-inline: 0 !important;
    padding: 0 !important;
    margin-bottom: 12px !important;
    overflow: visible !important;
    .generic-form-body {
        width: 100% !important;
        padding-right: 24px !important;

        .gfb-inner {
            .statusWiseStatistics {
                margin-right: 0 !important;
            }

            .generic-input-box {

                .gib-inner {
                    border-radius: 4px !important;
                }

                .gib-range{
                    min-width: 100px !important;
                    .ant-picker {
                        border-radius: 4px !important;
                    }
                } 

            }
            
            .ant-radio-group {
                position: fixed;
                right: 25px;
                top: 51px;

                .ant-radio-button-wrapper{
                    border-radius: 3px !important;
                }
            }
        }
    }
    .afd-filters{
        width: auto !important;
        padding-left: 2px !important;
        padding-right: 10px !important;

        .afdff-btns {
            button:first-child {
                margin-left: 0 !important;
            }
        }
    }
}