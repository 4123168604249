// Style Excel Upload Page
.maroon{
    background-color: maroon !important;
}
.red{
    background-color: red !important;
}
.orange{
    background-color: orange !important;
}
.yellow{
    background-color: yellow !important;
}