// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200&display=swap');

@import 'variables';

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    outline: none;
    user-select: text;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #ffffff;
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

// -------------------------------------SCROLLBAR CSS-----------------------------------------
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #eaeaee !important;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #eaeaee;
}

// --------------------------FULL PAGE LOADER -------------------------------------
.full-page-loader {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 9999;
}

.ball-scale-multiple {
    position: relative;
    transform: translate(-50px, -50px);
}

.ball-scale-multiple>div {
    background-color: $primary-color;
    position: absolute;
    left: 0px;
    top: 0px;
    opacity: 0;
    margin: 0;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    margin: 2px;
    animation-fill-mode: both;
    animation: ball-scale-multiple 1s 0s linear infinite;
}

.ball-scale-multiple>div:nth-child(2) {
    animation-delay: 0.2s;
}

.ball-scale-multiple>div:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes ball-scale-multiple {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    5% {
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

// Top Loader
.top-loading-bar {
    position: fixed;
    top: 0;
    left: 0;
    height: 3px;
    z-index: 99999;
    background-color: $primary-color;
    // transition: all 0.5s ease;
    background: linear-gradient(100deg,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.5) 50%,
            rgba(255, 255, 255, 0) 60%) $primary-color;
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loadv2 ease-in-out infinite;
}

// --------------------------CALCULATIONS FOR GENERIC CLASSES--------------------------------------------------

@for $i from 0 through 1000 {

    // MARGIN
    .m-#{$i} {
        margin: 1px * $i !important;
    }

    .m-top-#{$i} {
        margin-top: 1px * $i !important;
    }

    .m-bot-#{$i} {
        margin-bottom: 1px * $i !important;
    }

    .m-lft-#{$i} {
        margin-left: 1px * $i !important;
    }

    .m-rgt-#{$i} {
        margin-right: 1px * $i !important;
    }

    .m-lr-#{$i} {
        margin-left: 1px * $i !important;
        margin-right: 1px * $i !important;
    }

    .m-tb-#{$i} {
        margin-top: 1px * $i !important;
        margin-bottom: 1px * $i !important;
    }

    // PADDING
    .p-#{$i} {
        padding: 1px * $i !important;
    }

    .p-lr-#{$i} {
        padding-left: 1px * $i !important;
        padding-right: 1px * $i !important;
    }

    .p-tb-#{$i} {
        padding-top: 1px * $i !important;
        padding-bottom: 1px * $i !important;
    }

    .p-top-#{$i} {
        padding-top: 1px * $i !important;
    }

    .pt-#{$i} {
        padding-top: 1% * $i !important;
    }

    .p-bot-#{$i} {
        padding-bottom: 1px * $i !important;
    }

    .p-lft-#{$i} {
        padding-left: 1px * $i !important;
    }

    .p-rgt-#{$i} {
        padding-right: 1px * $i !important;
    }

    // ZINDEX
    .zi#{$i} {
        z-index: 1 * $i !important;
    }

    // WIDTH
    .w-#{$i} {
        width: 1% * $i !important;
    }

    .wid-#{$i} {
        width: 1px * $i !important;
    }

    .max-w-#{$i} {
        max-width: 1% * $i !important;
    }

    .min-w-#{$i} {
        min-width: 1px * $i !important;
    }

    // HEIGHT
    .h-#{$i} {
        height: 1% * $i !important;
    }

    .hgt-#{$i} {
        height: 1px * $i !important;
    }

    .max-h-#{$i} {
        max-height: 1px * $i !important;
    }

    .max-h-#{$i} {
        max-height: 1px * $i !important;
    }

    .min-h-#{$i} {
        min-height: 1px * $i !important;
    }

    // POSITIONS
    .top-#{$i} {
        top: 1px * $i !important;
    }

    .bot-#{$i} {
        bottom: 1px * $i !important;
    }

    .left-#{$i} {
        left: 1px * $i !important;
    }

    .right-#{$i} {
        right: 1px * $i !important;
    }
}

.width-fit {
    width: fit-content !important;
}

.zi998 {
    z-index: 998 !important;
}

.zi999 {
    z-index: 999 !important;
}

// -------------------------------------TAG MARGINS-----------------------------------------------------
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

p {
    margin-bottom: 0.5rem;
}

.opacity04 {
    opacity: 0.4;
    cursor: not-allowed !important;
}

// ---------------------------------------BUTTONS-------------------------------------
button {
    cursor: pointer !important;
    outline: none !important;
}

button[disabled] {
    opacity: 0.4;
    cursor: not-allowed !important;

    .generic-tooltip {
        display: none;
    }
}

.generic-approve-btn {
    background: $primary-color !important;
    border-radius: 3px;
    border: 1px solid $primary-color !important;
    font-weight: 500 !important;
    font-size: 13px;
    color: #ffffff !important;
    padding: 2px 15px !important;
    cursor: pointer;
    height: 32px;

    svg {
        fill: #fff !important;
    }

    a {
        color: #fff;
        text-decoration: none;
    }
}

.generic-approve-btn:hover {
    // background: #5355e5 !important;
    filter: brightness(0.9);
}

.generic-default-btn {
    background: #f5f5f5 !important;
    border-radius: 3px;
    border: 1px solid #f5f5f5 !important;
    font-weight: 500 !important;
    font-size: 13px;
    color: #67768e !important;
    // color: $fontBlack !important;
    padding: 2px 15px !important;
    cursor: pointer;
    height: 32px;
}

.generic-default-btn:hover {
    background: #ebebeb !important;
}

.generic-reject-btn {
    background: #E66767 !important;
    border-radius: 3px;
    border: 1px solid #E66767 !important;
    font-weight: 500 !important;
    font-size: 13px;
    color: #fff !important;
    padding: 2px 15px !important;
    cursor: pointer;
    height: 30px;

    svg {
        path {
            fill: #fff !important;
        }
    }

    a {
        color: #fff;
        text-decoration: none;
    }
}

.generic-reject-btn:hover {
    background: #d25353 !important;
}

.rotate180 {
    transform: rotate(180deg);
}

.onFocus:focus,
.focusBorder:focus,
.focusOn {
    border: 1px solid $primary-color !important;
    box-shadow: 0 0 0 2px #dadaff !important;
}

// -------------------------------MODAL AND BACKDROP---------------------------------------------------
.modal {
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999 !important;
}

.backdrop {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.6 !important;
    background-color: #36455fe3 !important;
}

.white-backdrop {
    background-color: #fff;
    opacity: 0.82;
    height: 100%;
    width: 100%;
    position: absolute;
}

.backdrop-transparent {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: transparent;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}

.gen-action-btn,
.gen-action-btn-disable {
    width: 30px;
    height: 30px !important;
    display: flex !important;
    flex: 0 0 30px;
    align-items: center;
    justify-content: center;
    border: 1px solid #dae4ee !important;
    padding: 0 !important;
    border-radius: 4px !important;
}

.gen-action-btn-disable {
    opacity: 0.4;
    cursor: not-allowed !important;
}

.gen-action-btn:hover,
.gen-action-btn-focus {
    color: #fff !important;
    background-color: $primary-color !important;
    border: 1px solid $primary-color !important;
    transition: all 0.3s !important;
    outline: none;

    svg {
        path {
            fill: #fff;
        }

        .fill-none {
            fill: none !important;
        }

        .csv-fill-1 {
            transition: all 0.3s !important;
            fill: $primary-color !important;
        }
    }
}

// -----------------------------------GENERIC INPUT ------------------------------------------------
// Style Generic Smart Search
.generic-smart-input {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    border-radius: 5px;
    border: solid 1px #dce6f0;
    height: 32px;
    padding-left: 7px;

    .gsi-search {
        padding: 6px;
        flex-grow: 1;
    }

    .gsi-selected {
        display: flex;
        font-size: 10px;
        font-weight: 600;
        border-radius: 5px;
        background-color: #f8f7fe;
        color: #21314b;
        align-items: center;
        padding: 3px 3px 3px 5px;
        flex-grow: 1;
        min-width: fit-content;
        margin-left: 5px;

        .gsis-clear {
            margin: 0px 5px;
            cursor: pointer !important;
        }
    }

    .gsis-clear {
        padding: 5px;
        cursor: pointer !important;
    }

    .gsi-right-arrow {
        flex-grow: 1;
        margin-right: 7px;
    }

    input {
        display: block;
        width: calc(100% - 165px);
        border: none;
        font-size: 12px;
        font-weight: 500;
        margin-left: 4px;
        margin-right: 5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex-grow: 999;
        outline: none;
    }

    input::placeholder {
        color: #8496a9 !important;
    }
}

textarea {
    outline: none !important;
    resize: none !important;
}

input::placeholder {
    color: #8496a9 !important;
}

textarea::placeholder {
    color: #8496a9 !important;
}

input {
    outline: none;
}

input[type="checkbox"] {
    display: none;
}

select {
    outline: none;
    cursor: pointer;
}

select[disabled] {
    cursor: not-allowed;
    opacity: 0.4;
}

select:focus {
    border: 1px solid $primary-color !important;
    box-shadow: 0px 0px 0px 2px #dadaff;
}

input[type="checkbox"],
input[type="radio"] {
    cursor: pointer;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

input[type="file"] {
    color: transparent;
}

::-webkit-file-upload-button {
    display: none;
    color: transparent;
}

.columnFilterGeneric {
    position: absolute;
    right: 5px;
    top: 0;
    display: inline-block;
    padding: 13px 0;
    height: 41px;
    width: 19px;
    z-index: 2;

    span.glyphicon {
        position: absolute;
        right: 0;
        top: -1px;
        padding: 14px 9px;
        background: #6d6dc9;
        color: #ffff;
        cursor: pointer;
    }
}

// HEADER CONFIGURATION
.columnSetting {
    position: absolute;
    right: 19px;
    display: block;
    width: max-content;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
    background-color: #f1f1f1;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    padding: 0px 15px;
}

.columnFilterDropDown.custom-header-modal {
    width: 650px;
    height: 350px;
    box-shadow: 0 3px 6px 0 rgba(108, 108, 108, 0.16);
    background-color: #ffffff;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 40px;
    border-radius: 0;

    .filterHeader.chm-head {
        box-shadow: -3px 7px 8px 0 rgba(181, 181, 181, 0.16);
        background-color: #ffffff;
        height: 40px;
        width: 100%;
        padding: 5px 15px;

        .chmh-search {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            input {
                border: solid 1px #ccd8ed;
                background-color: #ffffff;
                width: 320px;
                height: 30px;
                border-radius: 50px;
                font-size: 12px;
                font-weight: 500;
                color: #21314b;
                padding: 3px 35px 3px 10px;
            }

            input:focus {
                border: 1px solid $primary-color;
                box-shadow: 0px 0px 0px 2px #dadaff;
            }

            .chmhs-search-ion {
                position: absolute;
                top: 7.5px;
                right: 11px;
                width: 16px;

                svg {
                    width: 15px;
                }
            }

            .chmhs-cross {
                height: 100%;
                position: absolute;
                top: 0;
                right: 3px;
                padding: 0 10px;

                svg {
                    cursor: pointer;
                }
            }
        }

        .chmh-right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;

            button {
                font-weight: 600;
                font-size: 12px;
                padding: 6px 20px;
                margin-left: 15px;
                border-radius: 20px;
                color: #718096;
                background-color: transparent;
            }

            .saveBtn {
                background: $primary-color;
                color: #fff;
            }

            .resetBtn {
                font-weight: 500;
            }

            .resetBtn:hover {
                background-color: $dropdown-item-hover;
            }
        }
    }

    .chm-body {
        display: flex;
        position: relative;
        width: 100%;
        height: calc(100% - 40px);
        background: transparent;
        padding: 0px 10px;

        .chmb-sidebar {
            position: absolute;
            left: 0;
            top: 0;
            width: 55px;
            height: 100%;
            background: #fff;
            box-shadow: 10px 0 8px -4px hsla(0, 0%, 55%, 0.16);
            z-index: 99;

            ul.chmbs-menu {
                position: relative;
                padding: 0;
                margin: 0;

                li.chmbsm-item {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 55px;
                    border-bottom: 1px solid #dce6f0;
                    cursor: pointer;

                    span {
                        display: block;
                        padding: 0;
                        color: #000;
                        font-size: 12px;
                        font-weight: 500;
                    }
                }

                li.chmbsm-item.active {
                    span {
                        font-weight: 600;
                    }
                }

                li.chmbsm-item.active:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 4px;
                    height: 55px;
                    background-color: $primary-color;
                }
            }
        }

        ul.chmbs-menu {
            position: relative;
            padding: 0;
            margin: 0;

            .chmb-left {
                display: inline-block;
                width: 55%;
                padding: 10px 10px;
                padding-left: 70px;
                border-right: 1px solid #e6e6f5;

                .chmbl-head {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 55px;
                    border-bottom: 1px solid #dce6f0;
                    cursor: pointer;

                    span {
                        display: block;
                        padding: 0;
                        color: #000;
                        font-size: 12px;
                        font-weight: 500;
                    }
                }

                li.chmbsm-item.active {
                    span {
                        font-weight: 600;
                    }
                }

                li.chmbsm-item.active:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 4px;
                    height: 55px;
                    background-color: $primary-color;
                }
            }
        }

        .chmb-left {
            display: inline-block;
            width: 55%;
            padding: 10px 10px;
            padding-left: 70px;
            border-right: 1px solid #e6e6f5;

            .chmbl-head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 30px;

                h3 {
                    font-size: 15px;
                    font-weight: 600;
                    color: #21314b;
                    margin: 0;
                }

                span {
                    font-size: 12px;
                    font-weight: 500;
                    color: #718096;
                    display: inline-block;
                }
            }

            .chmbl-headers {
                margin: 0;
                padding: 0;
                position: relative;
                display: block;
                margin-top: 15px;
                max-height: 255px;
                overflow: auto;

                .chmblh-items {
                    display: block;
                    position: relative;
                    margin-bottom: 10px;
                    padding: 8px 15px;
                    border-radius: 7px;
                    cursor: pointer;

                    .checkBoxLabel0 {
                        width: auto;
                        height: auto;
                        padding-left: 36px;
                        margin: 0;
                        font-size: 12px;
                        font-weight: 500;
                        color: #21314b;
                        cursor: pointer !important;

                        .checkmark1 {
                            display: none;
                            left: 0;
                            top: 1px;
                            height: 16px;
                            width: 16px;
                            background-color: $primary-color;
                            cursor: pointer;
                            border-radius: 24px;
                            border: none;
                        }

                        .checkmark1:after {
                            content: "";
                            width: 4px;
                            height: 8px;
                            border-width: 0 1px 1px 0;
                        }

                        input:checked~.checkmark1 {
                            background-color: $primary-color;
                            border: none;
                        }

                        input:checked~.checkmark1:after {
                            content: "";
                            width: 4px;
                            height: 8px;
                            border-width: 0 1px 1px 0;
                        }
                    }
                }

                .chmblh-items:hover {
                    box-shadow: 0 3px 6px 0 #e6e2ff;
                    border: solid 1px $primary-color;
                    .checkBoxLabel0 {
                        font-weight: 600;

                        .checkmark1 {
                            display: inline-block;
                        }
                    }
                }

                .chmbl-nodata {
                    display: block;
                    height: 240px;
                    width: 100%;
                    text-align: center;
                    margin: auto;
                    padding: 60px 0 0;

                    span {
                        font-size: 14px;
                        font-weight: bold;
                        color: #718096;
                        padding: 10px 0;
                        display: block;
                    }
                }
            }
        }

        .chmb-right {
            display: inline-block;
            width: 45%;
            padding: 10px 15px;

            .chmbr-head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 30px;

                h3 {
                    font-size: 15px;
                    font-weight: 600;
                    color: #242b4b;
                    margin: 0;
                }

                span {
                    font-size: 12px;
                    font-weight: 500;
                    color: #718096;
                    display: inline-block;
                }
            }

            .chmbr-headers {
                position: relative;
                display: block;
                margin: 15px 0 0;
                max-height: 255px;
                overflow: auto;

                .chmbrh-items {
                    display: block;
                    position: relative;
                    margin-bottom: 10px;
                    padding: 8px 15px 3px;
                    border-radius: 7px;
                    cursor: pointer;

                    .chmbrhi-text {
                        font-size: 12px;
                        font-weight: 500;
                        color: #21314b;
                        padding-left: 20px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        max-width: 190px;
                        display: inline-block;
                        overflow: hidden;
                    }

                    .chmbrhi-close {
                        position: absolute;
                        right: 9px;
                        top: 0px;
                        height: 100%;
                        padding: 0px 10px;

                        svg {
                            display: none;
                        }
                    }

                    .chmbrhi-fixed {
                        display: none;
                        position: absolute;
                        top: 8px;
                        right: 20px;
                        font-size: 11px;
                        font-weight: 500;
                        color: #718096;
                    }
                }

                .chmbrh-items:before,
                .chmbrh-items:after,
                .chmbrhi-text:before,
                .chmbrhi-text:after,
                .chmbrhi-inner:before,
                .chmbrhi-inner:after {
                    content: "";
                    position: absolute;
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    background-color: #d7dee8;
                }

                .chmbrh-items:before {
                    top: 10px;
                    left: 10px;
                }

                .chmbrh-items:after {
                    top: 10px;
                    left: 17px;
                }

                .chmbrhi-text:before {
                    top: 15px;
                    left: 10px;
                }

                .chmbrhi-text:after {
                    top: 15px;
                    left: 17px;
                }

                .chmbrhi-inner:before {
                    top: 20px;
                    left: 10px;
                }

                .chmbrhi-inner:after {
                    top: 20px;
                    left: 17px;
                }

                .chmbrh-items:hover {
                    box-shadow: 0 3px 6px 0 #e6e2ff;
                    border: solid 1px $primary-color;

                    .chmbrhi-text {
                        font-weight: 600;
                    }

                    .chmbrhi-close {
                        display: inline-block;

                        svg {
                            display: inline-block;
                        }
                    }

                    .chmbrhi-fixed {
                        display: inline-block;
                    }
                }
            }
        }

    }
}

// ---------------------------------------BORDER-RADIUS----------------------------------------------
.btr-radius {
    border-top-right-radius: 6px;
}

// ---------------------------------------GENERIC MODALS----------------------------------------------
.genericFilterModel {
    min-width: 15% !important;
}

.dropdown-menu-city1.gen-width-auto {
    min-width: 100%;
    height: 300px;
    border: none;
    box-shadow: 0 4px 12px 0 #bdc7d180;

    .dropdown-menu-city-item {
        height: 87%;

        .table {
            margin: 0;
            border: none;

            thead {
                border: none;

                tr {
                    th {
                        border: none;
                        padding: 3px 5px;

                        label {
                            font-size: 11px;
                            font-weight: 600;
                            color: #21314b;
                            margin: 0;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        padding: 3px 5px;
                        border: none;

                        label {
                            font-size: 12px;
                            font-weight: 500;
                            color: #21314b;
                            margin: 0;
                        }

                        .checkBoxLabel0 {
                            width: 16px;
                            height: 16px;
                            padding-left: 16px;

                            .checkmark1 {
                                left: 0;
                                top: 0;
                                height: 16px;
                                width: 16px;
                                background-color: #ffffff;
                                cursor: pointer;
                                border-radius: 4px;
                                border: solid 2px #dce6f0;
                            }

                            .checkmark1:after {
                                width: 5px;
                                height: 9px;
                                border-width: 0 2px 2px 0;
                                content: none;
                            }

                            input:checked~.checkmark1 {
                                background-color: $primary-color;
                                border: none;
                            }

                            input:checked~.checkmark1:after {
                                width: 5px;
                                height: 9px;
                                border-width: 0 2px 2px 0;
                                content: "";
                            }
                        }
                    }

                    td:first-child {
                        padding-left: 10px;
                    }
                }

                tr:hover {
                    background-color: #f8fafe;
                }
            }
        }
    }

    .deselect-all {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        width: 100%;
        text-align: left;
        border-top: solid 1px #dee6f4;
        background: #fff;
        font-weight: 600;
        font-size: 12px;
        margin-top: 3px;
        height: 34px;

        .dab-icon {
            margin-right: 5px;
            top: auto;
            padding: 0;
        }
    }

    .deselect-all:hover {
        transition: 0.6s all;
        background-color: #f8fafe;
    }

    .gen-dropdown-pagination {
        border-top: solid 1px #dce6f0;
        height: 13%;
        display: flex;
        align-items: center;
        justify-content: center;

        .page-close {
            .gdp-done {
                padding: 0px 10px;
                border-radius: 3px;
                background-color: #f5f5f5;
                color: #111d31;
                font-size: 12px;
                border: none;
                font-weight: 600;
                height: 22px;
            }
        }
    }
}

// Style Dropdown Menu Option
.dropdown-menu-city,
.dropdown-menu-city1 {
    position: absolute;
    display: block;
    top: 20px;
    left: 0;
    width: 200px;
    height: 236px;
    transform: translateY(20px);
    transition: all 0.2s ease-out;
    z-index: 9;
    border-radius: 8px;
    border: 1px solid #ccd8ed;
    background-color: #fff;
    text-indent: 0;

    .ddmci-input {
        display: block;
        width: 100%;
        padding: 10px 15px;

        input {
            width: 100%;
            height: 30px;
            border-radius: 4px;
            border: solid 1px #ccd8ed;
            background-color: #ffffff;
            padding: 3px 10px;
        }
    }

    .dmci-checked {
        border-bottom: 1px solid #ccd8ed;
    }

    .dropdown-menu-city-item {
        padding: 7px 0;
        display: block;
        height: 85%;
        overflow-y: auto;
        margin: 0;
        position: relative;

        li {
            display: block;
            padding: 5px 15px;
            font-size: 12px;
            font-weight: 500;
            cursor: pointer;

            .vendor-details {
                display: table;
                width: 100%;
                padding: 0;

                .vd-name {
                    display: inline-block;
                    font-size: 10px;
                    font-weight: bold;
                    color: #12203c;
                    padding: 0;
                    width: 40%;
                    // float: left;
                    word-break: break-all;
                }

                .vd-loc {
                    font-size: 10px;
                    font-weight: 500;
                    color: #5677b1;
                    width: 10%;
                    padding: 0;
                    padding-left: 5px;
                    display: inline-block;
                    // float: left;
                }

                .vd-num {
                    font-size: 10px;
                    color: #5677b1;
                    font-weight: 500;
                    display: inline-block;
                    padding: 0;
                    width: 30%;
                    // float: left;
                }

                .vd-code {
                    display: inline-block;
                    font-size: 10px;
                    font-weight: 500;
                    color: #5677b1;
                    width: 20%;
                    padding: 0;
                    padding-left: 5px;
                    // float: left;
                }

                .div-col-1 {
                    width: 100%;
                    // float: left;
                }

                .div-col-2 {
                    width: 50%;
                    // float: left;
                }

                .div-col-3 {
                    width: 33.33%;
                    // float: left;
                }

                .div-col-4 {
                    width: 25%;
                    // float: left;
                }

                .div-col-5 {
                    width: 20%;
                    // float: left;
                }

                .div-col-6 {
                    width: 16.6666%;
                    // float: left;
                }

                .div-col-7 {
                    width: 14.28%;
                    // float: left;
                }
            }

            .transporter-name {
                display: inline-block;
                font-size: 12px;
                font-weight: bold;
                color: #12203c;
            }

            .transporter-code {
                font-size: 10px;
                font-weight: 500;
                color: #5677b1;
                display: inline-block;
            }
        }

        li:hover {
            background-color: #f8fafe;
            font-weight: 600;
        }
    }

    .chooseColorHeight {
        height: 62%;
        padding: 0;
    }
}

.dropdown-menu-city.display-city-drop-menu,
.dropdown-menu-city1.display-city-drop-menu {
    visibility: visible;
    opacity: 1;
    transform: translate(0);
}

.datasetting-dropdown {
    .gwa-advance-filter {
        padding: 0 15px;
        .gwaaf-btn {
            position: relative;
            font-size: 12px;
            border: 1px solid #dce6f0;
            font-weight: 500;
            color: #21314b;
            padding: 5px 22px 5px 5px;
            width: 100%;
            background: #fff;
            border-radius: 3px;
            text-align: left;
            height: 32px;
            white-space: nowrap;
    
            span {
                font-size: 11px;
                font-weight: 500;
                color: #90a5c7;
                padding: 0;
                margin-right: 5px;
            }
    
            .gwaafb-svg {
                position: absolute;
                right: 1px;
                bottom: -1px;
            }
        }
    
        .gwaaf-btn.open {
            border-radius: 3px 3px 0 0;
        }
        .gwa-dropdown {
            display: block;
            position: absolute;
            top: 36px;
            left: 15px;
            padding: 5px 0;
            margin: 0;
            z-index: 99;
            border-radius: 0 0 4px 4px;
            border: 1px solid #dce6f0;
            background-color: #fff;
            border-top: none;
            width: calc(100% - 30px);

            li {
                display: block;
                padding: 5px 10px;
                cursor: pointer;
                font-size: 12px;
                font-weight: 500;
                color: #4a5568;
                text-align: left;
            }

            li:hover {
                font-weight: 600;
                color: #21314b;
                background-color: #e5edf8;
            }
        }
    }
}

.dropdown-menu-vendor {
    width: 500px;
}

.dropdown-menu-city2.po-article-mod-dropdown {
    width: 605px;
}

.dropdown-menu-city1.pwq-filter-drop {
    width: 250px;
}

.dropdown-menu-city1.gen-width-auto {
    width: auto;
    min-width: 100%;

    .dropdown-menu-city-item li {
        padding: 5px 10px;
        position: relative;

        .checkBoxLabel0 {
            padding-left: 22px;
            font-size: 12px;
            font-weight: 500;
            color: #12203c;
            width: 100%;
            height: auto;
            top: auto;
            left: auto;
            display: flex;
            position: relative;
            cursor: pointer !important;
            white-space: nowrap;
            min-height: 15px;

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            input:checked~.checkmark1 {
                background-color: $primary-color;
                border: none;
            }

            .checkmark1 {
                position: absolute;
                left: 0;
                top: 0;
                height: 16px;
                width: 16px;
                background-color: #fff;
                cursor: pointer;
                border-radius: 4px;
                border: 2px solid #dce6f0;
            }

            .checkmark1::after {
                content: "";
                width: 5px;
                height: 9px;
                display: block;
                position: absolute;
                left: 6px;
                top: 3px;
                border: solid #fff;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
        }

        .vendor-details .vd-name {
            white-space: nowrap;
        }
    }

    .gwa-advance-filter {
        position: relative;
        padding: 5px 10px;

        .gwaaf-inner {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;

            .gwaaf-contain-text {
                font-size: 11px;
                font-weight: 500;
                color: #90a5c7;
                margin-bottom: 4px;
            }

            select {
                border: 1px solid #dce6f0;
                border-radius: 4px;
                height: 28px;
                font-size: 12px;
                font-weight: 500;
                padding: 2px 3px;
                width: 100%;
            }
        }

        p {
            font-size: 11px;
            font-weight: 500;
            color: #90a5c7;
            margin-bottom: 4px;
        }

        .gwaaf-btn {
            position: relative;
            font-size: 12px;
            border: 1px solid #dce6f0;
            font-weight: 500;
            color: #21314b;
            padding: 5px 22px 5px 5px;
            width: 100%;
            background: #fff;
            border-radius: 3px;
            text-align: left;
            height: 32px;
            white-space: nowrap;

            span {
                font-size: 11px;
                font-weight: 500;
                color: #90a5c7;
                padding: 0;
                margin-right: 5px;
            }

            .gwaafb-svg {
                position: absolute;
                right: 1px;
                bottom: -1px;
            }
        }

        .gwaaf-btn.open {
            border-radius: 3px 3px 0 0;
        }

        .gwa-dropdown {
            display: block;
            position: absolute;
            top: 36px;
            left: 10px;
            padding: 5px 0;
            margin: 0;
            z-index: 99;
            border-radius: 0 0 4px 4px;
            border: 1px solid #dce6f0;
            background-color: #fff;
            border-top: none;
            width: calc(100% - 20px);

            li {
                display: block;
                padding: 5px 10px;
                cursor: pointer;
                font-size: 12px;
                font-weight: 500;
                color: #4a5568;
                text-align: left;
            }

            li:hover {
                font-weight: 600;
                color: #21314b;
                background-color: #e5edf8;
            }
        }
    }

    .gen-dropdown-pagination {
        .page-next-prew-btn {
            display: flex;
            justify-content: center;
            padding-top: 3px;

            button {
                margin: 0px;
                font-size: 12px !important;
            }

            .pnpb-no {
                opacity: 1;
                color: #12203c;
                font-weight: 500;
                margin: 0 5px;
            }

            .pnpb-prev,
            .pnpb-next {
                padding: 0 5px;
            }

            .pnpb-prev:hover,
            .pnpb-next:hover {
                svg {
                    path {
                        fill: $primary-color;
                        transition: 0.6s all;
                    }
                }
            }

            .\!cursor-not-allowed:hover {
                svg {
                    path {
                        fill: #21314b !important;
                    }
                }
            }
        }
    }
}

.dropdown-menu-city .dropdown-menu-city-item li:focus {
    border: 1px solid $primary-color !important;
    font-weight: 700 !important;
    outline: none;
}

.genericFilterModel {
    position: absolute;
    min-height: 200px;
    top: -13%;
    left: 101%;

    .otbrc-search {
        font-size: 11px;
        color: rgb(0, 0, 0);
        font-weight: 600;
        width: 200px;
        border-radius: 5px;
        border-width: 1px;
        border-style: solid;
        border-color: rgb(220, 230, 240);
        border-image: initial;
        background: rgb(255, 255, 255);
        padding: 8px;
        margin: 5px;
        position: relative;

        input {
            position: relative;
            border: none;
        }
    }

    .dropdown-menu-city-item {
        .filter-loader-text {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            height: 23vh;

            .loader-text {
                padding-top: 10px;
            }
        }
    }
}

.dropdown-addfilter {
    position: relative;

    .sohr-upload-download {
        .dtb-show {
            font-size: 10px;
            font-weight: 600;
            color: #67768e;
            white-space: nowrap;
        }

        label {
            font-size: 12px;
            font-weight: bold;
            color: #21314b;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .gf-start-icon {
            margin-right: 10px;
        }
    }

    .addFilter-menu {
        .gen-approved {
            font-weight: 500;
            font-size: 12px;
        }
    }
}

.genericFilterModel1 {
    height: 400px;
    width: auto;
    box-shadow: 0 1px 11px -4px #9ea0a1e6;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 10;
    display: flex;
    flex-direction: row;

    .gfm-colmn {
        width: 185px;
        padding: 10px 0px;
        position: relative;
    }

    .smdd-filter {
        margin: 0 10px 5px;

        .smdd-filter1 {
            height: 34px;
            border-radius: 3px;
        }
    }

    .add-filter-item {
        padding: 0;
        max-height: 330px;

        .sdfl-input-col {
            width: 100%;
            padding: 0;
            margin: 0;
            position: inherit;

            .gsm-outer {
                .gsmo-list {
                    display: flex;
                    align-items: center;
                    padding: 0px 10px;
                    font-size: 12px;
                    font-weight: 500;
                    cursor: pointer;

                    .un-check {
                        height: 16px;
                        width: 16px;
                        background-color: #fff;
                        cursor: pointer;
                        border-radius: 0;
                        position: relative;
                        border: 2px solid #dce6f0;
                    }

                    .after-checked {
                        border: none;
                        background: $primary-color;
                    }

                    .after-checked:after {
                        content: "";
                        display: block;
                        position: absolute;
                        left: 6px;
                        top: 3px;
                        width: 5px;
                        height: 9px;
                        border: solid #fff;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                    }

                    .checkBoxLabel0 {
                        font-size: 12px;
                        font-weight: 500;
                        color: #12203c;
                        width: 100%;
                        margin: 0;
                        height: auto;
                        top: auto;
                        left: auto;
                        display: block !important;
                        position: relative;
                        cursor: pointer !important;
                        white-space: nowrap;
                        min-height: 15px;
                        padding: 6px 0;
                        margin-left: 5px;
                        overflow: visible;
                        background: #fff0;
                        text-align: left;

                        input:checked~.checkmark1 {
                            border: none;
                            background: $primary-color;
                        }

                        .checkmark1 {
                            left: -25px;
                            top: 7px;
                            height: 16px;
                            width: 16px;
                            background-color: #fff;
                            cursor: pointer;
                            border-radius: 0px;
                            position: absolute;
                            border: 2px solid #dce6f0;
                        }

                        .checkmark1::after {
                            content: none;
                            left: 6px;
                            top: 3px;
                            width: 5px;
                            height: 9px;
                            border: solid #fff;
                            border-width: 0px 2px 2px 0px;
                        }

                        input:checked~.checkmark1::after {
                            content: "";
                        }
                    }
                }

                .gsmo-list:hover {
                    background-color: #d8daf599;

                    .checkBoxLabel0 {
                        font-weight: 600;
                    }
                }
            }
        }

        #genericSearchModalDropdown {
            top: -20px;
            left: 190px;
            border-radius: 4px;
            height: 400px;

            .smiv2-inner {
                padding: 3px 10px 5px;
            }

            .dropdown-menu-city-item {
                height: 90%;

                li {
                    .checkBoxLabel0 {
                        color: #67768e;
                    }
                }

                li:hover {
                    background-color: #d8daf599;

                    .checkBoxLabel0 {
                        font-weight: 600;
                        color: #111d31;
                    }
                }
            }

            .gen-dropdown-pagination {
                height: 10%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 10px;
            }
        }

        .search-department {
            li {
                font-size: 12px;
                font-weight: 500;
                color: #111d31;
                padding-bottom: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                svg {
                    margin-right: 10px;
                }
            }
        }
    }
}

.genericFilterModel2 {
    height: 100%;
    overflow: auto;
    width: 240px;
    padding: 5px 15px 5px 15px;
    z-index: 10;
    border-right: solid 1px #dae4ee;

    .applied-filter-header {
        display: flex;
        font-size: 12px;
        font-weight: 700;
        margin: 10px 0 5px 0;
        color: #111d31;
        justify-content: space-between;

        button {
            background-color: #ffffff;
        }
    }

    .add-filter-item {
        padding: 0;

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px 0;

            .division-list-items {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                .select-item {
                    svg {
                        margin-right: 6px;
                    }
                }

                .select-item-name {
                    p {
                        font-size: 11px;
                        font-weight: 500;
                        color: #71819b;
                        margin: 0;
                        padding-bottom: 3px;
                    }

                    label {
                        color: #111d31;
                        font-size: 12px;
                        font-weight: 600;
                        margin: 0px 5px 5px 0;
                        padding: 2px 4px;
                        background: #f5f5f5;
                        border-radius: 3px;
                    }
                }
            }

            .select-item-close {
                svg:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

//generic checkbox
.gc-box {
    display: block;
    padding-right: 20px;

    .cfbif-item-row {
        display: flex;
        position: relative;
        padding: 3px 0;

        .checkBoxLabel0 {
            width: auto;
            height: 16px;
            padding-left: 25px;
            margin: 0;
            font-size: 12px;
            font-weight: 500;
            cursor: pointer !important;

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            input:checked~.checkmark1 {
                background-color: $primary-color !important;
                border: 1px solid $primary-color;
            }

            .checkmark1 {
                left: 0;
                top: 0;
                height: 16px;
                width: 16px;
                background-color: #fff;
                cursor: pointer;
                border-radius: 4px;
                border: 1px solid #dce6f0;
            }

            .checkmark1:after {
                left: 5px;
                top: 2px;
                width: 5px;
                height: 9px;
                border-width: 0 2px 2px 0;
            }
        }
    }
}

//generic checkbox
.gc-box {
    display: block;
    padding-right: 20px;

    .cfbif-item-row {
        display: flex;
        position: relative;
        padding: 3px 0;

        .checkBoxLabel0 {
            width: auto;
            height: 16px;
            padding-left: 25px;
            margin: 0;
            font-size: 12px;
            font-weight: 500;
            cursor: pointer !important;

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

            input:checked~.checkmark1 {
                background-color: $primary-color !important;
                border: 1px solid $primary-color;
            }

            .checkmark1 {
                left: 0;
                top: 0;
                height: 16px;
                width: 16px;
                background-color: #fff;
                cursor: pointer;
                border-radius: 4px;
                border: 1px solid #dce6f0;
            }

            .checkmark1:after {
                left: 5px;
                top: 2px;
                width: 5px;
                height: 9px;
                border-width: 0 2px 2px 0;
            }
        }
    }
}

.checkBoxLabel0 {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    input[type="checkbox"] {
        display: none;
    }
}

.checkmark1 {
    position: absolute;
    top: 5px;
    left: 25px;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 5px;
}

.checkBoxLabel0 .checkmark1:after {
    position: absolute;
    left: 6px;
    top: 3px;
    width: 7px;
    height: 11px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.ant-picker {
    display: flex;
    width: 100%;
    height: 34px;
    border: 1px solid #dce6f0 !important;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    cursor: pointer;
}

.ant-picker:hover {
    border-color: none;
}

.ant-picker:focus {
    border: 1px solid $primary-color !important;
    box-shadow: 0px 0px 0px 2px #dadaff !important;
}

.ant-picker-ranges {
    padding: 8px 8px;

    .ant-picker-preset {
        .ant-tag.ant-tag-blue {
            padding: 4px 9px;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 500;
            background: #fff;
            border: 1px solid #ccd8ed;
            color: #21314b;
            margin-bottom: 8px;
        }

        .ant-tag.ant-tag-blue:hover {
            border: 2px solid $primary-color !important;
            color: $primary-color !important;
        }
    }

    .ant-picker-preset:last-child {
        .ant-tag.ant-tag-blue {
            margin: 0;
        }
    }
}

.ant-picker-dropdown {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
    font-variant: tabular-nums;
    font-family: "Poppins";
    line-height: 1.66667;
    list-style: none;
    font-feature-settings: "tnum";
    position: absolute;
    z-index: 1050;

    .date-picker-extra-footer {
        display: block;
        margin: 0px 3px 8px 3px;

        .radio-label {
            border-radius: 5px;
            background-color: #fff;
            font-size: 12px;
            padding: 0px 10px;
            margin: 8px 8px 0px 0px;
            font-weight: 500;
            position: relative;
            display: inline-block;
            text-align: center;
            cursor: pointer !important;

            input[type="radio"] {
                display: none;
            }

            .radio-text {
                font-size: 12px;
                color: #12203c;
                font-weight: 500;
                position: relative;
                z-index: 1;
                cursor: pointer;
            }

            .radio-check::before {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: #fff;
                border: 1px solid #ccd8ed;
                border-radius: 5px;
                z-index: 0;
                cursor: pointer;
            }

            .radio-check::before:hover {
                background-color: #f5f5f5;
            }

            input:checked~.radio-text {
                font-weight: 600;
                color: $primary-color !important;
            }

            input:checked~.radio-check:before {
                border: 2px solid $primary-color !important;
            }
        }
    }
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single:after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single:after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range):after {
    border-top: 1px dashed $primary-color !important;
    border-bottom: 1px dashed $primary-color !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range):after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:after,
tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child:after,
tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child:after {
    border-right: 1px dashed $primary-color !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range):after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:after,
tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child:after,
tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child:after {
    border-left: 1px dashed $primary-color !important;
}

.ant-picker-date-panel .ant-picker-content {

    td,
    th {
        font-weight: 500;
        font-size: 12px;
        text-align: center;
    }
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
    border: 1px solid $primary-color !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    color: #fff !important;
    background: $primary-color !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range:before {
    background: #5d5fef40;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):before {
    background: #5d5fef40;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):before {
    background: #5d5fef45 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: #5d5fef45 !important;
}

.ant-picker-focused {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(139, 119, 250, 0.16);
}

.ant-picker-focused,
.ant-picker:hover {
    border-color: $primary-color !important;
    border-right-width: 1px !important;
}

.ant-picker-range .ant-picker-active-bar {
    background: $primary-color !important;
}

.checkBoxLabel0 input:checked~.checkmark1:after {
    display: block;
}

.default-heading {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.tc-role-btn {
    font-weight: 400;
    font-size: 12px;
    padding: 3px 10px;
    background: #bfdfff;
    border-radius: 33px;
    color: #004183;
}

.ant-tooltip-arrow::after,
.ant-tooltip-arrow::before {
    display: none;
}

.fontBold {
    font-weight: bold !important;
}

.colorTableText {
    color: #12203c !important;
}

.textWarning {
    color: #d0021b !important;
}

.partialTable {
    padding: 1px 3px;
    border-radius: 4px;
    // background-color: #ff6550;
    font-size: 11px;
    cursor: pointer;
    display: inline-block;
}

.labelEvent {
    font-size: 11px;
    // font-weight: 700;
    color: #fff;
    margin-left: 5px;
}

.hoverDropdownTr.hover {
    background-color: #D8DAF54D;
}

.generic-tooltip.generic-tooltip-error {
    background-color: #ed3946;
}

.generic-tooltip.generic-tooltip-error:after {
    border-color: #ed3946 transparent transparent;
}

.generic-tooltip.generic-tooltip-warning {
    background-color: #ffb103;
}

.generic-tooltip.generic-tooltip-warning:after {
    border-color: #ffb103 transparent transparent;
}

.ranges-strip {
    padding: 2px 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    border-radius: 4px;
    background-color: #f8f8f8;
    color: #67768e;
    white-space: nowrap;
}

.ant-picker-ok {
    .ant-btn {
        span {
            color: #1890ff;
        }
    }
}

.ant-picker-ok:hover {
    .ant-btn {
        span {
            color: #fff;
        }
    }

    .ant-btn[disabled] {
        span {
            color: #1890ff;
        }
    }
}

.smart-search-applied-filter {
    display: inline-block;
    position: relative;

    button {
        border-radius: 4px;
        border: 1px solid #ccd8ed;
        background-color: #fff;
        font-size: 12px;
        padding: 5px 15px;
        margin-left: 15px;
        height: 32px;
        font-weight: 500;
        position: relative;
        display: inline-block;
    }

    button:hover {
        .generic-tooltip {
            visibility: visible;
            opacity: 1;
        }
    }
}

.gen-annoucement-modal {
    width: 100%;
    height: 100%;

    .gam-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
        border-bottom: solid 1px #dee;

        .gamh-left {
            h3 {
                color: #111d31;
                font-size: 25px;
                font-weight: 700;
                margin: 0;
            }

            p {
                color: #598988;
                font-size: 12px;
                font-weight: 400;
                margin: 0;
            }
        }

        .gamh-right {
            a {
                color: #5355e5;
                text-decoration: underline;
                font-size: 12px;
                font-weight: 500;
                margin-right: 15px;
            }
        }
    }

    .gam-body {
        padding: 15px;
        width: 100%;
        height: calc(100% - 76px);

        img,
        svg {
            width: 100%;
            height: 100%;
        }
    }
}

.consumption-per {
    padding: 1px 5px;
    color: #fff !important;
    font-weight: 600;
    background-color: $danger-color;
    border-radius: 3px;
    display: inline-block;
    font-size: 11px;
}
.bg-yellow {background-color: #B6AF03;}
.bg-green {background-color: #03B675;}
.bg-orange {background-color: #FC965C;}
.bg-red {background-color: $danger-color;}
.imtmp-filters {
    .gen-dropdown-pagination{
        padding-inline: 8px;
    }

    .generic-smart-input{
            border-radius: 0;
            height: 35.5px;
        }
} 

.inline-border-yellow {
    border-inline: solid 1px #ffd693!important;
}

// 
.create-asn-modal {
    .ant-toggle {
        .ant-switch {
            width: 70px;
            background-color: #efefef;

            .ant-switch-handle {
                width: 14px;
                height: 14px;
                top: 4px;
            }

            .ant-switch-handle::before {
                background-color: #969fad;
                transform: translateX(2px);
            }

            .ant-switch-inner {
                span {
                    font-size: 10px !important;
                    font-weight: 600 !important;
                    color: #111D31 !important;
                    margin-left: -4px !important;
                }

                .ant-switch-inner-checked {
                    display: none;
                }

                .ant-switch-inner-unchecked {
                    margin-inline-start: -31px;
                    margin-top: 0;
                    margin-inline-end: 0;
                }
            }
        }

        .ant-switch-checked {
            background-color: #e3fbed;

            .ant-switch-handle::before {
                background: #3ee585;
            }

            .ant-switch-inner {
                span{
                    font-size: 10px !important;
                    font-weight: 600 !important;
                    color: #111D31 !important;
                    margin-left: -2px !important;
                }
                .ant-switch-inner-checked {
                    display: block;
                    margin-inline-start: -7px;
                }

                .ant-switch-inner-unchecked {
                    display: none;
                }
            }
        }

        .ant-switch-checked:hover:not(.ant-switch-disabled) {
            background-color: #e3fbed;
        }
    }
  } 

  .displayBlock {
    display: block !important;
  }