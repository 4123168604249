@import '../variables.scss';

.signin-flow {
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    display: flex;
    // align-items: flex-start;
    align-items: center;
    justify-content: center;
    overflow-y: auto;

    .sf-top {
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('../../../assets/icons/SigninUpperBgImg.svg');
        background-size: cover;

        .sft-btn {
            gap: 12px;
            padding: 60px;
            position: relative;
            z-index: 998;
            position: absolute;
            right: 0;

            button {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 60px;
                border: 1px solid #fff;
                background-color: transparent;
                position: relative;
                color: #fff;
                font-size: 13px;
                font-weight: 600;
                padding: 4px 14px;
                height: 40px;

                svg {
                    margin-left: 7px;
                }
            }

            button:hover {
                background: #6e6fc5;
            }

            .list-component-section {
                width: auto;
                min-width: 150px;
                height: auto;
                position: absolute;
                top: 110px;
                right: 36px;
                border-radius: 6px;
                background: #fff;
                box-shadow: 0 4px 24px 0 hsla(0, 0%, 45%, .25);
                z-index: 1;

                .modal-header-section {
                    padding: 10px 15px;
                    border-bottom: 0.5px solid #f2f2f2;

                    .mhs-left {
                        h4 {
                            color: #8496a9;
                            font-size: 10px;
                            font-weight: 400;
                        }
                    }
                }

                .lcs-inner {
                    .list-component-normal-view {
                        .modal-data-list-render {
                            li {
                                padding: 5px 15px;

                                .mdlr-col {
                                    span {
                                        color: #2a2a64;
                                        font-size: 13px;
                                        font-weight: 500;
                                    }

                                    span:nth-child(2) {
                                        color: #8496a9;
                                        font-size: 10px;
                                        font-weight: 400;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .sf-modal {
        width: 360px;
        height: auto;
        position: relative;

        // margin: 50px auto 25px;
        margin-inline: auto;

        .sfm-inner {
            width: 100%;
            height: fit-content;
            border: 1px solid #e7e7e7;
            background-color: #fff;

            .sfmi-head {
                padding: 25px 25px 15px;
                img {
                    max-height: 44px;
                }
            }

            .check-password {
                padding: 5px 25px 20px;

                h2 {
                    color: #0F172A;
                    font-size: 23px;
                    font-weight: 600;
                    margin-bottom: 3px;
                }

                .cp-body {
                    padding-top: 10px;

                    .generic-input-box {
                        padding: 0;

                        .gib-label-heading {
                            color: #6C717D;
                            font-size: 11px;
                            font-weight: 400;
                        }

                        .gib-inner {
                            height: 40px;
                            padding: 0;

                            .input-icons {
                                top: 9px;
                            }

                            input {
                                padding: 0 35px !important;
                                border-radius: 4px;
                                color: #0f172a;
                                font-size: 14px;
                                font-weight: 500;
                            }

                            input:focus {
                                border: 1px solid $primary-color;
                                box-shadow: 0px 0px 0px 2px #DADAFF;
                            }

                            .psswrd-eye-icon {
                                top: 5px;
                            }
                        }
                    }

                    .cpb-check {
                        padding-bottom: 15px;
                    }

                    .generic-approve-btn {
                        width: 100%;
                        height: 40px;
                    }

                    .cpb-password-expired {
                        display: flex;
                        padding: 4px;
                        background: #EBE2CD;
                        margin-bottom: 9px;

                        svg {
                            flex: 0 0 45px;
                        }

                        .cpbpe-right {
                            h3 {
                                font-size: 15px;
                                font-weight: 600;
                                color: #0F172A;
                                margin: 0;
                            }

                            p {
                                font-size: 11px;
                                font-weight: 400;
                                color: #0F172A;
                                margin: 0;
                            }
                        }
                    }
                }
            }

            .otp-form {
                padding: 25px 25px 15px;

                .generic-form-footer {
                    padding: 0;

                    .gff-right {
                        width: 100%;
                        padding: 35px 0 10px;

                        button {
                            width: 100%;
                            height: 40px;
                            margin: 0;
                        }
                    }
                }
            }

            .sfmi-body {
                padding: 5px 25px 15px;

                .sfmib-wlcm {
                    padding-bottom: 5px;

                    h3 {
                        color: #0f172a;
                        font-size: 23px;
                        font-weight: 600;
                        margin: 0;
                    }

                    img {
                        width: 31px;
                        height: 31px;
                        margin-bottom: 2px;
                    }

                    p {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        color: #71819b;
                        font-size: 12px;
                        font-weight: 400;
                        margin: 0;

                        b {
                            color: #0f172a;
                            font-weight: 600;
                        }


                    }



                    .generic-approve-btn {
                        width: 100%;
                        height: 40px;
                        margin-top: 40px;
                    }
                }

                .sfmib-reset-psswrd {
                    padding-top: 25px;

                    label {
                        color: #6C717D;
                        font-size: 11px;
                        font-weight: 400;
                        margin-bottom: 6px;
                    }

                    button {
                        width: 110px;
                        height: 64px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 6px;
                        border: 1px solid #E2E4E5;
                        background-color: #FFF;
                        padding: 0;
                        margin-right: 15px;

                        .mob-icon {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                        }

                        .email-icon {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                        }

                        p {
                            color: #0F172A;
                            font-size: 10px;
                            font-weight: 500;
                            margin: 0;
                            margin-top: 5px;
                        }
                    }

                    button:hover,
                    .btn-active {
                        border: 1px solid $primary-color;

                        .mob-icon {
                            path {
                                fill: $primary-color;
                                stroke: $primary-color;
                            }

                            circle {
                                fill: $primary-color;
                            }
                        }

                        .email-icon {
                            path {
                                stroke: $primary-color;
                            }
                        }

                        p {
                            color: $primary-color;
                        }
                    }
                }

                .generic-input-box {
                    margin: 20px 0 10px;

                    label {
                        color: #6c717d;
                        font-size: 11px;
                        font-weight: 400;
                    }

                    .gib-inner {
                        height: auto;
                        border: none;
                        border-radius: 3px;
                        padding: 0;

                        .input-icons {
                            top: 9px;
                        }

                        .psswrd-eye-icon {
                            top: 5px;
                        }

                        input {
                            height: 40px;
                            border: 1px solid #d3d4d8;
                            color: #0f172a;
                            font-size: 14px;
                            font-weight: 500;
                            border-radius: 3px;
                            padding: 5px 12px;
                        }

                        .gibp-password-padding {
                            padding: 0 40px !important;
                        }

                        input:focus {
                            border: 1px solid $primary-color;
                            box-shadow: 0px 0px 0px 2px #DADAFF;
                        }
                        
                        .react-tel-input {
                            font-family: "Poppins", sans-serif;
                            input {
                                padding-left: 45px;
                            }
                            .country-list {
                                overflow-x: hidden;
                                .search{
                                    padding-top: 10px;
                                    padding-bottom: 10px;
                                    padding-right: 1px;
                                    .search-emoji {
                                        position: absolute;
                                        left: 19px;
                                        top: 14px;
                                        visibility: hidden;
                                    }
                                    .search-emoji::before {
                                        content: '⌕';
                                        position: absolute;
                                        left: 3px;
                                        top: -11px;
                                        visibility: visible;
                                        font-size: 1.5rem;
                                        transform: rotateY(180deg);
                                    }
                                    .search-box{
                                        font-size: 12px;
                                        margin-left: 0;
                                        height: 30px;
                                        padding-left: 35px;
                                    }
                                }
                                .country *{
                                    margin-right: 10px;
                                }
                                .country, .no-entries-message{
                                    font-size: 12px;
                                    padding-left: 14px;
                                }
                                .country:hover, .highlight{
                                    background-color: $primary-w-less-opacity;
                                }
                            }
                        }

                    }

                    .focusOn {
                        border: none !important;
                        box-shadow: none !important;
                    }
                }

                .sfmib-frgt-psswrd {
                    display: flex;
                    justify-content: end;
                    margin-top: 10px;

                    button {
                        background-color: #fff;
                        color: #71819b;
                        font-size: 12px;
                        font-weight: 400;
                    }
                }

                .otp-form {
                    padding: 10px 0 0;

                    .otp-head {
                        padding: 0;
                    }

                    .generic-form-footer {
                        padding: 0;

                        .gff-right {
                            width: 100%;
                            padding: 35px 0 10px;

                            button {
                                width: 100%;
                                height: 40px;
                                margin: 0;
                            }
                        }
                    }
                }

                .sfmib-btns {
                    width: 100%;
                    padding: 35px 0 10px 0;
                    text-align: center;

                    button {
                        width: 100%;
                        height: 40px;
                    }

                    .login-with-psswrd {
                        border-radius: 3px;
                        border: 2px solid $primary-color;
                        background-color: #fff;
                        color: $primary-color;
                        font-size: 14px;
                        font-weight: 500;
                    }

                    label {
                        color: #67768e;
                        font-size: 12px;
                        font-weight: 500;
                        margin: 10px 0;
                    }
                }

                .sfmibw-login-with-section {
                    p {
                        color: #71819b;
                        font-size: 12px;
                        font-weight: 400;
                        margin-bottom: 10px;
                    }
                    .sfmibw-login-with-option {
                        padding: 5px 15px;
                        border: solid 1.5px #DAE4EE;
                        font-size: 12px;
                        font-weight: 500;
                        color: #0F172A;
                        margin-right: 15px;
                    }
                    .sfmibw-active {
                        border: solid 1px $primary-color;
                        color: $primary-color;
                    }
                }
            }

            .sfmi-tag {
                position: relative;
                // border-top: 1px solid #e7e7e7;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                .sfmit-sec{
                    display: flex;
                    align-items: center;
                    position: relative;
                    span {
                        width: 36px;
                        height: 36px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        left: 0;
                        background-color: #fff;
                        filter: drop-shadow(0 0 8px rgba(0, 0, 0, .25));
                    }
    
                    .privacy-tag {
                        width: auto;
                        border-radius: 40px;
                        border: 1px solid $primary-color;
                        background: #F4F4FE;
                        padding: 1px 5px 1px 40px;
    
                        p {
                            color: $primary-color;
                            font-size: 10px;
                            font-weight: 400;
                            margin: 0;
                        }
                    }
                }
            }

            .sfmib-error {
                font-size: 10px;
                font-weight: 500;
                color: #c44569;
            }

            .sfmi-merge {
                padding: 0 25px;

                .sfmim-det {
                    h1 {
                        color: #0F172A;
                        font-size: 18px;
                        font-weight: 600;
                        margin: 0 0 5px 0;
                    }

                    p {
                        color: #71819B;
                        font-size: 12px;
                        font-weight: 400;
                        margin: 0;
                    }
                }

                .generic-spread-form {
                    .gsf-body {
                        .generic-form-body {
                            padding: 10px 0;

                            .gfb-inner {
                                .generic-input-box {
                                    width: 100%;
                                    margin-right: 0;

                                    .gib-label-heading {
                                        color: #0F172A;
                                        font-weight: 400;
                                    }

                                    .gib-inner {
                                        height: 35px;
                                    }

                                    .gib-password {
                                        .input-icons {
                                            top: 7px;
                                        }

                                        input {
                                            padding: 0 20px 0 30px;
                                        }
                                    }

                                    .gib-inner.error {
                                        .psswrd-eye-icon {
                                            right: 20px;
                                            top: 3px;
                                        }
                                    }
                                }

                                .cnuf-input {
                                    .gib-text {
                                        .input-icons {
                                            top: 7px;
                                        }

                                        input {
                                            padding: 0 20px 0 30px;
                                        }
                                    }
                                }

                                .generic-input-box:nth-child(1),
                                .generic-input-box:nth-child(2) {
                                    width: 48%;
                                }

                                .generic-input-box:nth-child(1) {
                                    margin-right: 3.33%;
                                }

                                .generic-input-box:nth-child(2) {
                                    margin-right: 0;
                                }

                                .generic-input-box:nth-last-child(1),
                                .generic-input-box:nth-last-child(2) {
                                    .gib-inner {
                                        .input-icons {
                                            top: 7px;
                                        }

                                        input {
                                            padding: 0 20px 0 30px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .generic-form-footer {
                        padding: 0 0 15px;

                        .gff-right {
                            width: 100%;

                            button {
                                width: 100%;
                                height: 40px;
                                margin: 0;
                            }
                        }
                    }
                }
            }

            .onboarding-det {
                padding: 5px 25px 15px;

                .od-reg {
                    h2 {
                        color: #0F172A;
                        font-size: 18px;
                        font-weight: 600;
                    }

                    p {
                        color: #71819B;
                        font-size: 12px;
                        font-weight: 400;
                        margin: 0;
                    }

                    .generic-spread-form {
                        .gsf-body {
                            .generic-form-body {
                                padding: 10px 0;

                                .gfb-inner {
                                    .generic-input-box {
                                        width: 100%;
                                        margin-right: 0;

                                        .gib-label-heading {
                                            color: #0F172A;
                                            font-weight: 400;
                                        }

                                        .gib-inner {
                                            height: 36px;

                                            input {
                                                font-size: 11px;
                                                color: #0F172A;
                                            }
                                        }
                                    }

                                    .generic-input-box:nth-child(1),
                                    .generic-input-box:nth-child(2) {
                                        width: 50%;
                                    }

                                    .generic-input-box:nth-child(1) {
                                        padding-right: 3.33%;
                                    }

                                    .generic-input-box:nth-last-child(1) {
                                        .gib-inner {
                                            padding: 0 5px 0 40px;

                                            .input-icons {
                                                top: 6px;
                                            }

                                            .cursor-pointer {
                                                height: 100%;

                                                .psswrd-eye-icon {
                                                    position: static;
                                                    height: 100%;
                                                    padding: 5px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .generic-form-footer {
                            padding: 15px 0;

                            .gff-right {
                                width: 100%;

                                button {
                                    width: 100%;
                                    height: 40px;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }

                .od-congratulation-screen {
                    .odc-top {
                        text-align: center;

                        h1 {
                            color: #0F172A;
                            font-size: 25px;
                            font-weight: 700;
                            margin-bottom: 0;
                        }

                        p {
                            color: #0F172A;
                            font-size: 15px;
                            font-weight: 400;
                        }
                    }

                    .odc-steps {
                        display: flex;
                        justify-content: space-between;
                        padding: 35px 0 30px;

                        .odcs-sec {
                            width: 33.33%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            span {
                                color: #0F172A;
                                font-size: 12px;
                                font-weight: 400;
                                margin: 5px 0;
                            }

                            p {
                                color: #707494;
                                font-size: 14px;
                                font-weight: 600;
                            }
                        }

                        .odcs-sec-succes {
                            p {
                                color: #179C5F;
                            }
                        }
                    }

                    .odc-content {
                        text-align: center;
                        padding-bottom: 60px;

                        p {
                            font-size: 13px;
                            font-weight: 400;
                            color: #0f172a;
                            margin: 0;
                        }
                    }
                }
            }
        }

        .sfm-outer {
            // padding-top: 10px;
            margin-top: -5px;
            margin-bottom: 1rem;

            .sfmo-copy-right {
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    margin-right: 2px;
                }

                span {
                    // color: #fff;
                    color: #67768E;
                    font-size: 10px;
                    font-weight: 400;
                    padding-top: 1px;

                }
            }

            .sfmo-back-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-top: 15px;

                button {
                    display: flex;
                    align-items: center;
                    color: #0F172A;
                    font-size: 14px;
                    font-weight: 500;
                    padding-top: 1px;

                    svg {
                        margin-right: 5px;
                    }
                }

                button:hover {
                    opacity: 0.4;
                }
            }
        }
    }
}

.time-text {
    height: 30px;
    padding-right: 8px;

    p {
        font-weight: 700;
        font-size: 14px;
        color: $primary-color;
        margin: 0;
        height: 17px;
    }

    label {
        font-weight: 400;
        font-size: 11px;
        margin: 0;
        color: #67768E;
    }
}

// Merged Account
.merged-acc {
    background: #EBE2CD;
    border: 1px solid rgba(238, 238, 238, 0.40);
    // margin: 200px 0 10px;
    margin: 0px 0 10px;

    .ma-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 25px 10px;

        p {
            color: #0F172A;
            font-size: 18px;
            font-weight: 600;
            margin: 2px 0;
        }

        .p-para {
            font-size: 12px;
            font-weight: 400;
            margin: 0;
        }

        button {
            width: 32px;
            min-width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: #C5AD84;

            svg {
                path {
                    stroke: #fff !important;
                }
            }
        }
    }

    .mab-outer {
        height: auto;
        max-height: 160px;
        overflow: auto;
    }

    .ma-bot {
        display: table;

        .mab-det {
            display: table-row;

            .mabd {
                padding: 5px;
                display: table-cell;
                padding-right: 20px;
                border-top: 1px solid #eee;
                vertical-align: middle;

                label {
                    color: #0F172A;
                    font-size: 10px;
                    font-weight: 400;
                    white-space: nowrap;
                    margin: 0;
                }

                p {
                    color: #0F172A;
                    font-size: 12px;
                    font-weight: 500;
                    margin: 0;
                    white-space: nowrap;
                }
            }

            .mabd:first-child {
                padding-left: 25px;
            }

            .mabd:last-child {
                padding-right: 25px;
            }
        }
    }
}

.merged-acc-open {
    border: 1px solid rgba(238, 238, 238, 0.40);
    background: linear-gradient(180deg, #EBE2CD 0%, #FFF 35.42%);
}

// Edit Email
.edit-email {
    height: 100%;

    .success-msg {
        display: flex;
        align-items: center;
        background: #05c46b;
        padding: 10px 15px;

        svg {
            margin-right: 10px;
        }

        p {
            font-weight: 600;
            font-size: 14px;
            color: #fff;
            margin: 0;
        }
    }

    .modal-header-section {
        padding: 15px 25px;

        .mhs-left {
            h4 {
                font-size: 20px;
            }
        }
    }

    .ee-body {
        height: calc(100% - 122px);
        overflow: auto;
        position: relative;

        .otp-form {
            .otp-head {
                padding: 0 25px;

                h3 {
                    font-size: 18px;
                }
            }
        }

        .ee-alert {
            display: flex;
            border-radius: 5px;
            border: 1px solid #FE9D0B;
            background: rgba(254, 157, 11, 0.10);
            padding: 10px 15px;
            margin: 0 20px 10px;

            p {
                color: #2A2A64;
                font-size: 12px;
                font-weight: 500;
                margin-bottom: 5px;
            }

            span {
                color: #8D660D;
                font-size: 12px;
                font-weight: 500;
            }
        }

        .generic-input-box {
            padding: 10px 25px;
            margin: 0;
        }
    }

    .ee-otp-body {
        height: calc(100% - 102px);
    }
}

.multi-acc-merge {
    width: 100%;
    height: 100%;

    .mac-inner {
        width: 100%;
        height: calc(100% - 60px);
        overflow: auto;
        padding: 15px;
    }

    .eud-multi-acc-alert {
        width: 100%;
        height: auto;
        background-color: #fff5e7;
        border: 1px solid #FE9D0B;
        border-radius: 5px;
        display: flex;
        padding: 10px;
        margin-bottom: 15px;

        svg {
            flex: 0 0 30px;
        }

        .eudmaa-content {
            margin-left: 15px;

            label {
                font-size: 16px;
                font-weight: 700;
                color: #111D31;

                span {
                    color: #FE9D0B
                }
            }

            p {
                font-size: 12px;
                font-weight: 400;
                color: #757477;
                margin: 0;
            }
        }
    }

    .eud-merge-req {
        border: 1px solid #E9E9E9;
        background: #E9E9E9;

        label {
            font-weight: 600;
        }
    }

    .eudmaa-scroll {

        // height: calc(100% - 292px);
        // overflow-x: hidden;
        // overflow-y: auto;
        .eud-accounts {
            h2 {
                color: #67768E;
                font-size: 12px;
                font-weight: 600;
            }

            .euda-accounts {
                display: flex;
                align-items: center;
                padding: 0px 25px;
                border: 1px solid #E9E9E9;
                border-radius: 5px;
                height: 67px;
                margin: 10px 0px 20px;
                border: 1px solid #2969FF;
                background: rgba(41, 105, 255, 0.04);
                justify-content: space-between;

                .eudaa-acc-details {
                    p {
                        color: #111D31;
                        font-size: 13px;
                        font-weight: 700;
                        margin-bottom: 5px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 230px;
                        white-space: nowrap;
                        width: auto;
                        margin: 0;
                    }

                    .acc-type {
                        background: #DAE6FD;
                        border-radius: 3px;
                        color: #0B275F;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0px 5px;
                    }

                    label {
                        color: #757477;
                        font-size: 11px;
                        font-weight: 400;
                    }

                    .eudaa-primary-acc {
                        font-size: 11px;
                        font-weight: 600;
                        color: #2969FF;
                        padding: 3px 5px;
                        display: inline-block;
                        border: 1px solid #2969FF;
                        border-radius: 50px;
                        margin-left: 10px;
                        text-align: center;
                    }
                }

                button::before {
                    content: "";
                    position: absolute;
                    top: 4px;
                    right: auto;
                    left: -27px;
                    height: 20px;
                    width: 1px;
                    display: block;
                    background: #E9E9E9;
                }
            }

            .euda-oth-accs {
                border: 1px solid #E9E9E9;
                border-bottom: none;
                border-radius: 5px;
                margin: 10px 0px 20px;

                .euda-oth-acc {
                    display: flex;
                    align-items: center;
                    padding: 0px 25px;
                    height: 67px;
                    border-bottom: 1px solid #E9E9E9;

                    p {
                        color: #111D31;
                        font-size: 13px;
                        font-weight: 700;
                        margin-bottom: 5px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        max-width: 328px;
                        white-space: nowrap;
                        width: auto;
                        margin: 0;
                    }

                    .acc-type {
                        background: #DAE6FD;
                        border-radius: 3px;
                        color: #0B275F;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0px 5px;
                    }

                    label {
                        color: #757477;
                        font-size: 11px;
                        font-weight: 400;
                    }

                    button::before {
                        content: "";
                        position: absolute;
                        top: 4px;
                        right: auto;
                        left: -27px;
                        height: 20px;
                        width: 1px;
                        display: block;
                        background: #E9E9E9;
                    }
                }

                .euda-oth-acc:nth-last-child(1) {
                    border-radius: 5px;
                }
            }
        }
    }

    button {
        width: 165px;
        height: 30px;
        position: relative;
        margin-left: 100px;
    }
}

.acc-setup-cmpltd {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .setup-cmpltd {
        width: 45%;
        flex: 0 0 45%;
        height: 100%;
        background: #24A148;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 40px;
        border-radius: 4px 0 0 4px;

        .sc-top {
            display: flex;
            flex-direction: column;
            align-items: center;

            .sc-content {
                padding: 25px 0px;

                label {
                    font-size: 19px;
                    font-weight: 700;
                    color: #fff;
                }

                p {
                    color: #fff;
                    font-size: 12px;
                    font-weight: 400;
                    margin: 0;
                    padding-top: 15px;
                }
            }
        }

        .sc-bot {
            display: flex;
            justify-content: center;
            align-items: center;

            button {
                width: 228px;
                height: 44px;
                background: #fff;
                color: #24A148;
                border-radius: 3px;
                font-size: 13px;
                font-weight: 600;
            }
        }
    }

    .switch-org {
        width: 55%;
        flex: 0 0 55%;
        height: 100%;
        background: #edf3fa;
        text-align: center;
        padding: 30px 20px 0px;
        border-radius: 0 4px 4px 0;

        p {
            color: #2A2A64;
            font-size: 18px;
            font-weight: 600;
            margin: 0;
        }

        .success-img {
            height: 100%;
            height: calc(100% - 90px);

            img {
                margin-top: 10px;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}

.signInLanguageSelect {

    .generic-input-box {
        margin-bottom: 0;
        .gib-label-heading{
            color: white
        }
    }

    .list-component-section {
        min-width: auto !important;
        width: 100% !important;
        top: 100% !important;
        margin-top: 5px !important;
    }
}

.languagePopup {
    .generic-input-box{
        max-width: 130px;
    }
    .list-component-section{
        width: 100% !important;
        top: 35px !important;
        border-radius: 0 !important;
    }
    button{
        border-radius: 60px;
        height: 28px;
    }
    // svg{
    //     path{
    //         fill: white;
    //     }
    // }
}