@import '../variables.scss';

// Style New Design Of vendor Portal
.vgt-table {
    padding: 0 15px 1px 30px;

    .table-loader-bg {
        padding: 10px 0 0;
    }
}

.gen-vendor-potal-design {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border-bottom: 1px solid #dce6f0;
    background: #fff;
    padding: 10px 15px 10px 25px;

    .gvpd-left {
        display: flex;
        align-items: center;
        justify-content: start;
        position: relative;

        h3 {
            font-size: 16px;
            font-weight: bold;
            color: #12203c;
            margin: 0;
        }

        .gvpd-search {
            position: relative;
            display: inline-block;
            margin-right: 15px;

            form {
                margin: 0;
            }

            input[type="search"] {
                border-radius: 5px;
                border: solid 1px #dce6f0;
                background: #fff;
                padding: 8px 30px;
                height: 32px;
                font-size: 11px;
                color: #000;
                font-weight: 600;
                width: 250px;
            }

            input:focus {
                border: solid 1px #cdc4ff;
            }

            input::placeholder {
                font-size: 11px;
                font-weight: 500;
                color: #12203c;
            }

            .search-image {
                position: absolute;
                top: 8px;
                left: 7px;
                width: 15px;
            }

            .closeSearch {
                position: absolute;
                right: 10px;
                top: 10px;
                cursor: pointer;
            }

            // .pipdf-barcode {
            //     position: absolute;
            //     top: 11px;
            //     left: 7px;
            // }
        }

        button {
            border-radius: 5px;
            border: solid 1px #ccd8ed;
            background-color: #ffffff;
            font-size: 12px;
            padding: 5px 15px;
            margin-right: 15px;
            height: 32px;
            font-weight: 500;
            position: relative;
            display: inline-block;
        }

        .toggle-button-qc {
            display: block;
            margin: 0;
            margin-right: 15px;

            button {
                margin: 0;
                background-color: #f9f9f9;
                color: #d8d3d3;
                padding: 7px 16px;
                height: 32px;
                border: none;
                font-weight: 600;
            }

            .tbqc-confirm {
                border-radius: 5px 0 0 5px;
            }

            .tbqc-not-confirm {
                border-radius: 0 5px 5px 0;
            }
        }

        .toggle-button-qc.toggle-not-confirm {
            .tbqc-not-confirm {
                background-color: $primary-color;
                color: #ffffff;
            }
        }

        .toggle-button-qc.toggle-confirm {
            .tbqc-confirm {
                background-color: $primary-color;
                color: #ffffff;
            }
        }

        .drop-toggle-btn {
            display: block;
            position: relative;
            margin-right: 15px;

            .dtb-show {
                background-color: #ffffff;
                font-weight: 600;
                color: $primary-color;
                border: 2px solid $primary-color;
                padding: 5px 8px;
                border-radius: 4px;
                min-width: 150px;
                margin: 0px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                svg {
                    path {
                        fill: $primary-color;
                    }
                }
            }

            .dtb-hide {
                display: flex;
                align-items: center;
                justify-content: space-between;
                visibility: hidden;
                opacity: 0;
                position: absolute;
                top: 34px;
                left: 0;
                color: #12203c;
                font-weight: 600;
                height: 34px;
                width: 100%;
                padding: 5px 8px;
                margin: 0;
                border: none;
                text-align: left;
                box-shadow: 0 2px 6px 0 rgba(75, 75, 75, .16);
                transition: 0.6s all;
                z-index: 1;

                i {
                    display: none;
                }
            }

            .dtb-hide:hover {
                background-color: #e5edf8;
            }

            .dtb-dropdown {
                visibility: hidden;
                opacity: 0;
                position: absolute;
                top: 40px;
                left: 0;
                height: auto;
                width: 100%;
                background-color: #fff;
                padding: 5px 0;
                border: none;
                text-align: left;
                box-shadow: 0 2px 6px 0 rgba(75, 75, 75, .16);
                transition: 0.6s all;
                z-index: 2;

                .dtb-hide {
                    position: relative;
                    top: auto;
                    left: auto;
                    display: block;
                    height: auto;
                    box-shadow: none;
                    z-index: auto;
                    font-weight: 500;
                    border-radius: 0px;
                }

                .dtb-show {
                    display: none;
                }
            }

            .dtbd-vendord {
                width: 170px;
            }
        }

        .drop-toggle-btn:hover {
            .dtb-hide {
                transition: 0.6s all;
                visibility: visible;
                opacity: 1;
            }

            .dtb-dropdown {
                transition: 0.6s all;
                visibility: visible;
                opacity: 1;
            }

            .dtb-hide:hover {
                background-color: #e5edf8;
                font-weight: 600;
            }
        }

        .gvpd-back {
            border-radius: 21px;
            border: solid 1px #000;
            padding: 3px 10px;
            display: flex;
            align-items: center;
            height: 30px;

            .back-arrow {
                display: inline-block;
                vertical-align: middle;
                margin-right: 6px;
            }
        }

        .quick-filter {
            position: relative;

            .qf-btn {
                display: flex;
                align-items: center;
                font-weight: 600;
                color: #12203c;
                padding: 3px 6px;

                .qf-img {
                    margin-right: 6px;
                }
            }

            .qf-btn-active {
                color: #fff;
                background-color: $primary-color;
                border: 1px solid $primary-color;
                transition: all .3s;

                .qf-img {
                    svg {
                        .qfi-1 {
                            stroke: $primary-color;
                        }

                        .qfi-2 {
                            fill: $primary-color;
                        }

                        g {
                            path {
                                fill: #fff;
                            }
                        }
                    }
                }
                .qf-down-arrow {
                    svg {
                        path {
                            stroke: #fff;
                        }
                    }
                }
            }

            .qf-btn:hover {
                color: #fff;
                background-color: $primary-color;
                border: 1px solid $primary-color;
                transition: all .3s;

                .qf-img {
                    svg {
                        .qfi-1 {
                            stroke: $primary-color;
                        }

                        .qfi-2 {
                            fill: $primary-color;
                        }

                        g {
                            path {
                                fill: #fff;
                            }
                        }
                    }
                }

                .qf-down-arrow {
                    svg {
                        path {
                            stroke: #fff;
                        }
                    }
                }

                .generic-tooltip {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        .generate-report-btn {
            border: solid 1px $primary-color;
            background-color: $primary-color;
            color: #fff;
            font-weight: 600;
            border-radius: 4px;
            padding: 3px 20px;
        }

        .ssaf-date-range-btn {
            border: 1px solid #e8ecf4;
            border-radius: 15px;
            background-color: #e8ecf4;
        }
    }

    .gvpd-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
            position: relative;
            margin-left: 10px;
        }

        button:nth-last-child(1) {
            .generic-tooltip {
                left: -100%;
            }

            .generic-tooltip::after {
                left: auto;
                right: 15%;
            }
        }

        button:hover {
            .generic-tooltip {
                visibility: visible;
                opacity: 1;
            }
        }

        .gen-cancel {
            border: solid 1px #E66767;
            background-color: #E66767;
            color: #fff;
            font-weight: 600;
        }

        .gen-reject {
            border: solid 1px #E66767;
            background-color: #ffffff;
            color: #E66767;
            font-weight: 600;
        }

        .gen-delete {
            background-color: #ffffff;
            color: #E66767;
            font-weight: 600;
        }

        .gen-approved {
            border: 1px solid $primary-color;
            background-color: $primary-color;
            color: #fff;
            padding: 5px 10px;
            font-weight: 600;

            img {
                margin-right: 7px;
                width: 17px;
            }
        }

        .gen-approved-partail {
            border: none;
            background-color: $primary-color;
            color: #fff;
            padding: 0;
            font-weight: 700;
            display: flex;
            align-items: center;

            .ga-text {
                padding: 7px 15px;
                display: inline-block;

                img {
                    margin-right: 7px;
                    width: 15px;
                }
            }

            .partial-aprv {
                position: relative;
                display: inline-block;
                padding: 9px 10px;
                border-left: 1px solid #b1a3fc;
                border-radius: 0 5px 5px 0;
            }

            .partial-aprv:hover {
                background-color: #b1a3fc;

                .generic-tooltip {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        .gen-approved-partail:hover {
            .generic-tooltip {
                visibility: hidden;
                opacity: 0;
            }
        }

        .gen-transfer-order {
            color: #21324b;
            padding: 3px 15px;
            font-weight: 600;

            svg {
                margin-right: 6px;
            }
        }

        .gen-save {
            border: 1px solid $primary-color;
            background-color: $primary-color;
            color: #fff;
            padding: 5px 25px;
            font-weight: 600;
        }

        .gen-clear {
            padding: 5px 20px;
        }

        .rab-rinner {
            .checkBoxLabel0 {
                font-size: 12px;
                font-weight: 500;
                color: #12203c;
                cursor: pointer !important;
                margin-left: 24px;
                margin-bottom: 0;

                .checkmark1 {
                    position: absolute;
                    top: 0px;
                    left: 10px;
                    height: 20px;
                    width: 20px;
                    background-color: #eee;
                    border-radius: 5px;
                }
            }

            .checkBoxLabel0 input:checked~.checkmark1 {
                background-color: $primary-color !important;
            }
        }

        .btnDisabled {
            border: 1px solid #f9f9f9 !important;
            // background-color: #f9f9f9!important;
            opacity: .4;

            img {
                margin-right: 7px;
                width: 17px;
            }
        }

        .gen-hold {
            border: 1px solid #ff7703;
            color: #ff7703;
        }

        .gen-new-upload {
            color: #51aa77;
            background: #c8f9e6;
            box-shadow: none;
            padding: 6px 8px;
            border: none;
            border-radius: 30px;
            display: flex;
            align-items: center;

            svg {
                margin-right: 8px;
            }
        }

        .gen-aprv-disabled {
            padding: 5px 10px;
        }

        select {
            padding: 3px 15px;
            border-radius: 5px;
            background: #fff;
            border: 1px solid #ccd8ed;
            height: 32px;
            margin-right: 20px;
            font-size: 12px;
            font-weight: 500;
        }

        .gvpd-download-drop {
            display: inline-block;
            position: relative;

            .pi-history-download {
                white-space: nowrap;
                display: block;
                position: absolute;
                top: 42px;
                right: 0;
                width: 220px;
                padding: 10px 0;
                border-radius: 12px;
                box-shadow: 0 -1px 6px 0 rgba(177, 177, 177, 0.16);
                background: #ffffff;
                margin: 0;
                z-index: 9;

                li {
                    display: block;

                    button {
                        display: flex;
                        padding: 5px 10px;
                        margin: 0;
                        border: none;
                        width: 100%;
                        height: auto;
                        text-align: left;
                        white-space: nowrap;
                        border-radius: 0;
                        transition: all .6s;
                        align-items: center;

                        img {
                            width: 17px;
                            // margin-right: 12px;
                        }

                        span {
                            display: inline-block;
                            padding: 7px 0px;
                            border-radius: 50%;
                            height: 32px;
                            width: 32px;
                            text-align: center;
                            margin-right: 10px;
                        }

                        // .pipdf-text {
                        //     border-radius: 0;
                        //     max-width: 100px;
                        //     text-align: left;
                        //     width: auto;
                        //     text-overflow: ellipsis;
                        //     overflow: hidden;
                        //     margin: 0;
                        // }

                        .pi-pdf-img {
                            width: 20px;
                            margin-left: 8px;
                        }

                        div {
                            display: inline-block;

                            .multipleDownloadSelect {
                                border: none;
                                padding: 0;
                                -webkit-appearance: none;
                                background: #fff !important;
                            }
                        }

                        a {
                            font-size: 10px;
                            font-weight: 600;
                            background: transparent;
                            color: #21314b;
                            padding: 3px 8px;
                            border-radius: 50px;
                            text-align: right;
                            position: absolute;
                            top: 11px;
                            right: 10px;
                            text-decoration: none;
                        }

                        a:hover {
                            color: #21314b;
                        }
                    }

                    .export-excel {
                        color: #a4b9dd;

                        span {
                            margin-right: 10px;
                        }
                    }

                    .export-excel:hover {
                        color: #12203c;

                        span {
                            background-color: #049d4b14;

                            svg {
                                path {
                                    fill: #049d4b;
                                }

                                text {
                                    fill: #049d4b;
                                }
                            }
                        }
                    }

                    .pi-pdf-download {
                        // .pipdf-barcode {
                        //     padding: 10px 0px;
                        // }

                        .btnDisabled {
                            color: #a4b9dd !important;
                        }
                    }

                    .pi-pdf-download:hover {
                        background: #ebf2fa;
                        transition: 0.6s all;

                        .pi-pdf-svg {
                            background-color: #eb000817;

                            svg {
                                path {
                                    fill: #eb0008;
                                }

                                text {
                                    fill: #eb0008;
                                }
                            }
                        }

                        // .pipdf-barcode {
                        //     background-color: #3279dd1f;

                        //     svg {
                        //         path {
                        //             fill: #3279dd;
                        //         }
                        //     }
                        // }

                        // .pipdf-invoice {
                        //     background-color: #e2c80017;

                        //     svg {
                        //         path {
                        //             fill: #e2c800;
                        //         }
                        //     }
                        // }

                        // .pipdf-packing {
                        //     background-color: #00ced617;

                        //     svg {
                        //         path {
                        //             fill: #00ced6;
                        //         }
                        //     }
                        // }

                        a {
                            color: #fff;
                        }

                        // .pipd-pipdf {
                        //     background-color: #eb0008;
                        // }

                        // .pipd-barcode {
                        //     background-color: #3279dd;
                        // }

                        // .pipd-invoice {
                        //     background-color: #e2c800;
                        // }

                        // .pipd-packing {
                        //     background-color: #00ced6;
                        // }
                    }
                }
            }

            .pihd-v2 {
                width: 240px;

                // li button .pipdf-text {
                //     max-width: 120px;
                // }
            }
        }

        .pi-download,
        .pi-download2 {
            width: 32px;
            padding: 0;
        }

        .pi-download2 {
            border: 1px solid #d8d3d3;
        }

        .pi-download:hover {
            color: #fff;
            background-color: #4a5568;
            border: 1px solid #4a5568;
            transition: 0.3s all;

            svg path {
                fill: #fff;
            }
        }

        .pi-download-focus {
            color: #fff;
            background-color: #4a5568;
            border: 1px solid #4a5568;

            svg path {
                fill: #fff;
            }
        }

        .gvpd-filter {
            position: relative;

            .gvpd-filter-inner {
                width: 32px;
                padding: 0;
            }

            .gvpd-filter-inner:hover {
                color: #fff;
                background-color: #4a5568;
                border: 1px solid #4a5568;
                transition: 0.3s all;

                svg path {
                    fill: #fff;
                }
            }

            .gvpd-filter-inner-focus {
                color: #fff;
                background-color: #4a5568;
                border: 1px solid #4a5568;
                transition: 0.3s all;

                svg path {
                    fill: #fff;
                }
            }

            .noOfFilter {
                position: absolute;
                right: -9px;
                top: -7px;
                background-color: #eb0008;
                border-radius: 50%;
                padding: 2px 0;
                font-size: 10px;
                width: 17px;
                height: 17px;
                text-align: center;
                color: #fff;
            }
        }

        .toggle-button-qc {
            display: block;
            margin: 0;

            button {
                margin: 0;
                background-color: #f9f9f9;
                color: #d8d3d3;
                padding: 7px 16px;
                height: 32px;
                border: none;
                font-weight: 600;
            }

            .tbqc-confirm {
                border-radius: 5px 0 0 5px;
            }

            .tbqc-not-confirm {
                border-radius: 0 5px 5px 0;
            }
        }

        .toggle-button-qc.toggle-not-confirm {
            .tbqc-not-confirm {
                background-color: $primary-color;
                color: #ffffff;
            }
        }

        .toggle-button-qc.toggle-confirm {
            .tbqc-confirm {
                background-color: $primary-color;
                color: #ffffff;
            }
        }

        .add-btn {
            display: inline-block;
            position: relative;
            cursor: pointer;

            img {
                width: 27px;
            }

            .generic-tooltip {
                top: -30px;
                right: 50%;
                transform: translate(15%);
                width: auto;
                left: auto;
                white-space: nowrap;
            }

            .generic-tooltip:after {
                right: 10%;
                left: auto;
            }
        }

        .add-btn:hover>.generic-tooltip {
            visibility: visible;
            opacity: 1;
        }

        .gvpd-three-dot-btn {
            position: relative;
            display: inline-block;

            .gvpdtdb-btn {
                border: none;
                padding: 0 8px;

                span {
                    position: relative;
                    display: inline-block;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: #000000;
                }

                span:before,
                span:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    display: inline-block;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: #000000;
                }

                span:before {
                    left: -8px;
                }

                span:after {
                    right: -8px;
                }
            }

            .gvpdtd-menu {
                position: absolute;
                top: 45px;
                display: block;
                right: 0;
                background: #fff;
                z-index: 5;
                box-shadow: 0 2px 6px 0 rgba(75, 75, 75, 0.16);
                border-radius: 6px;
                width: 170px;

                .gvpdtdm-inner {
                    padding: 0;
                    margin: 0;

                    li {
                        button {
                            display: block;
                            margin: 0;
                            border: none;
                            padding: 6px 20px;
                            width: 100%;
                            text-align: left;
                            color: #12203c;
                            border-radius: 0;
                        }

                        button:hover {
                            background-color: #e5edf8;
                            font-weight: 600;
                        }

                        .gvpdtdm-cancel {
                            color: #eb0008;
                        }

                        .btnDisabled {
                            background-color: #ffffff !important;
                            border: none !important;
                        }

                        .gvpdtdm-update-status {
                            padding-right: 0;
                        }
                    }
                }
            }
        }

        .gvpd-back {
            border-radius: 21px;
            border: solid 1px #000;
            padding: 3px 10px;
            display: flex;
            align-items: center;
            height: 30px;

            .back-arrow {
                display: inline-block;
                vertical-align: middle;
                margin-right: 6px;
            }
        }

        .success_button {
            font-size: 11px;
            padding: 0;
            margin-left: 0;
            width: 60px;
            height: 25px;
            border-radius: 12px;
            background-color: #fff;
            border: 1px solid #dcdcdc;
            font-weight: 600;
            outline: none;
            transition: .5s;
            position: absolute;
        }

        .success_button:hover {
            background-color: $primary-color;
            border: solid 1px $primary-color;
        }

        .gvpdr-email {
            padding: 3px 10px;

            span {
                margin-left: 10px;
            }
        }

        .gvpdr-btn-with-dropd {
            position: relative;

            button {
                padding: 5px 12px;

                svg {
                    margin-left: 15px;
                }
            }

            button:hover,
            .gvpdr-btn-focus {
                background-color: $primary-color;
                color: #fff;
                font-weight: 600;
                border: solid 1px $primary-color;

                svg {
                    path {
                        stroke: #fff;
                    }
                }
            }

            .btnDisabled {
                svg {
                    path {
                        stroke: #d8d3d3;
                    }
                }
            }

            .gvpdrbwdd-down {
                position: absolute;
                top: 40px;
                right: 0;
                width: 180px;
                height: auto;
                max-height: 150px;
                overflow: auto;
                z-index: 99;
                background: #fff;
                border-radius: 5px;
                box-shadow: 0 0 16px 1px #a3a3a340;
                padding: 8;

                ul {
                    padding: 0;
                    margin: 0;

                    li {
                        padding: 7px 10px;
                        font-size: 12px;
                        font-weight: 600;
                        color: #21314b;
                        background-color: #fff;
                        transition: 0.6s all;
                        cursor: pointer;
                    }

                    li:hover {
                        background-color: #edf1f5;
                        transition: 0.6s all;
                    }
                }
            }
        }

        .sohr-upload-download {
            display: flex;
            align-items: center;
            height: 32px;
            border: solid 1px #dae4ee;
            position: relative;
            border-radius: 4px;
            padding: 3px 10px;
            padding-right: 0px;
            margin-left: 15px;

            label {
                margin: 0;
                font-size: 12px;
                font-weight: 600;
                cursor: pointer !important;

                input {
                    display: none;
                }
            }

            .sohrud-arrow {
                padding: 2px 10px;
                border: none;
                height: auto;
                border-left: solid 1px #c7d3df;
                border-radius: 0;
                margin-left: 10px;
            }

            .sohrud-download {
                position: absolute;
                top: 40px;
                left: 0px;
                padding: 3px 8px;
                border-radius: 4px;
                box-shadow: 0 1px 11px -3px rgba(1, 1, 1, 0.15);
                border-radius: 4px;
                height: 38px;
                border: none;
                color: #21314b;
                font-weight: 500;
                margin: 0;
                text-align: left;
                white-space: nowrap;
                display: flex;
                align-items: center;
                z-index: 9;

                svg {
                    margin-right: 5px;
                }
            }

            .sohrud-dropdown {
                position: absolute;
                box-shadow: 0 1px 11px -3px #01010126;
                top: 40px;
                right: 0;
                width: 130px;
                z-index: 9;
                background: #fff;
                padding: 5px 0;

                .sohrud-download {
                    position: relative;
                    box-shadow: none;
                    top: auto;
                    left: auto;
                    height: auto;
                    padding: 6px 8px;
                    width: 100%;
                }
            }
        }

        .sohr-upload-download-focus {
            border: solid 1px $primary-color;

            .sohrud-arrow {
                svg {
                    transform: rotate(180deg);

                    path {
                        fill: $primary-color;
                    }
                }
            }
        }
    }


}


.qf-drop {
    display: block;
    position: absolute;
    top: 40px;
    left: 0;
    width: 400px;
    padding: 0;
    margin: 0;
    z-index: 99;
    border-radius: 4px;
    box-shadow: 0 1px 11px -3px rgba(1, 1, 1, 0.15);
    background-color: #fff;
    height: 370px;

    .qfd-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 9px 15px;
        border-bottom: solid 1px #dae4ee;

        .qfdh-left {
            h5 {
                font-size: 13px;
                font-weight: bold;
                color: #21314b;
                margin: 0;
            }
        }

        .qfdh-right {
            display: flex;
            align-items: center;

            button {
                margin: 0;
                padding: 3px 10px;
                border-radius: 20px;
                border: none;
                height: auto;
                color: #67768e;
                font-weight: 600;
                font-size: 11px;
            }

            .qfdhr-setting {
                padding: 0 5px;
            }

            .qfdhr-cancel {
                border: solid 1px #dae4ee;
                margin-right: 10px;
            }

            .qfdhr-save {
                width: 52px;
                border: solid 1px $primary-color;
                color: #fff;
                background-color: $primary-color;

                .btn-loader-inner {
                    width: 1.25rem;
                    height: 1.25rem;
                    border-width: .2em;
                    top: 0;
                }
            }
        }
    }

    ul {
        margin: 0;
        padding: 0;
        height: calc(100% - 43px);
        overflow: auto;

        li {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;
            padding: 10px 15px;
            cursor: pointer;
            font-size: 12px;
            font-weight: 500;

            .qfd-left {
                display: block;
                width: 90%;

                p {
                    font-size: 10px;
                    font-weight: 500;
                    margin: 0;
                    color: #21314b;
                    font-style: italic;
                }

                .qfd-default-text {
                    font-size: 10px;
                    font-weight: 600;
                    color: #fff;
                    padding: 3px 6px;
                    border-radius: 20px;
                    background-color: #67768e;
                    display: inline-block;
                    margin-left: 5px;
                }
            }

            .qfd-right {
                display: flex;
                align-items: center;

                .qfd-delete {
                    width: 28px;
                    height: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: transparent;
                    border: none;
                    margin: 0;
                    border-radius: 50%;
                }

                .qfd-delete:hover {
                    background-color: #eb000826;
                    transition: 0.3s all;

                    svg path:last-child {
                        fill: #eb0008;
                    }
                }

                .qfdr-set-default {
                    padding: 3px 5px;
                    border-radius: 20px;
                    position: relative;
                    margin: 0;
                    margin-right: 10px;
                    display: flex !important;
                    align-items: center;
                    cursor: pointer !important;

                    svg {
                        margin-right: 3px;
                    }

                    input[type="radio"] {
                        display: none;
                    }

                    .qfdrsd-check {
                        display: block;
                    }

                    .qfdrsd-check::after {
                        content: "";
                        display: block;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        border-radius: 20px;
                        background-color: #fff;
                        border: solid 1px #67768e;
                        cursor: pointer;
                        z-index: 0;
                    }

                    img {
                        display: none;
                    }

                    .qfdrsd-text {
                        font-size: 11px;
                        font-weight: 600;
                        color: #67768e;
                        position: relative;
                        z-index: 1;
                        white-space: nowrap;
                        padding: 0 3px;
                    }

                    input:checked~.qfdrsd-check::after {
                        background-color: rgba(0, 206, 181, 0.13);
                        border: none;
                    }

                    input:checked~.qfdrsd-text {
                        color: #00baa1;
                    }

                    input:checked~img {
                        display: inline-block;
                        margin-right: 4px;
                        width: 13px;
                    }
                }

                .qfdr-set-default:hover {
                    transition: 0.6s all;

                    .qfdrsd-check::after {
                        background-color: rgba(0, 206, 181, 0.13);
                        border: none;
                    }

                    .qfdrsd-text {
                        color: #00baa1;
                    }
                }
            }

            h3 {
                font-size: 12px;
                font-weight: 600;
                color: #12203c;
                margin: 0;
                margin-bottom: 3px;
                display: inline-flex;
                align-items: center;
            }

            p {
                font-size: 11px;
                font-weight: 500;
                margin: 0;
                color: #a4b9dd;
            }
        }

        li:hover {
            background-color: #f5f5f5;
        }

        .qfd-default {
            background-color: rgba(93, 95, 239, 0.06);
            border-left: solid 3px $primary-color;
        }
    }

    .qfd-expand {
        li {
            .qfd-left {
                p {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 230px;
                }
            }
        }
    }
}

.order-request-modal {

    .gsf-body {
        .generic-form-body {
            .gfb-inner {
                flex-wrap: nowrap;

                .generic-input-box {
                    margin: 0 20px 0 0;
                }
            }
        }
    }

    .create-order-table {
        position: relative;
        padding: 20px 15px 10px;

        .cot-manage {
            box-shadow: 2px 0 6px 0 hsla(0, 0%, 55%, .16);
            overflow-x: auto;

            table {
                thead {
                    tr {
                        height: 42px;
                        box-shadow: 2px 0 6px 0 hsla(0, 0%, 55%, .16);

                        th {
                            border: none;
                            height: 42px;
                            vertical-align: middle;
                            padding: 0 8px;

                            label {
                                font-size: 11px !important;
                                margin: 0;
                                color: #12203c;
                            }
                        }

                        .fix-action-btn {
                            width: 90px;
                            box-shadow: 10px 0 8px -4px hsla(0, 0%, 55%, .16);
                            background-color: #fff;
                            border: 0;
                            left: 0;
                            position: sticky;
                            // z-index: 1;
                            max-width: 90px;
                        }
                    }
                }

                tbody {
                    tr {
                        .tInputText {
                            border: 1px solid #faecb4;
                            background-color: #fffae7;
                            padding: 0;
                            border-left: none;
                            border-top: none;

                            .modal-search-btn-table {
                                position: absolute;
                                top: 7px;
                                right: 6px;
                                padding: 0 3px;
                                cursor: pointer;
                            }

                            .tit-inner {
                                display: flex;
                                align-items: center;
                            }
                        }

                        .tInputDisabled {
                            border: 1px solid #bfdfff;
                            background-color: #f4f4fd;
                            padding: 0;
                            border-left: none;

                            .modal-search-btn-table {
                                position: absolute;
                                top: 7px;
                                right: 6px;
                                padding: 0 3px;
                                cursor: pointer;
                                background-color: #f4f4fd;

                                svg {
                                    path {
                                        fill: #0000003d;
                                    }
                                }
                            }

                            .tit-inner {
                                display: flex;
                                align-items: center;
                            }
                        }

                        td {
                            input {
                                height: 30px;
                                color: #21314b;
                                font-size: 11px;
                                font-weight: 600;
                                word-break: normal;
                                max-width: 110px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                text-align: left;
                                padding-left: 8px;
                            }
                        }
                    }

                    td {
                        height: 35px;
                        position: relative;
                        border: 1px solid #dce6f0;
                        background-color: #ffffff;
                        min-width: 160px;
                        padding: 0 8px;

                        label {
                            font-size: 11px !important;
                            color: #12203c;
                            font-weight: 500;
                            margin: 0;
                        }

                        .pittlabel {
                            margin-right: 10px;
                            padding-left: 8px;
                            padding-right: 25px;
                        }

                        input {
                            width: auto;
                            border: none;
                            padding: 5px 10px;
                            background-color: transparent;
                            font-size: 12px;
                            font-weight: 500;
                            color: #12203c;
                        }

                        .topToolTip {
                            .topToolTipText {
                                left: auto;
                                right: 0px;
                            }
                        }
                    }

                    .tInputText {
                        border: 1px solid #faecb4;
                        background-color: #fffae7;
                        border-left: 0;
                    }

                    .fix-action-btn {
                        width: 90px;
                        box-shadow: 10px 0 8px -4px hsla(0, 0%, 55%, .16);
                        background-color: #fff;
                        border: 0;
                        padding: 0;
                        left: 0;
                        position: sticky;
                        // z-index: 1;
                        max-width: 90px;
                        min-width: 90px;

                        .table-item-list {
                            display: flex;
                            width: 100%;
                            align-items: center;
                            justify-content: space-around;
                            padding: 0;
                            margin: 0;
                            height: 35px;

                            .til-inner {
                                display: inline-block;
                                padding: 7px;
                                position: relative;
                                border-radius: 50%;
                                cursor: pointer;

                                .checkBoxLabel0 {
                                    width: 16px;
                                    height: 16px;
                                    padding-left: 16px;
                                }

                                .checkmark1 {
                                    left: 0px;
                                    top: 0px;
                                    height: 16px;
                                    width: 16px;
                                    background-color: #ffffff;
                                    cursor: pointer;
                                    border-radius: 4px;
                                    border: solid 2px #dce6f0;
                                }

                                .checkBoxLabel0 .checkmark1:after {
                                    width: 5px;
                                    height: 9px;
                                    border-width: 0 2px 2px 0;
                                    content: none;
                                }

                                .checkBoxLabel0 input:checked~.checkmark1 {
                                    background-color: $primary-color;
                                    border: none;
                                }

                                .checkBoxLabel0 input:checked~.checkmark1:after {
                                    width: 5px;
                                    height: 9px;
                                    border-width: 0 2px 2px 0;
                                    content: "";
                                }
                            }

                            .til-inner:hover>.generic-tooltip {
                                visibility: visible;
                                opacity: 1;
                            }

                            .til-delete-btn:hover {
                                background-color: #eb000826;
                                transition: 0.3s all;

                                svg path {
                                    fill: #eb0008;
                                }
                            }

                            .til-copy-btn:hover {
                                background-color: #fea70333;

                                svg {

                                    .tilcb-fill,
                                    g {
                                        fill: #fea703;
                                    }
                                }
                            }
                        }
                    }

                    .cot-read {
                        border: 1px solid #bfdfff;
                        background-color: #f2f5f8;
                    }

                    .cot-color{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        span{
                            font-size: 11px;
                            color: #12203c;
                            font-weight: 500;
                            margin-right: 5px;
                        }
                    }

                    .cot-type {
                        padding: 0px;
                        border: 1px solid #dce6f0;
                        background-color: #ffffff;

                        input {
                            padding: 5px 10px 4px 30px;
                        }

                        .modal-search-btn-table {
                            right: auto;
                            left: 7px;
                            background-color: #ffffff;
                        }

                        .modal-search-btn-table svg:hover path {
                            fill: $primary-color !important;
                        }
                    }

                    .cot-type:focus {
                        border: 1px solid $primary-color;
                    }

                    tr:first-child .tInputText {
                        border: 1px solid #faecb4;
                        background-color: #fffae7;
                        border-left: 0;
                    }
                }
            }
        }
    }
}

// Select Color Modal
.select-color-modal{
    height: 100%;
    .list-component-section {
        height: calc(100% - 62px);
        .lcs-inner{
            .list-component-normal-view{
                padding: 10px 15px;
                .lcv-table-head{
                    .lcvth-row{
                        display: flex;
                        box-shadow: 2px 0 6px 0 hsla(0,0%,55%,.16);
                        li{
                            width: 12.5%;
                            height: 40px;
                            padding: 5px 8px;
                            label{
                                margin: 0;
                                font-weight: 600;
                                color: #12203c;
                                width: auto;
                                font-size: 12px;
                            }
                        }
                        li:nth-last-child(1){
                            border-right: none;
                        }
                    }
                }
                .modal-data-list-render{
                    li{
                        padding: 0;
                        .mdlr-col {
                            width: 12.5%;
                            height: 42px;
                            padding: 5px 8px;
                            .mdlr-multiple-row{
                                color: #12203c;
                            }
                            .generic-input-box {
                                margin: 0;
                            }
                        }
                        .mdlr-col:nth-last-child(1){
                            border-right: none;
                        }
                    }
                }
            }
        }
    }
}

// Style New Vendor Generic Table
.vendor-gen-table {
    position: relative;
    display: block;
    box-shadow: 2px 0 6px 0 hsla(0, 0%, 55%, .16);
    margin-top: 10px;

    .manage-table {
        overflow-y: hidden;
        overflow-x: auto;

        .columnFilterGeneric {
            right: 0;
            border-top-right-radius: 6px;

            .glyphicon {
                top: 0px;
                right: 0px;
                padding: 15px 3px;
                background: $primary-color;
                z-index: 9;
            }

            .glyphicon-menu-left {
                padding: 15px 3px;
                background: $primary-color;
                right: 0px;
                top: 0px;
            }

            .columnSetting {
                right: 9px;
            }
        }

        .gen-main-table {
            display: table;
            white-space: nowrap;
            border-collapse: collapse;
            margin: 0;
            border-spacing: 0;

            thead {
                border: none;
                box-shadow: 2px 0 6px 0 hsla(0, 0%, 55%, .16);

                tr {
                    height: 35px;
                    background-color: #fff;

                    th {
                        height: 42px;
                        vertical-align: middle;
                        white-space: nowrap;
                        border: none;
                        cursor: pointer;
                        padding: 8px;

                        label {
                            margin: 0;
                            font-weight: 600;
                            color: #000000;
                            width: auto;
                            cursor: pointer !important;
                            font-size: 12px !important;

                            svg {
                                margin-left: 15px;
                                cursor: pointer;
                            }
                        }

                        svg {
                            margin-left: 15px;
                            cursor: pointer;
                        }
                    }

                    .filter-table-header {
                        height: auto;

                        .asnfbb-right {
                            display: flex;
                            align-items: center;
                            padding: 0 8px;
                            position: relative;

                            .ant-picker {
                                min-width: 200px;
                                height: 30px;

                                .ant-picker-range-separator {
                                    .ant-picker-separator {
                                        span {
                                            svg {
                                                margin: 0;
                                            }
                                        }
                                    }
                                }
                            }

                            input {
                                font-size: 12px;
                                font-weight: 500;
                            }

                            input::placeholder {
                                font-size: 12px;
                                font-weight: 500;
                            }

                            .asnfbbr-inner {
                                position: absolute;
                                background: #fff;
                                border-radius: 8px;
                                box-shadow: 0 4px 13px 4px hsla(0, 0%, 56%, 0.16);
                                min-width: 140px;
                                width: 100%;
                                z-index: 99;
                                display: block;
                                top: 35px;

                                .asnfbbr-all-vendor {
                                    display: block;
                                    padding: 0;
                                    max-height: 175px;
                                    overflow: auto;

                                    li {
                                        display: block;
                                        width: 100%;
                                        padding: 5px 10px;
                                        transition: 0.6s all;
                                        cursor: pointer;

                                        button {
                                            padding: 0;
                                            margin: 0;
                                            height: auto;
                                            background-color: transparent;
                                        }

                                        .asnfbbrav-code {
                                            color: #97abbf;
                                            display: block;
                                            padding: 0;
                                        }
                                    }

                                    li:hover {
                                        background-color: $dropdown-item-hover;

                                        button {
                                            font-weight: 600;
                                        }
                                    }

                                    span {
                                        display: block;
                                        padding: 5px 10px;
                                        font-size: 11px;
                                        font-weight: 500;
                                    }

                                    button {
                                        display: block;
                                        padding: 5px 20px;
                                        font-size: 12px;
                                        font-weight: 500;
                                        border: none;
                                    }
                                }
                            }
                        }
                    }

                    .th-icon-rotate {
                        svg {
                            transform: rotate(180deg);

                            g {
                                path {
                                    fill: #fae5c0;
                                }
                            }
                        }
                    }

                    th:last-child {
                        padding-right: 30px;
                    }

                    .fix-action-btn {
                        width: auto;
                        box-shadow: 10px 0 8px -4px hsla(0, 0%, 55%, .16);
                        background-color: #fff;
                        border: 0;
                        left: 0;
                        position: sticky;
                        border-right: none;
                        border-bottom: none;
                        z-index: 1;
                        max-width: 90px;
                        padding: 0;
                        min-width: 40px;

                        label {
                            width: auto;
                        }

                        .save-icon {
                            position: relative;

                            .generic-tooltip {
                                left: 72%;
                            }
                        }

                        .save-icon:hover {
                            .generic-tooltip {
                                visibility: visible;
                                opacity: 1;
                            }
                        }

                        .rab-refresh {
                            display: flex;
                            margin: 0;
                            padding: 0;
                            display: flex;
                            width: 100%;
                            align-items: center;
                            justify-content: center;

                            .rab-rinner {
                                display: inline-block;
                                padding: 7px;
                                position: relative;
                                border-radius: 50%;
                                cursor: pointer;

                                span {
                                    svg {
                                        margin: 0;
                                        width: 18px;
                                    }
                                }

                                label {
                                    font-size: 12px;
                                }

                                .checkBoxLabel0 {
                                    width: 16px;
                                    height: 16px;
                                    padding-left: 16px;

                                    .checkmark1 {
                                        left: 0;
                                        top: 0;
                                        height: 16px;
                                        width: 16px;
                                        background-color: #ffffff;
                                        cursor: pointer;
                                        border-radius: 4px;
                                        border: solid 2px #dce6f0;
                                    }

                                    .checkmark1:after {
                                        width: 5px;
                                        height: 9px;
                                        border-width: 0 2px 2px 0;
                                        content: none;
                                    }

                                    input:checked~.checkmark1 {
                                        background-color: $primary-color;
                                        border: none;
                                    }

                                    input:checked~.checkmark1:after {
                                        width: 5px;
                                        height: 9px;
                                        border-width: 0 2px 2px 0;
                                        content: "";
                                    }
                                }

                                .select-all-text {
                                    position: absolute;
                                    top: 3px;
                                    left: 30px;
                                    padding: 4px 14px;
                                    font-size: 12px;
                                    background: $primary-color;
                                    color: #fff;
                                    border-radius: 30px;
                                    z-index: 9;
                                }

                                .generic-tooltip {
                                    left: 140%;
                                    top: 2px;
                                }

                                .generic-tooltip:after {
                                    top: 25%;
                                    left: -9%;
                                    margin-left: -6px;
                                    border-width: 6px;
                                    border-color: transparent #000 transparent transparent;
                                }
                            }

                            .rab-rinner:hover>.generic-tooltip {
                                visibility: visible;
                                opacity: 1;
                            }

                            .til-inner {
                                display: inline-block;
                                padding: 7px;
                                position: relative;
                                border-radius: 50%;
                                cursor: pointer;
                                margin-left: 5px;

                                .generic-tooltip {
                                    top: 1px;
                                    left: 160%;
                                    z-index: 99;
                                }

                                .generic-tooltip:after {
                                    top: 31%;
                                    left: -9%;
                                    margin-left: -5px;
                                    border-width: 5px;
                                    border-style: solid;
                                    border-color: transparent #000 transparent transparent;
                                }
                            }

                            .til-inner:hover>.generic-tooltip {
                                visibility: visible;
                                opacity: 1;
                            }

                            .til-edit-btn:hover {
                                background: #3ee58521;

                                svg {
                                    path {
                                        fill: #3ee585;
                                    }
                                }
                            }
                        }
                    }

                    .manage-role-fix {
                        width: 70px;
                    }

                    .user-fix-action-btn {
                        width: 120px;
                        max-width: 130px;
                    }

                    .ufab-v2 {
                        width: 140px;
                        max-width: 140px;
                    }

                    .width62 {
                        width: 62px;
                    }

                    .width62 {
                        width: 62px;
                    }

                    .width62 {
                        width: 62px;
                    }

                    .width40 {
                        width: 40px;
                    }

                    .width62 {
                        width: 62px;
                    }
                }
            }

            tbody {
                tr {
                    height: 35px;
                    background-color: #fff;

                    td {
                        height: 35px;
                        white-space: nowrap;
                        vertical-align: middle;
                        border: 0.5px solid #e3e7f3;
                        position: relative;
                        padding: 8px;

                        .acp-running,
                        .acp-scheduled {
                            margin-left: 7px;
                            width: 14px;
                        }

                        .user-active {
                            background: #03b675;
                            margin-right: 10px;
                            color: #ffffff;
                            font-weight: 600;
                            border-radius: 4px;
                            font-size: 12px;
                            padding: 2px 8px;
                        }

                        .user-inactive {
                            background: #232349;
                            margin-right: 10px;
                            color: #ffffff;
                            font-weight: 600;
                            border-radius: 4px;
                            font-size: 12px;
                            padding: 2px 8px;
                        }

                        .user-logged {
                            color: #ffffff;
                            font-weight: 600;
                            border-radius: 5px;
                            font-size: 12px;
                            padding: 2px 7px;
                            background-color: #5f45fd;
                        }

                        .table-per-tooptip {
                            position: relative;
                        }

                        .table-per-tooptip:hover>.generic-tooltip {
                            opacity: 1;
                            visibility: visible;
                        }

                        label {
                            color: #000;
                            margin: 0;
                            font-weight: 500;
                            white-space: nowrap;
                            max-width: 240px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            vertical-align: middle;
                            font-size: 11px !important;
                            cursor: auto;

                            .genmt-rupee-sign {
                                font-size: 12px;
                                font-weight: 400;
                                font-family: system-ui;
                                color: #97abbfcf;
                                margin-right: 5px;
                            }
                        }

                        .assort-label {
                            max-width: 450px;
                        }

                        .gmtvn-text {
                            position: relative;

                            .gmt-vendor-name {
                                display: inline-block;
                                position: relative;
                                white-space: nowrap;
                                max-width: 215px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                font-size: 12px;
                                font-weight: 500;
                                color: #000;
                            }
                        }

                        .gmtvn-text:hover {
                            .generic-tooltip {
                                visibility: visible;
                                opacity: 1;
                            }
                        }

                        .api-name-col:hover {
                            cursor: pointer !important;
                            color: $primary-color;
                            transition: 0.6s all;
                        }

                        .aprv-amt {
                            color: #35b16b;
                            font-weight: 600;
                        }

                        .vgt-item-img {
                            font-size: 12px;
                            font-weight: 500;
                            display: inline-block;
                            margin-right: 10px;
                        }

                        .cusorPointer {
                            cursor: pointer;
                        }

                        .vgt-item-name {
                            margin-right: 14px;
                        }

                        .vgt-status {
                            font-weight: 600;
                            min-width: 90px;
                            position: relative;
                        }

                        .vgt-status:after {
                            content: "";
                            position: absolute;
                            top: 5px;
                            right: 10px;
                            width: 5px;
                            height: 5px;
                            border-radius: 50%;
                            background-color: #6fc192;
                        }

                        .vgt-status-fail:after {
                            background-color: #ff0505;
                        }

                        .displayPointer {
                            cursor: pointer !important;
                        }

                        .gvpt-tag-cn {
                            display: inline-block;
                            margin-left: 15px;
                            padding: 2px 6px;
                            border-radius: 5px;
                            font-size: 11px;
                            font-weight: 600;
                            background: #7a65ef38;
                            color: #7a65ef;
                        }

                        input[type="text"] {
                            border-radius: 6px;
                            border: solid 1px #dce6f0;
                            background-color: #ffffff;
                            font-size: 11px;
                            font-weight: 500;
                            color: #000;
                            padding: 3px 5px;
                            height: 28px;
                            width: 80px;
                        }

                        .vgt-clientname {
                            width: 130px !important;
                        }

                        .vgt-clientname:focus {
                            border: 1px solid $primary-color;
                            box-shadow: 0px 0px 0px 2px #DADAFF;
                        }

                        .td-already-user {
                            display: inline-block;
                            position: relative;
                            margin-left: 5px;

                            img {
                                filter: contrast(0.5);
                                width: 17px;
                            }
                        }

                        .vgt-display-name {
                            width: 180px !important;
                        }

                        .td-already-user:hover>.generic-tooltip {
                            visibility: visible;
                            opacity: 1;
                        }

                        select {
                            border-radius: 4px;
                            border: 1px solid #ccd8ed;
                            background-color: #fff;
                            width: 220px;
                            display: block;
                            height: 30px;
                            padding: 3px 25px 3px 5px;
                            font-size: 12px;
                            font-weight: 500;
                            color: #12203c;
                            background-repeat: no-repeat;
                            background-position: 96%;
                            background-image: url("../../../assets/icons/downArrowNew.svg");
                        }

                        .vgt-select {
                            width: 160px;
                            height: 28px;
                            border: 1px solid #dce6f0;
                            padding: 0px 25px 0px 5px;
                        }

                        .vgt-status2 {
                            display: inline-block;
                            border-radius: 4px;
                            border: solid 1px #dee;
                            color: #21314b;
                            background-color: #fff;
                            font-size: 12px;
                            font-weight: 600;
                            padding: 3px 10px;
                        }

                        .vgts-aprvl-pend {
                            color: #67768e;
                            border: solid 1px #67768e;
                        }

                        .vgts-otb-aprvd {
                            border: solid 1px #03b675;
                            background-color: #03b675;
                            color: #ffffff;
                        }

                        .vgts-onhold {
                            border: solid 1px #fe9d0b;
                            color: #fe9d0b;
                        }

                        table {
                            tbody {
                                tr {
                                    td:first-child {
                                        border-right: 0.5px solid #e3e7f3;
                                        border-bottom: 0.5px solid #e3e7f3;
                                    }

                                    td {
                                        table {
                                            tbody {
                                                tr {
                                                    td:first-child {
                                                        border-right: 0.5px solid #e3e7f3;
                                                        border-bottom: 0.5px solid #e3e7f3;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .poStatus {
                            cursor: pointer;
                            margin-left: 5px;
                            position: relative;

                            .poStatusToolTip {
                                right: -136px;
                            }
                        }

                        .asnHoldCheck {
                            display: inline;
                            position: relative;
                            cursor: pointer;
                            margin-left: 5px;

                            img {
                                height: 18px;
                            }

                            span.table-tooltip {
                                width: auto;
                                padding: 4px 5px;
                                background-color: #ffb103;
                                color: #ffffff;
                                top: -20px;
                                left: 200%;
                                border: none;
                            }

                            span.table-tooltip::after {
                                content: "";
                                position: absolute;
                                border: 5px solid #ffb103;
                                border-color: #ffb103 transparent transparent transparent;
                                left: 18%;
                            }
                        }

                        .asnHoldCheck:hover>span.table-tooltip {
                            visibility: visible;
                            opacity: 1;
                        }

                        label.clr-red {
                            color: #eb0008;
                        }

                        .table-td-text {
                            white-space: nowrap;
                            max-width: 240px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            vertical-align: middle;
                        }

                        .table-td-blue {
                            color: #3279dd;
                            font-weight: 700;
                        }

                        .table-td-error {
                            color: #c44569 !important;
                            cursor: pointer !important;
                        }

                        pre {
                            background: #fff;
                            border: none;
                            white-space: nowrap;
                            margin: 0;
                            max-width: 260px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            vertical-align: middle;
                            padding: 0;

                            code {
                                white-space: nowrap;
                                max-width: 200px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                vertical-align: middle;
                            }
                        }

                        .vgdt-toggle {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: auto;

                            .nph-switch-btn {
                                margin: 0px;

                                .tg-switch {
                                    width: 47px;
                                    height: 18px;
                                    cursor: pointer !important;

                                    .tg-slider {
                                        background-color: #efefef;
                                    }

                                    .tg-slider:before {
                                        height: 14px;
                                        width: 14px;
                                        background-color: #969fad;
                                    }

                                    input:checked+.tg-slider {
                                        background-color: #e3fbed;
                                    }

                                    input:checked+.tg-slider:before {
                                        transform: translateX(27px);
                                        background: #3ee585;
                                    }

                                    .nph-wbtext {
                                        position: absolute;
                                        top: 2px;
                                        left: 21px;
                                        margin: 0;
                                        font-weight: 600;
                                    }

                                    .nph-wbtext.nph-wbtext-left {
                                        left: 7px;
                                    }
                                }
                            }

                            .generic-disabled {
                                .tg-switch {
                                    cursor: not-allowed !important;

                                    .tg-slider {
                                        cursor: not-allowed !important;
                                    }

                                    .tg-slider::before {
                                        cursor: not-allowed !important;
                                    }
                                }
                            }

                            span {
                                font-size: 11px;
                                font-weight: 500;
                                color: #12203c;
                            }

                            .vgdtt-value {
                                cursor: pointer;
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                border-radius: 6px;
                                border: 1px solid #dce6f0;
                                padding: 5px;

                                i {
                                    margin-left: 5px;
                                }
                            }

                            .type-dropdown {
                                position: absolute;
                                top: 37px;
                                right: 0;
                                height: auto;
                                background-color: #fff;
                                border: none;
                                text-align: left;
                                box-shadow: 2px 0 6px 0 hsla(0, 0%, 55%, .16);
                                transition: all .6s;
                                z-index: 2;
                                min-width: 100px;

                                button {
                                    border-radius: 5px;
                                    background-color: #fff;
                                    font-size: 12px;
                                    padding: 5px 10px;
                                    height: 28px;
                                    font-weight: 500;
                                    position: relative;
                                    display: block;
                                    width: 100%;
                                    margin: 0;
                                    text-align: left;
                                }

                                button:hover {
                                    background-color: $dropdown-item-hover;
                                }
                            }
                        }

                        .rprtmt-arrow {
                            position: absolute;
                            top: 10px;
                            left: 10px;
                            margin-right: 10px;
                        }

                        .table-tooltip {
                            label {
                                word-break: break-all;
                            }
                        }

                        .req-sta-req-btn,
                        .req-sta-apv-btn,
                        .req-sta-rej-btn,
                        .req-sta-del-btn,
                        .req-sta-disp-btn {
                            background: #fff;
                            padding: 2px 5px;
                            border-radius: 4px;
                            font-size: 12px;
                            font-weight: 500;
                            width: 90px;
                        }

                        .req-sta-req-btn {
                            border: solid 1px #2681ff;
                            color: #2681ff;
                        }

                        .req-sta-apv-btn {
                            border: solid 1px #50d388;
                            color: #50d388;
                        }

                        .req-sta-rej-btn {
                            border: solid 1px #d50007;
                            color: #d50007;
                        }

                        .req-sta-del-btn {
                            border: solid 1px #b4c5d6;
                            color: #b4c5d6;
                        }

                        .req-sta-disp-btn {
                            border: solid 1px #be8300;
                            color: #be8300;
                        }

                        .track-order-btn {
                            color: #2681ff;
                            font-size: 12px;
                            font-weight: 500;
                            background: #fff;

                            img {
                                margin-left: -7px;
                            }
                        }

                        .prod-detail {
                            display: block;
                            font-size: 11px;
                            font-weight: 500;
                            color: #a4b9dd;
                        }

                        .edd-status-tag {
                            padding: 3px 5px;
                            border-radius: 4px;
                            background: #fe9d0b;
                            color: #fff;
                            min-width: 84px;
                            text-align: center;
                        }

                        .eddst-complete {
                            background: #00ceb5;
                        }

                        .eddst-fail {
                            background: $danger-color;
                        }

                        .vgt-applied-filter {
                            position: relative;
                            display: inline-block;
                            font-size: 11px;
                            font-weight: 500;
                            background: #ebebeb;
                            padding: 5px 10px;
                            border-radius: 20px;
                            margin-right: 10px;
                        }

                        .vgt-applied-filter:hover {
                            .filter-tooltip {
                                visibility: visible;
                                opacity: 1;
                            }
                        }

                        .eddst-link {
                            display: block;
                            position: relative;
                            font-size: 11px;
                            font-weight: 600;
                            color: #21314b;
                            margin-top: 3px;
                        }

                        .eddst-link:hover {
                            .filter-tooltip {
                                visibility: visible;
                                opacity: 1;
                            }
                        }
                    }

                    .po-num-col {
                        border-left: solid 2px #fff !important;

                        label {
                            cursor: pointer !important;
                            font-weight: 600;
                        }

                        .ponc-icon {
                            margin-left: 5px;
                            cursor: pointer;
                        }
                    }

                    .po-num-col:hover {
                        border-left: solid 2px #2969ff !important;

                        label {
                            color: #2969ff;
                        }

                        svg {
                            path {
                                fill: #2969ff;
                            }
                        }
                    }

                    .highlighted-field {
                        background-color: rgba(93, 95, 239, 0.15);
                    }

                    .mr-vap {
                        label {
                            padding: 3px 10px;
                            background: #bfdfff;
                            border-radius: 33px;
                            color: #004183;
                        }
                    }

                    .bg-green {
                        background-color: rgba(68, 215, 182, .12);
                    }

                    .bg-red {
                        background-color: rgba(224, 32, 32, .12);
                        border: 1px solid #e5e5e5a1;
                    }

                    td:first-child {
                        border-left: none;
                    }

                    td:nth-child(2) {
                        border-left: none;
                    }

                    .fix-action-btn {
                        width: auto;
                        box-shadow: 10px 0 8px -4px hsla(0, 0%, 55%, .16);
                        background-color: #fff;
                        border: 0;
                        padding: 0;
                        left: 0;
                        position: sticky;
                        z-index: 1;
                        max-width: 90px;

                        .table-item-list {
                            display: flex;
                            width: 100%;
                            align-items: center;
                            justify-content: center;
                            padding: 0;
                            margin: 0;
                            height: 35px;

                            .til-inner {
                                display: inline-block;
                                padding: 7px;
                                position: relative;
                                border-radius: 50%;
                                cursor: pointer;

                                .checkBoxLabel0 {
                                    width: 16px;
                                    height: 16px;
                                    padding-left: 16px;
                                }

                                .checkmark1 {
                                    left: 0px;
                                    top: 0px;
                                    height: 16px;
                                    width: 16px;
                                    background-color: #ffffff;
                                    cursor: pointer;
                                    border-radius: 4px;
                                    border: solid 2px #dce6f0;
                                }

                                .checkBoxLabel0 .checkmark1:after {
                                    width: 5px;
                                    height: 9px;
                                    border-width: 0 2px 2px 0;
                                    content: none;
                                }

                                .checkBoxLabel0 input:checked~.checkmark1 {
                                    background-color: $primary-color;
                                    border: none;
                                }

                                .checkBoxLabel0 input:checked~.checkmark1:after {
                                    width: 5px;
                                    height: 9px;
                                    border-width: 0 2px 2px 0;
                                    content: "";
                                }

                                .chat-notification {
                                    top: 2px;
                                    left: 14px;
                                }

                                .nph-switch-btn {
                                    margin: 0px;

                                    .tg-switch {
                                        width: 47px;
                                        height: 18px;

                                        .tg-slider {
                                            background-color: #efefef;
                                        }

                                        .tg-slider:before {
                                            height: 14px;
                                            width: 14px;
                                            background-color: #969fad;
                                        }

                                        input:checked+.tg-slider {
                                            background-color: #eae6fe;
                                        }

                                        input:checked+.tg-slider:before {
                                            transform: translateX(27px);
                                            background: $primary-color;
                                        }

                                        .nph-wbtext {
                                            position: absolute;
                                            top: 2px;
                                            left: 21px;
                                            margin: 0;
                                            font-weight: 600;
                                        }

                                        .nph-wbtext.nph-wbtext-left {
                                            left: 7px;
                                        }
                                    }
                                }

                                .gen-radio-btn {
                                    margin: 0;
                                    width: 15px;
                                    height: 15px;
                                    padding-left: 15px;
                                    position: relative;
                                    cursor: pointer !important;

                                    input[type="radio"] {
                                        display: none;
                                    }

                                    .checkmark {
                                        content: "";
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        height: 15px;
                                        width: 15px;
                                        border: 2px solid #D6DCE6;
                                        border-radius: 50%;
                                    }

                                    .checkmark:before {
                                        content: "";
                                        position: absolute;
                                        top: 3px;
                                        left: 3px;
                                        width: 5px;
                                        height: 5px;
                                        display: block;
                                        border-radius: 50%;
                                        background: #D6DCE6;
                                    }

                                    input:checked~.checkmark {
                                        background-color: #fff;
                                        border: 2px solid $primary-color;
                                    }

                                    input:checked~.checkmark:before {
                                        background: $primary-color;
                                    }
                                }
                            }

                            .til-inner:hover>.generic-tooltip {
                                visibility: visible;
                                opacity: 1;
                            }

                            .til-delete-btn:hover {
                                background-color: #eb000826;
                                transition: 0.3s all;

                                svg path {
                                    fill: #eb0008;
                                }
                            }

                            .til-already-user {

                                // padding-left: 4px;
                                img {
                                    filter: contrast(0.5);
                                }
                            }

                            .til-chat-icon {
                                width: 30px;
                            }

                            .til-chat-icon:hover {
                                background: #12203c14;

                                img {
                                    filter: brightness(0.2);

                                    path {
                                        fill: #000000;
                                    }
                                }
                            }

                            .til-eye-btn {
                                padding: 0;
                                padding-top: 4px;
                                padding-left: 3px;
                            }

                            // .til-add-btn{
                            //     svg{
                            //         background-color: #ffffff;
                            //         box-shadow: 0 3px 6px 0 #ebe7f5;
                            //         border-radius: 4px;
                            //     }
                            // }
                            .til-add-btn:hover {
                                background-color: #5D5FEF1c;
                                transition: 0.3s all;

                                svg {
                                    g {
                                        g:first-child {
                                            fill: $primary-color;
                                            stroke: $primary-color;
                                        }

                                        g {
                                            g {
                                                fill: #ffffff !important;
                                                stroke: #ffffff !important;
                                            }
                                        }
                                    }
                                }
                            }

                            .til-download-btn:hover {
                                background-color: #5D5FEF1c;
                                transition: 0.3s all;

                                svg {
                                    path {
                                        fill: $primary-color;
                                    }
                                }
                            }

                            .til-edit-btn:hover {
                                background: #3ee58521;

                                svg {
                                    path {
                                        fill: #3ee585;
                                    }
                                }
                            }

                            .til-resend-mail:hover {

                                // background: #3ee58521;
                                svg {
                                    path {
                                        fill: #dce6f0;
                                    }
                                }
                            }

                            .til-copy-btn:hover {
                                background-color: #fea70333;

                                svg {
                                    .tilcb-fill {
                                        fill: #fea703;
                                    }
                                }
                            }

                            .til-btnDisabled {
                                svg {
                                    .tilcb-fill {
                                        fill: #d8d3d3;
                                    }
                                }
                            }

                            .til-btnDisabled:hover {
                                background-color: #f9f9f9;
                            }
                        }

                        .til-for-lr {
                            justify-content: flex-start;

                            .til-inner {
                                padding: 4px;
                            }

                            .til-chat-icon {
                                width: 25px;
                            }
                        }
                    }

                    .manage-role-fix {
                        width: 70px;

                        .table-item-list {
                            .til-inner {
                                button {
                                    border-radius: 12px;
                                    box-shadow: 0 0 6px 0 rgba(77, 77, 77, 0.16);
                                    background-color: #ffffff;
                                    font-size: 12px;
                                    font-weight: 500;
                                    padding: 3px 8px;
                                }

                                button:hover {
                                    color: #ffffff;
                                    background-color: $primary-color;
                                }
                            }
                        }
                    }

                    .user-fix-action-btn {
                        width: 120px;
                        max-width: 130px;

                        .table-item-list {
                            width: 120px;
                        }

                        .addToggleSwitch.mainToggle {
                            position: relative;

                            .sliderToggle {
                                background-color: $primary-color;
                                border: none;
                            }

                            span:before {
                                background-color: #fff;
                                transform: translateX(21px) !important;
                            }

                            p.onActive {
                                position: absolute;
                                top: 0px;
                                left: 10px;
                            }
                        }

                        .mainToggle {
                            position: relative;

                            p.onInActive {
                                position: absolute;
                                top: 1px;
                                left: 20px;
                            }

                            .sliderToggle {
                                border: none;
                                background-color: #e3e3ef;
                            }
                        }

                        .switchToggle {
                            position: relative;
                            display: inline-block;
                            width: 42px;
                            height: 21px;
                        }

                        .sliderToggle:before {
                            position: absolute;
                            content: "";
                            height: 15px;
                            width: 15px;
                            left: 3px;
                            bottom: 3px;
                            background-color: #ffffff;
                            transition: .4s;
                        }

                        .table-item-list {
                            justify-content: flex-start;
                        }

                        .mainToggle {
                            padding-left: 10 !important;
                        }

                        p {
                            font-size: 12px;
                            font-weight: 600;
                            margin: 0;
                            margin-left: 10px;
                            color: #fff;
                            cursor: pointer;
                        }
                    }

                    .ufab-v2 {
                        width: 140px;
                        max-width: 140px;

                        .table-item-list {
                            width: 140px;
                            justify-content: flex-start;
                        }
                    }

                    td:last-child {
                        padding-right: 30px;
                    }

                    .width62 {
                        width: 62px;
                    }

                    .width40 {
                        width: 40px;
                    }

                    .width62 {
                        width: 62px;
                    }
                }

                tr:nth-last-child(1),
                tr:nth-last-child(2) {
                    td {
                        .vgdt-toggle {
                            .type-dropdown {
                                top: -50px;
                            }
                        }
                    }
                }

                tr:first-child {
                    td {
                        border-top: none;
                    }
                }

                .vgt-tr-bg {
                    background-color: #f7f5fb;
                }
            }
        }
    }
}

.new-gen-pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 2px 0 6px 0 rgba(139, 139, 139, 0.16);
    background-color: #ffffff;
    width: 100%;
    height: 55px;
    padding: 0 15px;
    margin-bottom: 20px;

    .ngp-left {
        display: block;
        width: 50%;

        .ngp-total-item {
            margin: 0 3px 0 15px;
        }

        .ngp-set-udf-details {
            display: inline-block;
            font-size: 12px;
            font-weight: 500;
            color: #049d97;
            background-color: #d5ffe7;
            padding: 3px 10px;
            border-radius: 20px;
            margin-left: 15px;
        }
    }

    .ngp-right {
        display: block;

        .nt-btn {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .pagination-inner {
                display: block;

                .pagination-item {
                    display: flex;
                    margin: 0;
                    padding: 0;
                    height: auto;
                    border: none;
                    border-radius: 0;

                    li {
                        display: inline-block;
                        margin-right: 8px;

                        button {
                            display: flex;
                            align-items: center;
                            padding: 5px;
                            width: 33px;
                            height: 40px;
                            border: none;
                            background: #fff;
                            font-weight: 600;
                            font-size: 12px;
                            transition: all .6s;

                            span {
                                letter-spacing: 3px;
                            }

                            .page-item-btn-inner {
                                display: inline-block;

                                svg {
                                    display: inline-block;
                                }
                            }
                        }

                        .pi-number-btn {
                            width: auto;
                            min-width: 40px;
                        }

                        button:hover {
                            svg {
                                path {
                                    fill: $primary-color;
                                }
                            }
                        }
                    }

                    li:nth-child(5) {
                        margin: 0;
                    }
                }
            }
        }
    }
}

.table-page-no {
    display: block;

    span {
        font-size: 12px;
        font-weight: 500;

        input::placeholder {
            font-size: 12px;
            font-weight: 600;
            color: #000000;
        }
    }

    .paginationBorder {
        border-radius: 5px;
        border: 1px solid #dce6f0;
        min-width: 45px;
        padding: 4px 0;
        height: 30px;
        text-align: center;
        font-size: 12px;
        font-weight: 600 !important;
        color: #000;
        margin-left: 10px;
        max-width: 55px;
    }

    select {
        border-radius: 5px;
        border: 1px solid #dce6f0;
        width: 65px;
        padding: 4px 18px 4px 6px;
        height: 30px;
        font-size: 12px;
        font-weight: 600;
        color: #000;
        margin-left: 10px;
        background-image: url('../../../assets/icons/downArrowNew.svg');
        background-position: 90%;
        background-size: 10px;
        background-repeat: no-repeat;
    }
}

// Style Filter
.show-applied-filter {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    margin-top: 10px;
    border-radius: 6px;

    button {
        padding: 3px 10px;
        font-size: 12px;
        font-weight: 500;
        margin-right: 15px;
        background-color: #ffffff;
        position: relative;
    }

    .saf-clear-all {
        font-weight: 600;
        padding: 0;
    }

    .saf-btn,
    .safv2-btn {
        border-radius: 15px;
        border: solid 2px #dce6f0;
        background-color: #ffffff;
        padding: 3px 10px;
        font-size: 12px;
        font-weight: 500;
        margin-right: 15px;
        display: flex;
        align-items: center;


        svg {
            margin-left: 10px;
        }

        .generic-tooltip {
            left: 10%;
            // max-width: 300px;
            white-space: nowrap;
        }
    }

    .safv2-btn {
        display: inline-flex;
        align-items: center;

        .safb-text {
            display: block;
            max-width: 250px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .saf-btn:hover>.generic-tooltip,
    .safv2-btn:hover>.generic-tooltip {
        visibility: visible;
        opacity: 1;
    }

    .saf-btn:hover,
    .safv2-btn:hover {
        border: solid 2px #21314b;
        transition: 0.3s all;
    }

    label {
        font-size: 12px;
        font-weight: 600;
        color: #97abbf;
        position: relative;
        margin-left: 20px;
        margin-right: 10px;
    }

    label::before {
        content: "";
        position: absolute;
        left: -17.5px;
        top: 0px;
        height: 20px;
        width: 1px;
        background-color: #b4c5d6;
    }
}

// Style Vendor Bill Discounting 
.bill-discount-request-early-pay {
    display: flex;
    align-items: center;
    padding: 15px 15px 5px 30px;

    .bdrep-first {
        height: 125px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 6px;
        background-color: $primary-color;
        margin-right: 10px;
        padding: 12px 26px;

        p {
            font-size: 12px;
            font-weight: 500;
            color: #fff;
            margin: 10px 0;
        }

        .bdrep-amount {
            padding: 3px 18px;
            border-radius: 13px;
            background-color: rgba(222, 230, 244, .51);
            color: #fff;
            display: inline-block;
            font-size: 13px;
            font-weight: 700;

            .bdrep-rupee-sign {
                margin-right: 5px;
            }
        }
    }

    .bdrep-second {
        width: calc(100% - 360px);
        height: 125px;
        padding: 15px;
        border-radius: 6px;
        box-shadow: 0 3px 15px 0 #d6d6d6;
        margin-left: 5px;
        background: #fff;

        .bdrep-top {
            display: block;
            margin-bottom: 7px;

            h3 {
                font-size: 16px;
                font-weight: 700;
                margin: 0;
                color: #21314b;
            }
        }

        .bdrep-bottom {
            display: flex;
            align-items: end;

            .bddsb-col {
                margin-right: 20px;
                position: relative;
                width: 20%;

                p {
                    font-size: 12px;
                    font-weight: 600;
                    color: #3a5074;
                    margin-bottom: 7px;
                }

                .bddsb-discount {
                    width: 80%;
                }

                input::placeholder {
                    color: #e7ecf2;
                }

                button {
                    font-size: 12px;
                    border-radius: 4px;
                    background-color: #fff;
                    padding: 5px 30px;
                    color: #7a65ef;
                    font-weight: 600;
                    height: 33px;
                    // border: solid 2px $primary-color;
                    // box-shadow: 2px 4px 11px 0 #ded8ff;
                }
            }

            .generic-input-box {
                margin: 0 15px 0 0;
            }
        }
    }
}

.bill-discount-vendor-req-summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    box-shadow: 0 3px 15px 0 #d6d6d6;
    padding: 15px 15px 5px 30px;
    margin-bottom: 15px;
    background-color: #fff;

    .bdvrs-left {
        .bdvr-top {
            display: block;
            margin-bottom: 15px;

            h3 {
                font-size: 16px;
                font-weight: 700;
                margin: 0;
                color: #21314b;
            }
        }

        .bdvrs-bottom {
            display: flex;
            align-items: flex-start;
            border-radius: 6px;

            .bdvrs-col {
                margin-right: 40px;
                position: relative;

                p {
                    font-size: 13px;
                    font-weight: 500;
                    color: #3a5074;
                    margin-bottom: 0;
                }

                .bdvrs-sm-tip {
                    font-size: 11px;
                    font-weight: 500;
                    color: #90a5c7;
                    margin-bottom: 5px;
                    display: inline-block;
                    margin-top: 3px;
                }

                h3 {
                    font-size: 12px;
                    font-weight: 700;
                    margin: 0;
                    color: #3a5074;
                }

                .bdvrs-rate-dis {
                    font-size: 12px;
                    font-weight: 700;
                    color: #3a5074;
                    display: inline-block;
                    margin-top: 6px;

                    .genmt-rupee-sign {
                        color: #90a5c7;
                        margin-right: 5px;
                        font-family: system-ui;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .bdvrs-right {
        button {
            font-size: 12px;
            font-weight: 600;
            padding: 5px 20px;
            background-color: $primary-color;
            color: #fff;
            height: 32px;
            border-radius: 5px;
            box-shadow: 2px 4px 11px 0 #ded8ff;
            border: solid 1px $primary-color;
            margin-right: 20px;
        }
    }
}