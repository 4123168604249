@import '../variables.scss';

// Session Expired
.session-expired{
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: relative;
    .modal-header-section{
        align-items: flex-start;
    }
    .generic-input-box{
        padding: 0 15px;
        .gib-inner{
            padding: 0;
            input{
                border-radius: 4px;
            }
        }
    }
    .generic-form-footer{
        width: 100%;
        position: absolute;
        bottom: 0;
    }
}