@import './variables.scss';

.table-component-div1 {
    padding: 0 0 0 45px;
    min-height: calc(100vh - 45px);
    background-color: #f3f4f9;
}

.table-component-new-style {
    min-height: 100vh;
    background: #f3f4f9;

    .main-bread-crumps {
        border: none;
        padding: 0 15px 0 20px;
        user-select: none;
        position: sticky;
        top: 45px;
        z-index: 8;

        .mbc-right {
            height: 45px;

            .mbcr-radio {
                display: flex;
                align-items: center;
                padding-left: 10px;

                .mbcrr-p {
                    font-size: 12px;
                    font-weight: 500;
                    color: #67768E;
                    margin: 0 7px 0 0;
                }

                .ant-radio-group {
                    width: fit-content;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    background-color: #ebebf3;
                    border-radius: 4px;
                    padding: 0 3px;

                    .ant-radio-button-wrapper {
                        height: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: transparent;
                        border-radius: 4px;
                        font-size: 12px;
                        font-weight: 600;
                        color: #21314b;
                        border: none;
                        padding: 0 10px;
                    }

                    .ant-radio-button-wrapper-checked {
                        background-color: #fff;
                        border: none;
                    }

                    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
                        display: none;
                    }

                    .ant-radio-button-wrapper:not(:first-child)::before {
                        display: none;
                    }
                }
            }

            .mbcr-view-product-list{
                display: flex;
                align-items: center;
                .generic-reject-btn{
                    border: 1px solid #E66767 !important;
                }
                .generic-reject-btn:hover {
                    background: #d25353 !important;
                }
                .gab-icon{
                    background-color: #F4F4F4;
                    border: none !important;
                    border-radius: 0 !important;
                    margin-left: 0 !important;
                    svg{
                        g{
                            path{
                                stroke: #23235F;
                            }
                        }
                    }
                }
                .gab-icon:hover, .gab-icon.gen-action-btn-focus{
                    svg{
                        g{
                            path{
                                stroke: #fff;
                                fill: $primary-color ;
                            }
                        }
                    }
                }
                .dprtr-wishlist {
                    height: 30px;
                    border: 1px solid #DAE4EE !important;
                    display: flex;
                    align-items: center;
                    padding: 0 5px;
                    color: #0f172a;
                    font-size: 12px;
                    font-weight: 600;
                    background-color: transparent !important;


                    svg {
                        margin-right: 5px;
                    }

                    span {
                        color: #fff;
                        font-size: 10px;
                        font-weight: 600;
                        padding: 0 6px;
                        border-radius: 2px;
                        background-color: #fd3757;
                        margin-left: 5px;
                    }
                }
            }

            .header-last-update {
                font-size: 12px;
                font-weight: 500;
                color: #646b84;
            }

            .mbcr-upload-excel {
                display: flex;
                align-items: center;
                height: 30px;
                border: 1px solid #dae4ee;
                position: relative;
                border-radius: 4px;
                padding-left: 10px;
                margin-left: 10px;

                .generic-input-box {
                    margin: 0;

                    label {
                        margin: 0;
                        font-size: 12px;
                        font-weight: 600;
                        color: #000;
                        cursor: pointer !important;
                    }

                    .gib-upload {
                        width: 100%;
                        height: 30px;
                        position: absolute;
                        top: 0px;
                        left: -5px;
                        opacity: 0;
                        input {
                            display: block !important;
                        }
                    }
                }

                button {
                    height: auto;
                    border: none;
                    border-left: 1px solid #c7d3df;
                    border-radius: 0;
                }

                .download-btn {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    top: 33px;
                    left: 0;
                    border-radius: 4px;
                    height: 38px;
                    border: none;
                    color: #21314b;
                    font-weight: 500;
                    margin: 0;
                    text-align: left;
                    white-space: nowrap;
                    box-shadow: 0 1px 11px -3px rgba(1, 1, 1, .15);
                    z-index: 9;

                    button {
                        height: 38px;
                        margin: 0;
                        border: none;

                        svg {
                            margin-right: 7px;
                        }
                    }

                    button:hover {
                        background-color: #f5f5f5;
                    }
                }
            }

            .opacity04 {
                .generic-input-box {
                    label {
                        cursor: not-allowed !important;
                    }

                    .gib-upload {
                        cursor: not-allowed;

                        input {
                            cursor: not-allowed;
                        }
                    }
                }
            }

            .drop-icon {
                margin-left: 15px;
                padding: 6px;
            }

            .drop-icon:before {
                content: "";
                position: absolute;
                width: 1px;
                height: 15px;
                top: 7px;
                background-color: #B7C6DE;
                right: 36px;
            }

            .page-dropdown {
                position: absolute;
                top: 38px;
                right: 0;
                height: auto;
                background-color: #fff;
                padding: 0;
                border: none;
                text-align: left;
                box-shadow: 2px 0 6px 0 rgba(139, 139, 139, 0.16);
                transition: all .6s;
                z-index: 5;
                min-width: 152px;

                .sel-page {
                    border: none;
                    width: 100%;
                    margin: 0;
                    text-align: left;
                    font-weight: 500;
                    font-size: 13px;
                    color: #23235F;
                    padding: 5px 10px;
                }

                .sel-page:hover {
                    background-color: #e5edf8;
                }
            }

            .di-page-dropdown {
                top: 34px;
                left: 0;
            }

            .gen-action-btn,
            button {
                .generic-tooltip {
                    transform: translate(16%);
                    left: auto;
                    right: 50%;
                    top: 37px;
                }

                .generic-tooltip:after {
                    left: auto;
                    right: 12%;
                    top: -10px;
                    margin: 0;
                    border-color: transparent transparent #000;
                }
            }

            .gen-action-btn:hover,
            button:hover {
                .generic-tooltip {
                    visibility: visible;
                    opacity: 1;
                }
            }

            .sohrud-refresh-btn {
                padding: 5px 10px;
                border-radius: 5px;
                height: 30px;
            }

            .sohrup-reject {
                background-color: #e66667;
                color: #fff;

                .sohrud-arrow {
                    background-color: #e66667;

                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }

                .sohrud-delete {
                    width: 100%;
                    color: #e66667;
                    height: 32px;
                }
            }

            .sohr-upload-download {
                .sohrud-arrow {
                    padding: 10px;
                }
            }

            .disableLeftPortion::before {
                content: "";
                width: calc(100% - 30px);
                position: absolute;
                top: 0;
                left: 0;
                background-color: #fff;
                opacity: 0.4;
                height: 100%;
            }

            // .ngr-genmbq {
            //     display: flex;
            //     align-items: center;
            //     height: 32px;
            //     position: relative;
            //     border-radius: 4px;
            //     padding: 0 10px;
            //     padding-right: 0;
            //     background-color: $primary-color;
            //     border: solid 1px $primary-color;
            //     color: #fff;
            //     margin-left: 10px;
            //     cursor: pointer;

            //     label {
            //         margin: 0;
            //         font-size: 12px;
            //         font-weight: 500;
            //         cursor: pointer !important;
            //     }

            //     .ngrg-arrow {
            //         height: 100%;
            //         padding: 2px 10px;
            //         border: none;
            //         border-left: 1px solid #c7d3df;
            //         border-radius: 0;
            //         margin-left: 10px;
            //         background: $primary-color;
            //     }

            //     .ngrg-replace {
            //         position: absolute;
            //         top: 40px;
            //         right: 0;
            //         padding: 3px 8px;
            //         box-shadow: 0 -3px 4px 0 #0000000d;
            //         border-radius: 4px;
            //         height: 34px;
            //         border: none;
            //         color: #21314b;
            //         font-weight: 500;
            //         margin: 0;
            //         text-align: left;
            //         white-space: nowrap;
            //         display: flex;
            //         align-items: center;
            //         z-index: 9;
            //         box-shadow: 0px 4px 14px -3px rgba(141, 141, 141, 0.25);
            //     }

            //     .ngrg-replace:hover {
            //         background-color: #f5f5f5;
            //     }
            // }

            .gvpd-three-dot-btn {
                position: relative;
                display: inline-block;
                cursor: pointer;
                margin-left: 10px;

                .threeDot-svg {
                    width: 30px;
                    height: 30px;
                    border: 1px solid #dae4ee;
                    padding: 0;
                    display: inline-block;
                    border-radius: 4px;
                    transform: rotate(90deg);
                }

                .threeDot-svg:hover {
                    background-color: $primary-color !important;
                    border: 1px solid $primary-color !important;
                    transition: all .3s !important;

                    circle {
                        fill: #fff !important;
                    }
                }

                .gvpdtd-btn {
                    position: relative;

                    .generic-tooltip {
                        transform: translate(16%);
                        left: auto;
                        right: 50%;
                        top: 37px;
                    }

                    .generic-tooltip:after {
                        left: auto;
                        right: 12%;
                        top: -10px;
                        margin: 0;
                        border-color: transparent transparent #000;
                    }
                }

                .gvpdtd-btn:hover {
                    .generic-tooltip {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                .gvpdtd-menu {
                    position: absolute;
                    top: 40px;
                    display: block;
                    right: 0;
                    background: #fff;
                    z-index: 5;
                    box-shadow: 0 2px 6px 0 rgba(75, 75, 75, 0.16);
                    border-radius: 6px;
                    // width: 170px;

                    .gvpdtdm-inner {
                        padding: 0;
                        margin: 0;

                        li {
                            button {
                                display: block;
                                margin: 0;
                                border: none;
                                padding: 6px 20px;
                                width: 100%;
                                text-align: left;
                                color: #12203c;
                                border-radius: 0;
                                white-space: nowrap;
                            }

                            button:hover {
                                background-color: #e5edf8;
                                // font-weight: 600;
                            }

                            .gvpdtdm-cancel {
                                color: #eb0008;
                            }

                            .btnDisabled {
                                background-color: #ffffff !important;
                                border: none !important;
                            }

                            .gvpdtdm-update-status {
                                padding-right: 0;
                            }
                        }
                    }
                }
            }

            .gvpd-download-drop {
                display: inline-block;
                position: relative;

                .pi-history-download {
                    white-space: nowrap;
                    display: block;
                    position: absolute;
                    top: 36px;
                    right: 0;
                    width: 220px;
                    padding: 0;
                    background: #ffffff;
                    margin: 0;
                    z-index: 9;
                    box-shadow: 0 4px 12px 0 rgba(75, 75, 75, 0.16);

                    li {
                        display: block;
                        height: 100%;

                        button {
                            display: flex;
                            padding: 5px 10px;
                            margin: 0;
                            border: none;
                            width: 100%;
                            height: auto;
                            text-align: left;
                            white-space: nowrap;
                            border-radius: 0;
                            transition: all .6s;
                            align-items: center;

                            img {
                                width: 17px;
                                // margin-right: 12px;
                            }

                            span {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 50%;
                                height: 32px;
                                width: 32px;
                                margin-right: 10px;
                            }

                            // .pipdf-text {
                            //     border-radius: 0;
                            //     max-width: 100px;
                            //     text-align: left;
                            //     width: auto;
                            //     text-overflow: ellipsis;
                            //     overflow: hidden;
                            //     margin: 0;
                            // }

                            .pi-pdf-img {
                                width: 20px;
                                margin-left: 8px;
                            }

                            div {
                                display: inline-block;

                                .multipleDownloadSelect {
                                    border: none;
                                    padding: 0;
                                    -webkit-appearance: none;
                                    background: #fff !important;
                                }
                            }

                            a {
                                font-size: 10px;
                                font-weight: 600;
                                background: transparent;
                                color: #21314b;
                                padding: 3px 8px;
                                border-radius: 50px;
                                text-align: right;
                                position: absolute;
                                top: 11px;
                                right: 10px;
                                text-decoration: none;
                            }

                            a:hover {
                                color: #21314b;
                            }
                        }

                        .export-excel {
                            color: #a4b9dd;

                            span {
                                margin-right: 10px;
                            }
                        }

                        .export-excel:hover {
                            color: #12203c;

                            span {
                                background-color: #049d4b14;

                                svg {
                                    path {
                                        fill: #049d4b;
                                    }

                                    text {
                                        fill: #049d4b;
                                    }
                                }
                            }
                        }

                        .pi-pdf-download {
                            // .pipdf-barcode {
                            //     padding: 10px 0px;
                            // }

                            .btnDisabled {
                                color: #a4b9dd !important;
                            }

                            .dropdown-menu-city1 {
                                left: -241px;
                                top: -11px;
                                max-width: 200px;

                                .printer-loader {
                                    padding-top: 70px;
                                }

                                .dropdown-menu-city-item {

                                    li {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        height: 30px;

                                        p {
                                            color: #21314b;
                                            font-size: 12px;
                                            font-weight: 500;
                                            margin: 0;
                                        }
                                    }
                                }
                            }
                        }

                        .pi-pdf-download:hover {
                            background: #ebf2fa;
                            transition: 0.6s all;

                            .pi-pdf-svg {
                                background-color: #eb000817;

                                svg {
                                    path {
                                        fill: #eb0008;
                                    }

                                    text {
                                        fill: #eb0008;
                                    }
                                }
                            }

                            // .pipdf-barcode {
                            //     background-color: #3279dd1f;

                            //     svg {
                            //         path {
                            //             fill: #3279dd;
                            //         }
                            //     }
                            // }

                            // .pipdf-invoice {
                            //     background-color: #e2c80017;

                            //     svg {
                            //         path {
                            //             fill: #e2c800;
                            //         }
                            //     }
                            // }

                            // .pipdf-packing {
                            //     background-color: #00ced617;

                            //     svg {
                            //         path {
                            //             fill: #00ced6;
                            //         }
                            //     }
                            // }

                            a {
                                color: #fff;
                            }

                            // .pipd-pipdf {
                            //     background-color: #eb0008;
                            // }

                            // .pipd-barcode {
                            //     background-color: #3279dd;
                            // }

                            // .pipd-invoice {
                            //     background-color: #e2c800;
                            // }

                            // .pipd-packing {
                            //     background-color: #00ced6;
                            // }
                        }
                    }
                }

                .pihd-v2 {
                    width: 240px;

                    // li button .pipdf-text {
                    //     max-width: 120px;
                    // }
                }
            }
        }
    }

    .subscription-tab {
        background-color: #fff;

        .subscription-tab-list {
            border-top: 1px solid #dce6f0;

            .nav-item {
                .st-btn {
                    color: #67768E;
                    font-size: 14px;
                    font-weight: 500;
                    padding: 15px;
                }
            }

            .active {
                .st-btn {
                    color: $primary-color;
                }

                .st-btn:before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    width: 85%;
                    height: 3px;
                    background-color: $primary-color;
                    transform: translate(-50%);
                }

                .st-btn:after {
                    background-color: $primary-color;
                }
            }
        }
    }

    .st-new-tab {
        padding: 10px 25px 10px 10px;
        border-bottom: 1px solid #dee2e6;

        .subscription-tab-list {
            display: flex;
            align-items: center;
            padding: 0 !important;

            li {
                a {
                    position: relative;
                }

                a::before {
                    bottom: 2px !important;
                }
            }
        }
    }

    .custom-search-modal {
        .subscription-tab {
            .subscription-tab-list {
                border-top: none !important;
                padding: 0 12px;
                position: relative;

                .nav-item,
                li {
                    a {
                        color: #21314B;
                    }
                }

                .active {
                    .st-btn {
                        color: #21314B;
                    }

                    .st-btn::before {
                        background-color: #111D31;
                        width: 50%;
                    }

                    .st-btn:after {
                        background-color: #111D31;
                    }
                }

                .stl-clear-all {
                    position: absolute;
                    right: 0;
                    top: 1px;
                }
            }
        }
    }

    .otbrf-head {
        border-top: none;
    }

    .nph-history-search {
        margin-top: 5px;

        .nph-pi-po-quantity {
            .nppq-inner {
                display: flex;
                gap: 1rem;

                .nppq-history {
                    display: flex;
                    flex-direction: column;
                    margin-right: 10px;

                    label {
                        font-weight: 600;
                        font-size: 12px;
                        color: #67768E;
                        margin: 0;
                        white-space: nowrap;
                    }

                    .gd-no {
                        font-size: 9px;
                        height: 10px;
                    }

                    span {
                        display: flex;
                        align-items: center;
                        font-weight: 500;
                        font-size: 12px;
                        color: #2A2A64;
                    }

                    .nppqh-info {
                        margin-left: 5px;
                        position: relative;
                        padding: 8px;
                        aspect-ratio: 1;
                        border-radius: 100px;

                        .nppqhi-information {
                            max-height: 300px;
                            max-width: 700px;
                            min-width: 500px;
                            overflow: auto;
                            position: absolute;
                            top: -10px;
                            left: 25px;
                            border-radius: 6px;
                            background-color: #ffffff;
                            visibility: hidden;
                            opacity: 0;
                            transition: 0.6s all;
                            z-index: 3;
                            box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.16);

                            table {
                                width: 100%;
                                margin: 0px;

                                thead {
                                    th {
                                        padding: 8px;
                                        vertical-align: middle;
                                        border-bottom: 2px solid #97abbf40;
                                        position: sticky;
                                        top: 0;
                                        z-index: 1;
                                        background-color: #fff;

                                        label {
                                            margin: 0;
                                        }
                                    }

                                    th:first-child {
                                        padding-left: 10px;
                                    }
                                }

                                tbody {
                                    tr {
                                        td {
                                            border: none;
                                            padding: 8px;

                                            label {
                                                margin: 0;
                                                color: #21314b;
                                                font-size: 11px !important;
                                                font-weight: 500;

                                                img {
                                                    width: 8px;
                                                    margin-right: 6px;
                                                }
                                            }

                                            .nppqhii-status {
                                                font-weight: 600;
                                                position: relative;
                                                padding-left: 10px;
                                            }

                                            .nppqhii-status:before {
                                                content: "";
                                                position: absolute;
                                                top: 5px;
                                                left: -5px;
                                                width: 5px;
                                                height: 5px;
                                                border-radius: 50%;
                                                // background-color: #c1d8f0;
                                                background-color: #67768E;
                                            }

                                            .nppqhiis-pen:before {
                                                background-color: #ffb103;
                                            }

                                            .nppqhiis-drft:before {
                                                background-color: #c1d8f0;
                                            }

                                            .nppqhiis-can:before {
                                                background-color: #ff0505;
                                            }

                                            .nppqhiis-apv:before {
                                                background-color: #6fc192;
                                            }
                                        }

                                        td:first-child {
                                            padding-left: 15px;
                                        }
                                    }
                                }
                            }

                            .list-component-section {
                                .lcs-inner {
                                    .list-component-normal-view {
                                        .lcv-table-head {
                                            .lcvth-row {
                                                display: flex;
                                                align-items: center;
                                                border-bottom: 1px solid #dee;

                                                li {
                                                    padding: 5px 10px;
                                                }
                                            }
                                        }

                                        .modal-data-list-render {
                                            li {
                                                padding: 5px 10px;

                                                .mdlr-col {
                                                    position: relative;

                                                    label {
                                                        color: #0F172A;
                                                        padding-left: 10px;
                                                    }

                                                    .nppqhii-status::before {
                                                        content: "";
                                                        position: absolute;
                                                        top: 5px;
                                                        left: 0;
                                                        width: 5px;
                                                        height: 5px;
                                                        border-radius: 50%;
                                                        background-color: #c1d8f0;
                                                    }

                                                    .nppqhiis-drft::before {
                                                        background-color: #c1d8f0;
                                                    }

                                                    .nppqhiis-can::before {
                                                        background-color: #ff0505;
                                                    }

                                                    .nppqhiis-apv::before {
                                                        background-color: #6fc192;
                                                    }

                                                    .nppqhiis-pen:before {
                                                        background-color: #ffb103;
                                                    }

                                                    span {
                                                        font-size: 11px;
                                                        padding-left: 10px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .nppqh-info:hover{
                        background-color: $primary-light;
                    }

                    .nppqh-info:hover>.nppqhi-information {
                        opacity: 1;
                        visibility: visible;
                        transition: 0.6s all;
                    }

                    .goods-intransit-content {
                        width: 100%;
                        display: flex;
                        align-items: center;

                        label {
                            margin: 0;
                        }

                        .gic-left {
                            margin-right: 25px;
                        }
                    }
                }
            }
        }
    }

    .tableBody-border {
        display: flex;
        flex-direction: column;
        background: #F3F4F9;
    }

    .imh-update {
        background: #f3f4f9;
        padding: 0 15px 0 30px;
        margin-top: 10px;
    }

    .select-tab-head {
        background: #FFFFFF;
        box-shadow: 0px 8px 14px -17px #8b93bb;
        margin: 0;
        z-index: 4;
        padding: 0 30px;
        position: sticky;
        top: 90px;

        .mep-heading {
            min-height: 40.5px;
            display: flex;
            align-items: center;
            position: relative;
            padding-left: 0;
            user-select: none;

            span {
                color: #23235F;
                font-size: 13px;
                margin-right: 15px;
                cursor: pointer;
                font-weight: 500;
                position: relative;
                text-transform: capitalize;

                .button {
                    background-color: #fff;
                    color: #23235f;
                    font-size: 13px;
                    cursor: pointer;
                    font-weight: 500;
                }

                .order-count {
                    padding-right: 0;
                    background: rgba(112, 116, 148, 0.1);
                    border-radius: 4px;
                    font-weight: 600;
                    font-size: 13px;
                    color: #707494;
                    padding: 0px 9px;
                    margin-left: 10px;
                    margin-right: 0;
                }

                .order-count-sel {
                    padding-right: 0;
                    background: $primary-color;
                    border-radius: 4px;
                    font-weight: 600;
                    font-size: 13px;
                    color: #fff;
                    padding: 0px 9px;
                    margin-left: 10px;
                    margin-right: 0;
                }
            }

            .sel-drop-item,
            .select-item {
                padding: 10px 0 9px;
            }

            .select-item {
                color: $primary-color;

                .seldi-text {
                    margin: 0;
                }

                .order-count {
                    padding-right: 0;
                    background: $primary-color;
                    color: #fff;
                    border-radius: 4px;
                    font-weight: 600;
                    font-size: 13px;
                    padding: 0px 9px;
                    margin-left: 10px;
                    margin-right: 0;
                }

                .quick-three-dot {
                    padding: 2px 7px;
                    margin-right: 0;
                    position: relative;

                    .filter-menu {
                        position: absolute;
                        background-color: #fff;
                        top: 22px;
                        left: 0px;
                        background-color: #fff;
                        padding: 0;
                        border: none;
                        text-align: left;
                        position: absolute;
                        box-shadow: 0 4px 14px 1px #c9bfff;
                        transition: all .6s;
                        z-index: 2;
                        border-radius: 3px;
                        min-width: 150px;

                        .filter-item {
                            font-size: 13px;
                            color: #21314b;
                            display: block;
                            text-align: left;
                            width: 100%;
                            padding: 3px 10px;
                            cursor: pointer;
                            font-weight: 500;
                            border-left: 2px solid #5D5FEF00;
                            white-space: nowrap;
                            position: relative;
                            cursor: pointer;
                        }

                        .delete-tab {
                            color: $danger-color;
                        }

                        .delete-tab:hover {
                            background-color: #fdf2f7;
                        }

                        .filter-item:hover {
                            background-color: #d8daf599;
                        }
                    }
                }
            }

            .sel-drop-item:hover{
                color: $fontBlack ;
            }

            // .select-item:after {
            //     content: "";
            //     position: absolute;
            //     bottom: 0;
            //     color: $primary-color;
            //     right: 0;
            //     width: 100%;
            //     top: 38px;
            //     height: 2px;
            //     background: $primary-color;
            // }

            .all-tabs-area .sel-drop-item:hover::after{
                content: "";
                position: absolute;
                bottom: 0;
                color: $primary-color;
                right: 0;
                width: 100%;
                top: 38px;
                height: 2px;
                background: #CECFD3;
            }

            .sel-drop-item {
                // color: #23235F;
                color: #74767A;

                .order-count {
                    padding-right: 0;
                    background: rgba(112, 116, 148, 0.1);
                    border-radius: 4px;
                    font-weight: 600;
                    font-size: 13px;
                    color: #707494;
                    padding: 0px 9px;
                    margin-left: 10px;
                    margin-right: 0;
                }

                .seldi-text {
                    margin: 0;
                }

                .order-count-sel {
                    padding-right: 0;
                    background: $primary-color;
                    border-radius: 4px;
                    font-weight: 600;
                    font-size: 13px;
                    color: #fff;
                    padding: 0px 9px;
                    margin-left: 10px;
                    margin-right: 0;
                }

                .quick-three-dot {
                    padding: 2px 7px;
                    margin-right: 0;
                    position: relative;
                    opacity: 0;
                    visibility: hidden;

                    .filter-menu {
                        position: absolute;
                        background-color: #fff;
                        top: 22px;
                        left: 0px;
                        background-color: #fff;
                        padding: 0;
                        border: none;
                        text-align: left;
                        position: absolute;
                        box-shadow: 0px 4px 14px -3px rgba(141, 141, 141, 0.25);
                        transition: all .6s;
                        z-index: 2;
                        border-radius: 3px;
                        min-width: 150px;

                        .filter-item {
                            font-size: 13px;
                            color: #21314b;
                            display: block;
                            text-align: left;
                            width: 100%;
                            padding: 3px 10px;
                            cursor: pointer;
                            font-weight: 500;
                            border-left: 2px solid #5D5FEF00;
                            white-space: nowrap;
                            position: relative;
                            cursor: pointer;
                        }

                        .delete-tab {
                            color: $danger-color;
                        }

                        .delete-tab:hover {
                            background-color: #fdf2f7;
                        }

                        .filter-item:hover {
                            background-color: #d8daf599;
                        }
                    }
                }

                .more-qf {
                    margin: 0 0 0 5px;
                }

                .moreqf-menu {
                    height: auto;
                    max-height: 200px;
                    overflow-y: auto;
                    overflow-x: hidden;
                    position: absolute;
                    background-color: #fff;
                    top: 28px;
                    left: 0px;
                    background-color: #fff;
                    border: none;
                    text-align: left;
                    position: absolute;
                    box-shadow: 0px 2.71px 18.99px rgba(160, 160, 160, 0.26);
                    transition: all .6s;
                    z-index: 10;
                    border-radius: 3px;
                    min-width: 200px;

                    .inp-btn {
                        position: sticky;
                        top: 0;
                        z-index: 1;
                        background-color: #fff;
                        padding: 10px;

                        input {
                            border: 1px solid #dce6f0;
                            background: #fff;
                            padding: 4px 30px;
                            height: 32px;
                            font-size: 12px;
                            color: #000;
                            font-weight: 600;
                            border-radius: 3px;
                            width: 100%;
                        }

                        input:focus {
                            border-radius: 3px;
                            border: 1px solid $primary-color;
                            box-shadow: 0 0 0 2px #dadaff;
                        }

                        input::placeholder {
                            color: #67768e;
                        }

                        .search-btn {
                            position: absolute;
                            top: 18px;
                            left: 15px;
                        }
                    }

                    .more-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        padding: 5px 10px;
                        border-left: 2px solid transparent;

                        .qf-items {
                            font-weight: 500;
                            font-size: 13px;
                            color: #23235F;
                            white-space: nowrap;
                            margin: 0;
                            width: 100%;
                            cursor: pointer !important;

                            span {
                                font-weight: 600;
                                font-size: 10px;
                                line-height: 15px;
                                color: #FFFFFF;
                                background: $primary-color;
                                border-radius: 11px;
                                padding: 2px 8px;
                                margin: 0 0 0 5px;
                            }
                        }

                        .qf-more-icon {
                            display: flex;
                            align-items: center;
                            position: relative;

                            .pin-icon {
                                margin-right: 5px;
                                cursor: pointer;
                            }

                            .qf-three {
                                margin: 0;
                                cursor: pointer;
                                padding: 3px;
                            }

                            .qf-three:hover {
                                background: #F1F1F4;
                                border-radius: 3px;
                            }

                            .filter-menu {
                                position: absolute;
                                background-color: #fff;
                                top: 2px;
                                left: 56px;
                                background-color: #fff;
                                padding: 0;
                                border: none;
                                text-align: left;
                                position: absolute;
                                box-shadow: 0px 4px 14px -3px rgba(141, 141, 141, 0.25);
                                transition: all .6s;
                                z-index: 2;
                                border-radius: 3px;
                                min-width: 150px;

                                .filter-item {
                                    font-size: 13px;
                                    color: #21314b;
                                    display: block;
                                    text-align: left;
                                    width: 100%;
                                    padding: 3px 10px;
                                    cursor: pointer;
                                    font-weight: 500;
                                    border-left: 2px solid #5D5FEF00;
                                    white-space: nowrap;
                                    position: relative;
                                    cursor: pointer;
                                }

                                .delete-tab {
                                    color: $danger-color;
                                }

                                .delete-tab:hover {
                                    background-color: #fdf2f7;
                                }

                                .filter-item:hover {
                                    background-color: #d8daf599;
                                }
                            }
                        }
                    }

                    .more-item:hover {
                        color: #21314b;
                        font-weight: 600;
                        background-color: $dropdown-item-hover;
                        border-left: 2px solid $primary-color;
                    }
                }
            }

            // .sel-drop-item:hover{
            //     .quick-three-dot{
            //         opacity: 1;
            //         visibility: visible;
            //     }
            // }
            .sel-drop-item:after {
                content: "";
                position: absolute;
                bottom: 0;
                color: $primary-color;
                right: 0;
                width: 100%;
                top: 28px;
                height: 2px;
                // background:  #CED5E1;
            }

            .all-tabs-area:before {
                content: "|";
                position: absolute;
                top: 0px;
                left: auto;
                right: 6px;
                font-size: 13px;
                color: #b7c6de;
            }

            .tab-qf-hover:after {
                content: "";
                position: absolute;
                bottom: 0;
                right: 0;
                width: 100%;
                top: 28px;
                height: 2px;
                color: #CED5E1;
                background-color: #ced5e1;
                opacity: 0;
                visibility: hidden;
            }

            .custom-field {
                position: relative;
                display: flex;
                padding: 7px 0;

                .qf-item-bar {
                    margin: 0;

                    .sel-drop-item {
                        display: flex;
                        align-items: center;
                        border-radius: 4px;
                        padding: 2px 0px 2px 5px;
                        border: solid 1px #DDE6EF;
                        margin-right: 10px;

                        .seldi-cross {
                            display: none;
                        }
                    }

                    .select-item {
                        display: flex;
                        align-items: center;
                        background-color: $primary-color;
                        border-radius: 4px;
                        padding: 2px 5px;
                        border: 1px solid $primary-color;
                        margin-right: 10px;

                        .seldi-text {
                            color: #ffffff;
                        }

                        .seldi-cross {
                            margin: 0 3px;
                        }

                        .quick-three-dot {
                            display: inline-block;

                            svg {
                                circle {
                                    fill: #ffffff;
                                }
                            }
                        }
                    }

                    .select-item:after {
                        content: none;
                    }
                }

                .all-filter-btn {
                    display: flex;
                    align-items: center;
                    border: solid 1px #dde6ef;
                    border-radius: 3px;
                    padding: 2px 5px;
                    background-color: #ffffff;
                }

                .all-filter-btn:hover {
                    background-color: #F5F5F5;
                }

                .qf-item-bar:hover {
                    .sel-drop-item {
                        border: solid 1px #F5F5F5;
                        background-color: #f5f5f5;

                        .quick-three-dot {
                            // display: inline-block;
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }

    .summary-count-tab {
        display: flex;
        align-items: center;

        .summary-data {
            p {
                font-weight: 600;
                font-size: 11px;
                color: #67768E;
                margin: 0;
            }

            label {
                font-weight: 600;
                font-size: 16px;
                color: #2A2A64;
                margin: 0;
            }
        }

        .summ-rup-icon {
            padding: 0 50px;
            position: relative;

            .toggle-icon {
                cursor: pointer;
                padding-left: 10px;
            }
        }

        .summ-rup-icon:before {
            content: "|";
            position: absolute;
            top: 10px;
            left: 22px;
            color: #b7c6de;
            font-size: 17px;
            font-weight: 300;
        }

        .summ-rup-icon:after {
            content: "|";
            position: absolute;
            top: 10px;
            right: 22px;
            color: #b7c6de;
            font-size: 17px;
            font-weight: 300;
        }
    }

    .invoice-manage-head {
        height: auto;
        border: 0;
        padding: 0;
        padding: 10px 15px;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 2px 0 6px 0 hsla(0, 0%, 55%, 0.16);

        .imh-left {
            max-width: calc(100% - 470px);

            .summary-data {
                padding-right: 10px;

                p {
                    font-weight: 600;
                    font-size: 11px;
                    color: #67768E;
                    margin: 0;
                }

                span {
                    font-weight: 600;
                    font-size: 16px;
                    color: #2A2A64;
                }
            }

            .imhl-btn-back {
                margin-right: 10px;

                button {
                    padding: 5px 10px;
                    color: #21314b;
                    font-weight: 500;
                    border-radius: 3px;

                    svg {
                        margin-right: 5px;
                    }

                    margin: 0;
                }
            }

            .audit-filter-field {
                position: relative;

                .dropdown-addfilter {
                    display: flex;
                    align-items: center;

                    .sdfl-input-col {
                        width: 300px;
                        position: relative;

                        .gsi-v2 {
                            .gsis-clear {
                                padding-right: 10px;
                            }
                        }

                        .dropdown-menu-city1.gen-width-auto {
                            top: 24px !important;
                            min-width: 300px;
                        }
                    }
                }
            }

            .imhl-btn-back:hover {
                button {
                    border: 1px solid #111D31;
                }
            }

            .imhl-search {
                margin-right: 10px;

                input {
                    width: 230px;
                    border-radius: 3px;
                }

                svg {
                    position: absolute;
                    top: 7px;
                    left: 7px;
                }

                .td-search {
                    position: absolute;
                    right: 20px;
                }
            }

            .quick-filter {
                button {
                    border-radius: 3px;

                    .qf-img {
                        margin: 0;
                    }
                }

                .qf-btn {
                    margin: 0;

                    .qf-img {
                        margin: 0;
                    }
                }
            }

            .drop-toggle-btn {
                margin-right: 10px;

                button {
                    border: 1px solid #ccd8ed;
                    color: #000;
                    white-space: nowrap;
                    border-radius: 3px;
                }

                .dtb-dropdown {

                    .dtb-show,
                    .dtb-hide {
                        border: none;
                        white-space: nowrap;
                        width: 100%;
                        padding: 5px 10px;
                    }
                }
            }

            .summary-count-tab {
                display: flex;
                align-items: center;

                .summary-data {
                    p {
                        font-weight: 600;
                        font-size: 11px;
                        color: #67768E;
                        margin: 0;
                    }

                    label {
                        font-weight: 600;
                        font-size: 16px;
                        color: #2A2A64;
                        margin: 0;
                    }
                }

                .summ-rup-icon {
                    padding: 0 50px;
                    position: relative;

                    .toggle-icon {
                        cursor: pointer;
                        padding-left: 10px;
                    }
                }

                .summ-rup-icon:before {
                    content: "|";
                    position: absolute;
                    top: 10px;
                    left: 22px;
                    color: #b7c6de;
                    font-size: 17px;
                    font-weight: 300;
                }

                .summ-rup-icon:after {
                    content: "|";
                    position: absolute;
                    top: 10px;
                    right: 22px;
                    color: #b7c6de;
                    font-size: 17px;
                    font-weight: 300;
                }
            }
        }

        .imh-right {
            justify-content: space-between;

            .sort-genTooltip {
                .generic-tooltip {
                    transform: translate(16%);
                    left: auto;
                    right: 50%;
                }

                .generic-tooltip:after {
                    left: auto;
                    right: 15%;
                }
            }

            .gvpd-sync {
                .sync-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 5px 7px;
                    color: #21314b;
                    font-weight: 600;

                    svg {
                        margin-right: 5px;
                    }
                }
            }

            .applied-filters {
                display: flex;
                align-items: center;
                padding-inline: .75rem;
                margin-left: 0;
                border-radius: 5px;
                height: 30px;
            }

            .applied-filter-opened,
            .applied-filters:hover {
                background: #f5f5f5;
                border: 1px solid #f5f5f5;
                color: #67768e;
            }
        }

        .new-gen-pagination {
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            box-shadow: none;
            padding: 0;
            margin: 0;
            position: relative;
            background-color: #fff;

            .ngp-left {
                width: auto;
                position: relative;
                display: block;

                .barcode-search {
                    position: relative;
                    // margin-right: 10px;

                    .generic-input-box {
                        margin: 0;

                        .gib-text {
                            height: 30px;
                            border: 1px solid #ccd8ed;

                            // input {
                            //     padding-left: 25px;
                            // }
                        }
                    }

                    .barcode-icon {
                        position: absolute;
                        left: 6px;
                        top: 9px;
                    }
                }

                .imhl-search {
                    position: relative;

                    input {
                        background: #fff;
                        padding: 8px 30px;
                        height: 30px;
                        font-size: 12px;
                        color: #000;
                        font-weight: 600;
                        border-radius: 3px;
                        width: 110px;
                        border: 1px solid #ccd8ed;
                        transition: 0.5s;
                        border-radius: 5px;
                        outline: none;
                    }

                    input:focus {
                        width: 250px;
                        transition: 0.5s;
                        border: 1px solid $primary-color;
                        box-shadow: 0px 0px 0px 2px #DADAFF;
                    }

                    input::placeholder {
                        color: #8496A9;
                    }

                    .search-image {
                        position: absolute;
                        top: 8px;
                        left: 7px;
                        width: 15px;
                    }

                    svg {
                        position: absolute;
                        top: 7px;
                        left: 7px;
                    }

                    .td-search {
                        position: absolute;
                        top: 6px;
                        right: 2px;
                        padding: 10px;
                        cursor: pointer;
                        opacity: 0;
                        visibility: hidden;

                        svg {
                            position: absolute;
                            top: 2px;
                            left: 8px;
                        }
                    }

                    .closeSearch {
                        position: absolute;
                        right: 46px;
                        top: 0px;
                        cursor: pointer;
                    }
                }

                .imhl-search:hover {
                    .td-search {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                .drop-toggle-btn {
                    position: relative;
                    margin-left: 10px;

                    .dtb-show {
                        padding: 5px 7px;
                        white-space: nowrap;
                        color: #8496A9;
                        border-radius: 5px;
                        border: 1px solid #ccd8ed;
                        background-color: #fff;
                        margin-left: 0px;
                        height: 30px;
                        font-weight: 500;
                        position: relative;
                        font-size: 12px;
                        display: flex;
                        align-items: center;

                        .total-count {
                            padding-right: 5px;

                            .to-num {
                                color: #111D31;
                                font-size: 13px;
                                font-weight: 600;
                            }

                            .to-num:hover {
                                .generic-tooltip {
                                    opacity: 1;
                                    visibility: visible;
                                    left: 38%;
                                    color: #fff;
                                }
                            }

                            .to-slas {
                                color: #111D31;
                                font-size: 12px;
                                font-weight: 700;
                                padding: 0 3px;
                            }
                        }

                        span {
                            color: #111d31;
                            font-weight: 600;
                        }

                        svg {
                            margin-left: 5px;
                        }
                    }

                    .dtb-show:hover {
                        border: 1px solid $primary-color;
                        box-shadow: 0px 0px 0px 2px #DADAFF;
                    }

                    .page-dropdown {
                        position: absolute;
                        top: 38px;
                        right: 0;
                        height: auto;
                        background-color: #fff;
                        border: none;
                        text-align: left;
                        box-shadow: 2px 0 6px 0 rgba(139, 139, 139, 0.16);
                        transition: all .6s;
                        z-index: 2;

                        .sel-page {
                            border: none;
                            width: 100%;
                            margin: 0;
                            text-align: left;
                            font-size: 12px;
                            padding: 5px 15px;
                            font-weight: 500;
                        }

                        .sel-page:hover {
                            background-color: $dropdown-item-hover;
                        }
                    }
                }
            }

            .ngp-right {
                display: block;
                margin-left: 10px;

                .nt-btn {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    .pagination-inner {
                        display: block;

                        .pagination-item {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;

                            li {
                                margin: 0;

                                button {
                                    margin-left: 10px;

                                    .page-item-btn-inner {
                                        letter-spacing: 3px;
                                    }
                                }

                                .falsenext-btn,
                                .falselast-btn,
                                .falseprev-btn,
                                .falsefirst-btn {
                                    width: 30px;
                                    height: 30px;
                                    border: 1px solid #dae4ee;
                                    padding: 0;
                                    display: inline-block;
                                    border-radius: 4px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }

                                .falsenext-btn:hover,
                                .falselast-btn:hover,
                                .falseprev-btn:hover,
                                .falsefirst-btn:hover {
                                    color: #fff !important;
                                    background-color: $primary-color !important;
                                    border: 1px solid $primary-color !important;
                                    transition: all .3s !important;

                                    svg {
                                        path {
                                            fill: #fff;
                                        }
                                    }
                                }

                                .pi-number-btn,
                                .pinum-input {
                                    min-width: 70px;
                                    height: 30px;
                                    border: 1px solid #dae4ee;
                                    padding: 0 3px 0 5px;
                                    display: inline-block;
                                    border-radius: 4px;

                                    span {
                                        font-weight: 600;
                                        font-size: 12px;
                                        letter-spacing: 0;

                                        label {
                                            margin: 0;
                                            padding: 0 3px;
                                            cursor: pointer;
                                        }
                                    }
                                }

                                .pi-number-btn:hover {
                                    background-color: #f5f5f5;
                                }

                                .pinum-input {
                                    width: 70px;
                                    margin-left: 10px;
                                    font-size: 13px;
                                }

                                .pinum-input:focus {
                                    border: 1px solid $primary-color;
                                    box-shadow: 0px 0px 0px 2px #DADAFF;
                                }
                            }
                            li:first-child {
                                button{
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }
            }

            .quick-filter {
                button {
                    border-radius: 3px;

                    .qf-img {
                        margin: 0;
                    }
                }

                .qf-btn {
                    margin: 0;

                    .qf-img {
                        margin: 0;
                    }
                }
            }
        }

        // .imh-ret-sup-radio {
        //     // position: fixed;
        //     // right: 25px;
        //     // top: 96px;
        //     // z-index: 7;

        //     .ant-radio-group {
        //         width: fit-content;
        //         height: 30px;
        //         display: flex;
        //         align-items: center;
        //         background-color: #ebebf3;
        //         border-radius: 5px;
        //         padding: 0 3px;

        //         .ant-radio-button-wrapper {
        //             height: 24px;
        //             display: flex;
        //             align-items: center;
        //             justify-content: center;
        //             background-color: transparent;
        //             border-radius: 3px;
        //             font-size: 12px;
        //             font-weight: 600;
        //             color: #21314b;
        //             border: none;
        //             padding: 0 10px;
        //         }

        //         .ant-radio-button-wrapper-checked {
        //             background-color: #fff;
        //             border: none;
        //         }

        //         .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
        //             display: none;
        //         }

        //         .ant-radio-button-wrapper:not(:first-child)::before {
        //             display: none;
        //         }
        //     }
        // }

    }

    .invoice-manage-table {
        margin: 0 10px 0 0;
        box-shadow: none;
        padding: 10px 5px 0 30px;
        background: #f3f4f9;
        position: relative;
        display: block;

        .imt-manage-table {
            min-height: 360px;
            border: 1px solid #dae4ee;
            border-radius: 6px;
            background-color: #fff;
            overflow-y: hidden;
            overflow-x: auto;

            .columnFilterGeneric {
                z-index: 3;
                top: 10px;

                .glyphicon {
                    padding: 10px 3px;
                    background-color: #67768E;
                    top: 0;
                    cursor: pointer;
                }

                .columnSetting {
                    right: 14px;
                    height: 32px;
                }

                .custom-header-modal {
                    top: 32px;
                }
            }

            .imt-main-table {
                width: 100%;
                max-width: 100%;
                display: table;
                white-space: nowrap;
                border-collapse: collapse;
                margin: 0;
                border-spacing: 0;
                overflow-x: auto;

                thead {
                    .fix-action-btn {
                        background-color: #f8f8f8;
                        border-right: 1px solid #dae4ee;
                        border-bottom: 1px solid #dae4ee;
                        height: 30px;
                        box-shadow: none;
                        left: -1px;
                        z-index: 2;
                        max-width: 115px;

                        .clearAll {
                            color: #67768E;
                            font-weight: 500;
                            font-size: 13px;
                        }

                        .save-icon {
                            position: relative;
                            cursor: pointer;
                            padding-left: 10px;

                            .generic-tooltip {
                                left: 50px;
                                top: -3px;
                            }

                            .generic-tooltip:after {
                                top: 25%;
                                left: -9%;
                                margin-left: -6px;
                                border-width: 6px;
                                border-color: transparent #000 transparent transparent;
                            }
                        }

                        .save-icon:hover {
                            .generic-tooltip {
                                opacity: 1;
                                visibility: visible;
                            }
                        }

                        .rab-refresh {
                            padding-right: 5px;

                            .rab-rinner {
                                padding: 7px;

                                .select-all-text {
                                    top: 2px;
                                }

                                label {
                                    span:hover {
                                        svg {
                                            fill: #67768E;
                                        }

                                        .generic-tooltip {
                                            visibility: visible;
                                            opacity: 1;
                                        }
                                    }
                                }
                            }

                            .rab-rinner:hover {
                                background-color: #5D5FEF1c;
                                transition: all .3s;
                            }

                            .table-update-time {
                                font-weight: 500;
                                font-size: 12px;
                                color: #67768E;
                                position: relative;
                                cursor: default;
                                border-bottom: 1px dashed #C7D3DF;
                                ;

                                .generic-tooltip {
                                    background: #202937;
                                    border-radius: 2px;
                                    opacity: 0;
                                    visibility: hidden;
                                    left: 74px;
                                    top: -5px;
                                    right: auto;
                                    text-align: left;

                                    label {
                                        font-weight: 500;
                                        font-size: 11px;
                                        line-height: 13px;
                                        color: #FFFFFF;
                                        margin: 0;
                                    }

                                    p {
                                        font-weight: 500;
                                        font-size: 11px;
                                        margin: 0;
                                        color: #909295;
                                    }
                                }

                                .generic-tooltip:after {
                                    content: none;
                                }
                            }

                            .table-update-time:hover {
                                .generic-tooltip {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                    }

                    .refIndex:hover {
                        z-index: 3 !important;
                    }

                    th {
                        padding: 0 4px;
                        border-left: 1px solid #dae4ee;
                        border-bottom: 1px solid #dae4ee;
                        background-color: #f8f8f8;
                        height: 30px;
                        text-align: left;

                        .td-header {
                            min-width: 155px;
                            padding: 0 8px;
                            height: auto;
                            background-color: #f8f8f8;
                            display: flex;
                            justify-content: space-between;
                            border: 1px solid transparent;

                            label {
                                color: #67768E;
                                font-weight: 500;
                                display: flex;
                                align-items: center;

                                img {
                                    margin: 0 0 0 3px;
                                    visibility: hidden;
                                    opacity: 0;
                                    filter: none;
                                }
                            }

                            img {
                                opacity: 0;
                                visibility: hidden;
                                filter: none;
                            }
                        }

                        .td-header-sort {
                            padding-top: 2px;
                            padding-bottom: 2px;
                            border-radius: 5px;
                            border: solid 1px #dae4ee;
                            background-color: #fff;
                            cursor: pointer;

                            label {
                                img {
                                    visibility: visible;
                                    opacity: 1;
                                }
                            }

                            img {
                                visibility: visible;
                                opacity: 1;
                            }
                        }
                    }

                    .sticky-col {
                        padding: 0 8px;
                        // border-right: 1px solid #dae4ee;
                        border-left: 1px solid #dae4ee;
                        border-bottom: 1px solid #dae4ee;
                        background-color: #f8f8f8;
                        height: 30px;
                        z-index: 2;

                        .td-header {
                            min-width: 140px;
                            padding: 0 8px;
                            height: auto;
                            background-color: #f8f8f8;
                            display: flex;
                            justify-content: space-between;
                            border: 1px solid transparent;
                            cursor: pointer;

                            label {
                                display: flex;
                                align-items: center;
                                color: #67768e;
                                font-weight: 500;

                                img {
                                    margin: 0;
                                    visibility: hidden;
                                    opacity: 0;
                                }
                            }

                            img {
                                opacity: 0;
                                visibility: hidden;
                                filter: none;
                            }
                        }
                    }

                    th:hover,
                    .sticky-col:hover {
                        .td-header {
                            padding-top: 2px;
                            padding-bottom: 2px;
                            border-radius: 5px;
                            border: solid 1px #dae4ee;
                            background-color: #fff;
                            cursor: pointer;

                            label {
                                img {
                                    visibility: visible;
                                    opacity: 1;
                                }
                            }

                            img {
                                visibility: visible;
                                opacity: 1;
                            }
                        }
                    }

                    .tc-border-inline {
                        border-inline: solid 1px #ffd693;
                        background-color: #ffd693;
                        .td-header {
                            background-color: #ffd693;
                        }
                    }

                    .filter-table-header {
                        background-color: #ffffff;
                        padding: 0;
                        margin: 0;

                        div {
                            display: block;
                            height: auto;
                        }

                        .asnfbb-right {
                            display: block;
                            width: 100%;
                            position: relative;

                            .generic-smart-input {
                                height: 30px;
                            }

                            input {
                                border: none;
                                height: 30px;
                                color: #2A2A64 !important;
                                font-weight: 600 !important;
                            }

                            .dropdown-menu-city1.gen-width-auto {
                                top: 20px;
                                display: block;
                                height: 280px !important;
                                width: auto;
                            }

                            .gsis-clear {
                                position: relative;
                                right: 21px;
                                cursor: pointer;
                            }

                            .gsis-clear-sel {
                                right: 15px;
                            }

                            .generic-smart-input {
                                width: 100%;
                                height: 30px;
                                border-radius: 0;
                                display: flex;
                                border: none;

                                input {
                                    padding: 0;
                                    height: 28px;
                                    color: #2A2A64 !important;
                                    font-weight: 600 !important;
                                }

                                .gsi-selected {
                                    background: #B6BACA;
                                    margin-left: 5px;
                                }

                                .gsis-clear {
                                    cursor: pointer;
                                    position: relative;
                                    right: 5px;
                                    cursor: pointer;
                                }

                                .gsis-clear-sel {
                                    right: 15px;
                                }

                                .gsi-search {
                                    svg {
                                        fill: #67768E;
                                        cursor: pointer;
                                    }
                                }
                            }

                            .generic-smart-input:focus {
                                border: solid 1px $primary-color;
                                box-shadow: 0px 0px 0px 2px #DADAFF;
                            }

                            .ant-picker-range {
                                border-radius: 0;
                                height: 30px !important;
                                border: none !important;

                                .ant-picker-suffix {
                                    .anticon {
                                        svg {
                                            path {
                                                fill: #67768E;
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                }
                            }

                            .ant-picker-focused {
                                border: solid 1px $primary-color;
                                box-shadow: 0px 0px 0px 2px #DADAFF;
                            }

                            input[type=date] {
                                width: 100%;
                                height: 30px;
                                border-radius: 3px;
                                border: none;
                                background-color: #ffffff;
                                padding: 0px 10px;
                                font-size: 12px;
                                font-weight: 500;
                            }

                            input[type=text] {
                                width: 100%;
                                height: 30px;
                                border-radius: 3px;
                                border: none !important;
                                border-radius: 0 !important;
                                background-color: #ffffff;
                                padding: 0px 30px 0px 10px;
                                font-size: 12px;
                                background: transparent;
                                font-weight: 500;
                            }

                            input[type=text]:focus {
                                border: solid 1px $primary-color !important;
                                box-shadow: 0px 0px 0px 2px #DADAFF;
                            }

                            input::placeholder {
                                color: #8496a9;
                            }

                            .asnfbbr-input-arrow {
                                position: absolute;
                                right: 6px;
                                top: 12px;
                                cursor: pointer;
                            }

                            .asnfbbr-input-arrow:hover {
                                background: #ebedf0;
                            }

                            input[type=text][disabled] {
                                text-indent: 0;
                            }

                            input[type=number] {
                                width: 100%;
                                height: 30px;
                                border: solid 1px #DAE4EE;
                                background-color: #ffffff;
                                padding: 0px 10px;
                                font-size: 12px;
                                background: transparent;
                                -webkit-appearance: none;
                                font-weight: 500;
                            }

                            select {
                                width: 100%;
                                height: 30px;
                                margin-right: 0;
                                border-radius: 3px;
                                border: solid 1px #DAE4EE;
                                background-color: #ffffff;
                                padding: 5px 10px;
                                font-size: 12px;
                                background: transparent;
                                font-weight: 500;
                            }

                            .asnfbb-from {
                                border-radius: 0 !important;
                                border: none !important;
                                border-right: 1px solid #dae4ee !important;
                                padding: 0px 6px !important;
                                height: 30px !important;
                            }

                            .asnfbb-to {
                                border: none !important;
                                padding: 0px 6px !important;
                                height: 30px !important;
                            }

                            .gsis-clear-from {
                                position: absolute;
                                left: 84px;
                                top: 10px;
                                cursor: pointer;
                            }

                            .gsis-clear-to {
                                position: absolute;
                                right: 2px;
                                top: 10px;
                                cursor: pointer;
                            }

                            .asnfbb-from:focus,
                            .asnfbb-to:focus {
                                border: solid 1px $primary-color !important;
                                box-shadow: 0px 0px 0px 2px #DADAFF;
                            }

                            // .asnfbbr-poquantity{
                            //     margin-top: 10px;
                            // }
                            .asnfbbr-inner {
                                position: absolute;
                                background: #fff;
                                border-radius: 8px;
                                box-shadow: 0 4px 13px 4px hsla(0, 0%, 56%, 0.16);
                                min-width: 140px;
                                width: 100%;
                                padding: 10px 0px;
                                z-index: 99;
                                display: block;
                                top: 35px;

                                .asnfbbr-all-vendor {
                                    display: block;
                                    padding: 0;
                                    max-height: 175px;
                                    overflow: auto;

                                    li {
                                        display: block;
                                        width: 100%;
                                        padding: 5px 10px;
                                        transition: 0.6s all;
                                        cursor: pointer;

                                        button {
                                            padding: 0;
                                            margin: 0;
                                            height: auto;
                                            background-color: transparent;
                                        }

                                        .asnfbbrav-code {
                                            color: #97abbf;
                                            display: block;
                                            padding: 0;
                                        }
                                    }

                                    li:hover {
                                        background-color: $dropdown-item-hover;

                                        button {
                                            font-weight: 600;
                                        }
                                    }

                                    span {
                                        display: block;
                                        padding: 5px 10px;
                                        font-size: 11px;
                                        font-weight: 500;
                                    }

                                    button {
                                        display: block;
                                        padding: 5px 20px;
                                        font-size: 12px;
                                        font-weight: 500;
                                        border: none;
                                    }
                                }
                            }

                            #genericSearchModalDropdown {
                                top: 10px;
                                display: block;
                                height: 236px;
                            }

                            .dropdown-menu-city1 {
                                .dropdown-menu-city-item {
                                    .gwa-advance-filter {
                                        width: 100%;
                                        display: block;
                                        height: auto;

                                        .gwaaf-btn {
                                            margin-left: 0;
                                        }
                                    }

                                    .dmci-checked {
                                        display: block;
                                        width: 100%;
                                        height: auto;
                                    }

                                    .deselect-all {
                                        border: none;
                                        margin: 0;
                                        border-top: 1px solid #dee6f4;
                                        border-radius: 0;
                                        margin-top: 3px;
                                    }
                                }

                                .gen-dropdown-pagination {
                                    display: flex;
                                    align-items: center;
                                    padding: 0 15px;
                                    border-top: 1px solid #dce6f0;
                                    height: 13%;
                                    justify-content: center;

                                    .page-next-prew-btn {
                                        display: flex;
                                        justify-content: space-between;
                                        height: auto;

                                        button {
                                            display: inline-block;
                                            border-radius: 6px;
                                            border: none;
                                            background-color: #fff;
                                            font-size: 12px;
                                            font-weight: 500;
                                            color: #12203c;
                                            transition: all .6s;
                                            margin: 0;
                                            height: 24px;
                                            background-image: none;
                                            width: auto;
                                            white-space: normal;
                                            text-align: center;
                                        }

                                        .pnpb-no {
                                            margin: 0 5px;
                                        }

                                        .pnpb-prev:hover,
                                        .pnpb-next:hover,
                                        .pnpb-prev:focus,
                                        .pnpb-next:focus {
                                            svg {
                                                path {
                                                    fill: $primary-color;
                                                    transition: 0.6s all;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .ant-picker-range {
                                display: flex;
                                min-width: 200px;
                                padding: 0px 10px;

                                .ant-picker-input {
                                    input {
                                        height: 28px;
                                    }

                                    input::placeholder {
                                        color: #8496a9;
                                    }
                                }
                            }
                        }

                        .select-right {
                            .gsis-clear {
                                position: absolute;
                                right: 5px;
                                top: 11px;
                                cursor: pointer;
                            }
                        }

                        .asnfbb-right.select {
                            display: flex;
                            width: 100%;
                            align-items: center;
                            cursor: pointer;

                            .gsis-clear-sel {
                                right: 5px;
                                top: 11px;
                            }

                            input {
                                text-overflow: ellipsis;
                                cursor: pointer;

                                svg.asnfbbr-input-arrow:hover {
                                    background: #ffffff;
                                }
                            }

                            input+svg.asnfbbr-input-arrow:hover {
                                background: #ffffff;
                            }

                            .asnfbbr-select-count {
                                background: #b6baca;
                                position: absolute;
                                right: 34px;
                                display: inline-flex;
                                align-items: center;
                                padding: 1px 5px;
                                font-weight: 600;
                                color: #67768e;
                                font-size: 11px;
                                border-radius: 2px;
                                cursor: pointer;
                                top: 6px;
                            }

                            .dropdown-menu-city1 {
                                height: auto;
                                top: 30px;
                                left: unset;
                                transform: none;
                                right: 0;
                                max-width: 360px;
                                max-height: 270px;
                                overflow: auto;

                                .dropdown-menu-city-item {
                                    li {
                                        .checkBoxLabel0 {
                                            white-space: normal;
                                        }
                                    }
                                }
                            }

                            .gsis-clear {
                                right: 4px;
                                padding: 5px;
                            }
                        }

                        .asnfbb-right.not-checked {
                            height: 30px;
                            background-color: #f2f4f8;
                            // border-radius: 5px;
                        }

                        .asnfbb-date-field {
                            .gsis-clear {
                                position: absolute;
                                right: 10px;
                                top: 8px;
                                background: #fff;
                            }
                        }
                    }

                    .filter-table-header:first-child,
                    .filter-table-header:nth-child(2),
                    .filter-table-header:nth-child(3) {
                        .asnfbb-right {
                            .dropdown-menu-city1 {
                                left: 0;
                                right: auto;
                            }
                        }
                    }

                    .filter-table-header:last-child {
                        .asnfbb-right {
                            .dropdown-menu-city1 {
                                left: auto;
                                right: 0;
                            }
                        }
                    }

                    .tc-border-inline {
                        border-inline: solid 1px #ffd693;
                    }

                    // .sticky-col:last-child {
                    //     border-right: 1px solid #000;
                    //     background-color: red;
                    // }
                }

                thead {
                    tr {
                        height: 30px;

                    }
                }

                tbody {
                    tr {
                        height: 30px;

                        td {
                            height: 30px;
                            padding: 0 8px;
                            border: none;
                            // border-right: 1px solid #dae4ee;
                            border-left: 1px solid #dae4ee;
                            border-top: 1px solid #dae4ee;

                            .available-link-btn {
                                // font-size: 12px;
                                position: relative;
                                // font-weight: 600;
                                // color: #2969ff;
                                // padding: 2px 5px 3px 9px;
                                // border-radius: 40px;
                                // border: solid 1px #2969ff;
                                // background-color: #fff;
                                
                            }

                            // .available-link-btn:hover {
                            //     color: #fff;
                            //     background-color: #2969ff;
                            // }

                            .availableouterdiv {
                                .available-inner {
                                    display: block;
                                    position: absolute;
                                    top: 28px;
                                    // right: 38px;
                                    left: calc(100% + 2px);
                                    width: 210px;
                                    padding: 6px 0;
                                    margin: 0;
                                    z-index: 99;
                                    border-radius: 6px;
                                    box-shadow: 0 2px 6px 0 rgba(75, 75, 75, 0.16);
                                    background-color: #ffffff;
                                    max-height: 370px;

                                    li {
                                        display: flex;
                                        align-items: center;
                                        cursor: pointer;
                                        padding-inline: 10px;

                                        button {
                                            display: block;
                                            padding: 7px 10px;
                                            position: relative;
                                            font-size: 11px;
                                            font-weight: 600;
                                            color: #111d31;
                                            white-space: nowrap;
                                            background: #fff;
                                        }
                                    }

                                    li:hover {
                                        background-color: #f5f5f5;

                                        button {
                                            background-color: #f5f5f5;
                                        }
                                    }

                                    .opacity04:hover {
                                        background-color: #fff;

                                        button {
                                            background-color: #fff;
                                        }
                                    }
                                }
                            }

                            .rework-log-info {
                                position: relative;
                            }

                            // .rework-log-info:hover {
                            //     .rework-log-info-dropdown {
                            //         visibility: visible;
                            //         opacity: 1;
                            //     }
                            // }

                            .tmfDownloadDoc {
                                display: flex;
                                align-items: center;
                                color: #111D31;
                                text-decoration: underline;
                                font-size: 12px;
                                font-weight: 500;

                                svg {
                                    display: inline-block;
                                    margin-left: 7px;
                                }
                            }

                            label {
                                font-weight: 400;
                                font-size: 12px !important;
                                color: #111D31;
                            }

                            .label-success,
                            .label-pending,
                            .label-failed,
                            .label-default {
                                background: #03B675;
                                color: #fff;
                                padding: 2px 5px;
                                border-radius: 3px;
                                font-weight: 600;
                            }

                            .label-pending {
                                background: #FE9D0B;
                            }

                            .label-failed {
                                background: $danger-color;
                            }

                            .label-default {
                                background: #67768E;
                            }

                            .ghtmtblue,
                            .cursor-not-allowed {
                                cursor: pointer !important;
                                color: #3279dd !important;
                                font-weight: 700 !important;
                            }

                            .table-tooltip {
                                top: -22px;
                            }

                            .vso-status {
                                background: #F8D5E3;
                                text-align: center;
                                color: #392129;
                                border-radius: 3px;
                                padding: 3px 5px;
                                display: inline !important;
                                font-weight: 500;
                                cursor: pointer !important;
                            }

                            .vsos-pending {
                                background: #FDECC8;
                                text-align: center;
                                color: #5E1118;
                                border-radius: 3px;
                                padding: 3px 5px;
                                display: inline !important;
                                font-weight: 500;
                                cursor: pointer !important;
                            }

                            .vsos-approved {
                                background: #DAF8E7;
                                text-align: center;
                                color: #1B3326;
                                border-radius: 3px;
                                padding: 3px 5px;
                                display: inline !important;
                                font-weight: 500;
                                cursor: pointer !important;
                            }

                            .vsos-hold {
                                background: #FDECC8;
                                text-align: center;
                                color: #5E1118;
                                border-radius: 3px;
                                padding: 3px 5px;
                                display: inline !important;
                                font-weight: 500;
                                cursor: pointer !important;
                            }

                            .vsos-rejected {
                                background: #F8D5E3;
                                text-align: center;
                                color: #392129;
                                border-radius: 3px;
                                padding: 3px 5px;
                                display: inline !important;
                                font-weight: 500;
                                cursor: pointer !important;
                            }

                            .vsos-draft {
                                background: #EBE7FD;
                                text-align: center;
                                color: #0B275F;
                                border-radius: 3px;
                                padding: 3px 5px;
                                display: inline !important;
                                font-weight: 500;
                                cursor: pointer !important;
                            }

                            .vsos-update {
                                background: #DAE6FD;
                                text-align: center;
                                color: #0B275F;
                                border-radius: 3px;
                                padding: 3px 5px;
                                display: inline !important;
                                font-weight: 500;
                                cursor: pointer !important;
                            }

                            .page-dropdown-multi {
                                position: absolute;
                                top: 0;
                                left: 149px;
                                right: auto;
                                height: auto;
                                background-color: #fff;
                                border: none;
                                text-align: left;
                                box-shadow: 2px 0 6px 0 rgba(139, 139, 139, 0.16);
                                transition: all .6s;
                                z-index: 9;
                                display: inline-table;

                                .pdm-pending-page {
                                    position: relative;

                                    .page-dropdown-multi {
                                        position: absolute;
                                        top: 0px;
                                        left: -25px;

                                        .container {
                                            .timeline {
                                                .timeline-container {
                                                    .timeline-body {
                                                        padding: 3px 10px;
                                                    }
                                                }
                                            }

                                            .timeline::before {
                                                width: 0px;
                                            }
                                        }
                                    }
                                }

                                .timeline {
                                    position: relative;
                                    padding-left: 20px;
                                    margin: 0px 1px 0 10px;
                                    color: white;

                                    &:before {
                                        content: '';
                                        position: absolute;
                                        left: 2px;
                                        top: 5px;
                                        width: 1px;
                                        height: 93%;
                                        background: #05C46B;
                                    }

                                    .timeline-container {
                                        position: relative;
                                        margin-bottom: 15px;

                                        .timeline-icon {
                                            position: absolute;
                                            left: -33px;
                                            top: 4px;
                                            width: 30px;
                                            height: 30px;
                                            border-radius: 50%;
                                            text-align: center;
                                            font-size: 2rem;
                                            background: #05C46B;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;

                                            label {
                                                font-weight: 700;
                                                font-size: 11px;
                                                color: #FFFFFF;
                                                margin: 0;
                                            }
                                        }

                                        .m-pending {
                                            background: #FE9D0B;
                                        }

                                        .timeline-body {
                                            background: #fff;
                                            padding: 0 10px;

                                            h3 {
                                                font-weight: 600;
                                                font-size: 13px;
                                                color: #111D31;
                                                margin: 0;
                                            }

                                            label {
                                                font-weight: 500;
                                                font-size: 11px;
                                                color: #67768E;
                                                margin: 0;
                                            }

                                            span {
                                                background: #EDEEF2;
                                                border-radius: 3px;
                                                font-weight: 600;
                                                font-size: 10px;
                                                color: #67768E;
                                                padding: 2px 5px;
                                                margin-left: 5px;
                                            }

                                            .timeline-subtitle {
                                                font-weight: 300;
                                                font-style: italic;
                                                opacity: .4;
                                                margin-top: 16px;
                                                font-size: 11px;
                                            }
                                        }
                                    }
                                }
                            }

                            .multilevel-loader {
                                position: absolute;
                                height: auto;
                                background-color: #fff;
                                padding: 5px 0;
                                border: none;
                                text-align: left;
                                box-shadow: 2px 0 6px 0 rgba(139, 139, 139, 0.16);
                                transition: all .6s;
                                z-index: 9;

                                .timeline {
                                    position: relative;
                                    padding-left: 20px;
                                    margin: 0px 1px 0 10px;
                                    color: white;

                                    &:before {
                                        content: '';
                                        position: absolute;
                                        left: 2px;
                                        top: 5px;
                                        width: 1px;
                                        height: 93%;
                                        background: #EDEEF2;
                                    }

                                    .timeline-container {
                                        position: relative;
                                        margin-bottom: 15px;

                                        .timeline-icon {
                                            position: absolute;
                                            left: -33px;
                                            top: 4px;
                                            width: 30px;
                                            height: 30px;
                                            border-radius: 50%;
                                            text-align: center;
                                            background: #EDEEF2;
                                            display: flex;

                                            label {
                                                font-weight: 700;
                                                font-size: 11px;
                                                color: #FFFFFF;
                                                margin: 0;
                                            }
                                        }

                                        .m-pending {
                                            background: #EDEEF2;
                                        }

                                        .timeline-body {
                                            background: #fff;
                                            padding: 0 10px;

                                            h3 {
                                                width: 163px;
                                                height: 12px;
                                                border-radius: 2px;
                                                margin: 0;
                                                background: #EDEEF2;
                                            }

                                            label {
                                                width: 80px;
                                                height: 12px;
                                                margin: 0;
                                                margin-top: 5;
                                                border-radius: 2px;
                                                background: #EDEEF2;
                                            }

                                            .width50px {
                                                margin-left: 10;
                                            }
                                        }
                                    }
                                }
                            }

                            .clr-red {
                                color: #c44569;
                            }

                            .upd-data {
                                display: flex;
                                align-items: center;

                                span {
                                    color: #111D31;
                                    font-size: 12px;
                                    font-weight: 600;
                                    padding: 3px 7px;
                                    background: #FFFFFF;
                                    border: 1px solid #D6D9E4;
                                    border-radius: 20px;
                                    margin-right: 10px;
                                    cursor: pointer;
                                }

                                .map-val {
                                    font-weight: 400;
                                }

                                label {
                                    font-weight: 600;
                                    font-size: 12px !important;
                                    color: #2969FF;
                                    cursor: pointer !important;
                                }
                            }

                            .mappedOn {
                                .map-val {
                                    padding: 3px 9px;
                                    font-size: 12px;
                                    font-weight: 600;
                                    color: #111D31;
                                    background: #FFFFFF;
                                    border: 1px solid #D6D9E4;
                                    border-radius: 20px;
                                    margin-right: 5px;
                                }
                            }

                            .gen-vend-sticky-table {
                                .gvst-expend {
                                    .gvst-inner {
                                        .table {
                                            thead {
                                                box-shadow: none;

                                                tr {
                                                    box-shadow: none;

                                                    th {
                                                        label {
                                                            color: #67768e;
                                                            font-weight: 500 !important;
                                                        }
                                                    }
                                                }
                                            }

                                            tbody {
                                                tr {

                                                    td:nth-child(1),
                                                    td:nth-child(2) {
                                                        border-left: 1px solid #dae4ee;
                                                    }
                                                    .tc-border-inline {
                                                        border-inline: solid 1px #ffd693;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .grc-status-value {
                                cursor: pointer;
                                background: #F53B571A;
                                color: #F53B57;
                                text-decoration: underline;
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                                padding-right: 8px;
                            }

                            .grcPendingQty {
                                display: flex;
                                width: 100%;
                                font-size: 12px;
                                justify-content: end;
                                padding-top: 5px;
                            }

                            .crli-outer {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                select {
                                    border-radius: 4px;
                                    font-size: 11px;
                                    padding: 2px 5px;
                                    font-weight: 600;
                                    height: 26px;
                                    margin-right: 8px;
                                    width: 150px;
                                    border: 1px solid #dce6f0;
                                    background-size: 12px;
                                }

                                .crline-save {
                                    font-size: 12px;
                                    font-weight: 600;
                                    background: $primary-color;
                                    color: #fff;
                                    height: 26px;
                                    border-radius: 4px;
                                    display: -ms-inline-flexbox;
                                    display: inline-flex;
                                    -ms-flex-align: center;
                                    align-items: center;
                                    padding: 0;
                                    min-width: 65px;

                                    .crlines-txt {
                                        display: inline-block;
                                        padding: 4.3px 7px;
                                    }

                                    .crlines-close {
                                        position: relative;
                                        display: inline-block;
                                        padding: 9.2px 7px;
                                        border-left: 1px solid #b1a3fc;
                                        border-radius: 0 5px 5px 0;
                                    }
                                }
                            }

                            .user-resend-email {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                height: 100%;

                                button {
                                    position: relative;
                                    height: 100%;
                                    padding: 0 3px;
                                }

                                button:hover {
                                    .generic-tooltip {
                                        visibility: visible;
                                        opacity: 1;
                                    }
                                }

                                button[disabled] {
                                    .generic-tooltip {
                                        display: none;
                                    }
                                }
                            }
                        }
                        
                        td:hover>.generic-tooltip {
                            visibility: visible;
                            opacity: 1;
                        }
                        td:hover > .table-tooltip {
                            visibility: visible;
                            opacity: 1;
                        }

                        .fix-action-btn {
                            border-left: none !important;
                            border-top: 1px solid #dae4ee;
                            // border-bottom: 1px solid #dae4ee;
                            border-right: 1px solid #dae4ee;
                            left: -1px;
                            z-index: 2;
                            padding: 0;
                            height: 100%;

                            .table-item-list {
                                box-shadow: 0px 4px 8px #e6e9ec;
                                height: 30px;

                                .til-add-btn {
                                    .generic-tooltip {
                                        top: -27px;
                                    }
                                }

                                .btnDisabled {
                                    opacity: 0.4;
                                    cursor: not-allowed;

                                    .generic-tooltip {
                                        display: none;
                                    }
                                }

                                .til-inner:hover, .til-chat-icon:hover {
                                    background-color: #5D5FEF1c;
                                    transition: all .3s;

                                    .uploadImg {
                                        .generic-tooltip {
                                            opacity: 1;
                                            visibility: visible;
                                        }
                                    }
                                }

                                .til-eye-btn:hover,
                                .til-edit-btn:hover {
                                    background-color: #5D5FEF1c;
                                    transition: all .3s;
                                }

                                .tili-eye-hover:hover {
                                    svg {
                                        path {
                                            fill: #ffb103;
                                        }
                                    }
                                }
                            }
                        }

                        .user-fix-action-btn {
                            .table-item-list {
                                box-shadow: none;

                                .generic-toggle {
                                    padding: 0 5px;
                                    width: 42px;
                                    display: block;

                                    .gt-switch {
                                        width: 32px;

                                        .gt-slider {
                                            width: 32px;
                                        }
                                    }
                                }

                                .opacity04 {
                                    .gt-switch {
                                        .gt-slider {
                                            cursor: not-allowed;
                                        }
                                    }
                                }
                            }
                        }

                        .po-num-col {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            border-left: 1px solid #dae4ee !important;

                            label {
                                font-weight: 500 !important;
                                display: inline-flex;
                                align-items: center;
                                gap: 0.2rem;
                                transition: padding 0.3s;
                            }
                            .rightArrowLabel{
                                border: 1px solid transparent;
                                border-radius: 3px;
                                // color: #57606F;
                                padding: 2px 3px;
                                padding-left: 6px;
                                cursor: pointer;

                                .rightArrowIcon {
                                    // opacity: 0;
                                    // transform: translateX(-10px);
                                    // transition: .3s transform, .3s opacity;
                                    path{
                                        stroke: #2969ff;
                                    }
                                }

                                // .imageLottieStatic{
                                //     opacity: 1;
                                //     visibility: visible;
                                // }
                                // .imageLottieAnimation{
                                //     opacity: 0;
                                //     visibility: hidden;
                                // }
                            }
                        }

                        // .purchase-term-avaliable {
                        //     padding-right: 26px;
                        //     .purchase-term-icon {
                        //         position: absolute;
                        //         top: 5px;
                        //         right: 8px;
                        //     }
                        // }

                        .purchase-term-avaliable {
                            display: flex;
                            align-items: center;
                        }

                        .selected-col:first-child,
                        .selected-col:nth-child(2) {
                            border-left: 2px solid $primary-color;
                        }

                        .tc-border-inline {
                            border-inline: solid 1px #ffd693;
                        }

                        // .sticky-col:last-child {
                        //     border-right: 1px solid #000;
                        //     background-color: red;
                        // }
                    }

                    .tr-bg-change {
                        td {
                            background-color: #f2f5f8 !important;

                            label {
                                font-weight: 600 !important;
                                font-size: 12px !important;
                            }
                        }
                    }

                    tr:first-child {
                        td {
                            border: none;
                            // border-right: 1px solid #dae4ee;
                            border-left: 1px solid #dae4ee;
                        }

                        .fix-action-btn {
                            border-right: 1px solid #dae4ee;
                        }

                        .selected-col {
                            border: 2px solid $primary-color;
                        }
                        .tc-border-inline {
                            border-inline: solid 1px #ffd693;
                        }
                    }

                    // tr:nth-last-child(1),
                    // tr:nth-last-child(2),
                    // tr:nth-last-child(3),
                    // tr:nth-last-child(4),
                    // tr:nth-last-child(5) {
                    //     td {
                    //         .page-dropdown-multi {
                    //             bottom: 0;
                    //             top: auto;
                    //         }

                    //         .rework-log-info-dropdown {
                    //             bottom: auto;
                    //         }
                    //     }
                    // }

                    tr:nth-last-child(1),
                    tr:nth-last-child(2),
                    tr:nth-last-child(3),
                    tr:nth-last-child(4) {
                        td {
                            .availableouterdiv {
                                .available-inner {
                                    bottom: 31px;
                                    top: auto;
                                }
                            }

                            .purchase-term-dropdown,
                            .purchase-term-dropdown-skeleton {
                                top: auto;
                                bottom: 25px;
                            }
                        }
                    }

                    tr:nth-last-child(5) {
                        td {

                            .purchase-term-dropdown,
                            .purchase-term-dropdown-skeleton {
                                top: auto;
                                bottom: 25px;
                            }
                        }
                    }

                    tr:nth-child(1),
                    tr:nth-child(2),
                    tr:nth-child(3),
                    tr:nth-child(4),
                    tr:nth-child(5) {
                        td {
                            .page-dropdown-multi {
                                top: 0;
                                right: auto;
                            }

                            .purchase-term-dropdown,
                            .purchase-term-dropdown-skeleton {
                                bottom: auto;
                                top: 25px;
                            }

                            // .rework-log-info-dropdown {
                            //     top: auto;
                            // }
                        }
                    }

                    tr:last-child {
                        td {
                            border-bottom: 1px solid #dae4ee;
                        }
                    }
                }
            }

            .imtm-product-view{
                .imtmp-filters{
                    display: flex;
                    align-items: center;
                    padding: 0 10px;
                    overflow: auto;
                }
                .imtmp-no-data{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-top: 100px;
                    label{
                        font-size: 13px;
                        font-weight: 500;
                        color: #0F172A;
                        margin: 0;
                    }
                }
                .splrs-card-section{
                    background-color: #F3F4F9;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 15px;
                    padding: 15px;
                    .splrscs-card {
                        width: 19%;
                        padding-right: 0;
                        margin-bottom: 0;
                        .splrscsc-inner{
                            // padding-bottom: 5px;
                            .splrscsc-top {
                                .heart-icon {
                                    position: absolute;
                                    right: 8px;
                                    top: 5px;
                                    visibility: hidden;
                                    opacity: 0;
                                    z-index: 1;
                                }
        
                                .heart-icon-active {
                                    visibility: visible;
                                    opacity: 1;
        
                                    svg {
                                        path {
                                            fill: #fd3757;
                                        }
                                    }
                                }
                            }
                            .splrscsc-det{
                                display: flex;
                                flex-direction: column;
                                padding: 0 12px 5px;
                                label{
                                    color: #0f172a;
                                    font-size: 11px;
                                    font-weight: 600;
                                    margin: 0;
                                    margin-bottom: .3rem;
                                    white-space: nowrap;
                                    b{
                                        color: #888a8e;
                                        font-weight: 500;
                                    }
                                }
                                p{
                                    color: #0f172a;
                                    font-size: 11px;
                                    font-weight: 700;
                                    margin: 0;
                                    padding: 3px 0;
                                    span{
                                        margin-right: 2px;
                                    }
                                }
                            }
                            .splrscsc-btns{
                                display: flex;
                                gap: .25rem;
                                align-items: center;
                                padding-left: 11px;
                                padding-top: .6rem;
                                .til-chat-icon {
                                    display: flex;
                                    padding: 4px;
                                    background-color: #E3E4E7;
                                    width: 28px;
                                    height: 28px;
                                    cursor: pointer;
                                    .comments-button {
                                        border-radius: 0;
                                        background-color: transparent;
                                        .comment-icon {
                                            border-radius: 0;
                                            // background-color: transparent;
                                            svg {
                                                // background-color: transparent;
                                            }
                                        }
                                    }
                                    svg {
                                        path {
                                            stroke: #0f172a;
                                        }
                                    }
                                }
                                .comments-col, .comments-col:hover {
                                    background-color: #cef2e6!important;
                                    svg {
                                        path {
                                            stroke: #fff;
                                        }
                                    }
                                }
                                button{
                                    background-color: #E3E4E7;
                                    width: 28px;
                                    height: 28px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    // border: 1px solid #dee;
                                    margin-right: 5px;
                                    svg{
                                        width: 17px;
                                        height: 17px;
                                        g{
                                            path{
                                                stroke-width: 1px;
                                                fill: #0F172A;
                                            }
                                        }
                                        path{
                                            stroke-width: 1px;
                                            fill: #0f172a;
                                        }
                                    }
                                }
                                button:hover{
                                    // background-color: #e3e4e7;
                                    background-color: $primary-color;
                                    svg{
                                        path{
                                            fill: white;
                                        }
                                    }
                                }

                                .til-chat-icon:hover{
                                    background-color: $primary-color;
                                    svg{
                                        path{
                                            stroke: white;
                                        }
                                    }
                                }
                            }
                        }
                        .splrscsc-inner:hover{
                            // box-shadow: 0 0 9px 0 rgba(122, 122, 122, 0.1607843137);
                            .splrscsc-top {
                                .heart-icon {
                                    visibility: visible;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                    .splrscs-card:hover{
                        .splrscsc-inner{
                            .splrscsc-top{
                                height: 236px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Style Invoice Manage Table By 
.invoice-manage-table {
    position: relative;
    display: block;
    box-shadow: 2px 0 6px 0 hsla(0, 0%, 55%, .16);
    margin-top: 30px;

    .imt-manage-table {
        overflow-y: hidden;
        overflow-x: auto;

        .columnFilterGeneric {
            .glyphicon {
                padding: 15px 3px;
            }
        }

        .imt-main-table {
            display: table;
            white-space: nowrap;
            border-collapse: collapse;
            margin: 0;
            border-spacing: 0;

            thead {
                border: none;
                box-shadow: 2px 0 6px 0 hsla(0, 0%, 55%, .16);

                tr,
                div {
                    height: 30px;
                    background-color: #fff;
                    display: table-row;

                    th,
                    .default-th {
                        height: 30px;
                        vertical-align: middle;
                        white-space: nowrap;
                        border: none;

                        label {
                            margin: 0;
                            font-weight: 600;
                            color: #000000;
                            width: auto;
                            cursor: pointer !important;
                            font-size: 12px !important;
                        }

                        img {
                            margin-left: 15px;
                            cursor: pointer;
                        }
                    }

                    .default-th {
                        display: table-cell;
                        padding: 0 4px;
                    }

                    th.rotate180,
                    div.rotate180 {
                        transform: rotate(0);

                        img {
                            filter: sepia(1);
                            transform: rotate(180deg);
                        }
                    }

                    .fix-action-btn {
                        width: auto;
                        box-shadow: 10px 0 8px -4px hsla(0, 0%, 55%, .16);
                        background-color: #fff;
                        border: 0;
                        left: 0;
                        position: sticky;
                        border-right: none;
                        border-bottom: none;
                        // z-index: 2;
                        max-width: 90px;
                        padding: 0;
                        min-width: 40px;

                        label {
                            width: auto;
                        }

                        .rab-refresh {
                            display: flex;
                            margin: 0;
                            padding: 0;
                            display: flex;
                            width: 100%;
                            align-items: center;
                            justify-content: flex-start;

                            .rab-rinner {
                                display: inline-block;
                                padding: 7px;
                                position: relative;
                                border-radius: 50%;
                                cursor: pointer;

                                span {
                                    img {
                                        margin: 0;
                                        width: 18px;
                                    }
                                }

                                label {
                                    font-size: 12px;
                                }

                                .checkBoxLabel0 {
                                    width: 16px;
                                    height: 16px;
                                    padding-left: 16px;

                                    .checkmark1 {
                                        left: 0;
                                        top: 0;
                                        height: 16px;
                                        width: 16px;
                                        background-color: #ffffff;
                                        cursor: pointer;
                                        border-radius: 4px;
                                        border: solid 2px #dce6f0;
                                    }

                                    .checkmark1:after {
                                        width: 5px;
                                        height: 9px;
                                        border-width: 0 2px 2px 0;
                                        content: none;
                                    }

                                    input:checked~.checkmark1 {
                                        background-color: $primary-color;
                                        border: none;
                                    }

                                    input:checked~.checkmark1:after {
                                        width: 5px;
                                        height: 9px;
                                        border-width: 0 2px 2px 0;
                                        content: "";
                                    }
                                }

                                .select-all-text {
                                    position: absolute;
                                    top: 3px;
                                    left: 30px;
                                    padding: 4px 14px;
                                    font-size: 12px;
                                    background: $primary-color;
                                    color: #fff;
                                    border-radius: 30px;
                                    z-index: 9;
                                }

                                .generic-tooltip {
                                    left: 140%;
                                    top: 2px;
                                }

                                .generic-tooltip:after {
                                    top: 25%;
                                    left: -9%;
                                    margin-left: -6px;
                                    border-width: 6px;
                                    border-color: transparent #000 transparent transparent;
                                }
                            }

                            .rab-rinner:hover>.generic-tooltip {
                                visibility: visible;
                                opacity: 1;
                            }

                            .til-inner {
                                display: inline-block;
                                padding: 7px;
                                position: relative;
                                border-radius: 50%;
                                cursor: pointer;
                                margin-left: 5px;

                                .generic-tooltip {
                                    top: 1px;
                                    left: 160%;
                                    z-index: 99;
                                }

                                .generic-tooltip:after {
                                    top: 31%;
                                    left: -9%;
                                    margin-left: -5px;
                                    border-width: 5px;
                                    border-style: solid;
                                    border-color: transparent #000 transparent transparent;
                                }
                            }

                            .til-inner:hover>.generic-tooltip {
                                visibility: visible;
                                opacity: 1;
                            }

                            .til-edit-btn:hover {
                                background: #3ee58521;

                                svg {
                                    path {
                                        fill: #3ee585;
                                    }
                                }
                            }
                        }
                    }

                    .sticky-col {
                        background-color: #f8f8f8;
                        position: sticky;
                        z-index: 1;
                    }

                    .width40 {
                        width: 40px;
                    }

                    .width80 {
                        width: 80px;
                    }

                    .width90 {
                        width: 90px;
                    }
                }
            }

            tbody {
                tr {
                    height: 30px;

                    td {
                        height: 30px;
                        white-space: nowrap;
                        vertical-align: middle;
                        border: 0.5px solid #e3e7f3;
                        position: relative;

                        label {
                            color: #000;
                            margin: 0;
                            font-weight: 500;
                            white-space: nowrap;
                            max-width: 200px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            vertical-align: middle;
                            font-size: 11px !important;
                        }

                        .user-active {
                            background: #03b675;
                            margin-right: 10px;
                            color: #ffffff;
                            font-weight: 600;
                            border-radius: 4px;
                            font-size: 12px;
                            padding: 2px 8px;
                        }

                        .user-inactive {
                            background: #232349;
                            margin-right: 10px;
                            color: #ffffff;
                            font-weight: 600;
                            border-radius: 4px;
                            font-size: 12px;
                            padding: 2px 8px;
                        }

                        .user-logged {
                            color: #ffffff;
                            font-weight: 600;
                            border-radius: 5px;
                            font-size: 12px;
                            padding: 2px 7px;
                            background-color: #5f45fd;
                        }

                        input[type="text"] {
                            border-radius: 6px;
                            border: solid 1px #dce6f0;
                            background-color: #ffffff;
                            font-size: 11px;
                            font-weight: 500;
                            color: #000;
                            padding: 3px 5px;
                            height: 28px;
                            width: 80px;
                        }

                        label.clr-red {
                            color: #c44569;
                            cursor: pointer !important;
                        }

                        label.clr-blue {
                            color: #3279dd;
                            font-weight: 700;
                        }

                        select {
                            border-radius: 5px;
                            border: 1px solid #dce6f0;
                            background: #fff;
                            padding: 8px 10px;
                            height: 32px;
                            font-size: 11px;
                            color: #000;
                            font-weight: 600;
                            width: 250px;
                            background-position: 96%;
                            background-repeat: no-repeat;
                            background-size: 13px;
                        }

                        .asnHoldCheck {
                            display: inline;
                            position: relative;
                            cursor: pointer;
                            margin-right: 5px;

                            svg{
                                display: inline-block;
                            }

                            img {
                                height: 18px;
                            }

                            span.table-tooltip {
                                width: auto;
                                padding: 4px 5px;
                                background-color: #ffb103;
                                color: #ffffff;
                                top: -20px;
                                left: 225%;
                                border: none;
                            }

                            span.table-tooltip::after {
                                content: "";
                                position: absolute;
                                border: 5px solid #ffb103;
                                border-color: #ffb103 transparent transparent transparent;
                                left: 18%;
                            }
                        }

                        .asnHoldCheck:hover>span.table-tooltip {
                            visibility: visible;
                            opacity: 1;
                        }

                        .genmt-rupee-sign {
                            margin-right: 5px;
                            color: #000;
                            font-weight: 400;
                            font-family: system-ui;
                            font-size: 11px;
                        }

                        .custom-col-customer {
                            cursor: pointer;
                            text-decoration: underline;
                        }

                        .custom-col-customer:hover {
                            color: #666af6;
                        }

                        .vso-status {
                            font-weight: 600;
                            min-width: 90px;
                            position: relative;
                        }

                        // .vso-status::after {
                        //     content: "";
                        //     position: absolute;
                        //     top: 5px;
                        //     right: 15px;
                        //     width: 5px;
                        //     height: 5px;
                        //     border-radius: 50%;
                        //     background-color: #c1d8f0;
                        // }
                        .vsos-pending:after {
                            background-color: #ffb103;
                        }

                        .vsos-approved:after {
                            background-color: #6fc192;
                        }

                        .vsos-hold:after {
                            background-color: #ff0505;
                        }

                        .table-td-blue {
                            color: #3279dd;
                            font-weight: 700;
                        }

                        .table-td-error {
                            color: #c44569 !important;
                            cursor: pointer !important;
                        }

                        .unapprove-tag {
                            position: relative;
                            margin-left: 30px;
                            display: inline-block;

                            .generic-tooltip {
                                background-color: #ed3946;
                            }

                            .generic-tooltip:after {
                                border-color: #ed3946 transparent transparent;
                            }
                        }

                        .unapprove-tag:hover {
                            .generic-tooltip {
                                visibility: visible;
                                opacity: 1;
                            }
                        }

                        .vgt-status2 {
                            display: inline-block;
                            border-radius: 4px;
                            border: solid 1px #f8c902;
                            color: #f8c902;
                            background-color: #ffffff;
                            font-size: 11px;
                            font-weight: 600;
                            padding: 2px 9px;
                        }

                        .vgts-aprvl-pend {
                            // color: #67768e;
                            color: white;
                            background-color: #67768e;
                            border: solid 1px #67768e;
                        }

                        .vgts-otb-aprvd {
                            border: solid 1px #03b675;
                            background-color: #03b675;
                            color: white;
                        }

                        .vgts-onhold {
                            border: solid 1px #fe9d0b;
                            background-color: #fe9d0b;
                            // color: #fe9d0b;
                            color: white;
                        }

                        .vgts-reject {
                            border: solid 1px $danger-color;
                            background-color: $danger-color;
                            // color: $danger-color;
                            color: white;
                        }

                        .media-table-header {
                            border-radius: 3px;
                            padding: 1px 0 6px;
                            background-color: #f5f5f5;

                            button {
                                color: #21314b;
                                font-size: 11px;
                                font-weight: 600;
                                background-color: #f5f5f5;

                                svg {
                                    margin-right: 5px;
                                }
                            }
                        }

                        .dFlexW100PerJusBetween {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                            align-items: center;

                            button {
                                background-color: #fff;
                                padding: 3px;
                            }
                        }
                    }

                    .po-num-col {
                        border-left: solid 2px #fff !important;

                        label {
                            // cursor: pointer !important;
                            font-weight: 600;
                        }

                        .ponc-icon {
                            margin-left: 5px;
                            cursor: pointer;
                        }
                    }

                    .po-num-col {
                        border-left: solid 2px #2969ff !important;

                        // label:hover {
                        //     color: #2969ff;
                        // }

                        .rightArrowLabel:hover{
                            border-color: #2969ff;

                        //     .rightArrowIcon {
                        //         opacity: 1;
                        //         transform: translateX(0);
                                
                        //             path{
                        //                 stroke: #2969ff;
                        //             }
                        //     }
                        // .imageLottieStatic{
                        //     opacity: 0;
                        //     visibility: hidden;
                        // }
                        // .imageLottieAnimation{
                        //     opacity: 1;
                        //     visibility: visible;
                        // }
                        .imageLottieStatic {
                            transform: translateX(4px);
                        }
                        }

                        border: 1px solid #57606F;
                        

                        // svg {
                        //     path {
                        //         // fill: #2969ff;
                        //         stroke: #2969ff;
                        //     }
                        // }

                        .purchase-term-icon {
                            svg {
                                path {
                                    fill: none;
                                    stroke: #57606F;
                                }
                            }
                        }

                        .purchase-term-dropdown {
                            svg {
                                path {
                                    fill: #0F172A;
                                }
                            }
                        }
                    }

                    td:first-child {
                        border-left: none;
                    }

                    td:nth-child(2) {
                        border-left: none;
                    }

                    .selected-col {
                        border: solid 2px $primary-color !important;
                        border-left: solid 2px $primary-color !important;
                    }

                    .fix-action-btn {
                        width: auto;
                        box-shadow: 10px 0 8px -4px hsla(0, 0%, 55%, .16);
                        background-color: #fff;
                        border: 0;
                        padding: 0;
                        left: 0;
                        position: sticky;

                        .table-item-list {
                            display: flex;
                            width: 100%;
                            align-items: center;
                            justify-content: flex-start;
                            padding: 0;
                            margin: 0;
                            height: 30px;

                            .til-inner {
                                display: inline-block;
                                padding: 7px;
                                position: relative;
                                border-radius: 50%;
                                cursor: pointer;

                                .checkBoxLabel0 {
                                    width: 16px;
                                    height: 16px;
                                    padding-left: 16px;
                                }

                                .checkmark1 {
                                    left: 0px;
                                    top: 0px;
                                    height: 16px;
                                    width: 16px;
                                    background-color: #ffffff;
                                    cursor: pointer;
                                    border-radius: 4px;
                                    border: solid 2px #dce6f0;
                                }

                                .checkBoxLabel0 .checkmark1:after {
                                    width: 5px;
                                    height: 9px;
                                    border-width: 0 2px 2px 0;
                                    content: none;
                                }

                                .checkBoxLabel0 input:checked~.checkmark1 {
                                    background-color: $primary-color;
                                    border: none;
                                }

                                .checkBoxLabel0 input:checked~.checkmark1:after {
                                    width: 5px;
                                    height: 9px;
                                    border-width: 0 2px 2px 0;
                                    content: "";
                                }
                            }

                            .til-chat-icon:hover{
                                background-color: rgba(93, 95, 239, 0.1098039216) !important;
                                transition: all 0.3s;
                            }

                            .til-inner:hover>.generic-tooltip {
                                visibility: visible;
                                opacity: 1;
                            }

                            .til-delete-btn:hover {
                                background-color: #eb000826;
                                transition: 0.3s all;

                                svg path {
                                    fill: #eb0008;
                                }
                            }

                            .til-add-btn:hover {
                                background-color: #5D5FEF1c;
                                transition: 0.3s all;

                                g {
                                    g:first-child {
                                        fill: $primary-color;
                                        stroke: $primary-color;
                                    }

                                    g {
                                        g {
                                            fill: #ffffff !important;
                                            stroke: #ffffff !important;
                                        }
                                    }
                                }
                            }

                            .til-edit-btn:hover {
                                background: #3ee58521;

                                svg {
                                    path {
                                        fill: #3ee585;
                                    }
                                }
                            }

                            .til-eye-btn {
                                padding: 9px 6px;
                            }

                            .til-eye-btn:hover {
                                background: #ffb10321;

                                svg {
                                    path {
                                        fill: #ffb103;
                                    }
                                }
                            }

                            .til-eyedis-btn:hover {
                                svg {
                                    path {
                                        fill: #ccd8ed;
                                    }
                                }
                            }

                            .til-chat-icon {
                                width: 30px;
                                padding: 7px 7px 7px 5px;
                            }

                            .til-chat-icon:hover {
                                background: #12203c14;

                                img {
                                    filter: brightness(0.2);

                                    path {
                                        fill: #000000;
                                    }
                                }
                            }

                            .til-delete-icon:hover {
                                background-color: #fdf2f7 !important;

                                svg {
                                    path {
                                        fill: #fc5c65;
                                    }
                                }
                            }

                            .till-upload {
                                padding: 4px 7px;
                            }

                            .til-user {
                                .custom-modal {
                                    left: 45px;
                                }
                            }

                            .tip-ipr {
                                padding: 0 5px;

                                label {
                                    color: #A6A8AC;
                                    font-size: 13px;
                                    display: flex;
                                    align-items: center;
                                    border: 1px solid #A6A8AC;
                                    padding: 1px 5px;

                                    svg {
                                        margin-right: 3px;
                                    }
                                }

                                .label-active {
                                    color: #03B675;
                                    border-color: #03B675;
                                }
                            }
                        }
                    }

                    td.sticky-col {
                        background-color: #f3f6fb;
                        position: sticky;
                        z-index: 1;
                    }

                    .width40 {
                        width: 40px;
                    }

                    .width80 {
                        width: 80px;
                    }

                    .width90 {
                        width: 90px;
                    }

                }

                tr:first-child {
                    td {
                        border-top: none;
                    }

                    .selected-col {
                        border-top: solid 2px $primary-color;
                    }
                }

                .vgt-tr-bg {
                    background-color: #f7f5fb;
                }
            }
        }

        .imt-main-table.imtmt-v2 {
            tbody {
                tr {
                    td {
                        label {
                            max-width: fit-content;
                            overflow: visible;
                        }

                        .table-tooltip {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

// Style Transfer Order Tabs
.ars-transfer-order-tabs {
    display: flex;
    align-items: center;
    margin: 10px 15px 0 30px;
    border-radius: 6px;

    .atorbi-rule-eng {
        width: 60%;
        display: flex;
        background-color: #fff;
        padding: 5px 0 10px 15px;
        margin-right: 10px;

        .generic-input-box {
            margin: 0 15px 0 0;

            label {
                margin-bottom: 3px;
            }

            .list-component-section {
                top: 57px;
                z-index: 5;

                .modal-search-area {
                    padding: 5px 10px;
                }

                .lcs-inner {
                    .list-component-table-view {
                        .modal-data-list-render {
                            li {
                                .mdlr-col {
                                    ul {
                                        li {
                                            max-width: 200px;
                                            font-size: 11px;
                                            font-weight: 400;
                                            word-wrap: break-word;

                                        }

                                        .li-bold {
                                            font-weight: 600;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .generic-input-box:nth-last-child(1) {
            .list-component-section {
                .lcs-inner {
                    .list-component-table-view {
                        .lcv-table-head {
                            .lcvth-row {
                                li:nth-last-child(1) {
                                    padding-left: 5px;
                                }
                            }
                        }

                        .modal-data-list-render {
                            .mdlr-li {
                                .mdlr-col:nth-last-child(1) {
                                    .mdlr-multiple-row {
                                        max-width: 100px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .filter-summary {
        width: 40%;
        height: 70px;
    }
}

// Sticky Table
// Expand Table Style
.gen-vend-sticky-table {
    position: sticky;
    left: 90px;
    width: min-content;
    background: #fff;
    box-shadow: 2px 0 6px 0 hsla(0, 0%, 55%, .16);
    z-index: 3;

    .gvst-expend {
        display: table;
    }

    .gvst-inner {
        width: 1184px;
        overflow: auto;
        box-shadow: 2px 0 6px 0 hsla(0, 0%, 55%, .16);

        table {
            margin: 0;
            width: 100%;

            thead {
                box-shadow: 2px 0 6px 0 hsla(0, 0%, 55%, .16);

                tr {
                    box-shadow: 2px 0 6px 0 hsla(0, 0%, 55%, .16);

                    th {
                        background-color: #ffffff;
                        height: 35px;

                        label {
                            font-weight: 600 !important;
                        }
                    }

                    .fix-action-btn {
                        width: 40px !important;
                    }

                    .width70 {
                        width: 70px !important;
                    }
                }
            }

            tbody {
                tr:first-child {
                    td {
                        border-top: 1px solid #e3e7f3 !important;
                    }

                    .fix-action-btn {
                        border: none !important;
                        max-width: 40px !important;

                        .table-item-list {
                            width: 40px !important;
                        }

                        .width70 {
                            width: 70px !important;
                        }
                    }

                    .max-width90 {
                        max-width: 90px !important;
                    }
                }

                tr {
                    td {
                        input[type=text] {
                            border: 1px solid #fff4c9;
                            font-size: 11px;
                            font-weight: 500 !important;
                            padding: 3px 6px;
                            border-radius: 6px;
                            width: 100px;
                            background-color: #fffae7 !important;
                        }

                        .errorBorder {
                            border: 1px solid #c44569 !important;
                        }
                    }

                    .fix-action-btn {
                        border: none !important;
                        max-width: 40px !important;
                        width: 40px !important;

                        .table-item-list {
                            width: 40px !important;
                        }
                    }
                }
            }
        }
    }

    .gen-vend-sticky-table {
        left: 40px;

        .gvst-inner2 {
            overflow: auto;
            box-shadow: 2px 0 6px 0 hsla(0, 0%, 55%, .16);
            width: 1080px;
        }
    }

}

.right-click-menu {
    border-radius: 6px;
    box-shadow: 0 2.7px 19px 0 #a0a0a08f;
    position: fixed;
    width: 200px;
    height: 250px;
    background-color: #fff;
    padding: 5px 0;
    z-index: 9;

    .rcm-ul {
        padding: 0;
        margin: 0;
        max-height: 240px;
        overflow: auto;

        li {
            font-size: 11px;
            font-weight: 500;
            padding: 3px 10px;
            color: #8994a7;
        }

        .rcmul-heading {
            position: sticky;
            top: 0;
            background: #fff;
            z-index: 2;
        }

        .rightDNone {
            display: none;
        }

        .rcmu-list {
            padding: 0;
            transition: 0.5s all;
            background-color: #fff;

            button {
                background-color: #ffffff;
                color: #111d31;
                font-size: 12px;
                font-weight: 500;
                padding: 0;
                width: 100%;
                padding: 5px 10px;
                text-align: left;

                img,
                svg {
                    display: none;
                }
            }

            .gen-cancel {
                color: #d30b2c;
            }

            button:hover {
                background-color: $primary-color;
                color: #ffffff;
                transition: 0.5s all;
            }

            .gen-cancel:hover {
                background-color: #d30b2c;
                color: #ffffff;
                transition: 0.5s all;
            }

            .add-btn,
            input,
            .sohr-upload-download {
                display: none;
            }

            .gen-action-btn,
            .gen-action-btn-disable {
                display: none !important;
            }

            .generic-input-box {
                display: grid;
                padding: 10px;

                .gib-dropdown {
                    input {
                        display: block;
                    }
                }
            }

        }

        .rcmul-page-action {
            border-top: solid 4px #71819b2e;

            .rcmu-list {
                .generic-reject-btn {
                    background-color: #fff !important;
                    border: none !important;
                    border-radius: 0 !important;
                    color: #111d31 !important;
                }

                .generic-reject-btn:hover {
                    background: #e66767 !important;
                    color: #fff !important;
                }

                .generic-approve-btn {
                    background-color: #fff !important;
                    border: none !important;
                    border-radius: 0 !important;
                    color: #111d31 !important;
                }

                .generic-approve-btn:hover {
                    background: $primary-color !important;
                    color: #fff !important;
                }
            }
        }
    }
}

// Style Save Filter Modal
.save-filter-modal {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    width: 650px;
    height: 530px;
    // transform: translate(-50%, -50%);
    border: none;
    border-radius: 0;
    box-shadow: none;

    .sfm-head {
        display: block;
        padding: 20px 40px 0;

        .sfmh-top {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h3 {
                font-size: 16px;
                font-weight: 700;
                margin: 0;
                color: #12203c;
            }

            button {
                border: 2px solid #000;
                border-radius: 50%;
                background: #fff;
                padding: 4px;
                height: auto;
            }
        }

        .sfmh-bottom {
            display: block;
            padding: 10px 0 15px;
            border-bottom: 1px solid #c1d8f070;

            p {
                color: #97abbf;
                font-size: 12px;
                font-weight: 500;
                margin: 0;
            }

            .sfmhb-check {
                position: relative;
                margin-top: 10px;
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;

                button {
                    border-radius: 0;
                    border: none;
                    background-color: #fff;
                    font-size: 12px;
                    padding: 3px 8px;
                    margin-left: 0;
                    height: auto;
                    font-weight: 600;
                    position: relative;
                    display: inline-block;
                    color: #97abbfe0;

                    svg {
                        margin-right: 5px;
                    }
                }

                .checkBoxLabel0 {
                    width: auto;
                    height: 16px;
                    font-size: 12px;
                    font-weight: 500;
                    margin: 0px;
                    padding-left: 26px;
                    cursor: pointer !important;

                    .checkmark1 {
                        left: 0;
                        top: 0;
                        height: 16px;
                        width: 16px;
                        background-color: #ffffff;
                        cursor: pointer;
                        border-radius: 4px;
                        border: solid 2px #dce6f0;
                    }

                    .checkmark1:after {
                        width: 5px;
                        height: 9px;
                        border-width: 0 2px 2px 0;
                        content: none;
                    }

                    input:checked~.checkmark1 {
                        background-color: $primary-color;
                        border: none;
                    }

                    input:checked~.checkmark1:after {
                        width: 5px;
                        height: 9px;
                        border-width: 0 2px 2px 0;
                        content: "";
                    }
                }
            }
        }
    }

    .sfm-body {
        display: block;
        padding: 10px 40px;
        overflow: auto;
        max-height: 350px;

        .sfmb-filter-row {
            display: table;
            width: 100%;

            .sfmbfr-filter {
                display: block;
                width: 50%;
                float: left;
                padding-right: 15px;

                label {
                    font-size: 12px;
                    font-weight: 500;
                    color: #3a5074;
                }

                .gen-radio-btn {
                    margin: 0;
                    font-size: 13px;
                    font-weight: 500;
                    color: #12203c;
                    padding: 0 0 5px 25px;
                    cursor: pointer !important;
                    position: relative;
                    white-space: nowrap;

                    input[type="radio"] {
                        display: none;
                    }

                    .checkmark {
                        content: "";
                        position: absolute;
                        top: 2px;
                        left: 0;
                        height: 15px;
                        width: 15px;
                        border: 2px solid #D6DCE6;
                        border-radius: 50%;
                    }

                    .checkmark:before {
                        content: "";
                        position: absolute;
                        top: 3px;
                        left: 3px;
                        width: 5px;
                        height: 5px;
                        display: block;
                        border-radius: 50%;
                        background: #D6DCE6;
                    }

                    input:checked~.checkmark {
                        background-color: #fff;
                        border: 2px solid $primary-color;
                    }

                    input:checked~.checkmark:before {
                        background: $primary-color;
                    }

                    input:checked~.checkeddisabled {
                        border: 2px solid #5D5FEF8c;
                    }

                    input:checked~.checkeddisabled:before {
                        background: #5D5FEF8c;
                    }
                }

                input {
                    display: block;
                    border-radius: 5px;
                    border: 1px solid #ccd8ed;
                    background-color: #fff;
                    height: 30px;
                    width: 100%;
                    text-align: left;
                    font-weight: 600;
                    font-size: 12px;
                    padding: 4px 7px;
                    color: #12203c;
                }

                input:focus {
                    border: 1px solid $primary-color;
                    box-shadow: 0px 0px 0px 2px #DADAFF;
                }

                select {
                    display: block;
                    border-radius: 5px;
                    border: 1px solid #ccd8ed;
                    background-color: #fff;
                    height: 30px;
                    width: 100%;
                    text-align: left;
                    font-weight: 500;
                    font-size: 12px;
                    background-image: url("../../assets/icons/downArrowNew.svg");
                    background-position: 96%;
                    background-repeat: no-repeat;
                    padding: 4px 23px 4px 7px;
                    -webkit-appearance: none;
                    color: #12203c;
                }

                .sfmbfr-disabled {
                    // background: #f3f5fb;
                    background-color: #f2f4f8;
                }
            }
        }

        .sfmb-selected-filter {
            display: block;
            margin-top: 20px;

            h3 {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 10px;
            }

            .sfmbsf-box {
                border: 1px dashed #c1d8f0;
                padding: 10px 12px 0;
                border-radius: 7px;

                .sfmbsfb-fil {
                    display: inline-block;
                    align-items: center;
                    padding: 7px 15px;
                    background: #f2f5f8;
                    border-radius: 24px;
                    margin-right: 13px;
                    margin-bottom: 10px;

                    .sfmbsfb-name {
                        display: inline-block;
                        font-size: 12px;
                        font-weight: 500;
                        padding-right: 8px;
                        color: #5677b1;
                    }

                    .sfmbsfb-num {
                        display: inline-block;
                        font-size: 12px;
                        font-weight: 600;
                        color: #5677b1;
                        padding-left: 8px;
                        border-left: 1px solid #c1d8f0;
                    }
                }
            }
        }

        .sfmb-existing-filter {
            display: block;
            margin-top: 20px;

            h3 {
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 10px;
            }

            .sfmbsf-box {
                border: 1px dashed #c1d8f0;
                padding: 10px 12px 0;
                border-radius: 7px;

                .sfmbsfb-fil {
                    display: inline-block;
                    align-items: center;
                    padding: 7px 15px;
                    background: #fffae7;
                    border-radius: 24px;
                    margin-right: 13px;
                    margin-bottom: 10px;

                    .sfmbsfb-name {
                        display: inline-block;
                        font-size: 12px;
                        font-weight: 500;
                        padding-right: 8px;
                        color: #d9ca8b;
                    }

                    .sfmbsfb-num {
                        display: inline-block;
                        font-size: 12px;
                        font-weight: 600;
                        color: #c19c01;
                        padding-left: 8px;
                        border-left: solid 1px #d9ca8b;
                    }
                }
            }
        }

        .sfmh-bottom {
            display: flex;
            align-items: center;
            width: 100%;

            img {
                margin-right: 5px;
            }

            .warning {
                font-size: 12px;
                font-weight: 500;
                color: #12203c;
                margin: 0;
            }
        }
    }

    .sf-delete-filter {
        position: relative;
        display: block;
        padding: 10px 40px;

        .sfdf-inner {
            border-bottom: 1px solid #c1d8f070;
            overflow: auto;
            min-height: 349px;
            max-height: 350px;
        }

        .checkBoxLabel0 {
            width: auto;
            height: 34px;
            font-size: 12px;
            font-weight: 500;
            margin: 0;
            padding-left: 30px;
            cursor: pointer !important;
            display: block !important;
            margin-bottom: 10px;

            .checkmark1 {
                left: 0;
                top: 9px;
                height: 16px;
                width: 16px;
                background-color: #ffffff;
                cursor: pointer;
                border-radius: 4px;
                border: solid 2px #dce6f0;
            }

            .checkmark1:after {
                width: 5px;
                height: 9px;
                border-width: 0 2px 2px 0;
                content: none;
            }

            input:checked~.checkmark1 {
                background-color: $primary-color;
                border: none;
            }

            input:checked~.checkmark1:after {
                width: 5px;
                height: 9px;
                border-width: 0 2px 2px 0;
                content: "";
            }

            .sfdf-text {
                height: 34px;
                display: flex;
                align-items: center;
                width: 240px;
                background: #f2f4f8;
                padding: 3px 10px;
                border-radius: 5px;
                color: #6774b7;
            }
        }
    }

    .sfm-footer {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        padding-bottom: 22px;
        padding-top: 10px;
        background-color: #ffffff;

        button {
            font-size: 12px;
            font-weight: 600 !important;
            padding: 5px 30px !important;
            background-color: $primary-color !important;
            border: solid 1px $primary-color !important;
            color: #fff;
            border-radius: 5px;
            height: 34px;
            margin: 0 !important;
            display: inline-block !important;
        }

        .sfmf-cancel {
            border-radius: 0;
            border: none !important;
            background-color: #fff !important;
            font-size: 12px;
            padding: 3px 8px !important;
            margin-left: 15 !important;
            height: 34px;
            font-weight: 600;
            position: relative;
            display: inline-block !important;
            color: #12203c;
        }
    }
}

// Style Invoice Management head
.invoice-manage-head {
    display: flex;
    position: relative;
    width: 100%;
    height: 67px;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dce6f0;

    .imh-left {
        display: flex;
        align-items: center;
        justify-content: start;
        position: relative;

        .imhl-search {
            position: relative;
            display: inline-flex;
            margin-right: 15px;

            form {
                margin: 0;
            }

            input[type="search"] {
                border-radius: 5px;
                border: solid 1px #dce6f0;
                background: #fff;
                padding: 8px 30px;
                height: 32px;
                font-size: 11px;
                color: #000;
                font-weight: 600;
                width: 250px;
            }

            input:focus {
                border: solid 1px #cdc4ff;
            }

            input::placeholder {
                font-size: 11px;
                font-weight: 500;
                color: #12203c;
            }

            .search-image {
                position: absolute;
                top: 8px;
                left: 7px;
                width: 15px;
            }

            .closeSearch {
                position: absolute;
                right: 10px;
                top: 10px;
                cursor: pointer;
            }

            svg {
                position: absolute;
                top: 9px;
                left: 8px;
            }

            .custom-search-btn {
                display: inline-flex;
                justify-content: center;
                align-items: center;

                svg {
                    position: static;
                }
            }
        }

        button {
            border-radius: 5px;
            border: solid 1px #ccd8ed;
            background-color: #ffffff;
            font-size: 12px;
            padding: 5px 15px;
            margin-right: 10px;
            height: 32px;
            font-weight: 500;
            position: relative;
            display: inline-block;
        }

        .drop-toggle-btn {
            display: block;
            position: relative;
            margin-right: 15px;

            .dtb-show {
                height: 32px;
                font-size: 12px;
                background-color: #ffffff;
                font-weight: 600;
                color: $primary-color;
                border: 2px solid $primary-color;
                padding: 5px 8px;
                border-radius: 4px;
                min-width: 150px;
                margin: 0px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                i {
                    margin-left: 15px;
                }
            }

            .dtb-hide {
                height: 32px;
                font-size: 12px;
                visibility: hidden;
                opacity: 0;
                position: absolute;
                top: 40px;
                left: 0;
                color: #12203c;
                font-weight: 600;
                height: 34px;
                width: 100%;
                padding: 5px 8px;
                margin: 0;
                border: none;
                text-align: left;
                box-shadow: 0 2px 6px 0 rgba(75, 75, 75, .16);
                transition: 0.6s all;
                z-index: 2;

                i {
                    display: none;
                }
            }

            .dtb-hide:hover {
                background-color: #e5edf8;
            }

            .dtb-dropdown {
                visibility: hidden;
                opacity: 0;
                position: absolute;
                top: 40px;
                left: 0;
                height: auto;
                width: 100%;
                background-color: #fff;
                padding: 5px 0;
                border: none;
                text-align: left;
                box-shadow: 0 2px 6px 0 rgba(75, 75, 75, .16);
                transition: 0.6s all;
                z-index: 10;

                .dtb-hide {
                    position: relative;
                    top: auto;
                    left: auto;
                    display: block;
                    height: auto;
                    box-shadow: none;
                    z-index: auto;
                    font-weight: 500;
                    border-radius: 0px;
                    background-color: #fff;
                }

                .dtb-show {
                    display: none;
                }
            }
        }

        .drop-toggle-btn:hover {
            .dtb-hide {
                transition: 0.6s all;
                visibility: visible;
                opacity: 1;
            }

            .dtb-dropdown {
                transition: 0.6s all;
                visibility: visible;
                opacity: 1;
            }

            .dtb-hide:hover {
                background-color: #e5edf8;
                font-weight: 600;
            }
        }

        .imhlsbo-btn {
            padding: 3px 10px;
            font-weight: 600;
            color: #21314b;

            .imhlsbo-sho {
                font-size: 11px;
                color: #8496a9;
            }

            .imhlsbo-arrow {
                margin-left: 12px;
            }
        }
    }

    .imh-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .create-dc {
            background-color: $primary-color;
            color: #fff;
            font-weight: 600;
            border: 1px solid $primary-color;
            padding: 5px 12px;
        }

        .approve-btn {
            .smbh-approve {
                color: #fff;
                border-radius: 4px;
                background-color: $primary-color;
            }
        }

        .buy-reject-btn {
            button {
                border: solid 1px #f5f5f5;
                background-color: #f5f5f5;
                color: #21314b;
                font-weight: 600;
            }
        }

        button {
            border: 1px solid #ccd8ed;
            background-color: #fff;
            font-size: 12px;
            padding: 5px 15px;
            margin-left: 10px;
            height: 32px;
            font-weight: 500;
            position: relative;
            display: inline-block;
        }

        button:hover {
            .generic-tooltip {
                visibility: visible;
                opacity: 1;
            }
        }

        .aprv-req-btn {
            color: #ffffff;
            background-color: $primary-color;
            border: 1px solid $primary-color;
            position: relative;
        }

        .indent-dropdown {
            position: absolute;
            top: 55px;
            right: 237px;
            height: auto;
            background-color: #fff;
            padding: 0;
            border: none;
            text-align: left;
            box-shadow: 0 1px 11px -4px #9ea0a1e6;
            transition: all .6s;
            z-index: 2;
            border-radius: 3px;

            li {
                border-bottom: 1px solid #dce6f0;
                padding: 7px 10px;

                .add-blank {
                    font-size: 12px;
                    font-weight: 500;
                    color: #12203c;
                }
            }

            li:hover {
                background-color: #d8daf599;
                cursor: pointer;
            }
        }

        .reject-btn {
            border: solid 1px #E66767;
            background-color: #E66767;
            color: #fff;
        }

        .gen-reject {
            border: solid 1px #E66767;
            background-color: #ffffff;
            color: #E66767;
            font-weight: 600;
        }

        .new-trigger {
            color: #fff;
            background: $primary-color;
            border: solid 1px $primary-color;
            box-shadow: none;
            padding: 6px 8px;

            svg {
                margin-right: 5px;
            }
        }

        .gvpd-download-drop {
            display: inline-block;
            position: relative;

            .pi-history-download {
                white-space: nowrap;
                display: block;
                position: absolute;
                top: 42px;
                right: 0;
                width: 220px;
                padding: 10px 0;
                border-radius: 12px;
                box-shadow: 0 -1px 6px 0 rgba(177, 177, 177, 0.16);
                background: #ffffff;
                margin: 0;
                z-index: 9;

                li {
                    display: block;

                    button {
                        display: flex;
                        padding: 5px 10px;
                        margin: 0;
                        border: none;
                        width: 100%;
                        height: auto;
                        text-align: left;
                        white-space: nowrap;
                        border-radius: 0;
                        transition: all .6s;
                        align-items: center;

                        img {
                            width: 17px;
                            // margin-right: 12px;
                        }

                        span {
                            display: inline-block;
                            padding: 7px 0px;
                            border-radius: 50%;
                            height: 32px;
                            width: 32px;
                            text-align: center;
                            margin-right: 10px;
                        }

                        // .pipdf-text {
                        //     border-radius: 0;
                        //     max-width: 100px;
                        //     text-align: left;
                        //     width: auto;
                        //     text-overflow: ellipsis;
                        //     overflow: hidden;
                        //     margin: 0;
                        // }

                        .pi-pdf-img {
                            width: 20px;
                            margin-left: 8px;
                        }

                        div {
                            display: inline-block;

                            .multipleDownloadSelect {
                                border: none;
                                padding: 0;
                                -webkit-appearance: none;
                                background: #fff !important;
                            }
                        }

                        a {
                            font-size: 10px;
                            font-weight: 600;
                            background: transparent;
                            color: #21314b;
                            padding: 3px 8px;
                            border-radius: 50px;
                            text-align: right;
                            position: absolute;
                            top: 11px;
                            right: 10px;
                            text-decoration: none;
                        }

                        a:hover {
                            color: #21314b;
                        }
                    }

                    .export-excel {
                        color: #a4b9dd;

                        span {
                            margin-right: 10px;
                        }
                    }

                    .export-excel:hover {
                        color: #12203c;

                        span {
                            background-color: #049d4b14;

                            svg {
                                path {
                                    fill: #049d4b;
                                }

                                text {
                                    fill: #049d4b;
                                }
                            }
                        }
                    }

                    .pi-pdf-download {
                        // .pipdf-barcode {
                        //     padding: 10px 0px;
                        // }

                        .btnDisabled {
                            color: #a4b9dd !important;
                        }
                    }

                    .pi-pdf-download:hover {
                        background: #ebf2fa;
                        transition: 0.6s all;

                        .pi-pdf-svg {
                            background-color: #eb000817;

                            svg {
                                path {
                                    fill: #eb0008;
                                }

                                text {
                                    fill: #eb0008;
                                }
                            }
                        }

                        // .pipdf-barcode {
                        //     background-color: #3279dd1f;

                        //     svg {
                        //         path {
                        //             fill: #3279dd;
                        //         }
                        //     }
                        // }

                        // .pipdf-invoice {
                        //     background-color: #e2c80017;

                        //     svg {
                        //         path {
                        //             fill: #e2c800;
                        //         }
                        //     }
                        // }

                        // .pipdf-packing {
                        //     background-color: #00ced617;

                        //     svg {
                        //         path {
                        //             fill: #00ced6;
                        //         }
                        //     }
                        // }

                        a {
                            color: #fff;
                        }

                        // .pipd-pipdf {
                        //     background-color: #eb0008;
                        // }

                        // .pipd-barcode {
                        //     background-color: #3279dd;
                        // }

                        // .pipd-invoice {
                        //     background-color: #e2c800;
                        // }

                        // .pipd-packing {
                        //     background-color: #00ced6;
                        // }
                    }
                }
            }

            .pihd-v2 {
                width: 240px;

                // li button .pipdf-text {
                //     max-width: 120px;
                // }
            }
        }

        .pi-download,
        .pi-download2 {
            width: 32px;
            padding: 0;
        }

        .pi-download2 {
            border: 1px solid #d8d3d3;

            svg {
                path {
                    fill: #d8d3d3;
                }
            }
        }

        .pi-download:hover {
            color: #fff;
            background-color: #4a5568;
            border: 1px solid #4a5568;
            transition: 0.3s all;

            svg path {
                fill: #fff;
            }
        }

        .pi-download-focus {
            color: #fff;
            background-color: #4a5568;
            border: 1px solid #4a5568;

            svg path {
                fill: #fff;
            }
        }

        .gvpd-filter {
            position: relative;

            .gvpd-filter-inner {
                width: 32px;
                padding: 0;
            }

            .gvpd-filter-inner:hover {
                color: #fff;
                background-color: #4a5568;
                border: 1px solid #4a5568;
                transition: 0.3s all;

                svg path {
                    fill: #fff;
                }
            }

            .gvpd-filter-inner-focus {
                color: #fff;
                background-color: #4a5568;
                border: 1px solid #4a5568;
                transition: 0.3s all;

                svg path {
                    fill: #fff;
                }
            }

            .noOfFilter {
                position: absolute;
                right: -9px;
                top: -7px;
                background-color: #eb0008;
                border-radius: 50%;
                padding: 2px 0;
                font-size: 10px;
                width: 17px;
                height: 17px;
                text-align: center;
                color: #fff;
            }
        }

        .excel-export {
            // border-radius: 25px;
            display: flex;
            align-items: center;
            background-color: #00ced6;
            color: #fff;
            border: 1px solid #00ced6;
            padding: 5px 15px 5px 7px;

            span {
                padding: 5px;
                border-radius: 50%;
                background-color: #28d6dd;
                width: 20px;
                height: 20px;
                display: inline-block;
                margin-right: 10px;
            }
        }

        .gen-cancel {
            border: solid 1px #E66767;
            background-color: #E66767;
            color: #fff;
            font-weight: 600;
        }

        .gen-approved {
            border: 1px solid $primary-color;
            background-color: $primary-color;
            color: #fff;
            padding: 5px 10px;
            font-weight: 600;

            img {
                margin-right: 7px;
                width: 17px;
            }

            .gf-start-icon {
                margin-right: 7px;
            }
        }

        .add-btn {
            display: inline-block;
            position: relative;
            cursor: pointer;
            border: none;
            padding: 0px;
            background-color: transparent;
            border-radius: 50%;

            img {
                width: 27px;
            }

            .generic-tooltip {
                top: -30px;
                right: 50%;
                transform: translate(15%);
                width: 120px;
                left: auto;
            }

            .generic-tooltip:after {
                right: 10%;
                left: auto;
            }
        }

        .add-btn:hover>.generic-tooltip {
            visibility: visible;
            opacity: 1;
        }

        .get-details {
            border: solid 1px $primary-color;
            background-color: $primary-color;
            padding: 5px 25px;
            color: #ffffff;
        }

        .gvpd-three-dot-btn {
            position: relative;
            display: inline-block;

            .gvpdtdb-btn {
                border: none;
                padding: 0 8px;

                span {
                    position: relative;
                    display: inline-block;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: #000000;
                }

                span:before,
                span:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    display: inline-block;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: #000000;
                }

                span:before {
                    left: -8px;
                }

                span:after {
                    right: -8px;
                }
            }

            .gvpdtd-menu {
                position: absolute;
                top: 45px;
                display: block;
                right: 0;
                background: #fff;
                z-index: 5;
                box-shadow: 0 2px 6px 0 rgba(75, 75, 75, 0.16);
                border-radius: 6px;
                width: 170px;

                .gvpdtdm-inner {
                    padding: 0;
                    margin: 0;

                    li {
                        button {
                            display: block;
                            margin: 0;
                            border: none;
                            padding: 6px 20px;
                            width: 100%;
                            text-align: left;
                            color: #12203c;
                            border-radius: 0;
                        }

                        button:hover {
                            background-color: #e5edf8;
                            font-weight: 600;
                        }

                        .gvpdtdm-cancel {
                            color: #eb0008;
                        }

                        .btnDisabled {
                            background-color: #ffffff !important;
                            border: none !important;
                        }
                    }
                }
            }
        }
    }
}

.mbcr-summary {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0;

    .gib-label-heading {
        margin: 0;
        white-space: nowrap;
        width: 75%;
    }

    .gib-inner {
        input {
            cursor: pointer;
        }
    }

    .list-component-section {
        top: 34px;
        right: 0;
        left: auto;
        z-index: 5;
        min-width: 59%;
    }
}

// New table loader
.manipulate-loader-css {
    padding: 10px 15px 0 30px;
}

.new-table-loader {
    background-color: #fff;
    border: 1px solid #DAE4EE;
    border-right: none;
    border-radius: 6px;

    .imt-main-table {
        margin: 0;
        width: 100%;

        thead {
            tr {
                th {
                    border-bottom: 1px solid #DAE4EE !important;
                    border-right: 1px solid #DAE4EE !important;
                    height: 35px;
                    padding: 5px 8px 0;

                    label {
                        margin: 0;
                    }

                    .dummy-head {
                        width: 100% !important;
                        height: 13px;
                        position: relative;
                        background: linear-gradient(100deg,
                                rgba(255, 255, 255, 0) 40%,
                                rgba(255, 255, 255, .5) 50%,
                                rgba(255, 255, 255, 0) 60%) #ebebf3;
                        background-size: 200% 100%;
                        background-position-x: 180%;
                        animation: 1s loadskeleton ease-in-out infinite;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    height: 35px !important;
                    border-top: none !important;
                    border-left: none !important;
                    border-right: 1px solid #DAE4EE !important;
                    border-bottom: 1px solid #DAE4EE !important;
                    padding: 0 8px !important;

                    label {
                        margin: 0;
                    }

                    .dummy-skeleton {
                        width: 100%;
                        height: 13px;
                        position: relative;
                        background: linear-gradient(100deg,
                                rgba(255, 255, 255, 0) 40%,
                                rgba(255, 255, 255, .5) 50%,
                                rgba(255, 255, 255, 0) 60%) #ebebf3;
                        background-size: 200% 100%;
                        background-position-x: 180%;
                        animation: 1s loadskeleton ease-in-out infinite;
                    }

                    .dummy-skeleton-first {
                        width: 20px;
                        height: 20px;
                        background-color: #ebebf3;
                        position: relative;
                        background: linear-gradient(100deg,
                                rgba(255, 255, 255, 0) 40%,
                                rgba(255, 255, 255, .5) 50%,
                                rgba(255, 255, 255, 0) 60%) #ebebf3;
                        background-size: 200% 100%;
                        background-position-x: 180%;
                        animation: 1s loadskeleton ease-in-out infinite;
                    }

                    .ds-first {
                        width: 25px;
                        height: 13px;
                        background-color: #ebebf3;
                        position: relative;
                        background: linear-gradient(100deg,
                                rgba(255, 255, 255, 0) 40%,
                                rgba(255, 255, 255, .5) 50%,
                                rgba(255, 255, 255, 0) 60%) #ebebf3;
                        background-size: 200% 100%;
                        background-position-x: 180%;
                        animation: 1s loadskeleton ease-in-out infinite;
                    }
                }

                td:nth-child(1) {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

.table-loader-bg {
    padding: 10px 15px 0 30px;
    background-color: transparent;

    .table-loader-modal {
        display: block;
        width: 100%;
        height: 350px;
        box-shadow: 2px 0 6px 0 hsla(0, 0%, 55%, .16);
        position: relative;
        background: #fff;
        position: relative;
        border-radius: 6px;

        .tlm-head {
            display: block;
            width: 100%;
            height: 50px;
        }

        .tlm-body {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 245px;

            .tlmb-loader {
                display: block;
                position: relative;
                text-align: center;

                .tlmbsm-load-static {
                    display: inline-block;
                    border: 3px solid #dce2ec;
                    border-radius: 50%;
                    width: 34px;
                    height: 34px;
                    padding: 5px;

                    .tlmbsml-inner {
                        display: inline-block;
                        width: 18px;
                        height: 18px;
                        cursor: pointer;
                    }
                }

                .tlmbsm-load {
                    display: inline-block;
                    border: 3px solid #dce2ec;
                    border-radius: 50%;
                    width: 34px;
                    height: 34px;
                    padding: 5px;
                    -webkit-animation: spin 2s linear infinite;
                    /* Safari */
                    animation: spin 1s linear infinite;

                    .tlmbsml-inner {
                        display: inline-block;
                        border: 2px solid #99a4cb;
                        border-radius: 50%;
                        border-top: 2px solid #ffffff;
                        width: 18px;
                        height: 18px;
                    }
                }

                p {
                    font-size: 12px;
                    font-weight: 500;
                    color: #99a4cb;
                    margin: 0;
                    margin-top: 10px;
                }
            }
        }

        .tlm-footer {
            .new-gen-pagination {
                .ngp-left {
                    .table-page-no {
                        position: relative;

                        .paginationBorder {
                            height: 38px;
                        }

                        .tlm-sm-load {
                            border: 2px solid #99a4cb;
                            border-radius: 50%;
                            border-top: 3px solid white;
                            width: 18px;
                            height: 18px;
                            -webkit-animation: spin 2s linear infinite;
                            animation: spin 1s linear infinite;
                            display: inline-block;
                            position: absolute;
                            top: 10px;
                            left: 65px;
                        }
                    }
                }

                .ngp-right .nt-btn .pagination-inner .pagination-item li .pi-number-btn {
                    border: 2px solid #99a4cb;
                    border-radius: 50%;
                    border-top: 3px solid #fff;
                    width: 18px;
                    height: 18px;
                    animation: spin 1s linear infinite;
                    display: inline-block;
                    min-width: auto;

                    span {
                        display: none;
                    }
                }
            }
        }
    }
}

.chatMain {
    position: absolute;

    .table-chat-box:nth-child(2) {
        right: 396px;
    }

    .table-chat-box:nth-child(3) {
        right: 717px;
    }
}

// Style Confirmation Modal
.new-confirmation-modal {
    width: 36vw;
    max-width: 500px;
    max-height: 250px;
    height: fit-content;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    background: #fff;
    overflow: hidden;
    border: none;
    border-radius: 4px;

    .ncm-top {
        display: block;
        padding: 10px 20px;

        .ncmt-head {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: 10px 0;

            .ncmt-img {
                padding: 0;
                border-radius: 50%;

                // background: #f6ecfd;
                img {
                    width: 40px;
                }
            }

            .ncmt-close {
                button {
                    padding: 5px;
                    background-color: #fff;
                }
            }
        }

        .ncmt-body {
            display: block;

            h2 {
                font-size: 14px;
                font-weight: 700;
                margin-top: 10px;
                font-family: Poppins;
                font-style: normal;
            }

            p {
                font-size: 12px;
                font-weight: 500;
                margin: 5px 0;
                color: #97abbf;
                font-family: Poppins;
                font-style: normal;
            }
        }
    }

    .ncm-footer {
        display: block;
        margin-top: 30px;
        padding: 15px 30px;
        background-color: #f6f4ff;
        text-align: right;

        button {
            border-radius: 5px;
            border: 1px solid #627da8;
            background: #fff;
            padding: 3px 25px;
            height: 32px;
            font-size: 12px;
            color: #627da8;
            font-weight: 600;
            font-family: Poppins;
            font-style: normal;
        }

        .ncm-yes {
            background-color: $primary-color;
            border: 1px solid $primary-color;
            color: #fff;
            margin-left: 20px;
        }
    }
}


.poStatus {
    display: inline-block;
    position: relative;
    margin-left: 5px;
    // cursor: pointer;

    .each-tag {
        margin: 5px 0px;

        label {
            font-size: 13px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.23;
            letter-spacing: normal;
            text-align: center;
            color: #12203c !important;
            margin-left: 5px !important;
        }
    }

    &:hover {
        .poStatusToolTip {
            display: block;
        }
    }

    .partiaTag {
        font-size: 11px;
        font-weight: 600;
        line-height: 1.28;
        letter-spacing: normal;
        text-align: center;
        // color: #ff4602;
        color: #FFB142;
        border-radius: 3px;
        // background-color: #ffd5c5;
        // border: 1.5px solid #ff4602;
        border: 1.5px solid #FFB142;
        margin: 0;
        padding: 0px 3.5px;
        margin-right: 5px;
        cursor: auto;
    }

    .setTag {
        font-size: 11px;
        font-weight: 600;
        line-height: 1.28;
        text-align: center;
        // color: #ffcf03;
        color: #1B9CFC;
        margin: 0;
        padding: 0px 3.5px;
        border-radius: 3px;
        // background-color: #fff6d6;
        // border: 1.5px solid #ffcf03;
        border: 1.5px solid #1B9CFC;
        margin-right: 6px;
        cursor: auto;
    }

    .nonSetTag {
        color: #FF793F;
        border: 1.5px solid #FF793F;
        padding: 0px 3px;
        cursor: auto;
    }

    .poStatusToolTip {
        position: absolute;
        display: none;
        right: -138px;
        width: 131px;
        height: auto;
        border-radius: 6px;
        box-shadow: 0 1px 6px 0 rgba(170, 170, 170, 0.16);
        background-color: #ffffff;
        z-index: 2;
        top: 50%;
        padding: 2px 10px;
        transform: translate(0%, -50%);

        &:after {
            content: " ";
            position: absolute;
            top: 50%;
            right: 100%;
            margin-top: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent #fff transparent transparent;
        }

        .each-status {
            margin: 5px 0px;

            label {
                font-size: 13px;
                font-weight: 400;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.23;
                letter-spacing: normal;
                text-align: center;
                color: #12203c !important;
                margin-left: 10px;
            }
        }
    }
}

// Style New Table Comment Box
.table-chat-box {
    // display: block;
    // position: fixed;
    top: 0;
    right: 0;
    // z-index: 999;
    width: 100%;
    height: 100%;
}

.table-chat-box-inner {
    display: block;
    width: 40%;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-color: #fff;
    z-index: 99;
    animation: slideInFromRight 0.3s ease-out;

    .tcb-head {
        width: 100%;
        height: 28%;
        display: block;

        .tcb-head-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 20px;
            border-bottom: 1px solid #ccd8ed;

            h3 {
                font-size: 16px;
                font-weight: 600;
                color: #12203c;
                display: inline-block;
                margin: 0;
                line-height: 25px;
            }

            .tcbh-close {
                position: relative;
                display: flex;
                align-items: center;

                .tcbh-esc {
                    font-size: 12px;
                    font-weight: 500;
                    color: #969fad;
                    position: relative;
                    margin-right: 12px;
                }

                .tcbh-esc:after {
                    content: "";
                    position: absolute;
                    top: 4px;
                    right: -6px;
                    width: 1px;
                    height: 10px;
                    background: #969fad;
                }
            }
        }

        .tcb-head-bottom {
            display: flex;
            padding: 10px 20px;
            border-bottom: 1px solid #ccd8ed;
            background: #fff;
            align-items: center;
            background: #ffffff;

            .tcb-name {
                width: 100%;

                p {
                    font-weight: 500;
                    font-size: 11px;
                    color: #a4b9dd;
                    margin: 0;
                }

                span {
                    position: relative;
                    font-size: 13px;
                    font-weight: 600;
                    color: #12203c;
                }
            }

            .tcb-hb-search-box {
                display: block;
                position: relative;
                margin-top: 15px;

                input {
                    border-radius: 6px;
                    border: solid 1px #cbd5e0;
                    background-color: #ffffff;
                    width: 100%;
                    font-size: 12px;
                    font-weight: 500 !important;
                    height: 30px;
                    padding: 5px 30px 5px 10px;
                    color: #12203c;
                }

                input::placeholder {
                    font-size: 12px;
                    font-weight: 500;
                    color: #12203c;
                }

                span,
                button {
                    position: absolute;
                    right: 8px;
                    top: 7px;
                    cursor: pointer;
                }

                button {
                    height: 100%;
                    padding: 5px 7px;
                    top: 0;
                    right: 5px;
                }
            }
        }
    }

    .tcb-body {
        width: 100%;
        height: 45%;
        position: relative;

        .tch-msg-attachment {
            display: block;
            align-items: center;
            justify-content: flex-end;
            height: 13%;
            width: 100%;
            padding: 8px 13px;
            text-align: right;
            top: 7px;
            right: 10px;
            z-index: 99;

            span {
                display: inline-block;
                padding-left: 10px;

                img {
                    width: 18px;
                }
            }
        }

        .tcb-messages-scroller {
            position: relative;
            overflow-y: auto;
        }

        .tcb-messages {
            position: relative;
            padding: 15px 30px;
            width: 100%;
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;

            .msg-reciver {
                width: 100%;
                display: block;
                height: 100%;
            }
        }

        .tcb-messages-scroller>div {
            display: flex;
            flex-direction: column;
            position: absolute;
            width: 100%;
            min-height: 100%;
        }

        .tcb-spaser {
            flex: 1 1 auto;
        }

        .tcb-messages {
            flex: 0 1 auto;
        }

        .messageReceiver {
            display: block;
            max-width: 95%;
            width: auto;

            .each-comment {
                display: block;
                min-height: 28px;
            }

            .msgBg {
                position: relative;
                display: inline-block;

                .msg-user-icon {
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                .msg-user-icon-text {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color: #ffb103;
                    color: #ffffff;
                    font-size: 12px;
                    text-align: center;
                    font-weight: 600;
                    padding: 6px 0;
                }

                .tcb-file {
                    display: flex;
                    align-items: center;
                    min-height: 28px;
                    margin-left: 45px;
                    padding: 2px 4px 2px 6px;
                    border-radius: 6px;
                    background: #ffffff;
                    border: 1px solid #d1e2f4;

                    .msg-name-pdf {
                        font-size: 10px;
                        color: #12203c;
                        font-weight: 600;
                        border-radius: 3px;
                        background-color: #ffffff;
                        padding: 3px 4px;
                        margin-right: 4px;

                        img {
                            width: 21px;
                        }
                    }

                    .fileNameQc {
                        font-size: 12px;
                        font-weight: 600;
                        color: #12203c;
                    }

                    .msg-pdfdownload-link {
                        width: 12px;
                        cursor: pointer;
                        margin-left: 15px;
                    }

                    .msg-clear-msg {
                        display: inline-block;
                        margin-left: 10px;
                        padding: 5px;
                        cursor: pointer;
                    }
                }

                label {
                    margin-left: 45px;
                    display: flex !important;
                    margin-bottom: 0;
                    align-items: baseline;
                    min-height: 16px;
                    font-size: 12px;
                    font-weight: 500;

                    pre {
                        font-size: 12px;
                        font-weight: 500;
                        background: transparent;
                        border: none;
                        padding: 0;
                        margin: 0;

                    }

                    .mention-user {
                        border-radius: 4px;
                        background-color: #3ee58547;
                        padding: 2px 3px;
                        font-size: 11px;
                        font-weight: 600;
                        margin: 0 5px;
                        color: #00a546;
                        display: inline-block;
                    }
                }
            }

            .dtof-msg {
                display: block;
                font-size: 9px;
                font-weight: 500;
                color: #a4b9dd;
            }

            .msg-user-detail {
                display: block;
                margin-left: 45px;
                margin-top: 5px;

                .msg-user-name {
                    font-size: 10px;
                    padding: 1px 6px;
                    color: #be8300;
                    background: #be83001f;
                    border-radius: 3px;
                    font-weight: 600;
                    margin-right: 10px;
                }

                .dtof-msg {
                    display: inline-block;
                    font-size: 9px;
                    font-weight: 500;
                    color: #a4b9dd;
                }
            }
        }

        .messageSender {
            max-width: 95%;
            display: block;
            width: auto;

            .each-comment {
                display: block;
                min-height: 28px;
            }

            .msgBg {
                position: relative;
                display: inline-block;

                .msg-user-icon {
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                .msg-user-icon-text {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color: #23e3eb;
                    color: #ffffff;
                    font-size: 12px;
                    text-align: center;
                    font-weight: 600;
                    padding: 6px 0;
                }

                .tcb-file {
                    display: flex;
                    align-items: center;
                    min-height: 28px;
                    margin-left: 45px;
                    padding: 2px 4px 2px 6px;
                    border-radius: 6px;
                    background: #ffffff;
                    border: 1px solid #d1e2f4;

                    .msg-name-pdf {
                        font-size: 10px;
                        color: #12203c;
                        font-weight: 600;
                        border-radius: 3px;
                        background-color: #ffffff;
                        padding: 3px 4px;
                        margin-right: 4px;

                        img {
                            width: 21px;
                        }
                    }

                    .fileNameQc {
                        font-size: 12px;
                        font-weight: 600;
                        color: #12203c;
                    }

                    .msg-pdfdownload-link {
                        width: 12px;
                        cursor: pointer;
                        margin-left: 15px;
                    }

                    .msg-clear-msg {
                        display: inline-block;
                        margin-left: 10px;
                        padding: 5px;
                        cursor: pointer;
                    }
                }

                label {
                    margin-left: 45px;
                    display: flex !important;
                    margin-bottom: 0;
                    align-items: baseline;
                    min-height: 16px;
                    font-size: 12px;
                    font-weight: 500;

                    pre {
                        font-size: 12px;
                        font-weight: 500;
                        background: transparent;
                        border: none;
                        padding: 0;
                        margin: 0;

                    }

                    .mention-user {
                        border-radius: 4px;
                        background-color: #b7acfc38;
                        padding: 2px 3px;
                        font-size: 11px;
                        font-weight: 600;
                        margin: 0 5px;
                        color: #6196db;
                        display: inline-block;
                    }
                }
            }

            .msg-user-detail {
                display: block;
                margin-left: 45px;
                margin-top: 5px;

                .msg-user-name {
                    font-size: 10px;
                    padding: 1px 6px;
                    color: #23e3eb;
                    background: #23e3eb26;
                    border-radius: 3px;
                    font-weight: 600;
                    margin-right: 10px;
                }

                .dtof-msg {
                    display: inline-block;
                    font-size: 9px;
                    font-weight: 500;
                    color: #a4b9dd;
                }
            }
        }
    }

    .tcb-footer {
        position: relative;
        display: block;
        width: 100%;
        height: 27%;
        padding: 8px 30px;

        .tcb-footer-inner {
            display: flex;
            width: 100%;
            height: 100%;

            .tcb-footer-inner-item {
                width: 100%;
            }
        }

        .tcb-footer-write-comment-field {

            .tags,
            textarea {
                border: 0px;
            }

            textarea {
                position: absolute !important;
                left: -9999em !important;
                transform: scale(0) !important;
            }

            position: relative;
            width: 100%;
            border: solid 1px #ccd8ed;
            border-radius: 10px;
            height: 60%;

            .tagify.tagify--mix {
                .tagify__input {
                    display: block;
                    position: relative;
                    width: 100%;
                    border-radius: 10px;
                    border: none;
                    background-color: #fff;
                    height: 53px;
                    padding: 7px 10px;
                    font-size: 12px;
                    font-weight: 500 !important;
                    color: #12203c;
                    overflow: auto;
                    outline: none;

                    .tagify__tag.tagify--noAnim {
                        position: relative;
                        vertical-align: top;
                        --tag-bg: #ebff007a;
                        border-radius: 5px;
                        --tag-hover: #ebff007a;

                        .tagify__tag__removeBtn {
                            display: none;
                        }
                    }
                }
            }

            input[type="text"] {
                width: 100%;
                border-radius: 10px;
                border: none;
                background-color: #fff;
                height: 35px;
                padding: 0px 10px;
                font-size: 12px;
                font-weight: 500 !important;
                color: #12203c;
            }

            input:-internal-autofill-selected {
                background-color: #ffffff !important;
            }

            input::placeholder {
                font-size: 12px;
                font-weight: 500 !important;
                color: #12203c;
            }
        }

        .tcb-attach-img {
            img {
                position: absolute;
                top: 12px;
                cursor: pointer;
                right: 64px;
            }
        }

        .tcb-send-img {
            img {
                margin-left: 15px;
                cursor: pointer;
            }
        }

        .tcb-footer-attach-file {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 35px;
            border-top: 1px solid #ccd8ed;

            .tcbfaf-jumpnewline {
                display: inline-block;
                padding: 1px 6px;
                font-size: 11px;
                font-weight: 500;
                color: #a4b9dd;
                margin-right: 5px;
                margin-left: 5px;
                position: relative;

                .tcbfafj-lower {
                    display: block;
                    font-weight: 600;
                }
            }

            .tcbfaf-border:before {
                content: "";
                position: absolute;
                top: 10px;
                left: -6px;
                width: 1px;
                height: 16px;
                background-color: #a4b9dd;
            }

            .tcb-footer-attach-file-name {
                display: flex;
                position: absolute;
                top: 4px;
                right: 50px;
                height: 26px;
                vertical-align: middle;
                align-items: center;

                .tcb-fafn-icon {
                    display: inline-block;
                    margin-right: 10px;
                    cursor: pointer;
                    height: 26px;
                    width: 26px;
                    padding: 5px 7px;
                    border-radius: 50%;
                }

                .tcb-fafn-icon:hover {
                    background-color: #ff46021f;

                    svg path {
                        fill: #ff4602;
                    }
                }

                p {
                    display: inline-block;
                    margin: 0;
                    font-size: 11px;
                    font-weight: 500;
                    color: #12203c;
                    line-height: 26px;
                    max-width: 175px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .tcb-footer-attach-file-count {
                    display: inline-block;
                    margin-left: 10px;
                    padding: 5px 0;
                    color: #ff4602;
                    background-color: #ff46021f;
                    font-weight: 600;
                    font-size: 11px;
                    border-radius: 50%;
                    width: 26px;
                    height: 26px;
                    text-align: center;
                }
            }

            .writeComment {
                height: 35px;
                width: 35px;
                position: absolute;
                top: -1px;
                right: 0;
                display: inline-block;
                cursor: pointer;
                padding: 4px 8px;
                border-left: 1px solid #ccd8ed;

                .custom-file-Icon {
                    margin: 0;

                    img {
                        position: absolute;
                        top: 5px;
                        right: 3px;
                        border-radius: 50%;
                        padding: 4px;
                        cursor: pointer;
                    }

                    img:hover {
                        background: #ccd8ed;
                    }
                }
            }
        }

        span.tcb-send-img {
            display: inline-block;
            cursor: pointer;
        }

        .tcb-footer-addcomment {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            text-align: center;

            button {
                height: 34px;
                display: flex;
                align-items: center;
                font-size: 12px;
                background: $primary-color;
                color: #fff;
                font-weight: 600;
                border-radius: 5px;
                padding: 10px 15px;
                margin-right: 15px;
            }

            .tcb-footer-add-comment {
                svg {
                    margin-left: 10px;
                    vertical-align: top;
                }
            }

            .tcb-footer-email-tcranscript {
                background-color: #5677b1;

                svg {
                    margin: 0 7px 3px 0;
                }
            }
        }
    }
}

.table-chat-box.visible-chatbox {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
}

.table-chat-box.visible-chatbox.minimize-chatbox {
    display: flex;
    position: fixed;
    bottom: -324px;
    right: 70px;
    z-index: 999;
}

.eyeOrder {
    position: relative;
    top: 116px !important;
    left: 520px !important;
}

.eyeViewOrderPo {
    top: 11px !important;
    left: -79px !important;
}

.eyeOrderShip {
    top: 10px !important;
    left: -122px !important;
}

.filter-count {
    background-color: white;
    border: 1px solid #D6DBE9;
    margin-left: .5rem;
    padding: 0 .4rem;
}

.applied-filters-popup {
    max-width: 500px;
    line-height: 12px;
    font-size: 11px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: white;
    min-width: 400px;
    position: absolute;
    top: 100%;
    margin-top: .5rem;
    z-index: 40;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 30px;
    opacity: 0;
    pointer-events: none;
    transition: .3s;

    .afp-head {
        padding-inline: 1rem;
        padding-top: 1rem;

        h3 {
            font-weight: bolder;
            margin-bottom: .75rem;
            font-size: 14px;
        }

        p {
            font-style: italic;
            color: #525676;
            font-weight: 400;

        }
    }

    .afp-body {
        // max-height: 300px;
        max-height: 30vh;
        overflow-y: auto;
        padding-inline: 1rem;
        padding-bottom: 0.5rem;

        .filterDet {
            margin-top: 1rem;
            margin-bottom: .5rem;

            .filterData {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: .5rem;

                p,
                .afpbr-value {
                    padding: 0.25rem 0.5rem 0.25rem;
                    background-color: #F8F8FB;
                    font-weight: 400;
                    color: black;
                    font-weight: 500;
                    border: 1px solid #F0F2F5;
                    white-space: normal;
                    word-wrap: break-word;
                    max-width: 100%;
                }

                .clear-filter {
                    background-color: #f6f6f6;
                    border-radius: 50%;
                    cursor: pointer;
                    margin-left: 3px;
                    padding: 3px;

                    svg {
                        height: 12px;
                        width: 12px;
                    }
                }

                .clear-filter:hover {
                    background-color: $primary-color;

                    svg {
                        path {
                            fill: #fff;
                        }
                    }
                }
            }
        }
    }

    .afp-footer {
        border-top: 1px solid #DAE4EE;
        padding: .75rem;
        padding-inline: .5rem;
    }
}

.appliedFilterModalOpen {
    opacity: 1;
    pointer-events: auto;
}

// 

.barcode-search {
    .generic-input-box {
        margin-bottom: 0;
    }
}

// 
.table-component-new-style {
    .summaryHead {
        // padding-inline: 30px;
        width: calc(100% - 45px);
        margin-left: 30px;
        margin-top: 10px;
    }
}

.purchase-term-icon {
    cursor: pointer;
    margin-right: 3px;
}

.purchase-term-dropdown,
.purchase-term-dropdown-skeleton {
    position: absolute;
    top: 25px;
    left: 10px;
    width: auto;
    max-height: 200px;
    overflow: auto;
    height: auto;
    background-color: #fff;
    box-shadow: 2px 0 6px 0 rgba(139, 139, 139, 0.16);
    // visibility: hidden;
    // opacity: 0;
    z-index: 9;
    display: flex;
    flex-direction: row;
    padding: 0 10px;

    .ptd-inner {
        width: 100%;
        overflow: auto;
        display: flex;
        flex-direction: row;
        max-width: 440px;
    }

    .ptd-col {
        display: flex;
        flex-direction: column;
        border-right: solid 1px #DAE4EE;
        padding: 8px 12px;

        .ptd-main-head {
            padding: 1px 4px;
            color: #EE5A24;
            border: solid 1px #EE5A24;
            font-weight: 500;
            font-size: 12px;
        }

        .ptdmh-bot {
            margin-top: 8px;

            p {
                font-size: 10px;
                color: #8496A9;
                font-weight: 400;
                margin-bottom: 6px;
            }

            h3 {
                font-size: 12px;
                font-weight: 500;
                color: #0F172A;
                display: flex;
                align-items: center;
                margin: 0;

                svg {
                    path {
                        fill: #0F172A;
                    }
                }
            }
        }
    }

    .ptd-col:last-child {
        border-right: none;
        padding-right: 0;
    }

    .ptd-col:first-child {
        padding-left: 0;
    }
}

.purchase-term-icon:hover {

    .purchase-term-dropdown,
    .purchase-term-dropdown-skeleton {
        visibility: visible;
        opacity: 1;
    }
}

.purchase-term-dropdown-skeleton {
    min-width: 370px;
    min-height: 200px;

    .no-data-found-screen {
        padding: 0;

        .ndfs-inner {
            svg {
                width: 150px;
                height: auto;
            }
        }
    }
}

.vendor-product-tools {
    padding-right: 15px !important;

    .mep-heading-space-between {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

        .imh-ret-sup-radio {
            // position: fixed;
            // right: 25px;
            // top: 96px;
            // z-index: 7;

            .ant-radio-group {
                width: fit-content;
                height: 30px;
                display: flex;
                align-items: center;
                background-color: #ebebf3;
                border-radius: 5px;
                padding: 0 3px;

                span {
                    margin-right: 0;
                }

                .ant-radio-button-wrapper {
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: transparent;
                    border-radius: 3px;
                    font-size: 12px;
                    font-weight: 600;
                    color: #21314b;
                    border: none;
                    padding: 0 10px;
                }

                .ant-radio-button-wrapper-checked {
                    background-color: #fff;
                    border: none;
                }

                .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
                    display: none;
                }

                .ant-radio-button-wrapper:not(:first-child)::before {
                    display: none;
                }
            }
        }

    }
}

.gridFilterBtn{
    background-color: #ebebeb !important;
}

.gridFilterBtn:hover{
    opacity: 75%
}

// Order tracking dashboard table
.order-tracking-dashboard-table {

    .new-gen-pagination{
        background-color: transparent;
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: end;
        height: auto !important;
        margin-bottom: 0 !important;
        padding-right: 0;

        .ngp-left {
            width: auto;
            margin-right: 12px;
        }

        .imhl-search {
            position: relative;

            input {
                background: #fff;
                padding: 8px 30px;
                height: 30px;
                font-size: 12px;
                color: #000;
                font-weight: 600;
                border-radius: 3px;
                width: 110px;
                border: 1px solid #ccd8ed;
                transition: 0.5s;
                border-radius: 5px;
                outline: none;
            }

            input:focus {
                width: 250px;
                transition: 0.5s;
                border: 1px solid $primary-color;
                box-shadow: 0px 0px 0px 2px #DADAFF;
            }

            input::placeholder {
                color: #8496A9;
            }

            .search-image {
                position: absolute;
                top: 8px;
                left: 7px;
                width: 15px;
            }

            svg {
                position: absolute;
                top: 7px;
                left: 7px;
            }

            .td-search {
                position: absolute;
                top: 6px;
                right: 2px;
                padding: 10px;
                cursor: pointer;
                opacity: 0;
                visibility: hidden;

                svg {
                    position: absolute;
                    top: 2px;
                    left: 8px;
                }
            }

            .closeSearch {
                position: absolute;
                right: 46px;
                top: 0px;
                cursor: pointer;
            }
        }

        .imhl-search:hover {
            .td-search {
                opacity: 1;
                visibility: visible;
            }
        }

        .drop-toggle-btn {
            position: relative;
            margin-left: 10px;

            .dtb-show {
                padding: 5px 7px;
                white-space: nowrap;
                color: #8496A9;
                border-radius: 5px;
                border: 1px solid #ccd8ed;
                background-color: #fff;
                margin-left: 0px;
                height: 30px;
                font-weight: 500;
                position: relative;
                font-size: 12px;
                display: flex;
                align-items: center;

                .total-count {
                    padding-right: 5px;

                    .to-num {
                        color: #111D31;
                        font-size: 13px;
                        font-weight: 600;
                    }

                    .to-num:hover {
                        .generic-tooltip {
                            opacity: 1;
                            visibility: visible;
                            left: 38%;
                            color: #fff;
                        }
                    }

                    .to-slas {
                        color: #111D31;
                        font-size: 12px;
                        font-weight: 700;
                        padding: 0 3px;
                    }
                }

                span {
                    color: #111d31;
                    font-weight: 600;
                }

                svg {
                    margin-left: 5px;
                }
            }

            .dtb-show:hover {
                border: 1px solid $primary-color;
                box-shadow: 0px 0px 0px 2px #DADAFF;
            }

            .page-dropdown {
                position: absolute;
                top: 38px;
                right: 0;
                height: auto;
                background-color: #fff;
                border: none;
                text-align: left;
                // box-shadow: 2px 0 6px 0 rgba(139, 139, 139, 0.16);
                box-shadow: 0 2.71233px 18.99px rgba(160, 160, 160, 0.5607843137);
                transition: all .6s;
                z-index: 2;

                .sel-page {
                    border: none;
                    width: 100%;
                    margin: 0;
                    text-align: left;
                    font-size: 12px;
                    padding: 5px 15px;
                    font-weight: 500;
                }

                .sel-page:hover {
                    background-color: $dropdown-item-hover;
                }
            }
        }

        .pi-number-btn,
        .pinum-input {
            min-width: 70px !important;
            height: 30px !important;
            border: 1px solid $borderSlateGrey !important;
            padding: 0 3px 0 5px !important;
            display: inline-block !important;
            border-radius: 4px !important;

            span {
                font-weight: 600;
                font-size: 12px;
                letter-spacing: 0;

                label {
                    margin: 0;
                    padding: 0 3px;
                    cursor: pointer;
                }
            }
        }

        .pi-number-btn:hover {
            background-color: #f5f5f5;
        }

        .pinum-input {
            width: 70px;
            margin-left: 10px;
            font-size: 13px;
        }

        .pinum-input:focus {
            border: 1px solid $primary-color;
            box-shadow: 0px 0px 0px 2px #DADAFF;
        }

        .nt-btn {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .pagination-inner {
                display: block;

                .pagination-item {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    li {
                        margin: 0;

                        button {
                            // margin-left: 10px;

                            .page-item-btn-inner {
                                letter-spacing: 3px;
                            }
                        }

                        .falsenext-btn,
                        .falselast-btn,
                        .falseprev-btn,
                        .falsefirst-btn {
                            width: 30px;
                            height: 30px;
                            border: 1px solid #dae4ee;
                            padding: 0;
                            display: inline-block;
                            border-radius: 4px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .falsenext-btn:hover,
                        .falselast-btn:hover,
                        .falseprev-btn:hover,
                        .falsefirst-btn:hover {
                            color: #fff !important;
                            background-color: $primary-color !important;
                            border: 1px solid $primary-color !important;
                            transition: all .3s !important;

                            svg {
                                path {
                                    fill: #fff;
                                }
                            }
                        }

                        .pi-number-btn,
                        .pinum-input {
                            min-width: 70px;
                            height: 30px;
                            border: 1px solid #dae4ee;
                            padding: 0 3px 0 5px;
                            display: inline-block;
                            border-radius: 4px;

                            span {
                                font-weight: 600;
                                font-size: 12px;
                                letter-spacing: 0;

                                label {
                                    margin: 0;
                                    padding: 0 3px;
                                    cursor: pointer;
                                }
                            }
                        }

                        .pi-number-btn:hover {
                            background-color: #f5f5f5;
                        }

                        .pinum-input {
                            width: 70px;
                            margin-left: 10px;
                            font-size: 13px;
                        }

                        .pinum-input:focus {
                            border: 1px solid $primary-color;
                            box-shadow: 0px 0px 0px 2px #DADAFF;
                        }
                    }
                    li:first-child {
                        button{
                            margin-left: 0;
                        }
                    }
                }
            }
        }

    }
}