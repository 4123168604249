@import '../variables.scss';

// --------------------------------AUDIT LOG CSS-----------------------------------------------------
.audit-log-modal{
    width: 435px;
    height: 100%;
    right: 0;
    top: 0;
    position: absolute;
    .alm-head{
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
        border-bottom: 1px solid #DAE4EE;
        height: 70px;
        .alm-left{
            display: flex;
            label{
                color: #0F172A;
                font-size: 16px;
                font-weight: 700;
                margin: 0;
            }
            p{
                color: #0F172A;
                font-size: 13px;
                font-weight: 400;
                margin: 0;
            }
        }
        .alm-right{
            svg{
                cursor: pointer;
            }
        }
    }
    .alm-body-scroll{
        overflow-y: auto;
        .alm-body{
            padding: 0px 25px;
            .almb-logs{
                position: relative;
                padding: 10px 0;
                .almbl-request{
                    display: flex;
                    .alm-icon{
                        width: 28px;
                        height: 28px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 9;
                        background: #fff;
                    }
                    .almi-approved{
                        background: #03B675;
                    }
                    .almi-rejected{
                        background: $danger-color;
                    }
                    .almi-edited{
                        background: #0F172A;
                    }
                    .almi-created{
                        background: #2969FF;
                    }
                    .log-details{
                        padding: 3px 0px 0px 15px;
                        width: 100%;
                        label{
                            color: #242A31;
                            font-size: 14px;
                            font-weight: 400;
                            margin: 0;
                            b{
                                font-weight: 600;
                                padding-right: 3px;
                            }
                        }
                        .ld-audit-details{
                            width: auto;
                            height: auto;
                            padding: 0 10px 10px;
                            border-radius: 10px;
                            background: #f5f5f5;
                            margin: 5px 0px;
                            .ldad-amt-qty{
                                display: flex;
                                padding-top: 5px;
                                label{
                                    color: #808A94;
                                    font-size: 13px;
                                    font-weight: 400;
                                    margin: 0;
                                }
                                .right-arrow{
                                    padding: 1px 0;
                                }
                                span{
                                    color: #0F172A;
                                    font-size: 13px;
                                    font-weight: 500;
                                    padding: 0px 4px;
                                }
                            }
                            .view-full-det{
                                padding-top: 10px;
                                color: #0F172A;
                                font-size: 11px;
                                font-weight: 400;
                                text-decoration-line: underline;
                                cursor: pointer;
                                width: fit-content;
                            }
                            .view-full-det:hover{
                                color: #2969FF;
                            }
                        }
                        .ld-time{
                            color: #808A94;
                            font-size: 12px;
                            font-weight: 400;
                            padding-top: 5px;
                        }
                    }
                }
                .show-more-logs{
                    .alm-icon{
                        border-radius: 9px;
                        border: 1px solid #dee;
                    }
                }
            }
            .almb-logs::before{
                content: "";
                position: absolute;
                width: 1px;
                background-color: #dae4ee;
                top: 0px;
                bottom: 0;
                left: 13px;
                height: 100%;
                z-index: 1;
            }
            .almb-log-clicked{
                opacity: 1 !important;
            }
            .almb-log-clicked::before{
                display: none;
            }
        }
    }
    .alm-blur-body{
        .almb-logs{
            opacity: 0.2;
        }
    }
    .alm-timeline-disable{
        .almb-logs::before{
            display: none;
        }
    }
}

.log-event-det-modal{
    width: 550px;
    height: 100%;
    right: 433px;
    top: 0;
    position: absolute;
    border-radius: 0px 0px 0px 4px;
    box-shadow: 0px 4px 24px 0px rgba(115, 114, 114, 0.25);
    z-index: 999;
    .ledm-head{
        display: flex;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 1px solid #DAE4EE;
        height: 70px;
        .ledmh-icon{
            border-radius: 9px;
            border: 1px solid #DAE4EE;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            padding: 5px;
        }
        .ledmh-icon:hover{
            background-color: #f5f5f5;
        }
        label{
            color: #0F172A;
            font-size: 16px;
            font-weight: 700;
            margin: 0;
            padding: 0px 7px 0px 15px;
        }
        p{
            color: #0F172A;
            font-size: 16px;
            font-weight: 400;
            margin: 0;
        }
    }
    .ledm-body{
        padding: 10px 20px;
        overflow: auto;
        .ledmb-overview{
            padding-bottom: 30px;
            label{
                color: #242A31;
                font-size: 14px;
                font-weight: 700;
                margin: 0;
                padding-bottom: 10px;
            }
            .ledmbo-filter{
                width: 275px;
                height: auto;
                border-radius: 4px;
                border: 1px solid #dae4ee;
                background: #fff;
                display: flex;
                align-items: center;
                padding: 5px;
                margin-bottom: 10px;
                label{
                    color: #8496A9;
                    font-size: 13px;
                    font-weight: 400;
                    margin: 0;
                }
                select{
                    color: #0f172a;
                    font-size: 13px;
                    font-weight: 600;
                    border: none;
                    padding-left: 5px;
                    width: 165px;
                    background-color: #fff;
                }
            }
            .ledmbo-scroll{
                height: auto;
                max-height: 200px;
                overflow: auto;
                table{
                    width: 100%;
                    thead{
                        background-color: #F8F8F8;
                        position: sticky;
                        top: 0;
                        z-index: 99;
                        tr{
                            th{
                                padding: 5px 15px;
                                color: #67768E;
                                font-size: 12px;
                                font-weight: 600;
                                border-bottom: 1px solid #DAE4EE;
                                border-right: 1px solid #DAE4EE;
                            }
                            th:nth-child(1){
                                position: sticky;
                                left: 0;
                                z-index: 9;
                                background-color: #F8F8F8;
                            }
                        }
                    }
                    tbody{
                        border-bottom: 1px solid #DAE4EE;
                        tr{
                            td{
                                color: #67768E;
                                font-size: 12px;
                                font-weight: 400;
                                border-right: 1px solid #DAE4EE;
                                padding: 5px 15px;
                                svg{
                                    margin: 0px 5px;
                                }
                                .td-more-btn{
                                    color: #116FFF;
                                    font-size: 12px;
                                    font-weight: 400;
                                    text-decoration-line: underline;
                                    background-color: #fff;
                                }
                            }
                            td:nth-child(1){
                                position: sticky;
                                left: 0;
                                z-index: 9;
                                background-color: #fff;
                            }
                            .td-first-col{
                                color: #242A31;
                                font-weight: 600;
                            }
                            td:nth-last-child(1){
                                border-right: none;
                            }
                        }
                        tr:nth-last-child(1){
                            border-bottom: 1px solid #DAE4EE;
                        }
                    }
                }
            }
        }
    }
}
.ledm-more-data{
    right: 981px;
    width: 250px;
    border-radius: 4px 0px 0px 0px;
    box-shadow: none;
    .ledm-head{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 10px 10px 10px 15px;
        svg{
            margin-right: 5px;
        }
        h3{
            color: #242A31;
            font-size: 14px;
            font-weight: 600;
            margin: 0;
        }
        p{
            font-size: 13px;
            color: #116FFF;
            font-weight: 600;
        }
        span{
            color: #242A31;
            font-size: 11px;
            font-weight: 500;
        }
        button{
            background-color: #fff;
            padding: 0;
            svg{
                margin-right: 0;
            }
        }
    }
    .ledm-body{
        overflow: auto;
        padding: 0;
        .ledmb-det{
            padding: 1px 15px;
            border-bottom: 1px solid #116fff1a;
            margin-top: 2px;
            label{
                color: #242A31;
                font-size: 12px;
                font-weight: 600;
                margin: 0;
            }
            p{
                color: #242A31;
                font-size: 12px;
                font-weight: 400;
                margin: 0;
            }
        }
        .ledmb-det:nth-child(1){
            margin-top: 0;
        }
    }
}

.lr-new-page-design{
    top: 45px;
    right: 0;
    bottom: 0;
    left: 60px;
    z-index: 7 !important;
}

.lr-new-page-vendor{
    top: 20px;
    right: 0;
    bottom: 0;
    left: 30px;
    z-index: 7 !important;
}